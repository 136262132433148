const Icon_Radiation = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 30C8 42.1334 17.8698 52 30.0073 52C42.1156 52 52 42.1334 52 30C52 17.8666 42.1447 8 30.0073 8C17.8698 8 8 17.8666 8 30ZM10.6019 30C10.6019 19.3197 19.3089 10.6156 30.0073 10.6156C40.6766 10.6156 49.3836 19.3052 49.3836 30C49.3836 40.6949 40.6766 49.3844 29.9927 49.3844C19.2944 49.3844 10.6019 40.6803 10.6019 30ZM28.4541 24.945C26.6222 25.6503 25.2482 27.2961 25.0192 29.2946L14.5999 29.0595C14.7144 25.8854 15.7448 22.9465 17.4623 20.3602C19.1798 17.774 21.6987 15.7755 24.5612 14.5999L28.4541 24.945ZM30.057 33.2918C31.8276 33.2918 33.263 31.8181 33.263 30.0002C33.263 28.1823 31.8276 26.7086 30.057 26.7086C28.2864 26.7086 26.8511 28.1823 26.8511 30.0002C26.8511 31.8181 28.2864 33.2918 30.057 33.2918ZM30.0576 35.2902C28.9126 35.2902 27.8821 34.9375 26.9661 34.2322L21.4702 42.6963C23.9892 44.4597 27.0806 45.4001 30.0576 45.4001C33.0345 45.4001 36.0115 44.4597 38.6449 42.8139L33.149 34.3497C32.233 34.82 31.2026 35.2902 30.0576 35.2902ZM33.9498 26.5908C34.5223 27.4137 34.9803 28.2366 35.0948 29.2946L45.3996 28.9419C45.2851 25.8854 44.3691 22.8289 42.5372 20.2427C40.8197 17.6564 38.3007 15.6579 35.4383 14.5999L31.5453 24.945C32.5758 25.2976 33.3773 25.8854 33.9498 26.5908Z" fill="#79C8B0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 30C8 42.1334 17.8698 52 30.0073 52C42.1156 52 52 42.1334 52 30C52 17.8666 42.1447 8 30.0073 8C17.8698 8 8 17.8666 8 30ZM10.6019 30C10.6019 19.3197 19.3089 10.6156 30.0073 10.6156C40.6766 10.6156 49.3836 19.3052 49.3836 30C49.3836 40.6949 40.6766 49.3844 29.9927 49.3844C19.2944 49.3844 10.6019 40.6803 10.6019 30ZM28.4541 24.945C26.6222 25.6503 25.2482 27.2961 25.0192 29.2946L14.5999 29.0595C14.7144 25.8854 15.7448 22.9465 17.4623 20.3602C19.1798 17.774 21.6987 15.7755 24.5612 14.5999L28.4541 24.945ZM30.057 33.2918C31.8276 33.2918 33.263 31.8181 33.263 30.0002C33.263 28.1823 31.8276 26.7086 30.057 26.7086C28.2864 26.7086 26.8511 28.1823 26.8511 30.0002C26.8511 31.8181 28.2864 33.2918 30.057 33.2918ZM30.0576 35.2902C28.9126 35.2902 27.8821 34.9375 26.9661 34.2322L21.4702 42.6963C23.9892 44.4597 27.0806 45.4001 30.0576 45.4001C33.0345 45.4001 36.0115 44.4597 38.6449 42.8139L33.149 34.3497C32.233 34.82 31.2026 35.2902 30.0576 35.2902ZM33.9498 26.5908C34.5223 27.4137 34.9803 28.2366 35.0948 29.2946L45.3996 28.9419C45.2851 25.8854 44.3691 22.8289 42.5372 20.2427C40.8197 17.6564 38.3007 15.6579 35.4383 14.5999L31.5453 24.945C32.5758 25.2976 33.3773 25.8854 33.9498 26.5908Z" fill="url(#paint0_linear_3445_62609)"/>
<defs>
<linearGradient id="paint0_linear_3445_62609" x1="8.52381" y1="26.3333" x2="52.7437" y2="26.7846" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Radiation;