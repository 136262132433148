import { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import MuiListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';
import MenuLogo from '../../assets/demp_Logo.svg';
import { useLoggedInUser } from "../../api/userInfo";
import { DAP_URL } from "../../shared/config";

const ListHeader = styled.div`
  height: 15%; 
  display: flex;
  margin: auto;
`;



const ListItem = styled(MuiListItem)`
margin:5px 0px;

border-radius: 12px;
color: #ACC0D6;
  :hover {
    background-color:#1FA37C;
    &.Mui-selected {
      background-color: #00FFB7;
      color: black;
    };
  };  
  &.Mui-selected {
    background-color: #00FFB7;
    color: black;
  };
`;


const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation('main');
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useLoggedInUser();


  const activeMenu =
    location.pathname === "/"
      ? "/"
      : `/${location.pathname.split("/")[1]}`;
  const handleListItemClick = (path) => (event) => {
    navigate(path)
  };

  const handleToggleDrawer = (open) => (event) => {
    setIsOpen(!open);
  };

  const handleCloseDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsOpen(false);
  };

  const subStringMenuPath = (path) => {
    if (path.indexOf("/", 2) === -1) {
      return path
    } else {
      return path.substring(0, path.indexOf("/", 2))
    }
  };
  // the icons used are from https://remixicon.com/
  let menuList = [
    // {
    //   title: t('main:sider_bar.overview'),
    //   icon: <i className="ri-pie-chart-2-line "></i>,
    //   path: "/",
    // },
    {
      title: t('main:sider_bar.dashboard'),
      icon: <i className="ri-dashboard-line "></i>,
      path: `/plant/dashboard/${localStorage.getItem('dashboardId')}`,
    },
    {
      title: t('main:sider_bar.dap'),
      icon: <svg width="20" height="20" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M3.11101 1.17971C4.26215 0.410543 5.61553 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7C14 8.38447 13.5895 9.73785 12.8203 10.889C12.0511 12.0401 10.9579 12.9373 9.67879 13.4672C8.3997 13.997 6.99224 14.1356 5.63437 13.8655C4.2765 13.5954 3.02922 12.9287 2.05026 11.9497C1.07129 10.9708 0.404603 9.7235 0.134506 8.36563C-0.13559 7.00776 0.0030331 5.6003 0.532846 4.32122C1.06266 3.04213 1.95987 1.94888 3.11101 1.17971ZM7 0.933333C5.80013 0.933333 4.6272 1.28914 3.62954 1.95575C2.63189 2.62236 1.85431 3.56985 1.39513 4.67839C0.935962 5.78692 0.815822 7.00673 1.04991 8.18355C1.28399 9.36036 1.86178 10.4413 2.71022 11.2898C3.55866 12.1382 4.63964 12.716 5.81645 12.9501C6.99327 13.1842 8.21308 13.064 9.32161 12.6049C10.4302 12.1457 11.3776 11.3681 12.0442 10.3705C12.7109 9.3728 13.0667 8.19987 13.0667 7C13.0667 5.39102 12.4275 3.84794 11.2898 2.71022C10.1521 1.5725 8.60898 0.933333 7 0.933333Z" fill="#e4f7f2" />
        <path d="M7.4664 2C8.71792 1.99928 9.92284 2.47476 10.8366 3.32994L11.1765 3.64805L7 8.13246V2.00027L7.4664 2Z" fill="#e4f7f2" />
      </svg>,
      path: DAP_URL,
      // path: "https://dap.vekin.co.th/dashboard/dashboard",
      isExternal: true
    },
    // {
    //   title: 'Device Management',
    //   icon: <svg width="25" height="2ain:sider_bar.things4" viewBox="0 0 25 24" fill="current" className="fill-current" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M13.5 19H14.5C14.7652 19 15.0196 19.1054 15.2071 19.2929C15.3946 19.4804 15.5 19.7348 15.5 20H21.5C22.0523 20 22.5 20.4477 22.5 21C22.5 21.5523 22.0523 22 21.5 22H15.5C15.5 22.2652 15.3946 22.5196 15.2071 22.7071C15.0196 22.8946 14.7652 23 14.5 23H10.5C10.2348 23 9.98043 22.8946 9.79289 22.7071C9.60536 22.5196 9.5 22.2652 9.5 22H3.5C2.94772 22 2.5 21.5523 2.5 21C2.5 20.4477 2.94772 20 3.5 20H9.5C9.5 19.7348 9.60536 19.4804 9.79289 19.2929C9.98043 19.1054 10.2348 19 10.5 19H11.5V17H4.5C4.23478 17 3.98043 16.8946 3.79289 16.7071C3.60536 16.5196 3.5 16.2652 3.5 16V12C3.5 11.7348 3.60536 11.4804 3.79289 11.2929C3.98043 11.1054 4.23478 11 4.5 11H20.5C20.7652 11 21.0196 11.1054 21.2071 11.2929C21.3946 11.4804 21.5 11.7348 21.5 12V16C21.5 16.2652 21.3946 16.5196 21.2071 16.7071C21.0196 16.8946 20.7652 17 20.5 17H13.5V19ZM4.5 3H20.5C20.7652 3 21.0196 3.10536 21.2071 3.29289C21.3946 3.48043 21.5 3.73478 21.5 4V8C21.5 8.26522 21.3946 8.51957 21.2071 8.70711C21.0196 8.89464 20.7652 9 20.5 9H4.5C4.23478 9 3.98043 8.89464 3.79289 8.70711C3.60536 8.51957 3.5 8.26522 3.5 8V4C3.5 3.73478 3.60536 3.48043 3.79289 3.29289C3.98043 3.10536 4.23478 3 4.5 3ZM9.5 7H10.5V5H9.5V7ZM9.5 15H10.5V13H9.5V15ZM5.5 5V7H7.5V5H5.5ZM5.5 13V15H7.5V13H5.5Z" fill="current" />
    //   </svg>
    //   ,
    //   path: "/device-management",


    // },
    // {
    //   title: t('main:sider_bar.group'),
    //   icon: <i className="ri-user-line"></i>,
    //   path: "/group",
    // },
    // {
    //   title: t('main:sider_bar.sites'),
    //   icon: <i className="ri-map-2-line"></i>,
    //   path: "/site",
    // },
    // {
    //   title: t('main:sider_bar.devices'),
    //   icon: <i className="ri-tv-2-line"></i>,
    //   path: "/devices",
    // },
    // {
    //   title: t('main:sider_bar.analytic'),
    //   icon: <i className="ri-line-chart-line"></i>,
    //   path: "/analytic",
    // },
    // {
    //   title: t('main:sider_bar.management'),
    //   icon: <i className="ri-equalizer-line"></i>,
    //   path: "/management",
    // },
    // {
    //   title: t('main:sider_bar.report'),
    //   icon: <i className="ri-file-chart-line"></i>,
    //   path: "/report",
    // },
    // {
    //   title: t('main:sider_bar.user_profile'),
    //   icon: <i className="ri-shield-user-line"></i>,
    //   path: "/user-profile",
    // },
    {
      title: t('main:sider_bar.setting'),
      icon: <i className="ri-settings-2-line"></i>,
      path: `/setting`,
    },
  ];

  useEffect(() => {
    if (!user?.is_admin) {
      menuList = menuList.filter((item) => item.path != "/group")
    }
  })
  const SideMenuList = () => (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      height={"85%"}
      paddingX

    >
      <div>
        {menuList.slice(0, 2).map((menu, index) => menu.isExternal ?
          (<a key={index} href={menu.path} >
            <div
              style={{
                justifyContent: "center",
                paddingLeft: isOpen && "0px"
              }}
              className={"group my-5 opacity-70 inline-flex"}
            >
              <ListItemIcon style={{ justifyContent: "center", padding: '6px 0px' }}>
                {menu.icon}
              </ListItemIcon>
              {isOpen && <ListItemText primary={menu.title} />}
            </div>
          </a>)
          : (

            <Link key={menu.title} to={menu.path}>
              <ListItem selected={activeMenu === subStringMenuPath(menu.path)}
                style={{
                  justifyContent: "center",
                  paddingLeft: isOpen && "0px"
                }}
                className={"group"}
              >
                <ListItemIcon style={{ justifyContent: "center" }}>
                  {/* <img src={activeMenu === subStringMenuPath(menu.path) ? menu.iconActive : menu.icon} alt='' /> */}
                  <p className={`text-xl group-hover:text-black hover:transition-colors ease-in-out delay-50 ${(activeMenu === subStringMenuPath(menu.path)) ? "text-black fill-black" : "text-[#e4f7f2]"}`}>{menu.icon}</p>
                </ListItemIcon>
                {isOpen && <ListItemText primary={menu.title} />}
              </ListItem>
            </Link>
          ))}



      </div>
      <div>
        {menuList.slice(2).map((menu, index) => (
          <Link key={menu.title} to={menu.path}>
            <ListItem selected={activeMenu === subStringMenuPath(menu.path)}
              style={{
                justifyContent: "center",
                paddingLeft: isOpen && "0px"
              }}
              className={"group"}
            >
              <ListItemIcon style={{ justifyContent: "center" }}>
                {/* <img src={activeMenu === subStringMenuPath(menu.path) ? menu.iconActive : menu.icon} alt='' /> */}
                <i className={`text-xl group-hover:text-black hover:transition-colors ease-in-out delay-50 ${(activeMenu === subStringMenuPath(menu.path)) ? "text-black" : "text-[#e4f7f2]"}`}>{menu.icon}</i>
              </ListItemIcon>
              {isOpen && <ListItemText primary={menu.title} />}
            </ListItem>
          </Link>
        ))}

      </div>
    </Stack>
  );

  const SideList = () => (
    <List
      disablePadding
      style={{ height: "100vh" }}
      className="sidebar-background"

    >
      <div>
        <img src={MenuLogo} alt='' onClick={handleToggleDrawer(isOpen)} className="object-contain w-full h-full" />

      </div>
      <SideMenuList />
    </List>
  );

  const SideListOpen = () => (
    <List className="sidebar-background"
      disablePadding
      style={{ height: "100vh" }}
    >
      <div className="inline-flex items-center text-2xl text-white">
        {/* <ImageHeader src={MenuLogo} alt='' onClick={handleToggleDrawer(isOpen)} /> */}
        <img src={MenuLogo} alt='' onClick={handleToggleDrawer(isOpen)} className="object-contain w-20 h-20" />
        <span> DEMP</span>
      </div>
      <SideMenuList />
    </List>
  );

  return (
    <div  >
      <MuiDrawer
        sx={{
          width: "70px",
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: "70px",
            height: "100vh",
            boxSizing: 'border-box',
            // borderRadius: "0 24px 24px 0",
            // padding: "0 12px",
            borderRight: "none",
            zIndex: 0
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <SideList />
      </MuiDrawer>

      <MuiDrawer
        sx={{
          '& .MuiDrawer-paper': {
            height: "100vh",
            width: "12vw",
            backgroundColor: "#EEF5F7",
            borderRadius: "0 24px 24px 0",
            // padding: "12px"
          },
        }}
        BackdropProps={{ invisible: true }}
        open={isOpen}
        onClose={handleCloseDrawer()}
      >
        <SideListOpen />
      </MuiDrawer>
    </div>
  );
};

export default SideBar;
