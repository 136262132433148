const Icon_CarbonAvoid = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<g clip-path="url(#clip0_3293_57683)">
<path d="M37.0124 41.8113V40.1338L39.7762 35.885C39.9824 35.5688 40.1749 35.2663 40.3674 34.9775C40.5599 34.6888 40.6974 34.3863 40.8212 34.0838C40.9449 33.7538 40.9999 33.4375 40.9999 33.1075C40.9999 32.7775 40.9312 32.53 40.7937 32.365C40.6562 32.2 40.4774 32.1175 40.2574 32.1175C39.9962 32.1175 39.8037 32.1863 39.6524 32.3375C39.5149 32.475 39.4187 32.6675 39.3637 32.9013C39.3087 33.135 39.2812 33.41 39.2812 33.6988V34.2625H36.9712V33.6438C36.9712 32.9563 37.0812 32.3513 37.3012 31.815C37.5212 31.2788 37.8649 30.8525 38.3462 30.55C38.8274 30.2475 39.4462 30.0825 40.2162 30.0825C41.2474 30.0825 42.0312 30.3575 42.5674 30.8938C43.0899 31.43 43.3649 32.1863 43.3649 33.135C43.3649 33.6025 43.2962 34.0288 43.1449 34.4275C42.9937 34.8263 42.8012 35.2113 42.5674 35.5963C42.3199 35.9675 42.0587 36.3663 41.7837 36.7788L39.6799 39.9275H43.0624V41.7975H37.0124V41.8113Z" fill="#79C8B0"/>
<path d="M37.0124 41.8113V40.1338L39.7762 35.885C39.9824 35.5688 40.1749 35.2663 40.3674 34.9775C40.5599 34.6888 40.6974 34.3863 40.8212 34.0838C40.9449 33.7538 40.9999 33.4375 40.9999 33.1075C40.9999 32.7775 40.9312 32.53 40.7937 32.365C40.6562 32.2 40.4774 32.1175 40.2574 32.1175C39.9962 32.1175 39.8037 32.1863 39.6524 32.3375C39.5149 32.475 39.4187 32.6675 39.3637 32.9013C39.3087 33.135 39.2812 33.41 39.2812 33.6988V34.2625H36.9712V33.6438C36.9712 32.9563 37.0812 32.3513 37.3012 31.815C37.5212 31.2788 37.8649 30.8525 38.3462 30.55C38.8274 30.2475 39.4462 30.0825 40.2162 30.0825C41.2474 30.0825 42.0312 30.3575 42.5674 30.8938C43.0899 31.43 43.3649 32.1863 43.3649 33.135C43.3649 33.6025 43.2962 34.0288 43.1449 34.4275C42.9937 34.8263 42.8012 35.2113 42.5674 35.5963C42.3199 35.9675 42.0587 36.3663 41.7837 36.7788L39.6799 39.9275H43.0624V41.7975H37.0124V41.8113Z" fill="url(#paint0_linear_3293_57683)"/>
<path d="M30.0961 8.1925C18.0099 8.1925 8.19238 18.0238 8.19238 30.0963C8.19238 42.1825 18.0236 52 30.0961 52C42.1686 52 51.9999 42.1825 51.9999 30.0963C51.9999 18.0238 42.1686 8.1925 30.0961 8.1925ZM11.4374 30.0963C11.4374 19.8113 19.8111 11.4375 30.0961 11.4375C34.6474 11.4375 38.8274 13.0875 42.0724 15.81L15.8099 42.0725C13.0874 38.8413 11.4374 34.6613 11.4374 30.0963ZM30.0961 48.755C25.5449 48.755 21.3649 47.105 18.1199 44.3825L44.3824 18.12C47.1049 21.365 48.7549 25.545 48.7549 30.0963C48.7411 40.3813 40.3811 48.755 30.0961 48.755Z" fill="#79C8B0"/>
<path d="M30.0961 8.1925C18.0099 8.1925 8.19238 18.0238 8.19238 30.0963C8.19238 42.1825 18.0236 52 30.0961 52C42.1686 52 51.9999 42.1825 51.9999 30.0963C51.9999 18.0238 42.1686 8.1925 30.0961 8.1925ZM11.4374 30.0963C11.4374 19.8113 19.8111 11.4375 30.0961 11.4375C34.6474 11.4375 38.8274 13.0875 42.0724 15.81L15.8099 42.0725C13.0874 38.8413 11.4374 34.6613 11.4374 30.0963ZM30.0961 48.755C25.5449 48.755 21.3649 47.105 18.1199 44.3825L44.3824 18.12C47.1049 21.365 48.7549 25.545 48.7549 30.0963C48.7411 40.3813 40.3811 48.755 30.0961 48.755Z" fill="url(#paint1_linear_3293_57683)"/>
<path d="M30.3712 25.3525V22.3825C30.3712 22.08 30.3987 21.7913 30.4399 21.5163C30.4949 21.2413 30.5912 21.0213 30.7699 20.8425C30.9349 20.6638 31.1824 20.5813 31.5124 20.5813C31.8699 20.5813 32.1312 20.6638 32.2962 20.8425C32.4612 21.0213 32.5712 21.2413 32.6262 21.5163C32.6812 21.7913 32.6949 22.08 32.6949 22.3825V23.0425L35.5412 20.1963C35.2387 19.6188 34.8399 19.1238 34.2762 18.7938C33.6024 18.395 32.6812 18.2025 31.5262 18.2025C30.3849 18.2025 29.4637 18.395 28.8037 18.7938C28.1299 19.1925 27.6487 19.77 27.3599 20.5125C27.0712 21.255 26.9199 22.1625 26.9199 23.2213V28.8313L30.3712 25.3525Z" fill="#79C8B0"/>
<path d="M30.3712 25.3525V22.3825C30.3712 22.08 30.3987 21.7913 30.4399 21.5163C30.4949 21.2413 30.5912 21.0213 30.7699 20.8425C30.9349 20.6638 31.1824 20.5813 31.5124 20.5813C31.8699 20.5813 32.1312 20.6638 32.2962 20.8425C32.4612 21.0213 32.5712 21.2413 32.6262 21.5163C32.6812 21.7913 32.6949 22.08 32.6949 22.3825V23.0425L35.5412 20.1963C35.2387 19.6188 34.8399 19.1238 34.2762 18.7938C33.6024 18.395 32.6812 18.2025 31.5262 18.2025C30.3849 18.2025 29.4637 18.395 28.8037 18.7938C28.1299 19.1925 27.6487 19.77 27.3599 20.5125C27.0712 21.255 26.9199 22.1625 26.9199 23.2213V28.8313L30.3712 25.3525Z" fill="url(#paint2_linear_3293_57683)"/>
<path d="M30.7561 33.8913C31.0036 33.9188 31.2236 33.96 31.5124 33.96C32.6811 33.96 33.5886 33.7538 34.2624 33.3551C34.9361 32.9563 35.4174 32.365 35.7061 31.6088C35.9949 30.8525 36.1461 29.945 36.1461 28.9V28.4875L30.7561 33.8913Z" fill="#79C8B0"/>
<path d="M30.7561 33.8913C31.0036 33.9188 31.2236 33.96 31.5124 33.96C32.6811 33.96 33.5886 33.7538 34.2624 33.3551C34.9361 32.9563 35.4174 32.365 35.7061 31.6088C35.9949 30.8525 36.1461 29.945 36.1461 28.9V28.4875L30.7561 33.8913Z" fill="url(#paint3_linear_3293_57683)"/>
<path d="M18.0925 33.3412C18.7663 33.7675 19.6875 33.9737 20.8425 33.9737C21.1863 33.9737 21.5163 33.9462 21.8188 33.9187L25.1737 30.5637C25.2287 30.1925 25.27 29.8212 25.27 29.4087V28.075H21.9562V29.6837C21.9562 29.9862 21.9425 30.2887 21.9012 30.5775C21.86 30.8662 21.7637 31.1 21.6125 31.2925C21.4612 31.4712 21.2138 31.5675 20.8563 31.5675C20.5263 31.5675 20.2788 31.485 20.1138 31.3062C19.9488 31.1412 19.825 30.9075 19.77 30.6187C19.715 30.33 19.6875 30.0275 19.6875 29.6837V22.465C19.6875 22.1487 19.715 21.8462 19.7563 21.5712C19.7975 21.2825 19.9075 21.0487 20.0725 20.8562C20.2375 20.6775 20.4988 20.5812 20.8425 20.5812C21.2138 20.5812 21.4612 20.6775 21.6262 20.8562C21.7775 21.035 21.8737 21.2687 21.9012 21.5437C21.9287 21.8187 21.9425 22.1075 21.9425 22.41V23.95H25.2563V22.63C25.2563 21.7637 25.1188 20.9937 24.8575 20.32C24.5963 19.6462 24.1425 19.1237 23.51 18.7525C22.8775 18.3812 21.9838 18.1887 20.8425 18.1887C19.6875 18.1887 18.7663 18.395 18.0925 18.8075C17.4188 19.2337 16.9375 19.825 16.6625 20.595C16.3875 21.3512 16.25 22.2725 16.25 23.3312V28.8587C16.25 29.89 16.3875 30.7975 16.6625 31.5537C16.9375 32.3237 17.4188 32.915 18.0925 33.3412Z" fill="#79C8B0"/>
<path d="M18.0925 33.3412C18.7663 33.7675 19.6875 33.9737 20.8425 33.9737C21.1863 33.9737 21.5163 33.9462 21.8188 33.9187L25.1737 30.5637C25.2287 30.1925 25.27 29.8212 25.27 29.4087V28.075H21.9562V29.6837C21.9562 29.9862 21.9425 30.2887 21.9012 30.5775C21.86 30.8662 21.7637 31.1 21.6125 31.2925C21.4612 31.4712 21.2138 31.5675 20.8563 31.5675C20.5263 31.5675 20.2788 31.485 20.1138 31.3062C19.9488 31.1412 19.825 30.9075 19.77 30.6187C19.715 30.33 19.6875 30.0275 19.6875 29.6837V22.465C19.6875 22.1487 19.715 21.8462 19.7563 21.5712C19.7975 21.2825 19.9075 21.0487 20.0725 20.8562C20.2375 20.6775 20.4988 20.5812 20.8425 20.5812C21.2138 20.5812 21.4612 20.6775 21.6262 20.8562C21.7775 21.035 21.8737 21.2687 21.9012 21.5437C21.9287 21.8187 21.9425 22.1075 21.9425 22.41V23.95H25.2563V22.63C25.2563 21.7637 25.1188 20.9937 24.8575 20.32C24.5963 19.6462 24.1425 19.1237 23.51 18.7525C22.8775 18.3812 21.9838 18.1887 20.8425 18.1887C19.6875 18.1887 18.7663 18.395 18.0925 18.8075C17.4188 19.2337 16.9375 19.825 16.6625 20.595C16.3875 21.3512 16.25 22.2725 16.25 23.3312V28.8587C16.25 29.89 16.3875 30.7975 16.6625 31.5537C16.9375 32.3237 17.4188 32.915 18.0925 33.3412Z" fill="url(#paint4_linear_3293_57683)"/>
</g>
<defs>
<linearGradient id="paint0_linear_3293_57683" x1="37.0473" y1="34.9695" x2="43.4735" y2="35.0052" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57683" x1="8.7139" y1="26.4456" x2="52.7403" y2="26.8949" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57683" x1="27.0226" y1="22.6312" x2="35.6872" y2="22.7029" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57683" x1="30.8203" y1="30.7678" x2="36.2372" y2="30.8222" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_3293_57683" x1="16.3574" y1="24.7658" x2="25.4231" y2="24.8187" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_3293_57683">
<rect width="44" height="44" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>

     );
}
 
export default Icon_CarbonAvoid;
