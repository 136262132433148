import axios from 'axios';
import Cookies from "js-cookie";
import { API_URL } from './config';

export default axios.create({
  withCredentials: true,
  xsrfCookieName : "csrfToken",
  xsrfHeaderName : "X-CSRFToken",
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    'X-CSRFToken': Cookies.get('csrfToken'),
  },
});


