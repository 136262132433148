const Icon_PeakSaved = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#F2F8F6"/>
        <path d="M33.8146 24.4768H34.6888C35.1656 24.4768 35.4835 24.1589 35.4835 23.6821C35.4835 23.2053 35.1656 22.8874 34.6888 22.8874H33.8146C33.1789 18.1987 29.3643 14.4636 24.5961 14.2252V12.7947C24.5961 12.3179 24.2782 12 23.8014 12C23.3246 12 23.0067 12.3179 23.0067 12.7947V14.2252C18.3974 14.702 14.7418 18.2781 14.1855 22.8874H12.914C12.4371 22.8874 12.1193 23.2053 12.1193 23.6821C12.1193 24.1589 12.4371 24.4768 12.914 24.4768H14.106C14.3444 29.404 18.159 33.3775 22.9272 33.8543V34.5695C22.9272 35.0464 23.2451 35.3642 23.7219 35.3642C24.1987 35.3642 24.5166 35.0464 24.5166 34.5695V33.8543C29.6027 33.5364 33.5762 29.4834 33.8146 24.4768ZM15.6954 24.4768H16.4901C16.9669 24.4768 17.2848 24.1589 17.2848 23.6821C17.2848 23.2053 16.9669 22.8874 16.4901 22.8874H15.7749C16.2517 19.1523 19.2716 16.2914 23.0067 15.8146V16.4503C23.0067 16.9272 23.3246 17.245 23.8014 17.245C24.2782 17.245 24.5961 16.9272 24.5961 16.4503V15.8146C28.4901 16.1324 31.6689 19.0728 32.1458 22.8874H31.0332C30.5563 22.8874 30.2385 23.2053 30.2385 23.6821C30.2385 24.1589 30.5563 24.4768 31.0332 24.4768H32.2252C31.9868 28.6093 28.7285 31.947 24.5961 32.2649V30.9139C24.5961 30.4371 24.2782 30.1192 23.8014 30.1192C23.3246 30.1192 23.0067 30.4371 23.0067 30.9139V32.1854C19.0332 31.7881 15.9338 28.5298 15.6954 24.4768Z" fill="#528ACC"/>
        <path d="M33.3378 12.2384H29.9206C29.2848 12.2384 28.7285 12.7947 28.7285 13.4305C28.7285 14.0662 29.2848 14.6225 29.9206 14.6225H33.3378C33.4173 14.6225 33.4173 14.702 33.4173 14.702V17.8808C33.4173 18.5165 33.9735 19.0728 34.6093 19.0728C35.2451 19.0728 35.8808 18.5165 35.8808 17.8808V14.702C35.8808 13.351 34.7682 12.2384 33.3378 12.2384Z" fill="#528ACC"/>
        <path d="M34.6888 29.1655C34.053 29.1655 33.4967 29.7218 33.4967 30.3576V33.5364C33.4967 33.6159 33.4173 33.6159 33.4173 33.6159H30C29.3643 33.6159 28.808 34.1722 28.808 34.8079C28.808 35.4437 29.3643 36 30 36H33.4173C34.7682 36 35.8808 34.8874 35.8808 33.5364V30.3576C35.8808 29.7218 35.3245 29.1655 34.6888 29.1655Z" fill="#528ACC"/>
        <path d="M13.3113 19.0728C13.9471 19.0728 14.5034 18.5165 14.5034 17.8808V14.702C14.5034 14.6225 14.5828 14.6225 14.5828 14.6225H18.0001C18.6358 14.6225 19.1921 14.0662 19.1921 13.4305C19.1921 12.7947 18.7153 12.2384 18.0795 12.2384H14.5828C13.2318 12.2384 12.1193 13.351 12.1193 14.702V17.8808C12.1193 18.5165 12.6756 19.0728 13.3113 19.0728Z" fill="#528ACC"/>
        <path d="M18.0795 33.6159H14.5828C14.5034 33.6159 14.5034 33.5364 14.5034 33.5364V30.3576C14.5034 29.7218 13.9471 29.1655 13.3113 29.1655C12.6756 29.1655 12.1193 29.7218 12.1193 30.3576V33.5364C12.1193 34.8874 13.2318 36 14.5828 36H18.0001C18.6358 36 19.1921 35.4437 19.1921 34.8079C19.1921 34.1722 18.7153 33.6159 18.0795 33.6159Z" fill="#528ACC"/>
        <path d="M29.0465 19.7881C28.9671 19.3113 28.5697 18.9934 28.1724 18.9139C28.0134 18.9139 24.1194 18.0397 21.179 20.0265C18.6359 21.8543 18.6359 25.6689 18.7154 27.1788L18.5565 27.3377C18.0796 27.8146 18.0796 28.5298 18.477 29.0066C18.7154 29.245 19.0333 29.404 19.3512 29.404C19.669 29.404 19.9075 29.3245 20.1459 29.0861L20.3843 28.9271C20.8611 29.0066 21.4969 29.0861 22.2916 29.0861C23.8015 29.0861 25.7088 28.6887 27.2187 27.2583C30.0002 24.3179 29.126 19.947 29.0465 19.7881ZM25.4704 25.5099C24.6757 26.3046 23.6426 26.543 22.7684 26.6225L24.1194 25.351C24.5962 24.8742 24.5962 24.1589 24.1988 23.6821C23.722 23.2053 23.0068 23.2053 22.53 23.6026L21.179 24.8742C21.3379 23.7616 21.7353 22.5695 22.6094 21.9338C23.9604 20.9801 25.8677 20.9801 26.9008 21.0596C26.9008 22.1722 26.8214 24.1589 25.4704 25.5099Z" fill="#528ACC"/>
        </svg>

     );
}
 
export default Icon_PeakSaved;