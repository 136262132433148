import React, { useEffect, useState } from "react";
import imgClose from "../../../assets/siteManagement/images/close-1.svg"
import imgWarning from "../../../assets/siteManagement/images/warning.svg"

function PopupModalError(props) {
    const [labelError, setLabelError] = useState('');
    //==============================
    useEffect(() => {
        const bodyHtml = document.querySelector("body");
        if (props.isOpen) {
            bodyHtml.style.overflow = "hidden";
        } else {
            bodyHtml.style.overflow = "auto";
        }
        return () => {
            bodyHtml.style.overflow = "auto";
        }
    }, [])
    useEffect(() => {
        const bodyHtml = document.querySelector("body");
        if (props.isOpen) {
            bodyHtml.style.overflow = "hidden";
        } else {
            bodyHtml.style.overflow = "auto";
        }
    }, [props.isOpen])
    useEffect(() => {
        setLabelError(props.text);
    }, [props.text])
    //==============================
    function onClickCloseFunc() {
        try {
            props.onClickClose();
        } catch { }
    }
    //==============================
    return (
        <div className={`w-full h-full
        fixed top-0 left-0 z-[9999] 
        bg-black-opacity50
        flex justify-center items-center
        ${props.isOpen ? 'flex' : 'none'}
        `}
            style={{
                animation: `${props.isOpen ? 'fade-in 0.3s' : ''}`
            }}
        >
            <div className="w-96 p-4 rounded-lg shadow-content-box bg-white relative">
                <div className="w-full flex justify-center mt-2">
                    <img className="w-16 h-16" src={imgWarning} />
                </div>
                <div className="w-full flex justify-center mt-4 px-4">
                    <p className={`text-darkGreen text-xl font-semibold text-center`}>
                        {labelError}
                    </p>
                </div>
                <div className="absolute top-2 right-2 hover:opacity-50 duration-300 ease-in-out cursor-pointer">
                    <img className="w-4 h-4" src={imgClose} onClick={(e) => { onClickCloseFunc() }} />
                </div>
            </div>
        </div>
    )
}

export default PopupModalError;