import React, { Suspense, lazy, useEffect, useContext, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Routes, Route, Navigate } from "react-router-dom";
import PageLayout from "./components/layout/PageLayout";
import { AuthContext } from "./context/auth/auth_context";
import route from "./shared/constants/route_list";
import { webStyle as theme } from "./components/layout/Theme";
import {useLoggedInUser } from "./api/userInfo";



import LoginPage from './view/authentication/Login';
import SignUpPage from './view/authentication/SignUp';
import InvitationPage from './view/management/InvitationPage';
import DeviceManagement from './view/management/device/DeviceManagement';
import ForgotPassword from "./view/authentication/ForgotPassword";
import ResetPassword from "./view/authentication/ResetPassword";
import SiteMainPage from "./view/management/site/SiteMainPage";
import SiteAddEditPage from "./view/management/site/SiteAddEditPage";
import AnalyticsPage from "./view/Analytic";

//company dashboard
import AddNewWidget from "./view/management/widgets/addNewWidget";


//dashboards
import SignageTvDashboard from "./view/dashboards/signageTv/SignageTvDashboard";

// const OverviewPage = lazy(() => import('./view/Overview'));
const Dashboard = lazy(() => import("./view/Dashboard"));
const SitePage = lazy(() => import("./view/Site"));
const SettingPage = lazy(() => import("./view/Setting"));
const Users = lazy(() => import("./view/User"));
const Group = lazy(() => import("./view/Group"));
// const Theme = lazy(() => import('./view/Theme'));
// const SolarPage = lazy(() => import('./view/Solar'));
// const WindPage = lazy(() => import('./view/Wind'));
// const AnalyticPage = lazy(() => import('./view/Analytic'));

const Loading = () => {
  return (
    <div className="h-screen">
      <div className="flex items-center justify-center w-full h-full ">
        <CircularProgress
          size={100}
          style={{ color: theme["Theme"]["main"] }}
          disableShrink
        />
      </div>
    </div>
  );
};

const AppRouter = () => {
  // const authContext = useContext(AuthContext);
  // const { dispatch } = authContext;
  const {user, isLoadingUser, isErrorUser} = useLoggedInUser()
  
  const checkIsAuthenticated = (component) => {
    if (isLoadingUser) return <Loading />;
    else if (user?.is_authenticated) return <Navigate to={route.OVERVIEW} />;
    else return component;
  };

  const checkPrivateRoute = () => {
    if (isLoadingUser) return <Loading />;
    else if (user?.is_authenticated) return <PrivateRouter />;
    else return <Navigate to={route.LOGIN} />;
  };

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={"/login/dap"} element={checkIsAuthenticated(<LoginPage isDap={true} />)}/>
          <Route path={route.LOGIN} element={checkIsAuthenticated(<LoginPage isDap={false} />)}/>
          <Route path={'/signup'} element={<SignUpPage/>} />

          <Route path={'/forget-password'} element={<ForgotPassword/>} />
          <Route path={'/reset-password'} element={<ResetPassword/>} />
          <Route path={'/invitation/:invitationID'} element={<InvitationPage/>} />

          <Route path="*" element={checkPrivateRoute()} />
        </Routes>
      </Suspense>
    </>
  );
};

const PrivateRouter = () => {
    return (

        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path={route.OVERVIEW} element={<SitePage />}/>
            <Route path={route.SETTING} element={<PageLayout> <Users /></PageLayout>} />
            {/* <Route path={route.SITE} element={<SitePage />} /> */}

            {/* PLANT DASHBOARDS */}
            <Route path={"/plant/dashboard/:dashboardId"} element={<PageLayout> <Dashboard /></PageLayout>} />
            <Route path={"/plant/dashboard/setup/:dashboardId"} element={<PageLayout><SettingPage /></PageLayout>} />
            <Route path={"/plant/widget/add/:dashboardId"} element={<PageLayout> <AddNewWidget /></PageLayout>} />

            {/* Company DASHBOARDS */}
            <Route path={"/company/dashboard/:dashboardId"} element={<PageLayout> <Dashboard isCompany={true}/></PageLayout>} />
            <Route path={"/company/dashboard/setup/:dashboardId"} element={<PageLayout><SettingPage isCompany={true}/></PageLayout>} />
            <Route path={"/company/widget/add/:dashboardId"} element={<PageLayout> <AddNewWidget isCompany={true} /></PageLayout>} />
            
            
            {/* GLOBAL PAGES */}
            <Route path={route.DEVICE_MANAGEMENT} element={<PageLayout> <DeviceManagement/> </PageLayout>} />

            <Route path="/site" element={<PageLayout><SiteMainPage /> </PageLayout>} />
            <Route path="/site/add" element={<PageLayout> <SiteAddEditPage /> </PageLayout>} />
            <Route path="/site/edit/:id" element={<PageLayout> <SiteAddEditPage /> </PageLayout>} />

            <Route path="/analytics" element={<PageLayout><AnalyticsPage /> </PageLayout>} />


            {/* <Route path={route.OVERLAY} element={<PageLayout><Dashboard /></PageLayout>} /> */}
            {/* <Route path={route.USER_CREATION} element={<PageLayout> <UserCreation /></PageLayout>} /> */}
            {/* <Route path={route.THEME} element={<PageLayout><Theme /></PageLayout>} />
            <Route path={route.SOLAR} element={<PageLayout><SolarPage /></PageLayout>} />
            <Route path={route.WIND} element={<PageLayout><WindPage /></PageLayout>} />
            <Route path={route.ANALYTIC} element={<PageLayout><AnalyticPage /></PageLayout>} /> */}


            <Route path={"/signage/:dashboardId"} element={<SignageTvDashboard />} />

            <Route
              path="*"
              element={<Navigate to={route.OVERVIEW} />}
            />

          </Routes>
        </Suspense>
    );

}

export default AppRouter;
