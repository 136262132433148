import React, { useEffect, useState } from "react";
import { getImage } from "../../../utils/getImage";

function SiteCardWithDetail(props) {
    const [detailSite, setDetailSite] = useState(null);

    useEffect(() => {
        if (props.detailSite) {
            setDetailSite(props.detailSite);
        }
    }, [props.detailSite])

    function onClickActiveSiteCard() {
        if (!detailSite) return;

        let detail = {
            ...detailSite
        }
        detail.Active = !detail.Active
        setDetailSite(detail)

        try {
            props.onToggle(detail);
        } catch { }
    }

    return (
        <div className="flex items-center w-full mb-3 h-14">
            <div className="overflow-hidden rounded-lg w-14 h-14 bg-vekin-green60">
                {!detailSite?.Image.includes("data:") && <img className="w-full h-full" src={getImage(detailSite?.Image)} /> }
            </div>
            <div className="h-full mx-2" style={{ width: 'calc(100% - 126px' }}>
                <p className="w-full mb-px overflow-hidden text-sm font-bold text-vekin-green100 text-ellipsis whitespace-nowrap"
                    title={detailSite?.Name}>
                    {detailSite?.Name}
                </p>
                <p className="inline-block w-full overflow-hidden text-xs font-normal leading-tight whitespace-normal text-primary text-ellipsis line-clamp-2">
                    {detailSite?.Description}
                </p>
            </div>
            <div className={`toggle-box ${detailSite?.Active ? 'active' : 'inactive'}`}
                onClick={(e) => { onClickActiveSiteCard() }}>
                <div className={`toggle-thumb ${detailSite?.Active ? 'active' : 'inactive'}`}>
                </div>
            </div>
        </div>
    )
}

export default SiteCardWithDetail