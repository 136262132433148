const Icon_RenewableEnergy = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#F2F8F6"/>
        <path d="M22.5582 18.6309H24.9704C25.2817 18.6309 25.5151 18.9422 25.4373 19.3312L24.8926 22.5994C24.8148 22.9884 25.0483 23.2997 25.3595 23.2997H26.6045C27.0714 23.2997 27.227 23.8444 26.9158 24.3113L22.9473 29.9138C22.7917 30.1472 22.4026 30.0694 22.4804 29.7582L23.1807 26.0231C23.2586 25.6341 23.0251 25.3228 22.6361 25.3228L21.8579 25.4006C21.5467 25.4784 21.2354 25.1672 21.3132 24.7781L21.8579 19.3312C21.9357 18.9422 22.247 18.6309 22.5582 18.6309Z" fill="#528ACC"/>
        <path d="M33.6076 20.1094C32.207 15.9853 28.3942 13.184 24.0367 13.184C19.2123 13.2618 15.0882 16.7634 14.1544 21.3544L13.4541 20.265C13.2207 19.8759 12.7538 19.7981 12.3647 20.0315C11.9757 20.265 11.8979 20.7319 12.1313 21.1209L14.3879 24.4669C14.5435 24.6225 14.6991 24.7781 14.9326 24.7781C15.0104 24.7781 15.0104 24.7781 15.0882 24.7781C15.2438 24.7781 15.3994 24.7003 15.5551 24.6225L18.6676 22.1325C18.9788 21.8991 19.0567 21.3544 18.8232 21.0431C18.5898 20.7319 18.0451 20.6541 17.7338 20.8875L16.4888 21.8991C17.1113 18.3197 20.3017 15.5184 24.1145 15.5184C27.4604 15.5184 30.4173 17.6194 31.4289 20.8097C31.6623 21.4322 32.2848 21.7434 32.9073 21.51C33.5298 21.4322 33.8411 20.7319 33.6076 20.1094Z" fill="#528ACC"/>
        <path d="M35.8642 26.5678L33.6077 23.2997C33.452 23.1441 33.2964 22.9884 33.063 22.9884C32.8295 22.9884 32.5961 22.9884 32.4405 23.1441L29.328 25.6341C29.0167 25.8675 28.9389 26.4122 29.1723 26.7234C29.4058 27.0347 29.9505 27.1125 30.2617 26.8791L31.3511 26.0231C30.7286 29.6803 27.5383 32.4816 23.7254 32.4816C20.2239 32.4816 17.1892 30.1472 16.2554 26.8013C16.0998 26.1788 15.4773 25.7897 14.8548 26.0231C14.2323 26.1788 13.8432 26.8013 14.0767 27.4238C15.2438 31.7813 19.2901 34.816 23.8033 34.816C28.7833 34.816 32.9852 31.1588 33.7633 26.4122L34.6192 27.6572C34.7749 27.8906 35.0083 27.9685 35.2417 27.9685C35.3974 27.9685 35.553 27.8906 35.7086 27.8128C36.0199 27.4238 36.0977 26.9569 35.8642 26.5678Z" fill="#528ACC"/>
        </svg>

     );
}
 
export default Icon_RenewableEnergy;