import React, { useEffect, useState } from "react";
import imgArrow from "../../../assets/siteManagement/images/arrow-3.svg"
import imgDelete from "../../../assets/siteManagement/images/delete.svg"
import imgHamburger from "../../../assets/siteManagement/images/hamburger-mini.svg"

function ItemCard(props) {
    const [detail, setDetail] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [focusInput, setFocusInput] = useState(false);
    const [placeholder, setPlaceholder] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [mode, setMode] = useState(1);
    //==============================
    useEffect(() => {
        if (detail) {
            setInputValue(detail.Name)
        }
    }, [detail])
    useEffect(() => {
        setDetail(props.detail);
    }, [props.detail])
    useEffect(() => {
        setIsEdit(props.isEdit);
    }, [props.isEdit])
    useEffect(() => {
        setPlaceholder(props.placeholder);
    }, [props.placeholder])
    useEffect(() => {
        setMode(props.mode)
    }, [props.mode])
    //==============================
    function onClickFunc() {
        try {
            props.onClick();
        } catch { }
    }
    function onClickDeleteFunc() {
        try {
            props.onDelete();
        } catch { }
    }
    function onInputText(e) {
        const value = e.target.value
        setInputValue(value);
        try {
            props.onInputText(value);
        } catch { }
    }
    //==============================
    return (
        <>
            {!isEdit ?
                (<div className="w-full h-full flex items-center rounded-lg 
                hover:bg-vekin-greengray duration-300 ease-in-out cursor-pointer"
                    onClick={(e) => { onClickFunc() }}>
                    <div className="mr-2 px-2 flex items-center" style={{ width: 'calc(100% - 38px)' }}>
                        <p className={`text-vekin-green100 text-sm font-semibold`}>
                            {detail?.Name}
                        </p>
                        {mode === 1 ?
                            (<p className={`ml-auto mr-0 text-tertiary text-sm font-normal`}>
                                {detail?.SubDetail}
                            </p>)
                            :
                            (<></>)
                        }
                    </div>
                    {mode === 1 ?
                        (<div className="w-[30px] h-[30px] rounded bg-vekin-greengray-opacity25 
                            flex justify-center items-center">
                            <img src={imgArrow} />
                        </div>)
                        :
                        (<div className="w-[30px] h-[30px]">
                        </div>)
                    }
                </div>)
                :
                (<div className="w-full h-full flex items-center rounded-lg cursor-default">
                    <div className="w-[30px] h-[30px]
                         flex justify-center items-center">
                        <img src={imgHamburger} />
                    </div>
                    <div className={`h-full mx-2 px-2 flex items-center rounded border border-solid
                        ${focusInput ? 'border-vekin-green100' : 'border-quartenary'}
                        `}
                        style={{ width: 'calc(100% - 46px)' }}>
                        <input
                            className="w-full h-full text-vekin-green100 text-sm focus:outline-0"
                            type={`text`}
                            placeholder={placeholder}
                            onFocus={(e) => { setFocusInput(true) }}
                            onBlur={(e) => { setFocusInput(false) }}
                            value={inputValue}
                            onInput={(e) => { onInputText(e) }}
                        />
                    </div>
                    <div className="w-[30px] h-[30px] rounded bg-vekin-greengray-opacity25 
                        hover:bg-vekin-greengray duration-300 ease-in-out cursor-pointer
                        flex justify-center items-center"
                        onClick={(e) => { onClickDeleteFunc() }}>
                        <img src={imgDelete} />
                    </div>
                </div>)
            }
        </>
    )
}

export default ItemCard