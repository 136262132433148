const Icon_Electricity = ({style}) => {
    return (
        <svg className={style} width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="42" height="42" rx="21" fill="#FFFBF3"/>
        <path d="M18.1762 11H22.3521C22.8909 11 23.295 11.5452 23.1603 12.2266L22.2174 17.9509C22.0826 18.6323 22.4868 19.1775 23.0256 19.1775H25.1809C25.9891 19.1775 26.2585 20.1315 25.7197 20.9493L18.8497 30.7623C18.5803 31.1711 17.9068 31.0348 18.0415 30.4897L19.2538 23.9477C19.3886 23.2662 18.9844 22.7211 18.3109 22.7211L16.9639 22.8574C16.425 22.9937 15.8862 22.4485 16.0209 21.767L16.9639 12.2266C17.0986 11.5452 17.6374 11 18.1762 11Z" fill="#FFA800"/>
        </svg>

     );
}
 
export default Icon_Electricity;