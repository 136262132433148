import GoogleMapReact from 'google-map-react'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { createNewDevice, editDevice, useGetBrand } from '../../api/userInfo'
import { GOOGlE_API_KEY } from '../../shared/config'

const Marker = ({ text }) => {
  return (
    <div className="flex flex-row items-center w-56">
      <i className="text-3xl text-red-500 ri-map-pin-fill"></i>
      <span className="w-full text-sm font-bold"> {text}</span>
    </div>
  )
}

const CreateNewDevice = ({ close, status, info, deviceList, getPlace, getDeviceType }) => {
  const {register,handleSubmit,formState: { errors }} = useForm()
  const { brandList } = useGetBrand()
  const [selectedBrand, setSelectedBrand] = useState()
  const [selectedModel, setSelectedModel] = useState()
  const modelFilter = brandList?.filter((brand) => {
    return brand.id == info?.model
  })?.[0]?.model
  const [models, setModels] = useState(modelFilter)


  const [showOption, setShowOption] = useState('')

  const currentType = status === 'add' ? 'add device' : 'edit device'
  const deviceId = info?.id
  const [errorMsg, setErrorMsg] = useState(undefined)
  const [successMsg, setSuccessMsg] = useState(undefined)
  const [selectedBuildingId, setSelectedBuildingId] = useState(info?.building)
  const [selectedFloorId, setSelectedFloorId] = useState(info?.floor)
  const [selectedPlaceId, setSelectedPlaceId] = useState(info?.place)
  const floorFilter = getPlace?.building?.filter((building) => {return building.building_id == info?.building})?.[0]?.floor
  const placeFilter = floorFilter?.filter((x) => {return x.floor_id == info?.floor})?.[0]?.place
  const [floors, setFloors] = useState(floorFilter)
  const [places, setPlaces] = useState(placeFilter)
  const [inputList, setInputList] = useState([{}])

  const [formDataCreate, setFormDataCreate] = useState({
    // external_id: '',
    name: '',
    type: '',
    physical_type: '',
    brand: '',
    model: '',
    measurement: '',
    place: ''
  })

  useEffect(() => {
    if (status == 'edit') {
      setFormDataCreate({
        // external_id: info?.external_id,
        name: info?.name,
        type: info?.type,
        physical_type: info?.physical_type,
        brand: info?.brand,
        model: brandList[0]?.model[0]?.id,
        measurement: info?.measurement,
        place: getPlace?.building[0]?.floor[0]?.place[0]?.place_id
      })
      setSelectedBuildingId(info?.building)
      setSelectedFloorId(info?.floor)
      setSelectedPlaceId(info?.place)
      const floorFilter = getPlace?.building?.filter((building) => {
        return building.building_id == info?.building
      })?.[0]?.floor
      const placeFilter = floorFilter?.filter((x) => {
        return x.floor_id == info?.floor
      })?.[0]?.place
      setFloors(floorFilter)
      setPlaces(placeFilter)
      setSelectedBrand(info?.brand)
      setSelectedModel(info?.model)
      setInputList(JSON.parse(info?.property))
      const modelFilter = brandList?.filter((brand) => {
        return brand.id == info?.model
      })?.[0]?.model
      setModels(modelFilter)
    }else{
      setInputList([{}])
    }
  }, [status])

 
  const handleInputChange = (e, index) => {
    const { name, value } = e.target
    const list = [...inputList]
    list[index]['type'] = 'Float32'
    list[index]['address'] = index
    list[index][name] = value

    setInputList(list)
  }
  const handleRemoveClick = (index) => {
    const list = [...inputList]
    list.splice(index, 1)
    setInputList(list)
  }
  const handleAddClick = () => {
    setInputList([...inputList, { }])
  }

  const onInput = (field) => (e) => {
    setFormDataCreate({
      ...formDataCreate,
      [field]: e.target.value
    })
  }

  useEffect(() => {
    const typeOption = getDeviceType?.filter((option) => {
      return option.name === formDataCreate?.physical_type
    })?.[0]?.description
    setShowOption(typeOption)
  }, [formDataCreate])

  const handleSelectBuilding = (value) => {
    setSelectedBuildingId(value)
    const floor_list = getPlace?.building?.filter((building) => {
      return building.building_id == value
    })?.[0]?.floor
    setFloors(floor_list)
    setSelectedFloorId(null)
    setSelectedPlaceId(null)
  }
  const handleSelectFloor = (value) => {
    setSelectedFloorId(value)
    const places = floors?.filter((x) => {
      return x.floor_id == value
    })?.[0]
    setPlaces(places?.place)
    setSelectedPlaceId(null)
  }
  const handleSelectPlace = (value) => {
    setSelectedPlaceId(value)
  }

  const handleSelectBrand = (value) => {
    setSelectedBrand(value)
    const model_list = brandList?.filter((brand) => {return brand.id == value})?.[0]?.model
    setModels(model_list)
    setSelectedModel(null)
  }

  const handleSelectModel = (value) => {
    setSelectedModel(value)
  }

  // reset data after close
  const handleClose = () => {
    document.getElementById("add-new-device-form").reset();
    setFormDataCreate({
      name: null,
      type: null,
      physical_type: null,
      brand: null,
      model: null,
      measurement: null,
      place: null
    })
    close()
    setInputList([{  }])
    setSelectedBuildingId(null)
    setSelectedFloorId(null)
    setSelectedPlaceId(null)
    setFloors([])
    setSelectedBrand(null)
    setSelectedModel(null)
    setModels([])
  }

  const handleCreateDevice = async (e) => {
    e.preventDefault()
    const type_id = getDeviceType?.filter((typeList) => typeList?.name === formDataCreate?.physical_type)?.[0]?.id

    const _brand =brandList?.filter((brand) =>  brand?.name == selectedBrand)?.[0]?.id
    const model_id = models?.filter((model)=> model?.name == selectedModel)?.[0]?.id

    // console.log(_brand,selectedBrand,"_brand?.id")
    // console.log(_brand?.model,selectedModel,"model_id")

    const dataToSend = {
      ...formDataCreate,
      'property': JSON.stringify(inputList),
      'type': formDataCreate?.physical_type,
      'device_type': formDataCreate?.physical_type,
      'physical_type': type_id,
      'place': selectedPlaceId,
      // brand: selectedBrand,
      'model': (status==='edit')?model_id:selectedModel
    }

    if (status == 'add') {
      try {
        const { data, error } = await createNewDevice(dataToSend)
        if (data !== undefined) {
          setErrorMsg(undefined)
          setSuccessMsg('You added the device!')
          await new Promise((resolve) => setTimeout(resolve, 1500))
          close()
        } else if (error) {
          setSuccessMsg(undefined)
          setErrorMsg('please try again!')
        }
      } catch (error) {
        setSuccessMsg(undefined)
        setErrorMsg(error)
      }
    } else if (status == 'edit') {
      try {
        const { data, error } = await editDevice(deviceId, dataToSend)
        if (data !== undefined) {
          setErrorMsg(undefined)
          setSuccessMsg('device was updated!')
          await new Promise((resolve) => setTimeout(resolve, 1500))
          close()
        } else if (error) {
          setSuccessMsg(undefined)
          setErrorMsg('device could not be updated, please try again!')
        }
      } catch (error) {
        setSuccessMsg(undefined)
        setErrorMsg(error)
      }
    }
  }

  return (
    <>
      <section
        className={`${
          status ? 'block' : 'hidden'
        } bg-black bg-opacity-60 fixed z-1 left-0 top-0 w-[100%] h-[100%] overflow-hidden flex items-center justify-center`}
      >
        <div className="w-[90%] xl:w-[60%] p-8 mt-12 overflow-auto bg-white rounded-xl h-[80%]">
          <h1 className="mb-4 text-lg font-bold">
            {info !== undefined ? 'Edit devices' : 'New Device'}
          </h1>
          <div className="-mt-2">
            {successMsg && <span className="py-2 capitalize text-vekin">{successMsg}</span>}
            {errorMsg && <span className="py-2 capitalize text-danger">{errorMsg}</span>}
          </div>

          <form onSubmit={handleCreateDevice} id="add-new-device-form">
              <h3 className="mt-2 text-secondary">1. Device information</h3>
            <div className="grid grid-cols-3 gap-4 mt-2">

              <div className="relative w-full mt-0">
                <select
                  className="mt-0 input-server peer"
                  onChange={(e) => handleSelectBrand(e?.target?.value)}
                  name="brand"
                >
                  <option value={null}>Select Brand</option>
                  {brandList?.map((brand) => (
                    <option key={brand?.id+brand?.name} value={brand?.id} selected={brand?.name == selectedBrand}>
                      {brand.name}
                    </option>
                  ))}
                </select>
                <label className="absolute label-input text-md left-2">Brand</label>
                <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
              </div>

              <div className="relative w-full mt-0">
                <input
                  autoComplete="off"
                  type="text"
                  name="name"
                  placeholder="Solar22-414038"
                  className="mt-0 input-server peer"
                  defaultValue={info?.name}
                  onChange={onInput('name')}
                />
                <label className="absolute label-input text-md">Device name</label>
              </div>

              <div className="relative w-full mt-0">
                <select
                  className="mt-0 input-server peer"
                  onChange={(e) => handleSelectModel(Number(e?.target?.value))}
                  name="model"
                >
                  <option value={null}>Select Model</option>
                  {models?.map((model) => (
                    <option key={model?.id+model?.name} value={model?.id} selected={model?.id == selectedModel}>
                      {model.name}
                    </option>
                  ))}
                </select>
                <label className="absolute label-input text-md">Device model</label>
                <i className="absolute flex justify-end text-xl font-bold top-3 right-4 text-vekin ri-arrow-down-s-line"></i>
              </div>
            </div>

            <div className="mt-4">
              <textarea
                id="message"
                rows="3"
                className="block p-2.5 w-full text-sm text-gray-900 rounded-md border border-gray-300"
                placeholder="Description"
              ></textarea>
            </div>

            
              <h3 className="mt-4 text-secondary">2. Device Details</h3>
              
              <div className="relative grid grid-cols-4 gap-4 mt-2 mb-8">
                <div className="relative ">
                  <select
                    className="input-server peer"
                    value={formDataCreate.physical_type}
                    defaultValue={status ? getDeviceType?.[0]?.id : ''}
                    onChange={onInput('physical_type')}
                    name="physical_type"
                  >
                    <option value={null}>Select Device Type</option>
                    {getDeviceType?.map((deviceType) => (
                      <option key={deviceType?.id+deviceType.name} defaultValue={deviceType?.id}>
                        {deviceType.name}
                      </option>
                    ))}
                  </select>
                  <label className="absolute label-input text-md">Device type</label>
                  <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
                  
                </div>
                <div className="relative w-full col-span-3">
                  <input
                    className="input-server peer"
                    required
                    name="measurement"
                    type="text"
                    defaultValue={info?.measurement}
                    onChange={onInput('measurement')}
                  />
                  <label className="absolute label-input text-md">measurement</label>
                </div>
                <span className="text-[12px] text-gray-500 absolute -bottom-5 w-full">{showOption}</span>
              </div>

              <div className="mt-2">
                {inputList?.map((item, index) => {
                  return (
                    <div className="grid grid-cols-8 gap-4 " key={index}>
                      {/* <div className="relative w-full">
                        <input
                          readOnly={true}
                          className="bg-gray-200 input-server peer"
                          placeholder="Float32"
                        />
                        <label className="absolute label-input text-md left-2">Type</label>
                      </div> */}

                      <div className="relative w-full col-span-3">
                        <input
                          className="input-server peer"
                          // value={item?.name}
                          defaultValue={item?.name}
                          name="name"
                          onChange={(e) => handleInputChange(e, index)}
                          placeholder='Field Name'
                        />
                        <label className="absolute label-input text-md ">Field Name</label>
                      </div>

                      <div className="relative w-full col-span-2">
                        <input
                          className="input-server peer"
                          // value={item?.unit}
                          name="unit"
                          onChange={(e) => handleInputChange(e, index)}
                          defaultValue={item?.unit}
                          placeholder="kWh"
                        />
                        <label className="absolute label-input text-md left-2">Field Unit</label>
                      </div>

                      <div className="relative w-full col-span-2 ">
                        <input
                          className="input-server peer"
                          // value={item?.field}
                          name="field"
                          onChange={(e) => handleInputChange(e, index)}
                          defaultValue={item?.field}
                          placeholder="_ac_energy"
                        />
                        <label className="absolute label-input text-md left-2">Field Value</label>
                      </div>

                      <div className="flex justify-start w-6 h-12 gap-6 my-1 ml-4 ">
                        {inputList.length - 1 === index && (
                          <button onClick={handleAddClick} className="px-4 border rounded-lg">
                            <i className="ri-add-fill text-vekin"></i>
                          </button>
                        )}
                        {inputList.length !== 1 && (
                          <button
                            className="px-5 text-3xl text-center border rounded-lg text-danger"
                            onClick={() => handleRemoveClick(index)}
                          >
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>

              <h3 className="mt-4 text-secondary">3. Device Location</h3>

              <div className="grid grid-cols-4 gap-6 mt-2">
                <div className="relative w-full col-span-2 ">
                  <div className="pt-5 text-gray-400 bg-gray-200 input-server peer">
                    {' '}
                    {deviceList?.name}
                  </div>
                  <label className="pt-2 ml-2 label-input text-md top-6">Site</label>
                </div>

                <div className="grid grid-flow-col col-span-2 grid-rows-1 gap-2">
                  <div className="relative w-full col-span-2">
                    <div className="pt-5 text-gray-400 bg-gray-200 input-server peer">
                      {' '}
                      {deviceList?.location?.lat}
                    </div>
                    <label className="pt-2 ml-1 label-input text-md top-6">Latitude</label>
                  </div>
                  <div className="relative w-full col-span-2">
                    <div className="pt-5 text-gray-400 bg-gray-200 input-server peer">
                      {' '}
                      {deviceList?.location?.lon}
                    </div>
                    <i className="absolute flex justify-end text-xl font-bold text-gray-400 top-3 right-4 ri-focus-3-line"></i>
                    <label className="absolute label-input text-md">Longitude</label>
                  </div>
                </div>
              </div>

           

            <div className="grid grid-cols-3 gap-6 my-2">
              <div className="relative w-full ">
                <select
                  className="input-server peer text-vekin"
                  onChange={(e) => handleSelectBuilding(e?.target?.value)}
                  name="building_name"
                >
                  <option value={null}>Select Building</option>
                  {getPlace?.building?.map((building) => (
                    <option
                      key={building?.building_name}
                      value={building?.building_id}
                      selected={building?.building_id == selectedBuildingId}
                      className="capitalize"
                    >
                      {building?.building_name.replace('_', ' ')}
                    </option>
                  ))}
                </select>
                <label className="absolute label-input text-md left-1">Building</label>
                <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
              </div>

              <div className="relative w-full">
                <select
                  className="input-server peer text-vekin"
                  onChange={(e) => handleSelectFloor(e?.target?.value)}
                >
                  <option value={null}>Select Floor</option>
                  {floors?.map((floor) => (
                    <option
                      key={floor?.floor_id+floor?.floor_name}
                      value={floor?.floor_id}
                      selected={floor?.floor_id == selectedFloorId}
                    >
                      {floor?.floor_name?.replace('_', ' ')}
                    </option>
                  ))}
                </select>
                <label className="absolute label-input text-md left-2">Floor</label>
                <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
              </div>

              <div className="relative w-full">
                <select
                  className="input-server peer text-vekin"
                  onChange={(e) => handleSelectPlace(Number(e?.target?.value))}
                >
                  <option value={null}> Select Place</option>
                  {places?.map((place) => (
                    <option
                      key={place?.place_id+place?.place_name}
                      value={place?.place_id}
                      selected={place?.place_id == selectedPlaceId}
                    >
                      {place?.place_name?.replace('_', ' ')}
                    </option>
                  ))}
                </select>
                <label className="absolute label-input text-md left-2">Place</label>
                <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
              </div>
            </div>

            <div className="w-full h-56 mt-4">
              <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGlE_API_KEY }}
                center={{ lat: deviceList?.location?.lat, lng: deviceList?.location?.lon }}
                defaultZoom={15}
              >
                <Marker
                  lat={deviceList?.location?.lat}
                  lng={deviceList?.location?.lon}
                  text={deviceList?.name}
                  color="red"
                />
              </GoogleMapReact>
            </div>

            <div className="flex justify-between mt-14">
              <button
                type="button"
                onClick={() => handleClose(currentType)}
                className={` w-1/4 py-2 text-center border border-gray-300 rounded-md left-24 text-vekin hover:shadow-md`}
              >
                Cancel
              </button>
              <button
                className="w-1/4 py-2 text-center text-white rounded-md right-28 bg-vekin hover:shadow-md"
                type="submit"
              >
                {info !== undefined ? 'Save' : 'Add'}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}

export default CreateNewDevice
