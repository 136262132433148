const Icon_Temperature = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.0073 52C17.8698 52 8 42.1334 8 30C8 17.8666 17.8698 8 30.0073 8C42.1447 8 52 17.8666 52 30C52 42.1334 42.1156 52 30.0073 52ZM30.0073 10.6156C19.3089 10.6156 10.6019 19.3197 10.6019 30C10.6019 40.6803 19.2944 49.3844 29.9927 49.3844C40.6766 49.3844 49.3835 40.6949 49.3835 30C49.3835 19.3052 40.6766 10.6156 30.0073 10.6156ZM31.7356 31.3594V19.2643C31.7356 17.1476 30.0663 15.3333 27.906 15.3333C25.8439 15.3333 24.0764 17.0468 24.0764 19.2643V31.2586C24.0764 32.1658 23.7818 33.1737 23.1926 33.8793C22.3089 34.988 21.7197 36.2983 21.6215 37.911C21.5233 41.4388 24.371 44.5633 27.8078 44.6641C31.3428 44.7649 34.1905 41.8419 34.1905 38.2134C34.1905 36.4999 33.6013 34.988 32.5212 33.8793C32.0302 33.0729 31.7356 32.1658 31.7356 31.3594ZM26.7277 19.4659C26.7277 18.8611 27.2186 18.3571 27.8078 18.3571C28.397 18.3571 28.8879 18.8611 28.8879 19.4659V31.7626C28.4952 31.6618 28.1024 31.6618 27.8078 31.6618C27.5132 31.6618 27.0222 31.6618 26.7277 31.7626V19.4659ZM27.906 41.0356C26.2367 41.0356 24.8619 39.6245 24.8619 37.911C24.8619 36.1975 26.2367 34.7864 27.906 34.7864C29.5753 34.7864 30.95 36.1975 30.95 37.911C30.95 39.6245 29.5753 41.0356 27.906 41.0356ZM38.8153 18.4762C39.5394 18.4762 40.1429 19.158 40.1429 19.9762C40.1429 20.7944 39.5394 21.3398 38.8153 21.4762H33.1429V18.4762H38.8153ZM37.1429 24.1667C37.1429 23.3485 36.5714 22.6667 35.8857 22.6667H33.1429V25.6667H35.8857C36.5714 25.6667 37.1429 24.9848 37.1429 24.1667ZM33.1429 26.8571H38.8153C39.5394 26.8571 40.1429 27.539 40.1429 28.3571C40.1429 29.1753 39.5394 29.8571 38.8153 29.8571H33.1429V26.8571Z" fill="#79C8B0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.0073 52C17.8698 52 8 42.1334 8 30C8 17.8666 17.8698 8 30.0073 8C42.1447 8 52 17.8666 52 30C52 42.1334 42.1156 52 30.0073 52ZM30.0073 10.6156C19.3089 10.6156 10.6019 19.3197 10.6019 30C10.6019 40.6803 19.2944 49.3844 29.9927 49.3844C40.6766 49.3844 49.3835 40.6949 49.3835 30C49.3835 19.3052 40.6766 10.6156 30.0073 10.6156ZM31.7356 31.3594V19.2643C31.7356 17.1476 30.0663 15.3333 27.906 15.3333C25.8439 15.3333 24.0764 17.0468 24.0764 19.2643V31.2586C24.0764 32.1658 23.7818 33.1737 23.1926 33.8793C22.3089 34.988 21.7197 36.2983 21.6215 37.911C21.5233 41.4388 24.371 44.5633 27.8078 44.6641C31.3428 44.7649 34.1905 41.8419 34.1905 38.2134C34.1905 36.4999 33.6013 34.988 32.5212 33.8793C32.0302 33.0729 31.7356 32.1658 31.7356 31.3594ZM26.7277 19.4659C26.7277 18.8611 27.2186 18.3571 27.8078 18.3571C28.397 18.3571 28.8879 18.8611 28.8879 19.4659V31.7626C28.4952 31.6618 28.1024 31.6618 27.8078 31.6618C27.5132 31.6618 27.0222 31.6618 26.7277 31.7626V19.4659ZM27.906 41.0356C26.2367 41.0356 24.8619 39.6245 24.8619 37.911C24.8619 36.1975 26.2367 34.7864 27.906 34.7864C29.5753 34.7864 30.95 36.1975 30.95 37.911C30.95 39.6245 29.5753 41.0356 27.906 41.0356ZM38.8153 18.4762C39.5394 18.4762 40.1429 19.158 40.1429 19.9762C40.1429 20.7944 39.5394 21.3398 38.8153 21.4762H33.1429V18.4762H38.8153ZM37.1429 24.1667C37.1429 23.3485 36.5714 22.6667 35.8857 22.6667H33.1429V25.6667H35.8857C36.5714 25.6667 37.1429 24.9848 37.1429 24.1667ZM33.1429 26.8571H38.8153C39.5394 26.8571 40.1429 27.539 40.1429 28.3571C40.1429 29.1753 39.5394 29.8571 38.8153 29.8571H33.1429V26.8571Z" fill="url(#paint0_linear_3445_62603)"/>
<defs>
<linearGradient id="paint0_linear_3445_62603" x1="8.52381" y1="26.3333" x2="52.7437" y2="26.7846" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Temperature;