const Icon_Transport = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#F2F8F6"/>
<path d="M30.1723 14.9869L29.8573 14.5986C29.8338 14.6177 29.8121 14.6388 29.7924 14.6618L26.517 18.4896H16.8142C16.6865 18.4896 16.5636 18.5385 16.4708 18.6263L14.7063 20.2941C14.5672 20.4256 14.5154 20.6249 14.5728 20.8075C14.6302 20.99 14.7868 21.1238 14.9761 21.152L23.0553 22.3555L17.9495 28.5069H15.7348C15.5824 28.5069 15.4383 28.5764 15.3435 28.6957L14.0253 30.3531C13.9058 30.5034 13.8829 30.7087 13.9663 30.8816C14.0497 31.0545 14.2247 31.1644 14.4166 31.1644H17.3905L17.6869 34.0511C17.7065 34.2418 17.8335 34.4046 18.0137 34.47C18.194 34.5354 18.3958 34.492 18.5331 34.3582L19.9032 33.0239C19.9998 32.9298 20.0544 32.8006 20.0544 32.6657V30.2003L25.4368 24.6674L27.2873 32.777C27.3253 32.9437 27.4458 33.0794 27.6069 33.1367C27.7679 33.1941 27.9471 33.1652 28.082 33.0602L29.7946 31.7259C29.9251 31.6242 29.9969 31.4646 29.9863 31.2995L29.3169 20.8696L32.9014 17.6962C32.9186 17.6809 32.9348 17.6644 32.9498 17.6469L32.5699 17.3218C32.9498 17.6469 32.9499 17.6468 32.95 17.6467L32.9503 17.6464L32.9508 17.6457L32.9525 17.6438L32.9575 17.6378L32.9746 17.6173C32.989 17.5999 33.009 17.5752 33.0337 17.544C33.083 17.4817 33.1511 17.3927 33.229 17.2832C33.3835 17.0658 33.5824 16.759 33.7487 16.4121C33.9124 16.0704 34.0602 15.6576 34.0808 15.2354C34.1022 14.7984 33.9859 14.3336 33.6038 13.9615C33.2237 13.5914 32.7544 13.4821 32.3166 13.5024C31.8904 13.5221 31.4713 13.6639 31.1214 13.8229C30.7669 13.984 30.4529 14.1771 30.23 14.3273C30.1177 14.403 30.0265 14.4692 29.9626 14.5172C29.9307 14.5412 29.9054 14.5607 29.8876 14.5746L29.8666 14.5912L29.8604 14.5961L29.8584 14.5977L29.8578 14.5982L29.8575 14.5985C29.8574 14.5985 29.8573 14.5986 30.1723 14.9869Z" fill="#1FA37C" stroke="#1FA37C" stroke-linecap="square" stroke-linejoin="round"/>
</svg>

     );
}
 
export default Icon_Transport;