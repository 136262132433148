import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import main_en from './lang/en/main.json';
import main_th from './lang/th/main.json';
import login_en from './lang/en/login.json';
import login_th from './lang/th/login.json';

const resources = {
  en: {
    main: main_en,
    login: login_en,
  },
  th: {
    main: main_th,
    login: login_th,
  }
};

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('i18nextLng') || 'en',
    fallbackLng: localStorage.getItem('i18nextLng') || 'en',
    ns: ['main'],
    interpolation: {
      escapeValue: false
    }
  });

export default i18next;