const Icon_Information = () => {
  return (
    <svg width="44" height="48" viewBox="0 0 44 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.0189 45.5033C17.765 45.507 13.6054 44.2489 10.0667 41.8881C6.5279 39.5273 3.76891 36.1699 2.1387 32.2406C0.508482 28.3114 0.0803386 23.9869 0.908429 19.8143C1.73652 15.6417 3.7836 11.8085 6.79075 8.79961C9.79789 5.79072 13.6299 3.74139 17.8021 2.91087C21.9742 2.08035 26.2989 2.50598 30.2291 4.1339C34.1592 5.76183 37.5182 8.51889 39.8811 12.0563C42.244 15.5937 43.5045 19.7525 43.5033 24.0064C43.4967 29.7036 41.2314 35.1656 37.2041 39.1953C33.1768 43.225 27.7161 45.4934 22.0189 45.5033ZM22.0189 4.3786C18.1361 4.37489 14.3394 5.52288 11.1092 7.67736C7.87894 9.83184 5.36027 12.896 3.87181 16.4822C2.38335 20.0684 1.99196 24.0155 2.74719 27.8241C3.50242 31.6328 5.37031 35.1319 8.11456 37.8788C10.8588 40.6257 14.3561 42.4969 18.1641 43.2558C21.972 44.0146 25.9195 43.627 29.5071 42.142C33.0947 40.657 36.1613 38.1412 38.3188 34.913C40.4764 31.6849 41.628 27.8893 41.628 24.0064C41.6214 18.8061 39.5539 13.8203 35.8785 10.1414C32.203 6.46241 27.2193 4.39017 22.0189 4.3786ZM23.7629 18.5182C23.7629 17.555 22.9821 16.7742 22.0189 16.7742C21.0558 16.7742 20.2749 17.555 20.2749 18.5182V35.0956C20.2749 36.0588 21.0558 36.8396 22.0189 36.8396C22.9821 36.8396 23.7629 36.0588 23.7629 35.0956V18.5182ZM23.7567 12.9111C23.7567 13.8743 22.9759 14.6551 22.0127 14.6551C21.0495 14.6551 20.2687 13.8743 20.2687 12.9111C20.2687 11.9479 21.0495 11.1671 22.0127 11.1671C22.9759 11.1671 23.7567 11.9479 23.7567 12.9111Z" fill="#A5DACB"/>
</svg>

  )
}

export default Icon_Information
