const Icon_Coal = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="white"/>
<path d="M23.5344 35.0991H18.7945C16.0859 35.0991 13.8853 32.9694 13.8853 30.3482V22.8123H34.1146V24.7782C34.1146 29.1195 31.0675 29.5291 29.7979 29.5291H29.4593C28.1897 29.5291 27.0047 29.5291 25.7351 30.6758C24.8887 31.413 24.804 33.2151 24.8887 33.7885L24.9733 35.0991H23.5344ZM16.3399 25.2697V30.3482C16.3399 31.6588 17.4402 32.6417 18.7098 32.6417H22.2648C22.3494 31.5769 22.7726 30.0206 23.873 28.9557C25.9044 27.0717 27.9358 27.0717 29.3747 27.1537H29.7133C30.5597 27.1537 31.3215 26.9898 31.4907 25.3516H16.3399V25.2697Z" fill="url(#paint0_linear_1984_63968)"/>
<path d="M29.1208 36.0001C30.7569 36.0001 32.0833 34.7165 32.0833 33.1332C32.0833 31.5499 30.7569 30.2663 29.1208 30.2663C27.4847 30.2663 26.1583 31.5499 26.1583 33.1332C26.1583 34.7165 27.4847 36.0001 29.1208 36.0001Z" fill="url(#paint1_linear_1984_63968)"/>
<path d="M21.2491 21.5017L18.7945 19.1263L16.3399 21.4198H13.8853L18.7945 16.669L23.7883 21.5837L21.2491 21.5017Z" fill="url(#paint2_linear_1984_63968)"/>
<path d="M31.4907 21.5017L29.0361 19.1263L26.5814 21.4198H24.1268L29.0361 16.669L34.0299 21.5837L31.4907 21.5017Z" fill="url(#paint3_linear_1984_63968)"/>
<path d="M26.7508 17.3242L23.9576 14.7031L21.2491 17.3242L19.8102 15.9317L23.9576 12L28.1051 16.0137L26.7508 17.3242Z" fill="url(#paint4_linear_1984_63968)"/>
<defs>
<linearGradient id="paint0_linear_1984_63968" x1="12.5487" y1="16.5429" x2="30.6751" y2="35.2737" gradientUnits="userSpaceOnUse">
<stop stop-color="#F5C76D"/>
<stop offset="1" stop-color="#4D2800"/>
</linearGradient>
<linearGradient id="paint1_linear_1984_63968" x1="12.2889" y1="16.7944" x2="30.4153" y2="35.525" gradientUnits="userSpaceOnUse">
<stop stop-color="#F5C76D"/>
<stop offset="1" stop-color="#4D2800"/>
</linearGradient>
<linearGradient id="paint2_linear_1984_63968" x1="13.1357" y1="15.9749" x2="31.2622" y2="34.7056" gradientUnits="userSpaceOnUse">
<stop stop-color="#F5C76D"/>
<stop offset="1" stop-color="#4D2800"/>
</linearGradient>
<linearGradient id="paint3_linear_1984_63968" x1="18.2544" y1="11.0212" x2="36.3808" y2="29.7519" gradientUnits="userSpaceOnUse">
<stop stop-color="#F5C76D"/>
<stop offset="1" stop-color="#4D2800"/>
</linearGradient>
<linearGradient id="paint4_linear_1984_63968" x1="18.5547" y1="10.7307" x2="36.6811" y2="29.4613" gradientUnits="userSpaceOnUse">
<stop stop-color="#F5C76D"/>
<stop offset="1" stop-color="#4D2800"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Coal;