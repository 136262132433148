import { updateWidgetLayout } from "../../api/dashboard";
import { removeDuplicates } from "../../utils/removeDuplicates";
import { formateWidgetData } from "../../utils/widgetSetup/formatWidgetData";
import { useNavigate } from "react-router-dom";
import Button from "./buttons";


export const SaveConfirmationModal = ({dashPageUrl, setIsUpdated, saveConfirmation, setSaveConfirmation, widgetLayout, widgetData, removedWidgetList, dashboardId, setUpdateLayoutStatus, hasError}) => {
    // function to save the layout (when a user rearranges widgets)
    const navigate = useNavigate();

    const onLayoutSave = () => {
        // updating the position
        widgetLayout?.forEach((wid) => {
            const index = widgetData?.findIndex(e => e.i == wid.i);
            if (index > -1) {
              ['x','y'].forEach(prop => {
                widgetData[index][prop] = wid[prop]
              });
            }
          });

        const removed_widgets = removeDuplicates(formateWidgetData(removedWidgetList))

        const updatedLayout = {
            'id':Number(dashboardId),
             'plant': Number(localStorage.getItem("plantId")),
            'widget_map':formateWidgetData(widgetData),
            'removed_widgets':removed_widgets
        }

        try {
            const {error} = updateWidgetLayout(updatedLayout,Number(dashboardId) )
            if(error==undefined){
                setUpdateLayoutStatus(["success","the changes you made were saved"])
                setIsUpdated(true)
                setSaveConfirmation(false)
                navigate(`${dashPageUrl}/${dashboardId}`)

                // window.location.reload();
            }else{
                setUpdateLayoutStatus(["error","Could not save, Please try again!"])
            }
        } catch (error) {
            setUpdateLayoutStatus(["error","Could not save, Please try again!"])
        }
    };

    //format data in proper format to send to API
    // const formateWidgetData =(data_)=>{
    //   return  data_?.map((elm) => ({grid: {'data': elm?.dataURL,'h': elm?.h,'w': elm?.w,'x': elm?.x,'y': elm?.y},name: elm?.name,id: elm?.id}))
    //  }


    return (
        <>
        {saveConfirmation &&
        <div className='absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden bg-black/70'>
            <div className='p-4 bg-white rounded-lg h-50 w-96'>
                <p className='py-2 text-lg font-semibold text-primary'>Save Confirmation</p>
                <p className='py-2 text-sm text-center'>{hasError? 'The widgets you did not setup, will not be saved': 'Do you want to save the changes you made'}</p>
                <div className='flex justify-center gap-8 pt-4'>
                    <button onClick={onLayoutSave} className='p-2 px-4 text-white rounded-lg bg-vekin hover:bg-vekin/80'>Yes, Save</button>
                    <button className='p-2 px-4 border-2 rounded-lg border-vekin/90 hover:border-vekin/60 text-vekin' type='button' onClick={()=>setSaveConfirmation(false)} >Cancel</button>
                </div>
            </div>
        </div>
        }

        </>
     );
}


export const DeleteConfirmationModal = ({setIsUpdated, deleteConfirmation, widgetToRemove, setDeleteConfirmation, removedWidgetList, widgetData, setWidgetData, setRemovedWidgetList, widgetLayout, dashboardId, setUpdateLayoutStatus}) => {


    //TODO: this is not the proper way to delete widgets --> waiting for the api to be created
    const removeWidget =  () =>{

        setWidgetData(widgetData.filter(el => el.i != widgetToRemove.i ))
        const removed_widgets = formateWidgetData([widgetToRemove])
        const updatedData = widgetData.filter(el => el.i != widgetToRemove.i ) //remove the widget from widgetData
        // updating the position
        widgetLayout?.forEach((wid) => {
            const index = updatedData?.findIndex(e => e.i == wid.i);
            if (index > -1) {
              ['x','y'].forEach(prop => {
                updatedData[index][prop] = wid[prop]
              });
            }
        });

        const updatedLayout = {
            'id':Number(dashboardId),
            'plant': Number(localStorage.getItem("plantId")),
            'widget_map':formateWidgetData(updatedData),
            'removed_widgets':removed_widgets
        }

        try {
            const {error} =  updateWidgetLayout(updatedLayout,Number(dashboardId) )
            if(error==undefined){
                setUpdateLayoutStatus(["success",`${removed_widgets?.[0]?.name} was deleted`])
                setIsUpdated(true)
                setDeleteConfirmation(false)

                // window.location.reload();
            }else{
                setUpdateLayoutStatus(["error",`Could not delete the ${removed_widgets[0]?.name} , Please try again!`])
                setWidgetData([...widgetData, widgetToRemove])
                setDeleteConfirmation(false)
            }
        } catch (error) {
                setUpdateLayoutStatus(["error",`Could not delete the ${removed_widgets[0]?.name} , Please try again!`])
                setWidgetData([...widgetData, widgetToRemove])
                setDeleteConfirmation(false)
        }
    };
    return (
        <>
        {deleteConfirmation &&
        <div className='absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden bg-black/70'>
            <div className='p-4 bg-white rounded-lg h-50 w-[30.5rem] backdrop-filter backdrop-blur-md'>
                <p className='inline-flex items-center gap-2 py-2 text-lg font-semibold text-primary'> <i class="ri-delete-bin-line text-current"></i>Delete Confirmation</p>
                <hr/>
                <p className='py-2 text-sm text-gray-700'>Are you certain you want to delete the <span className="italic font-semibold">{widgetToRemove?.name}</span>? By confirming, the widget will be  removed from your dashboard.</p>
                <div className='flex justify-around gap-10 pt-4'>
                    <Button danger type="button" onClick={()=>removeWidget()}><i class="ri-delete-bin-line text-current group-hover:animate-bounce"></i> Delete</Button>
                    <Button secondary type='button' onClick={()=>setDeleteConfirmation(false)}><i class="ri-close-fill"></i>Cancel</Button>
                
                
                
                </div>
            </div>
        </div>
        }

        </>
     );
}

// export const RemoveDeviceConfirmationModal = ({setIsUpdated,removeDeviceConfirmation, deviceToRemove, setRemoveDeviceConfirmation,filteredDevices, setFilteredDevices,removedDeviceList, setRemovedDeviceList}) => {
//   const removeDevice =() =>{
//     setIsUpdated(true)
//     setFilteredDevices(filteredDevices.filter(device => device.id != deviceToRemove.id))
//     setRemovedDeviceList([...removedDeviceList,deviceToRemove])
//     setRemoveDeviceConfirmation(false)
//   }
//   return (
//       <>
//       {removeDeviceConfirmation &&
//       <div className='absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden bg-black/70'>
//           <div className='p-4 bg-white rounded-lg h-50 w-96'>
//               <p className='py-2 text-lg font-semibold text-primary'>Remove Confirmation</p>
//               <p className='py-2 text-sm text-center'>Are you sure you want to remove the {deviceToRemove?.name}?</p>
//               <div className='flex justify-center gap-8 pt-4'>
//                   <button onClick={removeDevice} className='p-2 px-4 text-white bg-red-600 rounded-lg hover:bg-red-600/80'>Remove</button>
//                   <button className='p-2 px-4 text-red-400 border-2 rounded-lg border-black-200 hover:border-red-400/80' type='button' onClick={()=>setRemoveDeviceConfirmation(false)} >Cancel</button>
//               </div>
//           </div>
//       </div>
//       }

//       </>
//    );
// }

// export const RemoveServerConfirmationModal = ({setIsUpdated,removeServerConfirmation, serverToRemove, setRemoveServerConfirmation,deviceDetailsList, setDeviceDetailsList,removedServer, setRemovedServer}) => {
//   const removeServer =() =>{
//     setIsUpdated(true)
//     setDeviceDetailsList(deviceDetailsList.server.filter(server => server.name != serverToRemove.name))
//     setRemovedServer([...removedServer,serverToRemove])
//     setRemoveServerConfirmation(false)
//   }
// console.log(serverToRemove,"serverToRemove");
//   return (
//       <>
//       {removeServerConfirmation &&
//       <div className='absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden bg-black/70'>
//           <div className='p-4 bg-white rounded-lg h-50 w-96'>
//               <p className='py-2 text-lg font-semibold text-primary'>Remove Confirmation</p>
//               <p className='py-2 text-sm text-center'>Are you sure you want to remove the {serverToRemove?.[0]?.name}?</p>
//               <div className='flex justify-center gap-8 pt-4'>
//                   <button onClick={removeServer} className='p-2 px-4 text-white bg-red-600 rounded-lg hover:bg-red-600/80'>Remove</button>
//                   <button className='p-2 px-4 text-red-400 border-2 rounded-lg border-black-200 hover:border-red-400/80' type='button' onClick={()=>setRemoveServerConfirmation(false)} >Cancel</button>
//               </div>
//           </div>
//       </div>
//       }

//       </>
//    );
// }
