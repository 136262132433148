const MsSql_Icon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 12.7059C18.6274 12.7059 24 11.4417 24 9.88232V14.1176C24 15.677 18.6274 16.9412 12 16.9412C5.37258 16.9412 0 15.677 0 14.1176V14.1176V9.88233C2.24522e-05 11.4417 5.3726 12.7059 12 12.7059Z" fill="#6CCCDF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 12.7059C18.6274 12.7059 24 11.4417 24 9.88232V14.1176C24 15.677 18.6274 16.9412 12 16.9412C5.37258 16.9412 0 15.677 0 14.1176V14.1176V9.88233C2.24522e-05 11.4417 5.3726 12.7059 12 12.7059Z" fill="url(#paint0_linear_6429_3675)" fill-opacity="0.7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.9414V21.1767C0 22.7361 5.37258 24.0002 12 24.0002C18.6274 24.0002 24 22.7361 24 21.1767V16.9414C24 18.5008 18.6274 19.7649 12 19.7649C5.37262 19.7649 5.7828e-05 18.5008 0 16.9414Z" fill="#6CCCDF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.9414V21.1767C0 22.7361 5.37258 24.0002 12 24.0002C18.6274 24.0002 24 22.7361 24 21.1767V16.9414C24 18.5008 18.6274 19.7649 12 19.7649C5.37262 19.7649 5.7828e-05 18.5008 0 16.9414Z" fill="url(#paint1_linear_6429_3675)" fill-opacity="0.7"/>
<ellipse cx="12" cy="2.76923" rx="12" ry="2.76923" fill="#6CCCDF"/>
<ellipse cx="12" cy="2.76923" rx="12" ry="2.76923" fill="url(#paint2_linear_6429_3675)" fill-opacity="0.7"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24 2.82373H0V7.05902C0 8.61842 5.37258 9.88256 12 9.88256C18.6274 9.88256 24 8.61842 24 7.05903V2.82373Z" fill="#6CCCDF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24 2.82373H0V7.05902C0 8.61842 5.37258 9.88256 12 9.88256C18.6274 9.88256 24 8.61842 24 7.05903V2.82373Z" fill="url(#paint3_linear_6429_3675)" fill-opacity="0.7"/>
<defs>
<linearGradient id="paint0_linear_6429_3675" x1="1.71429" y1="9.2941" x2="23.8801" y2="11.5909" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_6429_3675" x1="1.71429" y1="16.3532" x2="23.8801" y2="18.65" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint2_linear_6429_3675" x1="1.71428" y1="-0.461533" x2="23.7341" y2="2.44647" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint3_linear_6429_3675" x1="1.71429" y1="2.2355" x2="23.8801" y2="4.5323" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
</defs>
</svg>

    )
}

export default MsSql_Icon;