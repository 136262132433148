import React, { useEffect, useState } from 'react'
import { createNewServer, editServer, useGetDevice, useGetServer } from '../../api/userInfo'
import IconVisibility from "../../assets/images/ic_visibility.svg";
import IconVisibilityOff from "../../assets/images/ic_visibility_off.svg";

const CreateNewServer = ({ closeAddServer, isServer, isEditServer }) => {
  // const {register,handleSubmit,formState: { errors },control,setValue} = useForm({ mode: 'onBlur' });
  const { deviceList, isloadingDeviceList } = useGetDevice(localStorage.getItem('plantId'))
  const [isChecked, setIsChecked] = useState('IoT Database')
  const currentState = isServer ? 'add server' : 'edit server'
  const { status, info } = isEditServer
  const [errorMsg, setErrorMsg] = useState(undefined)
  const [successMsg, setSuccessMsg] = useState(undefined)
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: undefined,
    organization: undefined,
    type: undefined,
    host: undefined,
    token: undefined,
    bucket: undefined
  })

  //console.log(info?.[0]);

  useEffect(() => {
    if (currentState === 'edit server' && isEditServer.status) {
      // set default values based on what was stored previously

      setFormData({
        name: info?.[0]?.name,
        organization: info?.[0]?.organization,
        type: info?.[0]?.type,
        host: info?.[0]?.host,
        token: info?.[0]?.token,
        bucket: info?.[0]?.bucket
      })
    }
  }, [isEditServer, currentState])

  const onOptionChange = (e) => {
    setIsChecked(e.target.value)
  }

  const onInput = (field) => (e) => {
    setFormData({
      ...formData,
      [field]: e.target.value
    })
  }

  const onClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const handleCreateServer = async (e) => {
    e.preventDefault()
    const plantId = Number(localStorage.getItem('plantId'))
    // TODO to be optimized

    if (isServer) {
      try {
        const { data, error } = await createNewServer(plantId, formData)
        if (data !== undefined) {
          setErrorMsg(undefined)
          setSuccessMsg('You added the server!')
          await new Promise((resolve) => setTimeout(resolve, 1500))
          closeAddServer(currentState)
        } else if (error) {
          setSuccessMsg(undefined)
          setErrorMsg('please try again!')
        }
      } catch (error) {
        setSuccessMsg(undefined)
        setErrorMsg(error)
      }
    } else if (isEditServer) {
      try {
        // let req_data = {'server': JSON.stringify(formData), 'name': info?.name}
        const { data, error } = await editServer(plantId, formData)
        //can pop up a confirm that the server has been edited
        if (data !== undefined) {
          setErrorMsg(undefined)
          setSuccessMsg('server was updated!')
          await new Promise((resolve) => setTimeout(resolve, 1500))
          closeAddServer(currentState)
        } else if (error) {
          setSuccessMsg(undefined)
          setErrorMsg('server could not be updated, please try again!')
        }
      } catch (error) {
        setSuccessMsg(undefined)
        setErrorMsg(error)
      }
      // window.location.reload(false)
    }
  }

  return (
    <section className={isServer || status ? 'block' : 'hidden'}>
      <div className="bg-black bg-opacity-60 fixed z-1 left-0 top-0 w-[100%] h-[100%] overflow-hidden flex items-center justify-center">
        <div className="p-8 mt-12 bg-white rounded-xl">
          <h1 className="mb-4 text-lg font-bold">
            {info !== undefined ? 'Edit Server' : 'New Server'}
          </h1>
          <div className="-mt-2">
            {successMsg && <span className="py-2 capitalize text-vekin">{successMsg}</span>}
            {errorMsg && <span className="py-2 capitalize text-danger">{errorMsg}</span>}
          </div>
          <div>
            <p>Server type</p>
            <fieldset className="grid grid-cols-4 gap-4 my-4">
              <legend className="sr-only">server_type</legend>

              <div className="flex items-center">
                <input
                  id="option-1"
                  type="radio"
                  name="server"
                  value="IoT Database"
                  className="w-6 h-6 accent-emerald-700"
                  checked={isChecked === 'IoT Database'}
                  onChange={onOptionChange}
                />
                <label
                  htmlFor="option-1"
                  className={`font-bold block ml-2 text-md ${
                    isChecked === 'IoT Database' ? 'text-vekin' : 'text-primary'
                  }`}
                >
                  IoT Database
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="option-2"
                  type="radio"
                  name="server"
                  value="MQTT"
                  className="w-6 h-6 accent-emerald-700"
                  checked={isChecked === 'MQTT'}
                  onChange={onOptionChange}
                />
                <label
                  htmlFor="option-2"
                  className={`font-bold block ml-2 text-md ${
                    isChecked === 'MQTT' ? 'text-vekin' : 'text-primary'
                  }`}
                >
                  MQTT
                </label>
              </div>
              <div className="flex items-center">
                <input
                  id="option-3"
                  type="radio"
                  name="server"
                  value="Huawei"
                  className="w-6 h-6 accent-emerald-700"
                  checked={isChecked === 'Huawei'}
                  onChange={onOptionChange}
                />
                <label
                  htmlFor="option-3"
                  className={`font-bold block ml-2 text-md ${
                    isChecked === 'Huawei' ? 'text-vekin' : 'text-primary'
                  }`}
                >
                  Huawei
                </label>
              </div>
            </fieldset>
          </div>

          <form
            // onSubmit={handleSubmit(onSubmit)}
            onSubmit={handleCreateServer}
            autoComplete="off"
          >
            <div
              className={`grid grid-cols-4 gap-4 ${
                isChecked === 'IoT Database' ? 'block' : 'hidden'
              }`}
            >
              <div className="relative w-full col-span-2 mb-4 ">
                <input
                  autoComplete="off"
                  type="text"
                  className="input-server peer"
                  // value={formData.name}
                  defaultValue={status ? info?.[0]?.name : ''}
                  onChange={onInput('name')}
                />
                <label className="absolute label-input text-md left-2">Name</label>
              </div>
              <div className="relative w-full col-span-2 mb-4 ">
                <input
                  autoComplete="off"
                  type="text"
                  className="input-server peer"
                  // value={formData.organization}
                  defaultValue={status ? info?.[0]?.organization : ''}
                  onChange={onInput('organization')}
                />
                <label className="absolute label-input text-md">Organization</label>
              </div>
            </div>

            <div
              className={`grid grid-cols-4 gap-4 ${
                isChecked === 'IoT Database' ? 'block' : 'hidden'
              }`}
            >
              <div className="relative z-0 w-full col-span-2 mb-4 ">
                <select
                  // value={status? info?.server?.type:""}
                  className="input-server peer"
                  onChange={onInput('type')}
                >
                  <option value={null}>Select Database server</option>
                  <option selected={info?.[0]?.type} value={'influxdb'}>
                    influxdb
                  </option>
                </select>
                <label className="absolute label-input text-md -left-1">Database server</label>
                <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
              </div>
              <div className="relative w-full col-span-2 mb-4 ">
                <input
                  autoComplete="off"
                  type="text"
                  className="input-server peer"
                  placeholder="https://xxxxxx.co.th/"
                  defaultValue={status ? info?.[0]?.host : ''}
                  onChange={onInput('host')}
                />

                <label className="absolute label-input text-md left-2">Host</label>
              </div>
            </div>

            <div
              className={`relative w-full col-span-4 mb-4  ${
                isChecked === 'IoT Database' ? 'block' : 'hidden'
              }`}
            >
              <input
                autoComplete="off"
                type="text"
                placeholder=""
                className="input-server peer"
                onChange={onInput('token')}
                defaultValue={status ? info?.[0]?.token : ''}
              />
              <label className="absolute label-input text-md left-1">Token</label>
            </div>

            <div
              className={`relative z-0 w-full col-span-4 mb-4  ${
                isChecked === 'IoT Database' ? 'block' : 'hidden'
              }`}
            >
              <input
                autoComplete="off"
                type="text"
                placeholder=""
                className="input-server peer"
                onChange={onInput('bucket')}
                defaultValue={status ? info?.[0]?.bucket : ''}
              />

              <label className="absolute label-input text-md left-1">Bucket</label>
            </div>

            <div className={`grid grid-cols-4 gap-4 ${isChecked === 'MQTT' ? 'block' : 'hidden'}`}>
              <div className="relative w-full col-span-4">
                <input autoComplete="off" type="text" className="input-server peer" />
                <label className="absolute label-input text-md left-2">Name</label>
              </div>
              <div className="relative w-full col-span-3 mb-4">
                <input autoComplete="off" type="text" className="input-server peer" />
                <label className="absolute label-input text-md left-2">Server</label>
              </div>
              <div className="relative w-full mb-4">
                <input autoComplete="off" type="text" className="input-server peer" />
                <label className="absolute label-input text-md left-2">Port</label>
              </div>
            </div>
            <div className={`grid grid-cols-4 gap-4 ${isChecked === 'MQTT' ? 'block' : 'hidden'}`}>
              <div className="relative w-full col-span-2">
                <input autoComplete="off" type="text" className="input-server peer" />
                <i className="ri-arrow-up-line absolute top-0.5 left-2 text-vekin font-bold"></i>
                <label className="absolute label-input text-md left-2">
                  Topic Up [MAC address]
                </label>
              </div>
              <div className="relative w-full col-span-2">
                <input autoComplete="off" type="text" className="input-server peer" />
                <i className="ri-arrow-down-line absolute top-0.5 left-2 text-vekin font-bold"></i>
                <label className="absolute label-input text-md">Topic Down [MAC address]</label>
              </div>
              <div className="relative w-full col-span-4 mb-4 ">
                <input
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  className="input-server peer"
                />
                <label className="absolute label-input text-md left-1">QOS</label>
              </div>
            </div>
            <div
              className={`relative w-full col-span-4 mb-4  ${
                isChecked === 'MQTT' || isChecked === 'Huawei' ? 'block' : 'hidden'
              }`}
            >
              <input autoComplete="off" type="text" placeholder="" className="input-server peer" />
              <label className="absolute label-input text-md left-1">Username</label>
            </div>
            <div
              className={`relative w-full col-span-4 mb-4  ${
                isChecked === 'MQTT' || isChecked === 'Huawei' ? 'block' : 'hidden'
              }`}
            >
              <input autoComplete="off" type={isShowPassword ? "text" : "password"} placeholder="" className="input-server peer" />
              <label className="absolute label-input text-md left-1">Password</label>
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                <img
                  src={isShowPassword ? IconVisibilityOff : IconVisibility}
                  alt=""
                  className="cursor-pointer"
                  onClick={onClickShowPassword}
                />
              </div>
            </div>

            <div
              className={`relative w-full col-span-2 mb-4  ${
                isChecked === 'Huawei' ? 'block' : 'hidden'
              }`}
            >
              <select className="input-server peer">
                <option value={null}>Select Station</option>
              </select>
              <label className="absolute label-input text-md left-1">Station</label>
              <i className="absolute flex justify-end text-xl font-bold top-3 ri-arrow-down-s-line right-4 text-vekin"></i>
            </div>

            {/* Button */}
            <div className="flex flex-row justify-center gap-20 pt-4">
              <button
                type="submit"
                className="w-2/4 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md"
              >
                {info !== undefined ? 'save' : 'Connect'}
              </button>

              <button
                onClick={() => closeAddServer(currentState)}
                type="button"
                className="w-2/4 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md"
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  )
}

export default CreateNewServer
