import { useEffect, useState, useRef } from "react";
import { Link,useNavigate  } from "react-router-dom";
import { useLoggedInUser } from "../../api/userInfo";
import { API_URL } from "../../shared/config";

const SelectSiteHeaderCompany = () => {
  const navigate = useNavigate();
  const { user, isLoading, isError } = useLoggedInUser();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedPlant, setSelectedPlant] = useState();
  const [filteredCompany, setFilteredCompany] = useState(user?.company);
  const [isDropDown, setIsDropDown] = useState(false);
  const refDropDownList = useRef(null);
  const [searchInput, setSearchInput] = useState("");

  const storeDashboardID = (dash_id, plant_id, company_id) => {
    localStorage.setItem("dashboardId", dash_id);
    localStorage.setItem("plantId", plant_id);
    localStorage.setItem("companyId", company_id);
    navigate(0)
  };


  useEffect(() => {
    if(searchInput !=''){
      let filtered = user?.company?.filter((company) =>
        company?.name?.toLowerCase().includes(searchInput)
      );
      setFilteredCompany(filtered?.length > 0 ? filtered : user?.company);
    }else{
      setFilteredCompany(user?.company);
    }
  }, [searchInput, user?.company]);

  useEffect(() => {
    if(user?.company){
      const selected_company = user?.company?.filter((company) => company?.id == localStorage.getItem("companyId"))[0];

      if(!selected_company?.plants){
        navigate('/')
      }else{
        setSelectedCompany(selected_company);
        const plant_selected = selected_company?.plants?.filter((plant) => plant?.id == localStorage.getItem("plantId"))[0]
        
        if(!plant_selected){
          navigate('/')
        }else{
          setSelectedPlant(plant_selected);
        }
      }
    }
   
  }, [localStorage.getItem("plantId"), localStorage.getItem("companyId"), user?.company]);

  
  useEffect(() => {
    // to close the dropdown when user click outside the dropdown box
    function handleClickOutside(event) {
      if (
        refDropDownList.current &&
        !refDropDownList.current.contains(event.target)
      ) {
        setIsDropDown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refDropDownList]);

  return (
    <>
      <div ref={refDropDownList} className="relative w-full">
        <button
          className="flex items-center justify-between w-full group"
          onClick={() => setIsDropDown((cur) => !cur)}
        >
          <div className="flex flex-row gap-4">
            { !!selectedCompany?.logo ? (
              
              <div className="flex items-center justify-center bg-white border rounded-full w-14 h-14">
                         <img alt="logo" src={API_URL + selectedCompany?.logo}
                        className="object-cover p-2 rounded-md"/>
                        </div>


              
            ) : (
              <div className="rounded-full w-14 h-14 bg-gray-200/90"></div>
            )}

            <div className="flex flex-col justify-center">
              <span className="hidden text-sm font-semibold capitalize md:block text-vekin-green100 ">
                {selectedCompany?.name}
              </span>
              <p className="hidden md:inline-flex items-center gap-1 text-[12px] text-primary">
                <i className="ri-building-fill text-vekin"></i>
                {selectedPlant?.name ?? "No Plants"}
              </p>
            </div>
          </div>
          <i
            className={`ri-arrow-right-line text-vekin ${
              selectedCompany?.plants?.length > 0 && isDropDown && "rotate-90"
            }`}
          ></i>
        </button>

        {isDropDown && (
          <div className="absolute z-50 flex flex-col w-[21rem]  md:w-full gap-4 p-4 py-4 my-3 bg-white border shadow-sm rounded-b-md border-tertiary/25 ">
            {/* search input */}
            <div className="relative mb-2 text-vekin">
              <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-3 pointer-events-none">
                <i className="ri-search-line text-vekin"></i>
              </div>
              <input
                autoComplete="off"
                type="search"
                id="input-group-1"
                onChange={(e) => setSearchInput(e.target.value)}
                className="bg-gray-50 border border-tertiary/25 text-vekin text-sm rounded-lg focus:ring-vekin focus:border-vekin block w-full pl-10 px-2.5 py-1.5 accent-vekin outline-vekin outline-0"
                placeholder="search entity"
              />
            </div>
            
            <div className="overflow-y-auto max-h-[24rem] flex flex-col gap-4">
                {filteredCompany?.map((company, index) =>
                    <button key={index} className="flex items-center justify-between p-2 rounded-lg group bg-gray-50 hover:shadow " type="button"  
                    onClick={() => storeDashboardID(
                      company?.plants?.[0]?.dashboards?.[0]?.id ?? company?.default_dashboard,
                      company?.plants?.[0]?.id,
                      company?.plants?.[0]?.company)
                      }>
                      <div className="flex flex-row gap-1.5">
                        { !!company?.logo ? (
                        
                          <div className="flex items-center justify-center bg-white border rounded-full w-14 h-14">
                            <img alt="logo" src={API_URL + company?.logo}
                            className="object-cover p-2 rounded-md"/>
                            </div>
                        ) : (
                          <div className="w-10 h-10 rounded-full bg-gray-200/90"></div>
                        )}
                        <div  className="flex flex-col justify-center">
                          <span className="text-sm font-semibold capitalize text-vekin-green100">
                            {company?.name}
                          </span>
                          <p className="inline-flex items-center gap-1 text-[12px] text-primary">
                            <i className=" ri-user-fill text-vekin"></i>
                            {company?.role}
                          </p>
                        </div>
                      </div>
                    </button>
                )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectSiteHeaderCompany;
