const Invertor_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M454.18 409.533V382.153C454.18 376.712 449.565 375.308 443.846 378.994L395.786 410.586C390.067 414.359 385.451 421.818 385.451 427.259V454.902C385.652 459.553 389.063 461.396 393.277 458.851C397.592 456.306 401.103 450.163 401.103 445.161C401.103 444.986 401.103 444.81 401.103 444.635V428.663C401.103 426.733 402.709 424.012 404.816 422.696L434.916 402.863C436.923 401.547 438.628 401.986 438.628 404.004V418.835C438.628 418.922 438.628 418.922 438.628 419.01C438.628 419.098 438.628 419.098 438.628 419.186V420.063V419.976C439.03 424.188 442.341 425.767 446.354 423.31C450.669 420.765 454.18 414.622 454.18 409.62C454.281 409.62 454.18 409.533 454.18 409.533Z" fill="url(#paint0_linear_7424_111436)"/>
    <path d="M112.943 311.862V284.482C112.943 279.041 108.328 277.637 102.609 281.323L54.5488 312.915C48.8297 316.688 44.2144 324.147 44.2144 329.588V357.231C44.415 361.882 47.8264 363.725 52.0404 361.18C56.3548 358.635 59.8665 352.492 59.8665 347.49C59.8665 347.315 59.8665 347.139 59.8665 346.964V330.992C59.8665 329.061 61.4719 326.341 63.5789 325.025L93.6792 305.192C95.6859 303.876 97.3916 304.315 97.3916 306.333V321.164C97.3916 321.251 97.3916 321.251 97.3916 321.339C97.3916 321.427 97.3916 321.427 97.3916 321.515V322.392V322.304C97.793 326.517 101.104 328.096 105.117 325.639C109.432 323.094 112.943 316.951 112.943 311.949C112.943 311.949 112.943 311.949 112.943 311.862Z" fill="url(#paint1_linear_7424_111436)"/>
    <path d="M460 391.806L455.585 394.351L88.3608 280.445V62.7251C88.3608 45.1742 104.615 35.7844 124.682 41.8395L423.679 131.437C443.745 137.492 460 156.535 460 174.086V391.806Z" fill="url(#paint2_radial_7424_111436)"/>
    <path d="M455.585 394.351L83.9463 282.99V65.2701C83.9463 47.7191 100.2 38.3293 120.267 44.3844L419.264 133.982C439.331 140.037 455.585 159.08 455.585 176.631V394.351Z" fill="url(#paint3_linear_7424_111436)"/>
    <path d="M443.746 390.49L409.532 412.868L86.7556 283.517L70.1001 76.064C70.1001 76.064 90.0667 64.7436 103.511 56.7579C108.428 53.7743 114.448 52.5457 120.669 54.3885L408.93 140.476C428.194 146.268 443.846 164.609 443.846 181.458L443.746 390.49Z" fill="url(#paint4_linear_7424_111436)"/>
    <path d="M416.053 419.712L411.639 422.257L44.4146 308.351V90.6312C44.4146 73.0802 60.6687 63.6904 80.7356 69.7455L379.732 159.343C399.799 165.398 416.053 184.441 416.053 201.992V419.712Z" fill="url(#paint5_radial_7424_111436)"/>
    <path d="M411.639 422.257L40 310.808V93.0884C40 75.5374 56.2542 66.1477 76.3211 72.2027L375.318 161.801C395.385 167.856 411.639 186.898 411.639 204.449V422.257Z" fill="url(#paint6_linear_7424_111436)"/>
    <path d="M257.224 182.247L61.4717 122.662V118.362L257.224 177.947V182.247Z" fill="url(#paint7_radial_7424_111436)"/>
    <path d="M257.224 166.364L61.4717 106.69V102.39L257.224 161.976V166.364Z" fill="url(#paint8_radial_7424_111436)"/>
    <path d="M257.224 174.35L61.4717 114.676V110.376L257.224 169.962V174.35Z" fill="url(#paint9_radial_7424_111436)"/>
    <path d="M257.224 198.219L61.4717 138.633V134.333L257.224 193.919V198.219Z" fill="url(#paint10_radial_7424_111436)"/>
    <path d="M257.224 190.233L61.4717 130.648V126.348L257.224 185.933V190.233Z" fill="url(#paint11_radial_7424_111436)"/>
    <path d="M391.372 224.282L277.994 189.531V185.231L391.372 219.894V224.282Z" fill="url(#paint12_radial_7424_111436)"/>
    <path d="M391.372 208.311L277.994 173.56V169.26L391.372 204.011V208.311Z" fill="url(#paint13_radial_7424_111436)"/>
    <path d="M391.372 216.296L277.994 181.545V177.245L391.372 211.909V216.296Z" fill="url(#paint14_radial_7424_111436)"/>
    <path d="M391.372 240.166L277.994 205.502V201.202L391.372 235.866V240.166Z" fill="url(#paint15_radial_7424_111436)"/>
    <path d="M391.372 232.268L277.994 197.517V193.217L391.372 227.88V232.268Z" fill="url(#paint16_radial_7424_111436)"/>
    <path d="M416.053 367.235L437.123 354.335V345.735L416.053 358.635V367.235Z" fill="url(#paint17_linear_7424_111436)"/>
    <path d="M416.053 335.029L437.123 322.129V313.441L416.053 326.341V335.029Z" fill="url(#paint18_linear_7424_111436)"/>
    <path d="M416.053 351.176L437.123 338.276V329.588L416.053 342.488V351.176Z" fill="url(#paint19_linear_7424_111436)"/>
    <path d="M416.053 399.529L437.123 386.629V377.941L416.053 390.841V399.529Z" fill="url(#paint20_linear_7424_111436)"/>
    <path d="M416.053 383.382L437.123 370.482V361.794L416.053 374.694V383.382Z" fill="url(#paint21_linear_7424_111436)"/>
    <path d="M416.053 286.676L437.123 273.776V265.088L416.053 277.988V286.676Z" fill="url(#paint22_linear_7424_111436)"/>
    <path d="M416.053 254.382L437.123 241.482V232.882L416.053 245.782V254.382Z" fill="url(#paint23_linear_7424_111436)"/>
    <path d="M416.053 270.529L437.123 257.629V248.941L416.053 261.841V270.529Z" fill="url(#paint24_linear_7424_111436)"/>
    <path d="M416.053 318.882L437.123 305.982V297.294L416.053 310.282V318.882Z" fill="url(#paint25_linear_7424_111436)"/>
    <path d="M416.053 302.735L437.123 289.835V281.235L416.053 294.135V302.735Z" fill="url(#paint26_linear_7424_111436)"/>
    <path d="M226.421 113.272L250.401 100.021L241.873 97.4763L217.893 110.727L226.421 113.272Z" fill="url(#paint27_linear_7424_111436)"/>
    <path d="M194.716 103.795L218.796 90.6313L210.268 88.0864L186.288 101.25L194.716 103.795Z" fill="url(#paint28_linear_7424_111436)"/>
    <path d="M210.568 108.533L234.548 95.3701L226.12 92.8252L202.04 105.988L210.568 108.533Z" fill="url(#paint29_linear_7424_111436)"/>
    <path d="M258.026 122.662L282.006 109.499L273.578 106.954L249.498 120.117L258.026 122.662Z" fill="url(#paint30_linear_7424_111436)"/>
    <path d="M242.174 117.923L266.254 104.76L257.726 102.215L233.746 115.378L242.174 117.923Z" fill="url(#paint31_linear_7424_111436)"/>
    <path d="M147.258 89.6661L171.338 76.5029L162.81 73.958L138.83 87.1213L147.258 89.6661Z" fill="url(#paint32_linear_7424_111436)"/>
    <path d="M115.652 80.1884L139.732 67.0251L131.204 64.4802L107.124 77.6435L115.652 80.1884Z" fill="url(#paint33_linear_7424_111436)"/>
    <path d="M131.504 84.9271L155.484 71.7639L147.056 69.219L122.976 82.3822L131.504 84.9271Z" fill="url(#paint34_linear_7424_111436)"/>
    <path d="M178.963 99.0558L202.943 85.8926L194.415 83.3477L170.435 96.5109L178.963 99.0558Z" fill="url(#paint35_linear_7424_111436)"/>
    <path d="M163.11 94.4048L187.09 81.1538L178.662 78.6089L154.582 91.8599L163.11 94.4048Z" fill="url(#paint36_linear_7424_111436)"/>
    <path d="M339.398 147.145L363.478 133.982L354.95 131.437L330.97 144.6L339.398 147.145Z" fill="url(#paint37_linear_7424_111436)"/>
    <path d="M307.793 137.756L331.873 124.505L323.345 122.047L299.365 135.211L307.793 137.756Z" fill="url(#paint38_linear_7424_111436)"/>
    <path d="M323.646 142.407L347.626 129.243L339.197 126.698L315.117 139.949L323.646 142.407Z" fill="url(#paint39_linear_7424_111436)"/>
    <path d="M371.104 156.623L395.084 143.46L386.655 140.915L362.575 154.078L371.104 156.623Z" fill="url(#paint40_linear_7424_111436)"/>
    <path d="M355.251 151.884L379.331 138.721L370.803 136.176L346.723 149.339L355.251 151.884Z" fill="url(#paint41_linear_7424_111436)"/>
    <path d="M268.462 138.633H267.358V372.588H268.462V138.633Z" fill="url(#paint42_linear_7424_111436)"/>
    <path d="M275.986 128.278L274.682 127.839L304.682 109.411L305.986 109.85L275.986 128.278Z" fill="url(#paint43_linear_7424_111436)"/>
    <defs>
    <linearGradient id="paint0_linear_7424_111436" x1="391.702" y1="421.232" x2="457.005" y2="412.658" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7424_111436" x1="50.4432" y1="323.61" x2="115.747" y2="315.036" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <radialGradient id="paint2_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(274.179 213.314) rotate(16.6807) scale(173.927 139.587)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <linearGradient id="paint3_linear_7424_111436" x1="269.78" y1="96.1975" x2="201.369" y2="324.504" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint4_linear_7424_111436" x1="300.317" y1="119.388" x2="223.167" y2="384.017" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <radialGradient id="paint5_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(230.22 241.182) rotate(16.6807) scale(173.927 139.587)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <linearGradient id="paint6_linear_7424_111436" x1="225.821" y1="124.065" x2="157.41" y2="352.372" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <radialGradient id="paint7_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(282.378 70.344) scale(256.865 224.66)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint8_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(282.378 70.344) scale(256.865 224.66)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint9_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(282.378 70.3441) scale(256.865 224.66)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint10_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(282.378 70.3441) scale(256.865 224.66)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint11_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(282.378 70.3442) scale(256.865 224.66)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint12_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(419.93 105.12) scale(236.576 206.916)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint13_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(419.93 105.12) scale(236.576 206.916)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint14_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(419.93 105.12) scale(236.576 206.916)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint15_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(419.93 105.12) scale(236.576 206.916)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint16_radial_7424_111436" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(419.93 105.12) scale(236.576 206.916)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <linearGradient id="paint17_linear_7424_111436" x1="444.063" y1="356.483" x2="416.525" y2="356.483" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint18_linear_7424_111436" x1="444.063" y1="324.239" x2="416.525" y2="324.239" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint19_linear_7424_111436" x1="444.063" y1="340.361" x2="416.525" y2="340.361" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint20_linear_7424_111436" x1="444.063" y1="388.727" x2="416.525" y2="388.727" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint21_linear_7424_111436" x1="444.063" y1="372.605" x2="416.525" y2="372.605" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint22_linear_7424_111436" x1="444.063" y1="275.873" x2="416.525" y2="275.873" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint23_linear_7424_111436" x1="444.063" y1="243.629" x2="416.525" y2="243.629" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint24_linear_7424_111436" x1="444.063" y1="259.751" x2="416.525" y2="259.751" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint25_linear_7424_111436" x1="444.063" y1="308.117" x2="416.525" y2="308.117" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint26_linear_7424_111436" x1="444.063" y1="291.995" x2="416.525" y2="291.995" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint27_linear_7424_111436" x1="241.179" y1="85.4331" x2="228.841" y2="130" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint28_linear_7424_111436" x1="209.326" y1="76.6149" x2="196.989" y2="121.182" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint29_linear_7424_111436" x1="225.253" y1="81.024" x2="212.915" y2="125.591" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint30_linear_7424_111436" x1="273.031" y1="94.2509" x2="260.693" y2="138.818" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint31_linear_7424_111436" x1="257.106" y1="89.8421" x2="244.768" y2="134.409" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint32_linear_7424_111436" x1="161.548" y1="63.3881" x2="149.211" y2="107.955" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint33_linear_7424_111436" x1="129.696" y1="54.5698" x2="117.358" y2="99.1367" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint34_linear_7424_111436" x1="145.621" y1="58.9789" x2="133.284" y2="103.546" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint35_linear_7424_111436" x1="193.401" y1="72.2061" x2="181.063" y2="116.773" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint36_linear_7424_111436" x1="177.474" y1="67.7971" x2="165.136" y2="112.364" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint37_linear_7424_111436" x1="353.699" y1="120.908" x2="341.361" y2="165.475" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint38_linear_7424_111436" x1="321.846" y1="112.09" x2="309.509" y2="156.657" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint39_linear_7424_111436" x1="337.773" y1="116.499" x2="325.435" y2="161.066" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint40_linear_7424_111436" x1="385.551" y1="129.726" x2="373.213" y2="174.293" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint41_linear_7424_111436" x1="369.625" y1="125.317" x2="357.287" y2="169.884" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint42_linear_7424_111436" x1="185.4" y1="197.994" x2="278.494" y2="283.036" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint43_linear_7424_111436" x1="288.269" y1="114.897" x2="293.743" y2="128.821" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    </defs>
    </svg>
     );
}
 
export default Invertor_3d_Icon;