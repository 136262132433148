import CircularProgress from '@mui/material/CircularProgress';
import { webStyle as theme } from '../../components/layout/Theme';

const WidgetLoading = ({text}) => {

    return (
        <div className="flex flex-col items-center justify-center w-full h-full gap-2">
            <CircularProgress size={75} style={{ color: theme["Theme"]["main"] }} disableShrink/>
            {text && <p className='text-primary'>{text}</p>}
        </div>
    );
};

export default WidgetLoading;
