import React, { useEffect, useState } from "react";
import {getImage} from "../../../utils/getImage";
function SiteCard(props) {
    const [detailSite, setDetailSite] = useState(null)

    useEffect(() => {
        setDetailSite(props.detailSite);
    }, [props.detailSite])

    function onClickCard(param) {
        if (props.disable) return;

        try {
            props.onClick(detailSite)
        } catch { }
    }
    return (
        <div className="relative w-24 h-24 mr-2 overflow-hidden rounded-lg shadow-xl cursor-pointer"
            onClick={(e) => { onClickCard(e) }}
        >
            {!detailSite?.Image.includes("data:") ?<img className="absolute w-full h-full" src={getImage(detailSite?.Image)} /> : 
            <div className="absolute w-full h-full px-2 text-sm bg-vekin">No Image</div> }
            <div className="absolute bottom-0 flex w-full h-1/2"
                style={{
                    background: 'linear-gradient(180deg, rgba(31, 163, 124, 0) 0%, rgba(31, 163, 124, 0.9) 77.6%)',
                    overflowWrap: 'anywhere',
                }}
            >
                <span className="h-full p-2 text-xs leading-none text-white text-semibold"
                    title={detailSite?.Name}>
                    {detailSite?.Name}
                </span>
            </div>
        </div>
    )
}

export default SiteCard