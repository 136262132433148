const Online_Icon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 23.2812C18.4543 23.2812 23.2812 18.4543 23.2812 12.5C23.2812 6.54568 18.4543 1.71875 12.5 1.71875C6.54568 1.71875 1.71875 6.54568 1.71875 12.5C1.71875 18.4543 6.54568 23.2812 12.5 23.2812Z" fill="#79C8B0"/>
<path d="M12.5 23.2812C18.4543 23.2812 23.2812 18.4543 23.2812 12.5C23.2812 6.54568 18.4543 1.71875 12.5 1.71875C6.54568 1.71875 1.71875 6.54568 1.71875 12.5C1.71875 18.4543 6.54568 23.2812 12.5 23.2812Z" fill="url(#paint0_linear_854_16780)"/>
<path d="M11.3281 16.7969C11.0156 17.1094 10.4687 17.1094 10.0781 16.7969L6.71875 13.4375C6.25 12.9688 6.25 12.2656 6.71875 11.7969C7.1875 11.3281 7.89062 11.3281 8.35937 11.7969L10.7031 14.1406L16.5625 8.28125C17.0312 7.8125 17.7344 7.8125 18.2031 8.28125C18.6719 8.75 18.6719 9.45312 18.2031 9.92187L11.3281 16.7969Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_854_16780" x1="1.97545" y1="10.7031" x2="23.6457" y2="10.9242" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Online_Icon;