import React from 'react';
import { useState, useEffect } from 'react';
import Select from 'react-select';


const MultiSelectBtn = ({data, placeholder, name, setMultiSelect, index, isMulti=true}) => {
    const [dataOptions, setDataOptions] = useState([])

    // console.log(data)

    useEffect(() => {
        setDataOptions(data?.map((device, index) => (
            {
                index: index,
                value: device?.id,
                label: `${device?.id}. ${device?.name} - model: ${device?.model}`
            })))
        
        
    }, [data]);

    const handleChange = (_data) => {
        if(isMulti){
          setMultiSelect({'data': _data.map(d => d.value), 'field': name.split("-")[0], 'index': index})
        }else{
          const fields = JSON.parse(data[_data?.index]?.property).map(n => {return {'name': n.name,'field':n.field}}) ?? []
          setMultiSelect({'data': _data.value, 'field': name.split("-")[0], 'index': index, 'deviceFields': fields})
        }
    }

    const customStyles = {
        control: (baseStyles, state) => ({
          ...baseStyles,
        cursor: "pointer",
        boxShadow: state.isFocused ? 0 : 0,
        borderWidth:"1.5px",
        minHeight:"45px",
        borderColor: state.isFocused
          ? "var(--VekinBrandColor)"
          : "",
        "&:hover": {
          borderColor: state.isFocused
            ? "var(--VekinBrandColor)"
            : ""
        }}),
        singleValue: (provided, state) => ({
          ...provided,
          backgroundColor: "var(--Vekin80)",
        }),
        multiValue: (provided, state) => ({
          ...provided,
          backgroundColor: "var(--Vekin80)",
        }),

        option: (provided, state) => ({
          ...provided,
          "&:hover": {
            backgroundColor: state.isFocused ? "var(--Vekin80)" : "transparent",
          },
          backgroundColor: state.isFocused ? "var(--Vekin80)" : "transparent",
        })
      }
   
    return (
      
        <Select
            // defaultValue={[dataOptions?.[6]]}
            styles={customStyles}
            isMulti={isMulti}
            name={name}
            options={dataOptions}
            className="text-sm basic-multi-select focus:border-vekin focus:border"
            classNamePrefix="select"
            placeholder={placeholder}
            onChange={handleChange}
            required
            
      />
     );
}
 
export default MultiSelectBtn;