const WindTurbine_3d_Icon = ({style}) => {
    return (
        <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M245.5 500C328.453 500 395.7 480.659 395.7 456.8C395.7 432.941 328.453 413.6 245.5 413.6C162.547 413.6 95.3 432.941 95.3 456.8C95.3 480.659 162.547 500 245.5 500Z" fill="#E2E6EA"/>
<path d="M390.1 457.2C371.9 432.2 313.6 414 244.5 414C175.3 414 117.1 432.3 98.8 457.2H390.1Z" fill="#E2E6EA"/>
<path opacity="0.12" d="M189.2 426.8C178.4 432 178.4 440.4 189.2 445.6C200 450.8 217.5 450.8 228.3 445.6C239.1 440.4 239.1 432 228.3 426.8C217.5 421.6 200 421.6 189.2 426.8Z" fill="black"/>
<path d="M183.7 176.2C183.7 180 186.4 181.6 189.7 179.7C193 177.8 195.7 173.1 195.7 169.3C195.7 165.5 193 163.9 189.7 165.8C186.4 167.7 183.7 172.3 183.7 176.2Z" fill="url(#paint0_linear_5914_87591)"/>
<path d="M184.6 177.9C184.6 173.4 187.8 167.8 191.7 165.6C193.6 164.5 195.3 164.4 196.6 165C196.7 165.1 196.8 165.1 196.9 165.2L210.3 172.8V196.1L186.9 182.6C185.4 181.9 184.6 180.3 184.6 177.9Z" fill="url(#paint1_linear_5914_87591)"/>
<path d="M194.4 430.9L199.1 204L218.1 188.3L223.1 430.7C223.2 432.9 221.8 435.1 218.9 436.8C213.3 440 204.2 440 198.6 436.8C195.8 435.2 194.4 433 194.4 430.9Z" fill="url(#paint2_linear_5914_87591)"/>
<path d="M196.3 336.5C196.4 338.4 197.6 340.2 200 341.6C204.8 344.4 212.7 344.4 217.5 341.6C220 340.2 221.2 338.3 221.1 336.5L221.2 343.9C221 345.6 219.8 347.3 217.5 348.6C212.6 351.4 204.6 351.4 199.7 348.6C197.6 347.4 196.4 345.8 196.1 344.2L196.3 336.5Z" fill="url(#paint3_linear_5914_87591)"/>
<path d="M195.9 359.7C196 361.5 197.2 363.4 199.6 364.8C204.6 367.7 212.7 367.7 217.8 364.8C220.2 363.4 221.4 361.7 221.5 359.9L222.6 431C222.7 433.1 221.3 435.3 218.5 436.9C213.1 440 204.2 440 198.8 436.9C196 435.3 194.7 433.2 194.7 431.1C194.8 431.1 195.9 359.7 195.9 359.7Z" fill="url(#paint4_linear_5914_87591)"/>
<path d="M202.4 190.8L294.4 243.5L247.6 176.6L239.4 171.5L209.4 178.8L202.4 190.8Z" fill="#2EE8CD"/>
<path d="M213.1 101.1L243.5 14.6L250.9 5L259.1 10.1L220.2 143L211.4 182.7L202.2 184.8L194.9 152.9L213.1 101.1Z" fill="#2EE8CD"/>
<path d="M205.2 180.3L211.2 185.7L81.9 298.4L73.6 293.3L205.2 180.3Z" fill="#2EE8CD"/>
<path d="M210.6 195.8L302.7 258.8L302.6 248.6L247.6 176.6L217.6 183.9L210.6 195.8Z" fill="url(#paint5_linear_5914_87591)"/>
<path d="M219.6 187.8L259.1 10.1001L251.7 19.7001L203.2 157.9L210.5 189.9L219.6 187.8Z" fill="url(#paint6_linear_5914_87591)"/>
<path d="M213.5 183.7L81.9 298.4L89.4 299L192.9 232.8L215.6 193.5L213.5 183.7Z" fill="url(#paint7_linear_5914_87591)"/>
<path d="M201.7 191.6C201.7 198.7 206.7 201.6 212.8 198C218.9 194.5 223.9 185.8 223.9 178.7C223.9 171.6 218.9 168.7 212.8 172.3C206.6 175.9 201.7 184.6 201.7 191.6Z" fill="url(#paint8_linear_5914_87591)"/>
<path d="M203.2 193C203.2 200.1 208.2 203 214.3 199.4C220.4 195.9 225.4 187.2 225.4 180.1C225.4 173 220.4 170.1 214.3 173.7C208.2 177.3 203.2 185.9 203.2 193Z" fill="#CCCCCC"/>
<path d="M210.2 189C210.2 191.6 212.9 192.9 215.2 191.6C217.4 190.3 219 187.3 219 184.7C219 182.1 216.5 180.7 214.3 182C212 183.2 210.2 186.4 210.2 189Z" fill="url(#paint9_linear_5914_87591)"/>
<path d="M211.9 189.3C211.9 191.6 213.5 192.5 215.5 191.4C217.5 190.3 219.1 187.5 219.1 185.2C219.1 182.9 217.5 182 215.5 183.1C213.5 184.3 211.9 187.1 211.9 189.3Z" fill="#E3F3FF"/>
<path opacity="0.1" d="M205.6 433.1C191.7 433.1 178.9 435.3 168.9 438.9C165.2 438.5 161.3 438.3 157.3 438.3C129.9 438.3 107.8 448.2 107.8 460.3C107.8 472.5 130 482.3 157.3 482.3C163 482.3 168.5 481.9 173.7 481.1C182.9 483.8 193.8 485.3 205.6 485.3C238 485.3 264.3 473.6 264.3 459.2C264.3 444.8 238 433.1 205.6 433.1Z" fill="black"/>
<path d="M204 477.8C236.861 477.8 263.5 462.399 263.5 443.4C263.5 424.401 236.861 409 204 409C171.139 409 144.5 424.401 144.5 443.4C144.5 462.399 171.139 477.8 204 477.8Z" fill="url(#paint10_linear_5914_87591)"/>
<path d="M188.2 435.5C206.867 435.5 222 423.456 222 408.6C222 393.743 206.867 381.7 188.2 381.7C169.533 381.7 154.4 393.743 154.4 408.6C154.4 423.456 169.533 435.5 188.2 435.5Z" fill="url(#paint11_linear_5914_87591)"/>
<path d="M154.3 474.4C179.208 474.4 199.4 461.953 199.4 446.6C199.4 431.246 179.208 418.8 154.3 418.8C129.392 418.8 109.2 431.246 109.2 446.6C109.2 461.953 129.392 474.4 154.3 474.4Z" fill="url(#paint12_linear_5914_87591)"/>
<path d="M167.2 456.4C185.867 456.4 201 442.655 201 425.7C201 408.745 185.867 395 167.2 395C148.533 395 133.4 408.745 133.4 425.7C133.4 442.655 148.533 456.4 167.2 456.4Z" fill="url(#paint13_linear_5914_87591)"/>
<path d="M208.3 447.3C226.967 447.3 242.1 434.809 242.1 419.4C242.1 403.991 226.967 391.5 208.3 391.5C189.633 391.5 174.5 403.991 174.5 419.4C174.5 434.809 189.633 447.3 208.3 447.3Z" fill="url(#paint14_linear_5914_87591)"/>
<path d="M230.1 455C240.262 455 248.5 447.523 248.5 438.3C248.5 429.077 240.262 421.6 230.1 421.6C219.938 421.6 211.7 429.077 211.7 438.3C211.7 447.523 219.938 455 230.1 455Z" fill="url(#paint15_linear_5914_87591)"/>
<path d="M203.921 427.356C204.325 417.7 194.588 409.451 182.173 408.932C169.758 408.412 159.366 415.819 158.962 425.476C158.558 435.132 168.295 443.381 180.71 443.9C193.125 444.419 203.517 437.012 203.921 427.356Z" fill="#2EE8CD"/>
<path d="M255 426.6C255.6 434.4 249.1 441.3 240.5 442C232 442.7 224.6 436.9 224 429C223.4 421.2 229.9 414.3 238.5 413.6C247.1 413 254.5 418.8 255 426.6Z" fill="#2EE8CD"/>
<path d="M178 418.5C178 431.9 166.5 442.9 152.3 443C138.1 443.2 126.5 432.5 126.4 419.1C126.4 405.7 137.9 394.7 152.1 394.6C166.4 394.4 178 405.1 178 418.5Z" fill="#2EE8CD"/>
<path opacity="0.1" d="M294.4 440C317.541 440 336.3 429.3 336.3 416.1C336.3 402.901 317.541 392.2 294.4 392.2C271.259 392.2 252.5 402.901 252.5 416.1C252.5 429.3 271.259 440 294.4 440Z" fill="black"/>
<path d="M294.4 432.6C316.215 432.6 333.9 422.571 333.9 410.2C333.9 397.829 316.215 387.8 294.4 387.8C272.585 387.8 254.9 397.829 254.9 410.2C254.9 422.571 272.585 432.6 294.4 432.6Z" fill="url(#paint16_linear_5914_87591)"/>
<path d="M260.3 397.7C260.1 403.8 265.9 409 273.3 409.3C280.7 409.6 286.9 404.8 287.2 398.7C287.4 392.6 281.6 387.4 274.2 387.1C266.7 386.9 260.5 391.6 260.3 397.7Z" fill="#2EE8CD"/>
<path d="M273.7 397.3C273.3 408.9 284.3 418.7 298.4 419.2C312.5 419.7 324.2 410.8 324.6 399.2C325 387.6 314 377.8 299.9 377.3C285.9 376.7 274.1 385.7 273.7 397.3Z" fill="#2EE8CD"/>
<path d="M294.4 399C294.1 407.9 302.6 415.5 313.5 415.9C324.4 416.3 333.4 409.4 333.8 400.4C334.1 391.5 325.6 383.9 314.7 383.5C303.8 383.1 294.7 390 294.4 399Z" fill="#2EE8CD"/>
<path opacity="0.1" d="M309.6 445.7C298.8 450.9 298.8 459.3 309.6 464.5C320.4 469.7 337.9 469.7 348.7 464.5C359.5 459.3 359.5 450.9 348.7 445.7C337.9 440.5 320.4 440.5 309.6 445.7Z" fill="black"/>
<path d="M309.8 254.9C309.8 257.9 311.9 259.1 314.4 257.6C317 256.1 319 252.5 319 249.6C319 246.6 316.9 245.4 314.4 246.9C311.8 248.3 309.8 251.9 309.8 254.9Z" fill="url(#paint17_linear_5914_87591)"/>
<path d="M310.4 256.3C310.4 252.8 312.9 248.5 315.9 246.8C317.4 246 318.7 245.9 319.7 246.4C319.8 246.4 319.8 246.5 319.9 246.5L330.2 252.4V270.4L312.1 260C311.1 259.3 310.4 258.1 310.4 256.3Z" fill="url(#paint18_linear_5914_87591)"/>
<path d="M318 449.7L321.6 276.4L336.3 264.3L340.2 449.6C340.3 451.3 339.2 453 337 454.3C332.7 456.8 325.7 456.8 321.3 454.3C319.1 453 318 451.4 318 449.7Z" fill="url(#paint19_linear_5914_87591)"/>
<path d="M319.6 374.7C319.6 376.1 320.6 377.6 322.4 378.6C326.1 380.7 332.1 380.7 335.8 378.6C337.7 377.5 338.6 376.1 338.6 374.7L338.7 380.4C338.6 381.7 337.6 383 335.9 384C332.1 386.2 326 386.2 322.3 384C320.7 383.1 319.7 381.9 319.5 380.6L319.6 374.7Z" fill="url(#paint20_linear_5914_87591)"/>
<path d="M319.1 394.5C319.2 395.9 320.1 397.4 322 398.5C325.9 400.8 332.2 400.8 336.2 398.5C338 397.4 339 396.1 339.1 394.7L340.2 450.1C340.3 451.8 339 453.5 336.8 454.7C332.6 457.2 325.7 457.2 321.4 454.7C319.3 453.5 318 451.7 318 450L319.1 394.5Z" fill="url(#paint21_linear_5914_87591)"/>
<path d="M324.2 266.2L395.3 306.9L359.2 255.2L352.8 251.3L329.6 256.9L324.2 266.2Z" fill="#92E3FF"/>
<path d="M332.5 196.8L356 130L361.7 122.6L368.1 126.5L338 229.2L331.1 259.9L324.1 261.6L318.4 236.9L332.5 196.8Z" fill="#92E3FF"/>
<path d="M325.3 259L331 262.2L231.1 349.4L224.7 345.5L325.3 259Z" fill="#92E3FF"/>
<path d="M330.6 270.1L401.8 318.7L401.7 310.9L359.2 255.2L336 260.8L330.6 270.1Z" fill="url(#paint22_linear_5914_87591)"/>
<path d="M337.5 263.9L368.1 126.5L362.3 133.9L324.8 240.8L330.4 265.5L337.5 263.9Z" fill="url(#paint23_linear_5914_87591)"/>
<path d="M332.8 260.7L231.1 349.4L236.8 349.8L316.9 298.6L334.5 268.3L332.8 260.7Z" fill="url(#paint24_linear_5914_87591)"/>
<path d="M323.6 266.8C323.6 272.3 327.4 274.5 332.2 271.8C336.9 269.1 340.8 262.4 340.8 256.9C340.8 251.4 337 249.2 332.2 251.9C327.5 254.7 323.6 261.4 323.6 266.8Z" fill="url(#paint25_linear_5914_87591)"/>
<path d="M339.406 266.376C342.775 260.541 342.792 254.244 339.444 252.311C336.096 250.378 330.651 253.541 327.282 259.376C323.913 265.211 323.896 271.508 327.244 273.441C330.592 275.374 336.037 272.211 339.406 266.376Z" fill="#CCCCCC"/>
<path d="M330.3 264.8C330.3 266.8 332.4 267.8 334.2 266.8C335.9 265.8 337.1 263.4 337.1 261.4C337.1 259.4 335.2 258.3 333.5 259.3C331.7 260.3 330.3 262.8 330.3 264.8Z" fill="url(#paint26_linear_5914_87591)"/>
<path d="M331.5 265.1C331.5 266.9 332.7 267.6 334.3 266.7C335.8 265.8 337.1 263.7 337.1 261.9C337.1 260.1 335.9 259.4 334.3 260.3C332.8 261.1 331.5 263.3 331.5 265.1Z" fill="#E3F3FF"/>
<defs>
<linearGradient id="paint0_linear_5914_87591" x1="183.7" y1="172.75" x2="195.7" y2="172.75" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint1_linear_5914_87591" x1="207.931" y1="166.692" x2="193.111" y2="193.076" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="0.5016" stop-color="#A8A8A8"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint2_linear_5914_87591" x1="194.4" y1="313.75" x2="223.105" y2="313.75" gradientUnits="userSpaceOnUse">
<stop offset="0.000248478" stop-color="#CCCCCC"/>
<stop offset="0.5016" stop-color="#E3E3E3"/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint3_linear_5914_87591" x1="196.194" y1="343.6" x2="221.255" y2="343.6" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint4_linear_5914_87591" x1="194.799" y1="399.462" x2="222.704" y2="399.462" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint5_linear_5914_87591" x1="166.278" y1="123.378" x2="304.064" y2="261.164" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint6_linear_5914_87591" x1="212.228" y1="77.4282" x2="350.014" y2="215.214" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint7_linear_5914_87591" x1="98.1531" y1="191.503" x2="235.939" y2="329.289" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint8_linear_5914_87591" x1="219.516" y1="173.199" x2="204.695" y2="199.583" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="0.5016" stop-color="#A8A8A8"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint9_linear_5914_87591" x1="210.229" y1="186.808" x2="218.991" y2="186.808" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint10_linear_5914_87591" x1="154.363" y1="393.763" x2="255.798" y2="495.198" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint11_linear_5914_87591" x1="163.863" y1="384.263" x2="265.298" y2="485.698" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint12_linear_5914_87591" x1="127.913" y1="420.213" x2="229.348" y2="521.648" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint13_linear_5914_87591" x1="144.813" y1="403.313" x2="246.248" y2="504.748" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint14_linear_5914_87591" x1="168.513" y1="379.613" x2="269.948" y2="481.048" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint15_linear_5914_87591" x1="169.963" y1="378.163" x2="271.398" y2="479.598" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint16_linear_5914_87591" x1="254.9" y1="410.2" x2="333.9" y2="410.2" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint17_linear_5914_87591" x1="309.755" y1="252.25" x2="319.03" y2="252.25" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint18_linear_5914_87591" x1="334.079" y1="237.553" x2="319.258" y2="263.937" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="0.5016" stop-color="#A8A8A8"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint19_linear_5914_87591" x1="318" y1="360.238" x2="340.179" y2="360.238" gradientUnits="userSpaceOnUse">
<stop offset="0.000248478" stop-color="#CCCCCC"/>
<stop offset="0.5016" stop-color="#E3E3E3"/>
<stop offset="1" stop-color="#666666"/>
</linearGradient>
<linearGradient id="paint20_linear_5914_87591" x1="319.5" y1="380.175" x2="338.72" y2="380.175" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint21_linear_5914_87591" x1="318" y1="425.538" x2="340.182" y2="425.538" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint22_linear_5914_87591" x1="340.229" y1="257.979" x2="397.513" y2="315.263" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint23_linear_5914_87591" x1="375.729" y1="222.479" x2="433.013" y2="279.763" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint24_linear_5914_87591" x1="287.554" y1="310.654" x2="344.838" y2="367.938" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint25_linear_5914_87591" x1="343.042" y1="242.587" x2="328.221" y2="268.971" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="0.5016" stop-color="#A8A8A8"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint26_linear_5914_87591" x1="330.266" y1="263.059" x2="337.04" y2="263.059" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>
    )
}
export default WindTurbine_3d_Icon