import React, { useEffect, useState } from "react";
import imgEdit from '../../../assets/siteManagement/images/edit.svg'
import imgPin from '../../../assets/siteManagement/images/pin-2.svg'
import { getImage } from "../../../utils/getImage";

function SiteBoxDetailType3(props) {
    const [detailSite, setDetailSite] = useState({
        Id: null,
        Name: '',
        Description: '',
        Location: '',
        Image: null,
        ListImage: [],
    })

    useEffect(() => {
        if (props.detailSite) {
            let detail = {
                ...props.detailSite
            }
            setDetailSite(detail);
        }
    }, [props.detailSite])

    function onClickEditSite() {
        try {
            props.onClickEdit(detailSite)
        } catch { }
    }

    return (
        <div className="w-full h-full p-2 bg-white rounded-lg shadow-content-box">

            <div className="w-full h-[100px] flex">
                <div className="w-[100px] h-[100px] rounded-lg bg-vekin-green60 overflow-hidden">
                   {!detailSite?.Image?.includes("data:") && <img className="w-full h-full" src={getImage(detailSite?.Image)} /> }
                </div>
                <div className="h-full pl-4" style={{ width: 'calc(100% - 100px)' }}>
                    <div className="w-full h-[36px] flex items-center">
                        <p className="pr-2 overflow-hidden text-lg font-bold text-vekin-green100 text-ellipsis whitespace-nowrap"
                            style={{ width: 'calc(100% - 12px)' }}
                            title={detailSite?.Name}>
                            {detailSite.Name}
                        </p>
                        <img className="w-[12px] h-[12px] cursor-pointer hover:opacity-50 duration-300 ease-in-out"
                            src={imgEdit} onClick={(e) => { onClickEditSite() }} />
                    </div>
                    <div className="overflow-y-auto" style={{ height: 'calc(100% - 36px)' }}>
                        <p className="text-xs font-normal leading-tight text-secondary">
                            {detailSite.Description}
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-full h-px my-2 bg-quartenary">
            </div>

            <div className="w-full" style={{ height: 'calc(100% - 117px)' }}>
                <div className="w-full h-[16px]">
                    <p className="text-tertiary text-[10px] font-semibold">
                        Location
                    </p>
                </div>
                <div className="flex w-full" style={{ height: 'calc(100% - 16px)' }}>
                    <div className="h-full pr-2 overflow-y-auto" style={{ width: 'calc(100% - 40px)' }}>
                        <p className="text-xs font-normal text-primary">
                            {detailSite.Location}
                        </p>
                    </div>
                    <div className="w-[40px] h-full flex items-center">
                        <div className="w-[40px] h-[40px] rounded-lg bg-vekin-green-50 flex justify-center items-center">
                            <img className="w-3 h-4" src={imgPin} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default SiteBoxDetailType3