import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import GridLayout from "react-grid-layout";
import { withSize } from "react-sizeme";
import { webStyle as defaultStyle } from "../../../components/layout/Theme";
import { useWidgetDashboardByID } from "../../../api/dashboard";
import { PageLoading } from "../../../components/ui";
import { useLoggedInUser } from "../../../api/userInfo";

import SignageTvHeader from "../../../components/layout/signageTv/SignageTvHeader";
import { useGetSignageDashboardByID } from "../../../api/signageDash";
import {getSignageWidget} from "../../../utils/widgetLibrary/widgetTemplates/getSignageWidget";
import VekinLogo from "../../../components/layout/signageTv/VekinLogo";

const SignageTvDashboard = ({ size }) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [webStyle, setWebStyle] = useState(defaultStyle);
  let params = useParams();
  const { dashboardData, isLoadingData, isErrorData } = useGetSignageDashboardByID(1);
  const [widgetData, setWidgetData] = useState();
  const { user, isLoading, isError } = useLoggedInUser();

    const [popupData, setPopupData] = useState({})
    const [openPopUp, setOpenPopUp] = useState(false)


    useEffect(() => {
      //  getData(params.dashboardId);
        setWidgetData(dashboardData?.widget_map?.map((item, index) => ({
            i: `widget_${(index+1).toString()}`,
            x: item?.grid?.x,
            y: item?.grid?.y,
            w: item?.grid?.w,
            h: item?.grid?.h,
            dataURL:item?.grid?.data,
            name: item?.name,
            layoutOrientation: item?.grid?.layoutOrientation,
        })));
        //getWebStyle().then((theme) => setWebStyle(theme));
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [dashboardData])

  useEffect(() => {
    //  getData(params.dashboardId);
    setWidgetData(
      dashboardData?.widget_map?.map((item, index) => ({
        i: `widget_${(index + 1).toString()}`,
        x: item?.grid?.x,
        y: item?.grid?.y,
        w: item?.grid?.w,
        h: item?.grid?.h,
        dataURL: item?.grid?.data,
        name: item?.name,
        layoutOrientation: item?.grid?.layoutOrientation,
      }))
    );
    //getWebStyle().then((theme) => setWebStyle(theme));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardData]);

  return (
    <section className="w-full h-full overflow-x-hidden overflow-y-auto bg-vekin-green-50 scroll-smooth">
     <SignageTvHeader />

            <div className='relative overflow-x-hidden'>
            {(isLoadingData && widgetData?.length < 1)?<PageLoading loading={isLoadingData} />:
            <>
            
                <GridLayout
                    className="h-full overflow-x-hidden overflow-y-hidden layout rounded-xl "
                    layout={widgetData}
                    cols={(size?.width-65 > 4400)? 12:8}
                    rowHeight={100}
                    width={size?.width}
                    isResizable={false}
                    isDraggable={false}
                    compactType="horizontal"
                >{widgetData?.map((widget) =>
                (
                    <div key={widget.i} className="bg-white rounded-lg shadow-sm" >
                        {/* {checkCustomWidget(widget)} */}
                        {getSignageWidget(widget)}
                    </div>
                )
                )}
                </GridLayout>
            </>
            }
        </div>
    
     
        <div className="w-full py-1 overflow-x-hidden">
            <div className="flex flex-row items-center justify-end px-8" >
              <p>Presented By</p>

              <VekinLogo />
                         {/* <img alt="logo" src={API_URL + selectedCompany?.logo}
                        className="object-cover p-2 rounded-md w-[20%] h-[20%] xl:w-[8%] xl:h-[8%]"/>
                  */}
            </div>
        </div>
        
        </section>
    );
}

export default withSize({ refreshMode: "debounce", refreshRate: 60 })(
  SignageTvDashboard
);



