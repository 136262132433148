import React, { useState } from "react";
import MuiDatePicker from '@mui/lab/DatePicker';
import styled from '@emotion/styled';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import DateAdapter from '@mui/lab/AdapterMoment';


const CustomInput = styled(TextField)`
    background-color:#fff;
    border-radius: 12px;
  fieldset {
    border-radius: 12px;
  }
`;
const Label = styled.label`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom:5px;
`;
const InputLabel = styled(Label)`
  color: #000000;
`;

const RequiredLabel = styled(Label)`
  color: #DF4854;
`;
const DatePicker = ({ label, placeholder, value, error, onChange, onValidate, required, className }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className={`flex flex-col w-full ${className} mt-auto`}>
      {label && <Label>
        <InputLabel><p className="text-xs text-[#809FB8]">{label}</p></InputLabel>
        {required && <RequiredLabel> *</RequiredLabel>}
      </Label>}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <MuiDatePicker
          open={open}
          onOpen={(e) => setOpen(true)}
          onClose={(e) => setOpen(false)}
          value={value}
          onChange={onChange}
          inputFormat="DD/MM/YYYY"
          variant={'inline'}
          disableOpenPicker={true}
          renderInput={(params) => {
            return (
              <CustomInput
                {...params}
                size="small"
                onClick={(e) => setOpen(true)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            );
          }}
        />
      </LocalizationProvider>
      {error && <FormHelperText error={Boolean(error)} >{error}</FormHelperText>}
    </div>
  );
}

export default DatePicker;
