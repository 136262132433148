const WeatherHumidity_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4850_92928)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.299988C10 0.299988 2.70001 7.39999 2.70001 15.4C2.70001 19.9 6.90001 24 12 24C17.1 24 21.3 19.9 21.3 15.4C21.3 7.79999 14 0.299988 12 0.299988Z" fill={stroke}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1 11.6C18.3 12.1 19.1 13.6 19.1 15.9C19.1 19.3 15.7 21.4 12 21.4C8.29998 21.4 5.19998 18.9 5.19998 15.9C5.19998 15.5 5.19998 15 5.39998 14.4C5.49998 14 5.79998 13.7 6.19998 13.5C6.59998 13.3 7.19998 13.3 7.59998 13.5C9.49998 14.5 11 13.9 13.2 12.1C14.1 11.4 15 10.8 15.8 10.5C16.2 10.3 16.7 10.3 17.1 10.5C17.4 10.7 17.9 11.1 18.1 11.6Z" fill={stroke}/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.99999 11.3C5.39999 12.1 4.79999 13.4 4.89999 15.7C5.29999 19 8.19999 21.4 12 21.4C15.8 21.4 19 19.3 19.1 15.9C19.1 15.5 19.1 14.9 18.9 14.4C18.6 13.9 18.3 13.6 17.9 13.5C17.5 13.3 16.9 13.3 16.5 13.5C14.6 14.5 13.1 13.9 10.9 12.1C9.99999 11.4 9.09999 10.8 8.29999 10.5C7.89999 10.3 7.39999 10.3 6.99999 10.5C6.59999 10.7 6.39999 10.8 5.99999 11.3Z" fill={stroke}/>
        </g>
        </svg>

    )  
}  

export default WeatherHumidity_Icon;