const CoolingTower_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M434.44 113.6L434.5 431.78L280.99 388.21L280.91 70.04L434.44 113.6Z" fill="#CCCCCC"/>
    <path d="M280.91 70.04L280.98 388.21L65.56 430.06L65.5 111.89C137.31 97.93 209.1 83.99 280.91 70.04Z" fill="#CCCCCC"/>
    <path d="M434.44 113.6L280.92 70.05L65.5 111.88L219.01 155.43C248.13 149.78 305.83 138.58 434.44 113.6Z" fill="#E6E6E6"/>
    <path d="M434.5 431.78L434.43 113.61L219.01 155.44L219.08 473.61C290.9 459.67 362.69 445.72 434.5 431.78Z" fill="#B3B3B3"/>
    <path d="M219.09 473.61L219.01 155.44L65.5 111.88L65.56 430.06L219.09 473.61Z" fill="#CCCCCC"/>
    <path d="M235.07 409.62L235.12 400.12L326.76 382.78L326.68 392.27L235.07 409.62Z" fill="#999999"/>
    <path d="M235.07 428.85L235.12 419.35L326.76 402.01L326.68 411.49L235.07 428.85Z" fill="#999999"/>
    <path d="M235.07 447.57L235.12 438.08L326.76 420.72L326.68 430.23L235.07 447.57Z" fill="#999999"/>
    <path d="M335.53 390.65L335.59 381.15L427.22 363.81L427.15 373.3L335.53 390.65Z" fill="#999999"/>
    <path d="M335.53 409.88L335.59 400.38L427.22 383.03L427.15 392.52L335.53 409.88Z" fill="#999999"/>
    <path d="M335.53 428.6L335.59 419.11L427.22 401.75L427.15 411.25L335.53 428.6Z" fill="#999999"/>
    <path d="M235.07 352.71L235.12 343.21L326.76 325.87L326.68 335.35L235.07 352.71Z" fill="#999999"/>
    <path d="M235.07 371.94L235.12 362.43L326.76 345.09L326.68 354.58L235.07 371.94Z" fill="#999999"/>
    <path d="M235.07 390.65L235.12 381.16L326.76 363.81L326.68 373.31L235.07 390.65Z" fill="#999999"/>
    <path d="M335.53 333.74L335.59 324.24L427.22 306.89L427.15 316.38L335.53 333.74Z" fill="#999999"/>
    <path d="M335.53 352.96L335.59 343.46L427.22 326.12L427.15 335.61L335.53 352.96Z" fill="#999999"/>
    <path d="M335.53 371.68L335.59 362.19L427.22 344.84L427.15 354.34L335.53 371.68Z" fill="#999999"/>
    <path d="M235.07 295.8L235.12 286.29L326.76 268.95L326.68 278.44L235.07 295.8Z" fill="#999999"/>
    <path d="M235.07 315.02L235.12 305.52L326.76 288.18L326.68 297.67L235.07 315.02Z" fill="#999999"/>
    <path d="M235.07 333.74L235.12 324.25L326.76 306.89L326.68 316.4L235.07 333.74Z" fill="#999999"/>
    <path d="M335.53 276.82L335.59 267.32L427.22 249.98L427.15 259.47L335.53 276.82Z" fill="#999999"/>
    <path d="M335.53 296.05L335.59 286.55L427.22 269.21L427.15 278.69L335.53 296.05Z" fill="#999999"/>
    <path d="M335.53 314.77L335.59 305.28L427.22 287.92L427.15 297.43L335.53 314.77Z" fill="#999999"/>
    <path d="M235.07 238.88L235.12 229.38L326.76 212.04L326.68 221.53L235.07 238.88Z" fill="#999999"/>
    <path d="M235.07 258.11L235.12 248.6L326.76 231.26L326.68 240.75L235.07 258.11Z" fill="#999999"/>
    <path d="M235.07 276.82L235.12 267.34L326.76 249.98L326.68 259.48L235.07 276.82Z" fill="#999999"/>
    <path d="M335.53 219.91L335.59 210.41L427.22 193.07L427.15 202.55L335.53 219.91Z" fill="#999999"/>
    <path d="M335.53 239.14L335.59 229.63L427.22 212.29L427.15 221.78L335.53 239.14Z" fill="#999999"/>
    <path d="M335.53 257.85L335.59 248.36L427.22 231.01L427.15 240.51L335.53 257.85Z" fill="#999999"/>
    <path d="M235.07 181.97L235.12 172.46L326.76 155.12L326.68 164.61L235.07 181.97Z" fill="#999999"/>
    <path d="M235.07 201.19L235.12 191.69L326.76 174.35L326.68 183.84L235.07 201.19Z" fill="#999999"/>
    <path d="M235.07 219.91L235.12 210.42L326.76 193.07L326.68 202.57L235.07 219.91Z" fill="#999999"/>
    <path d="M335.53 163L335.59 153.49L427.22 136.15L427.15 145.64L335.53 163Z" fill="#999999"/>
    <path d="M335.53 182.22L335.59 172.72L427.22 155.38L427.15 164.87L335.53 182.22Z" fill="#999999"/>
    <path d="M335.53 200.94L335.59 191.45L427.22 174.09L427.15 183.6L335.53 200.94Z" fill="#999999"/>
    <path d="M202.59 410.13L202.55 400.63L142.74 383.29L142.79 392.78L202.59 410.13Z" fill="#999999"/>
    <path d="M202.59 429.36L202.55 419.86L142.74 402.51L142.79 412L202.59 429.36Z" fill="#999999"/>
    <path d="M202.59 448.08L202.55 438.59L142.74 421.23L142.79 430.73L202.59 448.08Z" fill="#999999"/>
    <path d="M137.01 391.16L136.97 381.66L77.1602 364.32L77.2102 373.81L137.01 391.16Z" fill="#999999"/>
    <path d="M137.01 410.39L136.97 400.88L77.1602 383.54L77.2102 393.03L137.01 410.39Z" fill="#999999"/>
    <path d="M137.01 429.1L136.97 419.62L77.1602 402.26L77.2102 411.76L137.01 429.1Z" fill="#999999"/>
    <path d="M235.07 181.97L235.19 172.57L427.4 326.28L427.24 335.63L235.07 181.97Z" fill="#B3B3B3"/>
    <path d="M235.07 371.73L235.19 362.1L427.4 136.52L427.24 146.18L235.07 371.73Z" fill="#B3B3B3"/>
    <path d="M358.85 86.65L357.62 108.66C357.62 108.66 345.3 137.71 241.58 137.41C133.29 137.09 130.14 110.29 130.14 110.29L127.49 83.8C127.49 83.8 136.41 112.84 234.39 116.1C332.37 119.36 358.85 86.65 358.85 86.65Z" fill="url(#paint0_linear_4850_75590)"/>
    <path d="M247.184 101.384C290.902 100.163 326.165 92.8057 325.946 84.9498C325.726 77.0939 290.108 71.7147 246.39 72.9349C202.672 74.1551 167.41 81.5127 167.629 89.3686C167.848 97.2245 203.466 102.604 247.184 101.384Z" fill="black"/>
    <path d="M356.21 62.13C343.42 51.99 311.57 35.97 238.13 36.56C126.23 37.48 128.4 69.42 128.4 69.42C128.4 69.42 142.39 87.12 167.07 86.54C182.45 81.01 204.77 76.46 237.23 75.04C276.06 73.35 305 78.1 325.86 84.55C351.05 77.97 362.1 66.78 356.21 62.13Z" fill="url(#paint1_linear_4850_75590)"/>
    <path d="M382.12 67.7701L380.64 84.2701C380.64 84.2701 365.83 119.2 241.11 118.84C110.9 118.46 107.11 86.2301 107.11 86.2301L103.92 64.3401C103.92 64.3401 114.64 99.2601 232.45 103.18C350.26 107.1 382.12 67.7701 382.12 67.7701Z" fill="url(#paint2_linear_4850_75590)"/>
    <path d="M242.48 104.34C316.38 104.08 381.5 84.85 382.37 67.4C383.48 45.6 323.45 27.26 246.58 26.42C169.71 25.58 105.85 42.56 103.96 64.36C102.07 86.14 170.34 104.59 242.48 104.34ZM133.39 64.44C134.72 49.1 184.94 37.23 245.57 37.95C306.2 38.66 353.66 51.67 352.96 67.02C352.38 79.3 301.19 92.75 242.92 92.82C186.03 92.89 132.05 79.79 133.39 64.44Z" fill="#E6E6E6"/>
    <path d="M249.78 54.14L248.67 42.52C240.53 42.52 233.79 43.97 233.61 45.84L232.4 58.56C242.23 58.56 250.01 56.53 249.78 54.14Z" fill="url(#paint3_radial_4850_75590)"/>
    <path d="M220.52 66.5001C221.12 63.5701 213.38 61.3101 203.26 61.3101H145.15C142.35 64.1101 148.61 66.5001 159.28 66.5001H220.52Z" fill="url(#paint4_radial_4850_75590)"/>
    <path d="M319.13 61.3201H261.02C261.59 64.1201 270.73 66.5101 281.4 66.5101H342.64C339.71 63.5701 329.25 61.3201 319.13 61.3201Z" fill="url(#paint5_radial_4850_75590)"/>
    <path d="M220.79 61.77C228.24 59.87 228.65 56.94 222.18 55.22L189.28 46.48C182.23 47.88 180.54 50.25 185.89 51.78L220.79 61.77Z" fill="url(#paint6_radial_4850_75590)"/>
    <path d="M248.54 58.38C255.76 60.22 267.23 60.22 273.66 58.38L306.27 49.05C299.02 47.61 289.07 47.61 283.66 49.05L248.54 58.38Z" fill="url(#paint7_radial_4850_75590)"/>
    <path d="M254.16 63.87C254.55 69.48 248.74 74.29 241.15 74.29C233.56 74.29 227.75 69.48 228.15 63.87C228.53 58.58 234.35 54.52 241.16 54.52C247.97 54.52 253.79 58.59 254.16 63.87Z" fill="url(#paint8_radial_4850_75590)"/>
    <path d="M230.75 75.92L228.32 101.38C242.66 101.38 253.87 97.1 253.4 92.17L251.26 69.75C240.26 69.75 231.08 72.42 230.75 75.92Z" fill="url(#paint9_radial_4850_75590)"/>
    <path d="M262.719 66C255.509 68.16 256.019 71.86 264.479 74.28L321.559 90.62C328.619 87.43 325.159 82.59 314.639 79.8L262.719 66Z" fill="url(#paint10_radial_4850_75590)"/>
    <path d="M233.17 69.9801C225.71 67.7501 213.33 67.7501 204.92 69.9801L148.52 84.9701C155.29 88.0301 170.12 88.0301 180.81 84.9701L233.17 69.9801Z" fill="url(#paint11_radial_4850_75590)"/>
    <path d="M352.78 65.1399C352.91 65.7599 352.99 66.3799 352.96 67.0099C352.38 79.2899 301.19 92.7399 242.92 92.8099C186.91 92.8799 133.75 80.1899 133.41 65.1399H103.94C103.74 86.5799 171.22 104.59 242.49 104.34C316.39 104.08 381.51 84.8499 382.38 67.3999C382.42 66.6399 382.38 65.8899 382.27 65.1399H352.78Z" fill="#E6E6E6"/>
    <path d="M178.99 167.06C174.06 175.34 175.67 187.15 182.58 193.45C189.49 199.75 199.1 198.15 204.03 189.87C208.96 181.59 207.35 169.78 200.44 163.48C193.52 157.18 183.92 158.78 178.99 167.06Z" fill="url(#paint12_radial_4850_75590)"/>
    <path d="M97.3301 463.07C88.7701 463.07 81.8301 456.13 81.8301 447.57V400.45C81.8301 376.6 97.8501 355.4 120.8 348.89L132.78 345.49C141.01 343.15 149.59 347.93 151.92 356.17C154.25 364.41 149.48 372.98 141.24 375.31L129.26 378.71C119.59 381.46 112.83 390.39 112.83 400.45V447.57C112.83 456.13 105.89 463.07 97.3301 463.07Z" fill="url(#paint13_radial_4850_75590)"/>
    <defs>
    <linearGradient id="paint0_linear_4850_75590" x1="242.876" y1="130.206" x2="243.214" y2="78.5076" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4850_75590" x1="199.729" y1="23.2705" x2="342.266" y2="165.807" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4850_75590" x1="362.475" y1="84.6076" x2="106.071" y2="94.2198" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <radialGradient id="paint3_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(244.399 42.8122) scale(103.279 28.86)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint4_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(196.986 61.4133) scale(54.3327 15.1825)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint5_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(317.344 61.4133) scale(56.8457 15.8848)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint6_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(213.094 46.7608) scale(101.102 28.2516)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint7_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(288.38 48.1881) scale(82.3238 23.0043)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint8_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(225.568 62.153) scale(28.7003 22.3862)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint9_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(245.643 70.3162) scale(203.214 56.7856)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint10_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(304.316 66.4388) scale(162.346 45.3654)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint11_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(206.94 68.6401) scale(130.569 36.4859)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint12_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(197.918 174.365) rotate(-149.211) scale(34.9268 34.9268)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint13_radial_4850_75590" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(82.9142 360.867) scale(73.8095 73.8095)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    </defs>
    </svg>
     );
}
export default CoolingTower_3d_Icon;