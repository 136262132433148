const Icon_EvPods = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M36.3741 28.9788L31.2877 28.1582L36.0192 18.5446C36.1375 18.3101 36.0192 17.9584 35.6644 17.9584L27.1476 18.0756C27.0293 18.0756 26.911 18.1929 26.7927 18.3101C26.7927 18.3101 22.0612 30.034 21.8246 30.5029C21.588 30.9719 21.9429 31.9098 22.5343 32.027L27.9756 32.8477L25.7281 42.5785C25.6098 42.9303 26.2013 43.0475 26.3196 42.813L36.8473 29.9167C37.2021 29.6823 36.9655 29.0961 36.3741 28.9788Z" fill="url(#paint0_linear_4272_63778)"/>
<path d="M47.5596 27.6713C46.5883 27.6713 45.7557 28.5039 45.7557 29.4752C45.7557 29.6139 45.7557 29.7527 45.7557 30.0302C45.7557 39.7433 37.7077 47.5139 27.9946 47.0976C19.2527 46.6813 12.0373 39.4658 11.621 30.5852C11.3435 20.8721 19.114 12.9628 28.6884 12.9628C31.186 12.9628 33.5449 13.5179 35.6263 14.4892C36.3201 14.7667 37.1527 14.6279 37.7077 13.9342C38.4015 13.1016 38.124 11.714 37.1527 11.159C34.1 9.77138 30.7698 9.07758 27.162 9.3551C16.7551 10.1877 8.42954 18.7907 8.01326 29.0589C7.59699 40.8534 17.0326 50.7053 28.6884 50.7053C40.0666 50.7053 49.3635 41.4084 49.3635 30.0302C49.3635 29.7527 49.3635 29.6139 49.3635 29.3364C49.3635 28.3651 48.5309 27.6713 47.5596 27.6713Z" fill="url(#paint1_linear_4272_63778)"/>
<path d="M50.8896 19.7621V16.8482C50.8896 16.1544 50.3346 15.5994 49.6408 15.5994C48.947 15.5994 48.3919 16.1544 48.3919 16.8482V19.7621H46.1718V16.8482C46.1718 16.1544 45.6168 15.5994 44.923 15.5994C44.2292 15.5994 43.6741 16.1544 43.6741 16.8482V19.7621H42.4253V25.1737C42.4253 27.8102 44.6454 29.8916 47.2819 29.8916C49.9183 29.8916 51.9997 27.6714 51.9997 25.035V19.6234L50.8896 19.7621Z" fill="url(#paint2_linear_4272_63778)"/>
<defs>
<linearGradient id="paint0_linear_4272_63778" x1="21.7604" y1="30.5029" x2="37.0416" y2="30.5029" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint1_linear_4272_63778" x1="8.03325" y1="29.9794" x2="49.3834" y2="29.9794" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint2_linear_4272_63778" x1="42.5446" y1="22.8192" x2="52.103" y2="22.7427" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_EvPods;