const Icon_Cctv = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 30C8 42.1334 17.8698 52 30.0073 52C42.1156 52 52 42.1334 52 30C52 17.8666 42.1447 8 30.0073 8C17.8698 8 8 17.8666 8 30ZM10.6019 30C10.6019 19.3197 19.3089 10.6156 30.0073 10.6156C40.6766 10.6156 49.3835 19.3052 49.3835 30C49.3835 40.6949 40.6766 49.3844 29.9927 49.3844C19.2944 49.3844 10.6019 40.6803 10.6019 30ZM34.4306 26.1291L39.1075 21.0419C40.0922 19.9752 41.7332 20.6316 41.7332 22.0265V32.6112C41.7332 34.0061 40.0101 34.6625 39.1075 33.5958L34.4306 28.1804C33.8562 27.524 33.9383 26.7034 34.4306 26.1291ZM19.4152 20.9598H32.3794C33.1178 20.9598 33.6922 21.6162 33.6101 22.2727L31.0665 32.8573C30.9024 33.4317 30.4101 33.7599 29.9178 33.7599H19.4152C18.7588 33.7599 18.2665 33.2676 18.2665 32.6112V22.0265C18.2665 21.4521 18.7588 20.9598 19.4152 20.9598ZM20.3998 25.5547C20.3998 26.7855 21.3844 27.7701 22.6152 27.7701C23.846 27.7701 24.8306 26.7855 24.8306 25.5547C24.8306 24.3239 23.846 23.3393 22.6152 23.3393C21.3844 23.3393 20.3998 24.3239 20.3998 25.5547ZM24.8305 39.4215C26.9638 39.4215 28.7689 37.6984 28.7689 35.483H20.892C20.892 37.6984 22.6971 39.4215 24.8305 39.4215Z" fill="#79C8B0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 30C8 42.1334 17.8698 52 30.0073 52C42.1156 52 52 42.1334 52 30C52 17.8666 42.1447 8 30.0073 8C17.8698 8 8 17.8666 8 30ZM10.6019 30C10.6019 19.3197 19.3089 10.6156 30.0073 10.6156C40.6766 10.6156 49.3835 19.3052 49.3835 30C49.3835 40.6949 40.6766 49.3844 29.9927 49.3844C19.2944 49.3844 10.6019 40.6803 10.6019 30ZM34.4306 26.1291L39.1075 21.0419C40.0922 19.9752 41.7332 20.6316 41.7332 22.0265V32.6112C41.7332 34.0061 40.0101 34.6625 39.1075 33.5958L34.4306 28.1804C33.8562 27.524 33.9383 26.7034 34.4306 26.1291ZM19.4152 20.9598H32.3794C33.1178 20.9598 33.6922 21.6162 33.6101 22.2727L31.0665 32.8573C30.9024 33.4317 30.4101 33.7599 29.9178 33.7599H19.4152C18.7588 33.7599 18.2665 33.2676 18.2665 32.6112V22.0265C18.2665 21.4521 18.7588 20.9598 19.4152 20.9598ZM20.3998 25.5547C20.3998 26.7855 21.3844 27.7701 22.6152 27.7701C23.846 27.7701 24.8306 26.7855 24.8306 25.5547C24.8306 24.3239 23.846 23.3393 22.6152 23.3393C21.3844 23.3393 20.3998 24.3239 20.3998 25.5547ZM24.8305 39.4215C26.9638 39.4215 28.7689 37.6984 28.7689 35.483H20.892C20.892 37.6984 22.6971 39.4215 24.8305 39.4215Z" fill="url(#paint0_linear_3672_62396)"/>
<defs>
<linearGradient id="paint0_linear_3672_62396" x1="8.52381" y1="26.3333" x2="52.7437" y2="26.7846" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Cctv;