import axiosNoCookie from "axios";
import axios from "../shared/axios";
import useSWR from "swr";
import { API_URL } from "../shared/config";
import { time } from "@amcharts/amcharts4/core";


const fetcher = (url) => axios.get(`${API_URL}${url}`);

const getTimeRangeData = (url) => {
  // const { data, error } = axios.post(`${API_URL}${url}`);
  // return data?.data;
}


//TODO: the function to fetch widget data can be refactored (only one is needed), instead of multiple
//Use the function bellow for all widgets (to be refactored slowly)
export const useWidgetData = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    widgetDetail: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};


export const useGetWidgetTemplates = (isCompany) => {
  const url = isCompany? `/widget/template/company/` : `/widget/template/`;
  const { data, error } = useSWR(url, fetcher);
  return {
    templates: data?.data,
    isLoading: !error && !data?.data,
    isError: error,
  };
};

export const useDeviceStatusWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    deviceStatus: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useRadarChartEnergyWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    radarChart: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useOverviewWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    overview: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useWeatherWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    weather: data?.data?.data,
    isSetup: data?.data?.is_setup,
    isLoadingWidget: !error && !data?.data?.data,
    isErrorWidget: error,
  };
};
export const useTimeCircleWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    TimeCircle: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useRealTimeEnergyWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    RealTimeEnergy: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useTotalValueWidget = (url) => {
  // console.log(localStorage.getItem("timeRange"), "timeRange");
  const timeRange = localStorage.getItem("timeRange");
  let _url = timeRange? `${url}${timeRange}`:url;
  // console.log(_url, "url")

    const { data, error } = useSWR(_url, fetcher);
    return {
      TotalWidget: data?.data,
      isLoadingWidget: !error && !data?.data,
      isErrorWidget: error,
    };
  
  
};

export const useGraphOverViewWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    GraphOverView: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};


export const usePerformanceWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    PerformanceWidget: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useEfficiencyWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    EfficiencyWidget: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useCurrentStatusWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    currentStatus: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const useNetZeroWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    netZero: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
export const usePerformanceDeviceListWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    deviceList: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const usePlantDetailsWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    plantDetails: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useWeatherAQIWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    weather: data?.data?.data,
    isSetup: data?.data?.is_setup,
    isLoadingWidget: !error && !data?.data?.data,
    isErrorWidget: error,
  };
};

export const usePlantLocationWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    plantLocation: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useStaticMapWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    staticMap: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
//TODO: refactor all the battery( they can use just one function, same thing applies to the other widgets)
export const useBatteryWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    battery: data?.data?.data,
    isLoadingWidget: !error && !data?.data?.data,
    isErrorWidget: error,
  };
};

export const useBatteryAlarmWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    battery: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useBatteryPerformanceWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    batteryPerformance: data?.data?.data,
    isLoadingWidget: !error && !data?.data?.data,
    isErrorWidget: error,
  };
};


export const useIslandingWidget =(url) =>{
  const { data, error } = useSWR(url, fetcher)

  return {
    islanding: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useDeviceListControlWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    devices: data?.data?.data,
    isLoadingWidget: !error && !data?.data?.data,
    isErrorWidget: error,
  };
};

export const useRoomStateWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    roomStates: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useGasWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  // console.log(data?.data,"test");
  return {
    gas: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useImageMockupWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    imageMockup: data?.data?.data?.url,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

//TODO: might remove this later
export const useChillWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    imageMockup: data?.data?.data?.url,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useAirWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    airWidget: data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useChillerWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    chiller: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useRectifierWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    rectifier: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};

export const useEventLogWidget = (url) => {
  const { data, error } = useSWR(url, fetcher);
  return {
    eventLog: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
