const AirCurtain_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M483.7 163.4V177.2L197.5 54.1999L130.3 25.2999V12.8999C138.4 12.8999 146.5 14.4999 154.1 17.6999L191.9 33.9999L197.5 36.3999L480 157.9C482.3 158.9 483.7 161 483.7 163.4Z" fill="url(#paint0_linear_7859_111777)"/>
    <path d="M130.3 12.8999V25.3999L60.3004 55.5999L19.4004 73.1999V55.3999L60.3004 37.6999L65.2004 35.5999L107.4 17.2999C114.8 14.2999 122.5 12.8999 130.3 12.8999Z" fill="url(#paint1_linear_7859_111777)"/>
    <path d="M15.2002 190.5L130.3 139.8L483.4 292.6C489.3 295.2 489.4 303.6 483.5 306.2L383.9 350.5L15.2002 190.5Z" fill="url(#paint2_linear_7859_111777)"/>
    <path d="M487.9 177C487.9 174.6 486.5 172.4 484.3 171.5L197.5 47.9999L154.7 29.5999C154.7 29.5999 154.7 29.5999 154.6 29.5999C146.8 26.2999 138.5 24.5999 130.3 24.5999V140.7V140.9V146.2C137.2 146.2 144.2 147.4 150.8 149.7C150.8 149.7 480.3 291.2 484 292.9C487.7 294.6 487.9 299.1 487.9 299.1V177Z" fill="url(#paint3_linear_7859_111777)"/>
    <path d="M40 242.8L91.6 219.6L416.7 360.3L367.8 378.9L40 242.8Z" fill="url(#paint4_linear_7859_111777)"/>
    <path d="M47.6001 243L92.2001 223.1L408.8 360.2L367.7 377.1L47.6001 243Z" fill="url(#paint5_linear_7859_111777)"/>
    <path d="M110.6 170.9L435.1 311L416.7 360.6L92.2002 220.7L110.6 170.9Z" fill="url(#paint6_linear_7859_111777)"/>
    <path d="M110.6 170.8L40 200.7V243L92.2 220.6L110.6 170.8Z" fill="url(#paint7_linear_7859_111777)"/>
    <path d="M52.5 240.8L92.2 223.1L404.6 358.4L367.8 373.4L52.5 240.8Z" fill="url(#paint8_linear_7859_111777)"/>
    <path d="M106 29.5999C106 29.5999 105.9 29.5999 105.9 29.6999L60.2002 49.4999L15.2002 68.9999V190.6L109.2 150.1C116 147.6 123.2 146.3 130.3 146.3V141V140.8V24.5999C122 24.5999 113.8 26.2999 106 29.5999Z" fill="url(#paint9_linear_7859_111777)"/>
    <path d="M309.2 189.5L170.9 130.2V126.6L309.2 185.7V189.5Z" fill="url(#paint10_linear_7859_111777)"/>
    <path d="M309.2 175.8L170.9 116.6V112.9L309.2 172.1V175.8Z" fill="url(#paint11_linear_7859_111777)"/>
    <path d="M309.2 182.6L170.9 123.3V119.7L309.2 178.9V182.6Z" fill="url(#paint12_linear_7859_111777)"/>
    <path d="M309.2 203.1L170.9 143.9V140.3L309.2 199.5V203.1Z" fill="url(#paint13_linear_7859_111777)"/>
    <path d="M309.2 196.3L170.9 137.1V133.4L309.2 192.7V196.3Z" fill="url(#paint14_linear_7859_111777)"/>
    <path d="M309.2 168.9L170.9 109.7V106.1L309.2 165.3V168.9Z" fill="url(#paint15_linear_7859_111777)"/>
    <path d="M309.2 162.1L170.9 102.9V99.1997L309.2 158.5V162.1Z" fill="url(#paint16_linear_7859_111777)"/>
    <path d="M455.1 252.5L316.9 193.2V189.6L455.1 248.7V252.5Z" fill="url(#paint17_linear_7859_111777)"/>
    <path d="M455.1 238.8L316.9 179.5V175.9L455.1 235.1V238.8Z" fill="url(#paint18_linear_7859_111777)"/>
    <path d="M455.1 245.7L316.9 186.4V182.7L455.1 242V245.7Z" fill="url(#paint19_linear_7859_111777)"/>
    <path d="M455.1 266.1L316.9 206.9V203.3L455.1 262.4V266.1Z" fill="url(#paint20_linear_7859_111777)"/>
    <path d="M455.1 259.3L316.9 200.1V196.4L455.1 255.6V259.3Z" fill="url(#paint21_linear_7859_111777)"/>
    <path d="M455.1 231.9L316.9 172.8V169.1L455.1 228.3V231.9Z" fill="url(#paint22_linear_7859_111777)"/>
    <path d="M455.1 225.1L316.9 165.9V162.2L455.1 221.4V225.1Z" fill="url(#paint23_linear_7859_111777)"/>
    <path d="M309.2 141.6L170.9 82.2997V78.6997L309.2 137.9V141.6Z" fill="url(#paint24_linear_7859_111777)"/>
    <path d="M309.2 127.9L170.9 68.6998V64.9998L309.2 124.3V127.9Z" fill="url(#paint25_linear_7859_111777)"/>
    <path d="M309.2 134.8L170.9 75.4998V71.7998L309.2 131V134.8Z" fill="url(#paint26_linear_7859_111777)"/>
    <path d="M309.2 155.3L170.9 95.9999V92.3999L309.2 151.6V155.3Z" fill="url(#paint27_linear_7859_111777)"/>
    <path d="M309.2 148.4L170.9 89.1998V85.4998L309.2 144.8V148.4Z" fill="url(#paint28_linear_7859_111777)"/>
    <path d="M309.2 121.1L170.9 61.7997V58.1997L309.2 117.4V121.1Z" fill="url(#paint29_linear_7859_111777)"/>
    <path d="M309.2 114.2L170.9 54.9998V51.2998L309.2 110.6V114.2Z" fill="url(#paint30_linear_7859_111777)"/>
    <path d="M455.1 204.6L316.9 145.4V141.7L455.1 200.9V204.6Z" fill="url(#paint31_linear_7859_111777)"/>
    <path d="M455.1 191L316.9 131.7V128L455.1 187.3V191Z" fill="url(#paint32_linear_7859_111777)"/>
    <path d="M455.1 197.8L316.9 138.6V134.9L455.1 194.1V197.8Z" fill="url(#paint33_linear_7859_111777)"/>
    <path d="M455.1 218.3L316.9 159.1V155.4L455.1 214.6V218.3Z" fill="url(#paint34_linear_7859_111777)"/>
    <path d="M455.1 211.5L316.9 152.2V148.5L455.1 207.7V211.5Z" fill="url(#paint35_linear_7859_111777)"/>
    <path d="M455.1 184.1L316.9 124.9V121.2L455.1 180.5V184.1Z" fill="url(#paint36_linear_7859_111777)"/>
    <path d="M455.1 177.3L316.9 118.1V114.4L455.1 173.6V177.3Z" fill="url(#paint37_linear_7859_111777)"/>
    <path d="M304.6 328.4L239.7 300.9L249 297.3L314 324.8L304.6 328.4Z" fill="url(#paint38_linear_7859_111777)"/>
    <path d="M291.5 333.5L226.5 306L235.9 302.4L300.8 329.9L291.5 333.5Z" fill="url(#paint39_linear_7859_111777)"/>
    <path d="M381.9 361.1L316.9 333.6L326.3 330L391.2 357.5L381.9 361.1Z" fill="url(#paint40_linear_7859_111777)"/>
    <path d="M368.8 366.2L303.8 338.7L313.1 335.1L378.1 362.6L368.8 366.2Z" fill="url(#paint41_linear_7859_111777)"/>
    <path d="M150.1 263.1L85.2002 235.6L94.5002 232L159.5 259.4L150.1 263.1Z" fill="url(#paint42_linear_7859_111777)"/>
    <path d="M137 268.2L72 240.7L81.4 237.1L146.3 264.5L137 268.2Z" fill="url(#paint43_linear_7859_111777)"/>
    <path d="M227.4 295.7L162.4 268.3L171.8 264.6L236.7 292.1L227.4 295.7Z" fill="url(#paint44_linear_7859_111777)"/>
    <path d="M214.2 300.8L149.3 273.4L158.6 269.7L223.6 297.2L214.2 300.8Z" fill="url(#paint45_linear_7859_111777)"/>
    <path d="M268.3 247.4L269.7 246.5L309.2 265.4L300.7 297.7L299.4 298.5L258.5 280.5L268.3 247.4Z" fill="url(#paint46_linear_7859_111777)"/>
    <path d="M269.7 246.5L310.6 264.6L300.7 297.7L259.9 279.6L269.7 246.5Z" fill="url(#paint47_radial_7859_111777)"/>
    <path d="M280.3 272L300.2 280.7L298.2 287.3L278.3 278.7L280.3 272Z" fill="white"/>
    <path d="M297.8 269.8C298.4 267.9 299.9 266.9 301.4 267.6C302.8 268.3 303.6 270.3 303 272.2C302.4 274.1 300.9 275.1 299.4 274.4C298 273.8 297.3 271.7 297.8 269.8Z" fill="url(#paint48_linear_7859_111777)"/>
    <path d="M290.5 266.2C291.1 264.3 292.6 263.3 294.1 264C295.5 264.7 296.3 266.7 295.7 268.6C295.1 270.5 293.6 271.5 292.1 270.8C290.6 270.1 289.9 268.1 290.5 266.2Z" fill="url(#paint49_linear_7859_111777)"/>
    <path d="M283.1 262.6C283.7 260.7 285.2 259.7 286.7 260.4C288.1 261.1 288.9 263.1 288.3 265C287.7 266.9 286.2 267.9 284.7 267.2C283.3 266.5 282.5 264.5 283.1 262.6Z" fill="url(#paint50_linear_7859_111777)"/>
    <path d="M186.3 318.2C186.2 318.2 186.2 318.2 186.1 318.2L67.7999 268.6C67.4999 268.5 67.3999 268.2 67.4999 267.9C67.5999 267.6 67.8999 267.5 68.1999 267.6L186.5 317.2C186.8 317.3 186.9 317.6 186.8 317.9C186.7 318.1 186.5 318.2 186.3 318.2Z" fill="#A5E5DC"/>
    <path d="M188.9 328.4C188.8 328.4 188.7 328.4 188.6 328.3L64.5996 277.2C64.1996 277 64.0996 276.6 64.1996 276.3C64.3996 275.9 64.7996 275.8 65.0996 275.9L189.1 327C189.5 327.2 189.6 327.6 189.5 327.9C189.4 328.2 189.1 328.4 188.9 328.4Z" fill="#A5E5DC"/>
    <path d="M191.4 338.5C191.3 338.5 191.2 338.5 191 338.4L61.2998 285.8C60.7998 285.6 60.5998 285.1 60.7998 284.6C60.9998 284.1 61.4998 283.9 61.9998 284.1L191.7 336.7C192.2 336.9 192.4 337.4 192.2 337.9C192.1 338.3 191.7 338.5 191.4 338.5Z" fill="#A5E5DC"/>
    <path d="M193.9 348.7C193.8 348.7 193.6 348.7 193.5 348.6L58 294.5C57.4 294.3 57.1 293.6 57.3 293C57.5 292.4 58.2 292.1 58.8 292.3L194.3 346.4C194.9 346.6 195.2 347.3 195 347.9C194.8 348.4 194.4 348.7 193.9 348.7Z" fill="#A5E5DC"/>
    <path d="M196.4 358.8C196.2 358.8 196.1 358.8 195.9 358.7L54.6996 303.1C53.9996 302.8 53.5996 302 53.8996 301.3C54.1996 300.6 54.9996 300.2 55.6996 300.5L196.9 356.2C197.6 356.5 198 357.3 197.7 358C197.5 358.5 197 358.8 196.4 358.8Z" fill="#A5E5DC"/>
    <path d="M198.9 369C198.7 369 198.5 369 198.3 368.9L51.4 311.7C50.6 311.4 50.2 310.4 50.5 309.6C50.8 308.8 51.8 308.4 52.6 308.7L199.5 365.9C200.3 366.2 200.7 367.2 200.4 368C200.2 368.6 199.6 369 198.9 369Z" fill="#A5E5DC"/>
    <path d="M201.4 379.1C201.2 379.1 201 379.1 200.7 379L48.1999 320.3C47.2999 319.9 46.7999 318.9 47.0999 317.9C47.4999 317 48.4999 316.5 49.4999 316.8L202.1 375.5C203 375.9 203.5 376.9 203.2 377.9C202.9 378.7 202.2 379.1 201.4 379.1Z" fill="#A5E5DC"/>
    <path d="M204 389.3C203.8 389.3 203.5 389.3 203.3 389.2L44.8998 329C43.7998 328.6 43.2998 327.4 43.6998 326.3C44.0998 325.2 45.2998 324.7 46.3998 325.1L204.7 385.3C205.8 385.7 206.3 386.9 205.9 388C205.6 388.8 204.8 389.3 204 389.3Z" fill="#A5E5DC"/>
    <path d="M206.5 399.5C206.2 399.5 206 399.5 205.7 399.4L41.5999 337.6C40.3999 337.2 39.7999 335.8 40.2999 334.7C40.6999 333.5 42.0999 332.9 43.1999 333.4L207.3 395.1C208.5 395.5 209.1 396.9 208.6 398C208.3 398.9 207.4 399.5 206.5 399.5Z" fill="#A5E5DC"/>
    <path d="M209 409.6C208.7 409.6 208.4 409.5 208.1 409.4L38.2998 346.2C36.9998 345.7 36.2998 344.3 36.7998 343C37.2998 341.7 38.6998 341 39.9998 341.5L209.8 404.7C211.1 405.2 211.8 406.6 211.3 407.9C211 409 210 409.6 209 409.6Z" fill="#A5E5DC"/>
    <path d="M349.8 381.3C349.7 381.3 349.7 381.3 349.6 381.3L231.3 331.7C231 331.6 230.9 331.3 231 331C231.1 330.7 231.4 330.6 231.7 330.7L350 380.3C350.3 380.4 350.4 380.7 350.3 381C350.1 381.2 350 381.3 349.8 381.3Z" fill="#A5E5DC"/>
    <path d="M359.1 393.6C359 393.6 358.9 393.6 358.8 393.5L234.8 342.4C234.4 342.2 234.3 341.8 234.4 341.5C234.6 341.1 235 341 235.3 341.1L359.3 392.2C359.7 392.4 359.8 392.8 359.7 393.1C359.7 393.5 359.4 393.6 359.1 393.6Z" fill="#A5E5DC"/>
    <path d="M368.5 405.9C368.4 405.9 368.3 405.9 368.1 405.8L238.4 353.2C237.9 353 237.7 352.5 237.9 352C238.1 351.5 238.6 351.3 239.1 351.5L368.8 404.1C369.3 404.3 369.5 404.8 369.3 405.3C369.3 405.7 368.9 405.9 368.5 405.9Z" fill="#A5E5DC"/>
    <path d="M377.9 418.3C377.8 418.3 377.6 418.3 377.5 418.2L242 364C241.4 363.8 241.1 363.1 241.3 362.5C241.5 361.9 242.2 361.6 242.8 361.8L378.4 416C379 416.2 379.3 416.9 379.1 417.5C378.8 418 378.4 418.3 377.9 418.3Z" fill="#A5E5DC"/>
    <path d="M387.3 430.6C387.1 430.6 387 430.6 386.8 430.5L245.6 374.8C244.9 374.5 244.5 373.7 244.8 373C245.1 372.3 245.9 371.9 246.6 372.2L387.8 427.9C388.5 428.2 388.9 429 388.6 429.7C388.4 430.2 387.9 430.6 387.3 430.6Z" fill="#A5E5DC"/>
    <path d="M396.7 442.9C396.5 442.9 396.3 442.9 396.1 442.8L249.2 385.6C248.4 385.3 248 384.3 248.3 383.5C248.6 382.7 249.6 382.3 250.4 382.6L397.3 439.8C398.1 440.1 398.5 441.1 398.2 441.9C398 442.5 397.4 442.9 396.7 442.9Z" fill="#A5E5DC"/>
    <path d="M406.1 455.2C405.9 455.2 405.7 455.2 405.4 455.1L252.8 396.4C251.9 396 251.4 395 251.7 394C252.1 393.1 253.1 392.6 254.1 392.9L406.7 451.6C407.6 452 408.1 453 407.8 454C407.5 454.8 406.8 455.2 406.1 455.2Z" fill="#A5E5DC"/>
    <path d="M415.5 467.5C415.3 467.5 415 467.5 414.8 467.4L256.5 407.2C255.4 406.8 254.9 405.6 255.3 404.5C255.7 403.4 256.9 402.9 258 403.3L416.3 463.5C417.4 463.9 417.9 465.1 417.5 466.2C417.1 467 416.3 467.5 415.5 467.5Z" fill="#A5E5DC"/>
    <path d="M424.9 479.8C424.6 479.8 424.4 479.8 424.1 479.7L260 418C258.8 417.6 258.2 416.2 258.7 415.1C259.1 413.9 260.5 413.3 261.6 413.8L425.7 475.5C426.9 475.9 427.5 477.3 427 478.4C426.7 479.3 425.8 479.8 424.9 479.8Z" fill="#A5E5DC"/>
    <path d="M434.3 492.2C434 492.2 433.7 492.1 433.4 492L263.6 428.8C262.3 428.3 261.6 426.9 262.1 425.6C262.6 424.3 264 423.6 265.3 424.1L435.1 487.3C436.4 487.8 437.1 489.2 436.6 490.5C436.2 491.5 435.3 492.2 434.3 492.2Z" fill="#A5E5DC"/>
    <defs>
    <linearGradient id="paint0_linear_7859_111777" x1="224.135" y1="162.492" x2="468.865" y2="-36.6678" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7859_111777" x1="-49.7719" y1="164.974" x2="143.221" y2="-28.0194" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint2_linear_7859_111777" x1="251.541" y1="377.265" x2="251.541" y2="20.0723" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint3_linear_7859_111777" x1="169.335" y1="20.6471" x2="451.916" y2="303.228" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint4_linear_7859_111777" x1="228.343" y1="399.103" x2="228.343" y2="129.05" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint5_linear_7859_111777" x1="259.577" y1="249.723" x2="224.385" y2="307.85" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint6_linear_7859_111777" x1="369.195" y1="371.393" x2="111.808" y2="114.006" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint7_linear_7859_111777" x1="41.9053" y1="197.371" x2="89.5638" y2="225.182" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint8_linear_7859_111777" x1="226.195" y1="304.71" x2="363.009" y2="8.93776" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint9_linear_7859_111777" x1="139.927" y1="40.4199" x2="5.68861" y2="174.658" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint10_linear_7859_111777" x1="30.3175" y1="319.295" x2="300.783" y2="111.305" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint11_linear_7859_111777" x1="23.7066" y1="310.698" x2="294.172" y2="102.709" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint12_linear_7859_111777" x1="27.012" y1="314.997" x2="297.478" y2="107.007" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint13_linear_7859_111777" x1="36.9283" y1="327.892" x2="307.394" y2="119.902" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint14_linear_7859_111777" x1="33.6229" y1="323.593" x2="304.088" y2="115.604" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint15_linear_7859_111777" x1="20.4012" y1="306.4" x2="290.867" y2="98.4104" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint16_linear_7859_111777" x1="17.0958" y1="302.102" x2="287.561" y2="94.112" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint17_linear_7859_111777" x1="170.46" y1="385.999" x2="413.246" y2="200.153" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint18_linear_7859_111777" x1="163.862" y1="377.379" x2="406.648" y2="191.533" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint19_linear_7859_111777" x1="167.161" y1="381.689" x2="409.947" y2="195.843" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint20_linear_7859_111777" x1="177.058" y1="394.619" x2="419.845" y2="208.772" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint21_linear_7859_111777" x1="173.759" y1="390.309" x2="416.545" y2="204.463" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint22_linear_7859_111777" x1="160.563" y1="373.069" x2="403.349" y2="187.223" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint23_linear_7859_111777" x1="157.264" y1="368.759" x2="400.05" y2="182.913" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint24_linear_7859_111777" x1="7.17946" y1="289.207" x2="277.645" y2="81.2171" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint25_linear_7859_111777" x1="0.568676" y1="280.61" x2="271.034" y2="72.6205" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint26_linear_7859_111777" x1="3.87406" y1="284.909" x2="274.34" y2="76.9189" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint27_linear_7859_111777" x1="13.7904" y1="297.803" x2="284.256" y2="89.8139" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint28_linear_7859_111777" x1="10.485" y1="293.505" x2="280.95" y2="85.5154" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint29_linear_7859_111777" x1="-2.73673" y1="276.312" x2="267.729" y2="68.3221" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint30_linear_7859_111777" x1="-6.04212" y1="272.014" x2="264.423" y2="64.024" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint31_linear_7859_111777" x1="147.366" y1="355.829" x2="390.153" y2="169.983" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint32_linear_7859_111777" x1="140.768" y1="347.21" x2="383.554" y2="161.363" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint33_linear_7859_111777" x1="144.067" y1="351.52" x2="386.853" y2="165.673" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint34_linear_7859_111777" x1="153.964" y1="364.449" x2="396.751" y2="178.603" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint35_linear_7859_111777" x1="150.665" y1="360.139" x2="393.452" y2="174.293" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint36_linear_7859_111777" x1="137.469" y1="342.9" x2="380.255" y2="157.053" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint37_linear_7859_111777" x1="134.17" y1="338.59" x2="376.956" y2="152.744" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint38_linear_7859_111777" x1="330.608" y1="203.778" x2="274.459" y2="317.659" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint39_linear_7859_111777" x1="322.065" y1="199.566" x2="265.916" y2="313.447" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint40_linear_7859_111777" x1="405.718" y1="240.812" x2="349.569" y2="354.692" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint41_linear_7859_111777" x1="397.176" y1="236.6" x2="341.027" y2="350.48" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint42_linear_7859_111777" x1="180.388" y1="129.712" x2="124.238" y2="243.592" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint43_linear_7859_111777" x1="171.845" y1="125.5" x2="115.696" y2="239.38" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint44_linear_7859_111777" x1="255.498" y1="166.745" x2="199.349" y2="280.625" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint45_linear_7859_111777" x1="246.956" y1="162.533" x2="190.806" y2="276.413" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint46_linear_7859_111777" x1="299.888" y1="292.805" x2="263.867" y2="242.268" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <radialGradient id="paint47_radial_7859_111777" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(312.897 312.522) rotate(180) scale(43.4746 46.3048)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <linearGradient id="paint48_linear_7859_111777" x1="296.053" y1="279.242" x2="300.388" y2="258.486" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint49_linear_7859_111777" x1="287.843" y1="277.223" x2="292.178" y2="256.467" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint50_linear_7859_111777" x1="279.576" y1="275.191" x2="283.911" y2="254.435" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    </defs>
    </svg>
     );
}
export default AirCurtain_3d_Icon;