const Icon_Co2Reduction = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#F2F8F6"/>
        <path d="M34.1677 13.591C34.0891 13.5124 31.4157 11.5467 28.1133 12.0971C26.8553 12.333 25.8331 12.8047 25.0468 13.4338V13.3551C25.0468 12.7261 24.4964 12.1757 23.8674 12.1757C23.2384 12.1757 22.688 12.7261 22.688 13.3551C21.9803 12.8047 21.0368 12.333 19.8574 12.1757C16.555 11.6253 13.8817 13.5124 13.803 13.591C13.3313 13.9055 13.174 14.5346 13.4099 15.0063C13.4885 15.1636 14.8252 18.466 18.4421 19.095C18.8352 19.1736 19.1497 19.1736 19.4642 19.1736C20.8009 19.1736 21.8231 18.7805 22.688 18.3087V19.8813C22.688 20.5103 23.2384 21.0607 23.8674 21.0607C24.4964 21.0607 25.0468 20.5103 25.0468 19.8813V16.972C25.8331 15.6354 26.3049 14.8491 28.5065 14.5346C29.9218 14.2987 31.1798 14.6918 31.9661 15.085C31.4943 15.7926 30.7081 16.8148 29.2927 16.8148C28.192 16.8148 26.698 15.6354 25.9904 16.4216C25.5972 16.8934 25.5972 17.6011 26.069 18.0728C26.1476 18.1515 27.2484 19.1736 29.3714 19.1736C33.3028 19.1736 34.6395 15.1636 34.6395 15.0063C34.7967 14.4559 34.6395 13.9055 34.1677 13.591ZM18.8352 16.8148C17.4985 16.5789 16.6336 15.714 16.0832 15.0063C16.8695 14.6132 18.1276 14.2201 19.4642 14.4559C20.7223 14.6918 21.5872 15.3208 22.059 15.8712C21.4299 16.4216 20.3292 17.0507 18.8352 16.8148Z" fill="#FFA800"/>
        <path d="M24.0247 18.7018C22.2949 18.7018 20.5651 19.2522 19.1498 20.2744C18.2062 20.9034 17.4199 21.6897 16.7909 22.6332C16.6337 22.8691 16.555 23.0264 16.3978 23.2623C15.6901 24.5203 15.3756 25.9356 15.3756 27.3509C15.3756 32.0686 19.2284 36 24.0247 36C28.7424 36 32.6738 32.1472 32.6738 27.3509C32.6738 22.5546 28.7424 18.7018 24.0247 18.7018ZM20.0147 32.1472C19.8574 31.5968 19.5429 30.8106 18.9925 30.4174C18.2062 29.6311 18.049 27.5082 19.0711 27.115C20.0933 26.7219 20.7223 28.4517 21.2727 29.0021C21.8231 29.5525 22.1376 29.1594 22.059 28.2945C21.9804 27.4295 22.688 27.115 23.3957 25.9356C24.1033 24.7562 22.688 23.6554 24.1033 23.5768C25.2827 23.4981 25.1255 21.6897 25.0469 21.2179C27.9561 21.7683 30.2363 24.2844 30.2363 27.3509C30.2363 27.6654 30.2363 27.9799 30.1577 28.2945C29.8432 27.7441 29.2141 27.5868 28.8996 27.7441C28.2706 28.0586 28.6637 28.8448 27.7202 28.9235C26.7767 29.0021 26.3049 28.7662 26.3835 29.9456C26.4622 31.1251 24.9682 31.4396 25.5186 32.2259C25.7545 32.619 26.069 33.0121 26.4622 33.1694C25.7545 33.4839 24.8896 33.6412 24.1033 33.6412C22.5308 33.5625 21.1155 33.0908 20.0147 32.1472Z" fill="#FFA800"/>
        <path d="M24.9682 29.5525C24.1033 28.8448 23.4743 28.609 23.0025 29.0807C22.6094 29.5525 22.5308 30.8105 23.6315 31.2823C24.7323 31.7541 25.8331 30.2602 24.9682 29.5525Z" fill="#FFA800"/>
        </svg>
     );
}
 
export default Icon_Co2Reduction;