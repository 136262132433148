const Grid_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.3701 8.30997L22.7301 6.94996L24.2301 2.11996C24.2301 1.89996 24.0101 1.67996 23.7901 1.89996L18.5301 6.83996C18.0901 7.27996 18.3101 7.95997 18.7501 8.17997L21.3001 9.49996L19.6501 14.34C19.6501 14.56 19.8701 14.78 20.0901 14.56L25.5301 9.86996C26.2001 9.24996 25.8301 8.54996 25.3701 8.30997Z" fill={stroke}/>
<path d="M25.3701 8.30997L22.7301 6.94996L24.2301 2.11996C24.2301 1.89996 24.0101 1.67996 23.7901 1.89996L18.5301 6.83996C18.0901 7.27996 18.3101 7.95997 18.7501 8.17997L21.3001 9.49996L19.6501 14.34C19.6501 14.56 19.8701 14.78 20.0901 14.56L25.5301 9.86996C26.2001 9.24996 25.8301 8.54996 25.3701 8.30997Z" fill={stroke}/>
<path d="M27.7301 13.05L25.8101 11.52L24.2601 12.86L25.8601 14.13C25.8601 14.13 23.5601 14.13 22.7801 14.13L21.2201 15.48C20.8901 15.79 20.4701 15.95 20.0501 15.95C19.8401 15.95 19.6201 15.91 19.4101 15.82C18.7701 15.55 18.3301 14.89 18.3301 14.18V13.94L18.3801 13.79L15.1901 15.99L10.3901 12.65L15.2901 9.80999L19.0301 11.9L19.6001 10.22L17.9601 9.37999C17.3001 9.02999 16.8501 8.38999 16.7401 7.65999C16.6701 7.18999 16.7601 6.73998 16.9601 6.32999L15.1901 7.16999L13.3301 6.19999H17.0201C17.1201 6.02998 17.2301 5.87998 17.3701 5.73998L22.0601 1.32999C21.7901 1.19999 21.4801 1.10999 21.1601 1.10999H8.93008C8.54008 1.10999 8.15008 1.20999 7.85008 1.39999L4.52008 3.55999C3.94008 3.94999 3.74008 4.53999 3.94008 5.11999C4.13008 5.70999 4.82008 6.19999 5.60008 6.19999H8.93008L8.44008 9.52999H7.56008C7.17008 9.52999 6.68008 9.72999 6.39008 9.91999L2.28008 13.05C1.69008 13.54 1.50008 14.22 1.69008 14.91C1.98008 15.69 2.67008 16.18 3.55008 16.18H7.56008L5.89008 27.94C5.79008 28.63 6.09008 29.31 6.58008 29.7C6.97008 29.99 7.36008 30.09 7.75008 30.09C7.95008 30.09 8.24008 30.09 8.44008 29.99L14.9001 27.44L21.3601 29.99C21.5601 30.09 21.8501 30.09 22.0501 30.09C22.4401 30.09 22.9301 29.99 23.2201 29.7C23.8101 29.31 24.0001 28.62 23.9101 27.94L22.2501 16.09H26.1701C27.0501 16.09 27.7401 15.6 28.0301 14.92C28.5101 14.23 28.3101 13.54 27.7301 13.05ZM11.3801 3.16999H18.7201L18.9201 4.24999H11.1901L11.3801 3.16999ZM10.7901 7.07999L10.8901 7.17999L13.0401 8.25999L10.5001 9.52999L10.7901 7.07999ZM20.4801 16.19L21.0701 20.4L17.1501 18.15L20.4801 16.19ZM7.07008 4.23998L8.93008 3.06999H9.32008L9.22008 4.23998H7.07008ZM9.52008 15.99L13.2401 18.14L8.83008 20.69L9.52008 15.99ZM4.03008 14.13L7.55008 11.49H8.14008L7.75008 14.13H4.03008ZM7.85008 28.23L8.54008 23.33L13.8301 25.88L7.85008 28.23ZM10.4001 22.06L15.3001 19.22L20.2001 21.96L15.2101 24.21L10.4001 22.06ZM16.2701 25.88L21.5601 23.53L22.2501 28.23L16.2701 25.88Z" fill={stroke}/>
<path d="M27.7301 13.05L25.8101 11.52L24.2601 12.86L25.8601 14.13C25.8601 14.13 23.5601 14.13 22.7801 14.13L21.2201 15.48C20.8901 15.79 20.4701 15.95 20.0501 15.95C19.8401 15.95 19.6201 15.91 19.4101 15.82C18.7701 15.55 18.3301 14.89 18.3301 14.18V13.94L18.3801 13.79L15.1901 15.99L10.3901 12.65L15.2901 9.80999L19.0301 11.9L19.6001 10.22L17.9601 9.37999C17.3001 9.02999 16.8501 8.38999 16.7401 7.65999C16.6701 7.18999 16.7601 6.73998 16.9601 6.32999L15.1901 7.16999L13.3301 6.19999H17.0201C17.1201 6.02998 17.2301 5.87998 17.3701 5.73998L22.0601 1.32999C21.7901 1.19999 21.4801 1.10999 21.1601 1.10999H8.93008C8.54008 1.10999 8.15008 1.20999 7.85008 1.39999L4.52008 3.55999C3.94008 3.94999 3.74008 4.53999 3.94008 5.11999C4.13008 5.70999 4.82008 6.19999 5.60008 6.19999H8.93008L8.44008 9.52999H7.56008C7.17008 9.52999 6.68008 9.72999 6.39008 9.91999L2.28008 13.05C1.69008 13.54 1.50008 14.22 1.69008 14.91C1.98008 15.69 2.67008 16.18 3.55008 16.18H7.56008L5.89008 27.94C5.79008 28.63 6.09008 29.31 6.58008 29.7C6.97008 29.99 7.36008 30.09 7.75008 30.09C7.95008 30.09 8.24008 30.09 8.44008 29.99L14.9001 27.44L21.3601 29.99C21.5601 30.09 21.8501 30.09 22.0501 30.09C22.4401 30.09 22.9301 29.99 23.2201 29.7C23.8101 29.31 24.0001 28.62 23.9101 27.94L22.2501 16.09H26.1701C27.0501 16.09 27.7401 15.6 28.0301 14.92C28.5101 14.23 28.3101 13.54 27.7301 13.05ZM11.3801 3.16999H18.7201L18.9201 4.24999H11.1901L11.3801 3.16999ZM10.7901 7.07999L10.8901 7.17999L13.0401 8.25999L10.5001 9.52999L10.7901 7.07999ZM20.4801 16.19L21.0701 20.4L17.1501 18.15L20.4801 16.19ZM7.07008 4.23998L8.93008 3.06999H9.32008L9.22008 4.23998H7.07008ZM9.52008 15.99L13.2401 18.14L8.83008 20.69L9.52008 15.99ZM4.03008 14.13L7.55008 11.49H8.14008L7.75008 14.13H4.03008ZM7.85008 28.23L8.54008 23.33L13.8301 25.88L7.85008 28.23ZM10.4001 22.06L15.3001 19.22L20.2001 21.96L15.2101 24.21L10.4001 22.06ZM16.2701 25.88L21.5601 23.53L22.2501 28.23L16.2701 25.88Z" fill={stroke}/>

</svg>
    );
}

export default Grid_Icon;