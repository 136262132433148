import React, {useEffect,useRef,useState} from "react";
import { useTotalValueWidget} from "../../../../api/widgets";
import WidgetLoading from "../../../ui/WidgetLoading";
import { Icon_Library } from "../../../..//utils/widgetLibrary/IconTemplates/Icon_library";

export const TotalMonthValueWidget = ({widgetData, theme }) => {
  const {TotalWidget,isLoadingWidget,isErrorWidget } = useTotalValueWidget(widgetData?.dataURL)
  return (
    <>
      {(isLoadingWidget) ? <WidgetLoading /> :(isErrorWidget)?<div>Loading... <WidgetLoading /></div>:
        <>
        
      {widgetData?.layoutOrientation === "vertical" ?
        <section className="flex flex-col justify-around h-full p-4 item-center">
          
        <div>
                <div className="flex flex-col items-center justify-center w-full gap-6">
                  {Icon_Library(TotalWidget?.data?.icon,"h-[35%] w-[35%] xl:h-[20%] xl:w-[20%] 2xl:w-[15%] 2xl:w-[15%] animate-wiggle animate-pulse" )}      
                  <p className="text-xl text-primary">{TotalWidget?.title}</p>
                </div>
        </div>

        <div className="flex flex-row justify-around">
            
            <p className="flex flex-col text-lg text-vekin-green80 ">
              This Month
              <span className="text-lg font-semibold text-primary animate-pulse">{TotalWidget?.data?.total_value?.toLocaleString()} {TotalWidget?.data?.unit}</span>
              </p>
              {/* {TotalWidget?.data?.unit} */}
            <p className="flex flex-col text-lg text-vekin-green80 ">
              Today
              <span className="text-lg font-semibold text-primary animate-pulse">{TotalWidget?.data?.today_value?.toLocaleString()} {TotalWidget?.data?.unit}</span>
              </p>
        </div>
      </section>
      :

      <section className="flex flex-row justify-around h-full p-4 item-center">
          
        <div>
                <div className="flex flex-col items-center justify-center w-full h-[85%]">
                  {Icon_Library(TotalWidget?.data?.icon,"w-[60%] h-[60%] xl:h-[75%] xl:w-[75%] animate-wiggle animate-pulse" )}      
                  <p className="text-xl font-semibold text-primary">{TotalWidget?.title}</p>
                </div>
        </div>

        <div className="flex flex-col justify-center h-full gap-20">
            
            <p className="flex flex-col text-lg text-vekin-green80 ">
              This Month
              <span className="text-lg font-semibold text-primary animate-pulse">{TotalWidget?.data?.total_value?.toLocaleString()} {TotalWidget?.data?.unit}</span>
              </p>
              {/* {TotalWidget?.data?.unit} */}
            <p className="flex flex-col text-lg text-vekin-green80 ">
              Today
              <span className="text-lg font-semibold text-primary animate-pulse">{TotalWidget?.data?.today_value?.toLocaleString()} {TotalWidget?.data?.unit}</span>
              </p>
        </div>
      </section>
      }
      </>




        }
    </>
  );
}