const Poor_Icon_T = ({style}) => {
    return ( 
        <svg className={style} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="url(#paint0_linear_1078_26713)"/>
<path d="M18.6325 19.0944H7.36743C7.15194 19.0944 6.94528 19.0088 6.79291 18.8564C6.64053 18.704 6.55493 18.4973 6.55493 18.2819C6.55493 18.0664 6.64053 17.8597 6.79291 17.7073C6.94528 17.555 7.15194 17.4694 7.36743 17.4694H18.6325C18.848 17.4694 19.0547 17.555 19.2071 17.7073C19.3594 17.8597 19.445 18.0664 19.445 18.2819C19.445 18.4973 19.3594 18.704 19.2071 18.8564C19.0547 19.0088 18.848 19.0944 18.6325 19.0944V19.0944Z" fill="#2C4866"/>
<path d="M20.2974 10.1566H15.8286C15.6131 10.1566 15.4065 10.2422 15.2541 10.3946C15.1017 10.547 15.0161 10.7536 15.0161 10.9691C15.0161 11.1846 15.1017 11.3913 15.2541 11.5437C15.4065 11.696 15.6131 11.7816 15.8286 11.7816H16.438C16.438 12.2126 16.6092 12.6259 16.9139 12.9307C17.2187 13.2354 17.632 13.4066 18.063 13.4066C18.494 13.4066 18.9073 13.2354 19.212 12.9307C19.5168 12.6259 19.688 12.2126 19.688 11.7816H20.2974C20.5129 11.7816 20.7195 11.696 20.8719 11.5437C21.0243 11.3913 21.1099 11.1846 21.1099 10.9691C21.1099 10.7536 21.0243 10.547 20.8719 10.3946C20.7195 10.2422 20.5129 10.1566 20.2974 10.1566V10.1566Z" fill="url(#paint1_linear_1078_26713)"/>
<path d="M10.1411 10.1566H5.67236C5.45688 10.1566 5.25021 10.2422 5.09784 10.3946C4.94547 10.547 4.85986 10.7536 4.85986 10.9691C4.85986 11.1846 4.94547 11.3913 5.09784 11.5437C5.25021 11.696 5.45688 11.7816 5.67236 11.7816H6.28174C6.28174 12.2126 6.45294 12.6259 6.75769 12.9307C7.06244 13.2354 7.47576 13.4066 7.90674 13.4066C8.33772 13.4066 8.75104 13.2354 9.05579 12.9307C9.36053 12.6259 9.53174 12.2126 9.53174 11.7816H10.1411C10.3566 11.7816 10.5633 11.696 10.7156 11.5437C10.868 11.3913 10.9536 11.1846 10.9536 10.9691C10.9536 10.7536 10.868 10.547 10.7156 10.3946C10.5633 10.2422 10.3566 10.1566 10.1411 10.1566V10.1566Z" fill="url(#paint2_linear_1078_26713)"/>
<defs>
<linearGradient id="paint0_linear_1078_26713" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD359"/>
<stop offset="0.0702" stopColor="#FFCD4F"/>
<stop offset="0.2689" stopColor="#FFAF37"/>
<stop offset="0.4806" stopColor="#FFB726"/>
<stop offset="0.7119" stopColor="#FFB21C"/>
<stop offset="1" stopColor="#FF6C19"/>
</linearGradient>
<linearGradient id="paint1_linear_1078_26713" x1="18.063" y1="10.1566" x2="18.063" y2="13.4066" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
<linearGradient id="paint2_linear_1078_26713" x1="7.90673" y1="10.1566" x2="7.90673" y2="13.4066" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
</defs>
</svg>



        


     );
}
 
const Poor_Icon_F =({style}) =>{
    return (
        <svg className={style} width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.13245 0C6.5502 0 5.00348 0.469191 3.68789 1.34824C2.37229 2.22729 1.34691 3.47672 0.741413 4.93853C0.135912 6.40034 -0.022514 8.00887 0.286168 9.56072C0.594849 11.1126 1.35678 12.538 2.4756 13.6569C3.59442 14.7757 5.01988 15.5376 6.57173 15.8463C8.12357 16.155 9.73211 15.9965 11.1939 15.391C12.6557 14.7855 13.9052 13.7602 14.7842 12.4446C15.6633 11.129 16.1324 9.58225 16.1324 8C16.13 5.87901 15.2864 3.84558 13.7866 2.34582C12.2869 0.84605 10.2534 0.00241846 8.13245 0ZM8.13245 15.0588C6.73635 15.0588 5.37159 14.6448 4.21078 13.8692C3.04996 13.0936 2.14521 11.9911 1.61095 10.7013C1.07668 9.41146 0.936894 7.99217 1.20926 6.62289C1.48163 5.25361 2.15391 3.99585 3.14111 3.00866C4.1283 2.02146 5.38606 1.34918 6.75534 1.07681C8.12462 0.804443 9.54391 0.944232 10.8337 1.4785C12.1236 2.01276 13.226 2.91751 14.0016 4.07833C14.7773 5.23914 15.1913 6.60389 15.1913 8C15.1892 9.87147 14.4448 11.6657 13.1215 12.989C11.7981 14.3124 10.0039 15.0567 8.13245 15.0588Z" fill="current"/>
<path d="M8.13856 9.64813C7.47738 9.64898 6.82378 9.78891 6.22021 10.0588C5.61663 10.3288 5.0766 10.7227 4.6352 11.2149C4.59361 11.2609 4.56152 11.3146 4.54075 11.373C4.51999 11.4314 4.51096 11.4933 4.51419 11.5552C4.51742 11.6171 4.53284 11.6777 4.55957 11.7336C4.5863 11.7895 4.62381 11.8396 4.66995 11.881C4.71609 11.9224 4.76994 11.9542 4.82843 11.9747C4.88691 11.9952 4.94887 12.004 5.01074 12.0005C5.07262 11.997 5.13319 11.9813 5.18899 11.9543C5.24478 11.9273 5.2947 11.8896 5.33587 11.8433C5.68755 11.4498 6.11815 11.1348 6.59963 10.9188C7.08111 10.7027 7.60269 10.5905 8.13043 10.5893C8.65816 10.5882 9.18022 10.6982 9.66263 10.9121C10.145 11.1261 10.577 11.4392 10.9304 11.8312C11.0143 11.9231 11.1312 11.9781 11.2555 11.984C11.3798 11.99 11.5014 11.9464 11.5937 11.8629C11.686 11.7794 11.7415 11.6628 11.7479 11.5385C11.7544 11.4142 11.7114 11.2924 11.6283 11.1998C11.187 10.712 10.6486 10.322 10.0476 10.0548C9.44656 9.78759 8.79629 9.64906 8.13856 9.64813Z" fill="current"/>
<path d="M11.8971 6.11765H10.0148C9.88997 6.11765 9.77027 6.16722 9.68202 6.25548C9.59377 6.34373 9.54419 6.46343 9.54419 6.58823C9.54419 6.71304 9.59377 6.83274 9.68202 6.92099C9.77027 7.00924 9.88997 7.05882 10.0148 7.05882H10.956V7.52941C10.956 7.65422 11.0055 7.77391 11.0938 7.86217C11.182 7.95042 11.3017 8 11.4265 8C11.5513 8 11.671 7.95042 11.7593 7.86217C11.8475 7.77391 11.8971 7.65422 11.8971 7.52941V7.05882C12.0219 7.05882 12.1416 7.00924 12.2299 6.92099C12.3181 6.83274 12.3677 6.71304 12.3677 6.58823C12.3677 6.46343 12.3181 6.34373 12.2299 6.25548C12.1416 6.16722 12.0219 6.11765 11.8971 6.11765Z" fill="current"/>
<path d="M5.30886 7.52941C5.30886 7.65422 5.35844 7.77391 5.44669 7.86217C5.53494 7.95042 5.65464 8 5.77945 8C5.90426 8 6.02395 7.95042 6.1122 7.86217C6.20046 7.77391 6.25003 7.65422 6.25003 7.52941V7.05882C6.37484 7.05882 6.49454 7.00924 6.58279 6.92099C6.67104 6.83274 6.72062 6.71304 6.72062 6.58823C6.72062 6.46343 6.67104 6.34373 6.58279 6.25548C6.49454 6.16722 6.37484 6.11765 6.25003 6.11765H4.36768C4.24287 6.11765 4.12318 6.16722 4.03493 6.25548C3.94667 6.34373 3.89709 6.46343 3.89709 6.58823C3.89709 6.71304 3.94667 6.83274 4.03493 6.92099C4.12318 7.00924 4.24287 7.05882 4.36768 7.05882H5.30886V7.52941Z" fill="current"/>
</svg>

        


    )
}

export {Poor_Icon_T,Poor_Icon_F}