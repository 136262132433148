import React from "react";
import imgAddSite from '../../../assets/siteManagement/images/add-site.svg'

function SiteCardAdd(props) {

    function onClickCard(param) {
        if (props.disable) return

        try {
            props.onClick()
        } catch { }
    }
    return (
        <div className="w-24 h-24 shadow-xl rounded-lg mr-2 flex justify-center items-center cursor-pointer"
            style={{ background: 'linear-gradient(167.96deg, #091A27 4.17%, #234157 28.65%, #387484 64.58%, #34A182 100%)' }}
            onClick={(e) => { onClickCard(e) }}
        >
            <div>
                <div className="w-full flex justify-center mb-2">
                    <img className="w-8 h-8" src={imgAddSite} />
                </div>
                <p className="text-white text-xs text-semibold">New site</p>
            </div>

        </div>
    )
}

export default SiteCardAdd