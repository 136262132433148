const Moderate_Icon_T = ({style}) => {
    return ( 
        <svg className={style} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="url(#paint0_linear_1078_26760)"/>
        <path d="M20.2573 19.9069H5.74219C5.5267 19.9069 5.32004 19.8213 5.16766 19.6689C5.01529 19.5165 4.92969 19.3098 4.92969 19.0944C4.92969 18.8789 5.01529 18.6722 5.16766 18.5198C5.32004 18.3675 5.5267 18.2819 5.74219 18.2819H20.2573C20.4728 18.2819 20.6795 18.3675 20.8318 18.5198C20.9842 18.6722 21.0698 18.8789 21.0698 19.0944C21.0698 19.3098 20.9842 19.5165 20.8318 19.6689C20.6795 19.8213 20.4728 19.9069 20.2573 19.9069Z" fill="#2C4866"/>
        <path d="M21.125 9.54687C21.125 8.20068 20.0337 7.10938 18.6875 7.10938C17.3413 7.10938 16.25 8.20068 16.25 9.54687V11.1719C16.25 12.5181 17.3413 13.6094 18.6875 13.6094C20.0337 13.6094 21.125 12.5181 21.125 11.1719V9.54687Z" fill="url(#paint1_linear_1078_26760)"/>
        <path d="M19.2969 10.3593C19.8578 10.3593 20.3125 9.9046 20.3125 9.34369C20.3125 8.78277 19.8578 8.32806 19.2969 8.32806C18.736 8.32806 18.2812 8.78277 18.2812 9.34369C18.2812 9.9046 18.736 10.3593 19.2969 10.3593Z" fill="#FFEFDC"/>
        <path d="M9.75 9.54687C9.75 8.20068 8.65869 7.10938 7.3125 7.10938C5.96631 7.10938 4.875 8.20068 4.875 9.54687V11.1719C4.875 12.5181 5.96631 13.6094 7.3125 13.6094C8.65869 13.6094 9.75 12.5181 9.75 11.1719V9.54687Z" fill="url(#paint2_linear_1078_26760)"/>
        <path d="M7.92188 10.3593C8.48279 10.3593 8.9375 9.9046 8.9375 9.34369C8.9375 8.78277 8.48279 8.32806 7.92188 8.32806C7.36096 8.32806 6.90625 8.78277 6.90625 9.34369C6.90625 9.9046 7.36096 10.3593 7.92188 10.3593Z" fill="#FFEFDC"/>
        <defs>
        <linearGradient id="paint0_linear_1078_26760" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFD359"/>
        <stop offset="0.0702" stopColor="#FFCD4F"/>
        <stop offset="0.2689" stopColor="#FFC037"/>
        <stop offset="0.4806" stopColor="#FFB726"/>
        <stop offset="0.7119" stopColor="#FFB21C"/>
        <stop offset="1" stopColor="#FFB019"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1078_26760" x1="16.5577" y1="8.22955" x2="20.8173" y2="12.4892" gradientUnits="userSpaceOnUse">
        <stop stopColor="#334E66"/>
        <stop offset="1" stopColor="#142F47"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1078_26760" x1="5.18268" y1="8.22955" x2="9.44232" y2="12.4892" gradientUnits="userSpaceOnUse">
        <stop stopColor="#334E66"/>
        <stop offset="1" stopColor="#142F47"/>
        </linearGradient>
        </defs>
        </svg>
        


     );
}
 
const Moderate_Icon_F =({style}) =>{
    return (
        <svg className={style} width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.26489 0C6.68264 0 5.13593 0.469191 3.82033 1.34824C2.50474 2.22729 1.47936 3.47672 0.87386 4.93853C0.268359 6.40034 0.109932 8.00887 0.418614 9.56072C0.727296 11.1126 1.48922 12.538 2.60804 13.6569C3.72686 14.7757 5.15233 15.5376 6.70417 15.8463C8.25602 16.155 9.86455 15.9965 11.3264 15.391C12.7882 14.7855 14.0376 13.7602 14.9166 12.4446C15.7957 11.129 16.2649 9.58225 16.2649 8C16.2625 5.87901 15.4188 3.84558 13.9191 2.34582C12.4193 0.84605 10.3859 0.00241846 8.26489 0ZM8.26489 15.0588C6.86879 15.0588 5.50404 14.6448 4.34322 13.8692C3.18241 13.0936 2.27766 11.9911 1.74339 10.7013C1.20913 9.41146 1.06934 7.99217 1.34171 6.62289C1.61407 5.25361 2.28636 3.99585 3.27355 3.00866C4.26075 2.02146 5.51851 1.34918 6.88779 1.07681C8.25706 0.804443 9.67636 0.944232 10.9662 1.4785C12.256 2.01276 13.3585 2.91751 14.1341 4.07833C14.9097 5.23914 15.3237 6.60389 15.3237 8C15.3216 9.87147 14.5773 11.6657 13.2539 12.989C11.9306 14.3124 10.1364 15.0567 8.26489 15.0588Z" fill="current"/>
        <path d="M12.0573 10.354H4.47169C4.34688 10.354 4.22718 10.4036 4.13893 10.4919C4.05068 10.5801 4.0011 10.6998 4.0011 10.8246C4.0011 10.9494 4.05068 11.0691 4.13893 11.1574C4.22718 11.2456 4.34688 11.2952 4.47169 11.2952H12.0573C12.1821 11.2952 12.3018 11.2456 12.3901 11.1574C12.4783 11.0691 12.5279 10.9494 12.5279 10.8246C12.5279 10.6998 12.4783 10.5801 12.3901 10.4919C12.3018 10.4036 12.1821 10.354 12.0573 10.354Z" fill="current"/>
        <path d="M10.6178 7.99999C10.6796 8 10.7408 7.98783 10.7979 7.96419C10.855 7.94054 10.9069 7.90588 10.9506 7.86218C10.9943 7.81848 11.0289 7.7666 11.0526 7.7095C11.0762 7.6524 11.0884 7.5912 11.0884 7.5294V6.11764C11.0884 5.99283 11.0388 5.87313 10.9506 5.78488C10.8623 5.69663 10.7426 5.64705 10.6178 5.64705C10.493 5.64705 10.3733 5.69663 10.285 5.78488C10.1968 5.87313 10.1472 5.99283 10.1472 6.11764V7.5294C10.1472 7.5912 10.1594 7.6524 10.183 7.7095C10.2067 7.7666 10.2413 7.81848 10.285 7.86218C10.3287 7.90588 10.3806 7.94054 10.4377 7.96419C10.4948 7.98783 10.556 8 10.6178 7.99999Z" fill="current"/>
        <path d="M5.91199 7.99999C5.9738 8 6.03499 7.98783 6.09209 7.96419C6.14919 7.94054 6.20107 7.90588 6.24477 7.86218C6.28847 7.81848 6.32314 7.7666 6.34678 7.7095C6.37043 7.6524 6.38259 7.5912 6.38258 7.5294V6.11764C6.38258 5.99283 6.333 5.87313 6.24475 5.78488C6.1565 5.69663 6.0368 5.64705 5.91199 5.64705C5.78719 5.64705 5.66749 5.69663 5.57924 5.78488C5.49099 5.87313 5.44141 5.99283 5.44141 6.11764V7.5294C5.4414 7.5912 5.45356 7.6524 5.47721 7.7095C5.50085 7.7666 5.53552 7.81848 5.57922 7.86218C5.62292 7.90588 5.6748 7.94054 5.7319 7.96419C5.789 7.98783 5.85019 8 5.91199 7.99999Z" fill="current"/>
        </svg>
        


    )
}

export {Moderate_Icon_T,Moderate_Icon_F}