import React, { useEffect, useRef, useState } from "react";
import imgDownload from '../../../assets/siteManagement/images/download.svg'
import { getImage } from "../../../utils/getImage";

function InputImage(props) {

    const inputImageRef = useRef(null);
    const [imageDetail, setImageDetail] = useState(null)

    useEffect(() => {
        setImageDetail(props.image)
    }, [props.image])

    async function onClickInputImage() {
        inputImageRef.current.click();
    }
    async function onHandleOpenFile(param) {
        let url = '';

        let reader = new FileReader();
        reader.onload = function (e) {
            url = e.target.result;
        };

        reader.readAsDataURL(param.target.files[0]);

        setTimeout(() => {
            // if (param.target.files[0].size < props.imageSize
            //     || !props.imageSize) 

            let detailImage = {
                Path: url,
                File: param.target.files[0],
            }
            try {
                props.onInputImage(detailImage)
            } catch { }

            param.target.value = null;
        }, 500);
    }

    return (
        <div className="flex items-center justify-center w-full h-full overflow-hidden border border-dashed rounded-lg bg-vekin-green-50 border-vekin-green40 ">
            {imageDetail ?
                <img className="w-full h-full cursor-pointer"
                    src={imageDetail.includes('data:')? imageDetail :getImage(imageDetail)}
                    onClick={(e) => { onClickInputImage() }}
                />
                :
                <div className="w-full">
                    <div className="flex justify-center w-full mb-2">
                        <img className="w-7 h-7" src={imgDownload} />
                    </div>
                    <div className="flex justify-center w-full px-6 mb-2">
                        <p className={`text-secondary text-[10px] font-medium text-center`}>
                            Drag and drop to upload asset image
                        </p>
                    </div>
                    <div className="flex justify-center w-full">
                        <div className="flex items-center justify-center h-6 duration-300 ease-in-out cursor-pointer w-28 bg-vekin-green100 rounded-xl hover:bg-vekin-green80"
                            onClick={(e) => { onClickInputImage() }}
                        >
                            <p className={`text-white text-[10px] font-semibold`}>
                                Browse image(s)
                            </p>
                        </div>
                    </div>
                </div>
            }
            <input ref={inputImageRef}
                className="hidden"
                accept="image/*" type={'file'}
                onChange={(e) => { onHandleOpenFile(e) }} />
        </div>
    )
}

export default InputImage;