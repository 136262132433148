import React, {useState, useEffect} from 'react'
import MultiSelectBtn from '../multiSelectBtn'
import { useGetDevice } from "../../../../api/userInfo";
import { SelectChartIcon, SelectNormalIcons } from "../../SetupWidget/SelectWidgetIcon";
import { SketchPicker, CirclePicker, ChromePicker, BlockPicker } from 'react-color';
import {WidgetTitleSetup} from '../widgetTitleSetup';



const RoomStateSetup = ({clearForm, submitForm,updateWidgetTitle, updateWidgetIcon, widgetIcon, updateSetupDetails, setupDetails, selectedWidget}) => {
    const {deviceList, isLoadingDeviceList, isErrorDeviceList} = useGetDevice(localStorage.getItem('plantId'));
    const [multiSelect,setMultiSelect ]= useState({})
    const [iconSelect, SetIconSelect] = useState({});
    // const [sideIconSelect, setSideIconSelect] = useState({});//side icon
    
    const [fields, setFields] =useState([{}])
    const [widgetTitle, setWidgetTitle] = useState(undefined);
    // const [hasSideDevice, setHasSideDevice]= useState(false);

    useEffect(() => {
        if(multiSelect?.data){
            const newFields = [...fields];
            newFields[multiSelect?.index][multiSelect?.field] = multiSelect?.data.length > 1 ? multiSelect?.data : multiSelect?.data[0];
            setFields(newFields);
            updateSetupDetails({
                ...setupDetails,
                "measurement": newFields
            });
        }
    }, [multiSelect]);

    useEffect(() => {
        if(iconSelect?.data){
            const newFields = [...fields];
            newFields[iconSelect?.index]['icon'] = iconSelect?.data;
            setFields(newFields);
            updateSetupDetails({
                ...setupDetails,
                "measurement": newFields
            });
        }
    }, [iconSelect]);

    // useEffect(() => {
    //     if(iconSelect?.data){
    //         const newFields = [...fields];
    //         newFields[sideIconSelect?.index]['side_icon'] = sideIconSelect?.data;
    //         setFields(newFields);
    //         updateSetupDetails({
    //             ...setupDetails,
    //             "measurement": newFields
    //         });
    //     }
    // }, [sideIconSelect]);

    useEffect(() => {
        if(widgetTitle){
            updateSetupDetails({...setupDetails, "title":widgetTitle});
        }
    }, [widgetTitle]);
   

    const onInput = (field) => (e) => {
        const newFields = [...fields];
        // the field name is in the format of field-index
        newFields[field.split("-")[1]][field.split("-")[0]] = e.target.value;
        setFields(newFields);

        updateSetupDetails({
            ...setupDetails,
            "measurement": newFields
        });

       
    };
    
    const addField = () => {
        setFields([...fields, {}]);
    };
    
    const removeField = (index) => {
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
    };

    const FormatData = () => {
       
        // updateSetupDetails({
        //     ...setupDetails,
        //     "measurement": fields
        // });
        submitForm()
    
    }

    // "setup": [
    //     {
    //         "device_id": 1100,
    //         "icon": "icon_name",
    //         "side_icon": "icon_name",
    //         "side_device": 1101,
    //         "side_field": "_temp"
    //     }
    // ]
   
   
    return ( 
        <form>
       
            <SelectNormalIcons updateSetupDetails={updateSetupDetails} widgetIcon={widgetIcon}/> 
        

<div className='pb-2 border-b'>
        <WidgetTitleSetup setWidgetTitle={setWidgetTitle}/>
        {/* <div className="flex flex-row gap-8 mt-3 ">
                            <p  className="col-span-1 text-sm text-gray-500">Has Side Device?</p>

                            <div className="inline-flex items-center gap-4">
                            <label  className="inline-flex items-center gap-2 text-sm text-gray-500">Yes <input type="radio"  name="hasSideDevice" value={true} checked={hasSideDevice} onChange={() => setHasSideDevice(true)}  /></label>
                            <label  className="inline-flex items-center gap-2 text-sm text-gray-500">No <input type="radio"  name="hasSideDevice" value={false} checked={!hasSideDevice} onChange={() => setHasSideDevice(false)} /></label>
                                
                            </div>
                            </div> */}
</div> 


        <section className='mt-4'>
            {fields?.map((field, index) => (
            <div className="flex flex-col gap-4 pb-2 mb-4 border-b" key={index}>

                
                    <div className="relative flex flex-col gap-2">
                    <button onClick={() => removeField(index)} type='button' className='absolute right-0 inline-flex items-center px-1 text-[11px] text-red-400 border rounded-md -top-0 border-red-400/60 hover:bg-red-400/60 hover:text-white'>
                    <i className="ri-close-circle-line"></i>Remove</button>
                        <label htmlFor={`device_id-${index}`} className="col-span-1 text-sm text-gray-500">Device (select one or more devices)</label>
                        <MultiSelectBtn setMultiSelect={setMultiSelect} data={deviceList?.devices} name={`device_id-${index}`} index={index} placeholder="select device" />

                    </div> 

                    <div className=''>
                            <label htmlFor={`icon-${index}`} className="col-span-1 text-sm text-gray-500">Select Device Icon</label>
                            <SelectChartIcon SetIconSelect={SetIconSelect} index={index} fields={fields} selectedWidget={selectedWidget} name={`icon-${index}`}/>                          
                        </div>

                    
                
                {/* {hasSideDevice &&
                    <div className="grid grid-cols-10 gap-2">
                        <div  className="flex flex-col col-span-6 gap-2">
                            <label htmlFor={`side_device-${index}`} className="col-span-1 text-sm text-gray-500">Side Device</label>
                        
                        <MultiSelectBtn isMulti={false} setMultiSelect={setMultiSelect} data={deviceList?.devices} name={`side_device-${index}`} index={index} placeholder="select side device" />

                        
                        </div> 
                        
                        <div  className="flex flex-col col-span-2 gap-2">
                            <label htmlFor={`side_field-${index}`} className="col-span-1 text-sm text-gray-500">Side Device Field</label>
                            <input onChange={onInput(`side_field-${index}`)} autoComplete="off" required type="text" name={`side_field-${index}`}  className="setupModal_input" placeholder="kWh"/>
                        </div>
                        
                        <div  className="flex flex-col col-span-2 gap-2">
                            <label htmlFor={`side_icon-${index}`} className="col-span-1 text-sm text-gray-500">Side Device Icon</label>

                            <SelectChartIcon setSideIconSelect={setSideIconSelect} index={index} fields={fields} selectedWidget={selectedWidget} name={`side_icon-${index}`}/>                          

                        
                        </div>

                    </div>
                } */}
                    
                    

                    
            </div>

            ))}

            <button onClick={addField} type='button' className='inline-flex items-center gap-1 p-1 text-[11px] hover:border-primary border rounded-md'> <i className="ri-add-circle-line"></i>Add Field</button>

         </section>
        
        
        
        {/* ------------ submit button */}
        <div className='flex flex-row justify-between gap-4 mt-4'>
        <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
            Cancel
        </button>

        
            <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                Submit
            </button>  

        
        </div>
</form>
     );
}
 
export default RoomStateSetup;