import Select, { SelectChangeEvent } from '@mui/material/Select';
import { chartIconList, checkCustomIcon } from '../../../utils/widgetLibrary/IconTemplates/checkCustomIcon';
import { iconList_3d, iconList_general, Icon_Library, roomState_iconList, roomState_Icons } from '../../../utils/widgetLibrary/IconTemplates/Icon_library';
import MenuItem from '@mui/material/MenuItem';
import { useState,useEffect } from 'react';
import Button from '../../ui/buttons';

export const SelectNormalIcons = ({updateSetupDetails, setupDetails, insideMeasurement=false}) => {
    
    const [selectedIcon, setSelectedIcon] = useState('')
    const [tab, setTab] = useState('')
    const [AvailableIcons, setAvailableIcons] = useState([])

    console.log(tab)
    
    const handleChange = (e) => {
        setSelectedIcon(e.target.value);
   
        if(insideMeasurement){
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                  ...setupDetails.measurement?.[0],
                  icon: e.target.value
                }]
              });
        }else{
            updateSetupDetails({
                ...setupDetails,
                icon: [e.target.value],
            })
        }
       // console.log(setupDetails)
    };

    useEffect(() => {
        if(tab=='3d'){
            setAvailableIcons(iconList_3d)
        }else if(tab=='general'){
            setAvailableIcons(iconList_general)
        }
        
        return () => {
            setAvailableIcons([])
        }
    }, [tab])
    

    const handleTabsChange = (tab_name) => {
        setTab(tab_name)
        
    }
    
    return ( 
        <>
            {/* <div className="flex flex-col w-full gap-4">
                    <div className="flex flex-col gap-2">
                        <span  className="col-span-1 text-sm text-gray-500"> Select Icon:</span>
                        
                        <ul className="grid w-full h-full grid-cols-4 col-span-3 gap-1 p-2 text-gray-500 list-none border border-gray-300 rounded-md xl:grid-cols-6">
                            
                            {iconList?.map(icon => {
                                    let isChecked = selectedIcon==icon
                                return (
                                    <li key={icon} className={isChecked ? "relative shadow-xl":'opacity-90'}>
                                    {isChecked && <i className="absolute left-0 z-20 text-xl transition ease-in-out bg-white rounded-full duration-400 ri-checkbox-circle-line text-vekin -top-2"></i>}
                                        <input type="radio" name="widget_icon" id={icon} required className="hidden" value={icon}  onChange={(e)=>handleChange(e)}/>
                                        <label className="relative cursor-pointer " htmlFor={icon}>
                                            <div className={`flex justify-center border-2  rounded-md hover:border-vekin ${isChecked && 'border-vekin'}`}>
                                                {Icon_Library(icon, 'h-12 w-12')}
                                            </div>
                                            

                                        </label>
                                    </li>
                                )
                            }
                                )}
                        </ul>
                    </div>

            </div> */}
        
            <div className="mt-3">
                    <p className="text-sm text-gray-500">Select Icon</p>
                    <nav className="flex mt-1 space-x-2" aria-label="Tabs" role="tablist">
                        <Button tabs active={tab==='general'} onClick={()=>handleTabsChange('general')} type="button" role="tab">General Icons</Button>
                        <Button tabs active={tab==='3d'} onClick={()=>handleTabsChange('3d')} type="button"  role="tab">3D Icons</Button>

                    </nav>
            </div>

            <section className='grid w-full grid-cols-5 col-span-3 gap-1 p-2 text-gray-500 list-none border border-gray-200 rounded-b-md xl:grid-cols-7 3xl:grid-cols-11'>
                {AvailableIcons?.map(icon => {
                    let isChecked = selectedIcon===icon
                    return (
                        <div key={icon} className={isChecked ? "relative shadow-xl ":'opacity-90'}>
                        {isChecked && <i className="absolute left-0 z-20 text-xl transition ease-in-out bg-white rounded-full duration-400 ri-checkbox-circle-line text-vekin -top-2"></i>}
                            <input type="radio" name="widget_icon" id={icon} required className="hidden" value={icon}  onChange={(e)=>handleChange(e)}/>
                            <label className="relative cursor-pointer " htmlFor={icon}>
                                <div className={`flex justify-center border p-1 rounded-md hover:border-vekin ${isChecked && 'border-vekin border-2'}`}>
                                    {Icon_Library(icon, 'h-8 w-8',true)}
                                </div>
                            </label>
                        </div>
                    )
                }
                    )}
                
            </section>
        </>
     );
}
 
export const SelectChartIcon = ({SetIconSelect,setSideIconSelect, index, fields, selectedWidget, name=""}) => {
    const handleChange = (event) =>{
        if(name.includes("side")){
            setSideIconSelect({"index":index, "data":event.target.value});
        }else{
            SetIconSelect({"index":index, "data":event.target.value});
            
        }
    } 
        

    const selectedColor = fields[index].color
    const value = name.includes("side") ? fields[index]["side_icon"] : fields[index].icon
  
    return (
    <div className='w-20 h-1/2'>
    {(selectedWidget === "Line Chart" || selectedWidget ==="Radar Chart" )?
                  <Select
                    key={index+name}
                    name={name ||`icon-${index}`}
                    sx={{ '& > fieldset': { border: 'none' } }}
                    className='w-full rounded-md text-gray-700 py-2.5 border  border-gray-300 flex-1 block  text-sm outline-none h-11 MuiSelect-nativeInput '
                    value={value ?? ""}
                    onChange={handleChange}>
                    
                  {chartIconList?.map((icon) => (
                    <MenuItem key={icon} value={icon} className='mx-auto mb-1'> {checkCustomIcon(icon,'h-8 w-8', selectedColor?? "gray" )}</MenuItem> 
                  ))}                  
                  </Select>             
          :
                  <Select
                    key={index+name}
                    name={name || `icon-${index}`}
                    sx={{ '& > fieldset': { border: 'none' } }}
                    className='w-full rounded-md text-gray-700 py-2.5 border  border-gray-300 flex-1 block  text-sm outline-none h-11 MuiSelect-nativeInput '
                    value={value ?? ""} 
                    onChange={handleChange}>
    
                    {roomState_iconList?.map((icon) => (
                        <MenuItem key={icon} value={icon} className='mb-1'> {roomState_Icons(icon,'h-10 w-10' )}</MenuItem>  
                    ))}
                  
                  </Select> 
                         
}
    </div>
        
   
    );
  }