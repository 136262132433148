import { useEffect, useState, useRef } from "react";
import { Link,useNavigate  } from "react-router-dom";
import { useLoggedInUser } from "../../api/userInfo";
import { API_URL } from "../../shared/config";

const SelectSiteHeader = () => {
  const navigate = useNavigate();
  const { user, isLoading, isError } = useLoggedInUser();
  const [selectedCompany, setSelectedCompany] = useState();
  const [selectedPlant, setSelectedPlant] = useState();
  const [filteredCompany, setFilteredCompany] = useState(user?.company);
  const [isDropDown, setIsDropDown] = useState(false);
  const refDropDownList = useRef(null);
  const [searchInput, setSearchInput] = useState("");

  const storeDashboardID = (dash_id, plant_id, company_id) => {
    localStorage.setItem("dashboardId", dash_id);
    localStorage.setItem("plantId", plant_id);
    localStorage.setItem("companyId", company_id);
  };


  useEffect(() => {
    if(searchInput !=''){
      let filtered = user?.company?.filter((company) =>
        company?.name?.toLowerCase().includes(searchInput)
      );
      setFilteredCompany(filtered?.length > 0 ? filtered : user?.company);
    }else{
      setFilteredCompany(user?.company);
    }
  }, [searchInput, user?.company]);

  useEffect(() => {
    if(user?.company){
      const selected_company = user?.company?.filter((company) => company?.id == localStorage.getItem("companyId"))[0];

      if(!selected_company?.plants){
        navigate('/')
      }else{
        setSelectedCompany(selected_company);
        const plant_selected = selected_company?.plants?.filter((plant) => plant?.id == localStorage.getItem("plantId"))[0]
        
        if(!plant_selected){
          navigate('/')
        }else{
          setSelectedPlant(plant_selected);
        }
      }
    }
   
  }, [localStorage.getItem("plantId"), localStorage.getItem("companyId"), user?.company]);

  
  useEffect(() => {
    // to close the dropdown when user click outside the dropdown box
    function handleClickOutside(event) {
      if (
        refDropDownList.current &&
        !refDropDownList.current.contains(event.target)
      ) {
        setIsDropDown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refDropDownList]);

  return (
    <>
      <div ref={refDropDownList} className="relative w-full">
        <button
          className="flex items-center justify-between w-full group"
          onClick={() => setIsDropDown((cur) => !cur)}
        >
          <div className="flex flex-row w-full gap-4">
            { !!selectedCompany?.logo ? (
              
              <div className="flex items-center justify-center w-10 h-10 bg-white border rounded-full">
                         <img alt="logo" src={API_URL + selectedCompany?.logo}
                        className="object-cover p-2 rounded-md"/>
                        </div>


              
            ) : (
              <div className="w-10 h-10 rounded-full bg-gray-200/90"></div>
            )}

            <div className="flex flex-col justify-center">
              <span className="hidden text-sm font-semibold capitalize md:block text-vekin-green100 ">
                {selectedCompany?.name}
              </span>
              <p className="hidden md:inline-flex items-center gap-1 text-[12px] text-primary">
                <i className="ri-building-fill text-vekin"></i>
                {selectedPlant?.name ?? "No Plants"}
              </p>
            </div>
          </div>
          <i
            className={`ri-arrow-right-line text-vekin ${
              selectedCompany?.plants?.length > 0 && isDropDown && "rotate-90"
            }`}
          ></i>
        </button>

        {isDropDown && (
          <div className="absolute z-50 flex flex-col w-[21rem] h-[40rem] overflow-auto  md:w-full gap-4 p-4 py-4 my-3 bg-white border shadow-sm rounded-b-md border-tertiary/25 ">
            {/* search input */}
            <div className="relative mb-2 text-vekin">
              <div className="absolute inset-y-0 left-0 z-20 flex items-center pl-3 pointer-events-none">
                <i className="ri-search-line text-vekin"></i>
              </div>
              <input
                autoComplete="off"
                type="search"
                id="input-group-1"
                onChange={(e) => setSearchInput(e.target.value)}
                className="bg-gray-50 border border-tertiary/25 text-vekin text-sm rounded-lg focus:ring-vekin focus:border-vekin block w-full pl-10 px-2.5 py-1.5 accent-vekin outline-vekin outline-0"
                placeholder="search entity"
              />
            </div>
            {/* list of companies */}
            {filteredCompany?.map((company, index) =>
              company?.plants?.length > 0 ? (
                <div className="pt-1 pl-1 bg-gray-50 hover:shadow">
                  
                  <details key={company?.name + index} className="cursor-pointer" >
                    <summary className="flex justify-between p-2 group">
                    <div className="flex flex-row">
                     <div className="flex flex-row gap-1.5">
                      {!!company?.logo ? (
                        <img
                          className="object-cover object-center w-10 h-10 rounded-full"
                          alt="logo"
                          src={API_URL + company?.logo}
                        />
                      ) : (
                        <div className="w-10 h-10 rounded-full bg-gray-200/90"></div>
                      )}
                      <div className="flex flex-col justify-center">
                        <p  className="text-sm font-semibold capitalize text-vekin-green100"> {company?.name}</p>
                        <p className="inline-flex items-center gap-1 text-[12px] text-primary">
                          <i className=" ri-user-fill text-vekin"></i>
                          {company?.role}
                        </p>
                      </div>
                    </div>
                  </div>
                          <span  className="text-[10px] font-semibold capitalize text-vekin-green100">
                            view plants ( {company?.plants?.length} )
                          <i className="opacity-0 ri-arrow-down-line text-vekin group-hover:opacity-100"></i>
                          </span>
                        
                    </summary>
                    {/* list of plants in a company */}
                    <p className="text-[10px] ml-[0.5rem] underline">List of plants</p>
                    <ul
                      className={`list-disc marker:text-vekin pl-6  px-3 flex flex-col gap-1`}
                    >
                      {company?.plants?.map((plant, index) => (
                          <li key={index+plant?.name}>
                          
                          <Link
                            to={`/plant/dashboard/${plant?.dashboards?.[0]?.id}`}
                            className="flex items-center justify-between group"
                            onClick={() =>
                              storeDashboardID(
                                plant?.dashboards?.[0]?.id,
                                plant?.id,
                                plant?.company
                              )
                            }
                          >
                            <div className="flex flex-col items-center justify-center">
                              <span className="text-[12px] font-semibold capitalize text-vekin-green100 group-hover:underline">
                            
                                {plant?.name}
                              </span>
                            </div>
                            <i className="ri-arrow-right-line text-vekin group-hover:translate-x-1 group-hover:animate-ping"></i>
                          </Link>
                        </li>
                        
                      ))}
                    </ul>
                  </details>
                </div>
              ) : (
                <div className="flex items-center justify-between p-2 rounded-lg group bg-gray-50 hover:shadow">
                  <div className="flex flex-row gap-1.5">
                    { !!company?.logo ? (
                     
                      <div className="flex items-center justify-center w-10 h-10 bg-white border rounded-full">
                         <img alt="logo" src={API_URL + company?.logo}
                        className="object-cover p-2 rounded-md"/>
                        </div>
                    ) : (
                      <div className="w-10 h-10 rounded-full bg-gray-200/90"></div>
                    )}
                    <div className="flex flex-col justify-center">
                      <span className="text-sm font-semibold capitalize text-vekin-green100">
                        {company?.name}
                      </span>
                      <p className="inline-flex items-center gap-1 text-[12px] text-primary">
                        <i className=" ri-user-fill text-vekin"></i>
                        {company?.role}
                      </p>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectSiteHeader;
