import React, { useEffect, useState } from "react";
import CircleProgressBarComponent from "../circle-progressbar";

function SummarySiteCard(props) {
    const [detailSummary, setDetailSummary] = useState({
        Type: null,
        Percentage: 0,
        Image: null,
        Count: 0,
        Text: '',
    })

    useEffect(() => {
        if (props) {
            let detail = {
                ...props
            }
            setDetailSummary(detail);
        }
    }, [props])

    function onGetColorText() {
        let textColor = '';
        if (detailSummary.Type === 1) {
            textColor = 'vekin-green100';
        } else if (detailSummary.Type === 2) {
            textColor = 'orange';
        } else if (detailSummary.Type === 3) {
            textColor = 'blue';
        } else if (detailSummary.Type === 4) {
            textColor = 'vekin-green100';
        }
        return textColor;
    }

    return (
        <div className="w-full h-[60px] flex">
            <CircleProgressBarComponent
                type={detailSummary.Type}
                percentage={detailSummary.Percentage}
                image={detailSummary.Image}
            />
            <div className="pt-2 ml-2">
                <p className={`text-${onGetColorText()} text-base font-bold`}>{detailSummary.Count}</p>
                <p className="text-gray-3 text-xs font-normal">{detailSummary.Text}</p>
            </div>
        </div>
    )
}

export default SummarySiteCard