import { TrashIcon, Icon_Air, Icon_Inveror,Icon_Lighting,Icon_WindTurbine, Icon_Others, 
    Icon_Battery, Icon_BioGas, Icon_BurnedReduction, Icon_CarbonAvoid, Icon_Cctv, 
    Icon_Chill, Icon_Co2Reduction, Icon_Coal, Icon_CoalPotential, Icon_Coin, 
    Icon_CoinSave, Icon_Devices, Icon_Dining, Icon_Electricity, Icon_ElectricPower, 
    Icon_ElectricSolar, Icon_EnergyConsumption, Icon_EnergySaved, Icon_EvPods,
     Icon_Grids, Icon_Kitchen, Icon_NoName, Icon_Other, Icon_PeakSaved, Icon_Power, 
     Icon_Pressure, Icon_Radiation, Icon_RecPotential, Icon_Recycling, Icon_RenewableEnergy, 
     Icon_Shopping, Icon_Solar, Icon_Solar2, Icon_Temperature, Icon_Transport, Icon_Tree, 
     Icon_Tree2, Icon_TreeSaved, Icon_Turbine, Icon_Wind,DryerMachine_Icon,WashingMachine_Icon,
     IroningMachine_Icon,FoldingMachine_Icon,AirMachine_Icon,WaterTank_Icon,KitchenRoom_Icon, 
     Invertor_Icon, Rectifier_Icon, Icon_Battery2 } from "../../../components/widgets/widget_icons/general_icons";

import {Invertor_3d_Icon,Pump_3d_Icon,WindTurbine_3d_Icon,Bulb_3d_Icon,Battery_3d_Icon,Cooler_3d_Icon,SolarCell_3d_Icon,CoolingTower_3d_Icon,
    Air_3d_Icon,AirCurtain_3d_Icon,Grid_3d_Icon,EVcar_3d_Icon,HandHeld_3d_Icon} from "../../../components/widgets/widget_icons/3D_Icons";

    
export const iconList_3d=["Invertor_3d_Icon","Pump_3d_Icon", "WindTurbine_3d_Icon", "Bulb_3d_Icon", "Battery_3d_Icon", "Cooler_3d_Icon", "SolarCell_3d_Icon", "CoolingTower_3d_Icon", "Air_3d_Icon", "AirCurtain_3d_Icon", "Grid_3d_Icon", "EVcar_3d_Icon", "HandHeld_3d_Icon" ]

// a library of all the icons (3d, general, and roomState)
export const Icon_Library=(IconName, styles, isSetupPage)=>{
    let style = `${styles } ${isSetupPage?"":"animate-wiggle"}`
    switch (IconName) {
        /* 
        -------------------------------------------------
                        3D ICONS
        -------------------------------------------------
        */
        case 'Invertor_3d_Icon':
            return <Invertor_3d_Icon style={style} />
        case 'Pump_3d_Icon':
            return <Pump_3d_Icon style={style} />
        case 'WindTurbine_3d_Icon':
            return <WindTurbine_3d_Icon style={style} />
        case 'Bulb_3d_Icon':
            return <Bulb_3d_Icon style={style} />
        case 'Battery_3d_Icon':
            return <Battery_3d_Icon style={style} />
        case 'Cooler_3d_Icon':
            return <Cooler_3d_Icon style={style} />
        case 'SolarCell_3d_Icon':
            return <SolarCell_3d_Icon style={style} />
        case 'CoolingTower_3d_Icon':
            return <CoolingTower_3d_Icon style={style} />
        case 'Air_3d_Icon':
            return <Air_3d_Icon style={style} />
        case 'AirCurtain_3d_Icon':
            return <AirCurtain_3d_Icon style={style} />
        case 'Grid_3d_Icon':
            return <Grid_3d_Icon style={style} />
        case 'EVcar_3d_Icon':
            return <EVcar_3d_Icon style={style} />
        case 'HandHeld_3d_Icon':
            return <HandHeld_3d_Icon style={style} />
        /* 
        -------------------------------------------------
                        GENERAL ICONs
        -------------------------------------------------
        */
        case 'Trash':
            return <TrashIcon style={style} />
        case 'energy_consumption':
           return <Icon_EnergyConsumption style={style} />
        case 'energy_saved':
            return <Icon_EnergySaved style={style} />
        case 'co2_reduction':
            return <Icon_Co2Reduction style={style} />
        case 'burned_reduction':
            return <Icon_BurnedReduction style={style} />
        case 'electricity':
            return <Icon_Electricity style={style} />
        case 'coin':
            return <Icon_Coin style={style} />
        case 'renewable_energy':
            return <Icon_RenewableEnergy style={style} />
        case 'peak_saved':
            return <Icon_PeakSaved style={style} />
        case 'tree_saved':
            return <Icon_TreeSaved style={style} />
        case 'solar':
            return <Icon_Solar style={style} />
        case 'bio_gas':
            return <Icon_BioGas style={style} />
        case 'battery':
            return <Icon_Battery style={style} />
        case 'coal':
            return <Icon_Coal style={style} />
        case 'wind':
            return <Icon_Wind style={style} />
        case 'shopping':
            return <Icon_Shopping style={style} />
        case 'dining':
            return <Icon_Dining style={style} />
        case 'transport':
            return <Icon_Transport style={style} />
        case 'recycling':
            return <Icon_Recycling style={style} />
        case 'tree':
            return <Icon_Tree style={style} />
        case 'other':
            return <Icon_Other style={style} />
        case 'electric_power':
            return <Icon_ElectricPower style={style} />
        case 'coal_potential':
            return <Icon_CoalPotential style={style} />
        case 'rec_potential':
            return <Icon_RecPotential style={style} />
        case 'electric_solar':
            return <Icon_ElectricSolar style={style} />
        case 'solar2':
            return <Icon_Solar2 style={style} />
        case 'turbine':
            return <Icon_Turbine style={style} />
        case 'tree2':
            return <Icon_Tree2 style={style} />
        case 'coin_save':
            return <Icon_CoinSave style={style} />
        case 'carbon_avoid':
            return <Icon_CarbonAvoid style={style} />
        case 'grid':
            return <Icon_Grids style={style} />
        case 'temperature':
            return <Icon_Temperature style={style} />
        case 'radiation':
            return <Icon_Radiation style={style} />
        case 'air':
            return <Icon_Air style={style} />
        case 'power':
            return <Icon_Power style={style} />
        case 'devices':
            return <Icon_Devices style={style} />
        case 'cctv':
            return <Icon_Cctv style={style} />
        case 'ev_pods':
            return <Icon_EvPods style={style} />
        case 'chill':
            return <Icon_Chill style={style} />
        case 'kitchen':
            return <Icon_Kitchen style={style} />
        case 'no_name':
            return <Icon_NoName style={style} />
        case 'pressure':
            return <Icon_Pressure style={style} />
        case 'invertor':
            return <Icon_Inveror style={style} />
        case 'lighting':
            return <Icon_Lighting style={style} />
        case 'wind_turbine':
            return <Icon_WindTurbine style={style} />
        case 'others':
            return <Icon_Others style={style} />
        case 'battery2':
          return <Icon_Battery2 style={style} />

         /* 
        -------------------------------------------------
                        ROOM STATE ICONs
        -------------------------------------------------
        */
        case "DryerMachine_Icon":
          return <DryerMachine_Icon style={style} />;
        case "WashingMachine_Icon":
          return <WashingMachine_Icon style={style} />;
        case "IroningMachine_Icon":
          return <IroningMachine_Icon style={style} />;
        case "FoldingMachine_Icon":
          return <FoldingMachine_Icon style={style} />;
        case "AirMachine_Icon":
          return <AirMachine_Icon style={style} />;
        case "WaterTank_Icon":
          return <WaterTank_Icon style={style} />;
        }


}

export const iconList_general =[
    'energy_consumption', 'energy_saved', 'co2_reduction', 'burned_reduction', 'electricity', 'coin', 'renewable_energy', 'peak_saved',
    'tree_saved', 'solar', 'bio_gas', 'battery', 'coal', 'wind', 'shopping','dining','transport','recycling','tree','other','electric_power',
    'coal_potential','rec_potential','electric_solar','solar2','turbine','tree2', 'coin_save', 'carbon_avoid', 'grid', 'temperature',
    'radiation','air','power','devices','cctv','ev_pods','chill','kitchen',
]

// a library of icons for the room state widget
export const roomState_Icons=(IconName)=>{
    const extraStyle ="inline-flex items-center gap-1";
    switch (IconName) {
        case 'DryerMachine_Icon':
          return <DryerMachine_Icon/>
        case 'WashingMachine_Icon':
            return <WashingMachine_Icon/> 
        case 'IroningMachine_Icon':
            return <IroningMachine_Icon/> 
        case 'FoldingMachine_Icon':
        return <FoldingMachine_Icon/> 
        case 'AirMachine_Icon':
            return <AirMachine_Icon /> 
        case 'WaterTank_Icon':
            return <WaterTank_Icon /> 
        case 'KitchenRoom_Icon':
            return <KitchenRoom_Icon /> 
        case 'Invertor_Icon':
            return <Invertor_Icon /> 
        case 'Rectifier_Icon':
            return <Rectifier_Icon /> 
        case 'Battery_Icon':
          return <Icon_Battery2 style={'h-10 w-10'}/> 

        default:
            return <div className={` rounded-full bg-gray-200/40`}> </div>
    }
}
export const roomState_iconList =['Battery_Icon', 'DryerMachine_Icon', 'WashingMachine_Icon', 'IroningMachine_Icon', 'FoldingMachine_Icon', 'AirMachine_Icon', 'WaterTank_Icon','KitchenRoom_Icon', "Invertor_Icon", "Rectifier_Icon"]




