import React, {useEffect,useRef,useState} from "react";
import { useWeatherAQIWidget} from "../../../../api/widgets";
import { checkCustom_weather_icon } from "../../../../utils/widgetLibrary/IconTemplates/checkCustomIcon";
import { Fair_Icon_F, Fair_Icon_T, Poor_Icon_F, Poor_Icon_T,  Moderate_Icon_F, Moderate_Icon_T, Very_Poor_Icon_F, Very_Poor_Icon_T, Good_Icon_F, Good_Icon_T} from "../icons/weatherAQI";
import WidgetLoading from "../../../ui/WidgetLoading";

// need to fix the sizing of text later
function Template({title,value}) {
    return (
        <>
      <p className="inline-flex flex-col text-[10px] 4xl:text-[12px] font-semibold text-tertiary  ">{title}<span className=" text-[12px] 2xl:text-[15px] 3xl:text-lg text-vekin-green80">{value}</span></p>

        </>
    );
}
const AqiWeather = ({widgetData }) => {
  const {weather,isLoadingWidget,isErrorWidget, isSetup } = useWeatherAQIWidget(widgetData?.dataURL)

  return (
    <>
      {(isLoadingWidget)? <WidgetLoading /> : (isErrorWidget) ? <div>Loading... <WidgetLoading/> </div> :
        <section className="flex flex-col justify-around h-full p-4 item-center">
        

        <div>
            <div className="flex flex-col items-center justify-center w-full">
              <div className="flex justify-center w-full h-full animate-scale">
                 {checkCustom_weather_icon(weather?.weather?.icon, 'ml-5 h-[30%] w-[30%]')}
                
              </div>
                <p className="text-xl font-semibold capitalize text-vekin-green80">{weather?.weather?.description}</p>

            </div>
            
        </div>
        
        <div className="flex flex-col items-center justify-center w-full gap-4">
            
            <div className="flex flex-col items-center">
            <p className="inline-flex items-baseline gap-1 -mt-2 text-[3em] text-vekin-green80">{weather?.temperature} <span className="text-xl">&#8451;</span></p>
            <span className="inline-flex flex-col items-center text-lg xl:text-xl text-tertiary">Today <span className="text-primary">{weather?.date}</span></span>
            </div>
        </div>
    
        <div className="flex flex-col items-center gap-2 px-4 py-2 rounded-md md:flex-row ha md:justify-between bg-vekin-green-25/10">
          <p className="text-sm font-normal">AQI Quality</p>

          <div className="flex flex-row items-center gap-6 3xl:gap-10">
        { (weather?.aqi==5)? <Very_Poor_Icon_T style={'drop-shadow-xl animate-bounce'} /> : <Very_Poor_Icon_F style={'fill-vekin'} />}
        { (weather?.aqi==4)? <Poor_Icon_T style={'drop-shadow-xl animate-bounce'}/> : <Poor_Icon_F style={'fill-vekin'} />}
        { (weather?.aqi==3)? <Moderate_Icon_T style={'drop-shadow-xl animate-bounce'}/> : <Moderate_Icon_F style={'fill-vekin'} />}
        { (weather?.aqi==2)? <Fair_Icon_T style={'drop-shadow-xl animate-bounce'}/> : <Fair_Icon_F style={'fill-vekin'} />}
        { (weather?.aqi==1)? <Good_Icon_T style={'drop-shadow-xl animate-bounce'} /> : <Good_Icon_F style={'fill-vekin'} />}
            
          </div>
          
        </div>
        
        </section>}
    </>
  );
}
export default AqiWeather;

