import React, { useEffect, useState } from "react";
import imgDelete from "../../../assets/siteManagement/images/close-3.svg"

function PopupModalConfirmDelete(props) {
    const [labelMain, setLabelMain] = useState('');
    const [labelSub, setLabelSub] = useState('');
    //==============================
    useEffect(() => {
        const bodyHtml = document.querySelector("body");
        if (props.isOpen) {
            bodyHtml.style.overflow = "hidden";
        } else {
            bodyHtml.style.overflow = "auto";
        }
        return () => {
            bodyHtml.style.overflow = "auto";
        }
    }, [])
    useEffect(() => {
        const bodyHtml = document.querySelector("body");
        if (props.isOpen) {
            bodyHtml.style.overflow = "hidden";
        } else {
            bodyHtml.style.overflow = "auto";
        }
    }, [props.isOpen])
    useEffect(() => {
        setLabelMain(props.labelMain);
    }, [props.labelMain])
    useEffect(() => {
        setLabelSub(props.labelSub);
    }, [props.labelSub])
    //==============================
    function onClickCloseFunc() {
        try {
            props.onClickClose();
        } catch { }
    }
    function onClickConfirmFunc() {
        try {
            props.onClickConfirm();
        } catch { }
    }
    //==============================
    return (
        <div className={`w-full h-full
        fixed top-0 left-0 z-[9999] 
        bg-black-opacity50
        flex justify-center items-center
        ${props.isOpen ? 'flex' : 'none'}
        `}
            style={{
                animation: `${props.isOpen ? 'fade-in 0.3s' : ''}`
            }}
        >
            <div className="w-64 h-72 p-4 rounded shadow-content-box bg-white relative">

                <div className="w-full flex justify-center mt-4">
                    <img className="w-12 h-12" src={imgDelete} />
                </div>

                <div className="w-full flex justify-center mt-4">
                    <p className={`text-vekin-red100 text-base font-bold`}>
                        {labelMain}
                    </p>
                </div>

                <div className="w-full flex justify-center mt-1">
                    <p className={`text-tertiary text-xs font-medium text-center`}>
                        {labelSub}
                    </p>
                </div>

                <div className="w-full absolute bottom-4 left-0">
                    <div className="w-full flex justify-center mt-4">
                        <div className={`w-48 h-10 rounded-lg flex justify-center items-center
                    border-white border border-solid 
                    duration-300 ease-in-out cursor-pointer hover:border-danger`}
                            style={{ background: 'linear-gradient(90.92deg, #DC5D5E 1.04%, #D8907D 95.96%), #D8907D' }}
                            onClick={(e) => { onClickConfirmFunc() }}>
                            <p className={`text-white text-sm font-semibold`}>
                                Confirm
                            </p>
                        </div>
                    </div>

                    <div className="w-full flex justify-center mt-2">
                        <div className={`w-48 h-10 rounded-lg flex justify-center items-center
                    border-quartenary border border-solid
                    duration-300 ease-in-out cursor-pointer
                    hover:border-vekin-green100`}
                            onClick={(e) => { onClickCloseFunc() }}>
                            <p className={`text-vekin-green100 text-sm font-semibold`}>
                                Cancel
                            </p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PopupModalConfirmDelete;


