import React, { useEffect, useState } from "react";

function InputLatLng(props) {
    const [focusInput, setFocusInput] = useState(false);
    const [detailInput, setDetailInput] = useState({
        Require: false,
        ReadOnly: false,
    })
    const [inputValueLatLng, setInputValueLatLng] = useState({
        Latitude: '',
        Longitude: '',
    });

    useEffect(() => {
        const init = async () => {
            let detail = {
                ...detailInput,
                Require: props.require,
                ReadOnly: props.readonly ? true : false,
            }
            setDetailInput(detail);
        };
        init();
    }, [])

    useEffect(() => {
        setInputValueLatLng({
            Latitude: props.valueLat ? props.valueLat : '',
            Longitude: props.valueLng ? props.valueLng : '',
        });
    }, [props.valueLat, props.valueLng])

    async function onInputText(key, e) {
        const value = e.target.value
        const numbers = new RegExp("^-?[0-9]*[.]?[0-9]*$");
        const check = numbers.test(value);
        if(!check) return

        let detail = {
            ...inputValueLatLng
        }
        detail[key] = value;
        setInputValueLatLng(detail);
        try {
            props.onInputText(detail);
        } catch { }
    }

    return (
        <div className={`w-full h-14 rounded px-2 py-1.5
        border border-solid ${focusInput && !detailInput.ReadOnly ? 'border-vekin-green100' : 'border-quartenary'}
        `}>
            <div className="h-full flex">
                <div className="w-1/2 pr-3">
                    <div className="flex">
                        <p className="text-secondary text-xs font-normal">
                            Latitude
                        </p>
                        {detailInput.Require && <p className="text-secondary text-xs font-normal">&nbsp;*</p>}
                    </div>
                    <input
                        className="w-full text-vekin-green100 text-sm focus:outline-0"
                        type={`text`}
                        placeholder={'0.000000'}
                        onFocus={(e) => { setFocusInput(true) }}
                        onBlur={(e) => { setFocusInput(false) }}
                        value={inputValueLatLng.Latitude}
                        readOnly={detailInput.ReadOnly}
                        onInput={(e) => { onInputText('Latitude', e) }}
                    />
                </div>

                <div className="w-px h-full bg-quartenary">
                </div>

                <div className="w-1/2 pl-3">
                    <div className="flex">
                        <p className="text-secondary text-xs font-normal">
                            Longitude
                        </p>
                        {detailInput.Require && <p className="text-secondary text-xs font-normal">&nbsp;*</p>}
                    </div>
                    <input
                        className="w-full text-vekin-green100 text-sm focus:outline-0"
                        type={`text`}
                        placeholder={'0.000000'}
                        onFocus={(e) => { setFocusInput(true) }}
                        onBlur={(e) => { setFocusInput(false) }}
                        value={inputValueLatLng.Longitude}
                        readOnly={detailInput.ReadOnly}
                        onInput={(e) => { onInputText('Longitude', e) }}
                    />
                </div>
            </div>
        </div>
    )
}

export default InputLatLng;