import {create} from 'zustand';

export const useWidgetSetupStore = create((set) => ({
    selectedWidget: '',
    widgetTitle: '',
    widgetIcon: '',
    setupDetails: {},
    updateSelectedWidget: (selectedWidget) => set(() => ({ 'selectedWidget': selectedWidget })),
    updateWidgetIcon: (widgetIcon) => set(() => ({ 'widgetIcon': widgetIcon })),
    updateWidgetTitle: (widgetTitle) => set(() => ({ 'widgetTitle': widgetTitle })),
    updateSetupDetails: (setupDetails) => set(() => ({ 'setupDetails': setupDetails })),
}))