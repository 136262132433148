import React, { useEffect, useState } from "react";
import SiteCardWithDetail from "../site-card-with-detail";

function SiteBoxDetailType2(props) {
    const [listSite, setListSite] = useState([])

    useEffect(() => {
        if (Array.isArray(props.listSite)) {
            let listX = props.listSite;
            listX = listX.filter(x => x.Visible);
            setListSite(listX);
        }
    }, [props.listSite])

    function onToggleActiveSite(param) {
        try {
            props.onChangeActiveSite(param);
        } catch { }
    }

    return (
        <div className="w-full h-full rounded-lg shadow-content-box bg-white">
            <div className="h-[48px] mx-2 mb-2.5 border-b border-solid border-quartenary flex items-center">
                <p className="text-black text-base font-bold">Site Details</p>
            </div>
            <div className="mx-2 overflow-y-auto"
                style={{ height: 'calc(100% - 68px)' }}>
                {listSite.map((value, index) => {
                    return (
                        <SiteCardWithDetail
                            key={value.Id}
                            detailSite={value}
                            onToggle={(e) => { onToggleActiveSite(e) }}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default SiteBoxDetailType2