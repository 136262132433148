import MuiButton from '@mui/material/Button';
import styled from '@emotion/styled';

const CustomButton = styled(MuiButton)`
width:  ${(props) => props.fullWidth ? "100% " : "max-content"};
border-radius: 12px;
border: 2px solid ${(props) => props.disabled ? "#C4C4C4" : props.bordercolor ? props.bordercolor : props.bgcolor};
background-color: ${(props) => props.bgcolor};
color: ${(props) => props.textcolor};
box-shadow:none;
:hover {
    background-color: ${(props) => props.bgcolor};
  }; 
&.Mui-disabled {
    color: white;
    background-color: #C4C4C4;
  }
`;

const Button = ({ startIcon, children, onClick, size, textcolor, bgcolor, bordercolor, fullWidth, style, disable }) => {
    return (
        <CustomButton
            startIcon={startIcon}
            disabled={disable}
            variant="contained"
            onClick={onClick}
            fullWidth={fullWidth}
            bgcolor={bgcolor}
            bordercolor={bordercolor}
            textcolor={textcolor}
            size={size ? size : "Medium"}
            style={style}
        // disabled
        >
            {children}
        </CustomButton>
    );
}


export default Button;