const Icon_Solar2 = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M37.3893 44.3275H26.8477C25.7391 44.3275 24.7514 43.8941 24.056 43.098C23.3606 42.3018 23.0482 41.2436 23.2095 40.125L24.2777 31.5788C24.5801 29.3616 26.5554 27.6282 28.7726 27.6282H39.3041C40.4127 27.6282 41.4004 28.0615 42.0957 28.8577C42.8012 29.6539 43.1035 30.7121 42.9524 31.8307L41.8841 40.3769C41.5818 42.5941 39.6065 44.3275 37.3893 44.3275ZM28.7625 29.5027C27.5027 29.5027 26.3034 30.571 26.1321 31.8307L25.0638 40.3769C24.9832 40.9514 25.1344 41.4754 25.467 41.8584C25.7995 42.2413 26.2833 42.4429 26.8477 42.4429H37.3792C38.639 42.4429 39.8383 41.3746 40.0096 40.1149L41.0779 31.5687C41.1585 30.9942 41.0073 30.4702 40.6747 30.0872C40.3422 29.7043 39.8584 29.5027 39.294 29.5027H28.7625Z" fill="#6CCCDF"/>
<path d="M37.3893 44.3275H26.8477C25.7391 44.3275 24.7514 43.8941 24.056 43.098C23.3606 42.3018 23.0482 41.2436 23.2095 40.125L24.2777 31.5788C24.5801 29.3616 26.5554 27.6282 28.7726 27.6282H39.3041C40.4127 27.6282 41.4004 28.0615 42.0957 28.8577C42.8012 29.6539 43.1035 30.7121 42.9524 31.8307L41.8841 40.3769C41.5818 42.5941 39.6065 44.3275 37.3893 44.3275ZM28.7625 29.5027C27.5027 29.5027 26.3034 30.571 26.1321 31.8307L25.0638 40.3769C24.9832 40.9514 25.1344 41.4754 25.467 41.8584C25.7995 42.2413 26.2833 42.4429 26.8477 42.4429H37.3792C38.639 42.4429 39.8383 41.3746 40.0096 40.1149L41.0779 31.5687C41.1585 30.9942 41.0073 30.4702 40.6747 30.0872C40.3422 29.7043 39.8584 29.5027 39.294 29.5027H28.7625Z" fill="url(#paint0_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M30.0223 43.5113L28.1578 43.2593L30.0324 28.6764L31.8968 28.9283L30.0223 43.5113Z" fill="#6CCCDF"/>
<path d="M30.0223 43.5113L28.1578 43.2593L30.0324 28.6764L31.8968 28.9283L30.0223 43.5113Z" fill="url(#paint1_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M35.9986 43.5113L34.1342 43.2593L36.0188 28.6764L37.8832 28.9283L35.9986 43.5113Z" fill="#6CCCDF"/>
<path d="M35.9986 43.5113L34.1342 43.2593L36.0188 28.6764L37.8832 28.9283L35.9986 43.5113Z" fill="url(#paint2_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M41.481 32.8789H24.6708V34.7635H41.481V32.8789Z" fill="#6CCCDF"/>
<path d="M41.481 32.8789H24.6708V34.7635H41.481V32.8789Z" fill="url(#paint3_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M41.2693 37.4241H24.4591V39.3087H41.2693V37.4241Z" fill="#6CCCDF"/>
<path d="M41.2693 37.4241H24.4591V39.3087H41.2693V37.4241Z" fill="url(#paint4_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M43.2448 34.6526L42.7207 38.8249L43.7501 41.6875C43.7501 41.6875 45.1251 40.3125 45.1251 39.625C45.1251 38.9375 43.2448 34.6526 43.2448 34.6526Z" fill="#6CCCDF"/>
<path d="M43.2448 34.6526L42.7207 38.8249L43.7501 41.6875C43.7501 41.6875 45.1251 40.3125 45.1251 39.625C45.1251 38.9375 43.2448 34.6526 43.2448 34.6526Z" fill="url(#paint5_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M25.1143 19.989C24.5902 19.989 24.177 19.5657 24.177 19.0517V17.1873C24.177 16.6632 24.6003 16.25 25.1143 16.25C25.6383 16.25 26.0515 16.6733 26.0515 17.1873V19.0416C26.0515 19.5657 25.6282 19.989 25.1143 19.989Z" fill="#6CCCDF"/>
<path d="M25.1143 19.989C24.5902 19.989 24.177 19.5657 24.177 19.0517V17.1873C24.177 16.6632 24.6003 16.25 25.1143 16.25C25.6383 16.25 26.0515 16.6733 26.0515 17.1873V19.0416C26.0515 19.5657 25.6282 19.989 25.1143 19.989Z" fill="url(#paint6_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M17.6666 27.1544H15.8123C15.2882 27.1544 14.875 26.7311 14.875 26.2172C14.875 25.6931 15.2983 25.2799 15.8123 25.2799H17.6666C18.1907 25.2799 18.6039 25.7032 18.6039 26.2172C18.6039 26.7311 18.1806 27.1544 17.6666 27.1544Z" fill="#6CCCDF"/>
<path d="M17.6666 27.1544H15.8123C15.2882 27.1544 14.875 26.7311 14.875 26.2172C14.875 25.6931 15.2983 25.2799 15.8123 25.2799H17.6666C18.1907 25.2799 18.6039 25.7032 18.6039 26.2172C18.6039 26.7311 18.1806 27.1544 17.6666 27.1544Z" fill="url(#paint7_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M18.9163 34.5517C18.6744 34.5517 18.4325 34.461 18.2511 34.2796C17.8883 33.9168 17.8883 33.3222 18.2511 32.9493L19.5613 31.6392C19.9241 31.2764 20.5187 31.2764 20.8916 31.6392C21.2544 32.002 21.2544 32.5966 20.8916 32.9695L19.5814 34.2796C19.3899 34.461 19.1581 34.5517 18.9163 34.5517Z" fill="#6CCCDF"/>
<path d="M18.9163 34.5517C18.6744 34.5517 18.4325 34.461 18.2511 34.2796C17.8883 33.9168 17.8883 33.3222 18.2511 32.9493L19.5613 31.6392C19.9241 31.2764 20.5187 31.2764 20.8916 31.6392C21.2544 32.002 21.2544 32.5966 20.8916 32.9695L19.5814 34.2796C19.3899 34.461 19.1581 34.5517 18.9163 34.5517Z" fill="url(#paint8_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M30.6168 21.7829C30.375 21.7829 30.1331 21.6922 29.9517 21.5108C29.5889 21.148 29.5889 20.5534 29.9517 20.1805L31.2618 18.8704C31.6246 18.4975 32.2192 18.5076 32.5921 18.8704C32.9549 19.2332 32.9549 19.8278 32.5921 20.2007L31.282 21.5108C31.0905 21.6922 30.8486 21.7829 30.6168 21.7829Z" fill="#6CCCDF"/>
<path d="M30.6168 21.7829C30.375 21.7829 30.1331 21.6922 29.9517 21.5108C29.5889 21.148 29.5889 20.5534 29.9517 20.1805L31.2618 18.8704C31.6246 18.4975 32.2192 18.5076 32.5921 18.8704C32.9549 19.2332 32.9549 19.8278 32.5921 20.2007L31.282 21.5108C31.0905 21.6922 30.8486 21.7829 30.6168 21.7829Z" fill="url(#paint9_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M20.297 21.8534C20.0551 21.8534 19.8132 21.7627 19.6318 21.5812L18.2511 20.2006C17.8883 19.8377 17.8883 19.2431 18.2511 18.8703C18.6139 18.5074 19.2085 18.5074 19.5814 18.8703L20.9621 20.2509C21.3249 20.6138 21.3249 21.2084 20.9621 21.5812C20.7706 21.7627 20.5288 21.8534 20.297 21.8534Z" fill="#6CCCDF"/>
<path d="M20.297 21.8534C20.0551 21.8534 19.8132 21.7627 19.6318 21.5812L18.2511 20.2006C17.8883 19.8377 17.8883 19.2431 18.2511 18.8703C18.6139 18.5074 19.2085 18.5074 19.5814 18.8703L20.9621 20.2509C21.3249 20.6138 21.3249 21.2084 20.9621 21.5812C20.7706 21.7627 20.5288 21.8534 20.297 21.8534Z" fill="url(#paint10_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M22.6553 31.9113L22.7863 30.833C23.139 28.2429 25.4469 26.2172 28.037 26.2172H31.0201C30.7077 23.345 28.2688 21.1077 25.3159 21.1077C22.1413 21.1077 19.5714 23.6776 19.5714 26.8522C19.5815 29.0592 20.8312 30.9539 22.6553 31.9113Z" fill="#6CCCDF"/>
<path d="M22.6553 31.9113L22.7863 30.833C23.139 28.2429 25.4469 26.2172 28.037 26.2172H31.0201C30.7077 23.345 28.2688 21.1077 25.3159 21.1077C22.1413 21.1077 19.5714 23.6776 19.5714 26.8522C19.5815 29.0592 20.8312 30.9539 22.6553 31.9113Z" fill="url(#paint11_linear_3293_57634)" fill-opacity="0.7"/>
<path d="M30.1925 51.01C18.7113 51.01 9.375 41.6738 9.375 30.1925C9.375 18.7113 18.7113 9.375 30.1925 9.375C41.6738 9.375 50.9963 18.7113 50.9963 30.1925C50.9963 41.6738 41.66 51.01 30.1925 51.01ZM30.1925 11.85C20.0725 11.85 11.85 20.0863 11.85 30.1925C11.85 40.2987 20.0725 48.535 30.1925 48.535C40.2987 48.535 48.535 40.3125 48.535 30.1925C48.535 20.0725 40.2987 11.85 30.1925 11.85Z" fill="#6CCCDF"/>
<path d="M30.1925 51.01C18.7113 51.01 9.375 41.6738 9.375 30.1925C9.375 18.7113 18.7113 9.375 30.1925 9.375C41.6738 9.375 50.9963 18.7113 50.9963 30.1925C50.9963 41.6738 41.66 51.01 30.1925 51.01ZM30.1925 11.85C20.0725 11.85 11.85 20.0863 11.85 30.1925C11.85 40.2987 20.0725 48.535 30.1925 48.535C40.2987 48.535 48.535 40.3125 48.535 30.1925C48.535 20.0725 40.2987 11.85 30.1925 11.85Z" fill="url(#paint12_linear_3293_57634)" fill-opacity="0.7"/>
<defs>
<linearGradient id="paint0_linear_3293_57634" x1="24.5837" y1="26.2366" x2="43.0638" y2="26.9051" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57634" x1="28.4249" y1="27.4402" x2="31.915" y2="27.467" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57634" x1="34.4019" y1="27.4402" x2="37.9014" y2="27.4671" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57634" x1="25.8715" y1="32.7219" x2="40.4839" y2="36.6941" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint4_linear_3293_57634" x1="25.6598" y1="37.267" x2="40.2722" y2="41.2393" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint5_linear_3293_57634" x1="42.8924" y1="34.0663" x2="45.1367" y2="34.0897" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint6_linear_3293_57634" x1="24.3109" y1="15.9384" x2="26.0603" y2="15.9652" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint7_linear_3293_57634" x1="15.1413" y1="25.1237" x2="18.6095" y2="25.334" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint8_linear_3293_57634" x1="18.2065" y1="31.1017" x2="21.1766" y2="31.1922" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint9_linear_3293_57634" x1="29.907" y1="18.3288" x2="32.8771" y2="18.4192" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint10_linear_3293_57634" x1="18.2115" y1="18.3269" x2="21.2474" y2="18.4194" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint11_linear_3293_57634" x1="20.3892" y1="20.2074" x2="31.0653" y2="20.5522" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint12_linear_3293_57634" x1="12.3479" y1="5.90546" x2="51.165" y2="7.08807" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Solar2;