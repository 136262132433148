const Pump_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M336.523 117.3L376.323 108.6C420.723 98.9 465.523 124.9 478.423 168.5C480.723 176.2 482.623 184.8 484.123 194.3C486.223 206.6 487.023 217.6 486.823 227.3C485.923 265.2 459.723 297.8 423.023 307.1L363.323 322.1C363.323 322.1 417.723 305.4 410.323 218C402.923 130.6 336.523 117.3 336.523 117.3Z" fill="url(#paint0_linear_4850_75677)"/>
    <path d="M412.423 210.6C403.523 156.5 365.223 113.5 337.323 117.2C302.423 122 280.123 170.6 287.723 227.2C295.223 283.8 329.723 326.4 364.723 322.3C399.723 318.2 421.123 263.5 412.423 210.6Z" fill="url(#paint1_radial_4850_75677)"/>
    <path d="M175.723 150.3L373.123 105.8C373.123 105.8 432.523 107.9 447.023 202.8C462.123 301.8 409.323 313.2 409.323 313.2L203.623 362.6C203.623 362.6 260.023 345.3 252.323 254.7C244.523 164.2 175.723 150.3 175.723 150.3Z" fill="url(#paint2_linear_4850_75677)"/>
    <path d="M254.323 246.9C245.023 190.8 205.423 146.3 176.523 150.2C140.423 155 117.323 205.5 125.223 264.2C133.123 322.9 168.823 366.9 205.023 362.7C241.223 358.4 263.423 301.7 254.323 246.9Z" fill="url(#paint3_radial_4850_75677)"/>
    <path d="M368.423 120L222.023 151.3C219.323 149.6 216.723 148.2 214.523 147.1L360.423 116.1C363.023 117.1 365.623 118.3 368.423 120Z" fill="url(#paint4_radial_4850_75677)"/>
    <path d="M379.923 128.7L234.023 160C232.123 158.3 230.123 156.8 228.223 155.5L374.423 124.1C376.223 125.5 378.023 127 379.923 128.7Z" fill="url(#paint5_radial_4850_75677)"/>
    <path d="M388.623 137.9L243.523 169.2C242.023 167.5 240.423 165.9 238.923 164.4L384.423 133.1C385.723 134.6 387.223 136.2 388.623 137.9Z" fill="url(#paint6_radial_4850_75677)"/>
    <path d="M395.423 147.5L251.123 178.8C249.823 177 248.623 175.4 247.323 173.8L392.023 142.6C393.223 144.1 394.423 145.7 395.423 147.5Z" fill="url(#paint7_radial_4850_75677)"/>
    <path d="M401.023 157.3L257.523 188.7C256.423 186.9 255.523 185.2 254.423 183.5L398.423 152.3C399.223 153.9 400.123 155.5 401.023 157.3Z" fill="url(#paint8_radial_4850_75677)"/>
    <path d="M405.623 167.5L262.623 198.9C261.823 197.1 260.923 195.3 260.123 193.6L403.323 162.2C404.223 163.9 404.923 165.7 405.623 167.5Z" fill="url(#paint9_radial_4850_75677)"/>
    <path d="M409.423 177.7L266.823 209.2C266.223 207.4 265.423 205.6 264.823 203.8L407.623 172.3C408.223 174.1 408.823 175.8 409.423 177.7Z" fill="url(#paint10_radial_4850_75677)"/>
    <path d="M412.623 188L270.223 219.6C269.723 217.7 269.123 215.9 268.523 214.2L411.023 182.6C411.523 184.4 412.123 186.2 412.623 188Z" fill="url(#paint11_radial_4850_75677)"/>
    <path d="M415.223 198.4L272.823 230.2C272.423 228.3 272.023 226.5 271.523 224.7L413.923 193C414.423 194.8 414.823 196.6 415.223 198.4Z" fill="url(#paint12_radial_4850_75677)"/>
    <path d="M417.323 209.1L274.723 241.1C274.423 239.2 274.123 237.3 273.823 235.5L416.223 203.6C416.523 205.3 416.823 206.9 417.123 208.6C417.223 208.8 417.223 208.9 417.323 209.1Z" fill="url(#paint13_radial_4850_75677)"/>
    <path d="M419.023 219.7L276.123 252C275.923 250.3 275.823 248.6 275.623 246.8C275.623 246.6 275.623 246.5 275.523 246.3L418.223 214.2C418.523 216.1 418.723 217.9 419.023 219.7Z" fill="url(#paint14_radial_4850_75677)"/>
    <path d="M420.323 230.6L277.023 263.3C276.923 261.4 276.823 259.5 276.723 257.6L419.823 225.1C419.923 226.9 420.123 228.7 420.323 230.6Z" fill="url(#paint15_radial_4850_75677)"/>
    <path d="M421.023 241.4L277.123 274.4C277.123 272.5 277.123 270.6 277.123 268.6L420.723 235.8C420.823 237.7 420.923 239.6 421.023 241.4Z" fill="url(#paint16_radial_4850_75677)"/>
    <path d="M421.123 246.8C421.123 248.8 421.123 250.7 421.123 252.5L276.423 285.8C276.623 283.9 276.723 281.9 276.823 279.9L421.123 246.8Z" fill="url(#paint17_radial_4850_75677)"/>
    <path d="M420.923 258C420.823 260 420.623 261.9 420.523 263.8L274.923 297.5C275.223 295.6 275.523 293.6 275.823 291.5L420.923 258Z" fill="url(#paint18_radial_4850_75677)"/>
    <path d="M419.823 269.2C419.523 271.3 419.223 273.2 418.723 275.2L272.023 309.3C272.623 307.3 273.223 305.3 273.723 303.1L419.823 269.2Z" fill="url(#paint19_radial_4850_75677)"/>
    <path d="M417.323 280.9C416.723 283.1 416.023 285.1 415.323 287L267.323 321.7C268.223 319.7 269.223 317.5 270.023 315.3L417.323 280.9Z" fill="url(#paint20_radial_4850_75677)"/>
    <path d="M412.823 293.1C411.623 295.5 410.423 297.7 409.123 299.6L259.623 334.8C261.123 332.7 262.723 330.5 264.023 328L412.823 293.1Z" fill="url(#paint21_radial_4850_75677)"/>
    <path d="M404.123 306.2C400.223 310.4 396.423 312.9 393.723 314.4L244.623 349.7C247.323 347.8 250.623 345.2 254.023 341.7L404.123 306.2Z" fill="url(#paint22_radial_4850_75677)"/>
    <path d="M72.623 177.5L151.623 160.3C151.623 160.3 211.023 162.4 225.523 257.3C240.623 356.3 187.823 367.7 187.823 367.7L100.323 389.8C100.323 389.8 156.723 372.5 149.023 281.9C141.323 191.3 72.623 177.5 72.623 177.5Z" fill="url(#paint23_linear_4850_75677)"/>
    <path d="M151.223 274.2C141.923 218.1 102.323 173.6 73.4229 177.5C37.3229 182.5 14.2229 232.8 22.0229 291.5C29.8229 350.2 65.5229 394.2 101.823 390C138.023 385.7 160.323 329 151.223 274.2Z" fill="url(#paint24_radial_4850_75677)"/>
    <path d="M211.023 233.2L152.423 244.7C151.823 242.8 151.223 241 150.623 239.3L209.223 227.9C209.823 229.7 210.423 231.5 211.023 233.2Z" fill="url(#paint25_radial_4850_75677)"/>
    <path d="M214.123 243.8L155.323 255.5C154.823 253.6 154.323 251.7 153.823 250L212.423 238.4C212.923 240.1 213.323 241.6 213.823 243.3C214.023 243.5 214.023 243.6 214.123 243.8Z" fill="url(#paint26_radial_4850_75677)"/>
    <path d="M216.823 254.3L157.823 266.3C157.423 264.6 157.223 263 156.823 261.2C156.823 261 156.823 260.9 156.623 260.7L215.523 248.9C215.923 250.8 216.323 252.5 216.823 254.3Z" fill="url(#paint27_radial_4850_75677)"/>
    <path d="M41.7227 241.1L83.7227 232C83.7227 232 115.323 233.1 122.923 283.5C130.923 336.1 102.923 342.2 102.923 342.2L56.4227 353.9C56.4227 353.9 86.3227 344.7 82.3227 296.6C78.2227 248.5 41.7227 241.1 41.7227 241.1Z" fill="url(#paint28_linear_4850_75677)"/>
    <path d="M83.4228 292.5C78.5228 262.7 57.4228 239.1 42.1228 241.1C22.9228 243.7 10.6228 270.5 14.8228 301.7C19.0228 332.9 37.9228 356.3 57.2228 354C76.4228 351.7 88.3228 321.6 83.4228 292.5Z" fill="url(#paint29_radial_4850_75677)"/>
    <path d="M91.9229 294V291.2L111.123 286.6V289.3L91.9229 294Z" fill="#48E5D2"/>
    <path d="M91.9229 299.5V296.7L111.123 292.2V294.8L91.9229 299.5Z" fill="#48E5D2"/>
    <path d="M91.9229 304.9V302.1L111.123 297.5V300.3L91.9229 304.9Z" fill="#48E5D2"/>
    <path d="M79.9228 293C75.5228 266.3 56.7228 245.2 42.9228 247C25.7228 249.4 14.7228 273.3 18.5228 301.2C22.2228 329.1 39.2228 350 56.4228 348C73.6228 346 84.2228 319 79.9228 293Z" fill="#CCCCCC"/>
    <path d="M63.4228 295.4C61.4228 283.1 52.7228 273.3 46.3228 274.2C38.4228 275.3 33.3228 286.3 35.0228 299.2C36.7228 312.1 44.6228 321.8 52.5228 320.8C60.5228 319.9 65.4228 307.5 63.4228 295.4Z" fill="url(#paint30_radial_4850_75677)"/>
    <path d="M170.223 132.6L169.823 170.1C169.823 170.1 170.423 197.4 153.223 201.5C146.623 203.1 125.723 183.9 100.723 176.1C91.8229 173.4 79.4229 176 79.4229 176V168.5L78.4229 128.4C78.4229 128.4 80.9229 144.4 119.723 147.3C158.523 150.3 170.223 132.6 170.223 132.6Z" fill="url(#paint31_linear_4850_75677)"/>
    <path d="M122.823 148.4C147.223 149 169.323 140.8 170.123 132.8C171.123 122.8 151.923 113.9 126.523 112.9C101.123 111.9 79.5227 119 78.2227 128.9C77.0227 138.8 99.0227 147.9 122.823 148.4Z" fill="#BABABA"/>
    <path d="M142.322 103.3L142.122 131.2C142.122 131.2 139.822 137.3 123.722 136.8C106.922 136.3 106.822 130.5 106.822 130.5L106.522 101.6C106.522 101.6 107.522 107.9 122.622 109C137.722 110.2 142.322 103.3 142.322 103.3Z" fill="url(#paint32_linear_4850_75677)"/>
    <path d="M123.823 109.4C133.423 109.6 142.023 106.4 142.323 103.3C142.723 99.4 135.223 95.9 125.323 95.5C115.423 95.1 106.923 97.9 106.423 101.7C105.923 105.7 114.523 109.3 123.823 109.4Z" fill="#E6E6E6"/>
    <path d="M124.323 77C89.2228 77 60.8228 89.5 60.8228 104.9C60.8228 120.3 89.2228 132.8 124.323 132.8C159.423 132.8 187.823 120.3 187.823 104.9C187.823 89.5 159.423 77 124.323 77ZM124.323 121.1C95.7228 121.1 72.5228 113.9 72.5228 105C72.5228 96.1 95.8228 87 124.323 87C152.823 87 176.123 96.1 176.123 105C176.123 113.9 153.023 121.1 124.323 121.1Z" fill="url(#paint33_linear_4850_75677)"/>
    <path d="M124.323 79.1001C91.0229 79.1001 63.9229 89.5001 63.9229 102.3C63.9229 115.1 86.1229 127.8 124.323 127.8C157.623 127.8 184.623 115.1 184.623 102.3C184.623 89.5001 157.723 79.1001 124.323 79.1001ZM124.323 123.5C88.7229 123.5 68.9229 112.7 68.9229 102.2C68.9229 91.7001 93.7229 82.3001 124.323 82.3001C154.923 82.3001 179.723 91.7001 179.723 102.2C179.723 112.7 160.123 123.5 124.323 123.5Z" fill="#BABABA"/>
    <path d="M343.123 100.2V132.6L288.823 125.1L307.423 88.6001L343.123 100.2Z" fill="#CCCCCC"/>
    <path d="M343.123 100.2L307.423 88.6001L255.123 100.9L290.723 112.5L343.123 100.2Z" fill="#E6E6E6"/>
    <path d="M343.123 132.6V100.2L290.723 112.5V144.9L343.123 132.6Z" fill="#B3B3B3"/>
    <path d="M290.823 144.8V112.5L255.223 100.9V132.3C255.223 132.3 264.223 130.5 272.623 134.7C282.223 139.6 290.823 144.8 290.823 144.8Z" fill="#CCCCCC"/>
    <path d="M298.223 123V120.1L336.723 110.8V113.6L298.223 123Z" fill="#999999"/>
    <path d="M298.223 128.7V125.9L336.723 116.6V119.4L298.223 128.7Z" fill="#999999"/>
    <path d="M298.223 134.4V131.6L336.723 122.2V125L298.223 134.4Z" fill="#999999"/>
    <path d="M165.923 284.4L189.123 408.2L229.323 397.5L206.723 275.2L165.923 284.4Z" fill="url(#paint34_linear_4850_75677)"/>
    <path d="M189.123 408.2V422.5L229.323 411.1V397.5L189.123 408.2Z" fill="url(#paint35_linear_4850_75677)"/>
    <path d="M165.923 284.4V314.4C165.923 314.4 164.223 365.3 144.223 380L151.623 397.2L189.123 422.5V408.3L165.923 284.4Z" fill="#CCCCCC"/>
    <path d="M350.223 225.9L376.823 369.7L422.823 360.4L396.823 218.4L350.223 225.9Z" fill="url(#paint36_linear_4850_75677)"/>
    <path d="M376.823 369.7V386.1L422.823 376V360.4L376.823 369.7Z" fill="url(#paint37_linear_4850_75677)"/>
    <path d="M350.223 225.9V260.4C350.223 260.4 348.323 318.7 325.323 334.1L333.723 354.3L376.723 386.1V369.8L350.223 225.9Z" fill="#BABABA"/>
    <path d="M328.423 113.7L317.723 111.4C317.723 111.4 309.723 111.3 307.723 121.8C305.723 132.7 312.823 134.2 312.823 134.2L324.623 137.1C324.623 137.1 317.023 134.9 318.023 124.9C319.123 114.9 328.423 113.7 328.423 113.7Z" fill="url(#paint38_linear_4850_75677)"/>
    <path d="M317.823 124C319.123 117.8 324.423 113.1 328.323 113.7C333.223 114.4 336.323 120.1 335.223 126.6C334.123 133.1 329.323 137.8 324.423 137.1C319.523 136.4 316.523 130 317.823 124Z" fill="#CCCCCC"/>
    <path d="M320.523 124.4C321.423 120.2 325.023 117 327.623 117.4C330.923 117.9 333.023 121.8 332.323 126.2C331.623 130.6 328.323 133.8 325.023 133.3C321.823 132.9 319.723 128.5 320.523 124.4Z" fill="url(#paint39_radial_4850_75677)"/>
    <defs>
    <linearGradient id="paint0_linear_4850_75677" x1="401.461" y1="109.285" x2="429.983" y2="300.28" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <radialGradient id="paint1_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(322.265 226.974) rotate(-10.8593) scale(87.6491 138.725)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <linearGradient id="paint2_linear_4850_75677" x1="287.617" y1="144.111" x2="337.995" y2="325.867" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <radialGradient id="paint3_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(160.963 263.915) rotate(-10.8593) scale(90.8098 143.728)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <radialGradient id="paint4_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 54.5478) scale(224.497 224.497)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint5_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 54.3596) scale(224.61 224.61)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint6_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 54.178) scale(224.614 224.614)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint7_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 54.0007) scale(224.582 224.582)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint8_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 53.8259) scale(224.542 224.542)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint9_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 53.6535) scale(224.505 224.505)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint10_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 53.4837) scale(224.472 224.472)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint11_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 53.3156) scale(224.445 224.445)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint12_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 53.1487) scale(224.423 224.423)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint13_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.9845) scale(224.404 224.404)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint14_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.8212) scale(224.389 224.389)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint15_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.6595) scale(224.376 224.376)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint16_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.4981) scale(224.367 224.367)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint17_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.3383) scale(224.36 224.36)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint18_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.1796) scale(224.355 224.355)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint19_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 52.0225) scale(224.351 224.352)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint20_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 51.8643) scale(224.353 224.353)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint21_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 51.706) scale(224.359 224.359)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint22_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(387.946 51.542) scale(224.376 224.376)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <linearGradient id="paint23_linear_4850_75677" x1="125.842" y1="187.016" x2="174.996" y2="364.354" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <radialGradient id="paint24_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(57.8335 291.168) rotate(-10.8593) scale(90.8098 143.728)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <radialGradient id="paint25_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(192.883 164.778) scale(102.005 101.016)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint26_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(194.579 169.701) scale(102.987 101.988)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <radialGradient id="paint27_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(196.019 174.594) scale(103.966 102.958)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </radialGradient>
    <linearGradient id="paint28_linear_4850_75677" x1="69.9899" y1="246.163" x2="96.0979" y2="340.357" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <radialGradient id="paint29_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(33.8669 301.484) rotate(-10.8593) scale(48.234 76.3415)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <radialGradient id="paint30_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(35.4082 313.732) scale(37.3898 37.3898)">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </radialGradient>
    <linearGradient id="paint31_linear_4850_75677" x1="163.386" y1="165.216" x2="78.5873" y2="164.781" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint32_linear_4850_75677" x1="139.615" y1="119.319" x2="106.501" y2="119.15" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint33_linear_4850_75677" x1="116.606" y1="78.5346" x2="133.381" y2="135.632" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint34_linear_4850_75677" x1="181.969" y1="288.539" x2="221.797" y2="399.703" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint35_linear_4850_75677" x1="207.137" y1="403.039" x2="212.202" y2="417.177" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint36_linear_4850_75677" x1="368.581" y1="231.854" x2="407.502" y2="364.33" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint37_linear_4850_75677" x1="397.408" y1="365.097" x2="402.358" y2="381.946" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint38_linear_4850_75677" x1="319.497" y1="113.631" x2="314.453" y2="133.802" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="0.2842" stop-color="#969696"/>
    <stop offset="0.5034" stop-color="#8C8C8C"/>
    <stop offset="0.7009" stop-color="#7C7C7C"/>
    <stop offset="0.8845" stop-color="#656565"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <radialGradient id="paint39_radial_4850_75677" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(332.314 131.168) rotate(-177.826) scale(15.6261 12.8151)">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </radialGradient>
    </defs>
    </svg>
     );
}
 
export default Pump_3d_Icon;