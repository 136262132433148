const Icon_Inveror = ({style}) => {
    return (
        <svg className={style} width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.6286 3.83061V14.4731C7.1461 14.4731 5.1286 12.5006 5.1286 10.0706C5.1286 8.90061 5.5936 7.79811 6.4411 6.96561L9.6286 3.83061ZM5.3911 5.89311C4.3036 6.96561 3.6286 8.44311 3.6286 10.0706C3.6286 13.3331 6.3136 15.9731 9.6286 15.9731C12.9436 15.9731 15.6286 13.3331 15.6286 10.0706C15.6286 8.44311 14.9536 6.96561 13.8661 5.89311L10.1536 2.24061C9.8611 1.95561 9.3961 1.95561 9.1036 2.24061L5.3911 5.89311Z" fill="#6CCCDF"/>
<path d="M9.6286 3.83061V14.4731C7.1461 14.4731 5.1286 12.5006 5.1286 10.0706C5.1286 8.90061 5.5936 7.79811 6.4411 6.96561L9.6286 3.83061ZM5.3911 5.89311C4.3036 6.96561 3.6286 8.44311 3.6286 10.0706C3.6286 13.3331 6.3136 15.9731 9.6286 15.9731C12.9436 15.9731 15.6286 13.3331 15.6286 10.0706C15.6286 8.44311 14.9536 6.96561 13.8661 5.89311L10.1536 2.24061C9.8611 1.95561 9.3961 1.95561 9.1036 2.24061L5.3911 5.89311Z" fill="url(#paint0_linear_2819_56323)" fill-opacity="0.7"/>
<defs>
<linearGradient id="paint0_linear_2819_56323" x1="4.48574" y1="0.864683" x2="15.68" y2="1.15823" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon_Inveror;