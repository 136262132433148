const EvPods_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M19.2552 14.0083L15.8186 13.4538L19.0154 6.95855C19.0953 6.80013 19.0154 6.5625 18.7757 6.5625L13.0214 6.64171C12.9415 6.64171 12.8616 6.72092 12.7817 6.80013C12.7817 6.80013 9.58491 14.7212 9.42507 15.038C9.26523 15.3548 9.50499 15.9885 9.90458 16.0677L13.5809 16.6222L12.0624 23.1967C11.9825 23.4343 12.3821 23.5135 12.462 23.3551L19.5749 14.6419C19.8146 14.4835 19.6548 14.0875 19.2552 14.0083Z" fill={stroke}/>
<path d="M26.8124 13.125C26.1562 13.125 25.5937 13.6875 25.5937 14.3437C25.5937 14.4375 25.5937 14.5312 25.5937 14.7187C25.5937 21.2812 20.1562 26.5312 13.5937 26.25C7.68743 25.9687 2.81243 21.0937 2.53118 15.0937C2.34368 8.53122 7.59368 3.18747 14.0624 3.18747C15.7499 3.18747 17.3437 3.56247 18.7499 4.21872C19.2187 4.40622 19.7812 4.31247 20.1562 3.84372C20.6249 3.28122 20.4374 2.34372 19.7812 1.96872C17.7187 1.03122 15.4687 0.562471 13.0312 0.749971C5.99993 1.31247 0.374929 7.12497 0.0936789 14.0625C-0.187571 22.0312 6.18743 28.6875 14.0624 28.6875C21.7499 28.6875 28.0312 22.4062 28.0312 14.7187C28.0312 14.5312 28.0312 14.4375 28.0312 14.25C28.0312 13.5937 27.4687 13.125 26.8124 13.125Z" fill={stroke}/>
<path d="M29.0625 7.78125V5.8125C29.0625 5.34375 28.6875 4.96875 28.2188 4.96875C27.75 4.96875 27.375 5.34375 27.375 5.8125V7.78125H25.875V5.8125C25.875 5.34375 25.5 4.96875 25.0313 4.96875C24.5625 4.96875 24.1875 5.34375 24.1875 5.8125V7.78125H23.3438V11.4375C23.3438 13.2188 24.8438 14.625 26.625 14.625C28.4062 14.625 29.8125 13.125 29.8125 11.3438V7.6875L29.0625 7.78125Z" fill={stroke}/>

</svg>
    )
}

export default EvPods_Icon;