import MenuHeader from "../MenuHeader";
import PawsICon from "./pawsIcon";
import { useNavigate } from "react-router-dom";
// import { useLoggedInUser } from "../../api/userInfo";
import { useEffect, useState } from "react";
// import { API_URL } from "../../shared/config";
import { useLoggedInUser } from "../../../api/userInfo";
import { API_URL } from "../../../shared/config";
import { getLogOut } from "../../../context/auth/auth_action";
import { AuthContext } from "../../../context/auth/auth_context";
import { useContext } from "react";

const SignageTvHeader = () => {
    const navigate = useNavigate();
    const { user, isLoading, isError } = useLoggedInUser();
    const [selectedCompany, setSelectedCompany] = useState();
    const authContext = useContext(AuthContext);

    const { dispatch } = authContext;

    useEffect(() => {
        if(user?.company){
          const selected_company = user?.company?.filter((company) => company?.id == localStorage.getItem("companyId"))[0];
          if(!selected_company?.plants){
            navigate('/')
          }else{
            setSelectedCompany(selected_company);
          }
        }
      }, [localStorage.getItem("plantId"), localStorage.getItem("companyId"), user?.company]);


      const onLogOut = (event) => {
        getLogOut(dispatch);
        localStorage.clear();
    };


    
    return ( 
        <>
         
        <div className="w-full py-1 bg-white border-b-2 border-gray-100">
            <div className="flex flex-row items-center justify-between px-8" >


          
           
                         <img alt="logo" src={'https://demp-api-dev.vekin.co.th/api/media/pics/logo-primary-002.png'}
                        className="object-cover p-2 rounded-md w-[10%] h-[10%] xl:w-[8%] xl:h-[8%]"/>
                       
           
          
        

          
                <div className="relative">
                    <PawsICon />
                    <h1 className="text-xl font-semibold xl:text-3xl lg:text-2xl text-vekin">Carbon Footprint</h1>
                </div>
                
           

              
                <details className="flex justify-end px-3 ">
                    <summary></summary>
                    <button onClick={onLogOut} className="p-1 px-4 border rounded-xl">
                        <p className="text-base">Sign Out</p>
                    </button>
                </details>
            </div>
        </div>
              
          
           
        </>
     );
}
 
export default SignageTvHeader;


