import React, { useEffect, useState } from "react";
import InputDropDown from "../input-dropdown";
import InputImage from "../input-image";
import InputLatLng from "../input-latlng";
import InputNormal from "../input-normal";

function InputSiteDetailBox(props) {
    const [detailSite, setDetailSite] = useState({
        Id: '',
        No: '',
        NameEn: '',
        NameTh: '',
        Type: '',
        TypeId: '',
        Description: '',
        Address: '',
        Image: '',
        ImageId: 0,
        ImageFile: null,
        ImageFileName: '',
        Latitude: '',
        Longitude: '',
    })
    const [textHeader, setTextHeader] = useState('')
    const [listInputRequired, setListInputRequired] = useState({
        'Name': false,
    });
    //==============================
    useEffect(() => {
        setDetailSite(props.detailSite);
    }, [props.detailSite])
    useEffect(() => {
        setTextHeader(props.isEdit ? 'Edit Site' : 'Add Site')
    }, [props.isEdit])
    useEffect(() => {
        let detailRequired = {
            ...listInputRequired,
        }
        Object.keys(detailRequired).forEach((key, index) => {
            detailRequired[key] = false;
        });
        let listX = props.alertRequiredField;
        if (Array.isArray(listX)) {
            for (let item of listX) {
                if (detailRequired[item] != undefined) {
                    detailRequired[item] = true;
                }
            }
        }
        setListInputRequired(detailRequired);
    }, [props.alertRequiredField])
    //==============================
    function onSetImage(param) {
        let detail = {
            ...detailSite,
            Image: param.Path,
            ImageFile: param.File,
            ImageFileName: param.File.name,
        }
        setDetailSite(detail);
        try {
            props.onChangeDetail(detail);
        } catch { }
    }
    function onInputDetailSite(key, param) {
        let detail = {
            ...detailSite
        }
        if (key === 'LatLng') {
            detail.Latitude = param.Latitude;
            detail.Longitude = param.Longitude;
        } else if (key === 'Type') {
            detail.Type = param.Name;
            detail.TypeId = param.Id;
        } else {
            detail[key] = param;
        }
        setDetailSite(detail);
        try {
            props.onChangeDetail(detail);
        } catch { }
    }
    //==============================
    return (
        <div className="w-full px-4 pb-4 bg-white rounded-lg shadow-content-box">
            <div className="w-full h-[88px] flex items-center">
                <div>
                    <div className="w-40">
                        <p className={`text-black text-xl font-bold`}>
                            {textHeader}
                        </p>
                    </div>
                </div>

                {/* <div className="w-full pl-4">
                    <div className="w-2/6 pl-1 ml-auto mr-0">
                        <InputNormal
                            keyName="Site No."
                            placeholder="Enter Site No."
                            require={false}
                            readonly={true}
                            value={detailSite.No}
                            onInputText={(e) => { onInputDetailSite('No', e) }} />
                    </div>
                </div> */}
            </div>

            <div className="w-full h-px bg-quartenary">
            </div>

            <div className="flex w-full pt-6">
                <div>
                    <div className="w-40 h-40">
                        <InputImage
                            image={detailSite?.Image}
                            onInputImage={(e) => { onSetImage(e) }}
                        />
                    </div>
                </div>

                <div className="w-full pl-4">
                    <p className={`text-secondary text-sm font-normal mb-3.5`}>
                        Site information
                    </p>
                    <div className="flex w-full mb-2">
                        <div className="w-full pr-1">
                            <InputNormal
                                keyName="Name"
                                placeholder="Enter Name"
                                require={true}
                                alert={listInputRequired['Name']}
                                value={detailSite.NameEn}
                                onInputText={(e) => { onInputDetailSite('NameEn', e) }} />
                        </div>
                        <div className="w-full pl-1">
                            <InputNormal
                                keyName="Address"
                                placeholder="Enter Address"
                                require={false}
                                value={detailSite.Address}
                                onInputText={(e) => { onInputDetailSite('Address', e) }} />
                        </div>
                        {/* <div className="w-2/6 pr-1">
                            <InputNormal
                                keyName="Name (English)"
                                placeholder="Enter Name (English)"
                                require={true}
                                value={detailSite.NameEn}
                                onInputText={(e) => { onInputDetailSite('NameEn', e) }} />
                        </div>
                        <div className="w-2/6 px-1">
                            <InputNormal
                                keyName="Name (Thai)"
                                placeholder="Enter Name (Thai)"
                                require={false}
                                value={detailSite.NameTh}
                                onInputText={(e) => { onInputDetailSite('NameTh', e) }} />
                        </div>
                        <div className="w-2/6 pl-1">
                            <InputDropDown
                                keyName="Site type"
                                placeholder="Select type"
                                require={false}
                                selectId={detailSite.TypeId}
                                onSelectItem={(e) => { onInputDetailSite('Type', e) }} />
                        </div> */}
                    </div>
                    <div className="flex w-full mb-2">
                        <div className="w-full pr-1">
                            <InputNormal
                                keyName="Description"
                                placeholder="Enter Description"
                                require={false}
                                value={detailSite.Description}
                                onInputText={(e) => { onInputDetailSite('Description', e) }} />
                        </div>
                        {/* <div className="w-4/6 pr-1">
                            <InputNormal
                                keyName="Description"
                                placeholder="Enter Description"
                                require={false}
                                value={detailSite.Description}
                                onInputText={(e) => { onInputDetailSite('Description', e) }} />
                        </div>
                        <div className="w-2/6 pl-1">
                            <InputLatLng
                                require={false}
                                valueLat={detailSite.Latitude}
                                valueLng={detailSite.Longitude}
                                onInputText={(e) => { onInputDetailSite('LatLng', e) }} />
                        </div> */}
                    </div>
                    {/* <div className="flex w-full mb-2">
                        <div className="w-full pr-1">
                            <InputNormal
                                keyName="Address"
                                placeholder="Enter Address"
                                require={false}
                                value={detailSite.Address}
                                onInputText={(e) => { onInputDetailSite('Address', e) }} />
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default InputSiteDetailBox;