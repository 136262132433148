const HandHeld_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M243.5 59.8999L455 60.1999C461.4 60.0999 463.7 65.1999 460.1 71.6999L262.6 412.2C259 418.7 250.9 424 244.5 424.1L33.5003 423.9C27.1003 424 24.8003 418.9 28.4003 412.4L225.4 71.8999C229 65.3999 237.1 59.9999 243.5 59.8999Z" fill="#3DF9D5"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M251.9 419C255.5 417 258.5 414 260.2 410.9L457.7 70.3999C459.1 67.8999 459.4 65.5999 458.7 64.2999C457.9 62.9999 456.1 62.8999 455.1 62.8999L243.6 62.5999C241.4 62.5999 238.8 63.4999 236.2 64.9999C232.6 66.9999 229.6 69.9999 227.9 73.0999L30.9004 413.6C29.5004 416.1 29.2004 418.4 29.9004 419.7C30.7004 421 32.5004 421.1 33.5004 421.1L244.5 421.3C246.7 421.4 249.3 420.5 251.9 419Z" fill="url(#paint0_linear_7859_111778)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M251.7 419C255.3 416.9 258.3 414 260.1 410.8L455.5 74.3L457.7 70.4L456.4 68.2C455.6 66.9 453.8 66.8 452.8 66.8L245.3 66.5C243.1 66.5 240.4 67.4 237.8 68.9C234.2 71 231.2 73.9 229.4 77.1L34.6001 413.7C33.2001 416.2 32.9001 418.5 33.6001 419.8C33.7001 420 34.4001 421.2 34.4001 421.2C34.4001 421.2 36.3001 421.2 37.2001 421.2L244.1 421.4C246.5 421.4 249.1 420.5 251.7 419Z" fill="url(#paint1_linear_7859_111778)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M253.9 439.8L42.9004 439.6C40.0004 439.7 37.9004 438.6 36.9004 436.8L27.4004 421C28.4004 422.9 30.5004 424 33.5004 423.9L244.5 424.1C245.9 424.1 247.4 423.8 249 423.3L258.4 439C256.9 439.5 255.4 439.8 253.9 439.8Z" fill="url(#paint2_linear_7859_111778)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M469.5 87.4L272 427.9C270.9 429.8 269.4 431.6 267.7 433.3L258.3 417.6C260 416 261.5 414.2 262.6 412.2L460.1 71.7C461.9 68.5 462.2 65.6 461.3 63.5L470.1 78.1C471.7 80.3 471.6 83.6 469.5 87.4Z" fill="url(#paint3_linear_7859_111778)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M258.3 417.6L267.7 433.3C265 435.9 261.6 437.9 258.3 439L248.9 423.3C252.3 422.2 255.6 420.2 258.3 417.6Z" fill="url(#paint4_linear_7859_111778)"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M284.5 344.9L433.5 95.6998L424.3 80.4998L256 80.2998L230.4 95.0998L82.1001 344.9L90.8001 360C91.2001 360 259.6 360.3 259.6 360.3L284.5 344.9Z" fill="url(#paint5_linear_7859_111778)"/>
    <path d="M366.2 168.3L396.9 115.6L359.7 115.5L335.2 157.5L341.4 168.2L366.2 168.3ZM395.3 116.5L365.7 167.4H341.9L336.2 157.6L360.1 116.5H395.3Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M318.7 168L349.4 115.3L312.2 115.2L287.7 157.2L293.9 167.9L318.7 168ZM347.8 116.1L318.2 167H294.4L288.7 157.2L312.6 116.1H347.8Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M259.6 360.3L285.1 345.6L285.2 345.5L347.6 244.5H333.9L341.1 232.1H354.8L363.6 216.9H349.9L357.1 204.5H370.8L370.9 204.3L433.5 95.7998L424.8 80.6998L347.8 80.5998L339.5 94.8998H325L333.4 80.5998L256 80.2998L230.5 94.9998L230.4 95.0998L167.5 204.1H181.5L174.3 216.5H160.3L151.5 231.7H165.5L158.2 244.2L144.3 244.1L144.2 244.3L82.1001 344.9L90.8001 360L151.9 360.1L158.7 348.3H173.1L166.3 360.1H183.9L190.7 348.3H205.1L198.3 360.1L259.6 360.3ZM284.5 344.9L259.4 359.3L199.8 359.2L206.6 347.4H190.1L183.3 359.2H167.8L174.6 347.4H158.1L151.3 359.2L91.3001 359.1L83.2001 344.9L144.9 245L158.8 245.1L167.1 230.8H153.1L160.9 217.4H174.9L183.2 203.2H169.2L231.2 95.6998L256.3 81.2998L331.9 81.3998L323.5 95.6998H340.1L348.4 81.3998L424.4 81.4998L432.5 95.5998L370.3 203.4H356.6L348.3 217.6H362L354.2 231H340.5L332.2 245.2H345.9L284.5 344.9Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M264.2 341.2L264.3 341L346.2 203.5L261.4 203.3L261.3 203.5L179.4 341L264.2 341.2ZM344.6 204.4L263.6 340.3L180.9 340.1L261.9 204.2L344.6 204.4Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M223.1 247.1L250.7 203.3L193.7 203.2L166.1 247L223.1 247.1ZM249.1 204.2L222.6 246.2L167.7 246.1L194.2 204.1L249.1 204.2Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M257.5 150C260.5 148.3 263.1 145.9 264.6 143.3C266.1 140.7 266.4 138.2 265.3 136.3C264.2 134.4 261.9 133.4 258.9 133.3C252.9 133.3 245.5 137.5 242.4 142.8C240.9 145.4 240.6 147.9 241.7 149.8C242.8 151.7 245.1 152.7 248.1 152.8C251.2 152.7 254.5 151.7 257.5 150ZM250 136.8C252.8 135.2 256 134.2 258.9 134.2C261.6 134.2 263.6 135.1 264.5 136.7C265.4 138.3 265.2 140.5 263.8 142.8C260.9 147.8 253.9 151.8 248.1 151.8C245.4 151.8 243.4 150.9 242.5 149.3C241.6 147.7 241.8 145.5 243.2 143.2C244.6 140.7 247.1 138.5 250 136.8Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M157.2 319.8C160.2 318.1 162.8 315.7 164.3 313.1C165.8 310.5 166.1 308 165 306.1C163.9 304.2 161.6 303.2 158.6 303.1C152.6 303.1 145.2 307.3 142.1 312.6C140.6 315.2 140.3 317.7 141.4 319.6C142.5 321.5 144.8 322.5 147.8 322.6C150.8 322.6 154.2 321.5 157.2 319.8ZM163.5 312.6C160.6 317.6 153.6 321.6 147.8 321.6C145.1 321.6 143.1 320.7 142.2 319.1C141.3 317.5 141.5 315.3 142.9 313C145.8 308 152.8 304 158.6 304C161.3 304 163.3 304.9 164.2 306.5C165.1 308.1 164.8 310.3 163.5 312.6Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M168.3 406.8C174.5 403.2 179.9 398.3 183.1 392.9C186.3 387.4 186.7 382.3 184.5 378.3C182.2 374.3 177.5 372.2 171.2 372.2C158.6 372.2 143.2 381 136.9 391.9C133.7 397.4 133.3 402.5 135.5 406.5C137.8 410.5 142.5 412.6 148.8 412.6C155.1 412.6 162.1 410.4 168.3 406.8ZM181.5 392C175.5 402.4 160.9 410.8 148.9 410.8C143.3 410.8 139.2 408.9 137.2 405.6C135.3 402.3 135.8 397.7 138.6 392.9C144.6 382.5 159.2 374.1 171.2 374.1C176.8 374.1 180.9 376 182.9 379.3C184.8 382.6 184.3 387.1 181.5 392Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M142 319.8L141.6 319L104.9 340L86.6001 339.9V340.8L105.2 340.9L105.3 340.8L142 319.8Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M183.5 274.3C186.5 272.6 189.1 270.2 190.6 267.6C192.1 265 192.4 262.5 191.3 260.6C190.2 258.7 187.9 257.7 184.9 257.6C178.9 257.6 171.5 261.8 168.4 267.1C166.9 269.7 166.6 272.2 167.7 274.1C168.8 276 171.1 277 174.1 277.1C177.2 277.1 180.6 276 183.5 274.3ZM176 261.2C178.8 259.6 182 258.6 184.9 258.6C187.6 258.6 189.6 259.5 190.5 261.1C191.4 262.7 191.2 264.9 189.8 267.2C186.9 272.2 179.9 276.2 174.1 276.2C171.4 276.2 169.4 275.3 168.5 273.7C167.6 272.1 167.8 269.9 169.2 267.6C170.7 265.1 173.2 262.8 176 261.2Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M168.4 274.3L168 273.5L131.3 294.5L112.9 294.4V295.3L131.5 295.4L131.6 295.3L168.4 274.3Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M203.46 142.432L203.458 143.332L242.858 143.4L242.86 142.5L203.46 142.432Z" stroke="white" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M197.9 119.1L225.8 71.3999C229.8 64.5999 237.1 60.3999 245 60.2999L289.4 59.8999L298.2 47.3999H244.6C229.1 47.3999 214.9 55.7999 207.4 69.3999L179.3 119.1H197.9Z" fill="url(#paint6_linear_7859_111778)"/>
    <path d="M179.4 119.1H197.9L188.7 135.1L179.4 119.1Z" fill="url(#paint7_linear_7859_111778)"/>
    <path d="M289.5 59.9L298.3 47.5L304.701 60L289.5 59.9Z" fill="url(#paint8_linear_7859_111778)"/>
    <path d="M189.4 424L242.5 424.2C249.9 424.2 257.5 420.4 261.2 414L290.3 364.7H308.7L313.9 378L282.6 434C275.6 446.7 262.2 454.5 247.7 454.4L193.8 454.2L184 438.6L189.4 424Z" fill="url(#paint9_linear_7859_111778)"/>
    <path d="M308.7 364.7L274.9 421.8C270.4 429.5 260.3 439.5 251.4 439.4L184.3 439.2L193.9 454.2L247.8 454.4C262.3 454.5 275.7 446.7 282.7 434L313.9 377.9L308.7 364.7Z" fill="url(#paint10_linear_7859_111778)"/>
    <path d="M56.0002 364.7H42.3002L11.6002 414.9C4.9002 425.8 12.8002 439.8 25.6002 439.8H72.4002L81.6002 423.9H33.2002C28.1002 423.9 25.0002 418.4 27.5002 414.1L56.0002 364.7Z" fill="url(#paint11_linear_7859_111778)"/>
    <path d="M13.9004 435L28.0004 449.2C31.3004 452.5 35.8004 454.4 40.5004 454.4H83.1004L92.1004 439.8L81.4004 423.9L72.2004 439.8C72.2004 439.8 31.5004 439.8 25.4004 439.8C19.3004 439.8 13.9004 435 13.9004 435Z" fill="url(#paint12_linear_7859_111778)"/>
    <path d="M395.3 59.8999L403.1 47.3999H467.5C479 47.3999 486.2 59.8999 480.4 69.8999L451.8 119.1L432.4 119.7L461.4 70.2999C464.1 65.6999 460.8 59.8999 455.5 59.8999H395.3Z" fill="url(#paint13_linear_7859_111778)"/>
    <path d="M432.3 119.7L441 136.5H459L489.2 83.5998C491.4 79.7998 491.4 75.1998 489.3 71.2998L481.3 56.7998C481.3 56.7998 483.8 63.8998 480.3 69.7998C476.8 75.6998 451.7 119 451.7 119L432.3 119.7Z" fill="url(#paint14_linear_7859_111778)"/>
    <defs>
    <linearGradient id="paint0_linear_7859_111778" x1="311.212" y1="357.769" x2="225.669" y2="209.948" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint1_linear_7859_111778" x1="-9.15889" y1="-1.40728" x2="433.38" y2="426.249" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint2_linear_7859_111778" x1="26.2078" y1="254.165" x2="138.29" y2="423.063" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint3_linear_7859_111778" x1="263.728" y1="96.5447" x2="375.81" y2="265.444" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint4_linear_7859_111778" x1="107.163" y1="200.442" x2="219.245" y2="369.341" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint5_linear_7859_111778" x1="201.402" y1="164.065" x2="488.529" y2="451.192" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint6_linear_7859_111778" x1="193.231" y1="57.8325" x2="930.22" y2="468.78" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint7_linear_7859_111778" x1="211.975" y1="144.093" x2="188.569" y2="122.79" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint8_linear_7859_111778" x1="315.759" y1="73.5736" x2="298.312" y2="57.6953" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint9_linear_7859_111778" x1="57.7579" y1="301.561" x2="779.165" y2="703.82" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint10_linear_7859_111778" x1="223.662" y1="334.83" x2="262.447" y2="447.585" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint11_linear_7859_111778" x1="10.3877" y1="386.514" x2="731.795" y2="788.774" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint12_linear_7859_111778" x1="31.0446" y1="387.525" x2="61.2418" y2="455.953" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint13_linear_7859_111778" x1="247.83" y1="-30.1727" x2="956.71" y2="365.102" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint14_linear_7859_111778" x1="429.338" y1="24.1109" x2="471.494" y2="119.639" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    </defs>
    </svg>
     );
}
 
export default HandHeld_3d_Icon;