import React, { useEffect, useState } from "react";
import GisMapInputComponent from "../gis-map/input-map";
import InputLatLng from "../input-latlng";
import imgCheck from "../../../assets/siteManagement/images/check.svg"

function ContentMapLatLng(props) {
    const [detailLatLng, setDetailLatLng] = useState({
        Latitude: '',
        Longitude: '',
    });
    const [detailLatLngMap, setDetailLatLngMap] = useState({
        Latitude: '',
        Longitude: '',
    });


    useEffect(() => {
        if (props.detailLatLng) {
            setDetailLatLng(props.detailLatLng);
            setDetailLatLngMap(props.detailLatLng);
        }
    }, [props.detailLatLng])

    function onInputDetailLatLng(param) {
        let detail = {
            ...detailLatLng
        }
        detail.Latitude = param.Latitude;
        detail.Longitude = param.Longitude;
        setDetailLatLng(detail);
    }

    function onClickSetLatLng() {
        if (detailLatLng.Latitude && detailLatLng.Longitude) {
            setDetailLatLngMap(null);
            setTimeout(() => {
                setDetailLatLngMap(detailLatLng);
            }, 100);
            try {
                props.onSetLatLng(detailLatLng);
            } catch { }
        }
    }
    function onChangeLatLngFromMap(param) {
        setDetailLatLng(param);
        try {
            props.onSetLatLng(param);
        } catch { }
    }

    return (
        <div className="w-full h-full p-4 rounded-lg shadow-content-box bg-white">
            <div className="w-full rounded-lg overflow-auto" style={{ height: 'calc(100% - 70px)' }}>
                <GisMapInputComponent
                    detailLatLng={detailLatLngMap}
                    onChangeLatLng={(e) => { onChangeLatLngFromMap(e) }}
                />
            </div>
            <div className="w-full h-[54px] mt-4 flex items-center">
                <div className="h-full mr-2" style={{ width: 'calc(100% - 38px)' }}>
                    <InputLatLng
                        require={false}
                        valueLat={detailLatLng?.Latitude}
                        valueLng={detailLatLng?.Longitude}
                        onInputText={(e) => { onInputDetailLatLng(e) }} />
                </div>
                <div className="w-[30px] h-[30px] rounded-lg hover:opacity-50 duration-300 ease-in-out cursor-pointer flex justify-center items-center"
                    style={{ background: 'linear-gradient(90.58deg, #1FA37C 1.6%, rgba(31, 163, 124, 0) 101.09%), #79C8B0' }}
                    onClick={(e) => { onClickSetLatLng() }}>
                    <img src={imgCheck} />
                </div>
            </div>
        </div>
    )
}

export default ContentMapLatLng;