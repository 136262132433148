import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BottomAddEditBox from "../../../components/site_management/bottom-add-edit-box";
import ButtonBack from "../../../components/site_management/button-back";
import InputSiteDetailBox from "../../../components/site_management/input-site-detail-box";
import ContentBuildingInfo from "../../../components/site_management/input-site-detail-box/building-info";
import ContentMapLatLng from "../../../components/site_management/input-site-detail-box/map-latlng";
import PopupModalConfirmDelete from "../../../components/site_management/popup-modal/confirm-delete";
import PopupModalError from "../../../components/site_management/popup-modal/error";
import PopupModalLoading from "../../../components/site_management/popup-modal/loading";
import { useCompanyId } from "../../../context/company-context";
import CoreAPI from '../../../shared/services/core';
import { addSite, deleteImageBySiteId, editSite, getSiteById, uploadImageFileBySiteId } from "../../../api/site";
import WidgetLibrary from "../../../components/dashboard_setting/WidgetLibrary";
import WidgetSetup from "../../../components/dashboard_setting/WidgetSetup";

import { shallow } from 'zustand/shallow'
import { create, useStore } from 'zustand'
import { useWidgetSetupStore } from "../../../context/stores";

function AddNewWidget({isCompany=false}) {
    const {dashboardId} = useParams();
    // const [selectedWidget, setSelectedWidget] = useState('');
    const navigate = useNavigate();
    
   

    // ==============================
    // const { companyId } = useCompanyId();
    const companyId  = localStorage.getItem('companyId')

    //==============================
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupError, setIsPopupError] = useState(false);
    const [isPopupConfirmDelete, setIsPopupConfirmDelete] = useState(false);
    const [labelError, setLabelError] = useState('');
    
    
    function onClickBack() {
        // navigate(`/dashboard/setup/${dashboardId}`, { replace: false });
        navigate(-1);
    }
    
    async function onClickConfirmDeleteFunc() {
        setIsPopupConfirmDelete(false);
    }
    //==============================
    return (
        <div className="relative w-full h-screen">
            {isLoading &&
                <PopupModalLoading isOpen={isLoading} />
            }
            {isPopupError &&
                <PopupModalError
                    isOpen={isPopupError}
                    text={labelError}
                    onClickClose={(e) => { setIsPopupError(false) }}
                />
            }
            {isPopupConfirmDelete &&
                <PopupModalConfirmDelete
                    isOpen={isPopupConfirmDelete}
                    // text={labelItem}
                    onClickClose={(e) => { setIsPopupConfirmDelete(false) }}
                    onClickConfirm={(e) => { onClickConfirmDeleteFunc() }}
                />
            }
            <div className="h-full px-6 pb-20 overflow-y-auto">
                {/* ========== Button Back ========== */}
                <div className="w-full h-[72px] flex items-center">
                    <ButtonBack
                        text={`Back to dashboard setting`}
                        onClick={(e) => { onClickBack() }}
                    />
                </div>
                {/* ========== Input Site Detail Content ========== */}
               
                <div className="flex w-full h-[90%] mt-4 overflow-x-auto">
                    <div className="w-1/2 h-full mr-4 bg-white rounded-lg 3xl:w-1/3">
                       <WidgetLibrary isCompany={isCompany} 
                    //    setSelectedWidget={setSelectedWidget}
                    //    selectedWidget={selectedWidget}
                       />
                    </div>
                    
                    <div className="w-1/2 h-full p-4 overflow-auto bg-white rounded-lg 3xl:w-2/3 mrl-4">

                    
                        <WidgetSetup 
                        returnToDash={onClickBack}
                        // selectedWidget={selectedWidget} 
                        // setSelectedWidget={setSelectedWidget}
                        setIsPopupError={setIsPopupError}
                        setLabelError={setLabelError}
                        dashboardId={dashboardId}
                        isCompany={isCompany}
                        /> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddNewWidget;
