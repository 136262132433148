//TODO to optimize and refactor
import moment from 'moment'
import React, { useEffect, useState,useRef } from 'react'
import { useGetDevice, useGetDeviceType, useGetPlace, useLoggedInUser, removeDevices, removeServer,useGetServer } from '../../../api/userInfo'
import CreateNewDevice from '../../../components/device_management/CreateNewDevice'
import CreateNewServer from '../../../components/device_management/CreateNewServer'
import {
  Device_Icon,
  Error_Icon,
  MsSql_Icon,
  Offline_Icon,
  Warning_Icon
} from '../../../components/device_management/icons'
import Header from '../../../components/layout/Header'
import SelectSiteHeader from '../../../components/layout/SelectSiteHeader'
import Online_Icon from '../../../components/widgets/widget_layouts/icons/DeviceListControl/Online_Icon'
import { Icon_Library } from '../../../utils/widgetLibrary/IconTemplates/Icon_library'
import SelectSiteHeaderCompany from '../../../components/layout/SelectSiteHeaderCompany'
import { RemoveDeviceConfirmationModal, RemoveServerConfirmationModal } from '../../../components/ui/ConfirmationModal'
import SiteCard from "../../../components/site_management/site-card";
import SiteCardAdd from "../../../components/site_management/site-card-add";
import { useNavigate } from "react-router-dom";
import {useCompanyId} from '../../../context/company-context'
import imgTransparent from '../../../assets/siteManagement/images/transparent.png'
import { getAllSiteByCompanyId } from "../../../api/site";


const DeviceManagement = () => {
  const navigate = useNavigate();
  const { user } = useLoggedInUser()
  const companyId  = localStorage.getItem('companyId')
  const { deviceList } = useGetDevice(localStorage.getItem('plantId'))
  const { getDeviceType } = useGetDeviceType()
  const { getPlace } = useGetPlace(localStorage.getItem('plantId'))
  const { serverList} = useGetServer(localStorage.getItem('plantId'))
  const member_test = user?.memberAt?.filter((x) => x.plant_id == localStorage.getItem('plantId'))
  const [showData, setShowData] = useState(member_test?.[0]?.permission?.toLowerCase() != 'user')


  useEffect(() => {
    setShowData(member_test?.[0]?.permission?.toLowerCase() != 'user') //return true or false
  }, [user])

  const [deviceDetailsList, setDeviceDetailsList] = useState([])
  const [filteredDevices, setFilteredDevices] = useState([])
  const [selectedStatus, setSelectedStatus] = useState([])

  const [selectedMenu, setSelectedMenu] = useState('all')
  // const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false)
  const [showRemoveServer, setShowRemoveServer] = useState(false)
  const [removeDeviceConfirmation, setRemoveDeviceConfirmation] = useState(false)
  const [removeServerConfirmation, setRemoveServerConfirmation] = useState(false)
  const [isServer, setIsServer] = useState(false)
  const [isEditServer, setIsEditServer] = useState({ status: false, info: undefined })
  const [deviceType, setDeviceType] = useState([])
  const [places, setPlace] = useState({})
  const [modalDevice, setModalDevice] = useState({ status: null, info: undefined })
  const deviceTotal = deviceDetailsList?.status_overview?.reduce(
    (total, currentValue) => total + currentValue.amt,
    0
  )
  const [deviceToRemove, setDeviceToRemove]= useState([])
  const [removedDeviceList, setRemovedDeviceList] = useState([])
  const [serverToRemove, setServerToRemove]= useState([])
  const [removedServer, setRemovedServer] = useState([])
  const [isUpdated, setIsUpdated]=useState(true)



  // For site selection
  const divSlideShowSiteCardRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const divGisMapContentRef = useRef(null);
  const leftDivSlideShowSiteCardRef = useRef(0);
  const [listSite, setListSite] = useState([]);
  const listSiteRef = useRef(listSite);
  const [disableClickCard, setDisableClickCard] = useState(false)
  const disableClickCardRef = useRef(disableClickCard);
  const [focusSite, setFocusSite] = useState(null);
  const [displayType, setDisplayType] = useState(1);



  const fetchData = () => {
    setDeviceDetailsList(deviceList)
    setFilteredDevices(deviceList?.devices)
    setDeviceType(getDeviceType)
    setPlace(getPlace)
  }

  useEffect(() => {
    fetchData()
  }, [deviceList, getDeviceType, getPlace])

  const closeAddServer = (currentState) => {
    if (currentState === 'add server') {
      setIsServer(false)
    } else if (currentState === 'edit server') {
      setIsEditServer({ status: false, info: undefined })
    }
  }
  const openEditServer = (server) => {
    let serverData = { status: true, info: server }
    setIsEditServer(serverData)
  }
  const openAddServer = () => {
    setIsServer(true)
  }

  const closeDevice = () => {
    fetchData()
    setModalDevice({ status: null, info: undefined })
  }

  const handleSelectType = (status) => {
    setSelectedMenu(status)
    setSelectedStatus([])
    if (status === 'Others') {
      setFilteredDevices(
        deviceList?.devices.filter(
          (type) =>
            type.physical_type !== 'Solar' &&
            type.physical_type !== 'Bio gas' &&
            type.physical_type !== 'Battery' &&
            type.physical_type !== 'Wind Turbine'
        )
      )
    } else if (status !== 'all') {
      setFilteredDevices(deviceList?.devices?.filter((type) => type.physical_type === status))
    } else {
      setFilteredDevices(deviceList?.devices)
    }
  }

  const handleSelectStatus = (status) => {
    const selected = Object.assign([], selectedStatus)
    let filters = []
    const statusNumber = []
    if (status != 'all') {
      const check = selected.includes(status)
      if (check) {
        filters = selected.filter((x) => x !== status)
      } else {
        selected.push(status)
        filters = selected
      }

      setSelectedStatus(filters)

      filters.forEach((x) => {
        if (x === 'online') {
          statusNumber.push(1)
        } else if (x === 'offline') {
          statusNumber.push(2)
        } else if (x === 'error') {
          statusNumber.push(3)
        } else if (x === 'warning') {
          statusNumber.push(4)
        }
      })
    }

    let devices
    if (filters.length > 0) {
      if (selectedMenu !== 'all') {
        devices = deviceList?.devices.filter((device) => {
          return statusNumber.includes(device.status) && device.physical_type === selectedMenu
        })
      } else {
        devices = deviceList?.devices.filter((device) => {
          return statusNumber.includes(device.status)
        })
      }
    } else if (filters.length === 0 && selectedMenu !== 'all') {
      devices = deviceList?.devices.filter((device) => device.physical_type === selectedMenu)
    } else {
      devices = deviceList?.devices
    }
    setFilteredDevices(devices)
  }

  const removeConfirmation = (devices) => {
    setDeviceToRemove(devices)
    setShowRemoveConfirmation(state => !state)
  }
  const removeDevice = (devices)=>{
    try {
      removeDevices(devices.id)
      setShowRemoveConfirmation(false)
      window.location.reload(false)
    } catch (error) {
      alert(error)
    }
  }


  const serverConfirmation = (server) => {
    setServerToRemove(server)
    setShowRemoveServer(state => !state)
  }
  const removeServers = (server)=>{
    try {
      removeServer(deviceList.id,server)
      setShowRemoveServer(false)
      window.location.reload(false)
    } catch (error) {
      alert(error)
    }
  }


  async function onMouseDownSlideShowSiteCard(param) {
    const wParent = divGisMapContentRef?.current?.offsetWidth;
    const wChild = divSlideShowSiteCardRef?.current?.offsetWidth;
    if (wChild < wParent) {
        divSlideShowSiteCardRef.current.style.left = `0px`
        return;
    }
    //==============================
    param.stopPropagation()
    //==============================
    leftDivSlideShowSiteCardRef.current = divSlideShowSiteCardRef.current.offsetLeft;
  }

  function onClickFocusSite(param) {
    divSlideShowSiteCardRef.current.style.left = `${0}px`
    //==============================
    let listX = [];
    listX = JSON.parse(JSON.stringify(listSite));
    listX = listX.map((value, index) => {
        value.Visible = param.Id === value.Id ? true : false;
        return value;
    })
    setListSite(listX);
    setFocusSite(param);
    setDisplayType(3);
    //==============================
    // onSetBuildingDeviceSummary(listX);
}

async function onGetListSiteByCompanyId(param) {
  let listX = [];
  const response = await getAllSiteByCompanyId(param);

  if (response?.status) {
      const dataResponse = response?.data;
      for (let item of dataResponse) {
          const detail = {
              Id: item.id,
              Name: item.name,
              Image: item.main_image_file,
              Active: true,
              Visible: true,
          }
          listX.push(detail);
      }
  }
  return listX;
  }


  useEffect(() => {
    const func = async () => {
        if (companyId) {
            await onSetListSite();
        }
    }
    func();
  }, [companyId])

  async function onSetListSite() {
    setIsLoading(true);
    //==============================
    let listX = [];
    // listX = await onGetListSite(1, listX);
    listX = await onGetListSiteByCompanyId(companyId);
    setListSite(listX);
    //==============================
    setTimeout(() => {
        setIsLoading(false);
    }, 1000);
  }

  const updateSelectedPlant = (plant_id) => {
    localStorage.setItem('plantId',plant_id)
    window.location.reload(false)
  }


  return (
    <>
      <Header pageTitle="Devices">
        {' '}
        <SelectSiteHeaderCompany/>
      </Header>

      {showRemoveConfirmation &&
          <div className='absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden bg-black/70'>
            <div className='p-4 bg-white rounded-lg h-50 w-96'>
                  <p className='py-2 text-lg font-semibold text-primary'>Remove Confirmation</p>
                  <p className='py-2 text-sm text-center'>Are you sure you want to remove {deviceToRemove?.name} ?</p>
                  <div className='flex justify-center gap-8 pt-4'>
                      <button className='p-2 px-4 text-white bg-red-600 rounded-lg hover:bg-red-600/80' onClick={()=>removeDevice(deviceToRemove)}>Remove</button>
                      <button className='p-2 px-4 text-red-400 border-2 rounded-lg border-black-200 hover:border-red-400/80' type='button' onClick={()=>removeConfirmation(undefined)} >Cancel</button>
                  </div>
              </div>
          </div>
        }

      {showRemoveServer &&
        <div className='absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden bg-black/70'>
          <div className='p-4 bg-white rounded-lg h-50 w-96'>
              <p className='py-2 text-lg font-semibold text-primary'>Remove Confirmation</p>
              <p className='py-2 text-sm text-center'>Are you sure you want to remove {serverToRemove[0]?.name} ?</p>
              <div className='flex justify-center gap-8 pt-4'>
                  <button className='p-2 px-4 text-white bg-red-600 rounded-lg hover:bg-red-600/80' onClick={()=>removeServers(serverToRemove)}>Remove</button>
                  <button className='p-2 px-4 text-red-400 border-2 rounded-lg border-black-200 hover:border-red-400/80' type='button' onClick={()=>serverConfirmation(undefined)}>Cancel</button>
              </div>
          </div>
        </div>
      }

      <div className="h-full overflow-x-auto overflow-y-hidden layout rounded-xl ">

      <section className='p-4 mx-4 mt-4 mb-4 bg-white border rounded-md shadow-xl'>
                <div ref={divSlideShowSiteCardRef} className="flex py-2 pl-4 top-28"
                onMouseDown={(e) => { onMouseDownSlideShowSiteCard(e) }}
                onTouchStart={(e) => { onMouseDownSlideShowSiteCard(e) }}
                >
                  {listSite.map((value, index) => {
                    if (value.Visible && value.Active) {
                      return (
                        <SiteCard
                          key={value?.Name+index}
                          disable={disableClickCardRef.current}
                          detailSite={value}
                          onClick={(e) => { updateSelectedPlant(value.Id) }}
                        />
                      )
                    }
                  })}
                </div>
              </section>
        {/* Server */}
        {showData && (
          <>
            {deviceDetailsList?.server?.length === 0 ? (
              <div className="grid gap-4 p-4 m-4 bg-white rounded-md shadow-xl md:grid-cols-4 lg:grid-cols-7 h-96">
                <div className="grid col-span-7 justify-items-center">
                  <Device_Icon />
                  <p className="w-full my-2 text-xl font-bold text-center text-primary">
                    No server(s) connected
                  </p>
                  <p className="my-2 text-sm font-medium text-tertiary">
                    Connect server(s) to setup devices
                  </p>
                  <button
                    onClick={() => openAddServer()}
                    className="flex items-center gap-1 p-4 mt-4 text-sm font-semibold text-white rounded-lg bg-vekin"
                  >
                    <i className="ri-add-line"></i>Add server
                  </button>
                </div>
              </div>
            ) : (
              <>
              
              
              <section className="h-full gap-4 p-4 mx-4 bg-white rounded-md shadow-xl">
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6">
                  <div className="grid md:col-span-3 lg:col-start-1 lg:col-end-6">
                    <div className="flex items-center gap-8">
                      <h1 className="text-xl font-bold">Server</h1>
                      <button className="w-6 h-6 text-sm rounded-full shadow-xl bg-vekin-green-50">
                        <i className="ri-arrow-up-s-line"></i>
                      </button>
                    </div>
                  </div>

                  <div className="flex justify-end">
                    <button
                      disabled={serverList?.server?.length !== 0}
                      onClick={() => openAddServer()}
                      className={`flex items-center gap-1 p-4 text-sm font-semibold text-white rounded-lg ${
                        deviceDetailsList?.server?.length !== 0 ? 'bg-gray-300' : 'bg-vekin'
                      }`}
                    >
                      <i className="ri-add-line"></i>Add Server
                    </button>
                  </div>
                </div>

                {serverList?.server?.map((server)=>(
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6" key={server?.[0]?.name}>
                  <div className="p-4 m-4 bg-white rounded-md shadow-lg">
                      <div className='flex justify-between'>
                        <MsSql_Icon />
                        <button>
                          <i onClick={()=>{serverConfirmation(server)}} className="text-xl ri-delete-bin-line text-danger"></i>
                        </button>
                      </div>
                    <div onClick={() => openEditServer(server)}>
                      <div className="flex flex-col gap-2">
                        <h1 className="pt-2 text-sm font-bold">{server?.[0]?.name}</h1>
                        <div className="flex items-end gap-2">
                          <p className="text-lg font-semibold text-vekin">{deviceTotal}</p>
                          <p className="text-sm font-medium">Devices</p>
                        </div>
                      </div>
                      {deviceDetailsList?.status_overview?.map((server_status) => (
                      <div
                        key={server_status?.status}
                        className="flex items-center justify-between"
                      >
                        <div className="flex items-center p-2">
                          {server_status.status === 'online' ? (
                            <Online_Icon />
                          ) : server_status.status === 'offline' ? (
                            <Offline_Icon />
                          ) : server_status.status === 'warning' ? (
                            <Warning_Icon />
                          ) : (
                            <Error_Icon />
                          )}
                          <div
                            className={`text-sm pt-2 pl-2 ${
                              server_status.status === 1 ? 'font-bold' : 'font-normal'
                            }`}
                          >
                            {server_status.status}
                          </div>
                        </div>
                        <p
                          className={`font-bold text-sm ${
                            server_status.status === 'online'
                              ? 'text-vekin'
                              : server_status.status === 'offline'
                              ? 'text-tertiary'
                              : server_status.status === 'warning'
                              ? 'text-orange'
                              : 'text-red-500'
                          }`}
                        >
                          {server_status.amt}
                        </p>
                      </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
              </section>
              </>
            )}
          </>
        )}

        {/* Devices list */}
        {deviceDetailsList?.devices?.length === 0 && showData && filteredDevices?.length === 0 ? (
          <div className="grid gap-4 p-4 m-4 bg-white rounded-md shadow-xl md:grid-cols-4 lg:grid-cols-7 h-96">
            <h1 className="text-xl font-bold">Devices list</h1>
            <div className="grid col-span-7 justify-items-center">
              <Device_Icon />
              <p className="w-full my-2 text-xl font-bold text-center text-primary">
                No Device found{' '}
              </p>
              <p className="my-2 text-sm font-medium text-tertiary">
                Select device(s) from selected server
              </p>
              <button
                onClick={() => setModalDevice({ status: 'add', info: undefined })}
                className="flex items-center gap-1 p-4 mt-4 text-sm font-semibold text-white rounded-lg bg-vekin"
              >
                <i className="ri-add-line"></i>Add Device(s)
              </button>
            </div>
          </div>
        ) : (
          <section className="h-full gap-4 p-4 m-4 bg-white rounded-md shadow-xl">
            <div className="grid grid-cols-2 gap-4 my-4 md:grid-cols-4 lg:grid-cols-6">
              <div className="grid md:col-span-3 lg:col-start-1 lg:col-end-6">
                <div className="flex items-center gap-8">
                  <h1 className="text-xl font-bold">Devices list</h1>
                </div>
              </div>

              {showData && (
                <div className="flex justify-end">
                  <button
                    onClick={() => setModalDevice({ status: 'add', info: undefined })}
                    className="flex items-center gap-1 p-4 text-sm font-semibold text-white rounded-lg bg-vekin"
                  >
                    <i className="ri-add-line"></i>Add device
                  </button>
                </div>
              )}
            </div>

            {/* <div className="grid grid-cols-4 gap-2 md:grid-cols-6 lg:grid-cols-8"> */}
            <div
            // className="flex gap-2 sm:flex-wrap md:flex-wrap lg:flex-row"
            className="flex gap-2 overflow-x-auto lg:flex-row"
            >
              {/* Status Online and Error */}
              <div className="flex lg:flex-row divide-x divide-[#F1F3F5] border border-[#F1F3F5] rounded-md">
                {deviceDetailsList?.status_overview?.map((state) => (
                  <div key={state.status} className="flex items-center justify-between p-4 px-4 ">
                    <div>
                      <p className="text-lg font-medium">{state.status}</p>
                      <div className="flex items-end gap-2">
                        <p className="font-semibold text-vekin">{state.amt}</p>
                        <div className="text-sm font-medium text-tertiary">
                          {state.status === 'error' ? <p> / {deviceTotal} Devices</p> : 'Devices'}
                        </div>
                      </div>
                    </div>
                    <div className="w-12 h-12 rounded-full shadow-md bg-vekin-green-50">
                      <div className="pt-3 pl-3">
                        {state.status === 'online' && <Online_Icon />}
                        {state.status === 'offline' && <Offline_Icon />}
                        {state.status === 'warning' && <Warning_Icon />}
                        {state.status === 'error' && <Error_Icon />}
                        {/* {state.status === "online"? <Online_Icon/> : state.status === "offline"? <Offline_Icon/> : state.status === "error"? <Error_Icon/> : <Warning_Icon/> } */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {/* Divice */}
              <div
              // className="flex flex-wrap gap-2"
              className="flex gap-2"
              >
                {deviceDetailsList?.overview?.map((device_overview) => (
                  <div
                    key={device_overview.device}
                    className="flex justify-between items-center px-4 p-4 border border-[#F1F3F5] rounded-md"
                  >
                    <div>
                      <p className="text-lg font-medium">{device_overview.device}</p>
                      <div className="flex items-end gap-2">
                        <p className="font-semibold text-vekin">{device_overview.amt}</p>
                        <p className="text-sm font-medium text-tertiary">Devices</p>
                      </div>
                    </div>
                    {Icon_Library(
                      device_overview?.icon,
                      'w-14 h-14 bg-vekin-green-50 shadow-md rounded-full p-1.5'
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex h-full gap-2 my-4 overflow-x-auto bg-white border rounded-md border-quartenary">
              <button
                type="button"
                onClick={() => handleSelectType('all')}
                className={`${
                  selectedMenu === 'all' ? 'bg-[#F2F8F6]' : 'bg-white'
                } px-8 py-2 h-full text-secondary text-sm font-medium`}
              >
                All
              </button>
              {deviceDetailsList?.overview?.map(({ device }, index) => (
                <button
                
                  key={device}
                  type="button"
                  onClick={() => handleSelectType(device)}
                  className={`${
                    selectedMenu === device ? 'bg-[#F2F8F6]' : 'bg-white'
                  } px-8 py-2 h-full text-secondary text-sm font-medium`}
                >
                  {device}
                </button>
              ))}
            </div>

            <div className="flex h-full py-2 overflow-x-auto bg-white rounded-md">
              <div
              className="flex items-center gap-8 lg:flex-row "
              // className="flex flex-wrap items-center gap-8 lg:flex-row "
              >
                <p className="text-sm font-medium text-secondary">Status:</p>
                <button
                  type="button"
                  onClick={() => {
                    setSelectedStatus([])
                    handleSelectStatus('all')
                  }}
                  className={
                    selectedStatus?.length === 0 ? 'status_primary_btn' : 'status_secondary_btn'
                  }
                >
                  All
                </button>
                {deviceDetailsList?.status_overview?.map(({ status }, index) => (
                  <button
                    type="button"
                    key={index+status}
                    onClick={() => handleSelectStatus(status)}
                    className={
                      selectedStatus.includes(status)
                        ? 'status_primary_btn'
                        : 'status_secondary_btn'
                    }
                  >
                    {status === 'online' && <Online_Icon />}
                    {status === 'offline' && <Offline_Icon />}
                    {status === 'error' && <Error_Icon />}
                    {status === 'warning' && <Warning_Icon />}
                    {status}
                  </button>
                ))}
              </div>
            </div>

            <div className="col-span-7 mt-4">
              {filteredDevices?.length === 0 ? (
                <div className="grid gap-4 p-4 m-4 bg-white rounded-md shadow-xl md:grid-cols-4 lg:grid-cols-7 h-96">
                  <div className="grid col-span-7 justify-items-center">
                    <Device_Icon />
                    <p className="w-full my-2 text-xl font-bold text-center text-primary">
                      Devices with status:{' '}
                      {selectedStatus?.map((status) => (
                        <span className="capitalize " key={status}>{status} </span>
                      ))}{' '}
                      not found
                    </p>
                  </div>
                </div>
              ) : (
                <div className="relative overflow-x-auto overflow-y-auto bg-white rounded-lg shadow">
                  <table className="relative w-full whitespace-no-wrap table-auto table-striped">
                  <thead>
                    <tr>
                      <th className="text-vekin font-semibold inline-flex gap-2 text-[16px]">
                        {/* <input type="checkbox" className="text-lg accent-vekin" /> */}
                        Date
                        <i className="ri-arrow-up-line"></i>
                      </th>
                      <th className="text-secondary text-[16px]">Device ID</th>
                      <th className="text-secondary text-[16px]">Name</th>
                      <th className="text-secondary text-[16px]">Location</th>
                      <th className="text-secondary text-[16px]">Latitude,Longitude</th>
                      <th className="text-secondary text-[16px]">Status</th>
                      {showData && (
                        <th className="text-secondary text-[16px] text-center">Actions</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {filteredDevices?.map((devices) => (
                      <tr className="hover:bg-vekin-green-50" key={devices?.id}>
                        <td className="flex gap-2 text-sm capitalize text-tertiary ">
                          {/* <input type="checkbox" className="text-lg accent-vekin" /> */}
                          {/* {devices.datetime_created!==undefined? devices.datetime_created :<p className="pl-8 text-xl">-</p>} */}
                          {moment(devices?.datetime_created).format('LLL')}
                        </td>
                        <td className="text-sm font-bold text-vekin">{devices?.id}</td>
                        <td className="text-sm text-black ">{devices.name}</td>
                        <td className="text-sm text-tertiary">
                          {devices.place !== undefined ? (
                            devices.place
                          ) : (
                            <p className="pl-8 text-xl">-</p>
                          )}
                        </td>
                        <td className="text-sm text-tertiary">
                          {deviceDetailsList?.location?.lat !== undefined &&
                          deviceDetailsList?.location?.lon !== undefined ? (
                            <>
                              {deviceDetailsList?.location?.lat} , {deviceDetailsList?.location?.lon}
                            </>
                          ) : (
                            <p className="pl-16 text-xl">-</p>
                          )}
                        </td>
                        <td className="text-sm text-tertiary">
                          <div className="flex items-center gap-2">
                            {devices?.status === 1 ? (
                              <>
                                <Online_Icon /> Online
                              </>
                            ) : devices?.status === 2 ? (
                              <>
                                <Offline_Icon /> Offline
                              </>
                            ) : devices?.status === 3 ? (
                              <>
                                <Warning_Icon /> Warning
                              </>
                            ) : (
                              <>
                                <Error_Icon /> Error
                              </>
                            )}
                          </div>
                        </td>
                        {showData && (
                          <td className="flex justify-center space-x-10 text-center text-tertiary">
                            <i
                              onClick={() => setModalDevice({ status: 'edit', info: devices })}
                              className="text-lg cursor-pointer ri-edit-2-fill text-vekin"
                            ></i>
                            <button>
                          <i onClick={()=>{removeConfirmation(devices)}} className="text-lg ri-delete-bin-line text-danger"></i>
                          </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
                </div>
              )}
            </div>
          </section>
        )}

        <CreateNewDevice
          status={modalDevice?.status}
          info={modalDevice?.info}
          close={closeDevice}
          deviceList={deviceList}
          getDeviceType={deviceType}
          getPlace={places}
        />

        <CreateNewServer
          closeAddServer={closeAddServer}
          isServer={isServer}
          isEditServer={isEditServer}
        />

        {/* <RemoveDeviceConfirmationModal
          removeDeviceConfirmation={removeDeviceConfirmation}
          deviceToRemove={deviceToRemove}
          setRemoveDeviceConfirmation={setRemoveDeviceConfirmation}
          filteredDevices={filteredDevices}
          setFilteredDevices={setFilteredDevices}
          removedDeviceList={removedDeviceList}
          setRemovedDeviceList={setRemovedDeviceList}
          setIsUpdated={setIsUpdated}
        />

        <RemoveServerConfirmationModal
        setIsUpdated={setIsUpdated}
        removeServerConfirmation={removeServerConfirmation}
        serverToRemove={serverToRemove}
        setRemoveServerConfirmation={setRemoveServerConfirmation}
        filteredDevices={filteredDevices}
        setFilteredDevices={setFilteredDevices}
        removedServer={removedServer}
        setRemovedServer={setRemovedServer}
        /> */}

      </div>
    </>
  )
}

export default DeviceManagement
