const ElectricSolar_Icon = ({style, stroke}) => {
    return (
        <svg className={style}  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M30.73 21.67L28.28 8.83002C28.04 7.59002 26.72 6.66002 25.19 6.66002H20.47L20.84 6.87002L20.98 7.00002C21.44 7.45002 21.74 7.96002 21.9 8.55002H25.19C25.86 8.55002 26.38 8.94002 26.43 9.19002L26.95 11.91H20.94L20.91 11.49L18.21 13.79H19.16L19.38 17.38H12.69L12.78 16.1C12.51 15.77 12.33 15.35 12.33 14.9V14.63L13.07 11.91L13.17 10.39L11.8 9.57002C11.64 9.48002 11.49 9.38002 11.36 9.26002L11.18 11.9H5.60002L6.12002 9.18002C6.17002 8.92002 6.69002 8.54002 7.36002 8.54002H10.78C10.63 8.27002 10.51 7.99002 10.46 7.67002C10.4 7.32002 10.43 6.98002 10.51 6.65002H7.36002C5.84002 6.65002 4.51002 7.58002 4.27002 8.82002L1.82002 21.67C1.66002 22.51 1.86002 23.32 2.39002 23.95C2.95002 24.62 3.81002 25.01 4.77002 25.01H27.79C28.75 25.01 29.62 24.62 30.17 23.95C30.69 23.31 30.89 22.5 30.73 21.67ZM10.41 23.12H4.77002C4.38002 23.12 4.04002 22.98 3.84002 22.75C3.68002 22.55 3.62002 22.3 3.67002 22.02L4.20002 19.26H10.67L10.41 23.12ZM10.8 17.38H4.56002L5.25002 13.79H11.05L10.8 17.38ZM12.3 23.12L12.56 19.26H19.5L19.74 23.12H12.3ZM21.05 13.79H27.31L28 17.38H21.28L21.05 13.79ZM28.71 22.75C28.51 22.99 28.17 23.12 27.78 23.12H21.62L21.38 19.26H28.35L28.88 22.02C28.93 22.3 28.88 22.55 28.71 22.75Z" fill={stroke}/>
<path d="M30.73 21.67L28.28 8.83002C28.04 7.59002 26.72 6.66002 25.19 6.66002H20.47L20.84 6.87002L20.98 7.00002C21.44 7.45002 21.74 7.96002 21.9 8.55002H25.19C25.86 8.55002 26.38 8.94002 26.43 9.19002L26.95 11.91H20.94L20.91 11.49L18.21 13.79H19.16L19.38 17.38H12.69L12.78 16.1C12.51 15.77 12.33 15.35 12.33 14.9V14.63L13.07 11.91L13.17 10.39L11.8 9.57002C11.64 9.48002 11.49 9.38002 11.36 9.26002L11.18 11.9H5.60002L6.12002 9.18002C6.17002 8.92002 6.69002 8.54002 7.36002 8.54002H10.78C10.63 8.27002 10.51 7.99002 10.46 7.67002C10.4 7.32002 10.43 6.98002 10.51 6.65002H7.36002C5.84002 6.65002 4.51002 7.58002 4.27002 8.82002L1.82002 21.67C1.66002 22.51 1.86002 23.32 2.39002 23.95C2.95002 24.62 3.81002 25.01 4.77002 25.01H27.79C28.75 25.01 29.62 24.62 30.17 23.95C30.69 23.31 30.89 22.5 30.73 21.67ZM10.41 23.12H4.77002C4.38002 23.12 4.04002 22.98 3.84002 22.75C3.68002 22.55 3.62002 22.3 3.67002 22.02L4.20002 19.26H10.67L10.41 23.12ZM10.8 17.38H4.56002L5.25002 13.79H11.05L10.8 17.38ZM12.3 23.12L12.56 19.26H19.5L19.74 23.12H12.3ZM21.05 13.79H27.31L28 17.38H21.28L21.05 13.79ZM28.71 22.75C28.51 22.99 28.17 23.12 27.78 23.12H21.62L21.38 19.26H28.35L28.88 22.02C28.93 22.3 28.88 22.55 28.71 22.75Z" fill={stroke} fill-opacity="0.7"/>
<path d="M17.14 27.51H15.4C14.91 27.51 14.52 27.11 14.52 26.63C14.52 26.14 14.92 25.75 15.4 25.75H17.14C17.63 25.75 18.02 26.15 18.02 26.63C18.02 27.12 17.62 27.51 17.14 27.51Z" fill={stroke}/>
<path d="M17.14 27.51H15.4C14.91 27.51 14.52 27.11 14.52 26.63C14.52 26.14 14.92 25.75 15.4 25.75H17.14C17.63 25.75 18.02 26.15 18.02 26.63C18.02 27.12 17.62 27.51 17.14 27.51Z" fill={stroke} fill-opacity="0.7"/>
<path d="M20.07 7.80999L17.5 6.37999C17.26 6.24999 17.15 5.95999 17.25 5.69999L19.07 0.849994C19.07 0.519994 18.74 0.199994 18.42 0.519994L11.79 6.16999C11.14 6.81999 11.46 7.82999 12.12 8.15999L15.03 9.90999C15.26 10.06 15.36 10.35 15.26 10.59L13.45 15.12C13.45 15.45 13.78 15.77 14.1 15.45L19.92 10.49C20.72 9.80999 20.84 8.57999 20.1 7.83999C20.08 7.82999 20.07 7.82999 20.07 7.80999Z" fill={stroke}/>
<path d="M20.07 7.80999L17.5 6.37999C17.26 6.24999 17.15 5.95999 17.25 5.69999L19.07 0.849994C19.07 0.519994 18.74 0.199994 18.42 0.519994L11.79 6.16999C11.14 6.81999 11.46 7.82999 12.12 8.15999L15.03 9.90999C15.26 10.06 15.36 10.35 15.26 10.59L13.45 15.12C13.45 15.45 13.78 15.77 14.1 15.45L19.92 10.49C20.72 9.80999 20.84 8.57999 20.1 7.83999C20.08 7.82999 20.07 7.82999 20.07 7.80999Z" fill={stroke} fill-opacity="0.7"/>
<path d="M24.47 31.23H8.08C7.56 31.23 7.13 30.81 7.13 30.28C7.13 29.17 8.03001 28.27 9.14001 28.27H23.4C24.51 28.27 25.41 29.17 25.41 30.28C25.41 30.8 24.99 31.23 24.47 31.23Z" fill={stroke}/>
<path d="M24.47 31.23H8.08C7.56 31.23 7.13 30.81 7.13 30.28C7.13 29.17 8.03001 28.27 9.14001 28.27H23.4C24.51 28.27 25.41 29.17 25.41 30.28C25.41 30.8 24.99 31.23 24.47 31.23Z" fill={stroke} fill-opacity="0.7"/>

</svg>

    )
}

export default ElectricSolar_Icon;