import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BottomAddEditBox from "../../../components/site_management/bottom-add-edit-box";
import ButtonBack from "../../../components/site_management/button-back";
import InputSiteDetailBox from "../../../components/site_management/input-site-detail-box";
import ContentBuildingInfo from "../../../components/site_management/input-site-detail-box/building-info";
import ContentMapLatLng from "../../../components/site_management/input-site-detail-box/map-latlng";
import PopupModalConfirmDelete from "../../../components/site_management/popup-modal/confirm-delete";
import PopupModalError from "../../../components/site_management/popup-modal/error";
import PopupModalLoading from "../../../components/site_management/popup-modal/loading";
import { useCompanyId } from "../../../context/company-context";
import CoreAPI from '../../../shared/services/core';
import { addSite, deleteImageBySiteId, editSite, getSiteById, uploadImageFileBySiteId } from "../../../api/site";

function SiteAddEditPage(props) {
    const paramsUrl = useParams();
    const navigate = useNavigate();
    // ==============================
    // const { companyId } = useCompanyId();
    const companyId  = localStorage.getItem('companyId')

    //==============================
    const [isLoading, setIsLoading] = useState(false);
    const [isPopupError, setIsPopupError] = useState(false);
    const [isPopupConfirmDelete, setIsPopupConfirmDelete] = useState(false);
    const [labelError, setLabelError] = useState('');
    const [detailSite, setDetailSite] = useState({
        Id: '',
        No: '',
        NameEn: '',
        NameTh: '',
        Type: '',
        TypeId: '',
        Description: '',
        Address: '',
        Image: '',
        ImageName: '',
        ImageId: 0,
        ImageFile: null,
        ImageFileName: '',
        Latitude: '',
        Longitude: '',
        CompanyId: '',
    })
    const isPageEditRef = useRef(false);
    const [listAlertRequiredField, setListAlertRequiredField] = useState([]);
    //==============================
    useEffect(() => {
        const init = async () => {
            setInitRoute();
        }
        init();
    }, [])
    //==============================
    function setInitRoute() {
        if (paramsUrl.id) {
            isPageEditRef.current = true;
            onSetDetailSite(paramsUrl['id']);
        } else {
            isPageEditRef.current = false;
            onSetDetailSite();
        }
    }
    async function onSetDetailSite(param) {
        setIsLoading(true);
        //==============================
        let detail = {
            ...detailSite,
            CompanyId: companyId,
        }
        if (!param) {
            // detail.No = randomString(10);
            // detail.Id = randomString(10);
        } else {
            // const response = await CoreAPI.apiCoreService.GetSiteById(param);
            const response = await getSiteById(param)
            if (response.status) {
                const dataResponse = response.data;
                //==============================
                let albumImage;
                try {
                    albumImage = JSON.parse(dataResponse.album)
                } catch { }
                let listImage = [];
                let imageId = 0;
                let imageName = '';
                let imageSite = '';
                if (Array.isArray(albumImage) && albumImage.length > 0) {
                    listImage = albumImage.slice();
                    // const detailImage = listImage[listImage.length - 2];
                    const detailImage = listImage[0];
                    // imageId = detailImage.id;
                    // imageName = detailImage.photo;
                    // imageSite = detailImage.photo;

                    // imageName = `/api/media/pics/${detailImage.photo}`;
                    // imageSite = `/api/media/pics/${detailImage.photo}`;
                }
                if (dataResponse.main_image) {
                    imageId = dataResponse.main_image;
                    imageName = dataResponse.main_image_file;
                    imageSite = dataResponse.main_image_file;
                }
                //==============================
                let detailLatLng = JSON.parse(dataResponse.location);
                detail = {
                    ...detail,
                    Id: dataResponse.id,
                    No: '',
                    NameEn: dataResponse.name,
                    NameTh: '',
                    Type: '',
                    TypeId: '',
                    Description: dataResponse.description,
                    Address: dataResponse.address,
                    Image: imageSite,
                    ImageName: imageName,
                    ImageId: imageId,
                    ImageFile: null,
                    AlbumImage: listImage,
                    Latitude: detailLatLng.lat ? detailLatLng.lat : '',
                    Longitude: detailLatLng.lon ? detailLatLng.lon : '',
                }
            }
        }
        setDetailSite(detail);
        //==============================
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }
    function randomString(length) {
        var text = "";
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    function onSetSiteDetail(param) {
        setDetailSite(param);
        //==============================
        onCheckRequireFillAlert(param, false)
        //==============================
    }
    function onSetSiteDetailLatLng(param) {
        const detail = {
            ...detailSite,
            Latitude: param.Latitude,
            Longitude: param.Longitude,
        }
        setDetailSite(detail);
    }
    function onCheckRequireFill() {
        let check = false;
        if (
            detailSite.NameEn
            // && detailSite.NameTh
        ) {
            check = true;
        }
        //==============================
        onCheckRequireFillAlert(detailSite, true)
        //==============================
        return check
    }
    function onCheckRequireFillAlert(param, fromSave) {
        //==============================
        let listAlertInput = [];
        if (fromSave) {
            if (!param.NameEn) listAlertInput.push('Name');
        } else {
            listAlertInput = listAlertRequiredField.slice();
            if (param.NameEn) listAlertInput = listAlertInput.filter(x => x !== 'Name');
        }
        setListAlertRequiredField(listAlertInput);
        //==============================
    }
    async function onSetImageFromAlbum(param) {
        let returnImage;
        let albumImage;
        try {
            albumImage = JSON.parse(param)
        } catch { }
        if (Array.isArray(albumImage) && albumImage.length > 0) {
            let listImage = albumImage.slice();
            returnImage = listImage[listImage.length - 1];
        }
        return returnImage;
    }
    //==============================
    function onClickBack() {
        navigate("/site", { replace: false });
    }
    async function onClickSaveAndDupSite() {
        if (!onCheckRequireFill()) return;
        const statusSave = await onSaveSite();
        if (statusSave) {
            isPageEditRef.current = false;
            navigate("/site/add", { replace: false });
        } else {
            setIsPopupError(true);
        }
    }
    async function onClickSaveSite() {
        if (!onCheckRequireFill()) return;
        const statusSave = await onSaveSite();
        if (statusSave) {
            navigate("/site", { replace: false });
        } else {
            setIsPopupError(true);
        }
    }
    async function onSaveSite() {
        setIsLoading(true);
        //==============================
        let statusSave = false;
        let siteId;
        let imageId = detailSite.ImageId;
        let body = {
            // "external_id": 0,
            "name": detailSite.NameEn,
            "description": detailSite.Description,
            "address": detailSite.Address,
            "location": "",
            // "server": "",
            // "config": "",
            // "main_image": 0,
            "company": Number(detailSite.CompanyId),
        }
        let detailLatLng = {
            lat: detailSite.Latitude ? Number(detailSite.Latitude) : '',
            lon: detailSite.Longitude ? Number(detailSite.Longitude) : '',
        }
        body.location = JSON.stringify(detailLatLng);
        let responseAdd;
        if (!isPageEditRef.current) {
            // responseAdd = await CoreAPI.apiCoreService.AddSite(body);
            responseAdd = await addSite(body);
        } else {
            siteId = Number(detailSite.Id);
        }
        if (responseAdd?.status) {
            statusSave = true;
            const dataResponse = responseAdd.data;
            siteId = Number(dataResponse.id);
        }
        //==============================
        if (siteId) {
            if (detailSite.ImageFile && detailSite.ImageId) {
                const bodyDeleteImage = {
                    "photo_id": detailSite.ImageId,
                }
                // const responseDeleteImage = await CoreAPI.apiCoreService.DeleteImageSiteById(siteId, bodyDeleteImage);
                const responseDeleteImage = await deleteImageBySiteId(siteId, bodyDeleteImage);
            }
            if (detailSite.ImageFile) {
                // const responseImage = await CoreAPI.apiCoreService.UploadImageFileBySiteId(siteId, detailSite.ImageFile);
                const responseImage = await uploadImageFileBySiteId(siteId, detailSite.ImageFile,detailSite.NameEn);
                if (responseImage.status) {
                    const dataResponse = responseImage.data;
                    const detailImage = await onSetImageFromAlbum(dataResponse.album);
                    imageId = detailImage?.id;
                }
            }
        }
        //==============================
        body.main_image = imageId;
        // body.main_image = 999;
        let responseEdit;
        // responseEdit = await CoreAPI.apiCoreService.EditSite(siteId, body);
        responseEdit = await editSite(siteId, body);
        if (responseEdit.status) {
            statusSave = true;
        } else {
            setLabelError(responseEdit.data);
        }
        // statusSave = false;
        //==============================
        setIsLoading(false);
        //==============================
        return statusSave;
    }
    async function onClickConfirmDeleteFunc() {
        setIsPopupConfirmDelete(false);
    }
    //==============================
    return (
        <div className="relative w-full h-screen">
            {isLoading &&
                <PopupModalLoading isOpen={isLoading} />
            }
            {isPopupError &&
                <PopupModalError
                    isOpen={isPopupError}
                    text={labelError}
                    onClickClose={(e) => { setIsPopupError(false) }}
                />
            }
            {isPopupConfirmDelete &&
                <PopupModalConfirmDelete
                    isOpen={isPopupConfirmDelete}
                    // text={labelItem}
                    onClickClose={(e) => { setIsPopupConfirmDelete(false) }}
                    onClickConfirm={(e) => { onClickConfirmDeleteFunc() }}
                />
            }
            <div className="h-full px-6 pb-20 overflow-y-auto">
                {/* ========== Header Fuction Content ========== */}
                <div className="w-full h-[72px] flex items-center">
                    <ButtonBack
                        text={`Back to Sites`}
                        onClick={(e) => { onClickBack() }}
                    />
                </div>
                {/* ========== Input Site Detail Content ========== */}
                <InputSiteDetailBox
                    isEdit={isPageEditRef.current}
                    detailSite={detailSite}
                    alertRequiredField={listAlertRequiredField}
                    onChangeDetail={(e) => { onSetSiteDetail(e) }}
                />
                <div className="w-full h-[500px] mt-4 flex">
                    <div className="w-1/2 h-full mr-4">
                        <ContentMapLatLng
                            detailLatLng={detailSite}
                            onSetLatLng={(e) => { onSetSiteDetailLatLng(e) }}
                        />
                    </div>
                    <div className="w-1/2 h-full mrl-4">
                        <ContentBuildingInfo
                            siteId={detailSite.Id}
                            disable={!isPageEditRef.current} />
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-0 flex justify-center w-full h-12 bg-white shadow-content-box">
                <BottomAddEditBox
                    onClickSaveAndDup={(e) => { onClickSaveAndDupSite() }}
                    onClickSave={(e) => { onClickSaveSite() }}
                />
            </div>
        </div>
    )
}

export default SiteAddEditPage;