function GradientSVGType3(props) {
    return (
        <svg>
            <defs>
                <linearGradient id={`GradientSVGType3`}>
                    <stop offset="0%" stopColor={`#6CCCDF`} />
                    <stop offset="100%" stopColor={`#0085FF`} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GradientSVGType3;