const Power_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.003 0C13.773 0 12.7693 1.00415 12.7693 2.23469V15.0043C12.7693 16.2349 13.773 17.239 15.003 17.239C16.2329 17.239 17.2366 16.2349 17.2366 15.0043V2.23469C17.2366 1.00415 16.2329 0 15.003 0ZM7.25364 4.75215C6.36412 3.8622 4.911 3.87738 4.05466 4.79957C1.63945 7.36671 0.122737 10.7854 0.00609924 14.5696L0.0060585 14.571C-0.227375 22.7117 6.38232 29.7419 14.5203 29.9924C23.0184 30.2591 30.0001 23.4404 30.0001 14.9884C30.0001 11.0363 28.4659 7.46662 25.9675 4.79994C25.1111 3.87726 23.6418 3.86221 22.7523 4.75215C21.8748 5.62999 21.907 6.99846 22.7236 7.86069C24.4216 9.66505 25.4729 12.0693 25.533 14.7314L25.5331 14.732C25.6683 20.4921 20.8923 25.4632 15.1399 25.5233L15.1368 25.5234C9.2564 25.6137 4.47303 20.8746 4.47303 15.0043C4.47303 12.2364 5.53933 9.72503 7.28398 7.84301C8.10305 6.97631 8.11038 5.6093 7.25364 4.75215Z" fill={stroke}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.003 0C13.773 0 12.7693 1.00415 12.7693 2.23469V15.0043C12.7693 16.2349 13.773 17.239 15.003 17.239C16.2329 17.239 17.2366 16.2349 17.2366 15.0043V2.23469C17.2366 1.00415 16.2329 0 15.003 0ZM7.25364 4.75215C6.36412 3.8622 4.911 3.87738 4.05466 4.79957C1.63945 7.36671 0.122737 10.7854 0.00609924 14.5696L0.0060585 14.571C-0.227375 22.7117 6.38232 29.7419 14.5203 29.9924C23.0184 30.2591 30.0001 23.4404 30.0001 14.9884C30.0001 11.0363 28.4659 7.46662 25.9675 4.79994C25.1111 3.87726 23.6418 3.86221 22.7523 4.75215C21.8748 5.62999 21.907 6.99846 22.7236 7.86069C24.4216 9.66505 25.4729 12.0693 25.533 14.7314L25.5331 14.732C25.6683 20.4921 20.8923 25.4632 15.1399 25.5233L15.1368 25.5234C9.2564 25.6137 4.47303 20.8746 4.47303 15.0043C4.47303 12.2364 5.53933 9.72503 7.28398 7.84301C8.10305 6.97631 8.11038 5.6093 7.25364 4.75215Z" fill={stroke}/>

</svg>
    )
}

export default Power_Icon;