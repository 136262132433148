import React, { useEffect, useState } from "react";
import MapTileChip from "./chip";

function MapTileSelectBox(props) {
    const [listTile, setListTile] = useState([])

    useEffect(() => {
        const init = async () => {
            onSetListTileType();
        };
        init();
    }, [])

    function onSetListTileType() {
        let listX = [];
        listX = [
            { Type: 1, Name: '3D view', Active: false, },
            { Type: 2, Name: 'Hybrids', Active: false, },
            { Type: 3, Name: 'Satellite', Active: false, },
        ]
        listX = listX.map((value, index) => {
            value.Active = value.Type === props.tile ? true : false;
            return value;
        })
        setListTile(listX);
    }
    function onSetTileSelect(param) {
        let listX = [];
        listX = JSON.parse(JSON.stringify(listTile));
        listX = listX.map((value, index) => {
            value.Active = value.Type === param.Type ? true : false;
            return value;
        })
        setListTile(listX);
        try {
            props.onSelectTile(param);
        } catch { }
    }
    return (
        <div className="flex items-center">
            <p className="pr-2 text-secondary text-xs font-medium">Display view</p>
            <div className="rounded-lg p-1 border border-solid border-quartenary flex">
                {listTile.map((value, index) => {
                    return (
                        <MapTileChip
                            key={value.Type}
                            detailTile={value}
                            onSelectTile={(e) => { onSetTileSelect(e) }}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export default MapTileSelectBox;