import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import GisMapComponent from "../../../components/site_management/gis-map";
import MapTileSelectBox from "../../../components/site_management/map-tile-select-box";
import PopupModalLoading from "../../../components/site_management/popup-modal/loading";
import SiteCard from "../../../components/site_management/site-card";
import SiteCardAdd from "../../../components/site_management/site-card-add";
import SiteBoxDetailType1 from "../../../components/site_management/site-detail-box-type1";
import SiteBoxDetailType2 from "../../../components/site_management/site-detail-box-type2";
import SiteBoxDetailType3 from "../../../components/site_management/site-detail-box-type3";
import {useCompanyId} from '../../../context/company-context'

import CoreAPI from '../../../shared/services/core';
import imgTransparent from '../../../assets/siteManagement/images/transparent.png'
import Header from "../../../components/layout/Header";
import SelectSiteHeaderCompany from "../../../components/layout/SelectSiteHeaderCompany";
import { PageLoading } from "../../../components/ui";
import { getAllSiteByCompanyId } from "../../../api/site";

function SiteMainPage(props) {
    const navigate = useNavigate();
    //==============================
    // const { companyId } = useCompanyId()
    const companyId  = localStorage.getItem('companyId')

    //==============================
    const [isLoading, setIsLoading] = useState(false);
    const divGisMapContentRef = useRef(null);
    const divSlideShowSiteCardRef = useRef(null);
    const leftDivSlideShowSiteCardRef = useRef(0);
    const leftMouseDownFirstRef = useRef(0);
    const [disableClickCard, setDisableClickCard] = useState(false)
    const disableClickCardRef = useRef(disableClickCard);
    const [listSite, setListSite] = useState([]);
    const listSiteRef = useRef(listSite);
    const [displayType, setDisplayType] = useState(1);
    const [focusSite, setFocusSite] = useState(null);
    const [centerMainMap, setCenterMainMap] = useState({
        Latitude: 0,
        Longitude: 0,
        Zoom: 0,
    });
    const [tileType, setTileType] = useState(3);
    const [siteOverview, setSiteOverview] = useState({
        Site: {
            Percentage: 0,
            Count: 0,
        },
        Building: {
            Percentage: 0,
            Count: 0,
        },
        Floor: {
            Percentage: 0,
            Count: 0,
        },
        Device: {
            Percentage: 0,
            Count: 0,
        },
    });

    useEffect(() => {
        const init = async () => {
            // await onLogin();
            await onSetListSite();
        };
        init();
    }, [])
    useEffect(() => {
        const func = async () => {
            if (companyId) {
                await onSetListSite();
            }
        }
        func();
    }, [companyId])
    useEffect(() => {
        listSiteRef.current = listSite;
    }, [listSite])
    //==============================
    async function onGetListSiteByCompanyId(param) {
        let listX = [];
        // const response = await CoreAPI.apiCoreService.GetAllSiteByCompanyId(param);
        const response = await getAllSiteByCompanyId(param);

        
        if (response?.status) {
            const dataResponse = response?.data;
            for (let item of dataResponse) {
                let albumImage;
                try {
                    albumImage = JSON.parse(item?.album)
                } catch { }
                let listImage = [];
                let imageSite = imgTransparent;
                let imageId = 0;
                if (Array.isArray(albumImage) && albumImage.length > 0) {
                    listImage = albumImage.slice();
                    const detailImage = listImage[listImage.length - 1];
                    // imageId = detailImage.id;
                    // imageSite = `/api/media/pics/${detailImage.photo}`;
                }
                if (item.main_image) {
                    imageId = item.main_image;
                    imageSite = item.main_image_file;
                }
                //==============================
                let detailCountOverview = {
                    countBuilding: 0,
                    countFloor: 0,
                    countPlace: 0,
                    countDevice: 0,
                }
                detailCountOverview = await onSetCountSiteOverview(detailCountOverview, item.asset, 'building');
                //console.log(item?.asset, "building")
                let detailLatLng; 
                if(item?.location){
                    //moved inside an if condition, coz it was having undefined error on server
                    detailLatLng = JSON.parse(item?.location)
                }else{
                    detailLatLng = {lat:0,lon:0}
                }
                const detail = {    
                    Id: item.id,
                    Name: item.name,
                    Image: imageSite,
                    ImageId: imageId,
                    AlbumImage: listImage,
                    Description: item.description,
                    Location: item.address,
                    Latitude: detailLatLng.lat,
                    Longitude: detailLatLng.lon,
                    Assets: item.asset,
                    CountBuilding: detailCountOverview.countBuilding,
                    CountFloor: detailCountOverview.countFloor,
                    CountPlace: detailCountOverview.countPlace,
                    CountDevice: detailCountOverview.countDevice,
                    Active: true,
                    Visible: true,
                }
                listX.push(detail);
            }
        }
        return listX;
    }
    async function onSetCountSiteOverview(param, list, type) {
       // console.log('onSetCountSiteOverview param list type', { param, list, type })
        if (type === 'building' && Array.isArray(list)) {
            param.countBuilding = param.countBuilding + list.length;
        }
        for (let item of list) {
            if (Array.isArray(item.floor)) {
                param.countFloor = param.countFloor + item.floor.length;
                param = await onSetCountSiteOverview(param, item.floor, 'floor')
            }
            if (Array.isArray(item.place)) {
                param.countPlace = param.countPlace + item.place.length;
                param = await onSetCountSiteOverview(param, item.place, 'place')
            }
            if (Array.isArray(item.device)) {
                param.countDevice = param.countDevice + item.device.length;
                param = await onSetCountSiteOverview(param, item.device, 'device')
            }
        }
        return param
    }
    async function onSetListSite() {
        setIsLoading(true);
        //==============================
        let listX = [];
        // listX = await onGetListSite(1, listX);
        listX = await onGetListSiteByCompanyId(companyId);
        setListSite(listX);
        //==============================
        setTimeout(() => {
            setIsLoading(false);
        }, 1000);
    }
    function onSetHeightBoxByType(param) {
        let height = '';
        if (displayType === 1) {
            height = param === 1 ? '33.333333%' : param === 2 ? '66.666667%' : '0px';
        } else if (displayType === 2) {
            height = param === 1 ? '50%' : param === 2 ? '50%' : '0px';
        } else if (displayType === 3) {
            height = param === 1 ? '50%' : param === 2 ? '25%' : '25%';
        }
        return height
    }
    function onSetClassHeightBoxByType(param) {
        let classHeight = '';
        if (displayType === 1) {
            classHeight = param === 1 ? 'h-1/3' : param === 2 ? 'h-2/3' : '';
        } else if (displayType === 2) {
            classHeight = param === 1 ? 'h-2/4' : param === 2 ? 'h-2/4' : '';
        } else if (displayType === 3) {
            classHeight = param === 1 ? 'h-2/4' : param === 2 ? 'h-1/4' : 'h-1/4';
        }
        return classHeight
    }
    async function onSetBuildingDeviceSummary(param) {
        let SiteAllCount = param.length;
        let BuilingAllCount = 0;
        let FloorAllCount = 0;
        let DeviceAllCount = 0;
        //==============================
        let SiteViewCount = 0;
        let BuilingViewCount = 0;
        let FloorViewCount = 0;
        let DeviceViewCount = 0;
        //==============================
        for (let item of param) {
            if (item.Visible && item.Active) {
                SiteViewCount++;
                BuilingViewCount = BuilingViewCount + item.CountBuilding;
                FloorViewCount = FloorViewCount + item.CountFloor;
                DeviceViewCount = DeviceViewCount + item.CountDevice;
            }
            //==============================
            BuilingAllCount = BuilingAllCount + item.CountBuilding;
            FloorAllCount = FloorAllCount + item.CountFloor;
            DeviceAllCount = DeviceAllCount + item.CountDevice;
        }
        //==============================
        let SiteViewPercentage = SiteAllCount ? (SiteViewCount * 100) / SiteAllCount : 0;
        let BuilingViewPercentage = BuilingAllCount ? (BuilingViewCount * 100) / BuilingAllCount : 0;
        let FloorViewPercentage = FloorAllCount ? (FloorViewCount * 100) / FloorAllCount : 0;
        let DeviceViewPercentage = DeviceAllCount ? (DeviceViewCount * 100) / DeviceAllCount : 0;
        setSiteOverview({
            Site: {
                Percentage: SiteViewPercentage,
                Count: SiteViewCount,
            },
            Building: {
                Percentage: BuilingViewPercentage,
                Count: BuilingViewCount,
            },
            Floor: {
                Percentage: FloorViewPercentage,
                Count: FloorViewCount,
            },
            Device: {
                Percentage: DeviceViewPercentage,
                Count: DeviceViewCount,
            },
        });
    }
    //==============================
    function onDetectMobile() {
        const toMatch = [
            /Android/i,
            /webOS/i,
            /iPhone/i,
            /iPad/i,
            /iPod/i,
            /BlackBerry/i,
            /Windows Phone/i
        ];

        return toMatch.some((toMatchItem) => {
            return navigator.userAgent.match(toMatchItem);
        });
    }
    async function onMouseDownSlideShowSiteCard(param) {
        const wParent = divGisMapContentRef.current.offsetWidth;
        const wChild = divSlideShowSiteCardRef.current.offsetWidth;
        if (wChild < wParent) {
            divSlideShowSiteCardRef.current.style.left = `0px`
            return;
        }
        //==============================
        param.stopPropagation();
        //==============================
        const isMobile = onDetectMobile();
        //==============================
        if (!isMobile) param.preventDefault();
        //==============================
        leftDivSlideShowSiteCardRef.current = divSlideShowSiteCardRef.current.offsetLeft;
        if (isMobile) {
            const targetTouches = param.targetTouches
            if (!targetTouches) return
            const leftClickFirst = targetTouches[0].clientX;
            leftMouseDownFirstRef.current = leftClickFirst;
            document.ontouchend = onMouseUpSlideShowSiteCard;
            document.ontouchmove = onMouseMoveSlideShowSiteCard;
        } else {
            const leftClickFirst = param.clientX;
            leftMouseDownFirstRef.current = leftClickFirst;
            document.onmouseup = onMouseUpSlideShowSiteCard;
            document.onmousemove = onMouseMoveSlideShowSiteCard;
        }
    }
    async function onMouseUpSlideShowSiteCard(param) {
        setTimeout(() => {
            disableClickCardRef.current = false;
            setDisableClickCard(disableClickCardRef.current);
        }, 100);
        //==============================
        const isMobile = onDetectMobile();
        //==============================
        if (isMobile) {
            document.ontouchend = null;
            document.ontouchmove = null;
        } else {
            document.onmouseup = null;
            document.onmousemove = null;
        }
        //==============================
    }
    async function onMouseMoveSlideShowSiteCard(param) {
        disableClickCardRef.current = true;
        setDisableClickCard(disableClickCardRef.current);
        //==============================
        const isMobile = onDetectMobile();
        //==============================
        let left;
        if (isMobile) {
            const targetTouches = param.targetTouches
            left = targetTouches[0].clientX;
        } else {
            param.preventDefault();
            left = param.clientX;
        }
        //==============================
        const wParent = divGisMapContentRef.current.offsetWidth;
        const wChild = divSlideShowSiteCardRef.current.offsetWidth;
        const positionLeftDiff = left - leftMouseDownFirstRef.current;
        let leftPosition = positionLeftDiff + leftDivSlideShowSiteCardRef.current;
        if (leftPosition > 0) {
            leftPosition = 0;
        }
        if ((wChild + leftPosition) < wParent) {
            leftPosition = wParent - wChild;
        }
        divSlideShowSiteCardRef.current.style.left = `${leftPosition}px`
        //==============================
    }
    //==============================
    function onClickAddSite() {
        navigate("/site/add", { replace: false });
    }
    function onClickEditSite(param) {
        navigate(`/site/edit/${param.Id}`, { replace: false });
    }
    function onClickFocusSite(param) {
        divSlideShowSiteCardRef.current.style.left = `${0}px`
        //==============================
        let listX = [];
        listX = JSON.parse(JSON.stringify(listSite));
        listX = listX.map((value, index) => {
            value.Visible = param.Id === value.Id ? true : false;
            return value;
        })
        setListSite(listX);
        setFocusSite(param);
        setDisplayType(3);
        //==============================
        onSetBuildingDeviceSummary(listX);
    }
    function onSetVisibleSite(param) {
        let listX = [];
        listX = JSON.parse(JSON.stringify(listSiteRef.current));
        listX = listX.map((value, index) => {
            let model = param.find(x => x.Id === value.Id);
            if (model) {
                value.Visible = model.Visible;
            }
            return value;
        })
        setListSite(listX);
        setFocusSite(null);
        setDisplayType(2);
        //==============================
        onSetBuildingDeviceSummary(listX);
    }
    function onSetActiveSite(param) {
        let listX = [];
        listX = JSON.parse(JSON.stringify(listSite));
        let model = listX.find(x => x.Id === param.Id);
        if (model) {
            model.Active = param.Active;
        }
        setListSite(listX);
        //==============================
        onSetBuildingDeviceSummary(listX);
    }
    function onSetCenterMap(param) {
        setCenterMainMap({
            ...param
        })
    }
    function onSelectTileMap(param) {
        setTileType(param.Type);
    }
    return (

        <>
        <Header pageTitle="Site Management"> <SelectSiteHeaderCompany /></Header>
        <div className="pb-20">
            {isLoading &&
                <PageLoading loading={isLoading}/>
                // <PopupModalLoading isOpen={isLoading} />
            }
            {/* ========== Map Fuction Content ========== */}
            <div className="h-[72px] mx-6 flex items-center">
                <div className="ml-auto mr-0">
                    <MapTileSelectBox
                        tile={tileType}
                        onSelectTile={(e) => { onSelectTileMap(e) }}
                    />
                </div>
            </div>
            {/* ========== Main Map Content ========== */}
            <div className="h-[824px] mx-6 flex">
                {/* ========== GIS Map Content ========== */}
                <div ref={divGisMapContentRef} className="relative h-full mr-2 overflow-hidden rounded-lg shadow-content-box"
                    style={{ width: 'calc(100% - 328px)' }}>
                        {/* z-[401] */}
                    <div ref={divSlideShowSiteCardRef} className="absolute top-[0px] z-30 flex p-2"
                        onMouseDown={(e) => { onMouseDownSlideShowSiteCard(e) }}
                        onTouchStart={(e) => { onMouseDownSlideShowSiteCard(e) }}
                    >
                        <SiteCardAdd
                            disable={disableClickCardRef.current}
                            onClick={(e) => { onClickAddSite() }}
                        />
                        {listSite.map((value, index) => {
                            if (value.Visible && value.Active) {
                                return (
                                    <SiteCard
                                        key={value.Id}
                                        disable={disableClickCardRef.current}
                                        detailSite={value}
                                        onClick={(e) => { onClickFocusSite(value) }}
                                    />
                                )
                            }
                        })}
                    </div>
                    <GisMapComponent
                        focusSite={focusSite}
                        listSite={listSite}
                        onChangeVisibleSite={(e) => { onSetVisibleSite(e) }}
                        onChangeCenterMap={(e) => { onSetCenterMap(e) }}
                    />
                </div>
                {/* ========== Detail Site Content ========== */}
                <div className="w-[320px] h-full">
                    <div className={`pb-1 ${onSetClassHeightBoxByType(1)}`}>
                        <SiteBoxDetailType1
                            type={displayType}
                            listSite={listSite}
                            centerMapFocus={centerMainMap}
                            summary={siteOverview}
                        />
                    </div>
                    {displayType === 3 &&
                        <div className={`py-1 ${onSetClassHeightBoxByType(3)}`}>
                            <SiteBoxDetailType3
                                detailSite={focusSite}
                                onClickEdit={(e) => { onClickEditSite(e) }}
                            />
                        </div>
                    }
                    <div className={`pt-1 ${onSetClassHeightBoxByType(2)}`}>
                        <SiteBoxDetailType2
                            listSite={listSite}
                            onChangeActiveSite={(e) => { onSetActiveSite(e) }}
                        />
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default SiteMainPage;
