const Air_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M475.9 239.7L476 403.9L323.2 354.2L323.1 190L475.9 239.7Z" fill="#CCCCCC"/>
    <path d="M475.9 239.7L323.1 190L179 224.9L331.8 274.5L475.9 239.7Z" fill="#E6E6E6"/>
    <path d="M323.2 354.2L476 403.8L331.8 438.7L179 389.1C295.4 366.5 206.9 376.8 323.2 354.2Z" fill="#CCCCCC"/>
    <path d="M476 403.9L475.9 239.7L331.7 274.6L331.8 438.8L476 403.9Z" fill="#B3B3B3"/>
    <path d="M331.9 438.7L331.7 274.5L179 224.9L179.1 389.1L331.9 438.7Z" fill="#CCCCCC"/>
    <path d="M450.7 282.7L450.6 305.9L444.2 307.5C441.6 300.1 438.2 293.1 434.2 286.9L450.7 282.7Z" fill="#999999"/>
    <path d="M450.7 314.7L450.6 337.9H450.4C449.9 330.2 448.7 322.7 446.8 315.6L450.7 314.7Z" fill="#999999"/>
    <path d="M450.7 347.4L450.6 370.6L447.9 371.3C449.6 363.6 450.6 355.6 450.7 347.4Z" fill="#999999"/>
    <path d="M444.2 307.4L366.2 327.1L366.3 303.9L434.2 286.8C438.2 293.1 441.6 300 444.2 307.4Z" fill="url(#paint0_linear_5149_80650)"/>
    <path d="M450.5 338L366.3 359.2L366.4 336L446.9 315.7C448.7 322.8 450 330.2 450.5 338Z" fill="url(#paint1_linear_5149_80650)"/>
    <path d="M450.7 347.4C450.6 355.7 449.6 363.7 447.9 371.3L366.2 391.9L366.3 368.7L450.7 347.4Z" fill="url(#paint2_linear_5149_80650)"/>
    <path d="M191.8 393.1L191.7 228.9L179.3 224.8V389.1L191.8 393.1Z" fill="#E6E6E6"/>
    <path d="M331.6 438.7V274.5L319.5 270.5L319.6 434.7L331.6 438.7Z" fill="#E6E6E6"/>
    <path d="M331.6 274.5L179.3 224.9V238L331.6 287.6V274.5Z" fill="#E6E6E6"/>
    <path d="M331.6 425.5L179.3 375.9V389.1L331.6 438.7V425.5Z" fill="#E6E6E6"/>
    <path d="M331.9 438.8V274.7L344.5 271.7L344.4 435.9L331.9 438.8Z" fill="url(#paint3_linear_5149_80650)"/>
    <path d="M463.8 407L463.9 242.9L476.5 239.8L476.4 404L463.8 407Z" fill="url(#paint4_linear_5149_80650)"/>
    <path d="M331.9 274.6L476.5 239.8V253L331.9 287.8V274.6Z" fill="url(#paint5_linear_5149_80650)"/>
    <path d="M331.9 425.7L476.5 390.9V404L331.9 438.8V425.7Z" fill="url(#paint6_linear_5149_80650)"/>
    <path d="M344.4 271.7L191.6 222L179.4 224.8L331.7 274.4L344.4 271.7Z" fill="#CECECE"/>
    <path d="M476.5 239.7L323.7 190.1L311.4 192.9L464.2 242.5L476.5 239.7Z" fill="#CECECE"/>
    <path d="M476.3 239.7L465 236.1L320.8 270.9L331.7 274.5L476.3 239.7Z" fill="#CECECE"/>
    <path d="M334.9 193.4L323.6 189.8L179.4 224.6L190.3 228.2L334.9 193.4Z" fill="#CECECE"/>
    <path d="M324.8 280.7L190.2 237L176.7 241.5L311.3 285.2L324.8 280.7Z" fill="#B3B3B3"/>
    <path d="M324.9 425.4L324.8 280.8L311.3 284.4L311.4 429L324.9 425.4Z" fill="#999999"/>
    <path d="M315.2 279.5L315.3 443.7L162.5 394.1L162.4 229.9L315.2 279.5Z" fill="#CCCCCC"/>
    <path d="M315.2 279.5L162.4 229.9L18.2 264.7L170.5 314.3L315.2 279.5Z" fill="#E6E6E6"/>
    <path d="M162.5 394.1L315.3 443.7L170.6 478.6L18.2 428.9C134.5 406.3 46.2 416.7 162.5 394.1Z" fill="#CCCCCC"/>
    <path d="M315.3 443.7L315.2 279.5L170.6 314.4V478.6L315.3 443.7Z" fill="#B3B3B3"/>
    <path d="M170.5 478.5V314.4L18.2 264.7V428.9L170.5 478.5Z" fill="#CCCCCC"/>
    <path d="M290 322.5L289.9 345.7L283.5 347.3C280.9 339.9 277.5 332.9 273.5 326.7L290 322.5Z" fill="#999999"/>
    <path d="M290 354.6L289.9 377.8H289.7C289.2 370.1 288 362.6 286.1 355.5L290 354.6Z" fill="#999999"/>
    <path d="M290 387.2L289.9 410.4L287.2 411.1C288.9 403.5 289.8 395.5 290 387.2Z" fill="#999999"/>
    <path d="M283.5 347.3L205.5 367L205.6 343.8L273.5 326.7C277.5 332.9 280.8 339.9 283.5 347.3Z" fill="url(#paint7_linear_5149_80650)"/>
    <path d="M289.7 377.8L205.5 399L205.6 375.8L286.1 355.5C288 362.6 289.2 370.1 289.7 377.8Z" fill="url(#paint8_linear_5149_80650)"/>
    <path d="M289.9 387.2C289.8 395.5 288.8 403.5 287.1 411.1L205.4 431.7L205.5 408.5L289.9 387.2Z" fill="url(#paint9_linear_5149_80650)"/>
    <path d="M154.4 342C153.1 348.1 149.4 350.8 146.1 348.1C142.8 345.3 141.2 338.1 142.5 332C143.8 325.9 147.5 323.2 150.8 325.9C154.1 328.6 155.7 335.9 154.4 342Z" fill="url(#paint10_radial_5149_80650)"/>
    <path d="M200.6 342C199.3 348.1 195.6 350.8 192.3 348.1C189 345.3 187.4 338.1 188.7 332C190 325.9 193.7 323.2 197 325.9C200.3 328.6 201.9 335.9 200.6 342Z" fill="url(#paint11_linear_5149_80650)"/>
    <path d="M140 438.7L139.9 339.7L44.3999 308.7L44.4999 407.7L140 438.7Z" fill="#999999"/>
    <path d="M137.5 432.7L137.4 342.9C137.4 342.1 136.9 341.3 136.1 341.1L49.3999 312.9C48.1999 312.5 46.8999 313.4 46.8999 314.7L46.9999 404.5C46.9999 405.3 47.4999 406.1 48.2999 406.3L135 434.4C136.2 434.9 137.5 434 137.5 432.7Z" fill="#CCCCCC"/>
    <path d="M146.3 374.6V356.4L137.6 353.6C133.3 352.2 128.8 355.4 128.8 360V360.6C128.8 365.6 132 370 136.7 371.5L145 375.9L146.3 374.6Z" fill="#999999"/>
    <path d="M145 375.9V357.7L136.3 354.9C132 353.5 127.5 356.7 127.5 361.3V361.9C127.5 366.9 130.7 371.3 135.4 372.8L145 375.9Z" fill="#E6E6E6"/>
    <path d="M68.7999 366.3C68.7999 364.7 67.8999 363.3 66.4999 362.6L51.1999 354.8L49.5999 356.4L51.2999 363.8L66.3999 369.4L68.2999 367.8C68.4999 367.4 68.7999 366.8 68.7999 366.3Z" fill="url(#paint12_linear_5149_80650)"/>
    <path d="M67.0999 367.8C67.0999 366.3 66.2999 365 64.8999 364.3L49.3999 356.4V365.5L64.4999 369.7C65.8999 370 67.0999 369.1 67.0999 367.8Z" fill="url(#paint13_linear_5149_80650)"/>
    <path d="M146.3 427.7V409.5L137.6 406.7C133.3 405.3 128.8 408.5 128.8 413.1V413.7C128.8 418.7 132 423.1 136.7 424.6L145 429L146.3 427.7Z" fill="#999999"/>
    <path d="M145 429V410.8L136.3 408C132 406.6 127.5 409.8 127.5 414.4V415C127.5 420 130.7 424.4 135.4 425.9L145 429Z" fill="#E6E6E6"/>
    <path d="M170.5 478.6V314.4L183.2 311.5L183.1 475.7L170.5 478.6Z" fill="url(#paint14_linear_5149_80650)"/>
    <path d="M302.4 446.8L302.6 282.6L315.2 279.5L315.1 443.7L302.4 446.8Z" fill="url(#paint15_linear_5149_80650)"/>
    <path d="M170.5 314.4L315.1 279.6V292.8L170.5 327.6V314.4Z" fill="url(#paint16_linear_5149_80650)"/>
    <path d="M170.5 465.4L315.1 430.6V443.8L170.5 478.6V465.4Z" fill="url(#paint17_linear_5149_80650)"/>
    <path d="M30.7999 432.9L30.6 268.8L18.2 264.7V428.9L30.7999 432.9Z" fill="#E6E6E6"/>
    <path d="M170.5 478.6V314.4L158.4 310.4L158.5 474.6L170.5 478.6Z" fill="#E6E6E6"/>
    <path d="M170.5 314.4L18.2 264.7V277.9L170.5 327.5V314.4Z" fill="#E6E6E6"/>
    <path d="M170.5 465.4L18.2 415.8V429L170.5 478.6V465.4Z" fill="#E6E6E6"/>
    <path d="M183.2 311.5L30.5 261.9L18.3 264.7L170.6 314.3L183.2 311.5Z" fill="#CECECE"/>
    <path d="M315.3 279.5L162.5 229.9L150.2 232.7L303 282.3L315.3 279.5Z" fill="#CECECE"/>
    <path d="M315.2 279.5L303.9 275.9L159.7 310.7L170.6 314.3L315.2 279.5Z" fill="#CECECE"/>
    <path d="M173.7 233.2L162.4 229.6L18.2 264.5L29.1 268.1L173.7 233.2Z" fill="#CECECE"/>
    <path d="M475.9 69.7999L476 234L323.2 184.3L323.1 20.0999L475.9 69.7999Z" fill="#CCCCCC"/>
    <path d="M475.9 69.8L323.1 20.2L179 55L331.8 104.6L475.9 69.8Z" fill="#E6E6E6"/>
    <path d="M323.2 184.3L476 234L331.8 268.9L179 219.3C295.4 196.6 206.9 206.9 323.2 184.3Z" fill="#CCCCCC"/>
    <path d="M476 234L475.9 69.7998L331.7 104.7L331.8 268.9L476 234Z" fill="#B3B3B3"/>
    <path d="M331.9 268.8L331.7 104.6L179 54.9998L179.1 219.2L331.9 268.8Z" fill="#CCCCCC"/>
    <path d="M450.7 112.8L450.6 136L444.2 137.6C441.6 130.2 438.2 123.2 434.2 117L450.7 112.8Z" fill="#999999"/>
    <path d="M450.7 144.8L450.6 168H450.4C449.9 160.3 448.7 152.8 446.8 145.7L450.7 144.8Z" fill="#999999"/>
    <path d="M450.7 177.5L450.6 200.7L447.9 201.4C449.6 193.8 450.6 185.8 450.7 177.5Z" fill="#999999"/>
    <path d="M444.2 137.6L366.2 157.3L366.3 134.1L434.2 117C438.2 123.2 441.6 130.1 444.2 137.6Z" fill="url(#paint18_linear_5149_80650)"/>
    <path d="M450.5 168.1L366.3 189.3L366.4 166.1L446.9 145.8C448.7 152.9 450 160.4 450.5 168.1Z" fill="url(#paint19_linear_5149_80650)"/>
    <path d="M450.7 177.5C450.6 185.8 449.6 193.8 447.9 201.4L366.2 222L366.3 198.8L450.7 177.5Z" fill="url(#paint20_linear_5149_80650)"/>
    <path d="M191.8 223.2L191.7 59L179.3 55V219.2L191.8 223.2Z" fill="#E6E6E6"/>
    <path d="M331.6 268.8V104.7L319.5 100.6L319.6 264.8L331.6 268.8Z" fill="#E6E6E6"/>
    <path d="M331.6 104.6L179.3 55V68.2L331.6 117.8V104.6Z" fill="#E6E6E6"/>
    <path d="M331.6 255.7L179.3 206V219.2L331.6 268.8V255.7Z" fill="#E6E6E6"/>
    <path d="M331.9 269V104.8L344.5 101.9L344.4 266.1L331.9 269Z" fill="url(#paint21_linear_5149_80650)"/>
    <path d="M463.8 237.2L463.9 72.9999L476.5 69.8999L476.4 234.1L463.8 237.2Z" fill="url(#paint22_linear_5149_80650)"/>
    <path d="M331.9 104.8L476.5 70V83.2L331.9 118V104.8Z" fill="url(#paint23_linear_5149_80650)"/>
    <path d="M331.9 255.8L476.5 221V234.2L331.9 269V255.8Z" fill="url(#paint24_linear_5149_80650)"/>
    <path d="M344.4 101.8L191.6 52.2L179.4 55L331.7 104.6L344.4 101.8Z" fill="#CECECE"/>
    <path d="M476.5 69.8L323.7 20.2L311.5 23L464.3 72.5999L476.5 69.8Z" fill="#CECECE"/>
    <path d="M476.3 69.8L465 66.2L320.8 101L331.7 104.6L476.3 69.8Z" fill="#CECECE"/>
    <path d="M334.9 23.4999L323.6 19.8999L179.4 54.7999L190.3 58.3999L334.9 23.4999Z" fill="#CECECE"/>
    <path d="M324.8 110.8L190.2 67.0999L176.7 71.5999L311.3 115.3L324.8 110.8Z" fill="#B3B3B3"/>
    <path d="M324.9 255.5L324.8 110.9L311.3 114.5L311.4 259.1L324.9 255.5Z" fill="#999999"/>
    <path d="M315.2 109.7L315.3 273.8L162.5 224.2L162.4 59.9998L315.2 109.7Z" fill="#CCCCCC"/>
    <path d="M315.2 109.7L162.4 59.9998L18.2 94.8998L170.5 144.5L315.2 109.7Z" fill="#E6E6E6"/>
    <path d="M162.5 224.2L315.3 273.8L170.6 308.7L18.2 259C134.5 236.5 46.2 246.8 162.5 224.2Z" fill="#CCCCCC"/>
    <path d="M315.3 273.8L315.2 109.6L170.6 144.5V308.7L315.3 273.8Z" fill="#B3B3B3"/>
    <path d="M170.5 308.7V144.5L18.2 94.8997V259.1L170.5 308.7Z" fill="#CCCCCC"/>
    <path d="M290 152.6L289.9 175.8L283.5 177.4C280.9 170 277.5 163 273.5 156.8L290 152.6Z" fill="#999999"/>
    <path d="M290 184.7L289.9 207.9H289.7C289.2 200.2 288 192.7 286.1 185.6L290 184.7Z" fill="#999999"/>
    <path d="M290 217.3L289.9 240.5L287.2 241.2C288.9 233.6 289.8 225.6 290 217.3Z" fill="#999999"/>
    <path d="M283.5 177.4L205.5 197.1L205.6 173.9L273.5 156.8C277.5 163.1 280.8 170 283.5 177.4Z" fill="url(#paint25_linear_5149_80650)"/>
    <path d="M289.7 208L205.5 229.2L205.6 206L286.1 185.7C288 192.8 289.2 200.2 289.7 208Z" fill="url(#paint26_linear_5149_80650)"/>
    <path d="M289.9 217.4C289.8 225.7 288.8 233.7 287.1 241.3L205.4 261.9L205.5 238.7L289.9 217.4Z" fill="url(#paint27_linear_5149_80650)"/>
    <path d="M154.4 172.1C153.1 178.2 149.4 180.9 146.1 178.2C142.8 175.4 141.2 168.2 142.5 162.1C143.8 156 147.5 153.3 150.8 156C154.1 158.8 155.7 166 154.4 172.1Z" fill="url(#paint28_radial_5149_80650)"/>
    <path d="M200.6 172.1C199.3 178.2 195.6 180.9 192.3 178.2C189 175.4 187.4 168.2 188.7 162.1C190 156 193.7 153.3 197 156C200.3 158.8 201.9 166 200.6 172.1Z" fill="url(#paint29_linear_5149_80650)"/>
    <path d="M140 268.8L139.9 169.9L44.3999 138.8L44.4999 237.8L140 268.8Z" fill="#999999"/>
    <path d="M137.5 262.8L137.4 173C137.4 172.2 136.9 171.4 136.1 171.2L49.3999 143C48.1999 142.6 46.8999 143.5 46.8999 144.8L46.9999 234.6C46.9999 235.4 47.4999 236.2 48.2999 236.4L135 264.5C136.2 265.1 137.5 264.1 137.5 262.8Z" fill="#CCCCCC"/>
    <path d="M146.3 204.8V186.6L137.6 183.8C133.3 182.4 128.8 185.6 128.8 190.2V190.8C128.8 195.8 132 200.2 136.7 201.7L145 206.1L146.3 204.8Z" fill="#999999"/>
    <path d="M145 206.1V187.9L136.3 185.1C132 183.7 127.5 186.9 127.5 191.5V192.1C127.5 197.1 130.7 201.5 135.4 203L145 206.1Z" fill="#E6E6E6"/>
    <path d="M68.7999 196.4C68.7999 194.8 67.8999 193.4 66.4999 192.7L51.1999 184.9L49.5999 186.5L51.2999 193.9L66.3999 199.5L68.2999 197.9C68.4999 197.5 68.7999 197 68.7999 196.4Z" fill="url(#paint30_linear_5149_80650)"/>
    <path d="M67.0999 197.9C67.0999 196.4 66.2999 195.1 64.8999 194.4L49.3999 186.5V195.6L64.4999 199.8C65.8999 200.1 67.0999 199.2 67.0999 197.9Z" fill="url(#paint31_linear_5149_80650)"/>
    <path d="M146.3 257.9V239.7L137.6 236.9C133.3 235.5 128.8 238.7 128.8 243.3V243.9C128.8 248.9 132 253.3 136.7 254.8L145 259.2L146.3 257.9Z" fill="#999999"/>
    <path d="M145 259.2V241L136.3 238.2C132 236.8 127.5 240 127.5 244.6V245.2C127.5 250.2 130.7 254.6 135.4 256.1L145 259.2Z" fill="#E6E6E6"/>
    <path d="M170.5 308.7V144.5L183.2 141.6L183.1 305.8L170.5 308.7Z" fill="url(#paint32_linear_5149_80650)"/>
    <path d="M302.4 276.9L302.6 112.7L315.2 109.7L315.1 273.9L302.4 276.9Z" fill="url(#paint33_linear_5149_80650)"/>
    <path d="M170.5 144.5L315.1 109.7V122.9L170.5 157.7V144.5Z" fill="url(#paint34_linear_5149_80650)"/>
    <path d="M170.5 295.5L315.1 260.7V273.9L170.5 308.7V295.5Z" fill="url(#paint35_linear_5149_80650)"/>
    <path d="M30.7999 263.1L30.6 98.8999L18.2 94.8999V259.1L30.7999 263.1Z" fill="#E6E6E6"/>
    <path d="M170.5 308.7V144.5L158.4 140.5L158.5 304.7L170.5 308.7Z" fill="#E6E6E6"/>
    <path d="M170.5 144.5L18.2 94.8999V108L170.5 157.6V144.5Z" fill="#E6E6E6"/>
    <path d="M170.5 295.6L18.2 245.9V259.1L170.5 308.7V295.6Z" fill="#E6E6E6"/>
    <path d="M183.2 141.6L30.5 91.9998L18.3 94.7998L170.6 144.4L183.2 141.6Z" fill="#CECECE"/>
    <path d="M315.3 109.7L162.6 59.9998L150.3 62.7998L303.1 112.4L315.3 109.7Z" fill="#CECECE"/>
    <path d="M315.2 109.7L303.9 106.1L159.7 140.9L170.6 144.5L315.2 109.7Z" fill="#CECECE"/>
    <path d="M173.7 63.3998L162.4 59.7998L18.2 94.5998L29.1 98.1998L173.7 63.3998Z" fill="#CECECE"/>
    <defs>
    <linearGradient id="paint0_linear_5149_80650" x1="398.249" y1="294.536" x2="421.376" y2="370.106" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint1_linear_5149_80650" x1="393.876" y1="295.874" x2="417.003" y2="371.444" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint2_linear_5149_80650" x1="386.558" y1="298.114" x2="409.685" y2="373.684" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint3_linear_5149_80650" x1="305.5" y1="342.362" x2="391.994" y2="371.094" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint4_linear_5149_80650" x1="437.551" y1="310.552" x2="523.909" y2="339.239" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint5_linear_5149_80650" x1="331.9" y1="263.8" x2="476.5" y2="263.8" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint6_linear_5149_80650" x1="331.9" y1="414.85" x2="476.5" y2="414.85" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint7_linear_5149_80650" x1="240.146" y1="342.921" x2="263.273" y2="418.492" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint8_linear_5149_80650" x1="235.71" y1="344.279" x2="258.837" y2="419.849" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint9_linear_5149_80650" x1="228.391" y1="346.518" x2="251.518" y2="422.089" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <radialGradient id="paint10_radial_5149_80650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(148.479 336.943) rotate(18.92) scale(8.23442 10.1783)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <linearGradient id="paint11_linear_5149_80650" x1="188.195" y1="334.788" x2="201.568" y2="337.606" gradientUnits="userSpaceOnUse">
    <stop stop-color="#29ABE2"/>
    <stop offset="0.2524" stop-color="#27A8DF"/>
    <stop offset="0.4563" stop-color="#239ED5"/>
    <stop offset="0.6434" stop-color="#1B8DC4"/>
    <stop offset="0.8203" stop-color="#0F75AC"/>
    <stop offset="0.9886" stop-color="#01568E"/>
    <stop offset="1" stop-color="#00548C"/>
    </linearGradient>
    <linearGradient id="paint12_linear_5149_80650" x1="49.4984" y1="362.1" x2="68.7517" y2="362.1" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint13_linear_5149_80650" x1="58.9428" y1="359.17" x2="55.2172" y2="375.539" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint14_linear_5149_80650" x1="144.125" y1="382.153" x2="230.62" y2="410.884" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint15_linear_5149_80650" x1="276.192" y1="350.298" x2="362.549" y2="378.985" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint16_linear_5149_80650" x1="170.5" y1="303.6" x2="315.1" y2="303.6" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint17_linear_5149_80650" x1="170.5" y1="454.6" x2="315.1" y2="454.6" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint18_linear_5149_80650" x1="398.23" y1="124.675" x2="421.357" y2="200.246" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint19_linear_5149_80650" x1="393.886" y1="126.005" x2="417.013" y2="201.575" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint20_linear_5149_80650" x1="386.567" y1="128.245" x2="409.694" y2="203.815" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint21_linear_5149_80650" x1="305.48" y1="172.554" x2="391.975" y2="201.286" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint22_linear_5149_80650" x1="437.547" y1="140.7" x2="523.904" y2="169.387" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint23_linear_5149_80650" x1="331.9" y1="94" x2="476.5" y2="94" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint24_linear_5149_80650" x1="331.9" y1="245" x2="476.5" y2="245" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint25_linear_5149_80650" x1="240.155" y1="173.052" x2="263.282" y2="248.622" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint26_linear_5149_80650" x1="235.691" y1="174.418" x2="258.818" y2="249.989" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint27_linear_5149_80650" x1="228.372" y1="176.658" x2="251.499" y2="252.228" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <radialGradient id="paint28_radial_5149_80650" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(148.479 167.077) rotate(18.9199) scale(8.23442 10.1783)">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="1" stop-color="#00A99D"/>
    </radialGradient>
    <linearGradient id="paint29_linear_5149_80650" x1="188.201" y1="164.889" x2="201.574" y2="167.708" gradientUnits="userSpaceOnUse">
    <stop stop-color="#29ABE2"/>
    <stop offset="0.2524" stop-color="#27A8DF"/>
    <stop offset="0.4563" stop-color="#239ED5"/>
    <stop offset="0.6434" stop-color="#1B8DC4"/>
    <stop offset="0.8203" stop-color="#0F75AC"/>
    <stop offset="0.9886" stop-color="#01568E"/>
    <stop offset="1" stop-color="#00548C"/>
    </linearGradient>
    <linearGradient id="paint30_linear_5149_80650" x1="49.4984" y1="192.2" x2="68.7517" y2="192.2" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint31_linear_5149_80650" x1="58.9355" y1="189.302" x2="55.2099" y2="205.67" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint32_linear_5149_80650" x1="144.135" y1="212.256" x2="230.63" y2="240.988" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint33_linear_5149_80650" x1="276.187" y1="180.446" x2="362.544" y2="209.133" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint34_linear_5149_80650" x1="170.5" y1="133.7" x2="315.1" y2="133.7" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    <linearGradient id="paint35_linear_5149_80650" x1="170.5" y1="284.7" x2="315.1" y2="284.7" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1" stop-color="#525252"/>
    </linearGradient>
    </defs>
    </svg>
     );
}
export default Air_3d_Icon;