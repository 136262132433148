const Icon_Solar = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#F2F8F6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.9757 14.5882C16.6858 14.5882 16.339 14.8019 16.227 15.3622L13.9741 26.5487L13.9723 26.5566C13.8915 26.9068 13.9654 27.2046 14.1627 27.4184C14.3667 27.6393 14.788 27.8618 15.5785 27.8618H33.1989C33.6248 27.8618 33.8451 27.802 33.9502 27.7491C34.0249 27.7116 34.0348 27.6844 34.0445 27.6492C34.0639 27.5786 34.0687 27.4517 34.0324 27.227C34.0079 27.0755 33.9739 26.9259 33.9348 26.7541C33.9149 26.6663 33.8937 26.5727 33.8717 26.4701L33.8706 26.465L31.6977 15.3677C31.58 14.8497 31.2122 14.5882 30.9479 14.5882H16.9757ZM14.3091 14.3571C14.5077 13.3652 15.2476 12.6476 16.1995 12.6476H31.7241C32.7007 12.6476 33.4176 13.4706 33.612 14.3455L33.6136 14.3527L35.7875 27.0073C35.8023 27.0761 35.8191 27.1506 35.8367 27.2281C35.8801 27.4196 35.9276 27.6292 35.958 27.8173C36.0017 28.0875 36.0308 28.4166 35.9434 28.7341C35.8464 29.0871 35.6186 29.3801 35.2494 29.5657C34.9106 29.736 34.4808 29.8023 33.9752 29.8023H14.8022C13.8073 29.8023 13.0255 29.5202 12.5309 28.9844C12.0317 28.4436 11.9105 27.7362 12.0596 27.0796L14.3091 14.3571Z" fill="#FFA800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.0038 28.419L20.9353 13.981L21.7099 14.031L20.7784 28.4689L20.0038 28.419Z" fill="#FFA800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.2227 28.4689L26.2913 14.031L27.0659 13.981L27.9974 28.419L27.2227 28.4689Z" fill="#FFA800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.2679 18.2753H32.8108V19.0515H15.2679V18.2753Z" fill="#FFA800"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2589 22.9327H33.82V23.7089H14.2589V22.9327Z" fill="#FFA800"/>
<path d="M33.7423 33.6447H30.0164V33.4118C30.0164 32.7132 29.5506 32.0922 29.0073 32.0922H25.6695V29.2202H22.3317V32.0922H18.9163C18.3729 32.0922 17.9072 32.6356 17.9072 33.4118V33.6447H14.1812C13.7155 33.6447 13.3274 34.1104 13.3274 34.6538V35.3524H34.5185V34.6538C34.5185 34.0328 34.1304 33.6447 33.7423 33.6447Z" fill="#FFA800"/>
</svg>

     );
}
 
export default Icon_Solar;