const Good_Icon_T = ({style}) => {
    return ( 
        <svg className={style} width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.713 26C20.8927 26 26.713 20.1797 26.713 13C26.713 5.8203 20.8927 0 13.713 0C6.53331 0 0.713013 5.8203 0.713013 13C0.713013 20.1797 6.53331 26 13.713 26Z" fill="url(#paint0_linear_1078_26773)"/>
<path d="M13.713 22.5469C16.1911 22.5469 18.5677 21.5624 20.32 19.8102C22.0723 18.0579 23.0568 15.6812 23.0568 13.2031H4.36926C4.36926 15.6812 5.35369 18.0579 7.10599 19.8102C8.85828 21.5624 11.2349 22.5469 13.713 22.5469Z" fill="url(#paint1_linear_1078_26773)"/>
<path d="M19.6036 13.2031V20.4507C19.1018 20.8628 18.557 21.2196 17.9786 21.515V13.2031H19.6036Z" fill="#DDC1A3"/>
<path d="M14.5255 13.2031V22.5103C14.2574 22.5347 13.9892 22.5469 13.713 22.5469C13.4368 22.5469 13.1686 22.5347 12.9005 22.5103V13.2031H14.5255Z" fill="#DDC1A3"/>
<path d="M9.44739 13.2031V21.515C8.86904 21.2196 8.32423 20.8628 7.82239 20.4507V13.2031H9.44739Z" fill="#DDC1A3"/>
<path d="M21.838 10.1563V8.9375C21.838 8.29103 21.5812 7.67105 21.1241 7.21393C20.667 6.75681 20.047 6.5 19.4005 6.5C18.754 6.5 18.1341 6.75681 17.6769 7.21393C17.2198 7.67105 16.963 8.29103 16.963 8.9375V10.5625L21.838 10.1563Z" fill="url(#paint2_linear_1078_26773)"/>
<path d="M20.1114 9.14063C20.6163 9.14063 21.0255 8.73138 21.0255 8.22656C21.0255 7.72174 20.6163 7.3125 20.1114 7.3125C19.6066 7.3125 19.1974 7.72174 19.1974 8.22656C19.1974 8.73138 19.6066 9.14063 20.1114 9.14063Z" fill="#FFEFDC"/>
<path d="M10.463 10.5625V8.9375C10.463 8.29103 10.2062 7.67105 9.74909 7.21393C9.29197 6.75681 8.67198 6.5 8.02551 6.5C7.37905 6.5 6.75906 6.75681 6.30194 7.21393C5.84482 7.67105 5.58801 8.29103 5.58801 8.9375V10.1563L10.463 10.5625Z" fill="url(#paint3_linear_1078_26773)"/>
<path d="M8.73645 9.14063C9.24127 9.14063 9.65051 8.73138 9.65051 8.22656C9.65051 7.72174 9.24127 7.3125 8.73645 7.3125C8.23163 7.3125 7.82239 7.72174 7.82239 8.22656C7.82239 8.73138 8.23163 9.14063 8.73645 9.14063Z" fill="#FFEFDC"/>
<defs>
<linearGradient id="paint0_linear_1078_26773" x1="13.713" y1="0" x2="13.713" y2="26" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD359"/>
<stop offset="0.0702" stopColor="#FFCD4F"/>
<stop offset="0.2689" stopColor="#FFC037"/>
<stop offset="0.4806" stopColor="#FFB726"/>
<stop offset="0.7119" stopColor="#FFB21C"/>
<stop offset="1" stopColor="#FFB019"/>
</linearGradient>
<linearGradient id="paint1_linear_1078_26773" x1="13.713" y1="13.2031" x2="13.713" y2="22.5469" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFF7EE"/>
<stop offset="0.2919" stopColor="#FEF5EB"/>
<stop offset="0.5263" stopColor="#FBEEE1"/>
<stop offset="0.7407" stopColor="#F5E3D0"/>
<stop offset="0.9422" stopColor="#EDD3B8"/>
<stop offset="1" stopColor="#EACEB0"/>
</linearGradient>
<linearGradient id="paint2_linear_1078_26773" x1="17.5231" y1="7.36776" x2="21.0748" y2="10.9195" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
<linearGradient id="paint3_linear_1078_26773" x1="6.24966" y1="7.2662" x2="10.0045" y2="11.021" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
</defs>
</svg>

     );
}
 
const Good_Icon_F =({style}) =>{
    return (
        <svg className={style} width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.52979 0C6.94754 0 5.40082 0.469191 4.08523 1.34824C2.76963 2.22729 1.74425 3.47672 1.13875 4.93853C0.533251 6.40034 0.374825 8.00887 0.683507 9.56072C0.992188 11.1126 1.75411 12.538 2.87293 13.6569C3.99175 14.7757 5.41722 15.5376 6.96907 15.8463C8.52091 16.155 10.1294 15.9965 11.5913 15.391C13.0531 14.7855 14.3025 13.7602 15.1815 12.4446C16.0606 11.129 16.5298 9.58225 16.5298 8C16.5274 5.87901 15.6837 3.84558 14.184 2.34582C12.6842 0.84605 10.6508 0.00241846 8.52979 0ZM8.52979 15.0588C7.13368 15.0588 5.76893 14.6448 4.60812 13.8692C3.4473 13.0936 2.54255 11.9911 2.00829 10.7013C1.47402 9.41146 1.33423 7.99217 1.6066 6.62289C1.87897 5.25361 2.55125 3.99585 3.53845 3.00866C4.52564 2.02146 5.7834 1.34918 7.15268 1.07681C8.52196 0.804443 9.94125 0.944232 11.2311 1.4785C12.5209 2.01276 13.6234 2.91751 14.399 4.07833C15.1746 5.23914 15.5886 6.60389 15.5886 8C15.5865 9.87147 14.8421 11.6657 13.5188 12.989C12.1955 14.3124 10.4013 15.0567 8.52979 15.0588Z" fill="current"/>
<path d="M13.7063 7.5294H3.3534C3.2916 7.52939 3.2304 7.54156 3.1733 7.5652C3.1162 7.58885 3.06432 7.62351 3.02062 7.66721C2.97692 7.71091 2.94226 7.76279 2.91861 7.81989C2.89497 7.87699 2.8828 7.93819 2.88281 7.99999C2.88281 9.49768 3.47777 10.934 4.5368 11.9931C5.59583 13.0521 7.03218 13.647 8.52987 13.647C10.0276 13.647 11.4639 13.0521 12.5229 11.9931C13.582 10.934 14.1769 9.49768 14.1769 7.99999C14.1769 7.93819 14.1648 7.87699 14.1411 7.81989C14.1175 7.76279 14.0828 7.71091 14.0391 7.66721C13.9954 7.62351 13.9435 7.58885 13.8864 7.5652C13.8293 7.54156 13.7681 7.52939 13.7063 7.5294ZM8.52987 12.7059C7.36365 12.7044 6.23939 12.2706 5.37448 11.4883C4.50956 10.706 3.96542 9.6308 3.84727 8.47058H13.2125C13.0943 9.6308 12.5502 10.706 11.6853 11.4883C10.8203 12.2706 9.69609 12.7044 8.52987 12.7059Z" fill="current"/>
<path d="M10.1768 5.43842L12.0592 6.52513C12.1127 6.55624 12.1718 6.57648 12.2332 6.58469C12.2945 6.59291 12.3569 6.58893 12.4167 6.573C12.4765 6.55707 12.5326 6.52949 12.5818 6.49184C12.6309 6.4542 12.6721 6.40722 12.7031 6.35361C12.7341 6.30001 12.7541 6.24082 12.7622 6.17944C12.7702 6.11806 12.7661 6.05569 12.75 5.99592C12.7339 5.93615 12.7061 5.88014 12.6683 5.83111C12.6305 5.78207 12.5835 5.74098 12.5298 5.71017L11.3533 5.03094L12.5298 4.35156C12.6378 4.28916 12.7167 4.18638 12.749 4.06584C12.7813 3.94529 12.7643 3.81686 12.7019 3.70879C12.6395 3.60072 12.5368 3.52187 12.4162 3.48958C12.2957 3.45729 12.1672 3.47421 12.0592 3.53661L10.1768 4.62347C10.1053 4.66475 10.0459 4.72415 10.0046 4.79568C9.96327 4.8672 9.94153 4.94834 9.94153 5.03094C9.94153 5.11354 9.96327 5.19468 10.0046 5.2662C10.0459 5.33773 10.1053 5.39712 10.1768 5.43841V5.43842Z" fill="current"/>
<path d="M4.35764 6.35294C4.38848 6.4065 4.42959 6.45346 4.47861 6.49112C4.52763 6.52877 4.58359 6.55639 4.6433 6.57238C4.70301 6.58838 4.76529 6.59243 4.82656 6.58432C4.88784 6.57621 4.94692 6.5561 5.00041 6.52512L6.88276 5.43841C6.9543 5.39712 7.0137 5.33773 7.05501 5.2662C7.09631 5.19468 7.11805 5.11354 7.11805 5.03094C7.11805 4.94834 7.09631 4.8672 7.05501 4.79568C7.0137 4.72415 6.9543 4.66475 6.88276 4.62347L5.00041 3.53661C4.9469 3.50571 4.88783 3.48566 4.82656 3.47759C4.7653 3.46952 4.70305 3.47359 4.64337 3.48958C4.58368 3.50557 4.52773 3.53316 4.4787 3.57077C4.42968 3.60838 4.38854 3.65528 4.35764 3.70879C4.32674 3.7623 4.30668 3.82137 4.29862 3.88264C4.29055 3.9439 4.29462 4.00615 4.31061 4.06584C4.3266 4.12552 4.35419 4.18147 4.3918 4.2305C4.42941 4.27952 4.47631 4.32066 4.52982 4.35156L5.70629 5.03094L4.52982 5.71017C4.4763 5.74107 4.4294 5.7822 4.39178 5.83122C4.35416 5.88024 4.32657 5.9362 4.31058 5.99589C4.29459 6.05558 4.29051 6.11783 4.29859 6.17909C4.30666 6.24036 4.32673 6.29943 4.35764 6.35294Z" fill="current"/>
</svg>

    )
}

export {Good_Icon_T,Good_Icon_F}