import { SelectNormalIcons } from "../SetupWidget/SelectWidgetIcon";
import MultiSelectBtn from "./multiSelectBtn";
import { useGetDeviceType } from "../../../api/userInfo";
import { useEffect, useState } from "react";

/*
setup fields for single field widget (don't have multiple fields)
- Performance Device Listed
*/

// "setup": {"measurement": [{"device": 62, "field": "_output_line_energy", "unit": "kWh"}]}
export const WidgetTitleTypeSetup = ({clearForm, submitForm, updateWidgetIcon, widgetIcon, updateSetupDetails, setupDetails, selectedWidget}) => {
    const {getDeviceType} = useGetDeviceType();
    const [selectedType, setSelectedType] = useState('')
    

    const onInput = (field) => (e) => {
            setSelectedType(e.target.value)
            updateSetupDetails({
                ...setupDetails,
                [field]: e.target.value
              });
    };
    
    // external_id - brand - model - name
    return ( 
        <>
            <div  className="grid items-center grid-cols-4 gap-3 mt-4 mb-4">

            <div  className="flex flex-col col-span-2">
                            <label htmlFor={`title`} className="col-span-1 text-sm text-gray-500">Title</label>
                            <input onChange={onInput(`title`)} name={`title`} autoComplete="off" required type="text" className="setupModal_input" placeholder="Title of widget"/>
                        </div> 

                <div className="w-full col-span-2">

                            <label htmlFor="field" className="text-sm text-gray-500 ">Device Type - {selectedType}</label>
                        
                            <select onChange={onInput('type')} className="setupModal_input" required>
                                <option value="">Select Device Type</option>
                                {getDeviceType?.map((device, index) => (
                                    <option key={device?.name} value={device?.name}>{device?.name} - {device?.description}</option>
                                ))}
                            </select>
                    
                </div>
            </div>
                
                
                    <SelectNormalIcons updateSetupDetails={updateSetupDetails} setupDetails={setupDetails}/> 
                
                
                {/* ------------ submit button */}
                <div className='flex flex-row justify-between gap-4 mt-4'>
                <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
                    Cancel
                </button>

                
                    <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                        Submit
                    </button>  

                
                </div>
        </>
     );
}


 

