import axios from "axios";

class HTTPCore {
  api;
  constructor({ }) {
    this.api = axios.create({
      withCredentials: true,
      xsrfCookieName : "csrfToken",
      xsrfHeaderName : "X-CSRFToken",
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json',
      },
    });  
  }

  post(path = "/", data = {}, config = {}) {
    return this.api.post(path, data, config).then(_processData);
  }

  get(path = "/", config = {}) {
    return this.api.get(path, config).then(_processData);
  }

  put(path = "/", data = {}, config = {}) {
    return this.api.put(path, data, config).then(_processData);
  }

  patch(path = "/", data = {}, config = {}) {
    return this.api.patch(path, data, config).then(_processData);
  }

  delete(path = "/", config = {}) {
    return this.api.delete(path, config).then(_processData);
  }
}

const _processData = (res) => {
  return res.data
};

export default HTTPCore;
