function GradientSVGType2(props) {
    return (
        <svg>
            <defs>
            <linearGradient id={`GradientSVGType2`}>
                    <stop offset="0%" stopColor={`#EFB274`} />
                    <stop offset="100%" stopColor={`#FFE24A`} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GradientSVGType2;