import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { registerNewUser, sendVerify } from '../../api/userInfo'
import bg_image from '../../assets/backgroundImages/company_bg.jpg'
import IconVisibility from '../../assets/images/ic_visibility.svg'
import IconVisibilityOff from '../../assets/images/ic_visibility_off.svg'
import Verify from '../authentication/Verify'

const SignUpPage = () => {
  const { t } = useTranslation('login')
  const [form, setForm] = useState({
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    username: undefined,
    phone: undefined,
    is_accepted_active_consent: undefined
  })

  const [isShowPassword, setIsShowPassword] = useState(false)
  const [verify, setVerify] = useState(false)
  const [isChecked, setIsChecked] = useState(false)

  const [terms, setTerms] = useState(false)
  const [consent, setConsent] = useState(false)
  const [receiveUpdates, setReceiveUpdates] = useState(false)
  const [passwordError, setPassError] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState(undefined)
  const [errorMsg, setErrorMsg] = useState(false)

  const onOptionChange = (e) => {
    setIsChecked(e.target.value)
  }

  const onClickShowPassword = () => {
    setIsShowPassword(!isShowPassword)
  }

  const onInput = (field) => (e) => {
    if (field === 'passwordConfirm') {
      setConfirmPassword(e.target.value)
    } else {
      //console.log(terms, e.target.value)
      setForm({
        ...form,
        [field]: e.target.value
      })
    }
  }

  const openModal = () => {
    setVerify(true)
  }

  const closeModal = () => {
    setVerify(false)
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (confirmPassword === form?.password) {
      if (terms) {
        const form_data = { ...form, is_accepted_active_consent: terms, username: form.email }
        const { data, error } = await registerNewUser(form_data)
        if (data || error.response.data.detail === 'Username has been already use') {
          //console.log(data)
          const { verifyData, error } = await sendVerify({ email: form.email })
          if (!error) {
            openModal()
          } else {
            // alert(error)
            setErrorMsg(error.response.data.detail)
          }
        }
      } else {
        alert('Please check the terms and conditions')
      }
    } else {
      setPassError(true)
    }
  }

  useEffect(() => {
    if (errorMsg) {
      let error_msg = setTimeout(() => setErrorMsg(undefined), 3000)
      return () => {
        clearTimeout(error_msg)
      }
    }
  }, [errorMsg])

  return (
    <div
      className="relative flex items-center justify-center w-full h-screen bg-no-repeat bg-cover bg-image"
      style={{ backgroundImage: `url(${bg_image})` }}
    >
      <div
        className={`flex flex-col justify-between p-8 bg-white shadow-md rounded-md w-[536px] ${
          verify ? 'hidden' : 'block'
        }`}
      >
        <h2 className="text-4xl font-bold text-vekin">Sign up</h2>
        {errorMsg && <span className="text-sm text-danger flex justify-center">{errorMsg}</span>}
        <form onSubmit={onSubmit} autoComplete="off">
          <section className="flex flex-col w-full gap-4 pr-3 mt-2">
            <fieldset className="grid grid-cols-2 gap-4 my-4">
              <legend className="sr-only">role</legend>

              <div className="flex items-center">
                <input
                  id="option-1"
                  type="radio"
                  name="role"
                  value="Personal"
                  className="w-6 h-6 accent-emerald-700"
                  checked={isChecked === 'Personal'}
                  onChange={onOptionChange}
                />
                <label
                  for="option-1"
                  className={`font-bold block ml-2 text-md ${
                    isChecked === 'Personal' ? 'text-vekin' : 'text-primary'
                  }`}
                >
                  Personal
                </label>
              </div>

              <div className="flex items-center">
                <input
                  id="option-2"
                  type="radio"
                  name="role"
                  value="Corporate"
                  className="w-6 h-6 accent-emerald-700"
                  checked={isChecked === 'Corporate'}
                  onChange={onOptionChange}
                />
                <label
                  for="option-2"
                  className={`font-bold block ml-2 text-md ${
                    isChecked === 'Corporate' ? 'text-vekin' : 'text-primary'
                  }`}
                >
                  Corporate
                </label>
              </div>
            </fieldset>
            {/* first and last name inputs*/}
            <div
              className={`flex flex-row w-full gap-6 ${
                isChecked === 'Corporate' ? 'hidden' : 'block'
              }`}
            >
              <div className="relative z-0 flex flex-col w-1/2 mb-4 group">
                <input
                  type="text"
                  name="floating_first_name"
                  id="floating_first_name"
                  className="block w-full appearance-none focus:outline-none focus:ring-0 peer"
                  value={form.first_name}
                  placeholder=""
                  onChange={onInput('first_name')}
                  style={{
                    marginTop: 4,
                    height: 48,
                    border: '2px solid rgba(172, 192, 214, 0.5)',
                    borderRadius: 10,
                    padding: '10px 0px 0px 8px',
                    fontSize: 14,
                    color: '#809FB8'
                  }}
                />
                <label
                  for="floating_first_name"
                  className="absolute pl-2 my-1 capitalize duration-300 transform scale-75 -translate-y-7 peer-focus:font-medium text-md text-primary top-6 z-12 -left-1.5"
                >
                  first name
                </label>
              </div>
              <div className="relative z-0 flex flex-col w-1/2 mb-4 group">
                <input
                  type="text"
                  name="floating_last_name"
                  id="floating_last_name"
                  className="block w-full appearance-none focus:outline-none focus:ring-0 peer "
                  value={form.last_name}
                  placeholder=""
                  onChange={onInput('last_name')}
                  style={{
                    marginTop: 4,
                    height: 48,
                    border: '2px solid rgba(172, 192, 214, 0.5)',
                    borderRadius: 10,
                    padding: '10px 0px 0px 8px',
                    fontSize: 14,
                    color: '#809FB8'
                  }}
                />
                <label
                  for="floating_last_name"
                  className="absolute pl-2 my-1 capitalize duration-300 transform scale-75 -translate-y-7 peer-focus:font-medium text-md text-primary top-6 z-12 -left-1.5"
                >
                  last name
                </label>
              </div>
            </div>

            {/* email inputs */}
            <div className="relative z-0 w-full mb-4 group">
              <input
                type="email"
                name="floating_email"
                id="floating_email"
                className="block w-full appearance-none focus:outline-none focus:ring-0 peer "
                autoComplete="off"
                value={form.email}
                placeholder=""
                onChange={onInput('email')}
                style={{
                  height: 48,
                  border: '2px solid rgba(172, 192, 214, 0.5)',
                  borderRadius: 10,
                  padding: '10px 0px 0px 8px',
                  fontSize: 14,
                  color: '#809FB8'
                }}
              />
              <label
                for="floating_email"
                className="absolute pl-2 my-1 capitalize duration-300 transform scale-75 -translate-y-7 peer-focus:font-medium text-md text-primary top-6 z-12 -left-1.5"
              >
                Email
              </label>
            </div>

            {/* phone inputs */}
            <div className="relative z-0 mb-4 group">
              <input
                type={'text'}
                name="floating_password"
                id="floating_password"
                className="block w-full appearance-none focus:outline-none focus:ring-0 peer"
                value={form.phone}
                placeholder=""
                onChange={onInput('phone')}
                style={{
                  height: 48,
                  border: '2px solid rgba(172, 192, 214, 0.5)',
                  borderRadius: 10,
                  padding: '10px 50px 0px 8px',
                  fontSize: 14,
                  color: '#809FB8'
                }}
              />

              <label
                for="floating_password"
                className="absolute my-1 capitalize duration-300 transform scale-75 -translate-y-7 peer-focus:font-medium text-md text-primary top-6 z-12 -left-1.5"
              >
                Phone Number
              </label>
            </div>

            {/* password */}
            <div className={`flex flex-row w-full gap-6 `}>
              <div className="relative z-0 flex flex-col w-1/2 mb-4 group">
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  name="floating_password"
                  id="floating_password"
                  className="block w-full appearance-none focus:outline-none focus:ring-0 peer"
                  value={form.password}
                  placeholder=""
                  onChange={onInput('password')}
                  style={{
                    height: 48,
                    border: '2px solid rgba(172, 192, 214, 0.5)',
                    borderRadius: 10,
                    padding: '10px 50px 0px 8px',
                    fontSize: 14,
                    color: '#809FB8'
                  }}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                  <img
                    src={isShowPassword ? IconVisibilityOff : IconVisibility}
                    alt=""
                    className="cursor-pointer"
                    onClick={onClickShowPassword}
                  />
                </div>
                <label
                  for="floating_password"
                  className="absolute pl-2 my-1 capitalize duration-300 transform scale-75 -translate-y-7 peer-focus:font-medium text-md text-primary top-6 z-12 -left-1.5"
                >
                  password
                </label>
              </div>
              <div className="relative z-0 flex flex-col w-1/2 mb-4 group">
                <input
                  type={isShowPassword ? 'text' : 'password'}
                  name="floating_password"
                  id="floating_password"
                  className="block w-full appearance-none focus:outline-none focus:ring-0 peer"
                  value={form.passwordConfirm}
                  placeholder=""
                  onChange={onInput('passwordConfirm')}
                  style={{
                    height: 48,
                    border: '2px solid rgba(172, 192, 214, 0.5)',
                    borderRadius: 10,
                    padding: '10px 50px 0px 8px',
                    fontSize: 14,
                    color: '#809FB8'
                  }}
                />
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
                  <img
                    src={isShowPassword ? IconVisibilityOff : IconVisibility}
                    alt=""
                    className="cursor-pointer"
                    onClick={onClickShowPassword}
                  />
                </div>
                <label
                  for="floating_password"
                  className="absolute my-1 capitalize duration-300 transform scale-75 -translate-y-7 peer-focus:font-medium text-md text-primary top-6 z-12 -left-1.5"
                >
                  Re-enter password
                </label>
                {passwordError && (
                  <span className="absolute text-[12px] -bottom-4 text-danger ">
                    Password do not match!
                  </span>
                )}
              </div>
            </div>

            <fieldset>
              <legend className="sr-only">Checkbox variants</legend>

              <div className="flex items-start items-center mb-4">
                <input
                  id="checkbox-1"
                  type="checkbox"
                  onChange={(e) => setReceiveUpdates(e?.target?.value)}
                  className="w-4 h-4 rounded accent-emerald-700 focus:ring-3 focus:ring-vekin"
                />
                <label for="checkbox-1" className="ml-3 text-sm font-medium text-gray-900">
                  I agree to receive email updates from Wallet
                </label>
              </div>

              <div className="flex items-start items-center mb-4">
                <input
                  id="checkbox-2"
                  type="checkbox"
                  onChange={(e) => setConsent(e?.target?.value)}
                  className="w-4 h-4 rounded accent-emerald-700 focus:ring-3 focus:ring-vekin"
                />
                <label for="checkbox-2" className="ml-3 text-sm font-medium text-gray-900">
                  I agree to share data for marketing purpose
                </label>
              </div>

              <div className="flex items-start items-center mb-4">
                <input
                  id="termsCondition"
                  type="checkbox"
                  defaultValue={terms}
                  onClick={(e) => setTerms(!terms)}
                  className="w-4 h-4 rounded accent-emerald-700 focus:ring-3 focus:ring-vekin"
                />
                <label for="termsCondition" className="ml-3 text-sm font-medium text-gray-900">
                  I agree to the{' '}
                  <a href="#" className="font-semibold text-vekin hover:underline">
                    Terms & Conditions and Privacy Policy
                  </a>
                </label>
              </div>
            </fieldset>
            <button
              className="w-full cursor-pointer"
              type="submit"
              style={{
                height: 48,
                marginTop: 24,
                backgroundColor: '#1FA37C',
                padding: 12,
                borderRadius: 10,
                fontSize: 16,
                fontWeight: 500,
                color: 'white',
                textAlign: 'center'
              }}
            >
              Sign Up
            </button>
            <p className="text-center text-primary">
              Already have an account?{' '}
              <a href="/login" className="font-semibold text-center text-vekin">
                {' '}
                login here!
              </a>{' '}
            </p>
          </section>
        </form>
      </div>
      <Verify userEmail={form?.email} verify={verify} closeModal={closeModal} />
    </div>
  )
}
export default SignUpPage
