export const ShowerRain_S = ({style}) => {
    return ( 
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.6543 9.56267C10.7108 9.74737 10.8946 9.86572 11.0904 9.84343C11.187 9.83281 11.2857 9.82698 11.3855 9.82698C11.4392 9.82698 11.4918 9.82857 11.5444 9.83175C11.7006 9.84131 11.8481 9.76223 11.924 9.62901C12.4024 8.79256 13.3163 8.22678 14.3647 8.22678C15.796 8.22678 16.9779 9.28243 17.1395 10.6411C17.1634 10.8418 17.3375 10.993 17.5436 10.993C18.2438 10.9936 18.8583 11.3497 19.2097 11.8868C19.2748 11.9866 19.3822 12.0524 19.5026 12.0672C19.623 12.0816 19.7434 12.0428 19.8318 11.9611C20.9079 10.966 21.5799 9.55577 21.5799 7.99326C21.5799 4.97969 19.0796 2.53296 16 2.53296C12.9204 2.53296 10.42 4.97969 10.42 7.99326C10.42 8.53886 10.5019 9.06536 10.6543 9.56267Z" fill="url(#paint0_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5933 0.398058V1.45955C15.5933 1.67927 15.7755 1.8576 16 1.8576C16.2246 1.8576 16.4068 1.67927 16.4068 1.45955V0.398058C16.4068 0.17833 16.2246 0 16 0C15.7755 0 15.5933 0.17833 15.5933 0.398058Z" fill="url(#paint1_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.4441 1.19465L18.9017 2.1139C18.79 2.30391 18.8567 2.54752 19.0509 2.65739C19.245 2.76725 19.4945 2.70197 19.6068 2.51196L20.1492 1.59271C20.2609 1.40218 20.1942 1.15856 20 1.0487C19.8058 0.938836 19.5564 1.00412 19.4441 1.19465Z" fill="url(#paint2_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.3724 3.76832L21.433 4.29906C21.2388 4.40893 21.1721 4.65307 21.2844 4.84308C21.3966 5.03308 21.6456 5.09836 21.8397 4.98903L22.7791 4.45829C22.9738 4.34842 23.0406 4.10428 22.9283 3.91427C22.816 3.72427 22.5671 3.65899 22.3724 3.76832Z" fill="url(#paint3_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.5932 7.43042H22.5085C22.284 7.43042 22.1017 7.60875 22.1017 7.82848C22.1017 8.04821 22.284 8.22654 22.5085 8.22654H23.5932C23.8178 8.22654 24 8.04821 24 7.82848C24 7.60875 23.8178 7.43042 23.5932 7.43042Z" fill="url(#paint4_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.7791 11.199L21.8397 10.6682C21.6456 10.5589 21.3966 10.6242 21.2844 10.8142C21.1721 11.0042 21.2388 11.2483 21.433 11.3582L22.3724 11.8889C22.5671 11.9983 22.816 11.933 22.9283 11.743C23.0406 11.553 22.9738 11.3088 22.7791 11.199Z" fill="url(#paint5_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M8.40678 8.22644H9.49153C9.71607 8.22644 9.89831 8.04811 9.89831 7.82839C9.89831 7.60866 9.71607 7.43033 9.49153 7.43033H8.40678C8.18224 7.43033 8 7.60866 8 7.82839C8 8.04811 8.18224 8.22644 8.40678 8.22644Z" fill="url(#paint6_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.2209 4.45829L10.1603 4.98903C10.3545 5.09836 10.6034 5.03308 10.7157 4.84308C10.828 4.65307 10.7612 4.40893 10.5671 4.29906L9.62768 3.76832C9.43297 3.65899 9.18402 3.72427 9.07175 3.91427C8.95948 4.10428 9.02619 4.34842 9.2209 4.45829Z" fill="url(#paint7_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.8509 1.59271L12.3932 2.51196C12.5055 2.70197 12.755 2.76725 12.9492 2.65739C13.1433 2.54752 13.2101 2.30391 13.0983 2.1139L12.556 1.19465C12.4437 1.00412 12.1942 0.938836 12 1.0487C11.8059 1.15856 11.7391 1.40218 11.8509 1.59271Z" fill="url(#paint8_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.3789 7.19107H15.3956C17.9369 7.19107 20 9.16496 20 11.5958C20 13.218 19.1045 16 15.6716 16C6.77054 16 6.77054 16 6.77054 16C5.54193 16 4.60376 15.5855 3.96871 14.9334C3.33539 14.2824 3 13.3867 3 12.394C3 10.5226 4.49024 8.98417 6.39712 8.80502C6.83508 6.6382 8.83071 5 11.2228 5C12.9712 5 14.5076 5.87477 15.3789 7.19107Z" fill="url(#paint9_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.36919 18.0588C7.35243 17.8819 7.23504 17.7299 7.06808 17.6691C6.90112 17.6084 6.71349 17.6493 6.58698 17.7741C6.58698 17.7741 5.1994 19.167 4.93888 19.8827C4.70402 20.528 5.03749 21.2421 5.68221 21.4768C6.32693 21.7115 7.04141 21.3788 7.27627 20.7335C7.5368 20.0177 7.36919 18.0588 7.36919 18.0588Z" fill="url(#paint10_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.7682 19.0539C11.7517 18.8764 11.6341 18.725 11.4671 18.6643C11.3002 18.6035 11.1128 18.6439 10.986 18.7692C10.986 18.7692 9.36702 20.3899 9.0634 21.2241C8.80224 21.9416 9.17262 22.7359 9.89013 22.997C10.6076 23.2582 11.4019 22.8878 11.6631 22.1703C11.9667 21.3361 11.7682 19.0539 11.7682 19.0539Z" fill="url(#paint11_linear_1570_78169)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.8559 17.9982C15.8385 17.8212 15.7215 17.67 15.5546 17.6092C15.387 17.5482 15.2002 17.5888 15.0737 17.7135C15.0737 17.7135 13.6859 19.107 13.4254 19.8228C13.1907 20.4675 13.5234 21.182 14.1687 21.4169C14.8134 21.6515 15.5275 21.318 15.7622 20.6733C16.0227 19.9575 15.8559 17.9982 15.8559 17.9982Z" fill="url(#paint12_linear_1570_78169)"/>
<defs>
<linearGradient id="paint0_linear_1570_78169" x1="16.9207" y1="7.23631" x2="14.6191" y2="16.6678" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#DBB500"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78169" x1="16.0146" y1="0.99548" x2="16.0146" y2="5.44013" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78169" x1="19.5942" y1="1.72487" x2="17.6942" y2="5.57478" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78169" x1="22.2073" y1="4.37868" x2="19.292" y2="6.29377" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_78169" x1="23.1885" y1="7.82848" x2="17.7511" y2="7.82848" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint5_linear_1570_78169" x1="21.9661" y1="11.2786" x2="19.6364" y2="9.29952" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint6_linear_1570_78169" x1="8.80958" y1="7.82839" x2="18.7118" y2="7.82839" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint7_linear_1570_78169" x1="9.7928" y1="4.37868" x2="15.1627" y2="7.93475" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint8_linear_1570_78169" x1="12.4746" y1="1.83261" x2="15.0353" y2="6.02071" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint9_linear_1570_78169" x1="10.6891" y1="12.5146" x2="10.6891" y2="31.3204" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint10_linear_1570_78169" x1="6.84161" y1="20.0996" x2="3.58247" y2="29.054" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
<linearGradient id="paint11_linear_1570_78169" x1="10.6786" y1="20.8307" x2="6.77037" y2="31.5685" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
<linearGradient id="paint12_linear_1570_78169" x1="14.2454" y1="19.7098" x2="10.1452" y2="30.975" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
</defs>
</svg>


        


     );
}

export const ShowerRain_N = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M23.8746 8.65118C23.623 8.27241 23.1774 8.07591 22.7331 8.14443C22.6156 8.15994 22.493 8.18192 22.3717 8.20906C20.1386 8.74168 18.3765 8.28792 17.2835 7.12832C16.1842 5.96226 15.7794 4.11492 16.2046 1.94568C16.2046 1.94568 16.3476 1.41695 15.842 1.07178C15.3466 0.77445 14.3762 1.48159 14.1451 1.72204C12.8299 2.90361 12 4.62814 12 6.54917C12 10.1094 14.8563 13 18.3714 13C20.3467 13 22.11 12.0899 23.2796 10.664C23.5132 10.4378 24.3036 9.28075 23.8746 8.65118Z" fill="#6CCCDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.8746 8.65118C23.623 8.27241 23.1774 8.07591 22.7331 8.14443C22.6156 8.15994 22.493 8.18192 22.3717 8.20906C20.1386 8.74168 18.3765 8.28792 17.2835 7.12832C16.1842 5.96226 15.7794 4.11492 16.2046 1.94568C16.2046 1.94568 16.3476 1.41695 15.842 1.07178C15.3466 0.77445 14.3762 1.48159 14.1451 1.72204C12.8299 2.90361 12 4.62814 12 6.54917C12 10.1094 14.8563 13 18.3714 13C20.3467 13 22.11 12.0899 23.2796 10.664C23.5132 10.4378 24.3036 9.28075 23.8746 8.65118Z" fill="url(#paint0_linear_1570_78170)" fillOpacity="0.7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.3789 7.19107H15.3956C17.9369 7.19107 20 9.16496 20 11.5958C20 13.218 19.1045 16 15.6716 16C6.77054 16 6.77054 16 6.77054 16C5.54193 16 4.60376 15.5855 3.96871 14.9334C3.33539 14.2824 3 13.3867 3 12.394C3 10.5226 4.49024 8.98417 6.39712 8.80502C6.83508 6.6382 8.83071 5 11.2228 5C12.9712 5 14.5076 5.87477 15.3789 7.19107Z" fill="url(#paint1_linear_1570_78170)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M7.36919 18.0586C7.35243 17.8818 7.23504 17.7298 7.06808 17.669C6.90112 17.6083 6.71349 17.6492 6.58698 17.7739C6.58698 17.7739 5.1994 19.1668 4.93888 19.8826C4.70402 20.5279 5.03749 21.242 5.68221 21.4767C6.32693 21.7113 7.04141 21.3786 7.27627 20.7333C7.5368 20.0176 7.36919 18.0586 7.36919 18.0586Z" fill="url(#paint2_linear_1570_78170)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.7682 19.0539C11.7517 18.8764 11.6341 18.725 11.4671 18.6643C11.3002 18.6035 11.1128 18.6439 10.986 18.7692C10.986 18.7692 9.36702 20.3899 9.0634 21.2241C8.80224 21.9416 9.17262 22.7359 9.89013 22.997C10.6076 23.2582 11.4019 22.8878 11.6631 22.1703C11.9667 21.3361 11.7682 19.0539 11.7682 19.0539Z" fill="url(#paint3_linear_1570_78170)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.8559 17.9982C15.8385 17.8212 15.7215 17.67 15.5546 17.6092C15.387 17.5482 15.2002 17.5888 15.0737 17.7135C15.0737 17.7135 13.6859 19.107 13.4254 19.8228C13.1907 20.4675 13.5234 21.182 14.1687 21.4169C14.8134 21.6515 15.5275 21.318 15.7622 20.6733C16.0227 19.9575 15.8559 17.9982 15.8559 17.9982Z" fill="url(#paint4_linear_1570_78170)"/>
<defs>
<linearGradient id="paint0_linear_1570_78170" x1="12.8571" y1="1.27033e-05" x2="24.0487" y2="0.341087" gradientUnits="userSpaceOnUse">
<stop stopColor="#0085FF" stopOpacity="0"/>
<stop offset="1" stopColor="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78170" x1="10.6891" y1="12.5146" x2="10.6891" y2="31.3204" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78170" x1="6.84161" y1="20.0995" x2="3.58247" y2="29.0539" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78170" x1="10.6786" y1="20.8307" x2="6.77037" y2="31.5685" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_78170" x1="14.2454" y1="19.7098" x2="10.1452" y2="30.975" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
</defs>
</svg>

        


     );
}
 
