const Icon_ElectricPower = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M50.7487 30C50.7487 18.5187 41.4262 9.1825 29.9449 9.1825C18.4637 9.1825 9.12744 18.5187 9.12744 30C9.12744 41.4812 18.4637 50.8175 29.9449 50.8175C30.8662 50.8175 31.7599 50.735 32.6399 50.625C31.0174 50.2812 29.5462 49.8137 28.5837 48.2737C19.0962 47.5725 11.6024 39.6525 11.6024 30C11.6024 19.8937 19.8249 11.6575 29.9449 11.6575C40.0512 11.6575 48.2874 19.88 48.2874 30C48.2874 36.5587 44.8224 42.2925 39.6387 45.5375C35.8299 47.7512 31.7187 45.8675 31.7187 42.8562C31.7187 39.845 28.3362 39.8587 28.3362 43.0625C28.3362 44.9737 30.7974 49.0437 35.4724 49.1262C36.9437 49.1537 39.4462 48.59 41.0687 47.5587C46.8712 43.86 50.7487 37.3837 50.7487 30Z" fill="#79C8B0"/>
<path d="M50.7487 30C50.7487 18.5187 41.4262 9.1825 29.9449 9.1825C18.4637 9.1825 9.12744 18.5187 9.12744 30C9.12744 41.4812 18.4637 50.8175 29.9449 50.8175C30.8662 50.8175 31.7599 50.735 32.6399 50.625C31.0174 50.2812 29.5462 49.8137 28.5837 48.2737C19.0962 47.5725 11.6024 39.6525 11.6024 30C11.6024 19.8937 19.8249 11.6575 29.9449 11.6575C40.0512 11.6575 48.2874 19.88 48.2874 30C48.2874 36.5587 44.8224 42.2925 39.6387 45.5375C35.8299 47.7512 31.7187 45.8675 31.7187 42.8562C31.7187 39.845 28.3362 39.8587 28.3362 43.0625C28.3362 44.9737 30.7974 49.0437 35.4724 49.1262C36.9437 49.1537 39.4462 48.59 41.0687 47.5587C46.8712 43.86 50.7487 37.3837 50.7487 30Z" fill="url(#paint0_linear_3293_57600)"/>
<path d="M25.8337 26.3563C24.775 26.3563 23.9912 25.5588 23.9912 24.5138V18.175C23.9912 17.1163 24.7887 16.3325 25.8337 16.3325C26.8925 16.3325 27.6762 17.13 27.6762 18.175V24.5138C27.415 25.8338 26.6312 26.3563 25.8337 26.3563Z" fill="#79C8B0"/>
<path d="M25.8337 26.3563C24.775 26.3563 23.9912 25.5588 23.9912 24.5138V18.175C23.9912 17.1163 24.7887 16.3325 25.8337 16.3325C26.8925 16.3325 27.6762 17.13 27.6762 18.175V24.5138C27.415 25.8338 26.6312 26.3563 25.8337 26.3563Z" fill="url(#paint1_linear_3293_57600)"/>
<path d="M34.8125 26.3563C33.7537 26.3563 32.97 25.5588 32.97 24.5138V18.175C32.97 17.1163 33.7675 16.3325 34.8125 16.3325C35.8712 16.3325 36.655 17.13 36.655 18.175V24.5138C36.6687 25.8338 35.8712 26.3563 34.8125 26.3563Z" fill="#79C8B0"/>
<path d="M34.8125 26.3563C33.7537 26.3563 32.97 25.5588 32.97 24.5138V18.175C32.97 17.1163 33.7675 16.3325 34.8125 16.3325C35.8712 16.3325 36.655 17.13 36.655 18.175V24.5138C36.6687 25.8338 35.8712 26.3563 34.8125 26.3563Z" fill="url(#paint2_linear_3293_57600)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.8149 33.7537C20.8149 39.0475 25.0499 43.2687 30.3299 43.2687C35.6099 43.2687 39.8312 39.0337 39.5837 33.7537V27.9375C39.5837 26.0813 37.9887 24.5 36.1462 24.5H24.2524C22.3962 24.5 20.8149 26.095 20.8149 27.9375V33.7537ZM31.1137 31.9114L33.4925 33.2314C34.0287 33.7539 34.0287 34.2764 34.015 34.8126L28.735 39.3089C28.4737 39.5701 28.2125 39.3089 28.2125 39.0476L29.7937 35.0876L27.1537 33.5064C26.6312 33.2451 26.37 32.4476 26.8925 31.9251L32.1725 27.4289C32.4337 27.1676 32.695 27.4289 32.695 27.6901L31.1137 31.9114Z" fill="#79C8B0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.8149 33.7537C20.8149 39.0475 25.0499 43.2687 30.3299 43.2687C35.6099 43.2687 39.8312 39.0337 39.5837 33.7537V27.9375C39.5837 26.0813 37.9887 24.5 36.1462 24.5H24.2524C22.3962 24.5 20.8149 26.095 20.8149 27.9375V33.7537ZM31.1137 31.9114L33.4925 33.2314C34.0287 33.7539 34.0287 34.2764 34.015 34.8126L28.735 39.3089C28.4737 39.5701 28.2125 39.3089 28.2125 39.0476L29.7937 35.0876L27.1537 33.5064C26.6312 33.2451 26.37 32.4476 26.8925 31.9251L32.1725 27.4289C32.4337 27.1676 32.695 27.4289 32.695 27.6901L31.1137 31.9114Z" fill="url(#paint3_linear_3293_57600)"/>
<defs>
<linearGradient id="paint0_linear_3293_57600" x1="9.62293" y1="26.5304" x2="51.4522" y2="26.9571" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57600" x1="24.0351" y1="20.5091" x2="27.7388" y2="20.523" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57600" x1="33.0138" y1="20.5091" x2="36.7178" y2="20.523" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57600" x1="21.0385" y1="32.3203" x2="39.9114" y2="32.513" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_ElectricPower;