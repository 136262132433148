const Offline_Icon = () => {
    return(
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 15.1501C11.9488 15.1501 15.15 11.9489 15.15 8.0001C15.15 4.05126 11.9488 0.850098 8 0.850098C4.05116 0.850098 0.849998 4.05126 0.849998 8.0001C0.849998 11.9489 4.05116 15.1501 8 15.1501Z" fill="#E5E5E5"/>
<path d="M8.8 8.05L10.95 5.9C11.2 5.65 11.2 5.3 10.95 5.05C10.7 4.8 10.35 4.8 10.1 5.05L7.95 7.2L5.8 5C5.55 4.75 5.2 4.75 4.95 5C4.7 5.25 4.7 5.6 4.95 5.85L7.1 8L4.95 10.15C4.7 10.4 4.7 10.75 4.95 11C5.05 11.1 5.2 11.2 5.4 11.2C5.6 11.2 5.7 11.15 5.85 11L8 8.85L10.15 11C10.25 11.1 10.4 11.2 10.6 11.2C10.8 11.2 10.9 11.15 11.05 11C11.3 10.75 11.3 10.4 11.05 10.15L8.8 8.05Z" fill="white"/>
</svg>

    )
}

export default Offline_Icon;