import axios from '../shared/axios';
import { API_URL } from '../shared/config'; 

//=======================================================
// Company
//=======================================================
export const getAllSiteByCompanyId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/plant/company/${id}/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const getSiteById = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/plant/${id}/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
        };
    }

}
export const addSite= async(data)=>{
    let status = false;
    let response;
    try {
        const response = await axios.post(`${API_URL}/plant/`, data);
        return{
            status: true,
            data: response
        }
        
    }catch (error) {
        if (error.response?.status) {
            response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }
            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
        }
    }
    return {
        status: status,
        data: response,
    }
}
export const editSite = async (id, data) => {
    let status = false;
    let response;
    try {
        const response = await axios.patch(`${API_URL}/plant/${id}/`, data);
        // status = true;
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
        }
    }
    return {
        status: status,
        data: response,
    }
}
//=======================================================
// Device
//=======================================================
export const getDeviceBySiteId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/plant/${id}/device/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const getImageBySiteId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/plant/${id}/albums/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const deleteImageBySiteId= async (id, data) => {
    try {
        const response = await axios.post(`${API_URL}/plant/${id}/albums/remove/`, data);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const uploadImageFileBySiteId = async (id, file,buildingName) => {
    try {
        let body = new FormData();
        body.append("file", file);
        body.append("title", buildingName);
        const header={
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        const response = await axios.post(`${API_URL}/plant/${id}/albums/add/`, body, {'headers':header});
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status,
          };
    }
}
//=======================================================
// BUILDING
//=======================================================
export const getBuildingBySiteId = async (id)=>{
    try {
        const response = await axios.get(`${API_URL}/plant/${id}/building/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status,
        };
    }
}
export const addBuilding = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/building/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const editBuilding = async (id,data) => {
    try {
        const response = await axios.patch(`${API_URL}/building/${id}/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const deleteBuilding = async (id,data) => {
    try {
        const response = await axios.delete(`${API_URL}/building/${id}/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const getImageByBuildingId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/building/${id}/albums/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const deleteImageByBuildingId = async (id, data) => {
    try {
        const response = await axios.post(`${API_URL}/building/${id}/albums/remove/`, data);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const uploadImageFileByBuildingId = async (id, file, buildingName) => {
    try {
        let body = new FormData();
        body.append("file", file);
        body.append("title", buildingName);

        const header={
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          }

        const response = await axios.post(`${API_URL}/building/${id}/albums/add/`, body, {'headers':header});
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status,
          };
    }
}
//=======================================================
// FLOOR
//=======================================================
export const getFloorByBuildingId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/building/${id}/floor/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const addFloor = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/building/floor/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const editFloor = async (id,data) => {
    try {
        const response = await axios.patch(`${API_URL}/building/floor/${id}/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const deleteFloor = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/building/floor/${id}/`);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const getImageByFloorId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/building/floor/${id}/albums/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const deleteImageByFloorId= async (id, data) => {
    try {
        const response = await axios.post(`${API_URL}/building/floor/${id}/albums/remove/`, data);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const uploadImageFileByFloorId = async (id, file,buildingName) => {
    try {
        let body = new FormData();
        body.append("file", file);
        body.append("title", buildingName);
        const header={
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        const response = await axios.post(`${API_URL}/building/floor/${id}/albums/add/`, body, {'headers':header});
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status,
          };
    }
}
//=======================================================
// Place
//=======================================================
export const getPlaceByFloorId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/building/floor/${id}/place/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const addPlace = async (data) => {
    try {
        const response = await axios.post(`${API_URL}/building/place/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const editPlace = async (id,data) => {
    try {
        const response = await axios.patch(`${API_URL}/building/place/${id}/`, data);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const deletePlace = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/building/place/${id}/`);
        return{
            status: true,
            data: response
        }
    } catch (error) {
        if (error.response?.status) {
            let response = 'Api Error.';
            if (error.response.data) {
                try {
                    response = JSON.stringify(error.response.data);
                } catch { }

            } else if (error.response.statusText) {
                response = `${error.response.statusText}.`;
            }
            return {
                status: false,
                data: response,
            }
        }
    }
}
export const getImageByPlaceId = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/building/place/${id}/albums/`);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const deleteImageByPlaceId= async (id, data) => {
    try {
        const response = await axios.post(`${API_URL}/building/place/${id}/albums/remove/`, data);
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status || 500,
          };
    }
}
export const uploadImageFileByPlaceId = async (id, file,buildingName) => {
    try {
        let body = new FormData();
        body.append("file", file);
        body.append("title", buildingName);
        const header={
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          }
        const response = await axios.post(`${API_URL}/building/place/${id}/albums/add/`, body, {'headers':header});
        return {
            status: response?.status === 200,
            data: response?.data
        }
    }
    catch (error) {
        return {
            status: false,
            data: null,
            errorCode: error.response?.status,
          };
    }
}
