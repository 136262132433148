const TemperatureRound_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_3445_62549)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.005 30C6.72944 30 0 23.2728 0 15C0 6.72721 6.72944 0 15.005 0C23.2805 0 30 6.72721 30 15C30 23.2728 23.2607 30 15.005 30ZM15.005 1.78336C7.71061 1.78336 1.77403 7.71797 1.77403 15C1.77403 22.282 7.70069 28.2166 14.995 28.2166C22.2795 28.2166 28.2161 22.2919 28.2161 15C28.2161 7.70806 22.2795 1.78336 15.005 1.78336ZM16.2425 15.9269V7.68018C16.2425 6.23701 15.0475 5 13.5009 5C12.0246 5 10.7592 6.16828 10.7592 7.68018V15.8582C10.7592 16.4767 10.5483 17.1639 10.1265 17.645C9.49386 18.4009 9.07206 19.2943 9.00177 20.3939C8.93147 22.7992 10.9701 24.9296 13.4306 24.9983C15.9613 25.067 18 23.074 18 20.6C18 19.4317 17.5782 18.4009 16.8049 17.645C16.4534 17.0952 16.2425 16.4767 16.2425 15.9269ZM12.6573 7.81763C12.6573 7.40529 13.0088 7.06168 13.4306 7.06168C13.8524 7.06168 14.2039 7.40529 14.2039 7.81763V16.2018C13.9227 16.1331 13.6415 16.1331 13.4306 16.1331C13.2197 16.1331 12.8682 16.1331 12.6573 16.2018V7.81763ZM13.5009 22.5243C12.3058 22.5243 11.3216 21.5621 11.3216 20.3939C11.3216 19.2256 12.3058 18.2635 13.5009 18.2635C14.696 18.2635 15.6801 19.2256 15.6801 20.3939C15.6801 21.5621 14.696 22.5243 13.5009 22.5243ZM20.2414 7C20.6552 7 21 7.45455 21 8C21 8.54545 20.6552 8.90909 20.2414 9H17V7H20.2414ZM20 11C20 10.4545 19.5714 10 19.0571 10H17V12H19.0571C19.5714 12 20 11.5455 20 11ZM17 13H20.2414C20.6552 13 21 13.4545 21 14C21 14.5455 20.6552 15 20.2414 15H17V13Z" fill={stroke} />
</g>
</svg>

    )  
}  

export default TemperatureRound_Icon;