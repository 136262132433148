import { TrashOverviewWidget, AqiWeather, TotalMonthValueWidget } from "../../../components/widgets/widget_layouts/SignageTvWidgets";


export const getSignageWidget = (data) => {
    if (!data) return null;
    switch (data.name) {
      // new widgets
      case "Total Value Widget":
      case "Solar Widget (Total)":
      case "Solar Widget":
      case "Cost Saved Widget":
      case "Carbon Avoid Widget":
      case "Standard Coal Saved Widget":
      case "Potential Rec Earn Widget":
      case "Equivalent Trees Planted Widget":
        return <TotalMonthValueWidget widgetData={data} />;
  
      case "Weather AQI Widget":
        return <AqiWeather widgetData={data} />;

      case "Trash Overview Widget":
        return <TrashOverviewWidget widgetData={data} />;
 
      default:
        return null;
    }
  };