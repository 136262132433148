import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import GradientSVGType1 from "./gradient-svg-type1";
import GradientSVGType2 from "./gradient-svg-type2";
import GradientSVGType3 from "./gradient-svg-type3";
import GradientSVGType4 from "./gradient-svg-type4";

function CircleProgressBarComponent(props) {
    const [typeChart, setTypeChart] = useState('');
    const [percentage, setPercentage] = useState(0);
    const [imageChart, setImageChart] = useState(null);

    useEffect(() => {
        if (props) {
            setTypeChart(`#GradientSVGType${props.type}`);
            setPercentage(props.percentage)
            setImageChart(props.image)
        }
    }, [props])

    return (
        <div className="w-[60px] h-[60px] relative flex justify-center items-center">
            <CircularProgressbar
                value={percentage}
                strokeWidth={10}
                styles={buildStyles({
                    root: {},
                    trailColor: '#F2F8F6',
                    pathColor: `url(${typeChart})`,
                })}
            />
            <div className="w-6 h-6 absolute">
                <img className="w-full h-full" src={imageChart} />
            </div>
            <div className="absolute">
                <GradientSVGType1 />
                <GradientSVGType2 />
                <GradientSVGType3 />
                <GradientSVGType4 />
            </div>
        </div>
    )
}

export default CircleProgressBarComponent;