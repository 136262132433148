import React, { useEffect, useState } from "react";
import CircleProgressBarComponent from "../circle-progressbar";
import GisMapMiniComponent from "../gis-map/mini";
import imgSite from '../../../assets/siteManagement/images/pin-3.svg'
import imgBuilding from '../../../assets/siteManagement/images/building.svg'
import imgFloor from '../../../assets/siteManagement/images/floor.svg'
import imgDevice from '../../../assets/siteManagement/images/device.svg'
import SummarySiteCard from "../summary-site-card.js";

function SiteBoxDetailType1(props) {
    const [typeBox, setTypeBox] = useState(1);
    const [centerMainMap, setCenterMainMap] = useState({
        Latitude: 0,
        Longitude: 0,
        Zoom: 0,
    });
    const [listSite, setListSite] = useState([])
    const [summaryType1, setSummaryType1] = useState({
        Type: 1,
        Name: 'Sites',
        Percentage: 0,
        Count: 0,
    })
    const [summaryType2, setSummaryType2] = useState({
        Type: 2,
        Name: 'Buildings',
        Percentage: 0,
        Count: 0,
    })
    const [summaryType3, setSummaryType3] = useState({
        Type: 3,
        Name: 'Floors',
        Percentage: 0,
        Count: 0,
    })
    const [summaryType4, setSummaryType4] = useState({
        Type: 4,
        Name: 'Devices',
        Percentage: 0,
        Count: 0,
    })

    useEffect(() => {
        if (Array.isArray(props.listSite)) {
            let listX = props.listSite;
            listX = listX.filter(x => x.Visible);
            setListSite(listX);
        }
    }, [props.listSite])
    useEffect(() => {
        if (props.type) {
            setTypeBox(props.type)
        }
    }, [props.type])
    useEffect(() => {
        setCenterMainMap(props.centerMapFocus);
    }, [props.centerMapFocus]);
    useEffect(() => {
        if (props.summary) {
            setSummaryType1({
                ...summaryType1,
                Percentage: props.summary.Site.Percentage,
                Count: props.summary.Site.Count,
            });
            setSummaryType2({
                ...summaryType2,
                Percentage: props.summary.Building.Percentage,
                Count: props.summary.Building.Count,
            });
            setSummaryType3({
                ...summaryType3,
                Percentage: props.summary.Floor.Percentage,
                Count: props.summary.Floor.Count,
            });
            setSummaryType4({
                ...summaryType4,
                Percentage: props.summary.Device.Percentage,
                Count: props.summary.Device.Count,
            });
        }
    }, [props.summary])

    function onSetClassMainByType() {
        let classUse = '';
        if (typeBox === 1 || typeBox === 3) {
            classUse = 'w-full h-full rounded-lg shadow-content-box bg-white overflow-hidden';
        } else if (typeBox === 2) {
            classUse = 'w-full h-full';
        }
        return classUse
    }
    function onSetClassMapByType() {
        let classUse = '';
        if (typeBox === 1 || typeBox === 3) {
            classUse = 'w-full';
        } else if (typeBox === 2) {
            classUse = 'w-full rounded-lg shadow-content-box bg-white overflow-hidden mb-2';
        }
        return classUse
    }
    function onSetHeightMapByType() {
        let height = '';
        if (typeBox === 1) {
            height = '100%';
        } else if (typeBox === 2) {
            height = 'calc(100% - 232px)';
        } else if (typeBox === 3) {
            height = 'calc(100% - 168px)';
        }
        return height
    }
    function onSetClassChartByType() {
        let classUse = '';
        if (typeBox === 2) {
            classUse = 'w-full h-[224px] rounded-lg shadow-content-box bg-white overflow-hidden';
        } else if (typeBox === 3) {
            classUse = 'w-full h-[168px]';
        }
        return classUse
    }

    return (
        <div className={`${onSetClassMainByType()}`}>
            <div className={`${onSetClassMapByType()} relative`}
                style={{ height: `${onSetHeightMapByType()}` }}>
                <GisMapMiniComponent
                    listSite={listSite}
                    centerMapFocus={centerMainMap} />
                <div className="w-full h-full z-[1000] bg-vekin-green-50 opacity-10 absolute top-[0px]">
                </div>
            </div>
            {typeBox !== 1 &&
                <div className={`${onSetClassChartByType()}`}>
                    {typeBox === 2 &&
                        <div className="w-full h-[36px] px-2 pt-2">
                            <p className="text-black text-sm font-bold">Site Overview</p>
                        </div>
                    }
                    <div className="w-full p-2"
                        style={{ height: `${typeBox === 2 ? 'calc(100% - 36px)' : '100%'}` }}>
                        <div className="w-full h-2/4 flex">
                            <div className="w-2/4 h-full pr-1 flex items-center">
                                <SummarySiteCard
                                    Type={summaryType1.Type}
                                    Percentage={summaryType1.Percentage}
                                    Image={imgSite}
                                    Count={summaryType1.Count}
                                    Text={summaryType1.Name}
                                />
                            </div>
                            <div className="w-2/4 h-full pl-1 flex items-center">
                                <SummarySiteCard
                                    Type={summaryType2.Type}
                                    Percentage={summaryType2.Percentage}
                                    Image={imgBuilding}
                                    Count={summaryType2.Count}
                                    Text={summaryType2.Name}
                                />
                            </div>
                        </div>
                        <div className="w-full h-2/4 flex">
                            <div className="w-2/4 h-full pr-1 flex items-center">
                                <SummarySiteCard
                                    Type={summaryType3.Type}
                                    Percentage={summaryType3.Percentage}
                                    Image={imgFloor}
                                    Count={summaryType3.Count}
                                    Text={summaryType3.Name}
                                />
                            </div>
                            <div className="w-2/4 h-full pl-1 flex items-center">
                                <SummarySiteCard
                                    Type={summaryType4.Type}
                                    Percentage={summaryType4.Percentage}
                                    Image={imgDevice}
                                    Count={summaryType4.Count}
                                    Text={summaryType4.Name}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default SiteBoxDetailType1