const Icon_Battery2 = ({ style,stroke }) => {
  return (
<svg className={style} width="150" height="155" viewBox="0 0 150 155" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M111 123H37C35 123 33 121.5 33 118.5V60H115V118.5C115 121.5 113 123 111 123Z" fill={stroke?stroke:"url(#paint0_linear_613_10187)"}/>
<path d="M117.5 17.5H92.5V14C92.5 9 88.5 5 83.5 5H65.5C60.5 5 56.5 9 56.5 14V17.5H30.5C18 17.5 8 27.5 8 40V124.5C8 137 18 147 30.5 147H117.5C130 147 140 137 140 124.5V40C140 27.5 130 17.5 117.5 17.5ZM125 124.5C125 128.5 121.5 132 117.5 132H30.5C26.5 132 23 128.5 23 124.5V40C23 36 26.5 32.5 30.5 32.5H117.5C121.5 32.5 125 36 125 40V124.5Z" fill={stroke?stroke:"url(#paint1_linear_613_10187)"}/>
<path d="M85.5 90.5H77V82C77 81 76 80 75 80H72C71 80 70 81 70 82V90.5H61.5C60.5 90.5 59.5 91.5 59.5 92.5V95.5C59.5 96.5 60.5 97.5 61.5 97.5H70V106C70 107 71 108 72 108H75C76 108 77 107 77 106V97.5H85.5C86.5 97.5 87.5 96.5 87.5 95.5V92.5C87.5 91.5 87 90.5 85.5 90.5Z" fill={stroke?stroke:"white"}/>
<defs>
<linearGradient id="paint0_linear_613_10187" x1="139.752" y1="156.051" x2="3.77949" y2="20.0775" gradientUnits="userSpaceOnUse">
<stop stop-color={stroke?stroke:"#009377"}/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint1_linear_613_10187" x1="142.374" y1="150.624" x2="13.1295" y2="21.379" gradientUnits="userSpaceOnUse">
<stop stop-color={stroke?stroke:"#009377"}/>
<stop offset="1" stop-color={stroke?stroke:"#3AD296"}/>
</linearGradient>
</defs>
</svg>

  )
}

export default Icon_Battery2
