import axios from '../../shared/axios';
import useSWR from 'swr'
import { API_URL } from '../../shared/config'; 


const fetcher = url => axios.get(url)

export const useGetSignageDashboardByID =(id) =>{
    const { data, error } = useSWR(`${API_URL}/widget/signage/${id}/`, fetcher)
    return {
      dashboardData: data?.data,
      isLoadingData: !error && !data?.data,
      isErrorData: error
    }
}

export const useTrashOverviewWidget = (url) => {
  const { data, error } = useSWR(`${API_URL}${url}`, fetcher);
  return {
    trashOverview: data?.data,
    isLoadingWidget: !error && !data?.data,
    isErrorWidget: error,
  };
};
