const ElectricPower_Icon = ({style, stroke}) => {

    return (
        <svg className={style}  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.04 5.21998C31.04 2.94998 29.19 1.09998 26.92 1.09998H5.37C3.1 1.09998 1.25 2.94998 1.25 5.21998V26.78C1.25 29.05 3.1 30.9 5.37 30.9H15.84C14.45 30.79 13.63 29.39 13.63 28.61H5.37C4.36 28.61 3.54 27.79 3.54 26.78V5.21998C3.54 4.20998 4.36 3.38998 5.37 3.38998H26.93C27.94 3.38998 28.76 4.20998 28.76 5.21998V12.9V27.45C28.76 28.14 28.19 28.6 27.61 28.6H18.82C17.9 28.6 17.22 27.91 17.22 27V23.68C17.22 22.99 16.76 22.53 16.07 22.53C15.38 22.53 14.92 22.99 14.92 23.68V27C14.92 29.18 16.64 30.9 18.82 30.9H27.61C29.56 30.9 31.05 29.41 31.05 27.58V26.79V5.21998H31.04Z" fill={stroke}/>
<path d="M31.04 5.21998C31.04 2.94998 29.19 1.09998 26.92 1.09998H5.37C3.1 1.09998 1.25 2.94998 1.25 5.21998V26.78C1.25 29.05 3.1 30.9 5.37 30.9H15.84C14.45 30.79 13.63 29.39 13.63 28.61H5.37C4.36 28.61 3.54 27.79 3.54 26.78V5.21998C3.54 4.20998 4.36 3.38998 5.37 3.38998H26.93C27.94 3.38998 28.76 4.20998 28.76 5.21998V12.9V27.45C28.76 28.14 28.19 28.6 27.61 28.6H18.82C17.9 28.6 17.22 27.91 17.22 27V23.68C17.22 22.99 16.76 22.53 16.07 22.53C15.38 22.53 14.92 22.99 14.92 23.68V27C14.92 29.18 16.64 30.9 18.82 30.9H27.61C29.56 30.9 31.05 29.41 31.05 27.58V26.79V5.21998H31.04Z" fill={stroke}/>
<path d="M12.97 13.3501C12.2 13.3501 11.63 12.7701 11.63 12.0101V7.40006C11.63 6.63006 12.21 6.06006 12.97 6.06006C13.74 6.06006 14.31 6.64006 14.31 7.40006V12.0101C14.12 12.9701 13.55 13.3501 12.97 13.3501Z" fill={stroke}/>
<path d="M12.97 13.3501C12.2 13.3501 11.63 12.7701 11.63 12.0101V7.40006C11.63 6.63006 12.21 6.06006 12.97 6.06006C13.74 6.06006 14.31 6.64006 14.31 7.40006V12.0101C14.12 12.9701 13.55 13.3501 12.97 13.3501Z" fill={stroke}/>
<path d="M19.4999 13.3501C18.7299 13.3501 18.1599 12.7701 18.1599 12.0101V7.40006C18.1599 6.63006 18.7399 6.06006 19.4999 6.06006C20.2699 6.06006 20.8399 6.64006 20.8399 7.40006V12.0101C20.8499 12.9701 20.2699 13.3501 19.4999 13.3501Z" fill={stroke}/>
<path d="M19.4999 13.3501C18.7299 13.3501 18.1599 12.7701 18.1599 12.0101V7.40006C18.1599 6.63006 18.7399 6.06006 19.4999 6.06006C20.2699 6.06006 20.8399 6.64006 20.8399 7.40006V12.0101C20.8499 12.9701 20.2699 13.3501 19.4999 13.3501Z" fill={stroke}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.31995 18.73C9.31995 22.58 12.3999 25.65 16.2399 25.65C20.0799 25.65 23.1499 22.57 22.9699 18.73V14.5C22.9699 13.15 21.8099 12 20.4699 12H11.8199C10.4699 12 9.31995 13.16 9.31995 14.5V18.73ZM16.81 17.3901L18.54 18.3501C18.93 18.7301 18.93 19.1101 18.92 19.5001L15.08 22.7701C14.89 22.9601 14.7 22.7701 14.7 22.5801L15.85 19.7001L13.93 18.5501C13.55 18.3601 13.36 17.7801 13.74 17.4001L17.58 14.1301C17.77 13.9401 17.96 14.1301 17.96 14.3201L16.81 17.3901Z" fill={stroke}/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.31995 18.73C9.31995 22.58 12.3999 25.65 16.2399 25.65C20.0799 25.65 23.1499 22.57 22.9699 18.73V14.5C22.9699 13.15 21.8099 12 20.4699 12H11.8199C10.4699 12 9.31995 13.16 9.31995 14.5V18.73ZM16.81 17.3901L18.54 18.3501C18.93 18.7301 18.93 19.1101 18.92 19.5001L15.08 22.7701C14.89 22.9601 14.7 22.7701 14.7 22.5801L15.85 19.7001L13.93 18.5501C13.55 18.3601 13.36 17.7801 13.74 17.4001L17.58 14.1301C17.77 13.9401 17.96 14.1301 17.96 14.3201L16.81 17.3901Z" fill={stroke}/>

</svg>
    )
}

export default ElectricPower_Icon;
