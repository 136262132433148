import React, { useState,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import bg_image from "../../assets/backgroundImages/company_bg.jpg";
import { requestResetPassword,resetPassword } from "../../api/userInfo"

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isConfirm, setIsConfirm] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false)
  const [form, setForm] = useState({
    email: undefined,
    is_accepted_active_consent: undefined});

  const onSubmit= async(e)=>{
    e.preventDefault()
    const { data, error } = await requestResetPassword({ email: form.email})
    if(data?.detail === "Success."){
      setErrorMsg(false)
      openModal()
    }else if(data === undefined){
      setErrorMsg(data?.detail || "This email not verify.")
    }else{
      setErrorMsg(data?.detail)
    }
  }

  useEffect(() => {
    if(errorMsg){
      let error_msg = setTimeout(() => setErrorMsg(undefined), 1000);
      return () => {
        clearTimeout(error_msg);
      };
    }
  }, [ errorMsg]);

  const closeModal = () => {
    setIsConfirm(false);
  };
  const openModal = () => {
    setIsConfirm(true);
  };


  return (
    <div
      className="relative flex items-center justify-center w-full h-screen bg-no-repeat bg-cover bg-image"
      style={{ backgroundImage: `url(${bg_image})` }}
    >
      <section
        className={`absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden ${ isConfirm ? "hidden" : "block" }`} >
        <form onSubmit={onSubmit}>
        <div className="max-w-sm p-4 mx-auto text-center bg-white rounded h-50 md:max-w-lg">
          <h1 className="text-3xl font-bold text-center text-vekin">
            Forgot password
          </h1>
          <p className="px-4 pt-4 text-sm">
            Enter your email and we'll send you a link to reset your password.
          </p>

          {errorMsg != '' && <span className="text-sm text-danger">{errorMsg}</span>}


          <div className="flex flex-row items-start mx-20 mt-4">
            <label className="pt-4 mr-2 text-sm text-gray-500">Email</label>
            <input
              type="email"
              onChange={e => {
                setForm({
                  email: e.target.value
                })
              }}
              value={form.email}
              className="w-full p-2 mt-2 text-sm text-gray-700 border border-gray-300 rounded-md outline-none"
              placeholder="john@example.com"
            />
          </div>

          <div className="flex flex-row items-center justify-center gap-8 mt-8">
            <button
                type="button"
                onClick={()=> navigate('/login')}
                className="inline-flex gap-2 px-4 py-1 mt-0 text-sm">
                <i className="ri-arrow-left-line"></i>
                Back to Login
            </button>
            <button
              // onClick={() => onSubmit()}
              // onClick={() => openModal()}
              type='submit'
              className="block w-1/3 py-2 text-white rounded bg-vekin focus:outline-none"
            >
              Continue
            </button>
          </div>
        </div>
        </form>
      </section>

      <section  className={`absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden ${ !isConfirm ? "hidden" : "block" }`} >
        <div className="max-w-sm p-4 mx-auto text-center bg-white rounded h-50 md:max-w-lg">
          <div className="relative w-full h-full max-w-md md:h-auto">
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              <button
                onClick={() => closeModal()}
                type="button"
                className="absolute top-3 right-0 -top-8 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                data-modal-hide="popup-modal"
              >
                <i className="text-xl ri-close-fill"></i>
                <span className="sr-only">Close modal</span>
              </button>

              <h1 className="mt-4 text-3xl font-bold text-center text-vekin">Check your email</h1>
              <div className="p-6 mt-4 text-center">
                <p className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                  Please check your email. We'll send you a link to reset your password.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ForgotPassword;
