const Grid_3d_Icon = ({ style }) => {
    return (
        <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.08" d="M256.5 371.6L181.5 415.7L44.4 401.6L181.5 329.2L256.5 371.6Z" fill="black"/>
<path d="M181.5 339L144 317.8L142.4 316.2L153.8 50.3002V48.7002L179.9 14.4002C179.9 12.8002 183.2 12.8002 183.2 14.4002L209.3 48.7002C209.3 48.7002 209.3 48.7002 209.3 50.3002L219.1 316.2C219.1 316.2 219.1 317.8 217.5 317.8L181.5 339C183.1 339 183.1 339 181.5 339ZM147.2 316.1L181.5 335.7L215.8 316.1L206 50.2002L181.5 17.6002L157 50.2002L147.2 316.1Z" fill="url(#paint0_linear_5914_87592)"/>
<path d="M181.5 339C179.9 339 179.9 337.4 179.9 337.4V64.9002L155.4 51.8002C153.8 51.8002 153.8 50.2002 153.8 48.5002C153.8 46.9002 155.4 46.9002 157.1 46.9002L179.8 60.0002V14.3002C179.8 12.7002 179.8 12.7002 181.4 12.7002C183 12.7002 183 12.7002 183 12.7002L209.1 47.0002V48.6002C209.1 48.6002 209.1 50.2002 207.5 50.2002L184.7 64.9002V337.4C184.7 339 183.1 339 181.5 339ZM184.7 20.8002V60.0002L205.9 48.6002L184.7 20.8002Z" fill="url(#paint1_linear_5914_87592)"/>
<path d="M122.7 373.2C121.1 373.2 119.4 371.6 119.4 371.6L142.2 316.1C142.2 316.1 142.2 316.1 143.8 314.5L179.7 293.3H181.3L217.2 314.5C217.2 314.5 217.2 314.5 218.8 316.1L241.6 371.6C241.6 373.2 241.6 373.2 240 374.9C238.4 374.9 238.4 374.9 236.7 373.3L213.9 317.8L179.6 298.2L145.3 317.8L122.5 371.6C124.4 373.2 122.7 373.2 122.7 373.2Z" fill="url(#paint2_linear_5914_87592)"/>
<path d="M207.6 51.8002L181.5 35.5002L157 51.8002C155.4 51.8002 155.4 51.8002 153.7 51.8002C153.7 50.2002 153.7 50.2002 153.7 48.5002L179.8 32.2002H181.4L207.5 48.5002C209.2 48.6002 209.2 50.2002 207.6 51.8002C209.2 51.8002 209.2 51.8002 207.6 51.8002Z" fill="url(#paint3_linear_5914_87592)"/>
<path d="M181.5 409.1L121.1 374.8L119.5 373.2C119.5 373.2 119.5 371.6 121.1 371.6L181.5 337.3H183.1L243.5 371.6L245.1 373.2C245.1 373.2 245.1 374.8 243.5 374.8L181.5 409.1ZM126 371.6L181.5 404.2L237 371.6L181.5 339L126 371.6Z" fill="url(#paint4_linear_5914_87592)"/>
<path d="M181.5 296.5L147.2 277L145.6 275.4C145.6 275.4 145.6 273.8 147.2 273.8L181.5 254.2H183.1L217.4 273.8L219 275.4C219 275.4 219 277 217.4 277L181.5 296.5ZM152.1 275.3L181.5 293.2L210.9 275.3L181.5 257.4L152.1 275.3Z" fill="url(#paint5_linear_5914_87592)"/>
<path d="M181.5 259L147.2 239.4L145.6 237.8C145.6 237.8 145.6 236.2 147.2 236.2L179.8 216.6H181.4L214 236.2L215.6 237.8C215.6 237.8 215.6 239.4 214 239.4L181.5 259ZM153.7 237.8L181.4 254.1L209.1 237.8L181.4 221.5L153.7 237.8Z" fill="url(#paint6_linear_5914_87592)"/>
<path d="M181.5 221.5L148.9 203.6L147.3 202C147.3 202 147.3 200.4 148.9 200.4L179.9 182.5H181.5L212.5 200.4L214.1 202C214.1 202 214.1 203.6 212.5 203.6L181.5 221.5C183.1 221.5 183.1 221.5 181.5 221.5ZM155.4 201.9L183.1 218.2L210.8 201.9L183.1 185.6L155.4 201.9Z" fill="url(#paint7_linear_5914_87592)"/>
<path d="M181.5 184L150.5 166.1L148.9 164.5C148.9 164.5 148.9 162.9 150.5 162.9L179.9 145H181.5L210.9 162.9L212.5 164.5C212.5 164.5 212.5 166.1 210.9 166.1L181.5 184C183.1 184 183.1 184 181.5 184ZM157 164.4L183.1 179.1L209.2 164.4L183.1 149.7L157 164.4Z" fill="url(#paint8_linear_5914_87592)"/>
<path d="M181.5 409.1C179.9 409.1 179.9 407.5 179.9 407.5V339C179.9 337.4 181.5 337.4 181.5 337.4C183.1 337.4 183.1 339 183.1 339V407.5C184.7 407.5 183.1 409.1 181.5 409.1Z" fill="url(#paint9_linear_5914_87592)"/>
<path d="M54.2 156.2C54.2 156.2 52.6 156.2 52.6 154.6C52.6 153 52.6 153 52.6 151.3L153.8 77.9001H155.4L181.5 92.6001L183.1 94.2001C183.1 94.2001 183.1 95.8001 181.5 95.8001L54.2 156.2ZM155.4 81.2001L72.2 143.2L176.6 94.3001L155.4 81.2001Z" fill="url(#paint10_linear_5914_87592)"/>
<path d="M155.4 81.2001C155.4 81.2001 153.8 81.2001 153.8 79.6001C153.8 78.0001 153.8 78.0001 153.8 76.3001L181.5 61.6001C183.1 61.6001 183.1 61.6001 184.8 61.6001C184.8 63.2001 184.8 63.2001 184.8 64.9001L155.4 81.2001Z" fill="url(#paint11_linear_5914_87592)"/>
<path d="M209.2 81.2001L181.5 66.5001L179.9 64.9001C179.9 64.9001 179.9 63.3001 181.5 63.3001L305.5 4.6001C307.1 4.6001 307.1 4.6001 308.8 6.2001C308.8 7.8001 308.8 7.8001 308.8 9.5001L209.2 81.2001ZM188 63.2001L209.2 76.3001L290.8 15.9001L188 63.2001Z" fill="url(#paint12_linear_5914_87592)"/>
<path d="M181.5 95.9C181.5 95.9 179.9 95.9 179.9 94.3C179.9 92.7 179.9 92.7 179.9 91L207.6 76.3C209.2 76.3 209.2 76.3 210.9 76.3C210.9 77.9 210.9 77.9 210.9 79.6L181.5 95.9Z" fill="url(#paint13_linear_5914_87592)"/>
<path d="M47.7 210.1C47.7 210.1 46.1 210.1 46.1 208.5C46.1 206.9 46.1 206.9 46.1 205.2L152.2 128.5H153.8L181.5 144.8L183.1 146.4C183.1 146.4 183.1 148 181.5 148L47.7 210.1ZM153.7 131.8L65.6 197L178.2 144.8L153.7 131.8Z" fill="url(#paint14_linear_5914_87592)"/>
<path d="M153.7 131.8C153.7 131.8 152.1 131.8 152.1 130.2C152.1 128.6 152.1 128.6 152.1 126.9L181.5 110.6C183.1 110.6 183.1 110.6 184.8 110.6C184.8 112.2 184.8 112.2 184.8 113.9L153.7 131.8Z" fill="url(#paint15_linear_5914_87592)"/>
<path d="M210.8 131.8L181.4 115.5L179.8 113.9C179.8 113.9 179.8 112.3 181.4 112.3L312 51.8C313.6 51.8 313.6 51.8001 315.3 53.4001C315.3 55.0001 315.3 55.0001 315.3 56.7001L210.8 131.8ZM188 113.8L210.8 126.9L297.3 61.6L188 113.8Z" fill="url(#paint16_linear_5914_87592)"/>
<path d="M181.5 148.1C181.5 148.1 179.9 148.1 179.9 146.5C179.9 144.9 179.9 144.9 179.9 143.2L209.3 126.9C210.9 126.9 210.9 126.9 212.6 126.9C212.6 128.5 212.6 128.5 212.6 130.2L181.5 148.1Z" fill="url(#paint17_linear_5914_87592)"/>
<path d="M57.5 164.4C55.9 164.4 55.9 162.8 55.9 162.8V153C55.9 151.4 57.5 151.4 57.5 151.4C59.1 151.4 59.1 153 59.1 153V162.8C60.7 162.8 59.1 164.4 57.5 164.4Z" fill="url(#paint18_linear_5914_87592)"/>
<path d="M303.8 19.2002C302.2 19.2002 302.2 17.6002 302.2 17.6002V7.8002C302.2 6.2002 303.8 6.2002 303.8 6.2002C305.4 6.2002 305.4 7.8002 305.4 7.8002V17.6002C305.5 17.6002 305.5 19.2002 303.8 19.2002Z" fill="url(#paint19_linear_5914_87592)"/>
<path d="M50.9001 216.6C49.3001 216.6 49.3 215 49.3 215V205.2C49.3 203.6 50.9001 203.6 50.9001 203.6C52.5001 203.6 52.5 205.2 52.5 205.2V215C54.2 215 52.6001 216.6 50.9001 216.6Z" fill="url(#paint20_linear_5914_87592)"/>
<path d="M310.4 64.9001C308.8 64.9001 308.8 63.3001 308.8 63.3001V53.5001C308.8 51.9001 310.4 51.9001 310.4 51.9001C312 51.9001 312 53.5001 312 53.5001V63.3001C313.6 64.9001 312 64.9001 310.4 64.9001Z" fill="url(#paint21_linear_5914_87592)"/>
<path opacity="0.08" d="M395.2 451.5L320.2 495.6L184.7 481.5L320.2 409.1L395.2 451.5Z" fill="url(#paint22_linear_5914_87592)"/>
<path d="M320.2 420.5L282.7 399.3L281.1 397.7L292.5 130.1V128.5L320.2 94.2C320.2 92.6 323.5 92.6 323.5 94.2L349.6 128.5C349.6 128.5 349.6 128.5 349.6 130.1L359.4 396C359.4 396 359.4 397.6 357.8 397.6L320.2 420.5C321.8 420.5 321.8 418.9 320.2 420.5ZM287.5 396.1L321.8 415.7L356.1 396.1L344.7 130.2L320.2 97.6L295.7 130.2L287.5 396.1Z" fill="url(#paint23_linear_5914_87592)"/>
<path d="M320.2 420.5C318.6 420.5 318.6 418.9 318.6 418.9V144.8L294.1 131.7C292.5 131.7 292.5 130.1 292.5 128.4C292.5 126.7 294.1 126.8 295.8 126.8L318.6 139.9V94.2001C318.6 92.6001 318.6 92.6001 320.2 92.6001C321.8 92.6001 321.8 92.6001 321.8 92.6001L347.9 126.9V128.5C347.9 128.5 347.9 130.1 346.3 130.1L321.8 143.2V415.7C323.4 418.9 321.8 420.5 320.2 420.5ZM323.4 102.4V141.6L344.6 130.2L323.4 102.4Z" fill="url(#paint24_linear_5914_87592)"/>
<path d="M261.4 454.8C259.8 454.8 259.8 453.2 259.8 451.5L282.6 396C282.6 396 282.6 396 284.2 394.4L320.1 373.2H321.7L357.6 394.4C357.6 394.4 357.6 394.4 359.2 396L382 451.5C382 453.1 382 453.1 380.4 454.8C378.8 454.8 378.8 454.8 377.1 453.2L354.3 397.7L320 378.1L285.7 397.7L262.9 453.2L261.4 454.8Z" fill="url(#paint25_linear_5914_87592)"/>
<path d="M347.9 131.8C346.3 131.8 346.3 131.8 347.9 131.8L321.8 115.5L297.3 131.8C295.7 131.8 295.7 131.8 294 131.8C294 130.2 294 130.2 294 128.5L320.1 112.2H321.7L347.8 128.5C349.5 128.5 349.5 130.1 347.9 131.8Z" fill="url(#paint26_linear_5914_87592)"/>
<path d="M320.2 489.1L259.8 453.2L258.2 451.6C258.2 451.6 258.2 450 259.8 450L320.2 415.7H321.8L382.2 450L383.8 451.6C383.8 451.6 383.8 453.2 382.2 453.2L320.2 489.1C321.8 489.1 321.8 489.1 320.2 489.1ZM264.7 451.5L320.2 484.1L375.7 451.5L320.2 418.9L264.7 451.5Z" fill="url(#paint27_linear_5914_87592)"/>
<path d="M320.2 376.5L285.9 356.9L284.3 355.3C284.3 355.3 284.3 353.7 285.9 353.7L320.2 334.1H321.8L356.1 353.7L357.7 355.3C357.7 355.3 357.7 356.9 356.1 356.9L320.2 376.5C321.8 376.5 321.8 376.5 320.2 376.5ZM290.8 355.3L320.2 373.2L349.6 355.3L320.2 339L290.8 355.3Z" fill="url(#paint28_linear_5914_87592)"/>
<path d="M320.2 339L285.9 319.4L284.3 317.8C284.3 317.8 284.3 316.2 285.9 316.2L318.5 296.6H320.1L352.7 316.2L354.3 317.8C354.3 317.8 354.3 319.4 352.7 319.4L320.2 339C321.8 339 321.8 339 320.2 339ZM292.4 317.8L320.1 334.1L347.8 317.8L320.1 301.5L292.4 317.8Z" fill="url(#paint29_linear_5914_87592)"/>
<path d="M321.8 303.1C320.2 303.1 320.2 303.1 321.8 303.1L289.2 285.2L287.6 283.6C287.6 283.6 287.6 282 289.2 282L320.2 264.1H321.8L352.8 282L354.4 283.6C354.4 283.6 354.4 285.2 352.8 285.2L321.8 303.1ZM294.1 281.9L321.8 298.2L349.5 281.9L321.8 265.6L294.1 281.9Z" fill="url(#paint30_linear_5914_87592)"/>
<path d="M321.8 265.5C320.2 265.5 320.2 265.5 321.8 265.5L290.8 247.6L289.2 246C289.2 246 289.2 244.4 290.8 244.4L320.2 226.5H321.8L351.2 244.4L352.8 246C352.8 246 352.8 247.6 351.2 247.6L321.8 265.5ZM295.7 246L321.8 260.7L347.9 246L321.8 231.3L295.7 246Z" fill="url(#paint31_linear_5914_87592)"/>
<path d="M320.2 489.1C318.6 489.1 318.6 487.5 318.6 487.5V419C318.6 417.4 320.2 417.4 320.2 417.4C321.8 417.4 321.8 419 321.8 419V485.9C323.4 487.4 321.8 489.1 320.2 489.1Z" fill="url(#paint32_linear_5914_87592)"/>
<path d="M192.9 236.2C192.9 236.2 191.3 236.2 191.3 234.6C191.3 233 191.3 233 191.3 231.3L292.5 156.2H294.1L320.2 170.9L321.8 172.5C321.8 172.5 321.8 174.1 320.2 174.1L192.9 236.2ZM294.1 162.8L210.9 224.8L315.3 175.9L294.1 162.8Z" fill="url(#paint33_linear_5914_87592)"/>
<path d="M294.1 161.1C294.1 161.1 292.5 161.1 292.5 159.5C292.5 157.9 292.5 157.9 292.5 156.2L318.6 141.5C320.2 141.5 320.2 141.5 321.9 141.5C321.9 143.1 321.9 143.1 321.9 144.8L294.1 161.1Z" fill="url(#paint34_linear_5914_87592)"/>
<path d="M347.9 161.1L320.2 146.4L318.6 144.8C318.6 144.8 318.6 143.2 320.2 143.2L445.8 84.5C447.4 84.5 447.4 84.5 449.1 86.1C449.1 87.7 449.1 87.7 449.1 89.4L347.9 161.1ZM326.7 144.8L347.9 156.2L429.5 95.8L326.7 144.8Z" fill="url(#paint35_linear_5914_87592)"/>
<path d="M320.2 177.4C320.2 177.4 318.6 177.4 318.6 175.8C318.6 174.2 318.6 174.2 318.6 172.5L346.3 157.8C347.9 157.8 347.9 157.8 349.6 157.8C349.6 159.4 349.6 159.4 349.6 161.1L320.2 177.4C321.8 177.4 321.8 177.4 320.2 177.4Z" fill="url(#paint36_linear_5914_87592)"/>
<path d="M186.4 290C186.4 290 184.8 290 184.8 288.4C184.8 286.8 184.8 286.8 184.8 285.1L290.9 206.8H292.5L320.2 223.1L321.8 224.7C321.8 224.7 321.8 226.3 320.2 226.3L186.4 290ZM292.4 213.3L204.3 278.6L316.9 226.4L292.4 213.3Z" fill="url(#paint37_linear_5914_87592)"/>
<path d="M292.4 211.7C292.4 211.7 290.8 211.7 290.8 210.1C290.8 208.5 290.8 208.5 290.8 206.8L320.2 190.5C321.8 190.5 321.8 190.5 323.5 190.5C323.5 192.1 323.5 192.1 323.5 193.8L292.4 211.7Z" fill="url(#paint38_linear_5914_87592)"/>
<path d="M349.5 211.7L320.1 195.4L318.5 193.8C318.5 193.8 318.5 192.2 320.1 192.2L450.6 131.8C452.2 131.8 452.2 131.8 453.9 133.4C453.9 135 453.9 135 453.9 136.7L349.5 211.7C351.2 211.7 351.2 211.7 349.5 211.7ZM326.7 193.8L349.5 206.9L436 143.3L326.7 193.8Z" fill="url(#paint39_linear_5914_87592)"/>
<path d="M320.2 228C320.2 228 318.6 228 318.6 226.4C318.6 224.8 318.6 224.8 318.6 223.1L348 206.8C349.6 206.8 349.6 206.8 351.3 206.8C351.3 208.4 351.3 208.4 351.3 210.1L320.2 228C321.8 228 321.8 228 320.2 228Z" fill="url(#paint40_linear_5914_87592)"/>
<path d="M197.8 244.3C196.2 244.3 196.2 242.7 196.2 242.7V232.9C196.2 231.3 197.8 231.3 197.8 231.3C199.4 231.3 199.4 232.9 199.4 232.9V242.7L197.8 244.3Z" fill="url(#paint41_linear_5914_87592)"/>
<path d="M442.5 99.1001C440.9 99.1001 440.9 97.5001 440.9 97.5001V87.7001C440.9 86.1001 442.5 86.1001 442.5 86.1001C444.1 86.1001 444.1 87.7001 444.1 87.7001V97.5001C445.8 99.1001 444.2 99.1001 442.5 99.1001Z" fill="url(#paint42_linear_5914_87592)"/>
<path d="M191.3 296.5C189.7 296.5 189.7 294.9 189.7 294.9V285.1C189.7 283.5 191.3 283.5 191.3 283.5C192.9 283.5 192.9 285.1 192.9 285.1V294.9C192.9 296.5 191.3 296.5 191.3 296.5Z" fill="url(#paint43_linear_5914_87592)"/>
<path d="M449.1 146.4C447.5 146.4 447.5 144.8 447.5 144.8V133.4C447.5 131.8 449.1 131.8 449.1 131.8C450.7 131.8 450.7 133.4 450.7 133.4V143.2C452.3 144.8 450.7 146.4 449.1 146.4Z" fill="url(#paint44_linear_5914_87592)"/>
<defs>
<linearGradient id="paint0_linear_5914_87592" x1="76.0266" y1="50.1556" x2="361.02" y2="427.821" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint1_linear_5914_87592" x1="83.1252" y1="44.7981" x2="368.118" y2="422.464" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint2_linear_5914_87592" x1="-5.80348" y1="111.905" x2="279.19" y2="489.57" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint3_linear_5914_87592" x1="142.913" y1="-0.318597" x2="427.906" y2="377.347" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint4_linear_5914_87592" x1="-11.6073" y1="116.285" x2="273.386" y2="493.95" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint5_linear_5914_87592" x1="35.4613" y1="80.7654" x2="320.454" y2="458.431" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint6_linear_5914_87592" x1="52.4653" y1="67.9347" x2="337.458" y2="445.6" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint7_linear_5914_87592" x1="69.7239" y1="54.9109" x2="354.717" y2="432.577" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint8_linear_5914_87592" x1="87.7668" y1="41.295" x2="372.76" y2="418.961" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint9_linear_5914_87592" x1="-12.1278" y1="116.677" x2="272.865" y2="494.343" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint10_linear_5914_87592" x1="66.8162" y1="57.1043" x2="351.809" y2="434.77" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint11_linear_5914_87592" x1="125.451" y1="12.858" x2="410.444" y2="390.524" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint12_linear_5914_87592" x1="190.285" y1="-36.066" x2="475.278" y2="341.6" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint13_linear_5914_87592" x1="135.024" y1="5.63396" x2="420.017" y2="383.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint14_linear_5914_87592" x1="39.2417" y1="77.9131" x2="324.235" y2="455.579" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint15_linear_5914_87592" x1="101.004" y1="31.3056" x2="385.997" y2="408.971" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint16_linear_5914_87592" x1="169.221" y1="-20.1721" x2="454.214" y2="357.493" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint17_linear_5914_87592" x1="110.832" y1="23.8891" x2="395.825" y2="401.555" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint18_linear_5914_87592" x1="12.4169" y1="98.1559" x2="297.41" y2="475.821" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint19_linear_5914_87592" x1="239.212" y1="-72.988" x2="524.205" y2="304.676" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint20_linear_5914_87592" x1="-16.8451" y1="120.237" x2="268.148" y2="497.903" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint21_linear_5914_87592" x1="221.404" y1="-59.5501" x2="506.397" y2="318.116" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint22_linear_5914_87592" x1="93.4626" y1="270.037" x2="366.874" y2="543.449" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint23_linear_5914_87592" x1="209.981" y1="153.519" x2="483.392" y2="426.931" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint24_linear_5914_87592" x1="213.767" y1="149.733" x2="487.179" y2="423.145" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint25_linear_5914_87592" x1="124.043" y1="239.457" x2="397.455" y2="512.868" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint26_linear_5914_87592" x1="277.331" y1="86.1685" x2="550.743" y2="359.58" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint27_linear_5914_87592" x1="116.462" y1="247.037" x2="389.874" y2="520.449" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint28_linear_5914_87592" x1="164.593" y1="198.907" x2="438.005" y2="472.318" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint29_linear_5914_87592" x1="182.541" y1="180.959" x2="455.952" y2="454.371" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint30_linear_5914_87592" x1="200.488" y1="163.012" x2="473.9" y2="436.424" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint31_linear_5914_87592" x1="219.251" y1="144.249" x2="492.663" y2="417.661" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint32_linear_5914_87592" x1="115.24" y1="248.261" x2="388.651" y2="521.672" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint33_linear_5914_87592" x1="208.057" y1="155.443" x2="481.468" y2="428.855" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint34_linear_5914_87592" x1="259.859" y1="103.641" x2="533.27" y2="377.053" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint35_linear_5914_87592" x1="315.921" y1="47.5791" x2="589.333" y2="320.991" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint36_linear_5914_87592" x1="265.161" y1="98.3389" x2="538.573" y2="371.751" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint37_linear_5914_87592" x1="179.912" y1="183.588" x2="453.324" y2="457" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint38_linear_5914_87592" x1="234.977" y1="128.523" x2="508.389" y2="401.935" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint39_linear_5914_87592" x1="293.079" y1="70.421" x2="566.491" y2="343.833" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint40_linear_5914_87592" x1="240.688" y1="122.812" x2="514.1" y2="396.224" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint41_linear_5914_87592" x1="161.738" y1="201.762" x2="435.15" y2="475.173" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint42_linear_5914_87592" x1="356.71" y1="6.7901" x2="630.121" y2="280.203" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint43_linear_5914_87592" x1="132.37" y1="231.13" x2="405.782" y2="504.541" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint44_linear_5914_87592" x1="336.723" y1="26.7768" x2="610.135" y2="300.189" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
</defs>
</svg>

)}
export default Grid_3d_Icon