const Icon_Pressure = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M30 52C42.1503 52 52 42.1503 52 30C52 17.8497 42.1503 8 30 8C17.8497 8 8 17.8497 8 30C8 42.1503 17.8497 52 30 52Z" fill="url(#paint0_linear_6201_97319)"/>
<path d="M29.9991 29.9995L13.2508 40.7866C11.2637 37.5221 10.1282 33.8318 10.1282 29.9995C10.1282 19.0705 19.0701 10.1285 29.9991 10.1285C40.9282 10.1285 49.8701 19.0705 49.8701 29.9995C49.8701 34.3995 48.4507 38.5156 45.8959 42.064L29.9991 29.9995Z" fill="white"/>
<path d="M30.0011 48.3094C40.1132 48.3094 48.3108 40.1119 48.3108 29.9997C48.3108 19.8876 40.1132 11.6901 30.0011 11.6901C19.8889 11.6901 11.6914 19.8876 11.6914 29.9997C11.6914 40.1119 19.8889 48.3094 30.0011 48.3094Z" fill="url(#paint1_linear_6201_97319)"/>
<path d="M25.1745 37.5226C24.3229 36.9548 24.181 35.9613 24.6068 35.2516L36.5294 17.5097C37.0971 16.8 38.0907 16.5161 38.8003 17.0839H38.9423C39.6519 17.6516 39.9358 18.6452 39.3681 19.3549L27.4455 37.0968C26.8777 37.8065 25.8842 37.9484 25.1745 37.5226Z" fill="white"/>
<path d="M30.0011 35.3941C32.9015 35.3941 35.2527 33.0429 35.2527 30.1425C35.2527 27.2421 32.9015 24.8909 30.0011 24.8909C27.1007 24.8909 24.7495 27.2421 24.7495 30.1425C24.7495 33.0429 27.1007 35.3941 30.0011 35.3941Z" fill="white"/>
<path d="M30.0006 32.6973C31.4116 32.6973 32.5555 31.5535 32.5555 30.1425C32.5555 28.7315 31.4116 27.5876 30.0006 27.5876C28.5896 27.5876 27.4458 28.7315 27.4458 30.1425C27.4458 31.5535 28.5896 32.6973 30.0006 32.6973Z" fill="url(#paint2_linear_6201_97319)"/>
<path d="M30.9949 11.6901H29.0078V15.2385H30.9949V11.6901Z" fill="white"/>
<path d="M49.0122 31.0883V29.1012H45.3219V31.0883H49.0122Z" fill="white"/>
<path d="M14.7358 31.017V29.0299H11.0455V31.017H14.7358Z" fill="white"/>
<path d="M47.1178 21.7537L46.1487 20.0189L42.927 21.8187L43.8961 23.5534L47.1178 21.7537Z" fill="white"/>
<path d="M17.5131 38.1191L16.5439 36.3844L13.4462 38.1149L14.4153 39.8497L17.5131 38.1191Z" fill="white"/>
<path d="M21.7491 17.1372L23.4839 16.1681L21.6841 12.9464L19.9494 13.9155L21.7491 17.1372Z" fill="white"/>
<path d="M44.6044 41.1564L45.7251 39.5155L42.7948 37.5142L41.6741 39.1552L44.6044 41.1564Z" fill="white"/>
<path d="M16.8051 22.3228L17.9258 20.6819L14.8783 18.6005L13.7576 20.2415L16.8051 22.3228Z" fill="white"/>
<defs>
<linearGradient id="paint0_linear_6201_97319" x1="8.01746" y1="30" x2="51.9825" y2="30" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint1_linear_6201_97319" x1="11.6255" y1="29.9997" x2="48.3766" y2="29.9997" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint2_linear_6201_97319" x1="27.9381" y1="28.794" x2="32.089" y2="31.5814" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon_Pressure;