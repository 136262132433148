import React, { useEffect, useState } from "react";
import imgCheck from "../../../assets/siteManagement/images/check.svg"

function ItemCardNew(props) {
    const [focusInput, setFocusInput] = useState(false);
    const [placeholder, setPlaceholder] = useState('');
    const [inputValue, setInputValue] = useState('');
    //==============================
    useEffect(() => {
        setPlaceholder(props.placeholder);
    }, [props.placeholder])
    useEffect(() => {
        if (props.clear) {
            setInputValue('');
        }
    }, [props.clear])
    //==============================
    function onClickAddItem() {
        try {
            props.onClickAdd(inputValue);
        } catch { }
    }
    function onInputText(e) {
        const value = e.target.value
        setInputValue(value);
    }
    //==============================
    return (
        <div className="w-full h-full flex items-center rounded-lg cursor-default">
            <div className="w-[30px] h-[30px]"></div>
            <div className={`h-full mx-2 px-2 flex items-center rounded border border-solid
            ${focusInput ? 'border-vekin-green100' : 'border-quartenary'}
            `}
                style={{ width: 'calc(100% - 46px)' }}>
                <input
                    className="w-full h-full text-vekin-green100 text-sm focus:outline-0"
                    type={`text`}
                    placeholder={placeholder}
                    onFocus={(e) => { setFocusInput(true) }}
                    onBlur={(e) => { setFocusInput(false) }}
                    value={inputValue}
                    onInput={(e) => { onInputText(e) }}
                />
            </div>
            {inputValue ?
                (<div className="w-[30px] h-[30px] rounded-lg 
                    hover:opacity-50 duration-300 ease-in-out cursor-pointer flex justify-center items-center"
                    style={{ background: 'linear-gradient(90.58deg, #1FA37C 1.6%, rgba(31, 163, 124, 0) 101.09%), #79C8B0' }}
                    onClick={(e) => { onClickAddItem() }}>
                    <img src={imgCheck} />
                </div>)
                :
                (<div className="w-[30px] h-[30px]"></div>)
            }
        </div>
    )
}

export default ItemCardNew