import React, { useEffect } from "react";

function PopupModalLoading(props) {
    useEffect(() => {
        const bodyHtml = document.querySelector("body");
        if (props.isOpen) {
            bodyHtml.style.overflow = "hidden";
        } else {
            bodyHtml.style.overflow = "auto";
        }
        return () => {
            bodyHtml.style.overflow = "auto";
        }
    }, [])
    useEffect(() => {
        const bodyHtml = document.querySelector("body");
        if (props.isOpen) {
            bodyHtml.style.overflow = "hidden";
        } else {
            bodyHtml.style.overflow = "auto";
        }
    }, [props.isOpen])
    //==============================
    return (
        <div className={`w-full h-full
        fixed top-0 left-0 z-[9999] 
        bg-black-opacity50
        flex justify-center items-center
        ${props.isOpen ? 'flex' : 'none'}
        `}
            style={{
                animation: `${props.isOpen ? 'fade-in 0.3s' : ''}`
            }}
        >
                <div className="loader"></div>
        </div>
    )
}

export default PopupModalLoading;