const Icon_ElectricSolar = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M44.4375 35.4313L42.0588 22.9463C41.825 21.7363 40.5463 20.8425 39.0475 20.8425H34.455L34.8125 21.0488L34.95 21.1725C35.4038 21.6125 35.6925 22.1075 35.8438 22.685H39.0475C39.6938 22.685 40.2025 23.07 40.2575 23.3038L40.7663 25.9438H34.9225L34.895 25.5313L32.2688 27.7725H33.19L33.41 31.265H26.9063L26.9888 30.0138C26.7275 29.6975 26.5488 29.285 26.5488 28.845V28.5838L27.2638 25.9438L27.36 24.4725L26.0263 23.675C25.875 23.5925 25.7238 23.4963 25.6 23.3725L25.4213 25.9438H20.0038L20.5125 23.3038C20.5675 23.0563 21.0625 22.685 21.7225 22.685H25.05C24.8988 22.4238 24.7888 22.1488 24.7338 21.8325C24.6788 21.4888 24.7063 21.1588 24.7888 20.8425H21.7088C20.2375 20.8425 18.9313 21.75 18.6975 22.9463L16.3188 35.445C16.1675 36.2563 16.36 37.0538 16.8688 37.6588C17.4188 38.305 18.2438 38.69 19.1788 38.69H41.5638C42.4988 38.69 43.3375 38.305 43.8738 37.6588C44.3963 37.0263 44.5888 36.2425 44.4375 35.4313ZM24.6788 36.8475H19.1925C18.8075 36.8475 18.4775 36.71 18.285 36.49C18.1338 36.2975 18.065 36.05 18.12 35.775L18.6288 33.0938H24.9263L24.6788 36.8475ZM25.05 31.265H18.9863L19.66 27.7725H25.2975L25.05 31.265ZM26.5075 36.8475L26.755 33.0938H33.5063L33.74 36.8475H26.5075ZM35.0188 27.7725H41.11L41.7838 31.265H35.2525L35.0188 27.7725ZM42.4713 36.49C42.2788 36.7238 41.9488 36.8475 41.5638 36.8475H35.5688L35.335 33.0938H42.1138L42.6225 35.775C42.6913 36.05 42.6363 36.2975 42.4713 36.49Z" fill="#6CCCDF"/>
<path d="M44.4375 35.4313L42.0588 22.9463C41.825 21.7363 40.5463 20.8425 39.0475 20.8425H34.455L34.8125 21.0488L34.95 21.1725C35.4038 21.6125 35.6925 22.1075 35.8438 22.685H39.0475C39.6938 22.685 40.2025 23.07 40.2575 23.3038L40.7663 25.9438H34.9225L34.895 25.5313L32.2688 27.7725H33.19L33.41 31.265H26.9063L26.9888 30.0138C26.7275 29.6975 26.5488 29.285 26.5488 28.845V28.5838L27.2638 25.9438L27.36 24.4725L26.0263 23.675C25.875 23.5925 25.7238 23.4963 25.6 23.3725L25.4213 25.9438H20.0038L20.5125 23.3038C20.5675 23.0563 21.0625 22.685 21.7225 22.685H25.05C24.8988 22.4238 24.7888 22.1488 24.7338 21.8325C24.6788 21.4888 24.7063 21.1588 24.7888 20.8425H21.7088C20.2375 20.8425 18.9313 21.75 18.6975 22.9463L16.3188 35.445C16.1675 36.2563 16.36 37.0538 16.8688 37.6588C17.4188 38.305 18.2438 38.69 19.1788 38.69H41.5638C42.4988 38.69 43.3375 38.305 43.8738 37.6588C44.3963 37.0263 44.5888 36.2425 44.4375 35.4313ZM24.6788 36.8475H19.1925C18.8075 36.8475 18.4775 36.71 18.285 36.49C18.1338 36.2975 18.065 36.05 18.12 35.775L18.6288 33.0938H24.9263L24.6788 36.8475ZM25.05 31.265H18.9863L19.66 27.7725H25.2975L25.05 31.265ZM26.5075 36.8475L26.755 33.0938H33.5063L33.74 36.8475H26.5075ZM35.0188 27.7725H41.11L41.7838 31.265H35.2525L35.0188 27.7725ZM42.4713 36.49C42.2788 36.7238 41.9488 36.8475 41.5638 36.8475H35.5688L35.335 33.0938H42.1138L42.6225 35.775C42.6913 36.05 42.6363 36.2975 42.4713 36.49Z" fill="url(#paint0_linear_3293_57627)" fill-opacity="0.7"/>
<path d="M31.2238 41.11H29.5326C29.0513 41.11 28.6801 40.725 28.6801 40.2575C28.6801 39.7763 29.0651 39.405 29.5326 39.405H31.2238C31.7051 39.405 32.0763 39.79 32.0763 40.2575C32.0763 40.7388 31.6913 41.11 31.2238 41.11Z" fill="#6CCCDF"/>
<path d="M31.2238 41.11H29.5326C29.0513 41.11 28.6801 40.725 28.6801 40.2575C28.6801 39.7763 29.0651 39.405 29.5326 39.405H31.2238C31.7051 39.405 32.0763 39.79 32.0763 40.2575C32.0763 40.7388 31.6913 41.11 31.2238 41.11Z" fill="url(#paint1_linear_3293_57627)" fill-opacity="0.7"/>
<path d="M31.2238 47.1875H29.5326C29.0513 47.1875 28.6801 46.8025 28.6801 46.335C28.6801 45.8538 29.0651 45.4825 29.5326 45.4825H31.2238C31.7051 45.4825 32.0763 45.8675 32.0763 46.335C32.0763 46.8025 31.6913 47.1875 31.2238 47.1875Z" fill="#6CCCDF"/>
<path d="M31.2238 47.1875H29.5326C29.0513 47.1875 28.6801 46.8025 28.6801 46.335C28.6801 45.8538 29.0651 45.4825 29.5326 45.4825H31.2238C31.7051 45.4825 32.0763 45.8675 32.0763 46.335C32.0763 46.8025 31.6913 47.1875 31.2238 47.1875Z" fill="url(#paint2_linear_3293_57627)" fill-opacity="0.7"/>
<path d="M34.07 21.9563L31.5675 20.5675C31.3337 20.4438 31.2237 20.155 31.32 19.9075L33.0937 15.1913C33.0937 14.875 32.7775 14.5588 32.4612 14.875L26.0125 20.375C25.38 21.0075 25.6962 21.9838 26.3287 22.3138L29.1612 24.0188C29.3812 24.17 29.4775 24.445 29.3812 24.6788L27.6212 29.0788C27.6212 29.395 27.9375 29.7113 28.2537 29.395L33.9187 24.5688C34.7025 23.9088 34.8125 22.7125 34.0975 21.9975C34.0837 21.9838 34.07 21.9838 34.07 21.9563Z" fill="#6CCCDF"/>
<path d="M34.07 21.9563L31.5675 20.5675C31.3337 20.4438 31.2237 20.155 31.32 19.9075L33.0937 15.1913C33.0937 14.875 32.7775 14.5588 32.4612 14.875L26.0125 20.375C25.38 21.0075 25.6962 21.9838 26.3287 22.3138L29.1612 24.0188C29.3812 24.17 29.4775 24.445 29.3812 24.6788L27.6212 29.0788C27.6212 29.395 27.9375 29.7113 28.2537 29.395L33.9187 24.5688C34.7025 23.9088 34.8125 22.7125 34.0975 21.9975C34.0837 21.9838 34.07 21.9838 34.07 21.9563Z" fill="url(#paint3_linear_3293_57627)" fill-opacity="0.7"/>
<path d="M38.3463 44.74H22.41C21.9013 44.74 21.4888 44.3275 21.4888 43.8187C21.4888 42.7325 22.3688 41.8662 23.4413 41.8662H37.315C38.4013 41.8662 39.2675 42.7462 39.2675 43.8187C39.2675 44.3137 38.855 44.74 38.3463 44.74Z" fill="#6CCCDF"/>
<path d="M38.3463 44.74H22.41C21.9013 44.74 21.4888 44.3275 21.4888 43.8187C21.4888 42.7325 22.3688 41.8662 23.4413 41.8662H37.315C38.4013 41.8662 39.2675 42.7462 39.2675 43.8187C39.2675 44.3137 38.855 44.74 38.3463 44.74Z" fill="url(#paint4_linear_3293_57627)" fill-opacity="0.7"/>
<path d="M29.9449 50.8175C18.4637 50.8175 9.12744 41.4812 9.12744 30C9.12744 18.5187 18.4637 9.1825 29.9449 9.1825C41.4262 9.1825 50.7487 18.5187 50.7487 30C50.7487 41.4812 41.4124 50.8175 29.9449 50.8175ZM29.9449 11.6575C19.8249 11.6575 11.6024 19.8937 11.6024 30C11.6024 40.1062 19.8249 48.3425 29.9449 48.3425C40.0512 48.3425 48.2874 40.12 48.2874 30C48.2874 19.88 40.0512 11.6575 29.9449 11.6575Z" fill="#6CCCDF"/>
<path d="M29.9449 50.8175C18.4637 50.8175 9.12744 41.4812 9.12744 30C9.12744 18.5187 18.4637 9.1825 29.9449 9.1825C41.4262 9.1825 50.7487 18.5187 50.7487 30C50.7487 41.4812 41.4124 50.8175 29.9449 50.8175ZM29.9449 11.6575C19.8249 11.6575 11.6024 19.8937 11.6024 30C11.6024 40.1062 19.8249 48.3425 29.9449 48.3425C40.0512 48.3425 48.2874 40.12 48.2874 30C48.2874 19.88 40.0512 11.6575 29.9449 11.6575Z" fill="url(#paint5_linear_3293_57627)" fill-opacity="0.7"/>
<defs>
<linearGradient id="paint0_linear_3293_57627" x1="18.2842" y1="19.3553" x2="44.5654" y2="20.6217" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57627" x1="28.9226" y1="39.2629" x2="32.0814" y2="39.4547" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57627" x1="28.9226" y1="45.3405" x2="32.0814" y2="45.5322" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57627" x1="26.304" y1="13.5122" x2="34.6164" y2="13.6649" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint4_linear_3293_57627" x1="22.7587" y1="41.6267" x2="38.7853" y2="44.6485" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint5_linear_3293_57627" x1="12.1004" y1="5.71296" x2="50.9175" y2="6.89556" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_ElectricSolar;