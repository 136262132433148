const Icon_Coin = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#F2F8F6"/>
        <path d="M26.1256 20.2286H25.7141V19.6114C25.7141 19.0629 25.2341 18.5829 24.6856 18.5829C24.137 18.5829 23.657 19.0629 23.657 19.6114V20.2286C23.177 20.2971 22.697 20.5029 22.2856 20.9143C21.8056 21.3943 21.5999 21.9429 21.5999 22.6286C21.5999 23.3143 21.8741 23.8629 22.2856 24.3429C22.697 24.8229 23.3141 25.0286 23.9999 25.0286C24.0684 25.0286 24.2056 25.0971 24.2741 25.0971C24.3427 25.1657 24.3427 25.2343 24.3427 25.3714C24.3427 25.5086 24.2741 25.5771 24.2741 25.6457C24.2056 25.6457 24.0684 25.7143 23.9999 25.7143H21.5999C21.0513 25.7143 20.5713 26.1943 20.5713 26.7429C20.5713 27.2914 21.0513 27.7714 21.5999 27.7714H22.2856V28.1829C22.2856 28.7314 22.7656 29.2114 23.3141 29.2114C23.8627 29.2114 24.3427 28.7314 24.3427 28.1829V27.7714C24.8227 27.7029 25.3027 27.4971 25.7141 27.0857C26.1941 26.6057 26.3999 26.0571 26.3999 25.3714C26.3999 24.6857 26.1256 24.1371 25.7141 23.6571C25.3027 23.1771 24.6856 22.9714 23.9999 22.9714C23.9313 22.9714 23.7941 22.9029 23.7256 22.9029C23.657 22.9029 23.657 22.7657 23.657 22.6286C23.657 22.4914 23.7256 22.4229 23.7256 22.3543C23.7256 22.2857 23.9313 22.2857 23.9999 22.2857H26.1256C26.6741 22.2857 27.1541 21.8057 27.1541 21.2571C27.1541 20.7086 26.6741 20.2286 26.1256 20.2286Z" fill="#FFA800"/>
        <path d="M23.9999 33.0514C23.1085 33.0514 22.2171 32.9143 21.3256 32.64C20.3656 32.3657 19.4056 31.8857 18.5828 31.2686C17.6913 30.5829 16.9371 29.76 16.3199 28.8C15.4285 27.36 14.9485 25.7143 14.9485 24C14.9485 23.5886 15.2228 23.3143 15.6342 23.3143C16.0456 23.3143 16.3199 23.5886 16.3199 24C16.3199 25.44 16.7313 26.88 17.4856 28.1143C17.9656 28.9372 18.6513 29.6229 19.4056 30.1714C20.0913 30.72 20.9142 31.1314 21.7371 31.3372C23.2456 31.8172 24.8913 31.8172 26.3999 31.3372C26.7428 31.2 27.1542 31.4057 27.2913 31.7486C27.4285 32.0914 27.2228 32.5029 26.8799 32.64C25.9199 32.9143 24.9599 33.0514 23.9999 33.0514Z" fill="#FFA800"/>
        <path d="M19.1316 17.8286C18.9258 17.8286 18.7201 17.76 18.583 17.5543C18.3773 17.28 18.4458 16.8 18.7201 16.5943C20.2287 15.4971 22.0801 14.88 24.0001 14.88C25.6458 14.88 27.223 15.2914 28.663 16.1143C29.0058 16.32 29.0744 16.7314 28.9373 17.0743C28.7316 17.4171 28.3201 17.4857 27.9773 17.3486C26.743 16.6629 25.3716 16.32 24.0001 16.32C22.3544 16.32 20.8458 16.8 19.543 17.76C19.4058 17.8286 19.2687 17.8286 19.1316 17.8286Z" fill="#FFA800"/>
        <path d="M30.3086 30.24C30.1715 30.24 29.9658 30.1714 29.8286 30.1029C29.5543 29.8286 29.4858 29.4172 29.7601 29.1429C30.1715 28.6629 30.5143 28.1143 30.8572 27.5657C31.4058 26.4686 31.6801 25.3029 31.6801 24.0686C31.6801 23.6572 31.9543 23.3829 32.3658 23.3829C32.7772 23.3829 33.0515 23.6572 33.0515 24.0686C33.0515 25.5086 32.7086 26.88 32.0915 28.1829C31.7486 28.8686 31.3372 29.4857 30.8572 30.0343C30.7201 30.1029 30.5143 30.24 30.3086 30.24Z" fill="#FFA800"/>
        <path d="M24 36C17.4171 36 12 30.5829 12 24C12 17.4171 17.4171 12 24 12C30.5829 12 36 17.4171 36 24C36 30.5829 30.5829 36 24 36ZM24 14.0571C18.5143 14.0571 14.0571 18.5143 14.0571 24C14.0571 29.4857 18.5143 33.9429 24 33.9429C29.4857 33.9429 33.9429 29.4857 33.9429 24C33.9429 18.5143 29.4857 14.0571 24 14.0571Z" fill="#FFA800"/>
        </svg>

     );
}
 
export default Icon_Coin;