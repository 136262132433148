const RecPotential_Icon = ({style, stroke}) => {
    return (  
        <svg className={style}   width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4795 11.0414C14.3053 10.9062 14.9959 10.5759 15.5364 9.99037C15.9118 9.58499 16.152 9.10453 16.3022 8.56402C17.0529 5.74136 18.8546 3.96968 21.7073 3.33909C25.5209 2.49829 29.3945 5.03569 30.0251 9.02946C30.2954 10.7261 29.9501 12.3026 29.0492 13.7589C28.9291 13.9691 28.839 14.0142 28.6138 13.849C27.728 13.2034 26.737 12.783 25.671 12.5578C25.5809 12.5428 25.5059 12.5128 25.4158 12.4827C25.4158 12.3626 25.5059 12.3176 25.5659 12.2575C26.4367 11.2516 26.677 10.1105 26.1815 8.8643C25.686 7.66317 24.7551 6.97252 23.4639 6.83739C21.7673 6.64221 20.2959 7.84334 19.9356 9.31473C19.3651 11.6419 17.9387 13.2635 15.7316 14.1943C15.5064 14.2844 15.4163 14.2844 15.3262 14.0142C14.9509 12.9932 14.6056 12.4377 13.4795 11.0414Z" fill={stroke}/>
<path d="M13.4795 11.0414C14.3053 10.9062 14.9959 10.5759 15.5364 9.99037C15.9118 9.58499 16.152 9.10453 16.3022 8.56402C17.0529 5.74136 18.8546 3.96968 21.7073 3.33909C25.5209 2.49829 29.3945 5.03569 30.0251 9.02946C30.2954 10.7261 29.9501 12.3026 29.0492 13.7589C28.9291 13.9691 28.839 14.0142 28.6138 13.849C27.728 13.2034 26.737 12.783 25.671 12.5578C25.5809 12.5428 25.5059 12.5128 25.4158 12.4827C25.4158 12.3626 25.5059 12.3176 25.5659 12.2575C26.4367 11.2516 26.677 10.1105 26.1815 8.8643C25.686 7.66317 24.7551 6.97252 23.4639 6.83739C21.7673 6.64221 20.2959 7.84334 19.9356 9.31473C19.3651 11.6419 17.9387 13.2635 15.7316 14.1943C15.5064 14.2844 15.4163 14.2844 15.3262 14.0142C14.9509 12.9932 14.6056 12.4377 13.4795 11.0414Z" fill={stroke}/>
<path d="M21.7822 17.8127C21.2267 18.3833 20.8663 19.0139 20.7612 19.7796C20.6711 20.3651 20.7462 20.9357 20.9864 21.4762C22.7882 25.6201 20.1457 30.3195 15.6264 31.0252C11.7677 31.6258 8.1193 28.9232 7.62383 25.0646C7.53374 24.3589 7.53374 24.3589 8.23941 24.3289C9.23034 24.2838 10.1762 24.0436 11.0771 23.6532C11.1371 23.6232 11.1972 23.5932 11.3173 23.5481C11.3023 23.9235 11.2573 24.2688 11.3023 24.6141C11.5125 26.2357 12.9088 27.4518 14.5754 27.4518C16.257 27.4518 17.6533 26.2057 17.8335 24.5691C17.9085 23.9535 17.7734 23.383 17.5482 22.8275C16.6774 20.6504 16.9026 18.5634 18.1037 16.5515C18.2238 16.3413 18.3139 16.2963 18.5391 16.4614C19.47 17.1371 20.506 17.5725 21.6321 17.7827C21.6471 17.7677 21.6921 17.7827 21.7822 17.8127Z" fill={stroke}/>
<path d="M21.7822 17.8127C21.2267 18.3833 20.8663 19.0139 20.7612 19.7796C20.6711 20.3651 20.7462 20.9357 20.9864 21.4762C22.7882 25.6201 20.1457 30.3195 15.6264 31.0252C11.7677 31.6258 8.1193 28.9232 7.62383 25.0646C7.53374 24.3589 7.53374 24.3589 8.23941 24.3289C9.23034 24.2838 10.1762 24.0436 11.0771 23.6532C11.1371 23.6232 11.1972 23.5932 11.3173 23.5481C11.3023 23.9235 11.2573 24.2688 11.3023 24.6141C11.5125 26.2357 12.9088 27.4518 14.5754 27.4518C16.257 27.4518 17.6533 26.2057 17.8335 24.5691C17.9085 23.9535 17.7734 23.383 17.5482 22.8275C16.6774 20.6504 16.9026 18.5634 18.1037 16.5515C18.2238 16.3413 18.3139 16.2963 18.5391 16.4614C19.47 17.1371 20.506 17.5725 21.6321 17.7827C21.6471 17.7677 21.6921 17.7827 21.7822 17.8127Z" fill={stroke}/>
<path d="M16.0918 6.44698C15.7915 5.87644 15.4011 5.411 14.8756 5.08069C13.3292 4.10477 11.2722 4.55519 10.2963 6.10165C9.33542 7.6331 9.81587 9.61497 11.4074 10.5909C13.2992 11.762 14.4102 13.4436 14.7555 15.6507C14.8606 16.3113 14.8456 16.3113 14.23 16.3563C13.2091 16.4314 12.2332 16.6266 11.3023 17.062C11.0921 17.1671 11.047 17.107 11.0771 16.8969C11.1822 16.0711 10.972 15.3204 10.4915 14.6297C10.2213 14.2393 9.86091 13.9391 9.44052 13.6838C7.54873 12.4977 6.42267 10.8011 6.16742 8.59401C5.77706 5.32092 7.78896 2.25802 10.9269 1.25207C13.6745 0.366236 16.6623 1.22204 18.5241 3.44414C18.6142 3.54924 18.7193 3.62431 18.5391 3.74443C17.5482 4.43508 16.7674 5.3059 16.1819 6.34188C16.1669 6.35689 16.1519 6.38692 16.0918 6.44698Z" fill={stroke}/>
<path d="M16.0918 6.44698C15.7915 5.87644 15.4011 5.411 14.8756 5.08069C13.3292 4.10477 11.2722 4.55519 10.2963 6.10165C9.33542 7.6331 9.81587 9.61497 11.4074 10.5909C13.2992 11.762 14.4102 13.4436 14.7555 15.6507C14.8606 16.3113 14.8456 16.3113 14.23 16.3563C13.2091 16.4314 12.2332 16.6266 11.3023 17.062C11.0921 17.1671 11.047 17.107 11.0771 16.8969C11.1822 16.0711 10.972 15.3204 10.4915 14.6297C10.2213 14.2393 9.86091 13.9391 9.44052 13.6838C7.54873 12.4977 6.42267 10.8011 6.16742 8.59401C5.77706 5.32092 7.78896 2.25802 10.9269 1.25207C13.6745 0.366236 16.6623 1.22204 18.5241 3.44414C18.6142 3.54924 18.7193 3.62431 18.5391 3.74443C17.5482 4.43508 16.7674 5.3059 16.1819 6.34188C16.1669 6.35689 16.1519 6.38692 16.0918 6.44698Z" fill={stroke}/>
<path d="M20.1006 11.4467C20.2808 11.7319 20.4009 11.9872 20.5961 12.1974C21.3318 13.0231 22.2477 13.4135 23.3437 13.3384C25.6709 13.1883 27.6828 13.894 29.2443 15.6506C32.6675 19.4943 30.8808 25.62 25.6559 26.9263C24.5448 27.1965 23.4338 27.1815 22.3227 26.9263C22.0825 26.8662 21.9774 26.8212 22.0825 26.5209C22.3828 25.605 22.5179 24.6591 22.4278 23.6832C22.4128 23.488 22.3978 23.3079 22.3978 23.0826C22.7732 23.2028 23.0885 23.3529 23.4338 23.413C25.2505 23.7132 26.9922 22.4971 27.2624 20.7104C27.5327 18.8787 26.2264 17.1971 24.3647 16.9869C24.1244 16.9569 23.8842 16.9719 23.644 16.9869C21.2267 17.137 19.2448 16.2662 17.6683 14.4345C17.5783 14.3294 17.5482 14.2693 17.6834 14.1792C18.6893 13.4586 19.4851 12.5727 20.1006 11.4467Z" fill={stroke}/>
<path d="M20.1006 11.4467C20.2808 11.7319 20.4009 11.9872 20.5961 12.1974C21.3318 13.0231 22.2477 13.4135 23.3437 13.3384C25.6709 13.1883 27.6828 13.894 29.2443 15.6506C32.6675 19.4943 30.8808 25.62 25.6559 26.9263C24.5448 27.1965 23.4338 27.1815 22.3227 26.9263C22.0825 26.8662 21.9774 26.8212 22.0825 26.5209C22.3828 25.605 22.5179 24.6591 22.4278 23.6832C22.4128 23.488 22.3978 23.3079 22.3978 23.0826C22.7732 23.2028 23.0885 23.3529 23.4338 23.413C25.2505 23.7132 26.9922 22.4971 27.2624 20.7104C27.5327 18.8787 26.2264 17.1971 24.3647 16.9869C24.1244 16.9569 23.8842 16.9719 23.644 16.9869C21.2267 17.137 19.2448 16.2662 17.6683 14.4345C17.5783 14.3294 17.5482 14.2693 17.6834 14.1792C18.6893 13.4586 19.4851 12.5727 20.1006 11.4467Z" fill={stroke}/>
<path d="M16.167 21.3261C15.7466 21.1459 15.3562 20.9958 14.9358 20.9507C13.9899 20.8456 13.1491 21.1009 12.4284 21.7014C8.32958 25.1847 2.11371 23.1128 0.957615 17.8578C0.251948 14.6598 2.20379 11.1615 5.26668 10.0804C5.41683 10.0204 5.52193 10.0054 5.58198 10.2006C5.94232 11.3266 6.54289 12.3025 7.35366 13.1734C7.36867 13.1884 7.38368 13.2184 7.41371 13.2635C7.15847 13.3385 6.90323 13.3836 6.663 13.4887C5.34176 14.0142 4.59105 15.0051 4.53099 16.4164C4.47093 17.7377 5.08651 18.7737 6.27263 19.3892C7.5038 20.0348 8.73496 19.9297 9.831 19.0739C10.7318 18.3683 11.6928 17.7827 12.8038 17.4974C13.9599 17.2122 15.101 17.2122 16.2571 17.4824C16.4823 17.5425 16.5724 17.6025 16.4823 17.8728C16.167 18.8637 16.0168 19.8847 16.137 20.9207C16.152 21.0408 16.167 21.1609 16.167 21.3261Z" fill={stroke}/>
<path d="M16.167 21.3261C15.7466 21.1459 15.3562 20.9958 14.9358 20.9507C13.9899 20.8456 13.1491 21.1009 12.4284 21.7014C8.32958 25.1847 2.11371 23.1128 0.957615 17.8578C0.251948 14.6598 2.20379 11.1615 5.26668 10.0804C5.41683 10.0204 5.52193 10.0054 5.58198 10.2006C5.94232 11.3266 6.54289 12.3025 7.35366 13.1734C7.36867 13.1884 7.38368 13.2184 7.41371 13.2635C7.15847 13.3385 6.90323 13.3836 6.663 13.4887C5.34176 14.0142 4.59105 15.0051 4.53099 16.4164C4.47093 17.7377 5.08651 18.7737 6.27263 19.3892C7.5038 20.0348 8.73496 19.9297 9.831 19.0739C10.7318 18.3683 11.6928 17.7827 12.8038 17.4974C13.9599 17.2122 15.101 17.2122 16.2571 17.4824C16.4823 17.5425 16.5724 17.6025 16.4823 17.8728C16.167 18.8637 16.0168 19.8847 16.137 20.9207C16.152 21.0408 16.167 21.1609 16.167 21.3261Z" fill={stroke}/>
<path d="M14.5754 21.8365C15.8666 21.8365 16.9326 22.9025 16.9326 24.1787C16.9326 25.4849 15.8666 26.5359 14.5454 26.5359C13.2391 26.5359 12.1431 25.4549 12.1581 24.1787C12.1881 22.8574 13.2541 21.8215 14.5754 21.8365Z" fill={stroke}/>
<path d="M14.5754 21.8365C15.8666 21.8365 16.9326 22.9025 16.9326 24.1787C16.9326 25.4849 15.8666 26.5359 14.5454 26.5359C13.2391 26.5359 12.1431 25.4549 12.1581 24.1787C12.1881 22.8574 13.2541 21.8215 14.5754 21.8365Z" fill={stroke}/>
<path d="M20.7463 10.2154C20.7463 8.90915 21.8123 7.87317 23.1335 7.87317C24.4397 7.87317 25.5208 8.9692 25.5057 10.2454C25.4907 11.5516 24.4247 12.5726 23.0885 12.5726C21.7822 12.5576 20.7312 11.5066 20.7463 10.2154Z" fill={stroke}/>
<path d="M20.7463 10.2154C20.7463 8.90915 21.8123 7.87317 23.1335 7.87317C24.4397 7.87317 25.5208 8.9692 25.5057 10.2454C25.4907 11.5516 24.4247 12.5726 23.0885 12.5726C21.7822 12.5576 20.7312 11.5066 20.7463 10.2154Z" fill={stroke}/>
<path d="M7.78911 18.8487C6.48288 18.8487 5.41687 17.7827 5.41687 16.5065C5.41687 15.2003 6.48288 14.1643 7.81914 14.1643C9.14039 14.1643 10.1914 15.2303 10.1764 16.5365C10.1764 17.8428 9.11036 18.8637 7.78911 18.8487Z" fill={stroke}/>
<path d="M7.78911 18.8487C6.48288 18.8487 5.41687 17.7827 5.41687 16.5065C5.41687 15.2003 6.48288 14.1643 7.81914 14.1643C9.14039 14.1643 10.1914 15.2303 10.1764 16.5365C10.1764 17.8428 9.11036 18.8637 7.78911 18.8487Z" fill={stroke}/>
<path d="M13.1191 5.59109C14.4403 5.6061 15.4913 6.6571 15.4763 7.9333C15.4613 9.23954 14.3803 10.2905 13.074 10.2755C11.7528 10.2605 10.6868 9.17948 10.7168 7.88826C10.7468 6.59704 11.8128 5.56106 13.1191 5.59109Z" fill={stroke}/>
<path d="M13.1191 5.59109C14.4403 5.6061 15.4913 6.6571 15.4763 7.9333C15.4613 9.23954 14.3803 10.2905 13.074 10.2755C11.7528 10.2605 10.6868 9.17948 10.7168 7.88826C10.7468 6.59704 11.8128 5.56106 13.1191 5.59109Z" fill={stroke}/>
<path d="M24.0043 22.5571C22.6831 22.5571 21.6321 21.5061 21.6321 20.1999C21.6321 18.8936 22.7131 17.8577 24.0193 17.8577C25.3256 17.8727 26.3766 18.9087 26.3766 20.1999C26.3916 21.5061 25.3256 22.5571 24.0043 22.5571Z" fill={stroke}/>
<path d="M24.0043 22.5571C22.6831 22.5571 21.6321 21.5061 21.6321 20.1999C21.6321 18.8936 22.7131 17.8577 24.0193 17.8577C25.3256 17.8727 26.3766 18.9087 26.3766 20.1999C26.3916 21.5061 25.3256 22.5571 24.0043 22.5571Z" fill={stroke}/>

</svg>

    );
}

export default RecPotential_Icon;