import React from "react";
import imgClose from "../../../assets/siteManagement/images/close-2.svg"

function BtnCancel(props) {
    function onClickFunc() {
        try {
            props.onClick();
        } catch { }
    }
    return (
        <div className={`h-full px-4
        flex items-center rounded-lg 
        cursor-pointer hover:opacity-60
        duration-300 ease-in-out`}
            style={{ background: 'linear-gradient(90.92deg, #DC5D5E 1.04%, #D8907D 95.96%), #D8907D' }}
            onClick={(e) => { onClickFunc() }}>
            <img className="w-3.5 h3.5 mr-2" src={imgClose} />
            <p className={`text-white text-xs font-semibold`}>
                Cancel
            </p>
        </div>
    )
}

export default BtnCancel;