const Cctv_Icon = ({style, stroke}) => {
    return (
        <svg className={style}  width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.4062 4.78126L20.0624 10.5938C19.4999 11.25 19.4062 12.1875 20.0624 12.9375L25.4062 19.125C26.4374 20.3438 28.4062 19.5938 28.4062 18V5.90626C28.4062 4.31251 26.5312 3.56251 25.4062 4.78126Z" fill={stroke}/>
<path d="M25.4062 4.78126L20.0624 10.5938C19.4999 11.25 19.4062 12.1875 20.0624 12.9375L25.4062 19.125C26.4374 20.3438 28.4062 19.5938 28.4062 18V5.90626C28.4062 4.31251 26.5312 3.56251 25.4062 4.78126Z" fill={stroke}/>
<path d="M17.7187 4.6875H2.90625C2.15625 4.6875 1.59375 5.25 1.59375 5.90625V18C1.59375 18.75 2.15625 19.3125 2.90625 19.3125H14.9062C15.4688 19.3125 16.0312 18.9375 16.2187 18.2812L19.125 6.1875C19.2187 5.4375 18.5625 4.6875 17.7187 4.6875ZM6.5625 12.4688C5.15625 12.4688 4.03125 11.3438 4.03125 9.9375C4.03125 8.53125 5.15625 7.40625 6.5625 7.40625C7.96875 7.40625 9.09375 8.53125 9.09375 9.9375C9.09375 11.3438 7.96875 12.4688 6.5625 12.4688Z" fill={stroke}/>
<path d="M17.7187 4.6875H2.90625C2.15625 4.6875 1.59375 5.25 1.59375 5.90625V18C1.59375 18.75 2.15625 19.3125 2.90625 19.3125H14.9062C15.4688 19.3125 16.0312 18.9375 16.2187 18.2812L19.125 6.1875C19.2187 5.4375 18.5625 4.6875 17.7187 4.6875ZM6.5625 12.4688C5.15625 12.4688 4.03125 11.3438 4.03125 9.9375C4.03125 8.53125 5.15625 7.40625 6.5625 7.40625C7.96875 7.40625 9.09375 8.53125 9.09375 9.9375C9.09375 11.3438 7.96875 12.4688 6.5625 12.4688Z" fill={stroke}/>
<path d="M9.09375 25.7812C11.5312 25.7812 13.5938 23.8125 13.5938 21.2812H4.59375C4.59375 23.8125 6.65625 25.7812 9.09375 25.7812Z" fill={stroke}/>
<path d="M9.09375 25.7812C11.5312 25.7812 13.5938 23.8125 13.5938 21.2812H4.59375C4.59375 23.8125 6.65625 25.7812 9.09375 25.7812Z" fill={stroke}/>

</svg>
    )
}

export default Cctv_Icon;