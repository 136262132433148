import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import L from 'leaflet';
import 'leaflet.markercluster';

import imgPin from '../../../assets/siteManagement/images/pin.svg'

let map;

function GisMapMiniComponent(props) {
    const [id, setId] = useState('');
    const [gisMap, setGisMap] = useState(null);
    const [mapPosition, setMapPosition] = useState({
        latitude: 13.8583,
        longitude: 100.4688,
        zoom: 10,
        zoomSnap: 0.5,
        maxZoom: 19,
        minZoom: 1,
    })
    const [listMarker, setListMarker] = useState([]);
    const listSiteRef = useRef([]);
    const [markerCluster, setMarkerCluster] = useState(null);

    useEffect(() => {
        const init = async () => {
            setId(randomString(10));
        }
        init();
    }, [])
    useEffect(() => {
        if (id) {
            onInitailizationMap();
        }
    }, [id])
    useEffect(() => {
        if (
            Array.isArray(props.listSite)
            && gisMap
        ) {
            onSetMarkerSite(props.listSite);
        }
    }, [props.listSite, gisMap])
    useEffect(() => {
        if (gisMap) {
            onSetFocusMap(props.centerMapFocus);
        }
    }, [props.centerMapFocus, gisMap])

    function randomString(length) {
        var text = "";
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    async function onInitailizationMap() {
        try {
            if (!gisMap) {
                map = L.map(`map${id}`, {
                    center: [mapPosition.latitude, mapPosition.longitude],
                    zoom: mapPosition.zoom,
                    zoomSnap: mapPosition.zoomSnap,
                })
                setGisMap(map)
            } else {
                map = gisMap;
            }
            //==============================
            let tileLayer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            const tiles = L.tileLayer(tileLayer, {
                maxZoom: mapPosition.maxZoom,
                minZoom: mapPosition.minZoom,
            });
            tiles.addTo(map);
            //==============================
            map.zoomControl.setPosition('bottomright');
            //==============================
            await onSetMapEvent();
            //==============================
        } catch (e) { }
    }
    function onSetMapEvent() {
        if (!map) return;

        map.on('click', (event) => {
            const target = event.target
            const center = target.getCenter();
            const latlng = event.latlng;
        });

        map.on('zoomend', (event) => {
            const target = event.target
            const latlng = target.getCenter();
        });

        map.on('dragend', (event) => {
            const target = event.target
            const latlng = target.getCenter();
        });
    }
    //==============================
    async function onSetMarkerSite(param) {
        if (map && markerCluster) map.removeLayer(markerCluster);
        //================================
        let listX = [];
        let listMarkerX = [];
        let listMarkerXWithId = [];
        listSiteRef.current = [];
        try {
            listX = JSON.parse(JSON.stringify(param))
        } catch { }
        for (let item of listX) {
            listSiteRef.current.push(item);
            if (item.Active) {
                const marker = await onCreateMarkerSite(item);
                if (marker) {
                    listMarkerX.push(marker)
                    listMarkerXWithId.push({
                        Id: item.Id,
                        Latitude: item.Latitude,
                        Longitude: item.Longitude,
                        Marker: marker,
                    })
                    if (map) {
                        map.addLayer(marker);
                    }
                }
            }
        }
        setListMarker(listMarkerXWithId)
        //================================
        return;
        let markerClusterX = L.markerClusterGroup({
            iconCreateFunction: (e) => {
                let styleCluster = {
                    bgColor: '#DC5D5E',
                    fontColor: '#FFFFFF',
                    fontSize: '10px',
                    fontWeight: 600,
                    boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.16)',
                };
                return L.divIcon({
                    html: `<div 
                    style="
                    display: flex;align-items: center;justify-content: center;position: relative;
                    ">
                    <div
                    style="
                    display: flex;align-items: center;justify-content: center;position: absolute;
                    box-shadow: ${styleCluster.boxShadow};
                    background-color: ${styleCluster.bgColor};
                    color: ${styleCluster.fontColor};
                    font-size: ${styleCluster.fontSize};
                    font-weight: ${styleCluster.fontWeight};
                    border-radius: 50%;
                    width: 28px;
                    height: 28px;
                    ">
                    <span style="padding-bottom: 2px;">${e.getChildCount()}</span>
                    </div>
                    </div>`,
                    className: '',
                })
            }
        });
        markerClusterX.addLayers(listMarkerX)
        if (map) {
            map.addLayer(markerClusterX);
            setMarkerCluster(markerClusterX);
        }
    }
    async function onCreateMarkerSite(param) {
        if (!map) return;
        if (!param.Latitude || !param.Longitude) return;

        let marker = L.marker([param.Latitude, param.Longitude]);
        //==============================
        let icon;
        let svgPin = '';
        svgPin = '<div style="display: flex;align-items: center;justify-content: center;position: relative;">' +
            `<img style='width: 24px;position: absolute;' src='${imgPin}' />` +
            '</div>';
        icon = L.divIcon({
            html: svgPin,
            className: '',
            iconAnchor: [0, 0],
        });
        marker.options.icon = icon;
        marker.options.alt = JSON.stringify(param);
        //==============================
        //=============event============
        marker.on('click', (event) => {
            const element = event.target;
            const latlng = element.getLatLng();
            let obj;
            try {
                obj = JSON.parse(element.options.alt);
            } catch { }
        });
        marker.on('mouseover', (event) => {
            const element = event.target;
            let obj;
            try {
                obj = JSON.parse(element.options.alt);
            } catch { }
            // onSetPopupMarker(true, element, obj?.Name, 7, 0);
        });
        marker.on('mouseout', (event) => {
            const element = event.target;
            let obj;
            try {
                obj = JSON.parse(element.options.alt);
            } catch { }
            // onSetPopupMarker(false, element, obj?.Name, 7, 0);
        });
        //==============================
        return marker
    }
    async function onSetPopupMarker(showPopup, marker, text, x, y) {
        if (marker) {
            if (showPopup) {
                marker.bindPopup(
                    `<div style='`
                    + `display: flex;`
                    + `justify-content: center;`
                    + `color: #1FA37C;`
                    + `font-size: 14px;`
                    + `font-weight: 700;`
                    + `font-family: "_Prompt";`
                    + `'>`
                    + `${text}`
                    + `</div>`
                    ,
                    { 'offset': L.point(x, y), 'closeButton': '' }).openPopup();
            }
            else {
                marker.closePopup();
            }
        }
    }
    //==============================
    async function onSetFocusMap(param) {
        if (map) {
            if (
                param?.Latitude
                && param?.Longitude
                && param?.Zoom
            ) {
                const zoom = param.Zoom / 2;
                const center = [param.Latitude, param.Longitude];
                map.invalidateSize();
                map.setView(center, zoom);
            }
        }
    }

    return (
        <div id={`map${id}`} className="w-full h-full">
        </div>
    )
}

export default GisMapMiniComponent;