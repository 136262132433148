const Icon_CoinSave = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<g clip-path="url(#clip0_3293_57678)">
<path d="M40.9313 35.225C40.9313 37.26 40.12 39.2263 38.6763 40.67C37.81 41.5363 36.6413 42.0175 35.4175 42.0175H31.595V44.0938C31.595 45.0288 30.8388 45.7988 29.89 45.7988C28.955 45.7988 28.185 45.0425 28.185 44.0938V42.0175H21.7088C20.9938 42.0175 20.4163 41.44 20.4163 40.725V19.275C20.4163 18.56 20.9938 17.9825 21.7088 17.9825H28.1988V15.9063C28.1988 14.9713 28.955 14.2013 29.9038 14.2013C30.8388 14.2013 31.6088 14.9575 31.6088 15.9063V17.9825H34.73C35.885 17.9825 36.9988 18.4363 37.8238 19.2613C39.13 20.5675 39.8725 22.355 39.8725 24.2113V25.9575C39.8725 27.2913 39.3363 28.57 38.4013 29.5188L38.7038 29.8213C40.1338 31.2513 40.9313 33.2038 40.9313 35.225ZM24.3075 28.3225H28.2125V21.3513H24.3075V28.3225ZM28.2125 38.6488V31.54H24.3075V38.6488H28.2125ZM31.6088 21.3513V28.3225H31.9938C32.6263 28.3225 34.7988 28.075 35.2525 27.6213C35.7063 27.1675 35.9538 26.5625 35.9538 25.9163V23.6888C35.9538 23.07 35.7063 22.465 35.2663 22.025C34.8263 21.585 34.235 21.3375 33.6163 21.3375L31.6088 21.3513ZM36.325 32.475L36.0638 32.2138C35.6238 31.7875 35.0325 31.54 34.4275 31.54H31.6088V38.6488H34.3863C35.0188 38.6488 35.6375 38.4013 36.0913 37.9475L36.3388 37.7C36.7925 37.2463 37.04 36.6413 37.04 35.995V34.1388C37.0263 33.52 36.765 32.915 36.325 32.475Z" fill="#79C8B0"/>
<path d="M40.9313 35.225C40.9313 37.26 40.12 39.2263 38.6763 40.67C37.81 41.5363 36.6413 42.0175 35.4175 42.0175H31.595V44.0938C31.595 45.0288 30.8388 45.7988 29.89 45.7988C28.955 45.7988 28.185 45.0425 28.185 44.0938V42.0175H21.7088C20.9938 42.0175 20.4163 41.44 20.4163 40.725V19.275C20.4163 18.56 20.9938 17.9825 21.7088 17.9825H28.1988V15.9063C28.1988 14.9713 28.955 14.2013 29.9038 14.2013C30.8388 14.2013 31.6088 14.9575 31.6088 15.9063V17.9825H34.73C35.885 17.9825 36.9988 18.4363 37.8238 19.2613C39.13 20.5675 39.8725 22.355 39.8725 24.2113V25.9575C39.8725 27.2913 39.3363 28.57 38.4013 29.5188L38.7038 29.8213C40.1338 31.2513 40.9313 33.2038 40.9313 35.225ZM24.3075 28.3225H28.2125V21.3513H24.3075V28.3225ZM28.2125 38.6488V31.54H24.3075V38.6488H28.2125ZM31.6088 21.3513V28.3225H31.9938C32.6263 28.3225 34.7988 28.075 35.2525 27.6213C35.7063 27.1675 35.9538 26.5625 35.9538 25.9163V23.6888C35.9538 23.07 35.7063 22.465 35.2663 22.025C34.8263 21.585 34.235 21.3375 33.6163 21.3375L31.6088 21.3513ZM36.325 32.475L36.0638 32.2138C35.6238 31.7875 35.0325 31.54 34.4275 31.54H31.6088V38.6488H34.3863C35.0188 38.6488 35.6375 38.4013 36.0913 37.9475L36.3388 37.7C36.7925 37.2463 37.04 36.6413 37.04 35.995V34.1388C37.0263 33.52 36.765 32.915 36.325 32.475Z" fill="url(#paint0_linear_3293_57678)"/>
<path d="M49.3874 18.3538L45.9499 16.4425C45.6199 16.2638 45.4824 15.8788 45.6062 15.535L48.0399 9.05878C48.0399 8.61878 47.5999 8.17878 47.1599 8.61878L38.2912 16.1675C37.4112 17.0475 37.8512 18.3813 38.7312 18.8213L42.6224 21.1588C42.9249 21.3513 43.0624 21.7363 42.9249 22.0663L40.5049 28.13C40.5049 28.57 40.9449 29.01 41.3849 28.57L49.1674 21.9425C50.2399 21.035 50.4049 19.385 49.4149 18.395C49.4012 18.3675 49.3874 18.3675 49.3874 18.3538Z" fill="#79C8B0"/>
<path d="M49.3874 18.3538L45.9499 16.4425C45.6199 16.2638 45.4824 15.8788 45.6062 15.535L48.0399 9.05878C48.0399 8.61878 47.5999 8.17878 47.1599 8.61878L38.2912 16.1675C37.4112 17.0475 37.8512 18.3813 38.7312 18.8213L42.6224 21.1588C42.9249 21.3513 43.0624 21.7363 42.9249 22.0663L40.5049 28.13C40.5049 28.57 40.9449 29.01 41.3849 28.57L49.1674 21.9425C50.2399 21.035 50.4049 19.385 49.4149 18.395C49.4012 18.3675 49.3874 18.3675 49.3874 18.3538Z" fill="url(#paint1_linear_3293_57678)"/>
<path d="M47.8336 25.9713C48.1224 27.2638 48.2874 28.6113 48.2874 30C48.2874 40.0788 40.0924 48.2875 29.9999 48.2875C19.9212 48.2875 11.7124 40.0925 11.7124 30C11.7124 19.9075 19.9074 11.7125 29.9999 11.7125C32.5849 11.7125 35.0462 12.2625 37.2874 13.2388C37.3286 13.1975 37.3561 13.1425 37.3974 13.1013L37.6037 12.9088L39.5287 11.2725C36.6549 9.80127 33.4237 8.96252 29.9999 8.96252C18.3949 8.96252 8.9624 18.395 8.9624 30C8.9624 41.605 18.3949 51.0375 29.9999 51.0375C41.6049 51.0375 51.0374 41.605 51.0374 30C51.0374 27.91 50.7211 25.9025 50.1574 23.9913L47.8336 25.9713Z" fill="#79C8B0"/>
<path d="M47.8336 25.9713C48.1224 27.2638 48.2874 28.6113 48.2874 30C48.2874 40.0788 40.0924 48.2875 29.9999 48.2875C19.9212 48.2875 11.7124 40.0925 11.7124 30C11.7124 19.9075 19.9074 11.7125 29.9999 11.7125C32.5849 11.7125 35.0462 12.2625 37.2874 13.2388C37.3286 13.1975 37.3561 13.1425 37.3974 13.1013L37.6037 12.9088L39.5287 11.2725C36.6549 9.80127 33.4237 8.96252 29.9999 8.96252C18.3949 8.96252 8.9624 18.395 8.9624 30C8.9624 41.605 18.3949 51.0375 29.9999 51.0375C41.6049 51.0375 51.0374 41.605 51.0374 30C51.0374 27.91 50.7211 25.9025 50.1574 23.9913L47.8336 25.9713Z" fill="url(#paint2_linear_3293_57678)"/>
</g>
<defs>
<linearGradient id="paint0_linear_3293_57678" x1="20.6605" y1="27.3669" x2="41.2793" y2="27.5035" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57678" x1="37.9575" y1="16.9014" x2="50.2804" y2="16.9773" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57678" x1="9.4633" y1="26.4938" x2="51.7486" y2="26.9252" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<clipPath id="clip0_3293_57678">
<rect width="44" height="44" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>

     );
}
 
export default Icon_CoinSave;
