import React from "react";
import imgCheck from "../../../assets/siteManagement/images/check.svg"

function BtnSave(props) {
    function onClickFunc() {
        try {
            props.onClick();
        } catch { }
    }
    return (
        <div className={`h-full px-4
        flex items-center rounded-lg 
        cursor-pointer hover:opacity-60
        duration-300 ease-in-out`}
            style={{ background: 'linear-gradient(90.58deg, #1FA37C 1.6%, rgba(31, 163, 124, 0) 101.09%), #79C8B0' }}
            onClick={(e) => { onClickFunc() }}>
            <img className="w-3.5 h3.5 mr-2" src={imgCheck} />
            <p className={`text-white text-xs font-semibold`}>
                Save
            </p>
        </div>
    )
}

export default BtnSave;