import React, { useEffect, useState } from "react";
import BuildingContent from "./building-info-building-content";
import FloorContent from "./building-info-floor-content";
import PlaceContent from "./building-info-place-content";

function ContentBuildingInfo(props) {
    const [pageSelect, setPageSelect] = useState('building');
    const [siteId, setSiteId] = useState(null);
    const [buildingId, setBuildingId] = useState(null);
    const [floorId, setFloorId] = useState(null);

    useEffect(() => {
        setSiteId(props.siteId);
    }, [props.siteId])

    function onSelectItem(type, param) {
        if (type === 'building') {
            setBuildingId(param.Id);
            setPageSelect('floor');
        } else if (type === 'floor') {
            setFloorId(param.Id);
            setPageSelect('place');
        } else if (type === 'place') {

        }
    }
    function onBack(type) {
        if (type === 'floor') {
            setBuildingId(null);
            setPageSelect('building');
        } else if (type === 'place') {
            setFloorId(null)
            setPageSelect('floor');
        }
    }


    return (
        <div className={`w-full h-full p-4 rounded-lg shadow-content-box bg-white ${props.disable?'opacity-50':''}`}>
            {
                {
                    'building': <BuildingContent siteId={siteId} disable={props.disable}
                        onSelectItem={(e) => { onSelectItem('building', e) }} />,
                    'floor': <FloorContent siteId={siteId} buildingId={buildingId}
                        onSelectItem={(e) => { onSelectItem('floor', e) }}
                        onClickBack={(e) => { onBack('floor') }} />,
                    'place': <PlaceContent siteId={siteId} buildingId={buildingId} floorId={floorId}
                        onSelectItem={(e) => { onSelectItem('place', e) }}
                        onClickBack={(e) => { onBack('place') }} />,
                }[pageSelect]
            }
        </div>
    )
}

export default ContentBuildingInfo;