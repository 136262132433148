const Icon_Turbine = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M32.035 27.14C31.705 27.6213 31.3338 28.1713 30.9213 28.7763C31.375 29.0788 31.705 29.56 31.705 30.1375C31.705 31.045 30.9763 31.7738 30.0688 31.7738C29.7113 31.7738 29.395 31.65 29.12 31.43C28.79 31.9113 28.4738 32.3925 28.1713 32.8325C35.1563 37.535 43.0213 41.1925 44.0663 39.3913C45.125 37.5625 39.4738 32.145 32.035 27.14Z" fill="#6CCCDF"/>
<path d="M32.035 27.14C31.705 27.6213 31.3338 28.1713 30.9213 28.7763C31.375 29.0788 31.705 29.56 31.705 30.1375C31.705 31.045 30.9763 31.7738 30.0688 31.7738C29.7113 31.7738 29.395 31.65 29.12 31.43C28.79 31.9113 28.4738 32.3925 28.1713 32.8325C35.1563 37.535 43.0213 41.1925 44.0663 39.3913C45.125 37.5625 39.4738 32.145 32.035 27.14Z" fill="url(#paint0_linear_3293_57660)" fill-opacity="0.7"/>
<path d="M30.2612 13.17C28.1437 13.2938 26.7 20.98 26.5487 29.945C27.1125 29.9588 27.7587 29.9588 28.4737 29.9725C28.57 29.1475 29.23 28.515 30.0825 28.515C30.935 28.515 31.6362 29.1888 31.6912 30.0413C32.2962 30.055 32.8875 30.055 33.4237 30.0688C33.5612 21.64 32.3375 13.0463 30.2612 13.17Z" fill="#6CCCDF"/>
<path d="M30.2612 13.17C28.1437 13.2938 26.7 20.98 26.5487 29.945C27.1125 29.9588 27.7587 29.9588 28.4737 29.9725C28.57 29.1475 29.23 28.515 30.0825 28.515C30.935 28.515 31.6362 29.1888 31.6912 30.0413C32.2962 30.055 32.8875 30.055 33.4237 30.0688C33.5612 21.64 32.3375 13.0463 30.2612 13.17Z" fill="url(#paint1_linear_3293_57660)" fill-opacity="0.7"/>
<path d="M31.0175 31.4438C30.7425 31.6363 30.4263 31.7876 30.0688 31.7876C29.1613 31.7876 28.4325 31.0588 28.4325 30.1513C28.4325 29.6013 28.735 29.1201 29.1338 28.8313C28.7213 28.2401 28.3088 27.6763 27.9375 27.1538C21.1038 32.0763 14.9163 38.1538 16.25 39.7626C17.6113 41.3988 24.665 37.9751 31.9525 32.7501C31.6913 32.3651 31.3613 31.9388 31.0175 31.4438Z" fill="#6CCCDF"/>
<path d="M31.0175 31.4438C30.7425 31.6363 30.4263 31.7876 30.0688 31.7876C29.1613 31.7876 28.4325 31.0588 28.4325 30.1513C28.4325 29.6013 28.735 29.1201 29.1338 28.8313C28.7213 28.2401 28.3088 27.6763 27.9375 27.1538C21.1038 32.0763 14.9163 38.1538 16.25 39.7626C17.6113 41.3988 24.665 37.9751 31.9525 32.7501C31.6913 32.3651 31.3613 31.9388 31.0175 31.4438Z" fill="url(#paint2_linear_3293_57660)" fill-opacity="0.7"/>
<path d="M30.0825 26.6312C28.13 26.6312 26.5625 28.2125 26.5625 30.1512C26.5625 32.09 28.1437 33.6712 30.0825 33.6712C32.0212 33.6712 33.6025 32.09 33.6025 30.1512C33.6025 28.2125 32.035 26.6312 30.0825 26.6312ZM30.0825 31.7875C29.175 31.7875 28.4462 31.0587 28.4462 30.1512C28.4462 29.2437 29.175 28.515 30.0825 28.515C30.99 28.515 31.7188 29.2437 31.7188 30.1512C31.705 31.0587 30.9762 31.7875 30.0825 31.7875Z" fill="#6CCCDF"/>
<path d="M30.0825 26.6312C28.13 26.6312 26.5625 28.2125 26.5625 30.1512C26.5625 32.09 28.1437 33.6712 30.0825 33.6712C32.0212 33.6712 33.6025 32.09 33.6025 30.1512C33.6025 28.2125 32.035 26.6312 30.0825 26.6312ZM30.0825 31.7875C29.175 31.7875 28.4462 31.0587 28.4462 30.1512C28.4462 29.2437 29.175 28.515 30.0825 28.515C30.99 28.515 31.7188 29.2437 31.7188 30.1512C31.705 31.0587 30.9762 31.7875 30.0825 31.7875Z" fill="url(#paint3_linear_3293_57660)" fill-opacity="0.7"/>
<path d="M31.6776 36.3525L30.0688 35.225L28.4601 36.3525C28.4601 36.3525 26.9613 42.1 26.9613 46.1287C28.3226 46.4312 31.2651 46.5137 33.1351 46.1287C33.1626 41.44 31.6776 36.3525 31.6776 36.3525Z" fill="#6CCCDF"/>
<path d="M31.6776 36.3525L30.0688 35.225L28.4601 36.3525C28.4601 36.3525 26.9613 42.1 26.9613 46.1287C28.3226 46.4312 31.2651 46.5137 33.1351 46.1287C33.1626 41.44 31.6776 36.3525 31.6776 36.3525Z" fill="url(#paint4_linear_3293_57660)" fill-opacity="0.7"/>
<path d="M29.9449 50.8175C18.4637 50.8175 9.12744 41.4812 9.12744 30C9.12744 18.5187 18.4637 9.1825 29.9449 9.1825C41.4262 9.1825 50.7487 18.5187 50.7487 30C50.7487 41.4812 41.4124 50.8175 29.9449 50.8175ZM29.9449 11.6575C19.8249 11.6575 11.6024 19.8937 11.6024 30C11.6024 40.1062 19.8249 48.3425 29.9449 48.3425C40.0512 48.3425 48.2874 40.12 48.2874 30C48.2874 19.88 40.0512 11.6575 29.9449 11.6575Z" fill="#6CCCDF"/>
<path d="M29.9449 50.8175C18.4637 50.8175 9.12744 41.4812 9.12744 30C9.12744 18.5187 18.4637 9.1825 29.9449 9.1825C41.4262 9.1825 50.7487 18.5187 50.7487 30C50.7487 41.4812 41.4124 50.8175 29.9449 50.8175ZM29.9449 11.6575C19.8249 11.6575 11.6024 19.8937 11.6024 30C11.6024 40.1062 19.8249 48.3425 29.9449 48.3425C40.0512 48.3425 48.2874 40.12 48.2874 30C48.2874 19.88 40.0512 11.6575 29.9449 11.6575Z" fill="url(#paint5_linear_3293_57660)" fill-opacity="0.7"/>
<defs>
<linearGradient id="paint0_linear_3293_57660" x1="29.3158" y1="26.0801" x2="44.2509" y2="26.6535" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57660" x1="27.0405" y1="11.7604" x2="33.4671" y2="11.8402" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57660" x1="17.2021" y1="26.0688" x2="32.0102" y2="26.6193" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57660" x1="27.0654" y1="26.0446" x2="33.631" y2="26.2447" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint4_linear_3293_57660" x1="27.4023" y1="34.2948" x2="33.1642" y2="34.3919" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
<linearGradient id="paint5_linear_3293_57660" x1="12.1004" y1="5.71296" x2="50.9175" y2="6.89556" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Turbine;