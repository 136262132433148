const Warning_Icon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.05 14.8998H2.95C1.45 14.8998 0.499998 13.3498 1.2 11.9998L6.25 2.1998C7 0.749805 9.05 0.749805 9.75 2.1998L14.85 11.9998C15.5 13.2998 14.55 14.8998 13.05 14.8998Z" fill="#FFA800"/>
<path d="M8 11.1499C7.8 11.1499 7.6 10.9999 7.6 10.7499C7.6 9.9999 7.55 9.3499 7.5 8.9499C7.45 8.4499 7.3 7.8999 7.1 7.2999C7.05 7.0999 7 6.9499 7 6.7999C7 6.6499 6.95 6.4999 6.95 6.2499C6.95 5.8499 7.05 5.5499 7.25 5.2499C7.5 4.9999 7.7 4.8999 8 4.8999C8.3 4.8999 8.5 5.0499 8.7 5.2999C8.9 5.5499 9 5.8999 9 6.2999C9 6.5499 9 6.6999 8.95 6.8499C8.95 6.9999 8.9 7.1499 8.85 7.3499C8.65 7.9499 8.55 8.4499 8.45 8.9999C8.4 9.4499 8.35 10.0499 8.35 10.8499C8.4 10.9499 8.2 11.1499 8 11.1499ZM8 11.4499C8.25 11.4499 8.45 11.5499 8.65 11.6999C8.8 11.8499 8.9 12.0999 8.9 12.3499C8.9 12.5999 8.8 12.7999 8.65 12.9999C8.5 13.1499 8.25 13.2499 8 13.2499C7.75 13.2499 7.55 13.1499 7.35 12.9999C7.2 12.8499 7.1 12.5999 7.1 12.3499C7.1 12.0999 7.2 11.8999 7.35 11.6999C7.55 11.5499 7.75 11.4499 8 11.4499Z" fill="white"/>
</svg>

    )
}

export default Warning_Icon;