export const ScatteredClouds_S = ({style}) => {
    return ( 
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1570_78166)">
<path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M13.7633 2.592C12.231 2.6445 10.9418 3.66525 10.4895 5.061C10.4265 5.25375 10.473 5.466 10.611 5.61525C10.7483 5.7645 10.956 5.82825 11.1533 5.78175C11.5425 5.69025 11.949 5.6415 12.366 5.6415C14.7278 5.6415 16.7288 7.197 17.3993 9.339C17.4728 9.57375 17.6903 9.7335 17.9363 9.7335H20.109C21.0765 9.7335 21.813 9.38625 22.3133 8.84925C22.8113 8.3145 23.079 7.5795 23.079 6.7635C23.079 5.28525 21.999 4.05975 20.5853 3.83175C20.1803 2.17125 18.6818 0.9375 16.8975 0.9375C15.597 0.9375 14.4473 1.593 13.7633 2.592Z" fill="url(#paint0_linear_1570_78166)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.0677 14.577C23.0677 12.0308 21.129 9.93751 18.6472 9.69376C18.0765 6.74551 15.48 4.51651 12.366 4.51651C10.0912 4.51651 8.091 5.70676 6.957 7.49851C6.95025 7.49776 6.9435 7.49776 6.936 7.49776C3.6285 7.49776 0.942749 10.1835 0.942749 13.491C0.942749 14.64 1.26375 16.2248 2.2065 17.4698C2.298 17.5898 2.43375 17.6685 2.58375 17.688C2.73375 17.7068 2.88525 17.6648 3.00375 17.571C3.42075 17.241 3.948 17.0438 4.52025 17.0438C4.57125 17.0438 4.6215 17.0453 4.67175 17.0483C4.887 17.0618 5.091 16.95 5.19675 16.7618C5.65425 15.9443 6.528 15.3915 7.53 15.3915C8.898 15.3915 10.0282 16.4228 10.1827 17.7503C10.2157 18.0338 10.4557 18.2475 10.7415 18.2475C11.4345 18.2475 12.0382 18.63 12.354 19.1948C12.453 19.3733 12.6412 19.4835 12.8452 19.4835H18.1612C19.7603 19.4835 20.9805 18.9195 21.807 18.0323C22.6312 17.1465 23.0677 15.9278 23.0677 14.577Z" fill="url(#paint1_linear_1570_78166)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.39576 15.921C2.48251 15.9863 0.948761 17.5605 0.948761 19.4903C0.948761 20.8088 1.65301 23.0625 4.32151 23.0625C10.7415 23.0625 10.7415 23.0625 10.7415 23.0625C11.709 23.0625 12.4455 22.7153 12.9458 22.1783C13.443 21.6435 13.7115 20.9085 13.7115 20.0925C13.7115 18.6143 12.6315 17.388 11.2178 17.1608C10.8128 15.5003 9.31426 14.2665 7.53001 14.2665C6.22876 14.2665 5.07976 14.922 4.39576 15.921Z" fill="url(#paint2_linear_1570_78166)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1570_78166" x1="16.7675" y1="6.12188" x2="16.7675" y2="17.4567" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78166" x1="12" y1="13.3381" x2="12" y2="32.625" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78166" x1="7.87501" y1="19.7047" x2="7.87501" y2="37.125" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<clipPath id="clip0_1570_78166">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>

        


     );
}

export const ScatteredClouds_N = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1570_78165)">
<path opacity="0.5" fillRule="evenodd" clipRule="evenodd" d="M13.7633 2.592C12.231 2.6445 10.9418 3.66525 10.4895 5.061C10.4265 5.25375 10.473 5.466 10.611 5.61525C10.7483 5.7645 10.956 5.82825 11.1533 5.78175C11.5425 5.69025 11.949 5.6415 12.366 5.6415C14.7278 5.6415 16.7288 7.197 17.3993 9.339C17.4728 9.57375 17.6903 9.7335 17.9363 9.7335H20.109C21.0765 9.7335 21.813 9.38625 22.3133 8.84925C22.8113 8.3145 23.079 7.5795 23.079 6.7635C23.079 5.28525 21.999 4.05975 20.5853 3.83175C20.1803 2.17125 18.6818 0.9375 16.8975 0.9375C15.597 0.9375 14.4473 1.593 13.7633 2.592Z" fill="url(#paint0_linear_1570_78165)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.0677 14.577C23.0677 12.0308 21.129 9.93751 18.6472 9.69376C18.0765 6.74551 15.48 4.51651 12.366 4.51651C10.0912 4.51651 8.091 5.70676 6.957 7.49851C6.95025 7.49776 6.9435 7.49776 6.936 7.49776C3.6285 7.49776 0.942749 10.1835 0.942749 13.491C0.942749 14.64 1.26375 16.2248 2.2065 17.4698C2.298 17.5898 2.43375 17.6685 2.58375 17.688C2.73375 17.7068 2.88525 17.6648 3.00375 17.571C3.42075 17.241 3.948 17.0438 4.52025 17.0438C4.57125 17.0438 4.6215 17.0453 4.67175 17.0483C4.887 17.0618 5.091 16.95 5.19675 16.7618C5.65425 15.9443 6.528 15.3915 7.53 15.3915C8.898 15.3915 10.0282 16.4228 10.1827 17.7503C10.2157 18.0338 10.4557 18.2475 10.7415 18.2475C11.4345 18.2475 12.0382 18.63 12.354 19.1948C12.453 19.3733 12.6412 19.4835 12.8452 19.4835H18.1612C19.7603 19.4835 20.9805 18.9195 21.807 18.0323C22.6312 17.1465 23.0677 15.9278 23.0677 14.577Z" fill="url(#paint1_linear_1570_78165)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.39576 15.921C2.48251 15.9863 0.948761 17.5605 0.948761 19.4903C0.948761 20.8088 1.65301 23.0625 4.32151 23.0625C10.7415 23.0625 10.7415 23.0625 10.7415 23.0625C11.709 23.0625 12.4455 22.7153 12.9458 22.1783C13.443 21.6435 13.7115 20.9085 13.7115 20.0925C13.7115 18.6143 12.6315 17.388 11.2178 17.1608C10.8128 15.5003 9.31426 14.2665 7.53001 14.2665C6.22876 14.2665 5.07976 14.922 4.39576 15.921Z" fill="url(#paint2_linear_1570_78165)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1570_78165" x1="16.7675" y1="6.12188" x2="16.7675" y2="17.4567" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78165" x1="12" y1="13.3381" x2="12" y2="32.625" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78165" x1="7.87501" y1="19.7047" x2="7.87501" y2="37.125" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<clipPath id="clip0_1570_78165">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>



     );
}
 
