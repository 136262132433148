import axios from "../../shared/axios";
import { API_URL, DOMAIN_URL,WEB_URL } from "../../shared/config";
import { SignInAndRegisterGoogle } from "../../api/userInfo";
import { AuthAction } from "./auth_context";
import Cookies from "js-cookie";

export const loginSuccess = (data) => {
  Cookies.set("sessionid", data.sessionid);
  Cookies.set("csrfToken", data.csrftoken,{ expires: 365});
  //TODO: to refactor
  // console.log("we got here, login success", data)
  window.location.replace(WEB_URL);
  // window.location.reload();

  return {
    type: AuthAction.LOGIN_SUCCESS,
    data,
  };
};

export const loginFailed = (error) => {
  return {
    type: AuthAction.LOGIN_FAILED,
    error,
  };
};

export const login = async (dispatch, data) => {
  // Cookies.remove('sessionid');
  // Cookies.remove('csrfToken');
  dispatch({ type: AuthAction.LOGIN_REQUEST });
  try {
    const response = await axios.post(`${API_URL}/account/login/`, data);
    dispatch(loginSuccess(response.data));
  } catch (error) {
    dispatch(loginFailed(error));
  }
};

export const googleSignIn = async (dispatch, request) => {
  // Cookies.remove('sessionid');
  // Cookies.remove('csrfToken');
  dispatch({ type: AuthAction.LOGIN_REQUEST });
  try {
    const { data } = await SignInAndRegisterGoogle(request);
    dispatch(loginSuccess(data));
  } catch (error) {
    dispatch(loginFailed(error));
  }
};

export const getIsAuthenticatedSuccess = (data) => {
  return {
    type: AuthAction.GET_AUTHENTICATED_SUCCESS,
    data,
  };
};

export const getIsAuthenticatedFailed = (error) => {
  return {
    type: AuthAction.GET_AUTHENTICATED_FAILED,
    error,
  };
};

export const getIsAuthenticated = async (dispatch) => {
  try {
    const response = await axios.get(`${API_URL}/account/is-authenticated/`);
    if (response.data.is_authenticated) {
      dispatch(getIsAuthenticatedSuccess(response.data));
    } else {
      dispatch(getIsAuthenticatedFailed(response.data));
    }
  } catch (error) {
    dispatch(getIsAuthenticatedFailed(error));
  }
};

export const getLogOutSuccess = (data, cb) => {
  Cookies.remove("sessionid");
  Cookies.remove("csrfToken");
  localStorage.clear();
  window.location.reload();
  return {
    type: AuthAction.GET_LOG_OUT_SUCCESS,
    data,
  };
};

export const getLogOutFailed = (error) => {
  return {
    type: AuthAction.GET_LOG_OUT_FAILED,
    error,
  };
};

export const getLogOut = async (dispatch) => {
  dispatch({ type: AuthAction.GET_LOG_OUT_REQUEST });
  try {
    const response = await axios.get(`${API_URL}/account/logout/`);
    dispatch(getLogOutSuccess(response.data));
  } catch (error) {
    dispatch(getLogOutFailed(error));
  }
};
