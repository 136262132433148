const Cooler_3d_Icon = ({style}) => {
    return ( <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M321.27 99.47L321.33 389.47L235.36 361.53L235.29 71.53L321.27 99.47Z" fill="#CCCCCC"/>
    <path d="M235.29 71.53L235.35 361.53L39.0097 399.67L38.9497 109.67C104.4 96.95 169.84 84.24 235.29 71.53Z" fill="#CCCCCC"/>
    <path d="M321.27 99.47L235.3 71.54L38.9497 109.66L124.92 137.59C190.37 124.89 255.82 112.18 321.27 99.47Z" fill="#E6E6E6"/>
    <path d="M235.36 361.53L321.33 389.46L124.99 427.6L39.0195 399.67C104.46 386.96 169.91 374.25 235.36 361.53Z" fill="#CCCCCC"/>
    <path d="M321.33 389.47L321.27 99.47L124.92 137.6L124.98 427.6C190.44 414.89 255.88 402.18 321.33 389.47Z" fill="#B3B3B3"/>
    <path d="M124.99 427.6L124.92 137.6L38.9497 109.66L39.0097 399.67L124.99 427.6Z" fill="#CCCCCC"/>
    <path d="M287.16 62.1499L287.17 95.4399L235.32 77.9699L235.3 44.6899L287.16 62.1499Z" fill="#CCCCCC"/>
    <path d="M235.3 44.6899L235.32 77.9699L183.43 88.0499L183.41 54.7699C200.71 51.4099 218.01 48.0499 235.3 44.6899Z" fill="#CCCCCC"/>
    <path d="M287.16 62.1499L235.3 44.6899L183.41 54.7699L235.26 72.2299C217.69 75.6399 241.11 71.0999 287.16 62.1499Z" fill="#E6E6E6"/>
    <path d="M235.32 77.97L287.17 95.43L235.28 105.51L183.43 88.05C200.73 84.69 218.02 81.33 235.32 77.97Z" fill="#CCCCCC"/>
    <path d="M287.17 95.4399L287.15 62.1599L235.26 72.2399L235.28 105.52C252.58 102.16 269.87 98.7999 287.17 95.4399Z" fill="#B3B3B3"/>
    <path d="M235.28 105.52L235.26 72.23L183.41 54.77L183.43 88.05L235.28 105.52Z" fill="#CCCCCC"/>
    <path d="M103.72 166.85L103.69 159.94L55.8999 144.42L55.9399 151.32L103.72 166.85Z" fill="#999999"/>
    <path d="M103.72 180.83L103.69 173.92L55.8999 158.4L55.9399 165.3L103.72 180.83Z" fill="#999999"/>
    <path d="M103.72 194.44L103.69 187.54L55.8999 172.01L55.9399 178.92L103.72 194.44Z" fill="#999999"/>
    <path d="M228.31 85.4999L228.28 79.6099L187.54 66.3699L187.57 72.2599L228.31 85.4999Z" fill="#999999"/>
    <path d="M228.31 95.58L228.28 89.69L187.54 76.45L187.57 82.34L228.31 95.58Z" fill="#999999"/>
    <path d="M71.5649 391.737C77.1305 390.459 78.979 377.825 75.6937 363.518C72.4083 349.211 65.2333 338.649 59.6677 339.927C54.1021 341.205 52.2536 353.839 55.5389 368.146C58.8243 382.453 65.9993 393.015 71.5649 391.737Z" fill="black"/>
    <path d="M41.8999 332.47C35.2799 337.86 25.4299 350.23 29.6999 374.47C36.2199 411.4 59.0599 407.04 59.0599 407.04C59.0599 407.04 71.0499 400.39 69.3399 392.29C64.5599 387.83 60.1199 380.96 57.3999 370.38C54.1499 357.72 56.0499 347.6 59.5999 339.96C53.5599 332.37 44.9399 329.99 41.8999 332.47Z" fill="url(#paint0_linear_4844_75373)"/>
    <path d="M44.6001 323.25L73.1801 319.22C73.1801 319.22 99.0601 320.14 105.33 361.46C111.87 404.6 88.9001 409.53 88.9001 409.53L56.6901 415.72C56.6901 415.72 81.2301 408.19 77.8801 368.75C74.5301 329.31 44.6001 323.25 44.6001 323.25Z" fill="url(#paint1_linear_4844_75373)"/>
    <path d="M78.1899 365.3C74.1399 340.87 56.8999 321.51 44.3099 323.21C28.5799 325.33 18.5299 347.29 21.9499 372.83C25.3699 398.37 40.9199 417.57 56.6899 415.71C72.4499 413.85 82.1499 389.15 78.1899 365.3ZM55.2099 405.96C44.1099 407.27 32.9499 392 30.2899 371.85C27.6299 351.7 34.4999 334.51 45.5699 332.99C54.4299 331.78 66.7799 347.19 69.8799 366.47C72.9099 385.29 66.3199 404.65 55.2099 405.96Z" fill="#999999"/>
    <path d="M153.35 149.16L392.79 101.15C392.79 101.15 424.33 102.27 431.97 152.62C439.95 205.19 411.95 211.2 411.95 211.2L168.09 261.84C168.09 261.84 197.99 252.67 193.91 204.6C189.83 156.53 153.35 149.16 153.35 149.16Z" fill="url(#paint2_linear_4844_75373)"/>
    <path d="M195.06 200.41C190.12 170.64 169.12 147.04 153.78 149.11C134.61 151.7 122.36 178.45 126.53 209.58C130.69 240.71 149.65 264.1 168.86 261.83C188.07 259.57 199.88 229.47 195.06 200.41Z" fill="#48E5D2"/>
    <path d="M111.99 192.22C109.16 194.53 106.08 199.4 107.91 209.77C110.7 225.57 120.47 223.7 120.47 223.7C120.47 223.7 148.9 215.05 148.16 211.59C146.12 209.68 144.21 206.74 143.05 202.22C141.66 196.8 142.47 192.48 143.99 189.2C141.41 185.96 113.3 191.16 111.99 192.22Z" fill="url(#paint3_linear_4844_75373)"/>
    <path d="M113.56 187.81L145.2 181.72C145.2 181.72 156.27 182.11 158.95 199.79C161.75 218.25 151.92 220.36 151.92 220.36L118.72 227.37C118.72 227.37 129.22 224.15 127.79 207.28C126.37 190.4 113.56 187.81 113.56 187.81Z" fill="url(#paint4_linear_4844_75373)"/>
    <path d="M128.21 205.81C126.48 195.36 119.1 187.07 113.72 187.8C106.99 188.71 102.69 198.1 104.15 209.03C105.61 219.96 112.27 228.17 119.01 227.38C125.75 226.58 129.9 216.01 128.21 205.81ZM118.38 223.2C113.63 223.76 108.86 217.23 107.72 208.61C106.58 199.99 109.52 192.64 114.26 191.99C118.05 191.47 123.33 198.07 124.66 206.31C125.95 214.36 123.13 222.64 118.38 223.2Z" fill="#48E5D2"/>
    <path d="M145 201C142.17 203.31 139.09 208.18 140.92 218.55C143.71 234.35 153.48 232.48 153.48 232.48C153.48 232.48 181.91 223.83 181.17 220.37C179.13 218.46 177.22 215.52 176.06 211C174.67 205.58 175.48 201.26 177 197.98C174.41 194.73 146.3 199.94 145 201Z" fill="url(#paint5_linear_4844_75373)"/>
    <path d="M146.56 196.59L178.2 190.5C178.2 190.5 189.27 190.89 191.95 208.57C194.75 227.03 184.92 229.14 184.92 229.14L151.72 236.15C151.72 236.15 162.22 232.93 160.79 216.06C159.37 199.18 146.56 196.59 146.56 196.59Z" fill="url(#paint6_linear_4844_75373)"/>
    <path d="M161.21 214.58C159.48 204.13 152.1 195.84 146.72 196.57C139.99 197.48 135.69 206.87 137.15 217.8C138.61 228.73 145.27 236.94 152.01 236.15C158.75 235.35 162.9 224.78 161.21 214.58ZM151.38 231.97C146.63 232.53 141.86 226 140.72 217.38C139.58 208.76 142.52 201.41 147.26 200.76C151.05 200.24 156.33 206.84 157.66 215.08C158.95 223.13 156.13 231.41 151.38 231.97Z" fill="#48E5D2"/>
    <path d="M121.64 269.91L414.53 210.08C414.53 210.08 464.5 211.86 476.6 291.63C489.24 374.93 444.88 384.45 444.88 384.45L144.98 448.44C144.98 448.44 192.36 433.91 185.89 357.75C179.42 281.59 121.64 269.91 121.64 269.91Z" fill="url(#paint7_linear_4844_75373)"/>
    <path d="M187.72 351.12C179.89 303.95 146.62 266.56 122.31 269.84C91.9299 273.94 72.5299 316.33 79.1299 365.65C85.7299 414.97 115.76 452.04 146.2 448.45C176.65 444.86 195.36 397.17 187.72 351.12Z" fill="#61C5F2"/>
    <path d="M427.53 308.15L427.56 303.81L457.6 294.05L457.58 298.39L427.53 308.15Z" fill="#0071BC"/>
    <path d="M427.53 316.94L427.56 312.6L457.6 302.84L457.58 307.18L427.53 316.94Z" fill="#0071BC"/>
    <path d="M427.53 325.5L427.56 321.16L457.6 311.4L457.58 315.74L427.53 325.5Z" fill="#0071BC"/>
    <defs>
    <linearGradient id="paint0_linear_4844_75373" x1="57.0796" y1="397.071" x2="36.3111" y2="332.472" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint1_linear_4844_75373" x1="68.5876" y1="325.442" x2="85.2542" y2="409.898" gradientUnits="userSpaceOnUse">
    <stop stop-color="#999999"/>
    <stop offset="1"/>
    </linearGradient>
    <linearGradient id="paint2_linear_4844_75373" x1="285.028" y1="130.899" x2="305.434" y2="234.307" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint3_linear_4844_75373" x1="130.472" y1="218.316" x2="123.92" y2="191.202" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint4_linear_4844_75373" x1="133.588" y1="186.822" x2="140.706" y2="222.892" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint5_linear_4844_75373" x1="163.473" y1="227.09" x2="156.92" y2="199.977" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint6_linear_4844_75373" x1="166.589" y1="195.597" x2="173.707" y2="231.667" gradientUnits="userSpaceOnUse">
    <stop stop-color="#00C7B9"/>
    <stop offset="0.223" stop-color="#00C4B6"/>
    <stop offset="0.4033" stop-color="#00BAAD"/>
    <stop offset="0.5687" stop-color="#00A99D"/>
    <stop offset="0.7251" stop-color="#009187"/>
    <stop offset="0.8738" stop-color="#00726A"/>
    <stop offset="1" stop-color="#00524C"/>
    </linearGradient>
    <linearGradient id="paint7_linear_4844_75373" x1="286.954" y1="248.765" x2="319.432" y2="413.344" gradientUnits="userSpaceOnUse">
    <stop stop-color="#29ABE2"/>
    <stop offset="0.2524" stop-color="#27A8DF"/>
    <stop offset="0.4563" stop-color="#239ED5"/>
    <stop offset="0.6434" stop-color="#1B8DC4"/>
    <stop offset="0.8203" stop-color="#0F75AC"/>
    <stop offset="0.9886" stop-color="#01568E"/>
    <stop offset="1" stop-color="#00548C"/>
    </linearGradient>
    </defs>
    </svg>
     );
}
 
export default Cooler_3d_Icon;