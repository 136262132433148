import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
// import searchIcon from "../../assets/images/ic_search.png";
// import notificationIcon from "../../assets/images/ic_notification_black.png";
// import messageIcon from "../../assets/images/ic_message_open.png"
// import dashboardIcon from "../../assets/images/ic_dashboard.png"
import MenuHeader from './MenuHeader'
// import { useLoggedInUser } from "../../api/userInfo";

const Header = ({ pageTitle = "Demp",showSubMenu=true, children }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation('main');
    let menuList = [
        {
            title: t('main:sider_bar.dashboard'),
            path: `/plant/dashboard/${localStorage.getItem('dashboardId')}`,
        }, {
            title: t('main:sub_header.map'),
            path: "/site"
        }, {
            title: t('main:sub_header.devicelist'),
            path: "/device-management"
        }, 
        // {
        //     title: t('main:sub_header.ai_control'),
        //     path: "/"
        // }, {
        //     title: t('main:sub_header.report'),
        //     path: "/report"
        // }, 
        {
            title: t('main:sub_header.analytic'),
            path: "/analytics"
        }

        // {
        //   title: t('main:sider_bar.devices'),
        //   path: "/devices",
        // },
        // {
        //   title: t('main:sider_bar.analytic'),
        //   path: "/analytic",
        // },

        // {
        //   title: t('main:sider_bar.report'),
        //   path: "/report",
        // },
    ];

    const subStringMenuPath = (path) => {
        if (path.indexOf("/", 2) === -1) {
            return path
        } else {
            return path.substring(0, path.indexOf("/", 2))
        }
    };
    const activeMenu =
        location.pathname === "/dashboard"
            ? "/dashboard"
            : `/${location.pathname.split("/")[1]}`;
    const handleListItemClick = (path) => (event) => {

        navigate(path)
    };

    return (
        <>

        <section className="flex flex-col ">
            
            <div className="bg-white demp-header">
                <div className="header-container">
                        <div className="header-inner-container" >
                            
                                <div className="">
                                    <h1 className="header">{pageTitle}</h1>
                                </div>
                                

                                <div className="flex flex-row items-center gap-4 w-[30%]">
                                    <LineBreak />
                                    {children}
                                    <LineBreak />
                                </div>
                            
                            
                           
                            <div></div>
                            <div></div>
                            <div></div>

                        
                            <div className="">
                                
                                <MenuHeader />
                            </div>
                        </div>
                </div>
            </div>
            

            <div className="demp-sub-header">
                <div className="subheader-container">
                    {menuList.map((menu, index) => (
                        <div key={menu.title} className={(activeMenu === subStringMenuPath(menu.path)) ? "active-div-wrapper" : "div-wrapper"}>
                            <Link to={menu?.path}  className={(activeMenu === subStringMenuPath(menu.path)) ? "active-text-wrapper" : "text-wrapper"}>{menu?.title}</Link>
                        </div>
                    ))}
                </div>
            </div>       
        </section>

           

        </>
    );
};

export default Header;


const LineBreak = () => {
    return(
        <svg width="2" height="32" viewBox="0 0 2 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <line x1="1.3252" y1="2.18557e-08" x2="1.32519" y2="32" stroke="#E5E5E5"/>
                                </svg>
    )}
