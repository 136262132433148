const Fair_Icon_T = ({style}) => {
    return ( 
        <svg className={style} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="url(#paint0_linear_1078_26767)"/>
<path d="M13 21.9373C11.3439 21.9421 9.7193 21.4847 8.30899 20.6165C6.89869 19.7483 5.75861 18.5038 5.01703 17.0231C4.9199 16.8307 4.90316 16.6077 4.97049 16.403C5.03781 16.1983 5.18369 16.0287 5.37603 15.9316C5.56837 15.8345 5.79141 15.8178 5.9961 15.8851C6.20078 15.9524 6.37033 16.0983 6.46746 16.2906C7.07734 17.4999 8.01092 18.5161 9.16423 19.2262C10.3175 19.9362 11.6453 20.3122 12.9997 20.3123C14.354 20.3123 15.6818 19.9364 16.8352 19.2264C17.9885 18.5164 18.9222 17.5003 19.5321 16.291C19.58 16.1954 19.6462 16.1101 19.7271 16.0401C19.808 15.9702 19.9019 15.9168 20.0034 15.8832C20.1049 15.8496 20.2121 15.8364 20.3187 15.8443C20.4254 15.8522 20.5294 15.8811 20.6249 15.9293C20.7204 15.9775 20.8054 16.044 20.8751 16.1252C20.9447 16.2063 20.9977 16.3004 21.0309 16.402C21.0642 16.5037 21.077 16.6109 21.0687 16.7175C21.0604 16.8242 21.0311 16.9281 20.9826 17.0234C20.2409 18.504 19.1009 19.7483 17.6906 20.6165C16.2804 21.4846 14.656 21.942 13 21.9373Z" fill="#2C4866"/>
<path d="M21.125 9.54688C21.125 8.20068 20.0337 7.10938 18.6875 7.10938C17.3413 7.10938 16.25 8.20068 16.25 9.54688V11.1719C16.25 12.5181 17.3413 13.6094 18.6875 13.6094C20.0337 13.6094 21.125 12.5181 21.125 11.1719V9.54688Z" fill="url(#paint1_linear_1078_26767)"/>
<path d="M19.2969 10.3594C19.8578 10.3594 20.3125 9.90466 20.3125 9.34375C20.3125 8.78284 19.8578 8.32812 19.2969 8.32812C18.736 8.32812 18.2812 8.78284 18.2812 9.34375C18.2812 9.90466 18.736 10.3594 19.2969 10.3594Z" fill="#FFEFDC"/>
<path d="M9.75 9.54688C9.75 8.20068 8.65869 7.10938 7.3125 7.10938C5.96631 7.10938 4.875 8.20068 4.875 9.54688V11.1719C4.875 12.5181 5.96631 13.6094 7.3125 13.6094C8.65869 13.6094 9.75 12.5181 9.75 11.1719V9.54688Z" fill="url(#paint2_linear_1078_26767)"/>
<path d="M7.92188 10.3594C8.48279 10.3594 8.9375 9.90466 8.9375 9.34375C8.9375 8.78284 8.48279 8.32812 7.92188 8.32812C7.36096 8.32812 6.90625 8.78284 6.90625 9.34375C6.90625 9.90466 7.36096 10.3594 7.92188 10.3594Z" fill="#FFEFDC"/>
<defs>
<linearGradient id="paint0_linear_1078_26767" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD359"/>
<stop offset="0.0702" stopColor="#FFCD4F"/>
<stop offset="0.2689" stopColor="#FFC037"/>
<stop offset="0.4806" stopColor="#FFB726"/>
<stop offset="0.7119" stopColor="#FFB21C"/>
<stop offset="1" stopColor="#FFB019"/>
</linearGradient>
<linearGradient id="paint1_linear_1078_26767" x1="16.5577" y1="8.22955" x2="20.8173" y2="12.4892" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
<linearGradient id="paint2_linear_1078_26767" x1="5.18268" y1="8.22955" x2="9.44232" y2="12.4892" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
</defs>
</svg>


     );
}
 
const Fair_Icon_F =({style}) =>{
    return (
        <svg className={style} width="19" height="18" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.39734 0C6.81509 0 5.26837 0.469191 3.95278 1.34824C2.63719 2.22729 1.61181 3.47672 1.00631 4.93853C0.400805 6.40034 0.242379 8.00887 0.55106 9.56072C0.859742 11.1126 1.62167 12.538 2.74049 13.6569C3.85931 14.7757 5.28477 15.5376 6.83662 15.8463C8.38847 16.155 9.997 15.9965 11.4588 15.391C12.9206 14.7855 14.17 13.7602 15.0491 12.4446C15.9281 11.129 16.3973 9.58225 16.3973 8C16.3949 5.87901 15.5513 3.84558 14.0515 2.34582C12.5518 0.84605 10.5183 0.00241846 8.39734 0ZM8.39734 15.0588C7.00124 15.0588 5.63649 14.6448 4.47567 13.8692C3.31485 13.0936 2.41011 11.9911 1.87584 10.7013C1.34157 9.41146 1.20179 7.99217 1.47415 6.62289C1.74652 5.25361 2.41881 3.99585 3.406 3.00866C4.39319 2.02146 5.65096 1.34918 7.02023 1.07681C8.38951 0.804443 9.80881 0.944232 11.0986 1.4785C12.3885 2.01276 13.4909 2.91751 14.2665 4.07833C15.0422 5.23914 15.4562 6.60389 15.4562 8C15.4541 9.87147 14.7097 11.6657 13.3864 12.989C12.063 14.3124 10.2688 15.0567 8.39734 15.0588Z" fill="current"/>
<path d="M12.4 9.46338C12.2883 9.40774 12.1591 9.3987 12.0408 9.43825C11.9224 9.4778 11.8246 9.5627 11.7688 9.67431C11.4571 10.3022 10.9763 10.8305 10.3805 11.1998C9.78477 11.5692 9.09772 11.7648 8.39677 11.7647C7.69582 11.7646 7.00882 11.5688 6.41316 11.1993C5.8175 10.8298 5.33685 10.3013 5.02535 9.67339C4.96882 9.56313 4.87109 9.4796 4.75337 9.44095C4.63564 9.4023 4.50743 9.41164 4.39655 9.46694C4.28566 9.52224 4.20107 9.61905 4.16112 9.73634C4.12117 9.85363 4.12909 9.98194 4.18316 10.0934C4.57274 10.8779 5.17355 11.5381 5.91796 11.9996C6.66237 12.4611 7.52083 12.7057 8.3967 12.7059C9.27258 12.706 10.1311 12.4616 10.8757 12.0003C11.6202 11.539 12.2212 10.879 12.611 10.0947C12.6667 9.98296 12.6758 9.85371 12.6362 9.73533C12.5966 9.61696 12.5117 9.51914 12.4 9.46338Z" fill="current"/>
<path d="M6.04444 7.99999C6.10624 8 6.16744 7.98783 6.22454 7.96419C6.28164 7.94054 6.33352 7.90588 6.37722 7.86218C6.42092 7.81848 6.45558 7.7666 6.47923 7.7095C6.50287 7.6524 6.51504 7.5912 6.51503 7.5294V6.11764C6.51503 5.99283 6.46545 5.87313 6.3772 5.78488C6.28894 5.69663 6.16925 5.64705 6.04444 5.64705C5.91963 5.64705 5.79994 5.69663 5.71169 5.78488C5.62343 5.87313 5.57385 5.99283 5.57385 6.11764V7.5294C5.57384 7.5912 5.58601 7.6524 5.60965 7.7095C5.6333 7.7666 5.66796 7.81848 5.71166 7.86218C5.75536 7.90588 5.80724 7.94054 5.86434 7.96419C5.92144 7.98783 5.98264 8 6.04444 7.99999Z" fill="current"/>
<path d="M10.7503 7.99999C10.8121 8 10.8733 7.98783 10.9303 7.96419C10.9874 7.94054 11.0393 7.90588 11.083 7.86218C11.1267 7.81848 11.1614 7.7666 11.185 7.7095C11.2087 7.6524 11.2208 7.5912 11.2208 7.5294V6.11764C11.2208 5.99283 11.1713 5.87313 11.083 5.78488C10.9948 5.69663 10.8751 5.64705 10.7503 5.64705C10.6254 5.64705 10.5057 5.69663 10.4175 5.78488C10.3292 5.87313 10.2797 5.99283 10.2797 6.11764V7.5294C10.2797 7.5912 10.2918 7.6524 10.3155 7.7095C10.3391 7.7666 10.3738 7.81848 10.4175 7.86218C10.4612 7.90588 10.5131 7.94054 10.5702 7.96419C10.6273 7.98783 10.6884 8 10.7503 7.99999Z" fill="current"/>
</svg>


    )
}

export {Fair_Icon_T,Fair_Icon_F}