const Icon_TreeSaved = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#F2F8F6"/>
<path d="M26.2507 22.5896V21.1673C27.6008 21.3451 29.6259 21.2562 31.1259 19.4783C33.4511 16.7227 32.626 12.278 32.626 12.278C32.626 12.278 29.2509 11.3891 26.8507 13.3447C25.8757 14.1448 25.3507 15.4782 25.1257 16.8115C24.9007 15.4782 24.3756 14.1448 23.4006 13.3447C21.0005 11.3891 17.6253 12.278 17.6253 12.278C17.6253 12.278 16.8003 16.7227 19.1254 19.4783C20.6255 21.2562 22.7256 21.3451 24.0006 21.1673V22.5896C21.3755 22.9452 20.3254 24.2786 20.2504 24.4564C19.8754 25.0786 19.9504 25.8786 20.4004 26.3231C20.6255 26.5009 20.8505 26.5898 21.0755 26.5898C21.3755 26.5898 21.7505 26.412 21.9005 26.0564C21.9005 26.0564 22.7256 25.0786 25.0507 25.0786C27.7508 25.0786 29.7009 28.5454 29.7009 28.6343C29.9259 28.9899 30.3009 29.2566 30.6759 29.2566C30.9009 29.2566 31.1259 29.1677 31.276 29.0788C31.801 28.6343 31.951 27.8343 31.576 27.212C31.651 27.0343 29.5509 23.3896 26.2507 22.5896Z" fill="#528ACC"/>
<path d="M35.9261 28.4566C35.7761 27.4787 35.1011 26.7676 34.3511 26.412C33.451 26.0564 32.401 26.412 31.5759 27.3009L31.5009 27.3898C30.8259 28.101 29.9258 29.1677 28.8758 29.7011C28.4258 28.8121 27.3007 26.8565 25.1256 26.8565H23.1005C22.8755 26.8565 22.5005 26.412 22.1255 25.9675C21.4504 25.0786 20.4754 23.923 18.8253 23.923H12.75C12.3 23.923 12 24.2786 12 24.8119C12 25.3453 12.3 25.7009 12.75 25.7009H18.8253C19.8004 25.7009 20.4004 26.412 21.0754 27.212C21.6755 27.9232 22.2755 28.6343 23.1005 28.6343H25.1256C26.2507 28.6343 26.9257 29.5233 27.3007 30.2344C27.2257 30.2344 27.2257 30.2344 27.1507 30.2344H22.1255C21.4504 30.2344 21.1504 29.8788 20.7004 29.2566C20.1754 28.6343 19.5754 27.8343 18.4503 27.8343C18.0003 27.8343 17.7003 28.1899 17.7003 28.7232C17.7003 29.2566 18.0003 29.6122 18.4503 29.6122C18.9003 29.6122 19.2003 29.8788 19.6504 30.5011C20.1754 31.1233 20.8504 32.0123 22.2005 32.0123H27.2257C29.4758 32.0123 31.4259 29.9677 32.551 28.7232L32.626 28.6343C33.076 28.1899 33.526 27.9232 33.976 28.101C34.276 28.1899 34.5011 28.4566 34.5761 28.8121C34.6511 29.1677 34.5011 29.4344 34.2761 29.7011C33.301 30.7678 30.2259 34.1457 27.0757 34.1457H20.6254C19.3503 34.1457 18.3003 33.3457 17.1752 32.5456C15.9002 31.6567 14.5501 30.6789 12.825 30.6789C12.375 30.6789 12.075 31.0345 12.075 31.5678C12.075 32.1012 12.375 32.4567 12.825 32.4567C14.1751 32.4567 15.2252 33.2568 16.4252 34.0568C17.7003 34.9457 18.9753 35.9236 20.6254 35.9236H27.0007C30.3009 35.9236 33.301 33.1679 35.1761 31.0344C35.8511 30.3233 36.1511 29.3455 35.9261 28.4566Z" fill="#528ACC"/>
</svg>

     );
}
 
export default Icon_TreeSaved;