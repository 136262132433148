const CarbonAvoid_Icon = ({style, stroke}) => {
  return (
    <svg className={style} 
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >  
      <path
        d="M23.52 28.55V26.77L26.46 22.25C26.68 21.91 26.88 21.59 27.09 21.29C27.29 20.98 27.44 20.66 27.57 20.34C27.7 19.99 27.76 19.65 27.76 19.3C27.76 18.95 27.69 18.69 27.54 18.51C27.39 18.33 27.2 18.25 26.97 18.25C26.69 18.25 26.49 18.32 26.33 18.48C26.18 18.63 26.08 18.83 26.02 19.08C25.96 19.33 25.93 19.62 25.93 19.93V20.53H23.47V19.87C23.47 19.14 23.59 18.5 23.82 17.93C24.05 17.36 24.42 16.91 24.93 16.59C25.44 16.27 26.1 16.09 26.92 16.09C28.02 16.09 28.85 16.38 29.42 16.95C29.98 17.52 30.27 18.32 30.27 19.33C30.27 19.83 30.2 20.28 30.04 20.7C29.88 21.12 29.67 21.53 29.43 21.94C29.17 22.33 28.89 22.76 28.6 23.2L26.36 26.55H29.96V28.54H23.53V28.55H23.52Z"
        fill={stroke}
      />
      <path
        d="M23.52 28.55V26.77L26.46 22.25C26.68 21.91 26.88 21.59 27.09 21.29C27.29 20.98 27.44 20.66 27.57 20.34C27.7 19.99 27.76 19.65 27.76 19.3C27.76 18.95 27.69 18.69 27.54 18.51C27.39 18.33 27.2 18.25 26.97 18.25C26.69 18.25 26.49 18.32 26.33 18.48C26.18 18.63 26.08 18.83 26.02 19.08C25.96 19.33 25.93 19.62 25.93 19.93V20.53H23.47V19.87C23.47 19.14 23.59 18.5 23.82 17.93C24.05 17.36 24.42 16.91 24.93 16.59C25.44 16.27 26.1 16.09 26.92 16.09C28.02 16.09 28.85 16.38 29.42 16.95C29.98 17.52 30.27 18.32 30.27 19.33C30.27 19.83 30.2 20.28 30.04 20.7C29.88 21.12 29.67 21.53 29.43 21.94C29.17 22.33 28.89 22.76 28.6 23.2L26.36 26.55H29.96V28.54H23.53V28.55H23.52Z"
        fill={stroke}
      />
      <path
        d="M16.46 11.06V7.89995C16.46 7.57995 16.49 7.26995 16.53 6.97995C16.59 6.68995 16.69 6.44995 16.88 6.25995C17.06 6.06995 17.32 5.97995 17.67 5.97995C18.05 5.97995 18.33 6.06995 18.5 6.25995C18.68 6.44995 18.79 6.67995 18.85 6.97995C18.91 7.26995 18.92 7.57995 18.92 7.89995V8.59995L21.95 5.56995C21.63 4.95995 21.2 4.42995 20.61 4.07995C19.89 3.65995 18.91 3.44995 17.69 3.44995C16.48 3.44995 15.5 3.64995 14.8 4.07995C14.08 4.49995 13.57 5.11995 13.27 5.90995C12.96 6.69995 12.8 7.65995 12.8 8.78995V14.75L16.46 11.06Z"
        fill={stroke}
      />
      <path
        d="M16.46 11.06V7.89995C16.46 7.57995 16.49 7.26995 16.53 6.97995C16.59 6.68995 16.69 6.44995 16.88 6.25995C17.06 6.06995 17.32 5.97995 17.67 5.97995C18.05 5.97995 18.33 6.06995 18.5 6.25995C18.68 6.44995 18.79 6.67995 18.85 6.97995C18.91 7.26995 18.92 7.57995 18.92 7.89995V8.59995L21.95 5.56995C21.63 4.95995 21.2 4.42995 20.61 4.07995C19.89 3.65995 18.91 3.44995 17.69 3.44995C16.48 3.44995 15.5 3.64995 14.8 4.07995C14.08 4.49995 13.57 5.11995 13.27 5.90995C12.96 6.69995 12.8 7.65995 12.8 8.78995V14.75L16.46 11.06Z"
        fill={stroke}
      />
      <path
        d="M16.8701 20.1399C17.1301 20.1699 17.3701 20.2099 17.6701 20.2099C18.9101 20.2099 19.8801 19.9899 20.5901 19.5699C21.3001 19.1499 21.8201 18.5199 22.1201 17.7099C22.4301 16.9099 22.5901 15.9399 22.5901 14.8299V14.3899L16.8701 20.1399Z"
        fill={stroke}
      />
      <path
        d="M16.8701 20.1399C17.1301 20.1699 17.3701 20.2099 17.6701 20.2099C18.9101 20.2099 19.8801 19.9899 20.5901 19.5699C21.3001 19.1499 21.8201 18.5199 22.1201 17.7099C22.4301 16.9099 22.5901 15.9399 22.5901 14.8299V14.3899L16.8701 20.1399Z"
        fill={stroke}
      />
      <path
        d="M3.40995 19.5499C4.12995 19.9999 5.10995 20.2199 6.32995 20.2199C6.69995 20.2199 7.04995 20.1899 7.36995 20.1599L10.94 16.5899C11 16.1999 11.04 15.7999 11.04 15.3599V13.9399H7.50995V15.6499C7.50995 15.9699 7.49995 16.2899 7.44995 16.5999C7.40995 16.9099 7.29995 17.1599 7.13995 17.3599C6.97995 17.5499 6.71995 17.6499 6.33995 17.6499C5.98995 17.6499 5.72995 17.5599 5.54995 17.3699C5.36995 17.1899 5.23995 16.9499 5.17995 16.6399C5.11995 16.3299 5.09995 16.0299 5.09995 15.6599V7.98994C5.09995 7.64994 5.12995 7.32994 5.16995 7.03994C5.20995 6.72994 5.32995 6.47994 5.50995 6.27994C5.68995 6.08994 5.95995 5.98994 6.32995 5.98994C6.71995 5.98994 6.98995 6.08994 7.15995 6.27994C7.31995 6.46994 7.41995 6.71994 7.44995 7.00994C7.48995 7.29994 7.49995 7.60994 7.49995 7.92994V9.56994H11.02V8.16994C11.02 7.24994 10.87 6.42994 10.6 5.70994C10.32 4.98994 9.83995 4.43994 9.16995 4.03994C8.49995 3.64994 7.54995 3.43994 6.32995 3.43994C5.09995 3.43994 4.11995 3.65994 3.40995 4.09994C2.68995 4.55994 2.17995 5.17994 1.88995 5.99994C1.59995 6.80994 1.44995 7.78994 1.44995 8.90994V14.7899C1.44995 15.8899 1.59995 16.8499 1.88995 17.6499C2.17995 18.4699 2.68995 19.0999 3.40995 19.5499Z"
        fill={stroke}
      />
      <path
        d="M3.40995 19.5499C4.12995 19.9999 5.10995 20.2199 6.32995 20.2199C6.69995 20.2199 7.04995 20.1899 7.36995 20.1599L10.94 16.5899C11 16.1999 11.04 15.7999 11.04 15.3599V13.9399H7.50995V15.6499C7.50995 15.9699 7.49995 16.2899 7.44995 16.5999C7.40995 16.9099 7.29995 17.1599 7.13995 17.3599C6.97995 17.5499 6.71995 17.6499 6.33995 17.6499C5.98995 17.6499 5.72995 17.5599 5.54995 17.3699C5.36995 17.1899 5.23995 16.9499 5.17995 16.6399C5.11995 16.3299 5.09995 16.0299 5.09995 15.6599V7.98994C5.09995 7.64994 5.12995 7.32994 5.16995 7.03994C5.20995 6.72994 5.32995 6.47994 5.50995 6.27994C5.68995 6.08994 5.95995 5.98994 6.32995 5.98994C6.71995 5.98994 6.98995 6.08994 7.15995 6.27994C7.31995 6.46994 7.41995 6.71994 7.44995 7.00994C7.48995 7.29994 7.49995 7.60994 7.49995 7.92994V9.56994H11.02V8.16994C11.02 7.24994 10.87 6.42994 10.6 5.70994C10.32 4.98994 9.83995 4.43994 9.16995 4.03994C8.49995 3.64994 7.54995 3.43994 6.32995 3.43994C5.09995 3.43994 4.11995 3.65994 3.40995 4.09994C2.68995 4.55994 2.17995 5.17994 1.88995 5.99994C1.59995 6.80994 1.44995 7.78994 1.44995 8.90994V14.7899C1.44995 15.8899 1.59995 16.8499 1.88995 17.6499C2.17995 18.4699 2.68995 19.0999 3.40995 19.5499Z"
        fill={stroke}
      />
      <path
        d="M4.52204 27.478C3.82599 26.7819 3.82599 25.6508 4.52204 24.9451L25.9451 3.52204C26.6411 2.82599 27.7722 2.82599 28.478 3.52204C29.174 4.2181 29.174 5.34919 28.478 6.05491L7.04524 27.478C6.34919 28.174 5.2181 28.174 4.52204 27.478Z"
        fill={stroke}
      />
      <path
        d="M4.52204 27.478C3.82599 26.7819 3.82599 25.6508 4.52204 24.9451L25.9451 3.52204C26.6411 2.82599 27.7722 2.82599 28.478 3.52204C29.174 4.2181 29.174 5.34919 28.478 6.05491L7.04524 27.478C6.34919 28.174 5.2181 28.174 4.52204 27.478Z"
        fill={stroke}
      />
      
    </svg>
  );
};

export default CarbonAvoid_Icon;
