import { useGetDevice } from "../../../api/userInfo"
import { DeviceFieldSetup } from "./deviceFieldSetup"
import RoomStateSetup from "./multipleFields/RoomStateSetup"
import MultipleFieldWithIconSetup from "./multipleFields/multiFieldWIthIcon"
import MultipleFieldSetup from "./multipleFields/multipleFieldSetup"
import {SingleFieldSetup, SingleFieldSetupV2} from "./singleFieldSetup"
import {SetupTitleOnly} from "./widgetTitleSetup"
import { WidgetTitleTypeSetup } from "./widgetTitleTypeSetup.js"

import { useWidgetSetupStore } from "../../../context/stores"
import {shallow} from 'zustand/shallow'
import { useEffect } from "react"
import { ChillerWidgetSetup } from "./chillerWidgetSetup"
import { SingleDeviceSetup } from "./singleDeviceSetup"

const GetSetupFields = ({submitForm, clearForm}) => {
    
    
    const selectedWidget = useWidgetSetupStore((state) => state?.selectedWidget)
    const [setupDetails, updateSetupDetails] = useWidgetSetupStore((state) => [state.setupDetails, state.updateSetupDetails],shallow )
    const [widgetIcon, updateWidgetIcon] = useWidgetSetupStore((state) => [state.widgetIcon, state.updateWidgetIcon],shallow )
    const [widgetTitle, updateWidgetTitle] = useWidgetSetupStore((state) => [state.widgetTitle, state.updateWidgetTitle],shallow )
    
   

    const clear_fields = () => {
        updateSetupDetails({})
        updateWidgetIcon('')
        updateWidgetTitle('')
    }
    
    
    let inputFields = {}
    switch (selectedWidget) {
        case "Solar Widget (Total)":
        case 'Solar Widget':
        case 'Cost Saved Widget':
        case 'Carbon Avoid Widget':
        case 'Standard Coal Saved Widget':
        case 'Potential Rec Earn Widget':
        case 'Equivalent Trees Planted Widget':
            
        case 'Total Value Widget':
        // case 'Performance Device Listed':
        case 'Gas Widget':
        case 'Performance Widget':
            return <SingleFieldSetup 
                        selectedWidget={selectedWidget} 
                        updateSetupDetails={updateSetupDetails}
                        setupDetails={setupDetails} 
                        updateWidgetIcon={updateWidgetIcon} 
                        
                        widgetIcon={widgetIcon}
                        submitForm={submitForm}
                        clearForm={clearForm}
                        />

        case 'Single Device Widget':
            return <SingleDeviceSetup 
                    selectedWidget={selectedWidget} 
                    updateSetupDetails={updateSetupDetails}
                    setupDetails={setupDetails} 
                    updateWidgetIcon={updateWidgetIcon} 
                    
                    widgetIcon={widgetIcon}
                    submitForm={submitForm}
                    clearForm={clearForm}
                    />

        case 'Circle Device Status Widget': 
        case 'Net Zero Widget': 
            return <SetupTitleOnly submitForm={submitForm} updateWidgetTitle={updateWidgetTitle} />

        case 'Battery Alarm Widget':
        case 'Rectifier Widget':
            return <DeviceFieldSetup 
                        updateWidgetTitle={updateWidgetTitle}
                        selectedWidget={selectedWidget} 
                        updateSetupDetails={updateSetupDetails}
                        setupDetails={setupDetails} 
                        updateWidgetIcon={updateWidgetIcon} 
                        
                        submitForm={submitForm}
                        clearForm={clearForm}
            />
            
        case 'Performance Device Listed':
            return <WidgetTitleTypeSetup 
                    updateSetupDetails={updateSetupDetails}
                    setupDetails={setupDetails}
                     
                    submitForm={submitForm} 
                    clearForm={clearForm} 
                    />
        case 'Current Status Widget':
            inputFields = {'title':'', 'device':'', 'unit':'', 'field':''}
            return <MultipleFieldSetup 
                        selectedWidget={selectedWidget} 
                        updateSetupDetails={updateSetupDetails}
                        setupDetails={setupDetails} 
                        updateWidgetIcon={updateWidgetIcon} 
                        
                        // widgetIcon={widgetIcon}
                        inputFields={inputFields}
                        submitForm={submitForm}
                        clearForm={clearForm}
                        />
                    
        case 'Line Chart':
        case 'Radar Chart':
            inputFields = {'title':'', 'device':'', 'unit':'', 'field':'', 'show_y_axis':true}
            return <MultipleFieldWithIconSetup
                        selectedWidget={selectedWidget} 
                        updateSetupDetails={updateSetupDetails}
                        setupDetails={setupDetails} 
                        updateWidgetIcon={updateWidgetIcon} 
                        
                        // widgetIcon={widgetIcon}
                        inputFields={inputFields}
                        submitForm={submitForm}
                        clearForm={clearForm}
                        />
        case 'Overview Widget':
            inputFields = {'title':'', 'device':'', 'unit':'', 'field':''}
            return <MultipleFieldWithIconSetup
                        selectedWidget={selectedWidget} 
                        updateSetupDetails={updateSetupDetails}
                        setupDetails={setupDetails} 
                        updateWidgetIcon={updateWidgetIcon} 
                        
                        // widgetIcon={widgetIcon}
                        inputFields={inputFields}
                        submitForm={submitForm}
                        clearForm={clearForm}
                        />
                    
        
            

        case 'Room State Widget':
            inputFields = {'title':'', 'device':'', 'unit':'', 'field':''}
            return <RoomStateSetup
                        selectedWidget={selectedWidget} 
                        updateSetupDetails={updateSetupDetails}
                        setupDetails={setupDetails} 
                        updateWidgetIcon={updateWidgetIcon}
                        updateWidgetTitle={updateWidgetTitle}
                        
                        // widgetIcon={widgetIcon}
                        inputFields={inputFields}
                        submitForm={submitForm}
                        clearForm={clearForm}
                        
                        />

        case 'Chiller Widget':
        case 'Chiller_1A Widget':
            return <ChillerWidgetSetup 
                updateWidgetTitle={updateWidgetTitle}
                selectedWidget={selectedWidget} 
                updateSetupDetails={updateSetupDetails}
                setupDetails={setupDetails} 
                updateWidgetIcon={updateWidgetIcon} 
                
                submitForm={submitForm}
                clearForm={clearForm}
            />
        
            

        default:
            return <div className="flex items-center mt-4 opacity-50 w-72"><p>This widget can not be configured at the moment. The setup functionality for this widget will be added soon</p></div>
    }
}
 
export default GetSetupFields;

