import React, { useEffect, useRef, useState } from "react";
import BtnEdit from "./btn-edit";
import CoreAPI from '../../../shared/services/core';

import BtnCancel from "./btn-cancel";
import BtnSave from "./btn-save";
import imgWarning from "../../../assets/siteManagement/images/warning-2.svg"
import ItemCard from "./building-info-item-card";
import ItemCardNew from "./building-info-item-card-new";
import PopupModalConfirmSave from "../popup-modal/confirm-save";
import { addBuilding, deleteBuilding, editBuilding, getBuildingBySiteId } from "../../../api/site";

function BuildingContent(props) {
    const [siteId, setSiteId] = useState(null);
    const [disable, setDisable] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isOpenPopupConFirmSave, setIsOpenPopupConFirmSave] = useState(false);
    const [isClearValueItemCardNew, setIsClearValueItemCardNew] = useState(false);
    const [listItemInfo, setListItemInfo] = useState([]);
    const listItemInfoDefaultRef = useRef([]);
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    //==============================
    useEffect(() => {
        setDisable(props.disable ? props.disable : false)
    }, [props.disable])
    useEffect(() => {
        if (props.siteId) {
            setSiteId(props.siteId);
        }
    }, [props.siteId])
    useEffect(() => {
        if (siteId) {
            onGetItemInfo(siteId);
        }
    }, [siteId])
    //==============================
    function randomString(length) {
        var text = "";
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    async function onGetItemInfo(param) {
        listItemInfoDefaultRef.current = [];
        let listX = [];
        // const response = await CoreAPI.apiCoreService.GetBuildingBySiteId(param);
        const response = await getBuildingBySiteId(param)
        if (response.status) {
            let dataResponse = response.data;
            for (let item of dataResponse?.building) {
                let listSubArea = [];
                if (Array.isArray(item.floor)) {
                    listSubArea = item.floor;
                }
                const detail = {
                    Id: item.building_id,
                    Name: item.building_name,
                    SubDetail: `${listSubArea.length} floors`,
                    Sequence: item.building_sequence,
                }
                listX.push(detail);
            }
            listX.sort((a, b) => a.Sequence > b.Sequence ? 1 : -1);
        }
        listItemInfoDefaultRef.current = listX.slice();
        setListItemInfo(listX);
    }
    function onSortFromDrag() {
        let listX = listItemInfo.slice();
        const dragItemX = listX.splice(dragItem.current, 1)[0];
        listX.splice(dragOverItem.current, 0, dragItemX);
        dragItem.current = null;
        dragOverItem.current = null;
        setListItemInfo(listX);
    }
    //==============================
    function onDragStartItem(param) {
        dragItem.current = param
    }
    function onDragEnterItem(param) {
        dragOverItem.current = param
    }
    function onDragOverItem(param) {
        param.preventDefault();
    }
    //==============================
    function onClickEdit() {
        setIsEdit(true);
    }
    function onClickCancel() {
        setListItemInfo([])
        setTimeout(() => {
            let listX = listItemInfoDefaultRef.current.slice();
            setListItemInfo(listX)
        }, 1);
        setIsEdit(false);
    }
    async function onClickSave() {
        setIsOpenPopupConFirmSave(true);
    }
    async function onConfirmSave() {
        setIsOpenPopupConFirmSave(false);
        //==============================
        for (let item of listItemInfoDefaultRef.current) {
            let model = listItemInfo.find(x => x.Id === item.Id);
            if (!model) {
                // const response = await CoreAPI.apiCoreService.DeleteBuilding(item.Id);
                const response = await deleteBuilding(item.Id)
            }
        }
        //==============================
        for (let [index, item] of listItemInfo.entries()) {
            if (item.NewItem) {
                const body = {
                    "name": item.Name,
                    "plant": siteId,
                    "sequence": index,
                }
                // const response = await CoreAPI.apiCoreService.AddBuilding(body);
                const response = await addBuilding(body);
            } else {
                let model = listItemInfoDefaultRef.current.find(x =>
                    x.Id === item.Id
                    && x.Name === item.Name
                    && x.Sequence === index
                )
                if (!model) {
                    const body = {
                        "name": item.Name,
                        "plant": siteId,
                        "sequence": index,
                    }
                    // const response = await CoreAPI.apiCoreService.EditBuilding(item.Id, body);
                    const response = await editBuilding(item.Id, body);
                }
            }
        }
        await onGetItemInfo(siteId);
        setIsEdit(false);
    }
    function onClickItemCard(param) {
        try {
            props.onSelectItem(param)
        } catch { }
    }
    function onDeleteItemCard(param) {
        let listX = listItemInfo.slice();
        listX = listX.filter(x => x.Id !== param.Id);
        setListItemInfo(listX);
    }
    function onInputTextItemCard(param, text) {
        let listX = listItemInfo.slice();
        let model = listX.find(x => x.Id === param.Id);
        if (model) {
            model.Name = text
        }
        setListItemInfo(listX);
    }
    function onClickAddNewItemCard(param) {
        let listX = listItemInfo.slice();
        const detail = {
            Id: randomString(10),
            Name: param,
            SubDetail: ``,
            Sequence: ``,
            NewItem: true,
        }
        listX.push(detail);
        setListItemInfo(listX);
        //==============================
        setIsClearValueItemCardNew(true)
        setTimeout(() => {
            setIsClearValueItemCardNew(false)
        }, 1);
    }
    //==============================
    return (
        <div className="w-full h-full">
            {isOpenPopupConFirmSave &&
                <PopupModalConfirmSave
                    isOpen={isOpenPopupConFirmSave}
                    labelMain={`Confirm save building?`}
                    onClickConfirm={(e) => { onConfirmSave() }}
                    onClickClose={(e) => { setIsOpenPopupConFirmSave(false) }}
                />
            }
            <div className="w-full h-[40px] mb-2 flex items-center">
                <p className={`text-secondary text-sm font-normal`}>
                    Building information
                </p>
                <div className="h-full ml-auto mr-0">
                    {!isEdit ?
                        (<BtnEdit
                            text={`Edit building`}
                            disable={disable}
                            onClick={(e) => { onClickEdit() }} />)
                        :
                        (<div className="flex h-full">
                            <div className="mr-2">
                                <BtnCancel onClick={(e) => { onClickCancel() }} />
                            </div>
                            <BtnSave onClick={(e) => { onClickSave() }} />
                        </div>)
                    }
                </div>
            </div>
            <div className="relative w-full" style={{ height: 'calc(100% - 48px)' }}>
                {(!isEdit && listItemInfo.length === 0) &&
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="w-full">
                            <div className="flex justify-center">
                                <img className="w-11 h-11" src={imgWarning} />
                            </div>
                            <div className="flex justify-center mt-2">
                                <p className={`text-tertiary text-xs font-medium`}>
                                    {disable ? 'Save this site to start editting the building' : 'No  buildings'}
                                </p>
                            </div>
                        </div>
                    </div>
                }
                <div className="w-full h-full overflow-y-auto">
                    {listItemInfo.map((value, index) => {
                        return (
                            <div key={value.Id} className="w-full h-12 mb-2" draggable={isEdit}
                                onDragStart={(e) => { onDragStartItem(index) }}
                                onDragEnter={(e) => { onDragEnterItem(index) }}
                                onDragEnd={(e) => { onSortFromDrag() }}
                                onDragOver={(e) => { onDragOverItem(e) }}
                            >
                                <ItemCard
                                    isEdit={isEdit}
                                    detail={value}
                                    mode={1}
                                    placeholder={'Building name'}
                                    onClick={(e) => { onClickItemCard(value) }}
                                    onDelete={(e) => { onDeleteItemCard(value) }}
                                    onInputText={(e) => { onInputTextItemCard(value, e) }}
                                />
                            </div>
                        )
                    })}
                    {isEdit &&
                        <div className="w-full h-12 mb-2">
                            <ItemCardNew
                                placeholder={'+ Add new building'}
                                clear={isClearValueItemCardNew}
                                onClickAdd={(e) => { onClickAddNewItemCard(e) }}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default BuildingContent;