const Radiation_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.875 14.4375C11.0625 12.8438 12.1875 11.5312 13.6875 10.9687L10.5 2.71875C8.15625 3.65625 6.09375 5.25 4.6875 7.3125C3.28125 9.375 2.4375 11.7187 2.34375 14.25L10.875 14.4375ZM17.625 15C17.625 16.4497 16.4497 17.625 15 17.625C13.5503 17.625 12.375 16.4497 12.375 15C12.375 13.5503 13.5503 12.375 15 12.375C16.4497 12.375 17.625 13.5503 17.625 15ZM12.4688 18.375C13.2188 18.9375 14.0625 19.2188 15 19.2188C15.9375 19.2188 16.7812 18.8438 17.5312 18.4688L22.0312 25.2188C19.875 26.5312 17.4375 27.2812 15 27.2812C12.5625 27.2812 10.0312 26.5312 7.96875 25.125L12.4688 18.375ZM19.125 14.4375C19.0312 13.5938 18.6562 12.9375 18.1875 12.2813C17.7188 11.7188 17.0625 11.25 16.2188 10.9687L19.4063 2.71875C21.75 3.5625 23.8125 5.15625 25.2188 7.21875C26.7188 9.28125 27.4688 11.7188 27.5625 14.1563L19.125 14.4375Z" fill={stroke}/>

</svg>  

    )
}

export default Radiation_Icon;