function GradientSVGType1(props) {
    return (
        <svg>
            <defs>
                <linearGradient id={`GradientSVGType1`}>
                    <stop offset="0%" stopColor={`#79C8B0`} />
                    <stop offset="100%" stopColor={`#1FA37C`} />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default GradientSVGType1;