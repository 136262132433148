export const webStyle = {
    Theme: {
        main: "#1FA37C",
        border: "#A9D1C5",
        background: "#FFFFFF",
        icon_light: "#ACC0D6",
        icon_dark: "#809FB8",
    },
    Chart: {
        first: "#67C3DE",
        second: "#DE5197",
        third: "#DDC850",
        forth: "#5C6BB2",
        fifth: "#F8C2B1",
    },
    Text: {
        primary: "#000000",
        secondary: "#666666",
        number: "#3570AB",
        caption: "#809FB8",
        red: "#AF2324",
        green: "#13854A"
    },
    Others: {
        primary_green: "#1FA37C",
        light_green: "#81C894",
        line: "#DAE7E4",
        yellow: "#F9DD79",
        sunny: "#FFBE52",
        pea: "#9B67AB",
    },
    Status: {
        error: "#CB4C52",
        online: "#1FA37C",
        offline: "#c4c4c4"
    },
    Style: {
        borderSize: 2,
        borderRadius: 20,
        searchBorderSize: 2,
        searchBorderRadius: 20
    }
};