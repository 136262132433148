const Route = {
    //public
    LOGIN: "/login",
    FORGET_PASSWORD: "/forget-password",

    // private
    OVERVIEW: "/",
    // USER_MANAGEMENT: "/user-management/",
    // USER_CREATION:"/user-management/creation",
    // GROUP_USER: "/group/:groupID",
    SETTING: "/setting",
    DASHBOARD: "/dashboard/:dashboardId",
    DASH_SETTING: "/dashboard/setup/:dashboardId",
    SITE: "/site",
    DEVICE_MANAGEMENT: "/device-management"

    // OVERLAY: "/overlay",
    // THEME: "/theme",
    // SOLAR: "/solar",
    // WIND: "/wind",
    // ANALYTIC: "/analytic",
};

export default Route;