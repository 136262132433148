import { useEffect, useState } from "react";
import { create, useStore } from 'zustand'
import { SelectNormalIcons } from "./SetupWidget/SelectWidgetIcon";
import { addNewWidgets } from "../../api/dashboard";
import NoSetupWidget from "./WidgetSetups_/noSetupWidget";
import SingleFieldSetup from "./WidgetSetups_/singleFieldSetup";
import GetSetupFields from "./WidgetSetups_";

import { useWidgetSetupStore } from "../../context/stores";
import {shallow} from 'zustand/shallow'


const WidgetSetup = ({isCompany, returnToDash, setIsPopupError, setSelectedWidget, setLabelError, dashboardId}) => {

    const [widgetTitle, updateWidgetTitle] = useWidgetSetupStore((state) => [state.widgetTitle, state.updateWidgetTitle],shallow )
    const [setupDetails, updateSetupDetails] = useWidgetSetupStore((state) => [state.setupDetails, state.updateSetupDetails],shallow)
    const [widgetIcon, updateWidgetIcon] = useWidgetSetupStore((state) => [state.widgetIcon, state.updateWidgetIcon],shallow)
    const [selectedWidget, updateSelectedWidget] = useWidgetSetupStore((state) => [state.selectedWidget, state.updateSelectedWidget],shallow)

    
    // need setup but only title
    const onlySetupTitle = ["Net Zero Widget","Circle Device Status Widget"];
    
    // dont need setup
    const doesNotNeedSetup = [
        "Weather Widget",
        // "Circle Device Status Widget",
        "Time Circle Widget",
        // "Net Zero Widget",
        "Plant Detail Widget",
        "Weather AQI Widget",
        "Plant Location Widget",
        "Battery Widget",
        "Islanding Widget",
        "Static Map Device Widget",
        "Event Log Widget",
        
    ]

    useEffect(() => {
        if(selectedWidget !== ""){
            updateWidgetIcon("")
            updateSetupDetails({})
            updateWidgetTitle("")
        }
    }, [selectedWidget])
    

    const clearForm = () => {
        updateSetupDetails({})
        updateWidgetIcon("")
        updateSelectedWidget("")
        updateWidgetTitle("")

    }

    const submitForm = async(e) => {
        // console.log(setupDetails)
        let details={}
        if(!onlySetupTitle.includes(selectedWidget)){
            details = setupDetails
        }

        const request = {
            "dashboard": Number(dashboardId),
            "name": selectedWidget,
            "title": widgetTitle || setupDetails?.title || setupDetails?.measurement?.[0].title|| selectedWidget,
            "setup": JSON.stringify(details),
            "has_popup": false,
            "has_inner_page": false,
            "is_setup": true,
            "is_active": true,
            "is_generate": true,
      }
    //   console.log(request)
      try {
        const {data, error} = await addNewWidgets(request)
        if(data){
            clearForm()
            returnToDash()

        }else{
            console.log(error)
            setLabelError(error)
            setIsPopupError(true)
        }
      } catch (error) {
        setLabelError(error)
        setIsPopupError(true)
      }
    }
        
    return ( 
        <section className="flex flex-col w-full h-full gap-2">
            {selectedWidget ===""?
            <div className="h-full overflow-hidden">
                <p className="pb-2 font-semibold border-b ">Widget Setup Details </p>
                <div className="flex items-center justify-center w-full h-full opacity-50">
                    <p>Select widget to setup</p>
                </div>
            </div>:
            <>
                {isCompany || doesNotNeedSetup.includes(selectedWidget)?
                    <NoSetupWidget selectedWidget={selectedWidget} clearForm={clearForm} submitForm={submitForm}/>
                :
                <>
                    {!isCompany &&
                    <div className="h-full 2xl:w-[80%]">
                        <p className="pb-2 font-semibold border-b">Widget Setup Details | <span className="font-normal">{selectedWidget}</span></p>
                        {/* -------------------INPUT FIELDS ------------- */}
                        <GetSetupFields submitForm={submitForm} clearForm={clearForm}/>
                    </div> }
                </>
                }   
            </>}
        </section>
     );
}
 
export default WidgetSetup;
