const Icon_Kitchen = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M38.1775 32.6353L37.6714 31.8558C37.6249 31.7743 37.555 31.7219 37.4736 31.7219H36.9384C36.9326 31.7045 36.9267 31.687 36.9151 31.6696L36.6882 31.3322C36.6242 31.2391 36.5079 31.2275 36.4323 31.3089C36.3566 31.3903 36.3508 31.5358 36.4148 31.6289L36.4788 31.7161H35.0535L35.0477 31.4252C35.0477 31.3031 34.9662 31.1984 34.8673 31.1984C34.7684 31.1984 34.6928 31.3031 34.6928 31.4311L34.6986 31.7161H33.2733L33.3373 31.6289C33.4013 31.5358 33.3955 31.3903 33.3198 31.3089C33.2442 31.2275 33.1337 31.2391 33.0639 31.3322L32.837 31.6696C32.8254 31.6812 32.8195 31.6987 32.8137 31.7219H32.2843C32.2145 31.7219 32.1447 31.7569 32.0982 31.8325L31.592 32.612C31.5455 32.6702 31.5222 32.7458 31.5222 32.8273C31.5222 32.996 31.6269 33.1356 31.7607 33.1356H37.9972C38.0554 33.1356 38.1019 33.1123 38.1484 33.0658C38.2473 32.9553 38.259 32.7691 38.1775 32.6353Z" fill="url(#paint0_linear_5282_83146)"/>
<path d="M28.4212 32.6353L27.9151 31.8558C27.8686 31.7743 27.7987 31.7219 27.7173 31.7219H27.1879C27.1821 31.7045 27.1763 31.687 27.1646 31.6696L26.9377 31.3322C26.8737 31.2391 26.7574 31.2275 26.6818 31.3089C26.6061 31.3903 26.6003 31.5358 26.6643 31.6289L26.7283 31.7161H25.303L25.2972 31.4252C25.2972 31.3031 25.2157 31.1984 25.1168 31.1984C25.0179 31.1984 24.9423 31.3031 24.9423 31.4311L24.9481 31.7161H23.5228L23.5868 31.6289C23.6508 31.5358 23.645 31.3903 23.5694 31.3089C23.4937 31.2275 23.3832 31.2391 23.3134 31.3322L23.0865 31.6696C23.0749 31.6812 23.069 31.6987 23.0632 31.7219H22.5338C22.464 31.7219 22.3942 31.7569 22.3477 31.8325L21.8415 32.612C21.795 32.6702 21.7717 32.7458 21.7717 32.8273C21.7717 32.996 21.8764 33.1356 22.0102 33.1356H28.2467C28.3049 33.1356 28.3514 33.1123 28.398 33.0658C28.491 32.9553 28.5085 32.7691 28.4212 32.6353Z" fill="url(#paint1_linear_5282_83146)"/>
<path d="M39.1259 26.7072H36.4905V21.6051H34.3613V26.6373H26.2691V21.5993C26.2691 20.3136 26.9962 18.7313 28.98 18.7313H31.3653C32.0517 18.7313 32.6102 18.1728 32.6102 17.4863C32.6102 16.7998 32.0517 16.2413 31.3653 16.2413H28.98C26.0247 16.2413 23.6337 18.6324 23.6337 21.5877V26.6897H20.7947C20.5504 26.6897 20.3118 26.7653 20.1141 26.905L18.3455 28.1499C17.6998 28.6037 18.0197 29.6159 18.8051 29.6159H41.1795C41.9707 29.6159 42.2907 28.5862 41.6275 28.1441L39.7775 26.8991C39.5971 26.777 39.3644 26.7072 39.1259 26.7072Z" fill="url(#paint2_linear_5282_83146)"/>
<path d="M31.7256 22.4197L30.4806 22.2161L31.6441 19.8309C31.6732 19.7727 31.6441 19.6854 31.5568 19.6854L29.4567 19.7145C29.4276 19.7145 29.3985 19.7436 29.3694 19.7727C29.3694 19.7727 28.2059 22.6815 28.1477 22.7978C28.0837 22.9084 28.1768 23.1469 28.3223 23.1818L29.6603 23.3854L29.1018 25.8055C29.0727 25.8928 29.2182 25.9219 29.2473 25.8637L31.8419 22.664C31.9292 22.5942 31.871 22.4488 31.7256 22.4197Z" fill="url(#paint3_linear_5282_83146)"/>
<path d="M36.7464 18.999V17.9053C36.7464 17.6435 36.5428 17.4341 36.2752 17.4341C36.0076 17.4341 35.8039 17.6377 35.8039 17.9053V18.999H34.972V17.9053C34.972 17.6435 34.7684 17.4341 34.5008 17.4341C34.239 17.4341 34.0296 17.6377 34.0296 17.9053V18.999H33.5583V21.0235C33.5583 22.0125 34.3903 22.7921 35.3793 22.7921C36.3682 22.7921 37.1478 21.9602 37.1478 20.9712V18.9467L36.7464 18.999Z" fill="url(#paint4_linear_5282_83146)"/>
<path d="M38.5443 32.7692H21.4523C19.5441 32.7692 18.0024 34.3108 18.0024 36.219V42.1936C18.0024 42.4554 18.2177 42.6765 18.4853 42.6765H18.7238V43.2757C18.7238 43.5375 18.9391 43.7586 19.2067 43.7586C19.4685 43.7586 19.6895 43.5433 19.6895 43.2757V42.6765H40.3129V43.2757C40.3129 43.5375 40.5339 43.7644 40.8015 43.7586C41.0691 43.7528 41.2786 43.5201 41.2786 43.2583V42.6765H41.5171C41.7789 42.6765 42 42.4613 42 42.1936V36.219C41.9941 34.3108 40.4525 32.7692 38.5443 32.7692ZM29.1257 40.396H21.1265V38.261H29.1315V40.396H29.1257ZM38.8759 40.396H30.8709V38.261H38.8817V40.396H38.8759ZM38.8759 36.696H21.1265C21.1265 35.8001 21.8537 35.0787 22.7438 35.0787H37.2586C38.1487 35.0787 38.8759 35.8059 38.8759 36.696Z" fill="url(#paint5_linear_5282_83146)"/>
<rect x="9.5" y="9.5" width="41" height="41" rx="20.5" stroke="#79C8B0" stroke-width="3"/>
<rect x="9.5" y="9.5" width="41" height="41" rx="20.5" stroke="url(#paint6_linear_5282_83146)" stroke-width="3"/>
<defs>
<linearGradient id="paint0_linear_5282_83146" x1="34.8727" y1="31.3283" x2="34.8727" y2="39.639" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint1_linear_5282_83146" x1="25.121" y1="31.3283" x2="25.121" y2="39.639" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint2_linear_5282_83146" x1="20.3797" y1="18.1402" x2="36.4103" y2="34.1709" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint3_linear_5282_83146" x1="28.6388" y1="21.0594" x2="32.6918" y2="25.1124" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint4_linear_5282_83146" x1="32.3019" y1="16.866" x2="36.3549" y2="20.919" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint5_linear_5282_83146" x1="14.8747" y1="23.6455" x2="30.9053" y2="39.6761" gradientUnits="userSpaceOnUse">
<stop stop-color="#3AD296"/>
<stop offset="1" stop-color="#009377"/>
</linearGradient>
<linearGradient id="paint6_linear_5282_83146" x1="8.52381" y1="26.3333" x2="52.7437" y2="26.7846" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Kitchen;