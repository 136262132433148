import WidgetTitleSetup from "./widgetTitleSetup";

const NoSetupWidget = ({selectedWidget,clearForm,submitForm }) => {
    return ( 
        <div className="flex flex-col items-center justify-center w-full h-full gap-8">
                  
                    <div className="flex flex-col items-center justify-center gap-2 w-96">
                        <div>
                            <p className="text-sm text-center text-gray-500">Information</p>
                            <p className="p-2 text-center rounded-md bg-vekin/20">{selectedWidget}</p>
                        </div>
                        <p className="text-center">This widget does not need setup, click the <span>Create Button</span> bellow to create the widget</p>  

                       
                    </div>
                    
                    <div className='flex flex-row justify-between gap-4 mt-2 w-96'>
                        <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
                            Cancel
                        </button>

                        <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                            Create Widget
                        </button>  
                    </div>
            
            </div>
    );
}
 
export default NoSetupWidget;