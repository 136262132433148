const Pressure_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6201_97262)">
        <path d="M15 29.5312C23.0254 29.5312 29.5312 23.0254 29.5312 15C29.5312 6.97461 23.0254 0.46875 15 0.46875C6.97461 0.46875 0.46875 6.97461 0.46875 15C0.46875 23.0254 6.97461 29.5312 15 29.5312Z" fill={stroke}/>
        <path d="M15 15L3.9375 22.125C2.625 19.9688 1.875 17.5312 1.875 15C1.875 7.78125 7.78125 1.875 15 1.875C22.2188 1.875 28.125 7.78125 28.125 15C28.125 17.9062 27.1875 20.625 25.5 22.9688L15 15Z" fill="white"/>
        <path d="M15 27.0938C21.6792 27.0938 27.0938 21.6792 27.0938 15C27.0938 8.32081 21.6792 2.90625 15 2.90625C8.32081 2.90625 2.90625 8.32081 2.90625 15C2.90625 21.6792 8.32081 27.0938 15 27.0938Z" fill={stroke}/>
        <path d="M11.8125 19.9688C11.25 19.5938 11.1563 18.9375 11.4375 18.4688L19.3125 6.75003C19.6875 6.28128 20.3438 6.09378 20.8125 6.46878H20.9063C21.375 6.84378 21.5625 7.50003 21.1875 7.96878L13.3125 19.6875C12.9375 20.1563 12.2813 20.25 11.8125 19.9688Z" fill="white"/>
        <path d="M15 18.5625C16.9157 18.5625 18.4688 17.0095 18.4688 15.0938C18.4688 13.178 16.9157 11.625 15 11.625C13.0843 11.625 11.5312 13.178 11.5312 15.0938C11.5312 17.0095 13.0843 18.5625 15 18.5625Z" fill="white"/>
        <path d="M15 16.7812C15.932 16.7812 16.6875 16.0257 16.6875 15.0938C16.6875 14.1618 15.932 13.4062 15 13.4062C14.068 13.4062 13.3125 14.1618 13.3125 15.0938C13.3125 16.0257 14.068 16.7812 15 16.7812Z" fill={stroke}/>
        <path d="M15.6563 2.90625H14.3438V5.25H15.6563V2.90625Z" fill="white"/>
        <path d="M27.5566 15.7189V14.4064H25.1191V15.7189H27.5566Z" fill="white"/>
        <path d="M4.9165 15.6714V14.3589H2.479L2.479 15.6714H4.9165Z" fill="white"/>
        <path d="M26.3056 9.55279L25.6655 8.40698L23.5376 9.59575L24.1777 10.7416L26.3056 9.55279Z" fill="white"/>
        <path d="M6.75241 20.3626L6.1123 19.2168L4.06621 20.3598L4.70632 21.5057L6.75241 20.3626Z" fill="white"/>
        <path d="M9.55023 6.50375L10.696 5.86365L9.50728 3.73571L8.36146 4.37582L9.55023 6.50375Z" fill="white"/>
        <path d="M24.6462 22.3687L25.3865 21.2848L23.451 19.9629L22.7108 21.0468L24.6462 22.3687Z" fill="white"/>
        <path d="M6.28441 9.92957L7.02466 8.8457L5.01177 7.47095L4.27152 8.55482L6.28441 9.92957Z" fill="white"/>
        </g>
        <defs>
        <linearGradient id="paint0_linear_6201_97262" x1="0.480281" y1="15" x2="29.5197" y2="15" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3AD296"/>
        <stop offset="1" stop-color="#009377"/>
        </linearGradient>
        <linearGradient id="paint1_linear_6201_97262" x1="2.86275" y1="15" x2="27.1373" y2="15" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3AD296"/>
        <stop offset="1" stop-color="#009377"/>
        </linearGradient>
        <linearGradient id="paint2_linear_6201_97262" x1="13.6377" y1="14.2031" x2="16.3794" y2="16.0442" gradientUnits="userSpaceOnUse">
        <stop stop-color="#3AD296"/>
        <stop offset="1" stop-color="#009377"/>
        </linearGradient>
        <clipPath id="clip0_6201_97262">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
        </defs>
        </svg>
        

    )
}

export default Pressure_Icon;