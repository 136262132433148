// all api related to company should come here
import axios from "../shared/axios";
import useSWR from "swr";
import { API_URL } from "../shared/config";


const fetcher = (url) => axios.get(url);

export const useCompanyReport = (id) => {
  const { data, error } = useSWR(`${API_URL}/report/company/${id}/`, fetcher);
  //console.log(data)
  return {
    data: data?.data,
    isLoadingData: !error && !data,
    isErrorData: error,
  };
};