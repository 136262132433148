import React, {useState, useEffect} from 'react'
import MultiSelectBtn from '../multiSelectBtn'
import { useGetDevice } from "../../../../api/userInfo";
import { SelectNormalIcons } from "../../SetupWidget/SelectWidgetIcon";



const MultipleFieldSetup = ({clearForm, submitForm, updateWidgetIcon, widgetIcon, updateSetupDetails, setupDetails, selectedWidget, inputFields}) => {
    const {deviceList, isLoadingDeviceList, isErrorDeviceList} = useGetDevice(localStorage.getItem('plantId'));
    const [multiSelect,setMultiSelect ]= useState({})
    
    const [fields, setFields] =useState([{}])

    useEffect(() => {
        if(multiSelect?.data){
            const newFields = [...fields];
            newFields[multiSelect?.index][multiSelect?.field] = multiSelect?.data.length > 1 ? multiSelect?.data : multiSelect?.data[0];
            setFields(newFields);
        }
    }, [multiSelect]);
   

    const onInput = (field) => (e) => {
        const newFields = [...fields];
        // the field name is in the format of field-index
        newFields[field.split("-")[1]][field.split("-")[0]] = e.target.value;
        setFields(newFields);

        updateSetupDetails({
            ...setupDetails,
            "measurement": newFields
        });
        
       
    };
    
    const addField = () => {
        setFields([...fields, {}]);
    };
    
    const removeField = (index) => {
        const newFields = [...fields];
        newFields.splice(index, 1);
        setFields(newFields);
    };

    
   
   
    return ( 
        <form>
       
        
            <SelectNormalIcons updateSetupDetails={updateSetupDetails} setupDetails={setupDetails}/> 
        


        <section>
            {fields?.map((field, index) => (
            <div className="relative flex flex-col gap-4 mt-4 mb-10" key={index}>
                    <div className="relative flex flex-col gap-2">
                    <button onClick={() => removeField(index)} type='button' className='absolute right-0 inline-flex items-center px-1 text-[11px] text-red-400 border rounded-md -top-0 border-red-400/60 hover:bg-red-400/60 hover:text-white'>
                    <i className="ri-close-circle-line"></i>Remove</button>
                        <label htmlFor={`device-${index}`} className="col-span-1 text-sm text-gray-500">Device ID (select one or more devices)</label>
                        <MultiSelectBtn setMultiSelect={setMultiSelect} data={deviceList?.devices} name={`device-${index}`} index={index} placeholder="select device" />

                    </div> 
                
                    <div className="grid grid-cols-10 gap-2">
                        <div  className="flex flex-col col-span-4 gap-2">
                            <label htmlFor={`title-${index}`} className="col-span-1 text-sm text-gray-500">Title</label>
                            <input onChange={onInput(`title-${index}`)} name={`title-${index}`} autoComplete="off" required type="text" className="setupModal_input" placeholder="Title of widget"/>
                        </div> 

                        <div  className="flex flex-col col-span-2 gap-2">
                            <label htmlFor={`unit-${index}`} className="col-span-1 text-sm text-gray-500">Unit</label>
                            <input onChange={onInput(`unit-${index}`)} autoComplete="off" required type="text" name={`unit-${index}`}  className="setupModal_input" placeholder="kWh"/>
                        </div>
                        
                        <div  className="flex flex-col col-span-4 gap-2">
                            <label htmlFor={`field-${index}`} className="col-span-1 text-sm text-gray-500">Field</label>
                            <input onChange={onInput(`field-${index}`)} autoComplete="off" required type="text" name={`field-${index}`} className="setupModal_input" placeholder="_ac_energy"/>
                        </div>

                    </div>

            </div>

            ))}

<button onClick={addField} type='button' className='inline-flex items-center gap-1 p-1 text-[11px] hover:border-primary border rounded-md'> <i className="ri-add-circle-line"></i>Add Field</button>


         </section>
        
        
        
        {/* ------------ submit button */}
        <div className='flex flex-row justify-between gap-4 mt-4'>
        <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
            Cancel
        </button>

        
            <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                Submit
            </button>  

        
        </div>
</form>
     );
}
 
export default MultipleFieldSetup;