import { AppBar } from '@mui/material';
import axios from '../shared/axios';
import useSWR from 'swr'
import { API_URL } from '../shared/config';


const fetcher = url => axios.get(url)


//list of all companies
export const useGetCompanies  =() =>{
    const { data, error } = useSWR(`${API_URL}/company/`, fetcher)
    return {
      companies: data?.data,
      isLoadingCompanies: !error && !data,
      isErrorCompanies: error
    }
}
// list of plants (of a company)
export const useGetCompanyPlants  =(company_id) =>{
    const { data, error } = useSWR(`${API_URL}/plant/company/${company_id}/`, fetcher)
    return {
      plants: data?.data,
      isLoadingPlants: !error && !data?.data,
      isErrorPlants: error
    }

}
// list of dashboards (of a plant)
export const useGetPlantDashboards  =(plant_id) =>{
    const { data, error } = useSWR(`${API_URL}/widget/dashboard/plant/${plant_id}/`, fetcher)
    return {
      dashboards: data?.data,
      isLoadingDashboards: !error && !data?.data,
      isErrorDashboards: error
    }
}
//get a dashboard (from plant)
export const useGetPlantDashboardByID  =(dashboard_id) =>{
    const { data, error } = useSWR(`${API_URL}/widget/dashboard/${dashboard_id}/`, fetcher)
    return {
      dashboardData: data?.data,
      isLoadingData: !error && !data?.data,
      isErrorData: error
    }
}










export const getDashboard = async () => {
    try {
        const response = await axios.get(`${API_URL}/widget/dashboard`);
        return response.data
    }
    catch (error) {
        //console.log(error)
    }
}


export const useGetDashboard  =() =>{
    const { data, error } = useSWR(`${API_URL}/widget/dashboard/`, fetcher)

    return {
      dashboard: data?.data,
      isLoadingDashboard: !error && !data,
      isErrorDashboard: error
    }
}
export const useWidgetDashboardByID  =(id) =>{
    const { data, error } = useSWR(`${API_URL}/widget/dashboard/${id}/`, fetcher)
    return {
      dashboardData: data?.data,
      isLoadingData: !error && !data?.data,
      isErrorData: error
    }
}

export const updateWidgetLayout = async (data,id) => {
    try {
        const request = await axios.patch(`${API_URL}/widget/dashboard/${id}/`, data);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}


export const addNewWidgets = async (widget_detail) => {
  try {
      const request = await axios.post(`${API_URL}/widget/generate/`, widget_detail);
      return { data: request.data, error: undefined };
  } catch (error) {
    
      return { data: undefined, "error": error?.response?.data?.detail };
  }
}

export const editWidgetSetup = async (data,id) => {
    try {
        const request = await axios.patch(`${API_URL}/widget/${id}/`, data);
        return { data: request.data, error: !request.data };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const useCompanyDashboardByID  =(id) =>{
  const { data, error } = useSWR(`${API_URL}/widget/dashboard/company/${id}/`, fetcher)
  return {
    companyDashboard: data?.data,
    isLoadingCompany: !error && !data?.data,
    isErrorCompany: error
  }
}
