export const WidgetTitleSetup = ({setWidgetTitle}) => {
    const updateTitle = (e) => {
        setWidgetTitle(e.target.value)
    }
    return ( 
        <>
        <div  className="flex flex-col col-span-4 gap-2 mt-2">
                <label htmlFor={`widget-title`} className="col-span-1 text-sm text-gray-500">Widget Title</label>
                <input onChange={(e) =>updateTitle(e)} name={`widget-title`} autoComplete="off" required type="text" className="setupModal_input" placeholder="Title of widget"/>
        </div> 
        
        </>
     );
}

export const SetupTitleOnly = ({submitForm, updateWidgetTitle}) => {
    const updateTitle = (e) => {
        updateWidgetTitle(e.target.value)
    }

    const clearForm = () => {
        updateWidgetTitle('')
    }
    return ( 
        <>
        <div  className="flex flex-col col-span-4 gap-2 mt-2">
                <label htmlFor={`widget-title`} className="col-span-1 text-sm text-gray-500">Widget Title</label>
                <input onChange={(e) =>updateTitle(e)} name={`widget-title`} autoComplete="off" required type="text" className="setupModal_input" placeholder="Title of widget"/>
        </div> 

        {/* ------------ submit button */}
        <div className='flex flex-row justify-between gap-4 mt-4'>
        <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
            Cancel
        </button>

        
            <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                Submit
            </button>  

        
        </div>
        
        </>
     );
}
 


