import React from "react";
import imgCopy from '../../../assets/siteManagement/images/copy.svg'
import imgSave from '../../../assets/siteManagement/images/save.svg'
import ButtonFuncBottom from "./button-func";

function BottomAddEditBox(props) {

    function onClickSaveAndDup() {
        try {
            props.onClickSaveAndDup();
        } catch { }
    }
    function onClickSave() {
        try {
            props.onClickSave();
        } catch { }
    }

    return (
        <div className="h-full py-1 flex">
            <div className="mr-1">
                <ButtonFuncBottom
                    text={`Save and Duplicate`}
                    image={imgCopy}
                    onClick={(e) => { onClickSaveAndDup() }}
                />
            </div>

            <div className="w-px h-full bg-quartenary">
            </div>

            <div className="ml-1">
                <ButtonFuncBottom
                    text={`Save`}
                    image={imgSave}
                    onClick={(e) => { onClickSave() }}
                />
            </div>
        </div>
    )
}

export default BottomAddEditBox;