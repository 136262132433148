export const Thunderstorm_S = ({style}) => {
    return ( 
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.6543 9.56267C10.7108 9.74737 10.8946 9.86572 11.0904 9.84343C11.187 9.83281 11.2857 9.82698 11.3855 9.82698C11.4392 9.82698 11.4918 9.82857 11.5444 9.83175C11.7006 9.84131 11.8481 9.76223 11.924 9.62901C12.4024 8.79256 13.3163 8.22678 14.3647 8.22678C15.796 8.22678 16.9779 9.28243 17.1395 10.6411C17.1634 10.8418 17.3375 10.993 17.5436 10.993C18.2438 10.9936 18.8583 11.3497 19.2097 11.8868C19.2748 11.9866 19.3822 12.0524 19.5026 12.0672C19.623 12.0816 19.7434 12.0428 19.8318 11.9611C20.9079 10.966 21.5799 9.55577 21.5799 7.99326C21.5799 4.97969 19.0796 2.53296 16 2.53296C12.9204 2.53296 10.42 4.97969 10.42 7.99326C10.42 8.53886 10.5019 9.06536 10.6543 9.56267V9.56267Z" fill="url(#paint0_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.5933 0.398058V1.45955C15.5933 1.67927 15.7755 1.8576 16 1.8576C16.2246 1.8576 16.4068 1.67927 16.4068 1.45955V0.398058C16.4068 0.17833 16.2246 0 16 0C15.7755 0 15.5933 0.17833 15.5933 0.398058Z" fill="url(#paint1_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M19.4441 1.19465L18.9017 2.1139C18.79 2.30391 18.8567 2.54752 19.0509 2.65739C19.245 2.76725 19.4945 2.70197 19.6068 2.51196L20.1492 1.59271C20.2609 1.40218 20.1942 1.15856 20 1.0487C19.8058 0.938836 19.5564 1.00412 19.4441 1.19465V1.19465Z" fill="url(#paint2_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.3724 3.76832L21.433 4.29906C21.2388 4.40893 21.1721 4.65307 21.2844 4.84308C21.3966 5.03308 21.6456 5.09836 21.8397 4.98903L22.7791 4.45829C22.9738 4.34842 23.0406 4.10428 22.9283 3.91427C22.816 3.72427 22.5671 3.65899 22.3724 3.76832Z" fill="url(#paint3_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.5932 7.43042H22.5085C22.284 7.43042 22.1017 7.60875 22.1017 7.82848C22.1017 8.04821 22.284 8.22654 22.5085 8.22654H23.5932C23.8178 8.22654 24 8.04821 24 7.82848C24 7.60875 23.8178 7.43042 23.5932 7.43042Z" fill="url(#paint4_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.7791 11.199L21.8397 10.6682C21.6456 10.5589 21.3966 10.6242 21.2844 10.8142C21.1721 11.0042 21.2388 11.2483 21.433 11.3582L22.3724 11.8889C22.5671 11.9983 22.816 11.933 22.9283 11.743C23.0406 11.553 22.9738 11.3088 22.7791 11.199Z" fill="url(#paint5_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M8.40678 8.22644H9.49153C9.71607 8.22644 9.89831 8.04811 9.89831 7.82839C9.89831 7.60866 9.71607 7.43033 9.49153 7.43033H8.40678C8.18224 7.43033 8 7.60866 8 7.82839C8 8.04811 8.18224 8.22644 8.40678 8.22644Z" fill="url(#paint6_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.2209 4.45829L10.1603 4.98903C10.3545 5.09836 10.6034 5.03308 10.7157 4.84308C10.828 4.65307 10.7612 4.40893 10.5671 4.29906L9.62768 3.76832C9.43297 3.65899 9.18402 3.72427 9.07175 3.91427C8.95948 4.10428 9.02619 4.34842 9.2209 4.45829V4.45829Z" fill="url(#paint7_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.8509 1.59271L12.3932 2.51196C12.5055 2.70197 12.755 2.76725 12.9492 2.65739C13.1433 2.54752 13.2101 2.30391 13.0983 2.1139L12.556 1.19465C12.4437 1.00412 12.1942 0.938836 12 1.0487C11.8059 1.15856 11.7391 1.40218 11.8509 1.59271Z" fill="url(#paint8_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.3789 7.19107H15.3956C17.9369 7.19107 20 9.16496 20 11.5958C20 13.218 19.1045 16 15.6716 16C6.77054 16 6.77054 16 6.77054 16C5.54193 16 4.60376 15.5855 3.96871 14.9334C3.33539 14.2824 3 13.3867 3 12.394C3 10.5226 4.49024 8.98417 6.39712 8.80502C6.83509 6.6382 8.83071 5 11.2228 5C12.9712 5 14.5076 5.87477 15.3789 7.19107V7.19107Z" fill="url(#paint9_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.6627 19.6099L12.6942 19.6129C12.2375 20.6299 11.834 21.5944 11.7537 21.7879C11.474 22.2814 11.6307 22.9211 12.1212 23.2301C12.6117 23.5399 13.2575 23.4056 13.586 22.9339C13.7592 22.7426 14.7732 21.6086 15.6372 20.5594C16.358 19.6841 16.9482 18.8899 16.9482 18.8899C16.9565 18.8786 16.9647 18.8666 16.9722 18.8546C17.3157 18.3101 17.336 17.6216 17.0247 17.0576C16.7172 16.5004 16.1345 16.1516 15.4985 16.1441C15.4392 16.1404 15.2052 16.1261 14.8947 16.1179C15.074 15.3236 15.221 14.6006 15.2562 14.4259C15.44 13.8904 15.1692 13.2926 14.6322 13.0774C14.0952 12.8629 13.487 13.1096 13.2477 13.6286C13.1225 13.8259 12.4932 14.8301 11.96 15.7519C11.507 16.5341 11.1447 17.2294 11.1447 17.2294C11.1365 17.2459 11.1282 17.2631 11.1215 17.2804C10.9122 17.8039 10.976 18.3971 11.2917 18.8636C11.6015 19.3211 12.113 19.5986 12.6627 19.6099V19.6099Z" fill="url(#paint10_linear_1570_78171)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.95955 16.8145L6.97773 16.8162C6.71415 17.4031 6.48129 17.9597 6.43498 18.0714C6.27354 18.3562 6.364 18.7254 6.64706 18.9037C6.93012 19.0824 7.30278 19.005 7.49235 18.7327C7.59233 18.6224 8.1775 17.9679 8.6761 17.3624C9.09204 16.8573 9.43266 16.399 9.43266 16.399C9.43742 16.3925 9.44218 16.3856 9.44651 16.3786C9.64474 16.0644 9.65643 15.6671 9.47681 15.3416C9.29936 15.02 8.96306 14.8188 8.59603 14.8144C8.56184 14.8123 8.4268 14.8041 8.24761 14.7993C8.35106 14.3409 8.43589 13.9237 8.45623 13.8229C8.56227 13.5138 8.40602 13.1689 8.09613 13.0447C7.78623 12.9209 7.43522 13.0633 7.29715 13.3628C7.22487 13.4766 6.86174 14.0561 6.554 14.5881C6.29258 15.0395 6.08353 15.4407 6.08353 15.4407C6.07877 15.4502 6.07401 15.4602 6.07012 15.4702C5.94936 15.7723 5.98615 16.1146 6.16836 16.3838C6.34712 16.6478 6.6423 16.808 6.95955 16.8145V16.8145Z" fill="url(#paint11_linear_1570_78171)"/>
<defs>
<linearGradient id="paint0_linear_1570_78171" x1="16.9207" y1="7.23631" x2="14.6191" y2="16.6678" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#DBB500"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78171" x1="16.0146" y1="0.99548" x2="16.0146" y2="5.44013" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78171" x1="19.5942" y1="1.72487" x2="17.6942" y2="5.57478" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78171" x1="22.2073" y1="4.37868" x2="19.292" y2="6.29377" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_78171" x1="23.1885" y1="7.82848" x2="17.7511" y2="7.82848" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint5_linear_1570_78171" x1="21.9661" y1="11.2786" x2="19.6364" y2="9.29952" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint6_linear_1570_78171" x1="8.80958" y1="7.82839" x2="18.7118" y2="7.82839" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint7_linear_1570_78171" x1="9.7928" y1="4.37868" x2="15.1627" y2="7.93475" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint8_linear_1570_78171" x1="12.4746" y1="1.83261" x2="15.0353" y2="6.02071" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint9_linear_1570_78171" x1="10.6891" y1="12.5146" x2="10.6891" y2="31.3204" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint10_linear_1570_78171" x1="17.8587" y1="19.5956" x2="17.8587" y2="41.7086" gradientUnits="userSpaceOnUse">
<stop stopColor="#FEC110"/>
<stop offset="1" stopColor="#D6A000"/>
</linearGradient>
<linearGradient id="paint11_linear_1570_78171" x1="9.9581" y1="16.8063" x2="9.9581" y2="29.5674" gradientUnits="userSpaceOnUse">
<stop stopColor="#FEC110"/>
<stop offset="1" stopColor="#D6A000"/>
</linearGradient>
</defs>
</svg>

        


        


     );
}

export const Thunderstorm_N = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M23.8746 8.65118C23.623 8.27241 23.1774 8.07591 22.7331 8.14443C22.6156 8.15994 22.493 8.18192 22.3717 8.20906C20.1386 8.74168 18.3765 8.28792 17.2835 7.12832C16.1842 5.96226 15.7794 4.11492 16.2046 1.94568C16.2046 1.94568 16.3476 1.41695 15.842 1.07178C15.3466 0.77445 14.3762 1.48159 14.1451 1.72204C12.8299 2.90361 12 4.62814 12 6.54917C12 10.1094 14.8563 13 18.3714 13C20.3467 13 22.11 12.0899 23.2796 10.664C23.5132 10.4378 24.3036 9.28075 23.8746 8.65118Z" fill="#6CCCDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M23.8746 8.65118C23.623 8.27241 23.1774 8.07591 22.7331 8.14443C22.6156 8.15994 22.493 8.18192 22.3717 8.20906C20.1386 8.74168 18.3765 8.28792 17.2835 7.12832C16.1842 5.96226 15.7794 4.11492 16.2046 1.94568C16.2046 1.94568 16.3476 1.41695 15.842 1.07178C15.3466 0.77445 14.3762 1.48159 14.1451 1.72204C12.8299 2.90361 12 4.62814 12 6.54917C12 10.1094 14.8563 13 18.3714 13C20.3467 13 22.11 12.0899 23.2796 10.664C23.5132 10.4378 24.3036 9.28075 23.8746 8.65118Z" fill="url(#paint0_linear_1570_78172)" fillOpacity="0.7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.3789 7.19107H15.3956C17.9369 7.19107 20 9.16496 20 11.5958C20 13.218 19.1045 16 15.6716 16C6.77054 16 6.77054 16 6.77054 16C5.54193 16 4.60376 15.5855 3.96871 14.9334C3.33539 14.2824 3 13.3867 3 12.394C3 10.5226 4.49024 8.98417 6.39712 8.80502C6.83509 6.6382 8.83071 5 11.2228 5C12.9712 5 14.5076 5.87477 15.3789 7.19107V7.19107Z" fill="url(#paint1_linear_1570_78172)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M12.6627 19.6099L12.6942 19.6129C12.2375 20.6299 11.834 21.5944 11.7537 21.7879C11.474 22.2814 11.6307 22.9211 12.1212 23.2301C12.6117 23.5399 13.2575 23.4056 13.586 22.9339C13.7592 22.7426 14.7732 21.6086 15.6372 20.5594C16.358 19.6841 16.9482 18.8899 16.9482 18.8899C16.9565 18.8786 16.9647 18.8666 16.9722 18.8546C17.3157 18.3101 17.336 17.6216 17.0247 17.0576C16.7172 16.5004 16.1345 16.1516 15.4985 16.1441C15.4392 16.1404 15.2052 16.1261 14.8947 16.1179C15.074 15.3236 15.221 14.6006 15.2562 14.4259C15.44 13.8904 15.1692 13.2926 14.6322 13.0774C14.0952 12.8629 13.487 13.1096 13.2477 13.6286C13.1225 13.8259 12.4932 14.8301 11.96 15.7519C11.507 16.5341 11.1447 17.2294 11.1447 17.2294C11.1365 17.2459 11.1282 17.2631 11.1215 17.2804C10.9122 17.8039 10.976 18.3971 11.2917 18.8636C11.6015 19.3211 12.113 19.5986 12.6627 19.6099V19.6099Z" fill="url(#paint2_linear_1570_78172)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.95955 16.8145L6.97773 16.8162C6.71415 17.4031 6.48129 17.9597 6.43498 18.0714C6.27354 18.3562 6.364 18.7254 6.64706 18.9037C6.93012 19.0824 7.30278 19.005 7.49235 18.7327C7.59233 18.6224 8.1775 17.9679 8.6761 17.3624C9.09204 16.8573 9.43266 16.399 9.43266 16.399C9.43742 16.3925 9.44218 16.3856 9.44651 16.3786C9.64474 16.0644 9.65643 15.6671 9.47681 15.3416C9.29936 15.02 8.96306 14.8188 8.59603 14.8144C8.56184 14.8123 8.4268 14.8041 8.24761 14.7993C8.35106 14.3409 8.43589 13.9237 8.45623 13.8229C8.56227 13.5138 8.40602 13.1689 8.09613 13.0447C7.78623 12.9209 7.43522 13.0633 7.29715 13.3628C7.22487 13.4766 6.86174 14.0561 6.554 14.5881C6.29258 15.0395 6.08353 15.4407 6.08353 15.4407C6.07877 15.4502 6.07401 15.4602 6.07012 15.4702C5.94936 15.7723 5.98615 16.1146 6.16836 16.3838C6.34712 16.6478 6.6423 16.808 6.95955 16.8145V16.8145Z" fill="url(#paint3_linear_1570_78172)"/>
<defs>
<linearGradient id="paint0_linear_1570_78172" x1="12.8571" y1="1.27033e-05" x2="24.0487" y2="0.341087" gradientUnits="userSpaceOnUse">
<stop stopColor="#0085FF" stopOpacity="0"/>
<stop offset="1" stopColor="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78172" x1="10.6891" y1="12.5146" x2="10.6891" y2="31.3204" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78172" x1="17.8587" y1="19.5956" x2="17.8587" y2="41.7086" gradientUnits="userSpaceOnUse">
<stop stopColor="#FEC110"/>
<stop offset="1" stopColor="#D6A000"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78172" x1="9.9581" y1="16.8063" x2="9.9581" y2="29.5674" gradientUnits="userSpaceOnUse">
<stop stopColor="#FEC110"/>
<stop offset="1" stopColor="#D6A000"/>
</linearGradient>
</defs>
</svg>

        




     );
}
 
