import React, { useEffect, useState } from "react";

function MapTileChip(props) {
    const [detailTile, setDetailTile] = useState({
        Type: 0,
        Name: '',
        Active: false,
    })

    useEffect(() => {
        setDetailTile(props.detailTile)
    }, [props.detailTile])

    function onClickChip() {
        try {
            props.onSelectTile(detailTile);
        } catch { }
    }

    return (
        <div className={`
        py-1.5 px-4 
        flex justify-center items-center 
        cursor-pointer
        rounded-lg
        mx-1
        first-of-type:m-0
        last-of-type:m-0
        ${!detailTile.Active ? 'hover:bg-quartenary duration-300 ease-in-out' : ''}
        ${detailTile.Active ? 'bg-vekin-green100' : ''}
        `}
            onClick={(e) => { onClickChip() }}
        >
            <p className={`
                 ${detailTile.Active ? 'text-white text-xs font-bold' : 'text-secondary text-xs font-medium'}
            `}>
                {detailTile.Name}
            </p>
        </div>
    )
}

export default MapTileChip;