import React from "react";
import imgEdit from "../../../assets/siteManagement/images/edit-2.svg"

function BtnEdit(props) {
    function onClickFunc() {
        try {
            props.onClick();
        } catch { }
    }
    return (
        <div className={`h-full px-4
        flex items-center rounded-lg 
        ${props.disable ? 'text-tertiary cursor-default opacity-50' : 'text-vekin-green100 cursor-pointer hover:bg-quartenary'}
        duration-300 ease-in-out`}
            onClick={(e) => { if (!props.disable) onClickFunc() }}>
            <img className="w-3.5 h3.5 mr-2" src={imgEdit} />
            <p className={` text-xs font-semibold`}>
                {props.text}
            </p>
        </div>
    )
}

export default BtnEdit;