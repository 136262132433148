
import current_status from"../../assets/images/widgets/current-status-widget.jpg"
import performance_device_listed from"../../assets/images/widgets/performance-device-list.jpg"
import circle_device_status from"../../assets/images/widgets/circle-device-status.jpg"
import efficiency from "../../assets/images/widgets/efficiency.jpg"

import line_chart from"../../assets/images/widgets/line-chart-widget.jpg"
import plant_location from"../../assets/images/widgets/plant-location-widget.jpg"
import netzero from"../../assets/images/widgets/net-zero-widget.jpg"
import overview from"../../assets/images/widgets/overview-widget.jpg"
import performance from"../../assets/images/widgets/performance-widget.jpg"
import radar_chart from"../../assets/images/widgets/radar-chart.jpg"
import time_circle from"../../assets/images/widgets/time-circle-widget.jpg"
import weather from"../../assets/images/widgets/weather-widget.jpg"
import weather_aqi from"../../assets/images/widgets/weather-aqi-widget.jpg"
import total_value from"../../assets/images/widgets/total-value-widget.jpg"

import islanding from"../../assets/images/widgets/islanding-widget.jpg"
import plant_detail from"../../assets/images/widgets/plant-detail-widget.jpg"
import room_state_widget from "../../assets/images/widgets/room-state-widget.jpg"
import chiller_widget from "../../assets/images/widgets/chiller-widget.jpg"
import rectifier_widget from "../../assets/images/widgets/rectifier-widget.jpg"
import chiller_A1_widget from "../../assets/images/widgets/chiller_1A_widget.jpg"
import battery_alarm_widget from "../../assets/images/widgets/battery-alarm-widget.jpg"
import event_log_widget from "../../assets/images/widgets/event-log-widget.jpg"

import single_device from "../../assets/images/widgets/single_device_widget.jpg"
import gas_widget from "../../assets/images/widgets/gas_widget.jpg"

export const checkCustomImage = (name) => {
  if (!name) return null;
  switch (name) {
      // new widgets
      case 'Line Chart':
        return line_chart

      case 'Radar Chart':
        return radar_chart

      case 'Overview Widget':
        return overview

      case 'Circle Device Status Widget':
        return circle_device_status

      case 'Weather Widget':
        return weather

      case 'Performance Widget':
        return performance

      case 'Net Zero Widget':
        return netzero

      case 'Plant Detail Widget':
        return plant_detail;

      case 'Weather AQI Widget':
        return weather_aqi
        
      case 'Plant Location Widget':
        return plant_location

      case 'Total Value Widget':
        return total_value

      case 'Time Circle Widget':
        return time_circle

      case 'Performance Device Listed':
        return performance_device_listed

      case 'Current Status Widget':
        return current_status
      
      case 'Efficiency Widget':
          return efficiency
      
      case 'Room State Widget':
        return room_state_widget

      case 'Battery':
          return 

      case 'Islanding Widget':
          return islanding

      case 'Floor Plan Device List Widget' :
          return 

      case 'Static Map Device Widget':
          return 
      
      case 'Chiller Widget':
          return chiller_widget
      
      case 'Chiller_1A Widget':
          return chiller_A1_widget;

      case 'Rectifier Widget':
          return rectifier_widget
      
      case 'Battery Alarm Widget':
          return battery_alarm_widget;

      case 'Event Log Widget':
          return event_log_widget;
      
      case 'Single Device Widget':
          return single_device;

      case 'Gas Widget':
          return gas_widget;
 
      default:
          return null;
  }
};

