import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/auth/auth_context";
import { useTranslation } from "react-i18next";
import bg_image from "../../assets/backgroundImages/company_bg.jpg";
import { GOOGLE_CLIENT_ID } from "../../shared/config";
import IconVisibilityOff from "../../assets/images/ic_visibility_off.svg";
import IconVisibility from "../../assets/images/ic_visibility.svg";
import { login, googleSignIn } from "../../context/auth/auth_action";
import { GoogleLogin, GoogleLogout } from "react-google-login";
import { gapi } from "gapi-script";
// import { SignInAndRegisterGoogle } from "../../api/userInfo";
// import { ResponsiveContainer } from "recharts";

const LoginPage = ({ isDap }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { dispatch } = authContext;
  const { t } = useTranslation("login");
  const [form, setForm] = useState({
    username: "",
    password: "",
    is_remember: true,
    is_vekin_login: true,
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [rememberPassword, setRememberPassword] = useState(false);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  }, []);

  const onClickShowPassword = () => {
    setIsShowPassword(!isShowPassword);
  };

  const onInput = (field) => (e) => {
    setForm({
      ...form,
      [field]: e.target.value,
    });
  };

  const onCheckBox = (field) => (e) => {
    setRememberPassword(e.target.checked);
    setForm({
      ...form,
      [field]: e.target.checked,
    });
  };

  const onSubmit = () => {
    login(dispatch, form);
    // TODO: dap redirect enhancement
    if (isDap) {
      localStorage.setItem("isDap", true);
    }
  };

  const onSuccess = async (resp) => {
    const request = {
      auth_token: resp.tokenId,
    };
    googleSignIn(dispatch, request);
    // const { data, error } = await SignInAndRegisterGoogle(request);
    // console.log("success:", resp.tokenId);
  };

  const onFailure = (err) => {
    console.log("failed:", err);
  };

  return (
    <div
      className="relative flex items-center justify-center w-full h-screen bg-black bg-no-repeat bg-cover"
      style={{ backgroundImage: `url(${bg_image})` }}
    >
      <div className="flex flex-col justify-between p-8 bg-white shadow-md rounded-xl w-96">
        <div>
          <h2 className="mb-4 text-3xl font-bold text-vekin">Sign in</h2>
          {authContext.error && (
            <div style={{ marginTop: 0, color: "#AF2324", fontSize: 16 }}>
              {t("login:error")}
            </div>
          )}
          <input
            type="text"
            className="w-full "
            value={form.username}
            placeholder={t("login:email")}
            onChange={onInput("username")}
            style={{
              marginTop: 4,
              height: 48,
              border: "2px solid rgba(172, 192, 214, 0.5)",
              borderRadius: 10,
              padding: "0px 24px",
              fontSize: 14,
              color: "#809FB8",
            }}
          />
          <div className="relative mt-[20px]">
            <input
              type={isShowPassword ? "text" : "password"}
              className="w-full"
              value={form.password}
              placeholder={t("login:password")}
              onChange={onInput("password")}
              style={{
                height: 48,
                border: "2px solid rgba(172, 192, 214, 0.5)",
                borderRadius: 10,
                padding: "0px 50px 0px 24px",
                fontSize: 14,
                color: "#809FB8",
              }}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5">
              <img
                src={isShowPassword ? IconVisibilityOff : IconVisibility}
                alt=""
                className="cursor-pointer"
                onClick={onClickShowPassword}
              />
            </div>
          </div>

          <div
            className="flex flex-row items-center justify-end gap-4 mx-2 accent-emerald-700"
            style={{ marginTop: 20 }}
          >
            {/* <label
              style={{ fontSize: 12, color: "#333333", fontWeight: 500 }}
              className="inline-flex items-center "
            >
              <input
                name="keep-signin"
                id="keep-signin"
                type="checkbox"
                className="peer"
                value={form.is_remember}
                onChange={onCheckBox("is_remember")}
                style={{
                  border: "2px solid rgba(172, 192, 214, 0.5)",
                  borderRadius: 4,
                  marginRight: 8,
                }}
              />
              <span className={rememberPassword ? "text-emerald-700" : ""}>
                {t("login:remember_me")}
              </span>
            </label> */}
            <div
              style={{ color: "#333333", fontSize: 12, fontWeight: 700 }}
              className=""
            >
              <Link to={"/forget-password"}>{t("login:forgot_password")}</Link>
            </div>
         

          </div>
          <div
            className="w-full cursor-pointer"
            onClick={onSubmit}
            style={{
              height: 48,
              marginTop: 24,
              backgroundColor: "#1FA37C",
              padding: 12,
              borderRadius: 6,
              fontSize: 16,
              fontWeight: 500,
              color: "white",
              textAlign: "center",
            }}
          >
            {t("login:sign_in")}
          </div>
        </div>

        <div className="flex justify-center w-full gap-2 pt-10 text-sm text-md">
          <p>Need here? </p>

          {/* <p className="font-semibold text-vekin">Create new account</p> */}
          <Link
            to={`/signup`}
            className="font-semibold text-vekin hover:underline "
          >
            Create new account
          </Link>
        </div>

        <div className="flex justify-center pt-10">
          <GoogleLogin
            theme="dark"
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Sign in with Google"
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={"single_host_origin"}
          >
            <p className="text-xs">Sign in with Google</p>
          </GoogleLogin>
        </div>
      </div>
    </div>
   
  );
};

export default LoginPage;
