const Icon_BurnedReduction = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#F2F8F6"/>
        <path d="M30.9011 22.1176C28.8619 18.8235 29.0188 16.3137 29.2541 14.5098C29.4109 13.2549 29.6462 12 28.8619 12C28.0776 12 26.1952 13.7255 25.5678 14.6667C24.7835 15.9216 23.2149 17.8824 22.9796 21.0196C21.8815 20.2353 21.1756 18.9804 21.2541 17.8824C21.2541 16.7059 21.0188 15.8431 20.4698 15.7647C19.9207 15.7647 19.2149 16.6275 18.4305 17.5686C16.8619 19.5294 15.058 23.3725 15.4501 27.1373C15.5286 28.2353 15.9207 31.8431 18.9796 34.1961C20.5482 35.3725 22.4305 36 24.3913 36H24.4698C29.6462 36 31.9207 32 32.4698 29.098C33.0188 26.1176 31.7639 23.1373 30.9011 22.1176ZM24.3913 33.5686C22.9796 33.5686 21.5678 33.098 20.3913 32.2353C18.1168 30.4314 17.8031 27.6863 17.7247 26.8235C17.5678 24.549 18.1952 21.6471 19.5286 19.7647C19.7639 20.2353 20.2345 21.3333 21.3325 22.4314C22.4305 23.5294 23.5286 24.2353 25.5678 24.549C24.0776 23.0588 25.8815 17.6471 26.8227 16.549C26.2737 18.5882 28.0776 22.1176 29.0188 23.3725C29.6462 24.2353 30.2737 25.8039 30.2737 27.451C30.2737 29.098 29.4894 31.1373 28.3129 32.1569C27.2149 33.098 25.8031 33.5686 24.3913 33.5686Z" fill="#DC5D5E"/>
        </svg>

     );
}
 
export default Icon_BurnedReduction;