import { Icon_Battery2 } from '../../../components/widgets/widget_icons/general_icons'
import {
  Air_Icon,
  BurnedReduction_Icon,
  CarbonAvoid_Icon,
  Cctv_Icon,
  CoalPotential_Icon,
  CoinSave_Icon,
  Devices_Icon,
  ElectricPower_Icon,
  ElectricSolar_Icon,
  EvPods_Icon,
  Grid_Icon,
  Power_Icon,
  Pressure_Icon,
  Radiation_Icon,
  RecPotential_Icon,
  Solar_Icon,
  TemperatureRound_Icon,
  Temperature_Icon,
  Tree_Icon,
  Turbine_Icon,
  WeatherHumidity_Icon
} from '../../../components/widgets/widget_layouts/icons/realTimeEnergy'
import {
  ClearSky_N,
  ClearSky_S,
  FewClouds_N,
  FewClouds_S,
  Mist_N,
  Mist_S,
  Rain_N,
  Rain_S,
  ScatteredClouds_N,
  ScatteredClouds_S,
  ShowerRain_N,
  ShowerRain_S,
  Thunderstorm_N,
  Thunderstorm_S
} from '../../../components/widgets/widget_layouts/icons/weather'

export const chartIconList = [
  'electric_power',
  'coal_potential',
  'rec_potential',
  'electric_solar',
  'solar_energy',
  'turbine',
  'tree',
  'coin_save',
  'carbon_avoid',
  'grid',
  'temperature',
  'radiation',
  'air',
  'cctv',
  'power',
  'devices',
  'ev_pods',
  'burned_reduction',
  'pressure',
  'weather_humidity',
  'temperature_round',
  'battery2'
]
export const checkCustomIcon = (name, style, stroke) => {
  if (!name) return null
  switch (name) {
    case 'electric_power':
      return <ElectricPower_Icon stroke={stroke} style={style} /> //already customised the colors
    case 'coal_potential':
      return <CoalPotential_Icon stroke={stroke} style={style} />
    case 'rec_potential':
      return <RecPotential_Icon stroke={stroke} style={style} extraStyle={style} />
    case 'electric_solar':
      return <ElectricSolar_Icon stroke={stroke} style={style} extraStyle={style} />
    case 'solar_energy':
      return <Solar_Icon stroke={stroke} style={style} extraStyle={style} />
    case 'turbine':
      return <Turbine_Icon stroke={stroke} style={style} extraStyle={style} />
    case 'tree':
      return <Tree_Icon stroke={stroke} style={style} />
    case 'coin_save':
      return <CoinSave_Icon stroke={stroke} style={style} />
    case 'carbon_avoid':
      return <CarbonAvoid_Icon stroke={stroke} style={style} />
    case 'grid':
      return <Grid_Icon stroke={stroke} style={style} />
    case 'temperature':
      return <Temperature_Icon stroke={stroke} style={style} />
    case 'radiation':
      return <Radiation_Icon stroke={stroke} style={style} />
    case 'air':
      return <Air_Icon stroke={stroke} style={style} />
    case 'power':
      return <Power_Icon stroke={stroke} style={style} />
    case 'devices':
      return <Devices_Icon stroke={stroke} style={style} />
    case 'cctv':
      return <Cctv_Icon stroke={stroke} style={style} />
    case 'ev_pods':
      return <EvPods_Icon stroke={stroke} style={style} />
    case 'burned_reduction':
      return <BurnedReduction_Icon stroke={stroke} style={style} />
    case 'pressure':
      return <Pressure_Icon stroke={stroke} style={style} />

    // add the new icon
    case 'weather_humidity':
      return <WeatherHumidity_Icon stroke={stroke} style={style} />
    case 'temperature_round':
      return <TemperatureRound_Icon stroke={stroke} style={style} />

    case 'battery2':
      return <Icon_Battery2 stroke={stroke} style={style} />

    default:
      return <div className={`${style} rounded-md bg-gray-200/50`}> </div>
  }
}

export const checkCustom_weather_icon = (type, style = '') => {
  if (!type) return null

  switch (type) {
    case '01d':
      return <ClearSky_S style={style} />
    case '01n':
      return <ClearSky_N style={style} />

    case '02d':
      return <FewClouds_S style={style} />
    case '02n':
      return <FewClouds_N style={style} />

    case '03d':
    case '04d':
      return <ScatteredClouds_S style={style} />
    case '03n':
    case '04n':
      return <ScatteredClouds_N style={style} />

    case '09d':
      return <ShowerRain_S style={style} />
    case '09n':
      return <ShowerRain_N style={style} />

    case '10d':
      return <Rain_S style={style} />
    case '10n':
      return <Rain_N style={style} />

    case '11d':
      return <Thunderstorm_S style={style} />
    case '11n':
      return <Thunderstorm_N style={style} />

    case '50d':
      return <Mist_S style={style} />
    case '50n':
      return <Mist_N style={style} />

    default:
      return null
  }
}
