import React, { useEffect, useState } from "react";
import imgArrow from '../../../assets/siteManagement/images/arrow-1.svg'

function ButtonBack(props) {
    const [detailBtn, setDetailBtn] = useState({
        Text: '',
    })

    useEffect(() => {
        if (props.text) {
            let detail = {
                ...detailBtn,
                Text: props.text,
            }
            setDetailBtn(detail);
        }
    }, [props.text])

    function onClickButton() {
        try {
            props.onClick();
        } catch { }
    }

    return (
        <div className={`
        py-1.5 px-4 
        flex justify-center items-center 
        cursor-pointer
        rounded-lg
        hover:bg-vekin-green40 duration-300 ease-in-out
        bg-vekin-green-opacity10
        `}
            onClick={(e) => { onClickButton() }}
        >
            <img className="w-4 h-3.5 mr-2" src={imgArrow} />
            <p className={`text-darkGreen text-xs font-semibold`}>
                {detailBtn.Text}
            </p>
        </div>
    )
}

export default ButtonBack;