export const formateWidgetData =(data_)=>{
    return  data_?.map((elm) => ({
        grid: {
            'data': elm?.dataURL,
            'h': elm?.h,
            'w': elm?.w,
            'x': elm?.x,
            'y': elm?.y
        },
        name: elm?.name,
        id: elm?.id
    }))
   }

