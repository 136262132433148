import React, {
    useState,
    useEffect,
    useRef,
} from "react";
import L from 'leaflet';
import 'leaflet.markercluster';

import imgPin from '../../../assets/siteManagement/images/pin.svg'

let map;

function GisMapInputComponent(props) {
    const [id, setId] = useState('');
    const [gisMap, setGisMap] = useState(null);
    const [mapPosition, setMapPosition] = useState({
        latitude: 13.8583,
        longitude: 100.4688,
        zoom: 5,
        zoomSnap: 0.5,
        maxZoom: 19,
        minZoom: 1,
    })
    const markerDetailRef = useRef(null);
    const [detailLatlngMapClick, setDetailLatlngMapClick] = useState(null);

    useEffect(() => {
        const init = async () => {
            setId(randomString(10));
        }
        init();
    }, [])
    useEffect(() => {
        if (id) {
            onInitailizationMap();
        }
    }, [id])
    useEffect(() => {
        if (
            props.detailLatLng
            && gisMap
        ) {
            onSetMarkerSite(props.detailLatLng);
        }
    }, [props.detailLatLng, gisMap]);
    useEffect(() => {
        if (detailLatlngMapClick) {
            try {
                props.onChangeLatLng(detailLatlngMapClick);
            } catch { }
        }
    }, [detailLatlngMapClick])

    function randomString(length) {
        var text = "";
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    async function onInitailizationMap() {
        try {
            if (!gisMap) {
                map = L.map(`map${id}`, {
                    center: [mapPosition.latitude, mapPosition.longitude],
                    zoom: mapPosition.zoom,
                    zoomSnap: mapPosition.zoomSnap,
                })
                setGisMap(map)
            } else {
                map = gisMap;
            }
            //==============================
            let tileLayer = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
            const tiles = L.tileLayer(tileLayer, {
                maxZoom: mapPosition.maxZoom,
                minZoom: mapPosition.minZoom,
            });
            tiles.addTo(map);
            //==============================
            map.zoomControl.setPosition('bottomright');
            //==============================
            await onSetMapEvent();
            //==============================
        } catch (e) { }
    }
    function onSetMapEvent() {
        if (!map) return;

        map.on('click', (event) => {
            const target = event.target
            const center = target.getCenter();
            const latlng = event.latlng;
            const detail = {
                Latitude: latlng.lat,
                Longitude: latlng.lng,
            }
            onSetMarkerSite(detail);
            setDetailLatlngMapClick(detail)
        });
    }
    //==============================
    async function onSetMarkerSite(param) {
        if (!param.Latitude || !param.Longitude) return;
        if (map && markerDetailRef.current) map.removeLayer(markerDetailRef.current);
        //================================
        const marker = await onCreateMarkerSite(param);
        if (map) {
            map.addLayer(marker);
        }
        markerDetailRef.current = marker;
        //================================
        onSetMapFocus(param)
    }
    async function onCreateMarkerSite(param) {
        if (!map) return;
        if (!param.Latitude || !param.Longitude) return;

        let marker = L.marker([param.Latitude, param.Longitude], {
            draggable: true,
        });
        //==============================
        let icon;
        let svgPin = '';
        svgPin = '<div style="display: flex;align-items: center;justify-content: center;position: relative;">' +
            `<img style='width: 24px;position: absolute;' src='${imgPin}' />` +
            '</div>';
        icon = L.divIcon({
            html: svgPin,
            className: '',
            iconAnchor: [0, 0],
        });
        marker.options.icon = icon;
        marker.options.alt = JSON.stringify(param);
        //==============================
        //=============event============
        marker.on('dragend', (event) => {
            const element = event.target;
            const latlng = element.getLatLng();
            let obj;
            try {
                obj = JSON.parse(element.options.alt);
            } catch { }
            if (obj) {
                obj = {
                    ...obj,
                    Latitude: latlng.lat,
                    Longitude: latlng.lng,
                };
                element.options.alt = JSON.stringify(obj);
            }
            //==============================
            const detail = {
                Latitude: latlng.lat,
                Longitude: latlng.lng,
            }
            onSetMapFocus(detail);
            setDetailLatlngMapClick(detail);
        });
        //==============================
        return marker
    }
    function onSetMapFocus(param) {
        if (!map) return;
        const currentZoom = map.getZoom();
        const zoom = currentZoom > 10 ? currentZoom : 10;
        const center = [param.Latitude, param.Longitude];
        map.invalidateSize();
        map.setView(center, zoom);

    }
    //==============================

    return (
        <div id={`map${id}`} className="w-full h-full">
        </div>
    )
}

export default GisMapInputComponent;