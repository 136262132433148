const Rectifier_Icon = ({ style }) => {
  return (
<svg className={style} width="50" height="50" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M250 492L113 432.7V410.7L250 469.8V492Z" fill="url(#paint0_linear_7859_111779)"/>
<path d="M250 492L387 432.7V410.7L250 469.8V492Z" fill="url(#paint1_linear_7859_111779)"/>
<path d="M392.2 85.7L250 148.3L107.8 85.7L250 22.5L392.2 85.7Z" fill="url(#paint2_linear_7859_111779)"/>
<path d="M250 477.5L107.8 415.9V85.7L250 147V477.5Z" fill="url(#paint3_linear_7859_111779)"/>
<path d="M250 477.5L392.2 415.9V85.7L250 147V477.5Z" fill="url(#paint4_linear_7859_111779)"/>
<path d="M170.1 396.3L122.4 376.8V371.1L170.1 390.4V396.3Z" fill="url(#paint5_radial_7859_111779)"/>
<path d="M170.1 374.9L122.4 355.5V349.7L170.1 369.1V374.9Z" fill="url(#paint6_radial_7859_111779)"/>
<path d="M170.1 385.6L122.4 366.1V360.4L170.1 379.7V385.6Z" fill="url(#paint7_radial_7859_111779)"/>
<path d="M170.1 417.6L122.4 398.2V392.5L170.1 411.8V417.6Z" fill="url(#paint8_radial_7859_111779)"/>
<path d="M170.1 406.9L122.4 387.5V381.7L170.1 401.1V406.9Z" fill="url(#paint9_radial_7859_111779)"/>
<path d="M170.1 364.2L122.4 344.8V339.1L170.1 358.4V364.2Z" fill="url(#paint10_radial_7859_111779)"/>
<path d="M170.1 353.5L122.4 334.1V328.3L170.1 347.7V353.5Z" fill="url(#paint11_radial_7859_111779)"/>
<path d="M226.6 420.7L178.9 401.3V395.5L226.6 414.8V420.7Z" fill="url(#paint12_radial_7859_111779)"/>
<path d="M226.6 399.4L178.9 379.9V374.2L226.6 393.6V399.4Z" fill="url(#paint13_radial_7859_111779)"/>
<path d="M226.6 410L178.9 390.6V384.8L226.6 404.2V410Z" fill="url(#paint14_radial_7859_111779)"/>
<path d="M226.6 442L178.9 422.7V416.9L226.6 436.2V442Z" fill="url(#paint15_radial_7859_111779)"/>
<path d="M226.6 431.4L178.9 412V406.2L226.6 425.5V431.4Z" fill="url(#paint16_radial_7859_111779)"/>
<path d="M226.6 388.6L178.9 369.3V363.5L226.6 382.9V388.6Z" fill="url(#paint17_radial_7859_111779)"/>
<path d="M226.6 378L178.9 358.6V352.8L226.6 372.1V378Z" fill="url(#paint18_radial_7859_111779)"/>
<path d="M327.8 394.4L375.5 375V369.2L327.8 388.5V394.4Z" fill="url(#paint19_radial_7859_111779)"/>
<path d="M327.8 373.1L375.5 353.6V347.9L327.8 367.3V373.1Z" fill="url(#paint20_radial_7859_111779)"/>
<path d="M327.8 383.7L375.5 364.3V358.5L327.8 377.9V383.7Z" fill="url(#paint21_radial_7859_111779)"/>
<path d="M327.8 415.7L375.5 396.4V390.6L327.8 410V415.7Z" fill="url(#paint22_radial_7859_111779)"/>
<path d="M327.8 405.1L375.5 385.7V379.9L327.8 399.2V405.1Z" fill="url(#paint23_radial_7859_111779)"/>
<path d="M327.8 341L375.5 321.6V315.8L327.8 335.1V341Z" fill="url(#paint24_radial_7859_111779)"/>
<path d="M327.8 319.7L375.5 300.2V294.5L327.8 313.9V319.7Z" fill="url(#paint25_radial_7859_111779)"/>
<path d="M327.8 330.3L375.5 310.9V305.1L327.8 324.5V330.3Z" fill="url(#paint26_radial_7859_111779)"/>
<path d="M327.8 362.3L375.5 343V337.2L327.8 356.6V362.3Z" fill="url(#paint27_radial_7859_111779)"/>
<path d="M327.8 351.7L375.5 332.3V326.5L327.8 345.8V351.7Z" fill="url(#paint28_radial_7859_111779)"/>
<path d="M271.3 418.9L319 399.4V393.7L271.3 413V418.9Z" fill="url(#paint29_radial_7859_111779)"/>
<path d="M271.3 397.5L319 378.1V372.3L271.3 391.7V397.5Z" fill="url(#paint30_radial_7859_111779)"/>
<path d="M271.3 408.2L319 388.8V383L271.3 402.3V408.2Z" fill="url(#paint31_radial_7859_111779)"/>
<path d="M271.3 440.2L319 420.9V415.1L271.3 434.4V440.2Z" fill="url(#paint32_radial_7859_111779)"/>
<path d="M271.3 429.6L319 410.1V404.4L271.3 423.7V429.6Z" fill="url(#paint33_radial_7859_111779)"/>
<path d="M271.3 365.5L319 346V340.3L271.3 359.6V365.5Z" fill="url(#paint34_radial_7859_111779)"/>
<path d="M271.3 344.1L319 324.7V318.9L271.3 338.4V344.1Z" fill="url(#paint35_radial_7859_111779)"/>
<path d="M271.3 354.8L319 335.4V329.6L271.3 348.9V354.8Z" fill="url(#paint36_radial_7859_111779)"/>
<path d="M271.3 386.8L319 367.5V361.7L271.3 381V386.8Z" fill="url(#paint37_radial_7859_111779)"/>
<path d="M271.3 376.2L319 356.7V351L271.3 370.3V376.2Z" fill="url(#paint38_radial_7859_111779)"/>
<path d="M243.7 466.1L114.1 410V97.1001L243.7 153V466.1Z" stroke="#09726A" stroke-miterlimit="10"/>
<path d="M207.4 236.3L206 237.5L150.3 211.6L148.9 167.6L150.3 166.5L207.4 191.1V236.3Z" fill="url(#paint39_linear_7859_111779)"/>
<path d="M206 237.5L148.9 212.8V167.6L206 192.2V237.5Z" fill="url(#paint40_radial_7859_111779)"/>
<path d="M183.6 202.6L155.8 190.8V181.7L183.6 193.5V202.6Z" fill="white"/>
<path d="M162.7 205.6C162.7 208.2 161.1 209.5 159 208.6C157 207.7 155.3 204.9 155.3 202.3C155.3 199.7 156.9 198.4 159 199.3C161.1 200.2 162.7 203.1 162.7 205.6Z" fill="url(#paint41_linear_7859_111779)"/>
<path d="M173.1 210.6C173.1 213.2 171.5 214.5 169.4 213.6C167.4 212.7 165.7 209.9 165.7 207.3C165.7 204.7 167.3 203.4 169.4 204.3C171.5 205.2 173.1 208 173.1 210.6Z" fill="url(#paint42_linear_7859_111779)"/>
<path d="M183.6 215.5C183.6 218.1 182 219.4 179.9 218.5C177.9 217.6 176.2 214.8 176.2 212.2C176.2 209.6 177.8 208.3 179.9 209.2C181.9 210.1 183.6 212.9 183.6 215.5Z" fill="url(#paint43_linear_7859_111779)"/>
<path d="M376.3 85.7L250 141.3L123.7 85.7L250 29.5L376.3 85.7Z" fill="url(#paint44_linear_7859_111779)"/>
<path d="M368.3 89.2001L250 141.3L131.7 89.2001L250 36.6001L368.3 89.2001Z" fill="url(#paint45_linear_7859_111779)"/>
<defs>
<linearGradient id="paint0_linear_7859_111779" x1="111.523" y1="394.281" x2="316.315" y2="560.939" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint1_linear_7859_111779" x1="246.514" y1="379.361" x2="359.134" y2="491.981" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint2_linear_7859_111779" x1="250" y1="6.5329" x2="250" y2="219.841" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint3_linear_7859_111779" x1="65.2421" y1="167.811" x2="342.453" y2="445.022" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint4_linear_7859_111779" x1="448.889" y1="319.277" x2="225.718" y2="188.4" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<radialGradient id="paint5_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint6_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint7_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.329) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint8_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint9_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint10_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint11_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint12_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint13_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint14_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint15_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint16_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint17_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint18_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint19_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint20_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint21_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint22_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint23_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint24_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint25_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint26_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint27_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint28_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint29_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint30_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint31_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint32_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint33_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint34_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint35_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint36_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint37_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.35)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint38_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(251.871 482.328) scale(131.933 155.351)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<linearGradient id="paint39_linear_7859_111779" x1="151.748" y1="174.181" x2="217.808" y2="240.241" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<radialGradient id="paint40_radial_7859_111779" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(128.418 147.296) scale(53.7198 63.2551)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<linearGradient id="paint41_linear_7859_111779" x1="161.421" y1="192.739" x2="155.637" y2="219.343" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint42_linear_7859_111779" x1="172.354" y1="195.508" x2="166.57" y2="222.112" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint43_linear_7859_111779" x1="183.287" y1="198.277" x2="177.503" y2="224.881" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint44_linear_7859_111779" x1="260.626" y1="272.336" x2="247.393" y2="39.8987" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint45_linear_7859_111779" x1="250" y1="46.7305" x2="250" y2="260.039" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

  )
}

export default Rectifier_Icon
