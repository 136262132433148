import { Link } from "react-router-dom";
// TODO: to add more button styles and also refactor the existing ones
const Button = ({ primary, secondary,tertiary, danger, tabs, isLink, className, children, ...props }) => {
    let buttonClasses =''
    let gradientBackground = ''
    let global = 'text-sm py-2 px-4 flex gap-1 items-center rounded-lg duration-300 ease-in-out hover:opacity-70 group hover:shadow-sm'

    if(primary){
        buttonClasses = `${global} text-white`
        gradientBackground= 'linear-gradient(90.58deg, #1FA37C 1.6%, rgba(31, 163, 124, 0) 101.09%), #79C8B0' 
    }else if(secondary){
        buttonClasses = `${global} text-[#1FA37C] border-[#1FA37C] border`
    }else if (tertiary){
        buttonClasses = `${global} text-[#1FA37C]  border bg-white/80`
    }else if (danger){
      buttonClasses = `${global} text-white`
      gradientBackground= 'linear-gradient(90.92deg, #DC5D5E 1.04%, #D8907D 95.96%), #D8907D'
    }else if (tabs){
        //used on icon selection (widget setup)
        buttonClasses = `text-gray-500 border rounded-t-lg inline-flex items-center gap-2 px-4 py-2 -mb-px text-[12px] font-medium text-center 
        ${props.active?'bg-vekin-green-40/60 text-gray-700':'bg-gray-50 hover:text-gray-700'}`
    }

    return (
      <>
      {isLink?
        <Link {...props} className={buttonClasses}>  
            {children}
          </Link>
          :
          <button disabled={props.disabled ?? false} className={buttonClasses} {...props} style={{background: gradientBackground}} >
            {children}
          </button>
      
        }
      </>
      
    );
  };

  export default Button; ;


