const Battery_3d_Icon=({style})=>{
    return(
        <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.08" d="M250 496.5C328.259 496.5 391.7 459.877 391.7 414.7C391.7 369.523 328.259 332.9 250 332.9C171.741 332.9 108.3 369.523 108.3 414.7C108.3 459.877 171.741 496.5 250 496.5Z" fill="black"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M384 177.1C384 177.1 384 377.1 384 396.9C384 416.7 370.9 436.5 344.8 451.6C292.5 481.8 207.6 481.8 155.3 451.6C129.1 436.5 116.1 416.7 116.1 396.9C116.1 377.1 116.1 180.3 116.1 180.3L384 177.1Z" fill="url(#paint0_radial_5914_87594)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M384 80.8999C384 80.8999 384 157.3 384 177.1C384 196.9 370.9 216.7 344.8 231.8C292.5 262 207.6 262 155.3 231.8C129.1 216.7 116.1 196.9 116.1 177.1C116.1 157.3 116.1 84.0999 116.1 84.0999L384 80.8999Z" fill="url(#paint1_linear_5914_87594)"/>
<path d="M250 158.3C324.006 158.3 384 123.647 384 80.9C384 38.1532 324.006 3.5 250 3.5C175.994 3.5 116 38.1532 116 80.9C116 123.647 175.994 158.3 250 158.3Z" fill="#40C5FF"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M292.7 38C292.7 38 292.7 69.7 292.7 76C292.7 82.3 288.5 88.6 280.2 93.4C263.5 103 236.5 103 219.8 93.4C211.5 88.6 207.3 82.3 207.3 76C207.3 69.7 207.3 39 207.3 39L292.7 38Z" fill="url(#paint2_linear_5914_87594)"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M280.2 21.0999C296.9 30.6999 296.9 46.2999 280.2 55.9999C263.5 65.5999 236.5 65.5999 219.8 55.9999C203.1 46.3999 203.1 30.7999 219.8 21.0999C236.5 11.4999 263.5 11.4999 280.2 21.0999Z" fill="#CCCCCC"/>
<path d="M349.3 208.7C346.6 208.7 344.3 206.5 344.3 203.7V160.1C344.3 157.4 346.5 155.1 349.3 155.1C352 155.1 354.3 157.3 354.3 160.1V203.7C354.2 206.5 352 208.7 349.3 208.7Z" fill="white"/>
<path d="M330.3 197.8C328.6 197.8 326.9 196.9 326 195.3C324.6 192.9 325.4 189.9 327.8 188.5L365.8 166.6C368.2 165.2 371.2 166 372.6 168.4C374 170.8 373.2 173.8 370.8 175.2L332.8 197.1C332 197.6 331.1 197.8 330.3 197.8Z" fill="white"/>
<path d="M330.3 401.1C328.6 401.1 326.9 400.2 326 398.6C324.6 396.2 325.4 393.2 327.8 391.8L365.8 369.9C368.2 368.5 371.2 369.3 372.6 371.7C374 374.1 373.2 377.1 370.8 378.5L332.8 400.4C332 400.8 331.1 401.1 330.3 401.1Z" fill="white"/>
<defs>
<radialGradient id="paint0_radial_5914_87594" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(124.842 207.319) scale(523.871 523.871)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<linearGradient id="paint1_linear_5914_87594" x1="162.584" y1="51.9431" x2="447.8" y2="337.159" gradientUnits="userSpaceOnUse">
<stop stop-color="#29ABE2"/>
<stop offset="0.2524" stop-color="#27A8DF"/>
<stop offset="0.4563" stop-color="#239ED5"/>
<stop offset="0.6434" stop-color="#1B8DC4"/>
<stop offset="0.8203" stop-color="#0F75AC"/>
<stop offset="0.9886" stop-color="#01568E"/>
<stop offset="1" stop-color="#00548C"/>
</linearGradient>
<linearGradient id="paint2_linear_5914_87594" x1="220.591" y1="69.3" x2="455.167" y2="69.3" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>
    )
}
export default Battery_3d_Icon;