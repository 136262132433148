import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VerificationInput from 'react-verification-input'
import { sendVerify, verifyOtpEmail } from '../../api/userInfo'

const Verify = ({ verify, closeModal, userEmail }) => {
  const [OTP, setOtp] = useState('')
  const [minutes, setMinutes] = useState(5)
  const [seconds, setSeconds] = useState(0)
  const [codeExpire, setCodeExpire] = useState(false)
  const [errorInput, setErrorInput] = useState(false)
  const [expire, setExpire] = useState(false)
  const navigate = useNavigate()

  const resendOTP = async () => {
    setMinutes(0)
    setSeconds(30)
    setOtp('')
    const { verifyData, error } = await sendVerify({ email: userEmail })
    if (!error) {
      console.log(verifyData);
    } else {
      alert(error)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval)
        } else {
          setSeconds(59)
          setMinutes(minutes - 1)
        }
      }
      if (minutes === 0 && seconds === 0) {
        setCodeExpire(true)
      } else {
        setCodeExpire(false)
      }
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [seconds])

  const handleOnSubmit = async () => {
    const formData = {
      email: userEmail,
      otp: OTP
    }
    const { data, error } = await verifyOtpEmail(formData)
    console.log('tet', data.detail)
    if (data.detail === 'Success.') {
      // console.log('tet1111', data.detail)
      navigate('/')
    } else if (error.response.data.detail === 'OTP not found.') {
      // console.log(error.response.data.detail, 'line 63')
      setErrorInput(error.response.data.detail)
      setExpire(true)
    } else {
      setErrorInput(error.response.data.detail)
      setExpire(true)
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (expire) {
        setErrorInput(false)
        setExpire(false)
      }
    }, 5000)
    return () => {
      clearInterval(interval)
    }
  }, [expire])

  return (
    <div className={`${verify ? 'block' : 'hidden'}`}>
      <div className="absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden">
        <div className="max-w-sm p-4 mx-auto text-center bg-white rounded h-50 md:max-w-lg">
          <h1 className="text-3xl font-bold text-center text-vekin">Verify Email</h1>
          <div className="flex flex-col mt-4">
            <p>
              A verification code has been sent to <p className="font-bold">{userEmail}</p>{' '}
            </p>
            <p>
              Please check your inbox and enter the verification code below to verify your email
              address.
            </p>
            <p>
              The code will expire in {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}{' '}
            </p>
            {codeExpire && (
              <span className="text-[12px] text-danger">
                The code has expired. Please request a new code.
              </span>
            )}
          </div>

          <VerificationInput
            value={OTP}
            onChange={setOtp}
            autoFocus={false}
            placeholder=" "
            classNames={{
              container: 'ml-24 my-6',
              character: 'text-xl text-vekin rounded border-vekin',
              characterInactive: ' ',
              characterSelected: ' '
            }}
          />
          {errorInput && <span className="text-[12px] text-danger">{errorInput}</span>}

          <div className="grid grid-cols-3 gap-12">
            <button
              onClick={() => closeModal()}
              className="inline-flex items-center w-full gap-2 p-2 text-center border border-gray-300 rounded-md text-md text-vekin hover:shadow-md"
            >
              <i className="ri-arrow-left-line"></i>
              Previous
            </button>
            <button
              className={`inline-flex items-center w-full gap-2 py-2 text-center text-md  ${
                seconds > 0 || minutes > 0 ? 'text-grey' : 'text-vekin'
              }`}
              disabled={seconds > 0 || minutes > 0}
              onClick={resendOTP}
            >
              <i className="ri-restart-line"></i>
              Resend
            </button>
            <button
              onClick={handleOnSubmit}
              type="button"
              className={`w-full py-2 text-white rounded focus:outline-none ${
                OTP.length !== 6 ? 'bg-gray-300' : 'bg-vekin'
              }`}
              disabled={OTP.length !== 6}
            >
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Verify
