export const Mist_S = ({style}) => {
    return ( 
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M22 9.037C22 9.27925 21.904 9.511 21.733 9.682C21.562 9.853 21.3295 9.949 21.088 9.949H14.5023C14.1918 9.949 13.9398 10.2018 13.9398 10.5115C13.9398 10.822 14.1918 11.074 14.5023 11.074C14.5023 11.074 19.1328 11.074 21.088 11.074C21.628 11.074 22.1463 10.8595 22.528 10.4778C22.9105 10.096 23.125 9.57775 23.125 9.037C23.125 8.497 22.9105 7.97875 22.528 7.597C22.1463 7.21525 21.628 7 21.088 7C20.7775 7 20.5255 7.25275 20.5255 7.5625C20.5255 7.873 20.7775 8.125 21.088 8.125C21.3295 8.125 21.562 8.22175 21.733 8.39275C21.904 8.56375 22 8.7955 22 9.037Z" fill="url(#paint0_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 11.5542C1 11.5542 1 11.8707 1 11.5602C1 12.1009 1.2145 12.6184 1.597 13.0009C1.97875 13.3827 2.497 13.5972 3.037 13.5972C4.99225 13.5972 9.62275 13.5972 9.62275 13.5972C9.93325 13.5972 10.1852 13.3452 10.1852 13.0347C10.1852 12.7242 9.93325 12.4722 9.62275 12.4722C9.62275 12.4722 4.99225 12.4722 3.037 12.4722C2.7955 12.4722 2.563 12.3762 2.392 12.2052C2.221 12.0342 2.125 11.8024 2.125 11.5602V11.5587C2.125 11.3172 2.221 11.0862 2.392 10.9152C2.563 10.7442 2.7955 10.6482 3.037 10.6482C3.3475 10.6482 3.5995 10.3962 3.5995 10.0857C3.5995 9.77519 3.3475 9.52319 3.037 9.52319C2.497 9.52319 1.97875 9.73769 1.597 10.1202C1.216 10.5004 1.0015 11.0164 1 11.5542" fill="url(#paint1_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.8315 7C16.5737 7 13.0862 7 13.0862 7C12.7757 7 12.5237 7.25275 12.5237 7.5625C12.5237 7.873 12.7757 8.125 13.0862 8.125C13.0862 8.125 16.5737 8.125 17.8315 8.125C18.142 8.125 18.394 7.873 18.394 7.5625C18.394 7.25275 18.142 7 17.8315 7Z" fill="url(#paint2_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.29349 10.6482C7.55124 10.6482 11.0387 10.6482 11.0387 10.6482C11.3492 10.6482 11.6012 10.3962 11.6012 10.0857C11.6012 9.77519 11.3492 9.52319 11.0387 9.52319C11.0387 9.52319 7.55124 9.52319 6.29349 9.52319C5.98299 9.52319 5.73099 9.77519 5.73099 10.0857C5.73099 10.3962 5.98299 10.6482 6.29349 10.6482Z" fill="url(#paint3_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.682 16.0466C18.682 16.1486 18.6415 16.2469 18.5695 16.3189C18.4967 16.3916 18.3985 16.4321 18.2965 16.4321H13.5512C13.2407 16.4321 12.9887 16.6841 12.9887 16.9946C12.9887 17.3051 13.2407 17.5571 13.5512 17.5571C13.5512 17.5571 17.0387 17.5571 18.2965 17.5571C18.697 17.5571 19.0817 17.3981 19.3645 17.1146C19.648 16.8311 19.807 16.4471 19.807 16.0466C19.807 15.6454 19.648 15.2614 19.3645 14.9779C19.0817 14.6944 18.697 14.5354 18.2965 14.5354C17.986 14.5354 17.734 14.7874 17.734 15.0979C17.734 15.4084 17.986 15.6604 18.2965 15.6604C18.3985 15.6604 18.4967 15.7009 18.5695 15.7737C18.6415 15.8457 18.682 15.9439 18.682 16.0466Z" fill="url(#paint4_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.2687 12.397H11.797C11.4865 12.397 11.2345 12.649 11.2345 12.9595C11.2345 13.27 11.4865 13.522 11.797 13.522H21.2687C21.5792 13.522 21.8312 13.27 21.8312 12.9595C21.8312 12.649 21.5792 12.397 21.2687 12.397Z" fill="url(#paint5_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.85626 16.0452H12.328C12.6385 16.0452 12.8905 15.7932 12.8905 15.4827C12.8905 15.1722 12.6385 14.9202 12.328 14.9202H2.85626C2.54576 14.9202 2.29376 15.1722 2.29376 15.4827C2.29376 15.7932 2.54576 16.0452 2.85626 16.0452V16.0452Z" fill="url(#paint6_linear_1570_78173)"/>
<defs>
<linearGradient id="paint0_linear_1570_78173" x1="11.8428" y1="13.1855" x2="11.8428" y2="26.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78173" x1="11.8428" y1="13.1857" x2="11.8428" y2="26.4552" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78173" x1="11.8428" y1="13.1855" x2="11.8428" y2="26.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78173" x1="11.8428" y1="13.1857" x2="11.8428" y2="26.4552" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_78173" x1="11.8428" y1="13.1856" x2="11.8428" y2="26.4551" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint5_linear_1570_78173" x1="11.8428" y1="13.1854" x2="11.8428" y2="26.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint6_linear_1570_78173" x1="11.8428" y1="13.1856" x2="11.8428" y2="26.4552" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
</defs>
</svg>



        


     );
}

export const Mist_N = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M22 9.037C22 9.27925 21.904 9.511 21.733 9.682C21.562 9.853 21.3295 9.949 21.088 9.949H14.5023C14.1918 9.949 13.9398 10.2018 13.9398 10.5115C13.9398 10.822 14.1918 11.074 14.5023 11.074C14.5023 11.074 19.1328 11.074 21.088 11.074C21.628 11.074 22.1463 10.8595 22.528 10.4778C22.9105 10.096 23.125 9.57775 23.125 9.037C23.125 8.497 22.9105 7.97875 22.528 7.597C22.1463 7.21525 21.628 7 21.088 7C20.7775 7 20.5255 7.25275 20.5255 7.5625C20.5255 7.873 20.7775 8.125 21.088 8.125C21.3295 8.125 21.562 8.22175 21.733 8.39275C21.904 8.56375 22 8.7955 22 9.037Z" fill="url(#paint0_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1 11.5542C1 11.5542 1 11.8707 1 11.5602C1 12.1009 1.2145 12.6184 1.597 13.0009C1.97875 13.3827 2.497 13.5972 3.037 13.5972C4.99225 13.5972 9.62275 13.5972 9.62275 13.5972C9.93325 13.5972 10.1852 13.3452 10.1852 13.0347C10.1852 12.7242 9.93325 12.4722 9.62275 12.4722C9.62275 12.4722 4.99225 12.4722 3.037 12.4722C2.7955 12.4722 2.563 12.3762 2.392 12.2052C2.221 12.0342 2.125 11.8024 2.125 11.5602V11.5587C2.125 11.3172 2.221 11.0862 2.392 10.9152C2.563 10.7442 2.7955 10.6482 3.037 10.6482C3.3475 10.6482 3.5995 10.3962 3.5995 10.0857C3.5995 9.77519 3.3475 9.52319 3.037 9.52319C2.497 9.52319 1.97875 9.73769 1.597 10.1202C1.216 10.5004 1.0015 11.0164 1 11.5542" fill="url(#paint1_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M17.8315 7C16.5737 7 13.0862 7 13.0862 7C12.7757 7 12.5237 7.25275 12.5237 7.5625C12.5237 7.873 12.7757 8.125 13.0862 8.125C13.0862 8.125 16.5737 8.125 17.8315 8.125C18.142 8.125 18.394 7.873 18.394 7.5625C18.394 7.25275 18.142 7 17.8315 7Z" fill="url(#paint2_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.29349 10.6482C7.55124 10.6482 11.0387 10.6482 11.0387 10.6482C11.3492 10.6482 11.6012 10.3962 11.6012 10.0857C11.6012 9.77519 11.3492 9.52319 11.0387 9.52319C11.0387 9.52319 7.55124 9.52319 6.29349 9.52319C5.98299 9.52319 5.73099 9.77519 5.73099 10.0857C5.73099 10.3962 5.98299 10.6482 6.29349 10.6482Z" fill="url(#paint3_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.682 16.0466C18.682 16.1486 18.6415 16.2469 18.5695 16.3189C18.4967 16.3916 18.3985 16.4321 18.2965 16.4321H13.5512C13.2407 16.4321 12.9887 16.6841 12.9887 16.9946C12.9887 17.3051 13.2407 17.5571 13.5512 17.5571C13.5512 17.5571 17.0387 17.5571 18.2965 17.5571C18.697 17.5571 19.0817 17.3981 19.3645 17.1146C19.648 16.8311 19.807 16.4471 19.807 16.0466C19.807 15.6454 19.648 15.2614 19.3645 14.9779C19.0817 14.6944 18.697 14.5354 18.2965 14.5354C17.986 14.5354 17.734 14.7874 17.734 15.0979C17.734 15.4084 17.986 15.6604 18.2965 15.6604C18.3985 15.6604 18.4967 15.7009 18.5695 15.7737C18.6415 15.8457 18.682 15.9439 18.682 16.0466Z" fill="url(#paint4_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.2687 12.397H11.797C11.4865 12.397 11.2345 12.649 11.2345 12.9595C11.2345 13.27 11.4865 13.522 11.797 13.522H21.2687C21.5792 13.522 21.8312 13.27 21.8312 12.9595C21.8312 12.649 21.5792 12.397 21.2687 12.397Z" fill="url(#paint5_linear_1570_78173)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.85626 16.0452H12.328C12.6385 16.0452 12.8905 15.7932 12.8905 15.4827C12.8905 15.1722 12.6385 14.9202 12.328 14.9202H2.85626C2.54576 14.9202 2.29376 15.1722 2.29376 15.4827C2.29376 15.7932 2.54576 16.0452 2.85626 16.0452V16.0452Z" fill="url(#paint6_linear_1570_78173)"/>
<defs>
<linearGradient id="paint0_linear_1570_78173" x1="11.8428" y1="13.1855" x2="11.8428" y2="26.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78173" x1="11.8428" y1="13.1857" x2="11.8428" y2="26.4552" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78173" x1="11.8428" y1="13.1855" x2="11.8428" y2="26.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78173" x1="11.8428" y1="13.1857" x2="11.8428" y2="26.4552" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_78173" x1="11.8428" y1="13.1856" x2="11.8428" y2="26.4551" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint5_linear_1570_78173" x1="11.8428" y1="13.1854" x2="11.8428" y2="26.455" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
<linearGradient id="paint6_linear_1570_78173" x1="11.8428" y1="13.1856" x2="11.8428" y2="26.4552" gradientUnits="userSpaceOnUse">
<stop stopColor="#6DBEFF"/>
<stop offset="1" stopColor="#0085F0"/>
</linearGradient>
</defs>
</svg>





     );
}
 
