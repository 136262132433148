const Solar_Icon = ({style, stroke}) => {
    return (  
        <svg className={style}  width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.9699 29.72H12.5099C11.4099 29.72 10.4299 29.29 9.73995 28.5C9.04995 27.71 8.73995 26.66 8.89995 25.55L9.95995 17.07C10.2599 14.87 12.2199 13.15 14.4199 13.15H24.8699C25.9699 13.15 26.9499 13.58 27.6399 14.37C28.3399 15.16 28.6399 16.21 28.4899 17.32L27.4299 25.8C27.1299 28 25.1699 29.72 22.9699 29.72ZM14.4099 15.01C13.1599 15.01 11.9699 16.07 11.7999 17.32L10.7399 25.8C10.6599 26.37 10.8099 26.89 11.1399 27.27C11.4699 27.65 11.9499 27.85 12.5099 27.85H22.9599C24.2099 27.85 25.3999 26.79 25.5699 25.54L26.6299 17.06C26.7099 16.49 26.5599 15.97 26.2299 15.59C25.8999 15.21 25.4199 15.01 24.8599 15.01C24.8599 15.01 14.4099 15.01 14.4099 15.01Z" fill={stroke}/>
        <path d="M22.9699 29.72H12.5099C11.4099 29.72 10.4299 29.29 9.73995 28.5C9.04995 27.71 8.73995 26.66 8.89995 25.55L9.95995 17.07C10.2599 14.87 12.2199 13.15 14.4199 13.15H24.8699C25.9699 13.15 26.9499 13.58 27.6399 14.37C28.3399 15.16 28.6399 16.21 28.4899 17.32L27.4299 25.8C27.1299 28 25.1699 29.72 22.9699 29.72ZM14.4099 15.01C13.1599 15.01 11.9699 16.07 11.7999 17.32L10.7399 25.8C10.6599 26.37 10.8099 26.89 11.1399 27.27C11.4699 27.65 11.9499 27.85 12.5099 27.85H22.9599C24.2099 27.85 25.3999 26.79 25.5699 25.54L26.6299 17.06C26.7099 16.49 26.5599 15.97 26.2299 15.59C25.8999 15.21 25.4199 15.01 24.8599 15.01C24.8599 15.01 14.4099 15.01 14.4099 15.01Z" fill="url(#paint0_linear_3293_57514)" fill-opacity="0.7"/>
        <path d="M15.6599 28.9101L13.8099 28.6601L15.6699 14.1901L17.5199 14.4401L15.6599 28.9101Z" fill={stroke}/>
        <path d="M15.6599 28.9101L13.8099 28.6601L15.6699 14.1901L17.5199 14.4401L15.6599 28.9101Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M21.59 28.9101L19.74 28.6601L21.61 14.1901L23.46 14.4401L21.59 28.9101Z" fill={stroke}/>
        <path d="M21.59 28.9101L19.74 28.6601L21.61 14.1901L23.46 14.4401L21.59 28.9101Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M27.03 18.36H10.35V20.23H27.03V18.36Z" fill={stroke}/>
        <path d="M27.03 18.36H10.35V20.23H27.03V18.36Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M26.8199 22.87H10.1399V24.74H26.8199V22.87Z" fill={stroke}/>
        <path d="M26.8199 22.87H10.1399V24.74H26.8199V22.87Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M28.78 20.12L28.26 24.26L29.77 29.39H31.35L28.78 20.12Z" fill={stroke}/>
        <path d="M28.78 20.12L28.26 24.26L29.77 29.39H31.35L28.78 20.12Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M10.79 5.56999C10.27 5.56999 9.85999 5.14999 9.85999 4.63999V2.78999C9.85999 2.26999 10.28 1.85999 10.79 1.85999C11.31 1.85999 11.72 2.27999 11.72 2.78999V4.62999C11.72 5.14999 11.3 5.56999 10.79 5.56999Z" fill={stroke}/>
        <path d="M10.79 5.56999C10.27 5.56999 9.85999 5.14999 9.85999 4.63999V2.78999C9.85999 2.26999 10.28 1.85999 10.79 1.85999C11.31 1.85999 11.72 2.27999 11.72 2.78999V4.62999C11.72 5.14999 11.3 5.56999 10.79 5.56999Z" fill="url(#paint6_linear_3293_57514)" fill-opacity="0.7"/>
        <path d="M3.4 12.6799H1.56C1.04 12.6799 0.630005 12.2599 0.630005 11.7499C0.630005 11.2299 1.05 10.8199 1.56 10.8199H3.4C3.92 10.8199 4.33 11.2399 4.33 11.7499C4.33 12.2599 3.91 12.6799 3.4 12.6799Z" fill={stroke}/>
        <path d="M3.4 12.6799H1.56C1.04 12.6799 0.630005 12.2599 0.630005 11.7499C0.630005 11.2299 1.05 10.8199 1.56 10.8199H3.4C3.92 10.8199 4.33 11.2399 4.33 11.7499C4.33 12.2599 3.91 12.6799 3.4 12.6799Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M4.63996 20.02C4.39996 20.02 4.15996 19.93 3.97996 19.75C3.61996 19.39 3.61996 18.8 3.97996 18.43L5.27996 17.13C5.63996 16.77 6.22996 16.77 6.59996 17.13C6.95996 17.49 6.95996 18.08 6.59996 18.45L5.29996 19.75C5.10996 19.93 4.87996 20.02 4.63996 20.02Z" fill={stroke}/>
        <path d="M4.63996 20.02C4.39996 20.02 4.15996 19.93 3.97996 19.75C3.61996 19.39 3.61996 18.8 3.97996 18.43L5.27996 17.13C5.63996 16.77 6.22996 16.77 6.59996 17.13C6.95996 17.49 6.95996 18.08 6.59996 18.45L5.29996 19.75C5.10996 19.93 4.87996 20.02 4.63996 20.02Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M16.2499 7.35004C16.0099 7.35004 15.7699 7.26004 15.5899 7.08004C15.2299 6.72004 15.2299 6.13004 15.5899 5.76004L16.8899 4.46004C17.2499 4.09004 17.8399 4.10004 18.2099 4.46004C18.5699 4.82004 18.5699 5.41004 18.2099 5.78004L16.9099 7.08004C16.7199 7.26004 16.4799 7.35004 16.2499 7.35004Z" fill={stroke}/>
        <path d="M16.2499 7.35004C16.0099 7.35004 15.7699 7.26004 15.5899 7.08004C15.2299 6.72004 15.2299 6.13004 15.5899 5.76004L16.8899 4.46004C17.2499 4.09004 17.8399 4.10004 18.2099 4.46004C18.5699 4.82004 18.5699 5.41004 18.2099 5.78004L16.9099 7.08004C16.7199 7.26004 16.4799 7.35004 16.2499 7.35004Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M6.00996 7.41994C5.76996 7.41994 5.52996 7.32994 5.34996 7.14994L3.97996 5.77994C3.61996 5.41994 3.61996 4.82994 3.97996 4.45994C4.33996 4.09994 4.92996 4.09994 5.29996 4.45994L6.66996 5.82994C7.02996 6.18994 7.02996 6.77994 6.66996 7.14994C6.47996 7.32994 6.23996 7.41994 6.00996 7.41994Z" fill={stroke}/>
        <path d="M6.00996 7.41994C5.76996 7.41994 5.52996 7.32994 5.34996 7.14994L3.97996 5.77994C3.61996 5.41994 3.61996 4.82994 3.97996 4.45994C4.33996 4.09994 4.92996 4.09994 5.29996 4.45994L6.66996 5.82994C7.02996 6.18994 7.02996 6.77994 6.66996 7.14994C6.47996 7.32994 6.23996 7.41994 6.00996 7.41994Z" fill={stroke} fill-opacity="0.7"/>
        <path d="M8.35004 17.4001L8.48004 16.3301C8.83004 13.7601 11.12 11.7501 13.69 11.7501H16.65C16.34 8.90005 13.92 6.68005 10.99 6.68005C7.84004 6.68005 5.29004 9.23005 5.29004 12.3801C5.30004 14.5701 6.54004 16.4501 8.35004 17.4001Z" fill={stroke}/>
        <path d="M8.35004 17.4001L8.48004 16.3301C8.83004 13.7601 11.12 11.7501 13.69 11.7501H16.65C16.34 8.90005 13.92 6.68005 10.99 6.68005C7.84004 6.68005 5.29004 9.23005 5.29004 12.3801C5.30004 14.5701 6.54004 16.4501 8.35004 17.4001Z" fill={stroke} fill-opacity="0.7"/>
      
        </svg>
        

    )
}

export default Solar_Icon;