import axios from "../shared/axios";
import useSWR from "swr";
import { API_URL } from "../shared/config";

const fetcher = (url) => axios.get(url);

export const useLoggedInUser = () => {
  const { data, error } = useSWR(`${API_URL}/account/profile/`, fetcher, 
    {
      revalidateIfStale: false,
      // revalidateOnFocus: false,
      revalidateOnReconnect: false
    });
  //console.log(data)
  return {
    user: data?.data,
    isLoadingUser: !error && !data,
    isErrorUser: error,
  };
};
export const useIsAuthenticated = () => {
  const { data, error } = useSWR( `${API_URL}/account/is-authenticated/`,fetcher);
// console.log(data?.data, "checking the isAuth")
  return {
    isAuthenticated: data?.data?.is_authenticated,
    isLoading: !error && !data?.data?.is_authenticated,
    isErrorUser: error,
  };
};

export const usePlantMemberList = (id) => {
  const { data, error } = useSWR(`${API_URL}/plant/${id}/member/`, fetcher);
  //console.log(data)
  return {
    memberList: data?.data?.sort((a, b) => (a.groups > b.groups ? 1 : -1)),
    isLoadingMemberList: !error && !data,
    isErrorMemberList: error,
  };
};

export const removeMembers = async (id) => {
  try {
    await axios.delete(`${API_URL}/role/${id}/`);
    //console.log('Item successfully deleted.')
  } catch (error) {
    alert(error);
  }
};

export const registerNewUser = async (data) => {
  try {
    const request = await axios.post(`${API_URL}/account/register/`, data);
    return { data: request.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};

export const SignInAndRegisterGoogle = async (data) => {
  try {
    const request = await axios.post(`${API_URL}/account/social/google`, data);
    return { data: request.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};

export const sendVerify = async (data) => {
  try {
    const request = await axios.post(
      `${API_URL}/account/register/sendEmailVerify/`,
      data
    );
    return { data: request.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};

export const verifyOtpEmail = async (data) => {
  try {
    const request = await axios.post(
      `${API_URL}/account/register/emailVerify/`,
      data
    );
    return { data: request.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};

export const inviteUser = async (data) => {
  try {
    const request = await axios.post(`${API_URL}/account/invite/`, data);
    return { data: request.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};
export const useGetInvitationDetails = (_pathname, _email) => {
  let email =new URLSearchParams(_email).get("email")
  let invitationId = _pathname?.split('/')?.slice(-1)?.[0]
  

  const { data, error } = useSWR(`${API_URL}/account/invite/${invitationId}/${email}/`, fetcher);
  return {
    invitation: data?.data,
    isLoadingInvitation: !error && !data?.data,
    isErrorInvitation: error,
  };
};


export const userInvitationConfirmation = async (invitationId, data) => {
  try {
    const request = await axios.post(`${API_URL}/account/invite/${invitationId}/verify/`, data);
    return { data: request?.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error?.response?.data?.error };
  }
};



export const updateUserRole = async (data) => {
  try {
    const request = await axios.post(`${API_URL}/role/edit/`, data);
    return { data: request.data, error: undefined };
  } catch (error) {
    return { data: undefined, error: error };
  }
};

// get list of roles
export const useGetRoleList = (id) => {
  const { data, error } = useSWR(`${API_URL}/account/role/`, fetcher);
  return {
    roleList: data?.data,
    isLoadingRolesList: !error && !data?.data,
    isErrorRoleList: error,
  };
};

export const getSiteList = async () => {
    try {
        const response = await axios.get(`${API_URL}/plant`);
        return { data: response.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const useGetDevice = (id) => {
    const { data, error } = useSWR(`${API_URL}/plant/${id}/device/`, fetcher)
    return {
      deviceList: data?.data,
      isLoadingDeviceList: !error && !data?.data,
      isErrorDeviceList: error
    }
}

export const useGetServer = (id) => {
  const { data, error } = useSWR(`${API_URL}/plant/${id}/server/`, fetcher)
  return {
    serverList: data?.data,
    isLoadingServerList: !error && !data?.data,
    isErrorServerList: error
  }
}

export const createNewDevice = async (data) => {
    try {
        const request = await axios.post(`${API_URL}/device/`, data);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const createNewServer = async (plantId,formData) => {
    try {
        const request = await axios.post(`${API_URL}/plant/${plantId}/server/add/`,formData);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const useGetPlace = (id) => {
    const { data, error } = useSWR(`${API_URL}/plant/${id}/building/`, fetcher)
    return {
      getPlace: data?.data,
      isLoadingGetPlace: !error && !data?.data,
      isErrorGetPlace: error
    }
}

export const useGetDeviceType = (id) => {
    const { data, error } = useSWR(`${API_URL}/device/device_type/`, fetcher)
    return {
      getDeviceType: data?.data,
      isLoadingGetDeviceType: !error && !data?.data,
      isErrorGetDeviceType: error
    }
}

export const editServer = async (plantId,formData) => {
    try {
        const request = await axios.post(`${API_URL}/plant/${plantId}/server/edit/`,formData);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const editDevice = async (deviceId,data) => {
    try {
        const request = await axios.patch(`${API_URL}/device/${deviceId}/`,data);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}



export const requestResetPassword = async (data) => {
    try {
        const request = await axios.post(`${API_URL}/account/password/request_reset_password/`,data);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const resetPassword = async (data) => {
    try {
        const request = await axios.post(`${API_URL}/account/password/reset_password/`,data);
        return { data: request.data, error: undefined };
    } catch (error) {
        return { data: undefined, error: error };
    }
}

export const verifyResetPassToken = async (token) => {
  try {
      const request = await axios.get(`${API_URL}/account/password/reset_password/${token}/`);
      console.log(request)
      return { data: request.data, error: undefined };
  } catch (error) {
      return { data: undefined, error: error };
  }
}

export const useGetBrand = () => {
  const { data, error } = useSWR(`${API_URL}/device/brand/model/`, fetcher)
  return {
    brandList: data?.data,
    isLoadingBrandList: !error && !data?.data,
    isErrorBrandList: error
  }
}


export const useGetFloorPlanDevice = (id) => {
  const { data, error } = useSWR(`${API_URL}/plant/${id}/device/group/`, fetcher)
  return {
    getFloorPlanDevice: data?.data,
    isLoadingGetFloorPlanDevice: !error && !data?.data,
    isErrorGetFloorPlanDevice: error
  }
}

export const useGetDeviceDetail = (id) => {
  const { data, error } = useSWR(`${API_URL}/device/${id}/info/`, fetcher)
  return {
    getDeviceDetail: data?.data,
    isLoadingGetDeviceDetail: !error && !data?.data,
    isErrorDeviceDetail: error
  }
}
export const useGetDeviceControlDetails = (id) => {
  const { data, error } = useSWR(`${API_URL}/device/${id}/control/list/`, fetcher)
  return {
    controlDetails: data?.data,
    isLoadingControlDetails: !error && !data?.data,
    isErrorControlDetails: error
  }
}



export const deviceControl = async (id, commandData) => {
  try {
      const request = await axios.post(`${API_URL}/device/${id}/control/`,commandData);
      return { data: request.data, error: undefined };
  } catch (error) {
      return { data: undefined, error: error?.response?.data?.error  };
  }
}


export const removeDevices = async (id) => {
  try {
    await axios.delete(`${API_URL}/device/${id}/`);
  } catch (error) {
    alert(error);
  }
};

export const removeServer = async (id,server) => {
  try {
      const request = await axios.post(`${API_URL}/plant/${id}/server/remove/`,server);
      return { data: request.data, error: undefined };
  } catch (error) {
      return { data: undefined, error: error };
  }
}

