const Icon_Power = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="-0.00012207" width="60" height="60" rx="30" fill="#F2F8F6"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 29.9999C8 42.1333 17.8698 51.9999 30.0073 51.9999C42.1156 51.9999 52 42.1333 52 29.9999C52 17.8665 42.1447 7.99988 30.0073 7.99988C17.8698 7.99988 8 17.8665 8 29.9999ZM10.6019 29.9999C10.6019 19.3196 19.3089 10.6155 30.0073 10.6155C40.6766 10.6155 49.3835 19.305 49.3835 29.9999C49.3835 40.6947 40.6766 49.3843 29.9927 49.3843C19.2944 49.3843 10.6019 40.6802 10.6019 29.9999ZM28.4544 19.4474C28.4544 18.3944 29.3133 17.5351 30.3659 17.5351C31.4184 17.5351 32.2773 18.3944 32.2773 19.4474V30.3753C32.2773 31.4284 31.4184 32.2877 30.3659 32.2877C29.3133 32.2877 28.4544 31.4284 28.4544 30.3753V19.4474ZM20.9966 21.6424C21.7295 20.8532 22.973 20.8402 23.7342 21.6018C24.4674 22.3353 24.4611 23.5052 23.7602 24.2469C22.2672 25.8575 21.3547 28.0066 21.3547 30.3753C21.3547 35.399 25.4481 39.4545 30.4804 39.3772L30.483 39.3772C35.4058 39.3257 39.493 35.0716 39.3772 30.1423L39.3772 30.1418C39.3257 27.8636 38.426 25.8061 36.9729 24.262C36.2741 23.5241 36.2466 22.353 36.9975 21.6018C37.7587 20.8402 39.0161 20.8531 39.749 21.6427C41.887 23.9248 43.2 26.9796 43.2 30.3617C43.2 37.5946 37.2252 43.4299 29.9528 43.2016C22.9886 42.9873 17.3322 36.9711 17.532 30.0045L17.532 30.0033C17.6318 26.7649 18.9298 23.8393 20.9966 21.6424Z" fill="#79C8B0"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 29.9999C8 42.1333 17.8698 51.9999 30.0073 51.9999C42.1156 51.9999 52 42.1333 52 29.9999C52 17.8665 42.1447 7.99988 30.0073 7.99988C17.8698 7.99988 8 17.8665 8 29.9999ZM10.6019 29.9999C10.6019 19.3196 19.3089 10.6155 30.0073 10.6155C40.6766 10.6155 49.3835 19.305 49.3835 29.9999C49.3835 40.6947 40.6766 49.3843 29.9927 49.3843C19.2944 49.3843 10.6019 40.6802 10.6019 29.9999ZM28.4544 19.4474C28.4544 18.3944 29.3133 17.5351 30.3659 17.5351C31.4184 17.5351 32.2773 18.3944 32.2773 19.4474V30.3753C32.2773 31.4284 31.4184 32.2877 30.3659 32.2877C29.3133 32.2877 28.4544 31.4284 28.4544 30.3753V19.4474ZM20.9966 21.6424C21.7295 20.8532 22.973 20.8402 23.7342 21.6018C24.4674 22.3353 24.4611 23.5052 23.7602 24.2469C22.2672 25.8575 21.3547 28.0066 21.3547 30.3753C21.3547 35.399 25.4481 39.4545 30.4804 39.3772L30.483 39.3772C35.4058 39.3257 39.493 35.0716 39.3772 30.1423L39.3772 30.1418C39.3257 27.8636 38.426 25.8061 36.9729 24.262C36.2741 23.5241 36.2466 22.353 36.9975 21.6018C37.7587 20.8402 39.0161 20.8531 39.749 21.6427C41.887 23.9248 43.2 26.9796 43.2 30.3617C43.2 37.5946 37.2252 43.4299 29.9528 43.2016C22.9886 42.9873 17.3322 36.9711 17.532 30.0045L17.532 30.0033C17.6318 26.7649 18.9298 23.8393 20.9966 21.6424Z" fill="url(#paint0_linear_3635_62448)"/>
<defs>
<linearGradient id="paint0_linear_3635_62448" x1="8.52381" y1="26.3332" x2="52.7437" y2="26.7844" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Power;