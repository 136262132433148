const DOMAIN_URL = process.env.REACT_APP_API_URL;
const DAP_URL = process.env.REACT_APP_DAP_URL;
const GOOGlE_API_KEY = process.env.REACT_APP_GOOGlE_API_KEY;
const GOOGLE_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const WEB_URL = process.env.REACT_APP_URL

//for dev server
const API_URL = `${DOMAIN_URL}/api`;

// for local server
// const API_URL = `/api`;
//const DOMAIN_URL = "http://localhost:3000/";

export { API_URL, GOOGlE_API_KEY, GOOGLE_CLIENT_ID, DOMAIN_URL , DAP_URL, WEB_URL};
