export const Rain_S = ({style}) => {
    return ( 
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.248 2.592C11.7802 2.6445 13.0695 3.66525 13.5217 5.061C13.5847 5.25375 13.5382 5.466 13.4002 5.61525C13.263 5.7645 13.0552 5.82825 12.858 5.78175C12.4687 5.69025 12.0622 5.6415 11.6452 5.6415C9.2835 5.6415 7.2825 7.197 6.612 9.339C6.5385 9.57375 6.321 9.7335 6.075 9.7335H3.90225C2.93475 9.7335 2.19825 9.38625 1.698 8.84925C1.2 8.3145 0.932248 7.5795 0.932248 6.7635C0.932248 5.28525 2.01225 4.05975 3.426 3.83175C3.831 2.17125 5.3295 0.9375 7.11375 0.9375C8.41425 0.9375 9.564 1.593 10.248 2.592Z" fill="#FFE600"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.248 2.592C11.7802 2.6445 13.0695 3.66525 13.5217 5.061C13.5847 5.25375 13.5382 5.466 13.4002 5.61525C13.263 5.7645 13.0552 5.82825 12.858 5.78175C12.4687 5.69025 12.0622 5.6415 11.6452 5.6415C9.2835 5.6415 7.2825 7.197 6.612 9.339C6.5385 9.57375 6.321 9.7335 6.075 9.7335H3.90225C2.93475 9.7335 2.19825 9.38625 1.698 8.84925C1.2 8.3145 0.932248 7.5795 0.932248 6.7635C0.932248 5.28525 2.01225 4.05975 3.426 3.83175C3.831 2.17125 5.3295 0.9375 7.11375 0.9375C8.41425 0.9375 9.564 1.593 10.248 2.592Z" fill="url(#paint0_linear_1570_78167)"/>
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.0035 3.72016H18.0238C21.111 3.72016 23.6171 6.227 23.6171 9.31418C23.6171 11.3744 22.5293 14.9075 18.3591 14.9075C7.5463 14.9075 7.5463 14.9075 7.5463 14.9075C6.05382 14.9075 4.91415 14.3811 4.14271 13.5529C3.37336 12.7262 2.96594 11.5886 2.96594 10.3278C2.96594 7.9512 4.77625 5.99739 7.09268 5.76988C7.62471 3.01802 10.0489 0.9375 12.9548 0.9375C15.0787 0.9375 16.945 2.04846 18.0035 3.72016V3.72016Z" fill="url(#paint1_linear_1570_78167)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M8.33768 16.8685C8.31723 16.6527 8.17401 16.4673 7.97033 16.3932C7.76666 16.3191 7.53776 16.369 7.38342 16.5212C7.38342 16.5212 5.69065 18.2204 5.37283 19.0937C5.0863 19.8809 5.49312 20.7521 6.27964 21.0383C7.06617 21.3246 7.9378 20.9187 8.22432 20.1315C8.54214 19.2583 8.33768 16.8685 8.33768 16.8685Z" fill="url(#paint2_linear_1570_78167)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M13.7043 18.0826C13.6841 17.8661 13.5406 17.6814 13.337 17.6072C13.1333 17.5331 12.9046 17.5824 12.75 17.7352C12.75 17.7352 10.7749 19.7124 10.4045 20.7301C10.0859 21.6055 10.5378 22.5744 11.4131 22.893C12.2884 23.2116 13.2574 22.7598 13.576 21.8844C13.9464 20.8668 13.7043 18.0826 13.7043 18.0826Z" fill="url(#paint3_linear_1570_78167)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.2927 16.7945C18.2715 16.5785 18.1288 16.394 17.9251 16.3199C17.7207 16.2455 17.4928 16.295 17.3384 16.4472C17.3384 16.4472 15.6454 18.1471 15.3276 19.0204C15.0413 19.8069 15.4472 20.6785 16.2344 20.965C17.0209 21.2513 17.8921 20.8445 18.1784 20.058C18.4962 19.1847 18.2927 16.7945 18.2927 16.7945Z" fill="url(#paint4_linear_1570_78167)"/>
        <defs>
        <linearGradient id="paint0_linear_1570_78167" x1="13.3992" y1="0.204509" x2="1.23724" y2="0.48488" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFE24A"/>
        <stop offset="1" stopColor="#EFB274"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1570_78167" x1="12.3064" y1="10.481" x2="12.3064" y2="34.3644" gradientUnits="userSpaceOnUse">
        <stop stopColor="#E2F4FD"/>
        <stop offset="1" stopColor="#4DC4FF"/>
        </linearGradient>
        <linearGradient id="paint2_linear_1570_78167" x1="7.69406" y1="19.3583" x2="3.71807" y2="30.2822" gradientUnits="userSpaceOnUse">
        <stop stopColor="#83D3F6"/>
        <stop offset="1" stopColor="#2898D4"/>
        </linearGradient>
        <linearGradient id="paint3_linear_1570_78167" x1="12.375" y1="20.2501" x2="7.60715" y2="33.3498" gradientUnits="userSpaceOnUse">
        <stop stopColor="#83D3F6"/>
        <stop offset="1" stopColor="#2898D4"/>
        </linearGradient>
        <linearGradient id="paint4_linear_1570_78167" x1="16.328" y1="18.8825" x2="11.326" y2="32.6255" gradientUnits="userSpaceOnUse">
        <stop stopColor="#83D3F6"/>
        <stop offset="1" stopColor="#2898D4"/>
        </linearGradient>
        </defs>
        </svg>
        


        
     );
}

export const Rain_N = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.5">
<path fillRule="evenodd" clipRule="evenodd" d="M10.248 2.592C11.7802 2.6445 13.0695 3.66525 13.5217 5.061C13.5847 5.25375 13.5382 5.466 13.4002 5.61525C13.263 5.7645 13.0552 5.82825 12.858 5.78175C12.4687 5.69025 12.0622 5.6415 11.6452 5.6415C9.2835 5.6415 7.2825 7.197 6.612 9.339C6.5385 9.57375 6.321 9.7335 6.075 9.7335H3.90225C2.93475 9.7335 2.19825 9.38625 1.698 8.84925C1.2 8.3145 0.932248 7.5795 0.932248 6.7635C0.932248 5.28525 2.01225 4.05975 3.426 3.83175C3.831 2.17125 5.3295 0.9375 7.11375 0.9375C8.41425 0.9375 9.564 1.593 10.248 2.592Z" fill="#6CCCDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M10.248 2.592C11.7802 2.6445 13.0695 3.66525 13.5217 5.061C13.5847 5.25375 13.5382 5.466 13.4002 5.61525C13.263 5.7645 13.0552 5.82825 12.858 5.78175C12.4687 5.69025 12.0622 5.6415 11.6452 5.6415C9.2835 5.6415 7.2825 7.197 6.612 9.339C6.5385 9.57375 6.321 9.7335 6.075 9.7335H3.90225C2.93475 9.7335 2.19825 9.38625 1.698 8.84925C1.2 8.3145 0.932248 7.5795 0.932248 6.7635C0.932248 5.28525 2.01225 4.05975 3.426 3.83175C3.831 2.17125 5.3295 0.9375 7.11375 0.9375C8.41425 0.9375 9.564 1.593 10.248 2.592Z" fill="url(#paint0_linear_1570_78168)" fillOpacity="0.7"/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M18.0035 3.72016H18.0238C21.111 3.72016 23.6171 6.227 23.6171 9.31418C23.6171 11.3744 22.5293 14.9075 18.3591 14.9075C7.5463 14.9075 7.5463 14.9075 7.5463 14.9075C6.05382 14.9075 4.91415 14.3811 4.14271 13.5529C3.37337 12.7262 2.96594 11.5886 2.96594 10.3278C2.96594 7.9512 4.77625 5.99739 7.09268 5.76988C7.62471 3.01802 10.0489 0.9375 12.9548 0.9375C15.0787 0.9375 16.945 2.04846 18.0035 3.72016Z" fill="url(#paint1_linear_1570_78168)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M8.33768 16.8686C8.31723 16.6528 8.17401 16.4674 7.97033 16.3933C7.76666 16.3192 7.53776 16.3691 7.38342 16.5213C7.38342 16.5213 5.69065 18.2205 5.37283 19.0937C5.0863 19.881 5.49312 20.7522 6.27964 21.0384C7.06617 21.3247 7.9378 20.9188 8.22432 20.1316C8.54214 19.2584 8.33768 16.8686 8.33768 16.8686Z" fill="url(#paint2_linear_1570_78168)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.7043 18.0825C13.6841 17.866 13.5406 17.6813 13.337 17.6072C13.1333 17.5331 12.9046 17.5823 12.75 17.7352C12.75 17.7352 10.7749 19.7124 10.4045 20.7301C10.0859 21.6054 10.5378 22.5744 11.4131 22.893C12.2884 23.2116 13.2574 22.7597 13.576 21.8844C13.9464 20.8667 13.7043 18.0825 13.7043 18.0825Z" fill="url(#paint3_linear_1570_78168)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M18.2927 16.7945C18.2715 16.5785 18.1288 16.394 17.9251 16.3199C17.7207 16.2455 17.4928 16.295 17.3384 16.4471C17.3384 16.4471 15.6454 18.1471 15.3276 19.0203C15.0413 19.8068 15.4472 20.6785 16.2344 20.965C17.0209 21.2513 17.8921 20.8444 18.1784 20.0579C18.4962 19.1847 18.2927 16.7945 18.2927 16.7945Z" fill="url(#paint4_linear_1570_78168)"/>
<defs>
<linearGradient id="paint0_linear_1570_78168" x1="12.6482" y1="0.204509" x2="0.89263" y2="0.718409" gradientUnits="userSpaceOnUse">
<stop stopColor="#0085FF" stopOpacity="0"/>
<stop offset="1" stopColor="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78168" x1="12.3064" y1="10.481" x2="12.3064" y2="34.3644" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_78168" x1="7.69406" y1="19.3584" x2="3.71807" y2="30.2823" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_78168" x1="12.375" y1="20.2501" x2="7.60715" y2="33.3497" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_78168" x1="16.328" y1="18.8824" x2="11.326" y2="32.6254" gradientUnits="userSpaceOnUse">
<stop stopColor="#83D3F6"/>
<stop offset="1" stopColor="#2898D4"/>
</linearGradient>
</defs>
</svg>

        





     );
}
 
