import SideBar from './SideBar';
// import Header from './Header';

const PageLayout = ({ children }) => {
    return (
        <div className="flex">
            <SideBar />
            <div className="w-screen h-screen overflow-hidden">
                <div className="w-full h-full overflow-x-auto overflow-y-auto bg-vekin-green-50 scroll-smooth">
                    <div className="">
                        {/* <Header /> */}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PageLayout;
