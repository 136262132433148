const CoinSave_Icon = ({style, stroke}) => {
  return (
    <svg
      className={style}  
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.6638 7.47907L27.4457 9.0258C27.4457 9.03137 27.4485 9.03415 27.4526 9.03832C27.4568 9.0425 27.4624 9.04806 27.4679 9.05919C28.2691 9.86037 28.1356 11.1957 27.2676 11.9301L20.9694 17.2936C20.6134 17.6497 20.2573 17.2936 20.2573 16.9375L22.2157 12.0302C22.327 11.7632 22.2157 11.4516 21.9709 11.2958L18.8218 9.40414C18.1097 9.04806 17.7536 7.96869 18.4657 7.25652L25.643 1.14749C25.9991 0.791412 26.3552 1.14749 26.3552 1.50358L24.3856 6.74466C24.2855 7.02284 24.3967 7.33442 24.6638 7.47907ZM18.7774 27.0857C19.9458 25.9173 20.6023 24.3261 20.6023 22.6792C20.6023 21.0435 19.9569 19.4633 18.7996 18.3061L18.5548 18.0613C19.3115 17.2935 19.7455 16.2586 19.7455 15.1792V13.766C19.7455 12.2638 19.1446 10.8172 18.0875 9.76012C17.4198 9.09247 16.5185 8.72526 15.5838 8.72526H13.0578V7.045C13.0578 6.2772 12.4347 5.66518 11.678 5.66518C10.9102 5.66518 10.2982 6.28832 10.2982 7.045V8.72526H5.04599C4.46736 8.72526 4 9.19261 4 9.77125V27.1302C4 27.7089 4.46736 28.1762 5.04599 28.1762H10.2871V29.8565C10.2871 30.6243 10.9102 31.2363 11.6669 31.2363C12.4347 31.2363 13.0467 30.6132 13.0467 29.8565V28.1762H16.1402C17.1305 28.1762 18.0764 27.7868 18.7774 27.0857ZM10.3093 17.0932H7.1491V11.4515H10.3093V17.0932ZM10.3093 19.697V25.45H7.1491V19.697H10.3093ZM13.0578 17.0932V11.4515L14.6825 11.4404C15.1832 11.4404 15.6617 11.6407 16.0178 11.9968C16.3738 12.3528 16.5741 12.8425 16.5741 13.3432V15.1459C16.5741 15.6689 16.3738 16.1585 16.0066 16.5257C15.6394 16.8929 13.8813 17.0932 13.3694 17.0932H13.0578ZM16.6632 20.2423L16.8746 20.4537C17.2307 20.8098 17.4421 21.2994 17.4532 21.8001V23.3024C17.4532 23.8254 17.2529 24.315 16.8857 24.6822L16.6854 24.8825C16.3182 25.2497 15.8175 25.45 15.3056 25.45H13.0578V19.697H15.339C15.8286 19.697 16.3071 19.8973 16.6632 20.2423Z"
        fill={stroke}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.6638 7.47907L27.4457 9.0258C27.4457 9.03137 27.4485 9.03415 27.4526 9.03832C27.4568 9.0425 27.4624 9.04806 27.4679 9.05919C28.2691 9.86037 28.1356 11.1957 27.2676 11.9301L20.9694 17.2936C20.6134 17.6497 20.2573 17.2936 20.2573 16.9375L22.2157 12.0302C22.327 11.7632 22.2157 11.4516 21.9709 11.2958L18.8218 9.40414C18.1097 9.04806 17.7536 7.96869 18.4657 7.25652L25.643 1.14749C25.9991 0.791412 26.3552 1.14749 26.3552 1.50358L24.3856 6.74466C24.2855 7.02284 24.3967 7.33442 24.6638 7.47907ZM18.7774 27.0857C19.9458 25.9173 20.6023 24.3261 20.6023 22.6792C20.6023 21.0435 19.9569 19.4633 18.7996 18.3061L18.5548 18.0613C19.3115 17.2935 19.7455 16.2586 19.7455 15.1792V13.766C19.7455 12.2638 19.1446 10.8172 18.0875 9.76012C17.4198 9.09247 16.5185 8.72526 15.5838 8.72526H13.0578V7.045C13.0578 6.2772 12.4347 5.66518 11.678 5.66518C10.9102 5.66518 10.2982 6.28832 10.2982 7.045V8.72526H5.04599C4.46736 8.72526 4 9.19261 4 9.77125V27.1302C4 27.7089 4.46736 28.1762 5.04599 28.1762H10.2871V29.8565C10.2871 30.6243 10.9102 31.2363 11.6669 31.2363C12.4347 31.2363 13.0467 30.6132 13.0467 29.8565V28.1762H16.1402C17.1305 28.1762 18.0764 27.7868 18.7774 27.0857ZM10.3093 17.0932H7.1491V11.4515H10.3093V17.0932ZM10.3093 19.697V25.45H7.1491V19.697H10.3093ZM13.0578 17.0932V11.4515L14.6825 11.4404C15.1832 11.4404 15.6617 11.6407 16.0178 11.9968C16.3738 12.3528 16.5741 12.8425 16.5741 13.3432V15.1459C16.5741 15.6689 16.3738 16.1585 16.0066 16.5257C15.6394 16.8929 13.8813 17.0932 13.3694 17.0932H13.0578ZM16.6632 20.2423L16.8746 20.4537C17.2307 20.8098 17.4421 21.2994 17.4532 21.8001V23.3024C17.4532 23.8254 17.2529 24.315 16.8857 24.6822L16.6854 24.8825C16.3182 25.2497 15.8175 25.45 15.3056 25.45H13.0578V19.697H15.339C15.8286 19.697 16.3071 19.8973 16.6632 20.2423Z"
        fill={stroke}
      />
    </svg>
  );
};

export default CoinSave_Icon;
