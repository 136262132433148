import HTTPCore from "../http.core";
import axios from "axios";

class ApiCoreService extends HTTPCore {
    constructor() {
        super({})
    }
    api;
    //==============================
    // async GetAllSite(page) {
    //     //================Set Parameter============
    //     const path = `/api/plant/?page=${page}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //             // { params: param }
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async GetAllSiteByCompanyId(id) {
    //     //================Set Parameter============
    //     const path = `/api/plant/company/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async GetSiteById(id) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //             // { params: param }
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async AddSite(param) {
    //     //================Set Parameter============
    //     const path = '/api/plant/';
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async EditSite(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.patch(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // //=========================================
    // async GetBuildingBySiteId(id) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/building/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async AddBuilding(param) {
    //     //================Set Parameter============
    //     const path = '/api/building/';
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async EditBuilding(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/building/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.patch(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeleteBuilding(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.delete(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async GetImageByBuildingId(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/${id}/albums/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeleteImageBuildingById(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/building/${id}/albums/remove/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async UploadImageFileByBuildingId(id, file) {
    //     //================Set Parameter============
    //     const path = `/api/building/${id}/albums/add/`;
    //     let body = new FormData();
    //     body.append("file", file);
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             body,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    //=========================================
    // async GetFloorByBuildingId(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/${id}/floor/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async AddFloor(param) {
    //     //================Set Parameter============
    //     const path = '/api/building/floor/';
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async EditFloor(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/building/floor/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.patch(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeleteFloor(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/floor/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.delete(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async GetImageByFloorId(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/floor/${id}/albums/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeleteImageFloorById(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/building/floor/${id}/albums/remove/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async UploadImageFileByFloorId(id, file) {
    //     //================Set Parameter============
    //     const path = `/api/building/floor/${id}/albums/add/`;
    //     let body = new FormData();
    //     body.append("file", file);
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             body,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    //=========================================
    // async GetPlaceByFloorId(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/floor/${id}/place/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async AddPlace(param) {
    //     //================Set Parameter============
    //     const path = '/api/building/place/';
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async EditPlace(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/building/place/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.patch(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeletePlace(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/place/${id}/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.delete(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             response = 'Api Error.';
    //             if (e.response.data) {
    //                 try {
    //                     response = JSON.stringify(e.response.data);
    //                 } catch { }
    //             } else if (e.response.statusText) {
    //                 response = `${e.response.statusText}.`;
    //             }
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async GetImageByPlaceId(id) {
    //     //================Set Parameter============
    //     const path = `/api/building/place/${id}/albums/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeleteImagePlaceById(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/building/place/${id}/albums/remove/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async UploadImageFileByPlaceId(id, file) {
    //     //================Set Parameter============
    //     const path = `/api/building/place/${id}/albums/add/`;
    //     let body = new FormData();
    //     body.append("file", file);
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             body,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // //=========================================
    // async GetDeviceBySiteId(id) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/device/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async GetImageBySiteId(id) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/albums/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.get(path,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async DeleteImageSiteById(id, param) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/albums/remove/`;
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             param,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
    // async UploadImageFileBySiteId(id, file) {
    //     //================Set Parameter============
    //     const path = `/api/plant/${id}/albums/add/`;
    //     let body = new FormData();
    //     body.append("file", file);
    //     //================Call API=================
    //     let status = false;
    //     let response;
    //     let errorCode;
    //     try {
    //         response = await this.post(path,
    //             body,
    //         );
    //         status = true;
    //     } catch (e) {
    //         if (e.response?.status) {
    //             errorCode = e.response?.status;
    //         }
    //     }
    //     //=========================================
    //     return {
    //         status: status,
    //         data: response,
    //     }
    // }
}

export default ApiCoreService;
