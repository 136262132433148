import styled from '@emotion/styled'
import ListItemIcon from '@mui/material/ListItemIcon'
import Menu from '@mui/material/Menu'
import MuiMenuItem from '@mui/material/MenuItem'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoggedInUser } from '../../api/userInfo'
import engIcon from '../../assets/images/eng.png'
// import dashboardIcon from '../../assets/images/ic_dashboard.png'
import downarrowIcon from '../../assets/images/ic_downArrow.png'
// import messageIcon from '../../assets/images/ic_message_open.png'
// import notificationIcon from '../../assets/images/ic_notification_black.png'
import tempProfile from '../../assets/images/temp_profile.png'
import thIcon from '../../assets/images/th.png'
import { getLogOut } from '../../context/auth/auth_action'
import { AuthContext } from '../../context/auth/auth_context'

const MenuItem = styled(MuiMenuItem)`
  :hover {
    background-color: #eef5f7;
  }
`

const MenuHeader = () => {
  const authContext = useContext(AuthContext)
  const { dispatch } = authContext
  const { t, i18n } = useTranslation('main')
  const [lang, setLang] = useState(localStorage.getItem('i18nextLng') || 'en')
  const [anchorElMenu, setAnchorElMenu] = useState(null)
  const [anchorElMenuLang, setAnchorElMenuLang] = useState(null)
  const { user, isLoading, isError } = useLoggedInUser()
  const [langColor, setLangColor] = useState({ th: 'balck', en: 'vekin' })

  const onMenuOpen = (setState) => (event) => {
    setState(event.currentTarget)
  }

  const onMenuClose = (setState) => (event) => {
    setState(null)
  }

  const onSetLang = (value) => (event) => {
    i18n.changeLanguage(value)
    setLang(value)
  }

  const onLogOut = (event) => {
    getLogOut(dispatch)
    localStorage.clear()
  }

  const role = (user?.is_admin && user?.is_superuser) ? 'Super Admin' : 
                user?.is_admin ? "Admin": 
                user?.is_superuser? "Super Admin" : 'User'

  const MenuList = () => {
    return (
      <>
        <div className='px-4'>
          <p className="text-base">{role}</p>
        </div>
        <MenuItem onClick={onLogOut} className='px-4'>
          <p className="text-base">{t('main:header_menu.sign_out')}</p>
        </MenuItem>
      </>
    )
  }



  const MenuHeader = ({ children, anchorEl, setAnchorEl }) => {
    return (
      <Menu
       className='mt-4'
        autoFocus={false}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMenuClose(setAnchorEl)}
        onClick={onMenuClose(setAnchorEl)}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        MenuListProps={{ disablePadding: true }}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            width: anchorElMenu ? 160 : 'auto',
            boxShadow: '2px 4px 8px 0px #52729725'
          }
        }}
      >
        {children}
      </Menu>
    )
  }

  return (
    <div className="flex">


      <div
        className="flex items-center h-full gap-4 cursor-pointer"
        style={{ marginLeft: 16, borderRadius: 0 }}
      >
        <div >
          <img alt="tempProfileIcon" className="w-8 h-8" src={tempProfile} />
        </div>
        <span>{user?.first_name ? `${user?.first_name} ${user.last_name}` : 'No name'}</span>

        
        <img
          src={downarrowIcon}
          alt="downarrowIcon"
          onClick={onMenuOpen(setAnchorElMenu)}
          className="w-[15px] h-[10px]"
        />
        <MenuHeader children={<MenuList />} anchorEl={anchorElMenu} setAnchorEl={setAnchorElMenu} />
      </div>
      
      {/* <MenuHeader
        children={<MenuLangList />}
        anchorEl={anchorElMenuLang}
        setAnchorEl={setAnchorElMenuLang}
      /> */}
    </div>
  )
}

export default MenuHeader
