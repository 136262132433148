export const FewClouds_S = ({style}) => {
    return ( 
        <svg className={style} width="65" height="65" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_1570_77815)">
<path fillRule="evenodd" clipRule="evenodd" d="M4.608 14.4502C4.686 14.7112 4.94025 14.8785 5.211 14.847C5.3445 14.832 5.481 14.8237 5.619 14.8237C5.69325 14.8237 5.766 14.826 5.83875 14.8305C6.05475 14.844 6.25875 14.7322 6.36375 14.544C7.02525 13.362 8.289 12.5625 9.73875 12.5625C11.718 12.5625 13.3522 14.0542 13.5757 15.9742C13.6087 16.2577 13.8495 16.4715 14.1345 16.4715C15.1027 16.4722 15.9525 16.9755 16.4385 17.7345C16.5285 17.8755 16.677 17.9685 16.8435 17.9895C17.01 18.0097 17.1765 17.955 17.2987 17.8395C18.7867 16.4332 19.716 14.4405 19.716 12.2325C19.716 7.97399 16.2585 4.51649 12 4.51649C7.7415 4.51649 4.284 7.97399 4.284 12.2325C4.284 13.0035 4.39725 13.7475 4.608 14.4502Z" fill="url(#paint0_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.56125 13.6995C3.00375 13.7303 0.9375 15.816 0.9375 18.381C0.9375 20.1067 1.85325 23.0625 5.34675 23.0625C14.1345 23.0625 14.1345 23.0625 14.1345 23.0625C15.3915 23.0625 16.35 22.6163 16.9995 21.918C17.6467 21.2228 17.9925 20.2658 17.9925 19.2045C17.9925 17.2373 16.5195 15.6135 14.616 15.3765C14.1338 13.1265 12.132 11.4375 9.73875 11.4375C7.99125 11.4375 6.45225 12.3375 5.56125 13.6995Z" fill="url(#paint1_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.4375 1.5V3C11.4375 3.3105 11.6895 3.5625 12 3.5625C12.3105 3.5625 12.5625 3.3105 12.5625 3V1.5C12.5625 1.1895 12.3105 0.9375 12 0.9375C11.6895 0.9375 11.4375 1.1895 11.4375 1.5Z" fill="url(#paint2_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.7625 2.62575L16.0125 3.92475C15.858 4.19325 15.9502 4.53751 16.2187 4.69275C16.4872 4.84801 16.8322 4.75575 16.9875 4.48725L17.7375 3.18825C17.892 2.919 17.7997 2.57475 17.5312 2.4195C17.2627 2.26425 16.9177 2.3565 16.7625 2.62575Z" fill="url(#paint3_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M20.8117 6.2625L19.5127 7.0125C19.2442 7.16775 19.152 7.51275 19.3072 7.78125C19.4625 8.04975 19.8067 8.142 20.0752 7.9875L21.3742 7.2375C21.6435 7.08225 21.7357 6.73725 21.5805 6.46875C21.4252 6.20025 21.081 6.108 20.8117 6.2625Z" fill="url(#paint4_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.5 11.4375H21C20.6895 11.4375 20.4375 11.6895 20.4375 12C20.4375 12.3105 20.6895 12.5625 21 12.5625H22.5C22.8105 12.5625 23.0625 12.3105 23.0625 12C23.0625 11.6895 22.8105 11.4375 22.5 11.4375Z" fill="url(#paint5_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M21.3742 16.7625L20.0752 16.0125C19.8067 15.858 19.4625 15.9502 19.3072 16.2187C19.152 16.4872 19.2442 16.8322 19.5127 16.9875L20.8117 17.7375C21.081 17.892 21.4252 17.7997 21.5805 17.5312C21.7357 17.2627 21.6435 16.9177 21.3742 16.7625Z" fill="url(#paint6_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.5 12.5625H3C3.3105 12.5625 3.5625 12.3105 3.5625 12C3.5625 11.6895 3.3105 11.4375 3 11.4375H1.5C1.1895 11.4375 0.9375 11.6895 0.9375 12C0.9375 12.3105 1.1895 12.5625 1.5 12.5625Z" fill="url(#paint7_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.62574 7.2375L3.92474 7.9875C4.19324 8.142 4.53749 8.04975 4.69274 7.78125C4.84799 7.51275 4.75574 7.16775 4.48724 7.0125L3.18824 6.2625C2.91899 6.108 2.57474 6.20025 2.41949 6.46875C2.26424 6.73725 2.35649 7.08225 2.62574 7.2375Z" fill="url(#paint8_linear_1570_77815)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.2625 3.18825L7.0125 4.48725C7.16775 4.75575 7.51275 4.84801 7.78125 4.69275C8.04975 4.53751 8.142 4.19325 7.9875 3.92475L7.2375 2.62575C7.08225 2.3565 6.73725 2.26425 6.46875 2.4195C6.20025 2.57475 6.108 2.919 6.2625 3.18825Z" fill="url(#paint9_linear_1570_77815)"/>
</g>
<defs>
<linearGradient id="paint0_linear_1570_77815" x1="13.2732" y1="11.1628" x2="9.95782" y2="24.4575" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#DBB500"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_77815" x1="10.5" y1="18.9158" x2="10.5" y2="33.375" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
<linearGradient id="paint2_linear_1570_77815" x1="12.0202" y1="2.34422" x2="12.0202" y2="8.625" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint3_linear_1570_77815" x1="16.9701" y1="3.375" x2="14.25" y2="8.76855" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint4_linear_1570_77815" x1="20.5834" y1="7.125" x2="16.5" y2="9.75" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint5_linear_1570_77815" x1="21.9403" y1="12" x2="14.4214" y2="12" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint6_linear_1570_77815" x1="20.25" y1="16.875" x2="16.9701" y2="14.1485" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint7_linear_1570_77815" x1="2.057" y1="12" x2="15.75" y2="12" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint8_linear_1570_77815" x1="3.41656" y1="7.125" x2="10.9394" y2="12" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint9_linear_1570_77815" x1="7.125" y1="3.52725" x2="10.7787" y2="9.375" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<clipPath id="clip0_1570_77815">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>




     );
}

export const FewClouds_N = ({style}) => {
    return (
        <svg className={style} width="65" height="65" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M22.8119 12.8392C22.4346 12.3026 21.7661 12.0242 21.0996 12.1213C20.9234 12.1432 20.7396 12.1744 20.5576 12.2128C17.2078 12.9674 14.5648 12.3246 12.9253 10.6818C11.2763 9.02987 10.6692 6.4128 11.3069 3.33972C11.3069 3.33972 11.5214 2.59068 10.763 2.10169C10.0199 1.68047 8.56429 2.68225 8.21763 3.02289C6.24492 4.69678 5 7.13987 5 9.86133C5 14.905 9.28442 19 14.5571 19C17.52 19 20.165 17.7107 21.9194 15.6907C22.2698 15.3702 23.4554 13.7311 22.8119 12.8392Z" fill="#6CCCDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.8119 12.8392C22.4346 12.3026 21.7661 12.0242 21.0996 12.1213C20.9234 12.1432 20.7396 12.1744 20.5576 12.2128C17.2078 12.9674 14.5648 12.3246 12.9253 10.6818C11.2763 9.02987 10.6692 6.4128 11.3069 3.33972C11.3069 3.33972 11.5214 2.59068 10.763 2.10169C10.0199 1.68047 8.56429 2.68225 8.21763 3.02289C6.24492 4.69678 5 7.13987 5 9.86133C5 14.905 9.28442 19 14.5571 19C17.52 19 20.165 17.7107 21.9194 15.6907C22.2698 15.3702 23.4554 13.7311 22.8119 12.8392Z" fill="url(#paint0_linear_1570_78000)" fillOpacity="0.7"/>
<path fillRule="evenodd" clipRule="evenodd" d="M5.56125 13.6995C3.00375 13.7303 0.9375 15.816 0.9375 18.381C0.9375 20.1067 1.85325 23.0625 5.34675 23.0625C14.1345 23.0625 14.1345 23.0625 14.1345 23.0625C15.3915 23.0625 16.35 22.6163 16.9995 21.918C17.6467 21.2228 17.9925 20.2658 17.9925 19.2045C17.9925 17.2373 16.5195 15.6135 14.616 15.3765C14.1338 13.1265 12.132 11.4375 9.73875 11.4375C7.99125 11.4375 6.45225 12.3375 5.56125 13.6995Z" fill="url(#paint1_linear_1570_78000)"/>
<defs>
<linearGradient id="paint0_linear_1570_78000" x1="6.28571" y1="0.583351" x2="23.0711" y2="1.125" gradientUnits="userSpaceOnUse">
<stop stopColor="#0085FF" stopOpacity="0"/>
<stop offset="1" stopColor="#0085FF"/>
</linearGradient>
<linearGradient id="paint1_linear_1570_78000" x1="10.5" y1="18.9158" x2="10.5" y2="33.375" gradientUnits="userSpaceOnUse">
<stop stopColor="#E2F4FD"/>
<stop offset="1" stopColor="#4DC4FF"/>
</linearGradient>
</defs>
</svg>



     );
}
 
