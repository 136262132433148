const Icon_Others = ({style}) => {
    return (
        <svg className={style} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.41162 10.8349V15.3349C9.41162 15.7474 9.74912 16.0849 10.1616 16.0849H14.6616C15.0741 16.0849 15.4116 15.7474 15.4116 15.3349V10.8349C15.4116 10.4224 15.0741 10.0849 14.6616 10.0849H10.1616C9.74912 10.0849 9.41162 10.4224 9.41162 10.8349ZM2.66162 16.0849H7.16162C7.57412 16.0849 7.91162 15.7474 7.91162 15.3349V10.8349C7.91162 10.4224 7.57412 10.0849 7.16162 10.0849H2.66162C2.24912 10.0849 1.91162 10.4224 1.91162 10.8349V15.3349C1.91162 15.7474 2.24912 16.0849 2.66162 16.0849ZM1.91162 3.3349V7.8349C1.91162 8.2474 2.24912 8.5849 2.66162 8.5849H7.16162C7.57412 8.5849 7.91162 8.2474 7.91162 7.8349V3.3349C7.91162 2.9224 7.57412 2.5849 7.16162 2.5849H2.66162C2.24912 2.5849 1.91162 2.9224 1.91162 3.3349ZM11.6241 2.1349L8.43662 5.3149C8.14412 5.6074 8.14412 6.0799 8.43662 6.3724L11.6241 9.5599C11.9166 9.8524 12.3891 9.8524 12.6816 9.5599L15.8691 6.3724C16.1616 6.0799 16.1616 5.6074 15.8691 5.3149L12.6891 2.1349C12.3966 1.8424 11.9166 1.8424 11.6241 2.1349Z" fill="#EE84E3"/>
<path d="M9.41162 10.8349V15.3349C9.41162 15.7474 9.74912 16.0849 10.1616 16.0849H14.6616C15.0741 16.0849 15.4116 15.7474 15.4116 15.3349V10.8349C15.4116 10.4224 15.0741 10.0849 14.6616 10.0849H10.1616C9.74912 10.0849 9.41162 10.4224 9.41162 10.8349ZM2.66162 16.0849H7.16162C7.57412 16.0849 7.91162 15.7474 7.91162 15.3349V10.8349C7.91162 10.4224 7.57412 10.0849 7.16162 10.0849H2.66162C2.24912 10.0849 1.91162 10.4224 1.91162 10.8349V15.3349C1.91162 15.7474 2.24912 16.0849 2.66162 16.0849ZM1.91162 3.3349V7.8349C1.91162 8.2474 2.24912 8.5849 2.66162 8.5849H7.16162C7.57412 8.5849 7.91162 8.2474 7.91162 7.8349V3.3349C7.91162 2.9224 7.57412 2.5849 7.16162 2.5849H2.66162C2.24912 2.5849 1.91162 2.9224 1.91162 3.3349ZM11.6241 2.1349L8.43662 5.3149C8.14412 5.6074 8.14412 6.0799 8.43662 6.3724L11.6241 9.5599C11.9166 9.8524 12.3891 9.8524 12.6816 9.5599L15.8691 6.3724C16.1616 6.0799 16.1616 5.6074 15.8691 5.3149L12.6891 2.1349C12.3966 1.8424 11.9166 1.8424 11.6241 2.1349Z" fill="url(#paint0_linear_2819_56347)"/>
<defs>
<linearGradient id="paint0_linear_2819_56347" x1="2.08039" y1="7.81943" x2="16.3281" y2="7.96489" gradientUnits="userSpaceOnUse">
<stop stop-color="#AE70FF"/>
<stop offset="1" stop-color="#FF58DA" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>


    )
}

export default Icon_Others;
