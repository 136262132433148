const CoalPotential_Icon =({style, stroke}) => {
    return (
        <svg className={style}  width="30" height="31" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.5 29.1H8.89998C5.69998 29.1 3.09998 26.5 3.09998 23.3V18.13C3.09998 15.9 4.90998 14.1 7.12998 14.1H27V16.5C27 21.8 23.4 22.3 21.9 22.3H21.5C20 22.3 18.6 22.3 17.1 23.7C16.1 24.6 16 26.8 16.1 27.5L16.2 29.1H14.5Z" fill={stroke}/>
<path d="M14.5 29.1H8.89998C5.69998 29.1 3.09998 26.5 3.09998 23.3V18.13C3.09998 15.9 4.90998 14.1 7.12998 14.1H27V16.5C27 21.8 23.4 22.3 21.9 22.3H21.5C20 22.3 18.6 22.3 17.1 23.7C16.1 24.6 16 26.8 16.1 27.5L16.2 29.1H14.5Z" fill={stroke}/>
<path d="M21.1 30.2C23.033 30.2 24.6 28.6329 24.6 26.7C24.6 24.767 23.033 23.2 21.1 23.2C19.167 23.2 17.6 24.767 17.6 26.7C17.6 28.6329 19.167 30.2 21.1 30.2Z" fill={stroke}/>
<path d="M21.1 30.2C23.033 30.2 24.6 28.6329 24.6 26.7C24.6 24.767 23.033 23.2 21.1 23.2C19.167 23.2 17.6 24.767 17.6 26.7C17.6 28.6329 19.167 30.2 21.1 30.2Z" fill={stroke}/>
<path d="M11.8 12.83L9.39999 10.33C9.11999 10.05 8.67999 10.05 8.39999 10.32L6.39999 12.26C6.14999 12.5 5.81999 12.64 5.46999 12.64C4.59999 12.64 4.15999 11.59 4.77999 10.97L7.48999 8.26003C8.26999 7.48003 9.54999 7.48003 10.32 8.27003L14.81 12.84H11.8V12.83Z" fill={stroke}/>
<path d="M11.8 12.83L9.39999 10.33C9.11999 10.05 8.67999 10.05 8.39999 10.32L6.39999 12.26C6.14999 12.5 5.81999 12.64 5.46999 12.64C4.59999 12.64 4.15999 11.59 4.77999 10.97L7.48999 8.26003C8.26999 7.48003 9.54999 7.48003 10.32 8.27003L14.81 12.84H11.8V12.83Z" fill={stroke}/>
<path d="M23.9 12.83L21.5 10.33C21.22 10.05 20.78 10.05 20.5 10.32L18.11 12.83H15.21L19.59 8.25002C20.37 7.47002 21.65 7.47002 22.42 8.26002L26.91 12.83H23.9Z" fill={stroke}/>
<path d="M23.9 12.83L21.5 10.33C21.22 10.05 20.78 10.05 20.5 10.32L18.11 12.83H15.21L19.59 8.25002C20.37 7.47002 21.65 7.47002 22.42 8.26002L26.91 12.83H23.9Z" fill={stroke}/>
<path d="M16.58 1.76005C15.7 0.880048 14.28 0.870048 13.39 1.74005L8.45996 6.62005C9.24996 6.36005 10.15 6.54005 10.78 7.18005L11.26 7.67005L14.43 4.50005C14.74 4.19005 15.25 4.18005 15.57 4.49005L18.7 7.56005L19.09 7.17005C19.73 6.53005 20.66 6.36005 21.46 6.65005L16.58 1.76005Z" fill={stroke}/>
<path d="M16.58 1.76005C15.7 0.880048 14.28 0.870048 13.39 1.74005L8.45996 6.62005C9.24996 6.36005 10.15 6.54005 10.78 7.18005L11.26 7.67005L14.43 4.50005C14.74 4.19005 15.25 4.18005 15.57 4.49005L18.7 7.56005L19.09 7.17005C19.73 6.53005 20.66 6.36005 21.46 6.65005L16.58 1.76005Z" fill={stroke}/>


</svg>

    )
}

export default CoalPotential_Icon ;