const Icon_Recycling = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#F2F8F6"/>
<path d="M23.9994 12.4528C22.8303 12.4528 21.7838 13.2033 21.0838 14.3869L18.4279 18.8615L20.0445 19.7853L22.6715 15.3396C23.1442 14.5385 23.6206 14.3003 23.9994 14.3003C24.3783 14.3003 24.8547 14.5385 25.3274 15.3396L28.2142 20.2183L26.7419 21.1132L31.3608 23.8845V18.3418L29.8019 19.2656L26.9151 14.3869C26.2151 13.2033 25.1686 12.4528 23.9994 12.4528ZM18.399 20.7379L13.7801 23.5092L15.1947 24.3464L12.6254 28.7055C11.929 29.8855 11.7738 31.1917 12.3656 32.1985C12.9574 33.2053 14.1554 33.6997 15.5411 33.6997H21.2281V31.8521H15.5411C14.5848 31.8521 14.1302 31.549 13.9534 31.2459C13.7765 30.9428 13.7405 30.4304 14.2132 29.6293L16.7536 25.2991L18.399 26.2806V20.7379ZM33.1218 24.9815L31.5629 25.9342L33.7857 29.6293C34.262 30.434 34.2224 30.9428 34.0455 31.2459C33.8687 31.549 33.414 31.8521 32.4578 31.8521H27.6946V30.0045L23.0757 32.7759L27.6946 35.5472V33.6997H32.4578C33.8435 33.6997 35.0415 33.2053 35.6333 32.1985C36.2251 31.1917 36.0735 29.8891 35.3735 28.7055V28.6766L33.1218 24.9815Z" fill="#528ACC" stroke="#528ACC" stroke-linejoin="round"/>
</svg>

     );
}
 
export default Icon_Recycling;