const TrashIcon = ({style}) => {
    return ( 
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#79C8B0"/>
<rect width="60" height="60" rx="30" fill="url(#paint0_linear_48_6867)"/>
<path d="M39.608 18.1394H20.4452C20.2591 18.1394 19.9801 18.3255 19.8871 18.6045L18.6777 21.9534C18.3987 22.6045 18.6777 23.5348 19.1429 23.5348H40.8173C41.2824 23.5348 41.5615 22.6975 41.3754 21.9534L40.1661 18.6045C40.0731 18.3255 39.887 18.1394 39.608 18.1394Z" fill="#A5DACB"/>
<path d="M31.515 16H28.5382C27.515 16 26.6778 16.3721 26.5847 16.8372L26.2126 18.0465H33.8406L33.4685 16.8372C33.3754 16.3721 32.5382 16 31.515 16Z" fill="#79C8B0"/>
<path d="M19.701 25.0232L21.0964 40.186C21.2824 42.3255 23.0499 43.9999 25.1894 43.9999H34.7708C36.9103 43.9999 38.6778 42.3255 38.8638 40.186L40.2592 25.0232H19.701ZM29.1894 38.7906C29.0964 38.9767 28.9103 39.0697 28.7243 39.0697C28.7243 39.0697 28.7243 39.0697 28.6313 39.0697C26.0266 38.4185 24.2592 35.9999 24.3522 33.3953C24.3522 33.2092 24.3522 32.9302 24.4452 32.7441L23.0499 32.279L25.4685 31.0697L26.6778 33.4883L25.2824 33.0232C25.2824 33.2092 25.2824 33.3023 25.2824 33.4883C25.1894 35.8139 26.7708 37.8604 29.0034 38.4185C29.1894 38.4185 29.2824 38.6046 29.1894 38.7906ZM26.5848 30.1395C26.4917 30.2325 26.3987 30.2325 26.2127 30.2325C26.1197 30.2325 26.0266 30.2325 26.0266 30.1395C25.8406 29.9534 25.8406 29.7674 26.0266 29.5813C27.8871 27.6278 30.8638 27.2558 33.0964 28.5581C33.2824 28.6511 33.4685 28.8371 33.6545 28.9302L34.7708 27.9069L34.6778 30.6046L31.9801 30.5116L33.0034 29.4883C32.9103 29.3953 32.7243 29.3023 32.6313 29.2092C30.6778 28.093 28.1662 28.4651 26.5848 30.1395ZM33.0034 38.5116C32.8173 38.6046 32.6313 38.6976 32.3522 38.7906L32.7243 40.279L30.4917 38.8837L31.8871 36.6511L32.2592 38.0464C32.3522 37.9534 32.5383 37.9534 32.6313 37.8604C34.5848 36.7441 35.608 34.3255 34.9569 32.093C34.8638 31.9069 35.0499 31.6278 35.2359 31.6278C35.422 31.5348 35.701 31.7209 35.701 31.9069C36.4452 34.4185 35.3289 37.2092 33.0034 38.5116Z" fill="#F2F8F6"/>
<defs>
<linearGradient id="paint0_linear_48_6867" x1="0.714286" y1="25" x2="61.0141" y2="25.6153" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default TrashIcon;