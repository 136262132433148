import { SelectNormalIcons } from "../SetupWidget/SelectWidgetIcon";
import MultiSelectBtn from "./multiSelectBtn";
import { useGetDevice } from "../../../api/userInfo";
import { useEffect, useState } from "react";
import { useWidgetSetupStore } from "../../../context/stores";
import {shallow} from 'zustand/shallow'

export const DeviceFieldSetup = ({updateWidgetTitle, clearForm, submitForm, updateSetupDetails, setupDetails}) => {
    const {deviceList, isLoadingDeviceList, isErrorDeviceList} = useGetDevice(localStorage.getItem('plantId'));
    const [multiSelect,setMultiSelect ]= useState({})
    const [selectedWidget, updateSelectedWidget] = useWidgetSetupStore((state) => [state.selectedWidget, state.updateSelectedWidget],shallow)
    

    useEffect(() => {
        if(multiSelect?.data){
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                    ...setupDetails?.measurement?.[0],
                    [multiSelect?.field]: multiSelect?.data.length > 1 ? multiSelect?.data : multiSelect?.data[0],
                  }]
            });
        }
    }, [multiSelect]);

    
    const onInput = (field) => (e) => {
        updateWidgetTitle(e.target.value);
        if(selectedWidget == "Battery Alarm Widget"){
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                    ...setupDetails?.measurement?.[0],
                    [field]: e.target.value,
                  }]
            });
        }
    };
    
    return ( 
        <>
            <div className="flex flex-col gap-4 mt-4">
                    <div  className="flex flex-col col-span-4 gap-2">
                            <label htmlFor="title" className="col-span-1 text-sm text-gray-500">Title</label>
                            <input onChange={onInput('title')} autoComplete="off" required type="text" name="title"  className="setupModal_input" placeholder="Title of widget"/>
                    </div> 
                        
                    <div className="flex flex-col gap-2">
                        <label htmlFor="device" className="col-span-1 text-sm text-gray-500">Device ID (select one or more devices)</label>
                        <MultiSelectBtn setMultiSelect={setMultiSelect} data={deviceList?.devices} name="device" placeholder="select device" />

                    </div> 
                
                    
                
                        

                    
            </div>
                
            

                
                {/* ------------ submit button */}
                <div className='flex flex-row justify-between gap-4 mt-4'>
                <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
                    Cancel
                </button>

                
                    <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                        Submit
                    </button>  

                
                </div>
        </>
     );
}


 

