const Icon_CoalPotential = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M29.4637 44.1625H23.3037C19.7837 44.1625 16.9237 41.3025 16.9237 37.7825V32.1038C16.9237 29.6563 18.9175 27.6763 21.3512 27.6763H43.2V30.3163C43.2 36.1463 39.24 36.6963 37.59 36.6963H37.15C35.5 36.6963 33.96 36.6963 32.31 38.2363C31.21 39.2263 31.1 41.6463 31.21 42.4163L31.32 44.1763L29.4637 44.1625Z" fill="#FFE600"/>
<path d="M29.4637 44.1625H23.3037C19.7837 44.1625 16.9237 41.3025 16.9237 37.7825V32.1038C16.9237 29.6563 18.9175 27.6763 21.3512 27.6763H43.2V30.3163C43.2 36.1463 39.24 36.6963 37.59 36.6963H37.15C35.5 36.6963 33.96 36.6963 32.31 38.2363C31.21 39.2263 31.1 41.6463 31.21 42.4163L31.32 44.1763L29.4637 44.1625Z" fill="url(#paint0_linear_3293_57608)"/>
<path d="M36.71 45.3725C38.8363 45.3725 40.56 43.6488 40.56 41.5225C40.56 39.3962 38.8363 37.6725 36.71 37.6725C34.5837 37.6725 32.86 39.3962 32.86 41.5225C32.86 43.6488 34.5837 45.3725 36.71 45.3725Z" fill="#FFE600"/>
<path d="M36.71 45.3725C38.8363 45.3725 40.56 43.6488 40.56 41.5225C40.56 39.3962 38.8363 37.6725 36.71 37.6725C34.5837 37.6725 32.86 39.3962 32.86 41.5225C32.86 43.6488 34.5837 45.3725 36.71 45.3725Z" fill="url(#paint1_linear_3293_57608)"/>
<path d="M26.4937 26.2875L23.8537 23.5375C23.5512 23.235 23.0562 23.235 22.7537 23.5237L20.5537 25.655C20.2787 25.9162 19.9212 26.0675 19.5362 26.0675C18.5737 26.0675 18.0925 24.9125 18.78 24.2387L21.7637 21.255C22.6162 20.4025 24.0325 20.4025 24.8712 21.2687L29.8075 26.2875H26.4937Z" fill="#FFE600"/>
<path d="M26.4937 26.2875L23.8537 23.5375C23.5512 23.235 23.0562 23.235 22.7537 23.5237L20.5537 25.655C20.2787 25.9162 19.9212 26.0675 19.5362 26.0675C18.5737 26.0675 18.0925 24.9125 18.78 24.2387L21.7637 21.255C22.6162 20.4025 24.0325 20.4025 24.8712 21.2687L29.8075 26.2875H26.4937Z" fill="url(#paint2_linear_3293_57608)"/>
<path d="M39.7901 26.2875L37.1501 23.5375C36.8476 23.235 36.3526 23.235 36.0501 23.5237L33.4376 26.2875H30.2476L35.0601 21.255C35.9126 20.4025 37.3288 20.4025 38.1676 21.2687L43.1038 26.2875H39.7901Z" fill="#FFE600"/>
<path d="M39.7901 26.2875L37.1501 23.5375C36.8476 23.235 36.3526 23.235 36.0501 23.5237L33.4376 26.2875H30.2476L35.0601 21.255C35.9126 20.4025 37.3288 20.4025 38.1676 21.2687L43.1038 26.2875H39.7901Z" fill="url(#paint3_linear_3293_57608)"/>
<path d="M31.7463 14.1187C30.7838 13.1562 29.2163 13.1425 28.24 14.0912L22.8225 19.4537C23.6888 19.165 24.6788 19.3712 25.3663 20.0725L25.8888 20.6087L29.3675 17.13C29.7113 16.7862 30.275 16.7725 30.6188 17.1162L34.0563 20.485L34.4825 20.0587C35.1838 19.3575 36.2013 19.165 37.0813 19.4812L31.7463 14.1187Z" fill="#FFE600"/>
<path d="M31.7463 14.1187C30.7838 13.1562 29.2163 13.1425 28.24 14.0912L22.8225 19.4537C23.6888 19.165 24.6788 19.3712 25.3663 20.0725L25.8888 20.6087L29.3675 17.13C29.7113 16.7862 30.275 16.7725 30.6188 17.1162L34.0563 20.485L34.4825 20.0587C35.1838 19.3575 36.2013 19.165 37.0813 19.4812L31.7463 14.1187Z" fill="url(#paint4_linear_3293_57608)"/>
<path d="M30 50.8175C18.5325 50.8175 9.1825 41.4812 9.1825 30C9.1825 18.5187 18.5325 9.1825 30 9.1825C41.4675 9.1825 50.8175 18.5187 50.8175 30C50.8175 41.4812 41.4675 50.8175 30 50.8175ZM30 11.6575C19.8937 11.6575 11.6575 19.8937 11.6575 30C11.6575 40.1062 19.8937 48.3425 30 48.3425C40.1062 48.3425 48.3425 40.12 48.3425 30C48.3425 19.88 40.1062 11.6575 30 11.6575Z" fill="#FFE600"/>
<path d="M30 50.8175C18.5325 50.8175 9.1825 41.4812 9.1825 30C9.1825 18.5187 18.5325 9.1825 30 9.1825C41.4675 9.1825 50.8175 18.5187 50.8175 30C50.8175 41.4812 41.4675 50.8175 30 50.8175ZM30 11.6575C19.8937 11.6575 11.6575 19.8937 11.6575 30C11.6575 40.1062 19.8937 48.3425 30 48.3425C40.1062 48.3425 48.3425 40.12 48.3425 30C48.3425 19.88 40.1062 11.6575 30 11.6575Z" fill="url(#paint5_linear_3293_57608)"/>
<defs>
<linearGradient id="paint0_linear_3293_57608" x1="17.2365" y1="26.3013" x2="42.5617" y2="26.9495" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE24A"/>
<stop offset="1" stop-color="#EFB274"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57608" x1="32.9517" y1="37.0308" x2="40.3759" y2="37.1501" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE24A"/>
<stop offset="1" stop-color="#EFB274"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57608" x1="18.5916" y1="20.1448" x2="29.5276" y2="20.4966" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE24A"/>
<stop offset="1" stop-color="#EFB274"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57608" x1="30.4006" y1="20.1448" x2="42.7832" y2="20.596" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE24A"/>
<stop offset="1" stop-color="#EFB274"/>
</linearGradient>
<linearGradient id="paint4_linear_3293_57608" x1="22.9923" y1="12.7865" x2="36.73" y2="13.2225" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE24A"/>
<stop offset="1" stop-color="#EFB274"/>
</linearGradient>
<linearGradient id="paint5_linear_3293_57608" x1="9.67815" y1="5.71296" x2="49.822" y2="6.35812" gradientUnits="userSpaceOnUse">
<stop stop-color="#FFE24A"/>
<stop offset="1" stop-color="#EFB274"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_CoalPotential;