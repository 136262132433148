const Icon_EnergySaved = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="48" height="48" rx="24" fill="#F2F8F6"/>
        <path d="M30.288 23.1792C29.6625 23.1792 29.1153 23.7264 29.1153 24.3518V29.1987C29.1153 31.6222 27.0827 33.6547 24.6593 33.6547C22.2358 33.6547 20.2033 31.6222 20.2033 29.1987V25.9153C21.4541 25.0554 24.0339 22.7883 24.0339 19.1922C24.0339 15.2052 20.8287 12.8599 19.8906 12.2345C19.3433 11.9218 18.7179 11.9218 18.2489 12.2345C17.3108 12.8599 14.1837 15.2834 14.1837 19.4267C14.1837 22.5537 16.7635 24.899 17.9362 25.8371V29.1987C17.9362 32.9511 20.985 36 24.7375 36C28.4899 36 31.5388 32.9511 31.5388 29.1987V24.3518C31.4606 23.7264 30.9134 23.1792 30.288 23.1792ZM16.4508 19.5049C16.4508 17.0033 18.0925 15.3616 19.0306 14.5798C19.9687 15.2834 21.6886 16.8469 21.6886 19.2704C21.6886 20.8339 20.985 22.0065 20.2033 22.8664V19.0358C20.2033 18.4104 19.656 17.8632 19.0306 17.8632C18.4052 17.8632 17.858 18.4104 17.858 19.0358V22.6319C17.1544 21.8502 16.4508 20.6775 16.4508 19.5049Z" fill="#1FA37C"/>
        <path d="M33.1804 20.7557H32.9459V19.0358C32.9459 18.4886 32.4769 18.0195 31.9296 18.0195C31.3824 18.0195 30.9133 18.4886 30.9133 19.0358V20.7557H29.5844V19.0358C29.5844 18.4886 29.1153 18.0195 28.5681 18.0195C28.0208 18.0195 27.5518 18.4886 27.5518 19.0358V20.7557H27.3954C27.0827 20.7557 26.77 20.9902 26.77 21.3811V24.5081C26.77 26.5407 28.4117 28.1042 30.3661 28.1042C32.3205 28.1042 33.9622 26.5407 33.8059 24.5081V21.3811C33.8059 21.0684 33.5713 20.7557 33.1804 20.7557Z" fill="#1FA37C"/>
        </svg>

     );
}
 
export default Icon_EnergySaved;