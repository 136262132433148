import React, { useEffect, useState } from "react";

function InputNormal(props) {
    const [focusInput, setFocusInput] = useState(false);
    const [detailInput, setDetailInput] = useState({
        Key: '',
        Placeholder: '',
        Require: false,
        ReadOnly: false,
    })
    const [inputValue, setInputValue] = useState('');
    const [alertInput, setAlertInput] = useState(false);
    //==============================
    useEffect(() => {
        const init = async () => {
            let detail = {
                ...detailInput,
                Key: props.keyName,
                Placeholder: props.placeholder,
                Require: props.require,
                ReadOnly: props.readonly ? true : false,
            }
            setDetailInput(detail);
        };
        init();
    }, [])
    useEffect(() => {
        setInputValue(props.value ? props.value : '');
    }, [props.value])
    useEffect(() => {
        setAlertInput(props.alert ? props.alert : false);
    }, [props.alert])
    //==============================
    async function onInputText(e) {
        const value = e.target.value
        setInputValue(value);
        try {
            props.onInputText(value);
        } catch { }
    }
    //==============================
    return (
        <div className={`w-full h-14 rounded px-2 py-1.5
        border border-solid 
        ${alertInput ? 'border-danger' : focusInput && !detailInput.ReadOnly ? 'border-vekin-green100' : 'border-quartenary'}
        `}>
            <div className="flex">
                <p className="text-secondary text-xs font-normal">
                    {detailInput.Key}
                </p>
                {detailInput.Require && <p className="text-secondary text-xs font-normal">&nbsp;*</p>}
            </div>
            <input
                className="w-full text-vekin-green100 text-sm focus:outline-0"
                type={`text`}
                placeholder={detailInput.Placeholder}
                onFocus={(e) => { setFocusInput(true) }}
                onBlur={(e) => { setFocusInput(false) }}
                value={inputValue}
                readOnly={detailInput.ReadOnly}
                onInput={(e) => { onInputText(e) }}
            />
        </div>
    )
}

export default InputNormal;