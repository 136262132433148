export const ClearSky_S = ({style}) => {
    return ( 
        <svg  className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_784_14131)">
<path d="M12.0056 19.9555C16.3087 19.9555 19.797 16.4992 19.797 12.2355C19.797 7.97191 16.3087 4.51555 12.0056 4.51555C7.70251 4.51555 4.21417 7.97191 4.21417 12.2355C4.21417 16.4992 7.70251 19.9555 12.0056 19.9555Z" fill="url(#paint0_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M4.54162 14.4511C4.62012 14.7111 4.87804 14.88 5.14941 14.8489C5.28398 14.8333 5.42303 14.8267 5.56208 14.8267C5.6361 14.8267 5.71011 14.8289 5.78412 14.8333C6.00167 14.8467 6.208 14.7356 6.31342 14.5467C6.98176 13.3644 8.25566 12.5644 9.7202 12.5644C11.7185 12.5644 13.367 14.0556 13.5935 15.9756C13.6271 16.26 13.8693 16.4733 14.1564 16.4733C15.1343 16.4733 15.991 16.9778 16.4822 17.7356C16.5741 17.8756 16.7222 17.9689 16.8904 17.9911C17.0586 18.0111 17.2268 17.9556 17.3501 17.84C18.8528 16.4333 19.7903 14.44 19.7903 12.2333C19.7903 7.97555 16.3005 4.51778 12.0034 4.51778C7.70618 4.51778 4.21417 7.97333 4.21417 12.2333C4.21417 13.0044 4.32855 13.7467 4.54162 14.4511Z" fill="url(#paint1_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.4337 1.5V3C11.4337 3.31111 11.6871 3.56222 12.0011 3.56222C12.3151 3.56222 12.5685 3.31111 12.5685 3V1.5C12.5685 1.18889 12.3151 0.937775 12.0011 0.937775C11.6871 0.937775 11.4337 1.18889 11.4337 1.5Z" fill="url(#paint2_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.8074 2.62666L16.0493 3.92666C15.8924 4.19555 15.9866 4.54 16.2579 4.69555C16.5293 4.85111 16.8769 4.75778 17.0339 4.49111L17.792 3.19111C17.949 2.92222 17.8548 2.57778 17.5834 2.42222C17.312 2.26666 16.9644 2.35555 16.8074 2.62666Z" fill="url(#paint3_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M20.8938 6.26222L19.5817 7.01333C19.3103 7.16889 19.2184 7.51333 19.3754 7.78222C19.5324 8.05111 19.88 8.14222 20.1514 7.98889L21.4634 7.23778C21.7348 7.08222 21.829 6.73777 21.672 6.46889C21.5128 6.2 21.1651 6.10889 20.8938 6.26222Z" fill="url(#paint4_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.5983 11.4378H21.0844C20.7704 11.4378 20.517 11.6889 20.517 12C20.517 12.3111 20.7704 12.5622 21.0844 12.5622H22.5983C22.9123 12.5622 23.1657 12.3111 23.1657 12C23.1657 11.6889 22.9123 11.4378 22.5983 11.4378Z" fill="url(#paint5_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M1.40397 12.5622H2.91785C3.23184 12.5622 3.48527 12.3111 3.48527 12C3.48527 11.6889 3.23184 11.4378 2.91785 11.4378H1.40397C1.08998 11.4378 0.836548 11.6889 0.836548 12C0.836548 12.3111 1.08998 12.5622 1.40397 12.5622Z" fill="url(#paint6_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.54108 7.23777L3.85311 7.98888C4.12449 8.14444 4.47212 8.0511 4.62911 7.78222C4.78611 7.51333 4.69191 7.16888 4.42278 7.01333L3.11075 6.26221C2.83937 6.10666 2.49174 6.19999 2.33475 6.46888C2.17775 6.73777 2.26746 7.08222 2.54108 7.23777Z" fill="url(#paint7_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.21025 3.18889L6.96831 4.48889C7.1253 4.75778 7.47293 4.84889 7.74431 4.69333C8.01569 4.53778 8.10764 4.19333 7.95289 3.92444L7.19483 2.62444C7.03783 2.35556 6.6902 2.26222 6.41883 2.41778C6.14745 2.57556 6.0555 2.92 6.21025 3.18889Z" fill="url(#paint8_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.4337 22.9556V21.4556C11.4337 21.1445 11.6871 20.8933 12.0011 20.8933C12.3151 20.8933 12.5685 21.1445 12.5685 21.4556V22.9556C12.5685 23.2667 12.3151 23.5178 12.0011 23.5178C11.6871 23.5178 11.4337 23.2667 11.4337 22.9556Z" fill="url(#paint9_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.8074 21.8289L16.0493 20.5289C15.8924 20.26 15.9866 19.9156 16.2579 19.76C16.5293 19.6044 16.8769 19.6978 17.0339 19.9644L17.792 21.2644C17.949 21.5333 17.8548 21.8778 17.5834 22.0333C17.312 22.1911 16.9644 22.1 16.8074 21.8289Z" fill="url(#paint10_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M20.8938 18.1933L19.5817 17.4422C19.3103 17.2867 19.2184 16.9422 19.3754 16.6733C19.5324 16.4044 19.88 16.3133 20.1514 16.4667L21.4634 17.2178C21.7348 17.3733 21.829 17.7178 21.672 17.9867C21.5128 18.2556 21.1651 18.3467 20.8938 18.1933Z" fill="url(#paint11_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M2.54107 17.2178L3.8531 16.4667C4.12448 16.3111 4.47211 16.4044 4.6291 16.6733C4.7861 16.9422 4.6919 17.2867 4.42276 17.4422L3.11074 18.1933C2.83936 18.3489 2.49173 18.2556 2.33474 17.9867C2.1755 17.7178 2.26745 17.3733 2.54107 17.2178Z" fill="url(#paint12_linear_784_14131)"/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.21025 21.2667L6.96831 19.9667C7.1253 19.6978 7.47293 19.6067 7.74431 19.7622C8.01569 19.9178 8.10764 20.2622 7.95289 20.5311L7.19483 21.8311C7.03783 22.1 6.6902 22.1933 6.41883 22.0378C6.14745 21.88 6.0555 21.5356 6.21025 21.2667Z" fill="url(#paint13_linear_784_14131)"/>
</g>
<defs>
<linearGradient id="paint0_linear_784_14131" x1="11.91" y1="12.6113" x2="7.72524" y2="29.5472" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#DBB500"/>
</linearGradient>
<linearGradient id="paint1_linear_784_14131" x1="12.3298" y1="10.9272" x2="9.04134" y2="24.2361" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#DBB500"/>
</linearGradient>
<linearGradient id="paint2_linear_784_14131" x1="12" y1="2.34435" x2="12" y2="8.62513" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint3_linear_784_14131" x1="17.0136" y1="3.37416" x2="14.3084" y2="8.78775" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint4_linear_784_14131" x1="20.6223" y1="7.06136" x2="16.5234" y2="9.7207" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint5_linear_784_14131" x1="22.0321" y1="12" x2="14.4437" y2="12" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint6_linear_784_14131" x1="1.96494" y1="12" x2="15.7846" y2="12" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint7_linear_784_14131" x1="3.37973" y1="7.06068" x2="10.9306" y2="11.9991" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint8_linear_784_14131" x1="7.06705" y1="3.53533" x2="10.7059" y2="9.4132" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint9_linear_784_14131" x1="12" y1="22.1109" x2="12" y2="15.8301" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint10_linear_784_14131" x1="17.0135" y1="21.0818" x2="14.3083" y2="15.6682" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint11_linear_784_14131" x1="20.6224" y1="17.3942" x2="16.5235" y2="14.7348" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint12_linear_784_14131" x1="3.37963" y1="17.3951" x2="10.9305" y2="12.4566" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<linearGradient id="paint13_linear_784_14131" x1="7.06676" y1="20.9202" x2="10.7057" y2="15.0423" gradientUnits="userSpaceOnUse">
<stop stopColor="#FCD935"/>
<stop offset="1" stopColor="#C19F00"/>
</linearGradient>
<clipPath id="clip0_784_14131">
<rect width="24" height="24" fill="white"/>
</clipPath>
</defs>
</svg>



     );
}

export const ClearSky_N= ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M22.9291 15.5889C22.4582 14.9378 21.6239 14.6 20.792 14.7178C20.5721 14.7444 20.3426 14.7822 20.1155 14.8289C15.9347 15.7444 12.6358 14.9644 10.5896 12.9711C8.53146 10.9667 7.77369 7.79111 8.56971 4.06222C8.56971 4.06222 8.83744 3.15333 7.89083 2.55999C6.96334 2.04888 5.1466 3.26444 4.71393 3.67777C2.25178 5.70888 0.697998 8.67333 0.697998 11.9755C0.697998 18.0955 6.04541 23.0644 12.6263 23.0644C16.3243 23.0644 19.6255 21.5 21.8151 19.0489C22.2526 18.66 23.7323 16.6711 22.9291 15.5889Z" fill="#6CCCDF"/>
<path fillRule="evenodd" clipRule="evenodd" d="M22.9291 15.5889C22.4582 14.9378 21.6239 14.6 20.792 14.7178C20.5721 14.7444 20.3426 14.7822 20.1155 14.8289C15.9347 15.7444 12.6358 14.9644 10.5896 12.9711C8.53146 10.9667 7.77369 7.79111 8.56971 4.06222C8.56971 4.06222 8.83744 3.15333 7.89083 2.55999C6.96334 2.04888 5.1466 3.26444 4.71393 3.67777C2.25178 5.70888 0.697998 8.67333 0.697998 11.9755C0.697998 18.0955 6.04541 23.0644 12.6263 23.0644C16.3243 23.0644 19.6255 21.5 21.8151 19.0489C22.2526 18.66 23.7323 16.6711 22.9291 15.5889Z" fill="url(#paint0_linear_784_14132)" fillOpacity="0.7"/>
<defs>
<linearGradient id="paint0_linear_784_14132" x1="2.3027" y1="0.717635" x2="23.2514" y2="1.41296" gradientUnits="userSpaceOnUse">
<stop stopColor="#0085FF" stopOpacity="0"/>
<stop offset="1" stopColor="#0085FF"/>
</linearGradient>
</defs>
</svg>


     );
}
 
