import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import { webStyle as theme } from '../../components/layout/Theme';

const CircularProgressStyles = styled(CircularProgress)`
  position: absolute;
  left: 47.75%;
  top: 40%;
  color: ${theme["Theme"]["main"]} ;
  @media only screen and (max-width: 425px) {
    position: absolute;
    left: 40%;
    top: 30%;
  }
`;

const StyledPageLoading = styled.div`
  display: ${(props) => (props.isLoading ? 'block' : 'none')};
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
`;

const PageLoading = ({ loading = false, progress = 0 }) => {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (loading) setIsLoading(true);
    else setIsLoading(false);
  }, [loading]);

  return (
    <StyledPageLoading isLoading={isLoading}>
      <CircularProgressStyles size={70} />
    </StyledPageLoading>
  );
};

export default PageLoading;
