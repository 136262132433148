import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { resetPassword, verifyResetPassToken } from '../../api/userInfo'
import bg_image from '../../assets/backgroundImages/company_bg.jpg'
import {WidgetLoading}  from '../../components/ui'

const ResetPassword = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const [OTP, setOtp] = useState(undefined)
  const [errorMsg, setErrorMsg] = useState(false)
  const [successMsg, setSuccessMsg] = useState(false)
  const [form, setForm] = useState({
    email: undefined,
    newPassword: undefined,
    otp: undefined,
    is_accepted_active_consent: undefined
  })
  const [isValidToken, setIsValidToken] = useState(false)

  let query_data = {}
  for (const item of search.split('?')) {
    if (item) {
      let [key, value] = item.split('=')
      query_data[key] = value
    }
  }

  // TODO fix verify api call

  useEffect(() => {
    if (query_data?.token) {
      verifyToken()
    }
  }, [])

  const verifyToken = async () => {
    try {
      const { data, detail } = await verifyResetPassToken(query_data?.token)
      if (data?.detail === 'This OTP is active.') {
        setIsValidToken(true)
      } else {
        navigate('/forget-password')
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleOnSubmit = async () => {
    if (form.newPassword === form.newPasswordConfirm) {
      try {
        const { data, detail } = await resetPassword({
          email: query_data.email,
          newPassword: form.newPassword,
          token: query_data.token
        })
        // console.log(data,"test data");
        // TODO : handle error
        if (data?.detail === 'Success.') {
          setSuccessMsg(data?.detail)
          setErrorMsg(false)
          navigate('/login')
        } else if (data?.detail === 'This OTP is expired.') {
          // console.log('68 test',data?.detail)
          navigate('/forget-password')
        } else {
          setErrorMsg(data?.detail)
          setSuccessMsg(false)
        }
      } catch (error) {}
    } else {
      setErrorMsg("Try again !!")
      // navigate('/forget-password')
    }
  }

  useEffect(() => {
    if(errorMsg){
      let error_msg = setTimeout(() => setErrorMsg(undefined), 700);
      return () => {
        clearTimeout(error_msg);
      };
    }
  }, [ errorMsg]);

  return (
    <div
      className="relative flex items-center justify-center w-full h-screen bg-no-repeat bg-cover bg-image"
      style={{ backgroundImage: `url(${bg_image})` }}
    >
      {!isValidToken && <WidgetLoading />}
      {isValidToken && (
        <section className="absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden">
          <div className="max-w-sm p-4 mx-auto text-center bg-white rounded h-50 md:max-w-lg">
            <h1 className="text-3xl font-bold text-center text-vekin">Reset password</h1>
            <form>
              <div className="flex flex-col justify-start gap-4 mx-8">
                <div className="mt-8">
                  <label className="mr-56 text-sm text-gray-500">New Password:</label>
                  <input
                    required
                    type="password"
                    name="newPassword"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        newPassword: e.target.value
                      })
                    }}
                    value={form.newPassword ?? ''}
                    className="rounded-md text-gray-700 py-2.5 border  border-gray-300 flex-1 block w-full pl-2 text-sm outline-none"
                    placeholder="xxxxxxxxxx"
                  />
                </div>
                <div className="my-2">
                  <label className="mr-40 text-sm text-gray-500">Confirm New Password:</label>
                  <input
                    required
                    type="password"
                    name="newPasswordConfirm"
                    onChange={(e) => {
                      setForm({
                        ...form,
                        newPasswordConfirm: e.target.value
                      })
                    }}
                    value={form?.newPasswordConfirm ?? ''}
                    className="rounded-md text-gray-700 py-2.5 border  border-gray-300 flex-1 block w-full pl-2 text-sm outline-none"
                    placeholder="xxxxxxxxxx"
                  />
                </div>
              </div>

              {successMsg && <span className="py-2 capitalize text-vekin">{successMsg}</span>}
              {errorMsg != '' && <span className="text-sm text-danger">{errorMsg}</span>}

              <div className="flex flex-row items-center justify-center gap-8 mt-8">
                <button
                  onClick={() => navigate('/login')}
                  className="inline-flex gap-2 px-4 py-1 mt-0 text-sm"
                >
                  <i className="ri-arrow-left-line"></i>
                  Back to Login
                </button>
                <button
                  onClick={handleOnSubmit}
                  type="button"
                  // onClick={() => navigate("/login")}
                  className="block w-1/3 py-2 text-white rounded bg-vekin focus:outline-none"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </section>
      )}
    </div>
  )
}

export default ResetPassword
