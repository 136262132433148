import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import ContextProvider from './context/';
import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import './styles/site_management.css'
import App from './App';
import 'remixicon/fonts/remixicon.css'
import './i18n';
import '../node_modules/react-grid-layout/css/styles.css'
import '../node_modules/react-resizable/css/styles.css'
import { AuthContextProvider } from './context/auth/auth_context';

// AuthContextProvider
const AppWithRouter = () => {
  return (
    <AuthContextProvider >
    {/* <ContextProvider> */}
      <BrowserRouter>
        <App />
      </BrowserRouter>
    {/* </ContextProvider> */}
    </AuthContextProvider>
  );
};

// <React.StrictMode>
{/* , */}
ReactDOM.render(
  <React.StrictMode>
    <AppWithRouter />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
