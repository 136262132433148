const Very_Poor_Icon_T = ({style}) => {
    return ( 
        <svg className={style} width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="url(#paint0_linear_1078_26683)"/>
<path d="M18.3796 20.8884C18.1812 20.8886 17.9896 20.8159 17.8412 20.6841C16.5061 19.5017 14.7849 18.8481 13.0015 18.846C11.2181 18.844 9.49538 19.4937 8.15751 20.6729C8.07758 20.7442 7.98437 20.7989 7.88323 20.834C7.78209 20.8691 7.67501 20.8838 7.56815 20.8774C7.46129 20.871 7.35675 20.8435 7.26054 20.7965C7.16433 20.7496 7.07835 20.6841 7.00753 20.6038C6.93671 20.5235 6.88246 20.43 6.84788 20.3287C6.8133 20.2274 6.79908 20.1203 6.80604 20.0134C6.813 19.9066 6.841 19.8022 6.88843 19.7062C6.93586 19.6103 7.00178 19.5246 7.08242 19.4542C8.71766 18.0127 10.8233 17.2185 13.0032 17.221C15.183 17.2235 17.2869 18.0225 18.9188 19.4677C19.0423 19.577 19.1295 19.7214 19.169 19.8815C19.2084 20.0416 19.1982 20.21 19.1397 20.3642C19.0812 20.5184 18.9771 20.6511 18.8414 20.7447C18.7056 20.8383 18.5446 20.8885 18.3797 20.8884H18.3796Z" fill="#2C4866"/>
<path d="M21.125 11.5781C21.125 10.2319 20.0337 9.14062 18.6875 9.14062C17.3413 9.14062 16.25 10.2319 16.25 11.5781V13.2031C16.25 14.5493 17.3413 15.6406 18.6875 15.6406C20.0337 15.6406 21.125 14.5493 21.125 13.2031V11.5781Z" fill="url(#paint1_linear_1078_26683)"/>
<path d="M19.2969 12.3906C19.8578 12.3906 20.3125 11.9359 20.3125 11.375C20.3125 10.8141 19.8578 10.3594 19.2969 10.3594C18.736 10.3594 18.2812 10.8141 18.2812 11.375C18.2812 11.9359 18.736 12.3906 19.2969 12.3906Z" fill="#FFEFDC"/>
<path d="M9.75 11.5781C9.75 10.2319 8.65869 9.14062 7.3125 9.14062C5.96631 9.14062 4.875 10.2319 4.875 11.5781V13.2031C4.875 14.5493 5.96631 15.6406 7.3125 15.6406C8.65869 15.6406 9.75 14.5493 9.75 13.2031V11.5781Z" fill="url(#paint2_linear_1078_26683)"/>
<path d="M7.92188 12.3906C8.48279 12.3906 8.9375 11.9359 8.9375 11.375C8.9375 10.8141 8.48279 10.3594 7.92188 10.3594C7.36096 10.3594 6.90625 10.8141 6.90625 11.375C6.90625 11.9359 7.36096 12.3906 7.92188 12.3906Z" fill="#FFEFDC"/>
<path d="M15.4383 9.14044C15.2606 9.14062 15.0877 9.08251 14.9461 8.975C14.8046 8.8675 14.7022 8.71655 14.6546 8.54527C14.6071 8.374 14.617 8.19186 14.6829 8.02678C14.7488 7.86169 14.867 7.72276 15.0194 7.63129L19.0819 5.19379C19.1734 5.13888 19.2748 5.10252 19.3803 5.0868C19.4858 5.07107 19.5934 5.07629 19.697 5.10215C19.8005 5.12801 19.8979 5.17401 19.9836 5.23752C20.0694 5.30103 20.1418 5.3808 20.1967 5.47229C20.2516 5.56377 20.288 5.66518 20.3037 5.77072C20.3194 5.87625 20.3142 5.98385 20.2883 6.08737C20.2625 6.19089 20.2165 6.2883 20.153 6.37405C20.0894 6.45979 20.0097 6.53218 19.9182 6.5871L15.8557 9.0246C15.7297 9.10045 15.5854 9.14051 15.4383 9.14044Z" fill="#2C4866"/>
<path d="M10.5617 9.14044C10.4147 9.14051 10.2704 9.10046 10.1444 9.0246L6.08186 6.5871C5.8971 6.4762 5.76396 6.29644 5.71173 6.08737C5.6595 5.87831 5.69246 5.65705 5.80336 5.47229C5.91427 5.28753 6.09402 5.15438 6.30309 5.10215C6.51216 5.04993 6.73341 5.08289 6.91817 5.19379L10.9807 7.63129C11.1331 7.72276 11.2513 7.86169 11.3172 8.02678C11.3831 8.19186 11.393 8.374 11.3454 8.54528C11.2979 8.71655 11.1955 8.86751 11.0539 8.97501C10.9124 9.08251 10.7395 9.14062 10.5617 9.14044Z" fill="#2C4866"/>
<defs>
<linearGradient id="paint0_linear_1078_26683" x1="13" y1="0" x2="13" y2="26" gradientUnits="userSpaceOnUse">
<stop stopColor="#FFD359"/>
<stop offset="0.0702" stopColor="#FFCD4F"/>
<stop offset="0.2689" stopColor="#FFC037"/>
<stop offset="0.4806" stopColor="#FFB726"/>
<stop offset="0.7119" stopColor="#FF7B1C"/>
<stop offset="1" stopColor="#FF5019"/>
</linearGradient>
<linearGradient id="paint1_linear_1078_26683" x1="16.5577" y1="10.2608" x2="20.8173" y2="14.5204" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
<linearGradient id="paint2_linear_1078_26683" x1="5.18268" y1="10.2608" x2="9.44232" y2="14.5204" gradientUnits="userSpaceOnUse">
<stop stopColor="#334E66"/>
<stop offset="1" stopColor="#142F47"/>
</linearGradient>
</defs>
</svg>




        


     );
}
 
const Very_Poor_Icon_F =({style}) =>{
    return (
        <svg className={style} width="19" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0C6.41775 0 4.87103 0.469191 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346616 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9976 5.87901 15.154 3.84558 13.6542 2.34582C12.1544 0.84605 10.121 0.00241846 8 0ZM8 15.0588C6.6039 15.0588 5.23915 14.6448 4.07833 13.8692C2.91751 13.0936 2.01277 11.9911 1.4785 10.7013C0.944236 9.41146 0.804447 7.99217 1.07681 6.62289C1.34918 5.25361 2.02147 3.99585 3.00866 3.00866C3.99586 2.02146 5.25362 1.34918 6.62289 1.07681C7.99217 0.804443 9.41147 0.944232 10.7013 1.4785C11.9911 2.01276 13.0936 2.91751 13.8692 4.07833C14.6448 5.23914 15.0588 6.60389 15.0588 8C15.0567 9.87147 14.3124 11.6657 12.989 12.989C11.6657 14.3124 9.87148 15.0567 8 15.0588Z" fill="current"/>
        <path d="M7.99939 9.64813C7.33819 9.64897 6.68456 9.78892 6.08099 10.0589C5.47741 10.3288 4.93739 10.7228 4.49601 11.2151C4.45435 11.261 4.42218 11.3147 4.40135 11.3732C4.38053 11.4316 4.37145 11.4935 4.37465 11.5555C4.37785 11.6174 4.39326 11.6781 4.41999 11.734C4.44672 11.79 4.48425 11.8401 4.53042 11.8815C4.57659 11.9229 4.63049 11.9548 4.68902 11.9753C4.74755 11.9958 4.80956 12.0046 4.87147 12.001C4.93339 11.9975 4.994 11.9817 5.04981 11.9547C5.10562 11.9276 5.15553 11.8898 5.19668 11.8434C5.54835 11.4499 5.97893 11.1349 6.46041 10.9188C6.94188 10.7027 7.46347 10.5905 7.99121 10.5893C8.51895 10.5882 9.04102 10.6981 9.52344 10.9121C10.0059 11.1261 10.4378 11.4392 10.7912 11.8312C10.8751 11.9231 10.992 11.9781 11.1163 11.984C11.2406 11.99 11.3622 11.9464 11.4545 11.8629C11.5468 11.7794 11.6023 11.6628 11.6088 11.5385C11.6152 11.4142 11.5722 11.2924 11.4891 11.1998C11.0478 10.7121 10.5094 10.3221 9.90836 10.0549C9.30736 9.78763 8.65711 9.64909 7.99939 9.64813Z" fill="current"/>
        <path d="M9.88232 6.58823V8C9.88232 8.12481 9.9319 8.2445 10.0202 8.33276C10.1084 8.42101 10.2281 8.47059 10.3529 8.47059C10.4777 8.47059 10.5974 8.42101 10.6857 8.33276C10.7739 8.2445 10.8235 8.12481 10.8235 8V6.58823C10.8235 6.46343 10.7739 6.34373 10.6857 6.25548C10.5974 6.16722 10.4777 6.11765 10.3529 6.11765C10.2281 6.11765 10.1084 6.16722 10.0202 6.25548C9.9319 6.34373 9.88232 6.46343 9.88232 6.58823Z" fill="current"/>
        <path d="M5.17651 6.58823V8C5.17651 8.12481 5.22609 8.2445 5.31435 8.33276C5.4026 8.42101 5.52229 8.47059 5.6471 8.47059C5.77191 8.47059 5.89161 8.42101 5.97986 8.33276C6.06811 8.2445 6.11769 8.12481 6.11769 8V6.58823C6.11769 6.46343 6.06811 6.34373 5.97986 6.25548C5.89161 6.16722 5.77191 6.11765 5.6471 6.11765C5.52229 6.11765 5.4026 6.16722 5.31435 6.25548C5.22609 6.34373 5.17651 6.46343 5.17651 6.58823Z" fill="current"/>
        <path d="M9.09935 5.76487C9.18184 5.76486 9.26286 5.74304 9.33418 5.7016L11.3719 4.52513C11.4256 4.49433 11.4727 4.45323 11.5104 4.4042C11.5482 4.35517 11.576 4.29916 11.5921 4.23939C11.6082 4.17961 11.6123 4.11725 11.6043 4.05587C11.5962 3.99449 11.5762 3.9353 11.5452 3.88169C11.5143 3.82809 11.473 3.78111 11.4239 3.74347C11.3748 3.70582 11.3187 3.67824 11.2588 3.66231C11.199 3.64637 11.1367 3.6424 11.0753 3.65062C11.0139 3.65883 10.9548 3.67907 10.9013 3.71018L8.8636 4.88665C8.77388 4.93849 8.70378 5.0185 8.66417 5.11424C8.62456 5.20999 8.61765 5.31614 8.64451 5.41621C8.67138 5.51629 8.73052 5.6047 8.81275 5.66774C8.89499 5.73078 8.99573 5.76492 9.09935 5.76487Z" fill="current"/>
        <path d="M4.62813 4.52513L6.66582 5.7016C6.71934 5.73271 6.77847 5.75295 6.83983 5.76116C6.90119 5.76938 6.96356 5.7654 7.02338 5.74947C7.0832 5.73354 7.13928 5.70596 7.18842 5.66831C7.23756 5.63066 7.2788 5.58369 7.30975 5.53008C7.34071 5.47648 7.36078 5.41729 7.36882 5.35591C7.37686 5.29453 7.37271 5.23216 7.35661 5.17239C7.34051 5.11262 7.31277 5.05661 7.27498 5.00758C7.23719 4.95854 7.19011 4.91745 7.13641 4.88664L5.09872 3.71017C4.99067 3.64819 4.86246 3.63158 4.74218 3.66398C4.62191 3.69638 4.51939 3.77515 4.4571 3.88302C4.39481 3.99089 4.37784 4.11906 4.4099 4.23943C4.44196 4.35979 4.52044 4.46253 4.62813 4.52513Z" fill="current"/>
        </svg>
        

        


    )
}

export {Very_Poor_Icon_T,Very_Poor_Icon_F}