import { SelectNormalIcons } from "../SetupWidget/SelectWidgetIcon";
import MultiSelectBtn from "./multiSelectBtn";
import { useGetDevice } from "../../../api/userInfo";
import { useEffect, useState } from "react";
import { useWidgetSetupStore } from "../../../context/stores";
import {shallow} from 'zustand/shallow'

export const ChillerWidgetSetup = ({updateWidgetTitle, clearForm, submitForm, updateSetupDetails, setupDetails}) => {
    const {deviceList, isLoadingDeviceList, isErrorDeviceList} = useGetDevice(localStorage.getItem('plantId'));
    const [multiSelect,setMultiSelect ]= useState({})
    const [selectedWidget, updateSelectedWidget] = useWidgetSetupStore((state) => [state.selectedWidget, state.updateSelectedWidget],shallow)
    

    useEffect(() => {
        //console.log(multiSelect)
        if(multiSelect?.data){
            
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                    ...setupDetails?.measurement?.[0],
                    [multiSelect?.field]: multiSelect?.data.length > 1 ? multiSelect?.data : multiSelect?.data[0] ?? multiSelect?.data ,
                    "measure":["_chiller_design_capacity", "_ac_active_power"], 
                    "field": "_ac_active_power",
                  }]
            });
        }
    }, [multiSelect]);

    //add condition for title
    //add condition for unit
    const onInput = (field) => (e) => {
        if(field === 'title'){
            updateWidgetTitle(e.target.value); 
        }else{
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                    ...setupDetails?.measurement?.[0],
                    [field]: e.target.value,
                    }]
            });
        }
        
    };
    
    return ( 
        <>
            <div className="flex flex-col gap-4 mt-4">
                <div className="grid grid-cols-8 gap-2">
                    <div  className="flex flex-col col-span-6 gap-2">
                            <label htmlFor="title" className="col-span-1 text-sm text-gray-500">Title</label>
                            <input onChange={onInput('title')} autoComplete="off" required type="text" name="title"  className="setupModal_input" placeholder="Title of widget"/>
                    </div> 

                    <div  className="flex flex-col col-span-2 gap-2">
                                <label htmlFor="unit" className="col-span-1 text-sm text-gray-500">Unit</label>
                                <input onChange={onInput('unit')} autoComplete="off" required type="text" name="unit"  className="setupModal_input" placeholder="kWh"/>
                        </div> 

                    
                </div>
                        
                    <div className="flex flex-col gap-2">
                        <label htmlFor="device" className="col-span-1 text-sm text-gray-500">Device ID (select one or more devices)</label>
                        <MultiSelectBtn setMultiSelect={setMultiSelect} 
                        isMulti={selectedWidget === 'Chiller Widget'}
                        data={deviceList?.devices} 
                        name="device" 
                        placeholder="select device" />

                    </div> 

                        {(selectedWidget === 'Chiller_1A Widget') &&
                        <div className='flex flex-col gap-1'>
                            <p className="col-span-1 text-sm text-gray-500">Chiller Type</p>

                            <div className='flex flex-row gap-4'>
                                <label htmlFor={`chiller_type`} className='inline-flex items-center gap-2 text-gray-500' >
                                    <input onChange={onInput('chiller_type')} value={'water-cooled'}  required type="radio" name={`chiller_type`} className="w-4 h-4 accent-vekin" placeholder="_ac_energy"/>  
                                    Water Cooled
                                </label>

                                <label htmlFor={`chiller_type`}  className='inline-flex items-center gap-2 text-gray-500' >
                                    <input onChange={onInput('chiller_type')} value={'air-cooled'}  required type="radio" name={`chiller_type`} className="w-4 h-4 accent-vekin" placeholder="_ac_energy"/>  
                                    Air Cooled
                                </label>
                            </div>   
                        </div>
                        }         
            </div>
                
            

                
                {/* ------------ submit button */}
                <div className='flex flex-row justify-between gap-4 mt-4'>
                <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
                    Cancel
                </button>

                
                    <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                        Submit
                    </button>  

                
                </div>
        </>
     );
}


 

