import { useEffect, useRef, useState } from "react";
import { useGetWidgetTemplates } from "../../api/widgets";
import { checkCustomImage, widgetList_2by2 } from "../../utils/widgetLibrary/widgetListLibrary";
import WidgetLoading from "../ui/WidgetLoading";
import { API_URL } from "../../shared/config";
import { addNewWidgets } from "../../api/dashboard";
import { useWidgetSetupStore } from "../../context/stores";
import {shallow} from 'zustand/shallow'

const WidgetLibrary = ({dashboardId, widgetLength, setIsUpdated, isCompany}) => {
    const {templates, isLoading, isError} = useGetWidgetTemplates(isCompany)
    const [selectedWidget, updateSelectedWidget] = useWidgetSetupStore((state) => [state.selectedWidget, state.updateSelectedWidget],shallow)

    const ref = useRef(null);

    const [selectedCategory, setSelectedCategory]= useState('All Categories')
    const [filteredList, setFilteredList]= useState({})
    const [categories, setCategories]= useState([])

    const [hasError, setHasError]= useState(false)


    // console.log(templates?.widget_list)
    useEffect(() => {
        let filtered = templates?.widget_list
        if(selectedCategory != "All Categories"){
            filtered = filtered?.filter(widget => widget?.type.includes(selectedCategory))
        }
        
        setFilteredList({
            'two_x_one':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([2,1])),
            'two_x_two':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([2,2])),
            'four_x_one':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,1])),
            'four_x_two':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,2])),
            'extraLarge':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,3])),
            'extraLarge2':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,4])),
            'extraLarge3':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,5])),
            'extraLarge4':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,6])),
            'extraLarge5':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,7])),
            'extraLarge6':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([4,8])),
            'six_x_four':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([6,4])),
            'six_x_six':filtered?.filter(widget => JSON.stringify(widget.size)==JSON.stringify([6,6])),

        })
        reset_checkBox()
        
    }, [selectedCategory, isLoading]);
    
    //get categories from the widget list
    useEffect(() => {
        setCategories(['All Categories',... new Set(templates?.widget_list.map(t => t.type).flat())])
    }, [templates, isLoading]);
    
    //close lib when user click outside
    // useEffect(() => {
    //     //user click outside
    //     function handleClickOutside(event) {
    //       if (ref.current && !ref.current.contains(event.target)) {
    //         reset_checkBox()
    //         closeLibrary()
    //       }
    //     }
    //     document.addEventListener("mousedown", handleClickOutside);
    //     return () => {document.removeEventListener("mousedown", handleClickOutside);};
    // }, [ref]);



    const onCheckWidget = (item) => (e) => {
        updateSelectedWidget(item?.name)
        // setSelectedWidget(item?.name)
    };



const reset_checkBox=()=>{
    let inputs = document.getElementsByTagName("input")
    for (var i = 0; i < inputs.length; ++i) {
        if(inputs[i].checked){
            inputs[i].checked = false;
        }
    }
}


let btn_primary = 'px-3 py-1 text-white bg-vekin hover:bg-vekin/80 rounded-r-2xl transition ease-in-out duration-400 text-xs'
let btn_secondary = 'px-3 py-1 border rounded-2xl text-primary border-tertiary/25 hover:border-vekin transition ease-in-out duration-300 text-xs'
    
    const widgetTypeList=[
        {
            'label': '1 x 2 Widgets', 
            'items': filteredList?.two_x_one, 
            'extraStyle2': 'w-[11rem] h-20',
            'rows':2
        },
        {
            'label': '2 x 2 Widgets', 
            'items': filteredList?.two_x_two, 
            'extraStyle2': 'w-[11rem] h-32',
            'rows':2
        },
        {
            'label': '6 x 4 Widgets', 
            'items': filteredList?.six_x_four, 
            'extraStyle2': 'w-[23rem] h-[7.5rem]',
        },
        {
            'label': '4 x 1 Widgets', 
            'items': filteredList?.four_x_one,
            'extraStyle2': 'w-[23rem] h-[5rem]'
        },
        {
            'label': '4 x 2 Widgets', 
            'items': filteredList?.four_x_two, 
            'extraStyle2': 'w-[23rem] h-[9rem]'
        },
        {
            'label': '4 x 3 Widgets', 
            'items': filteredList?.extraLarge, 
            'extraStyle2': 'w-[23rem] h-[13rem]'
        },
        {
            'label': '4 x 4 Widgets', 
            'items': filteredList?.extraLarge2, 
            'extraStyle2': 'w-[23rem] h-[15rem]'
        },
        {
            'label': '4 x 5 Widgets', 
            'items': filteredList?.extraLarge3, 
            'extraStyle2': 'w-[23rem] h-[22rem]'
        },
        {   
            'label': '4 x 6 Widgets',
            'items': filteredList?.extraLarge4,
            'extraStyle2': 'w-[23rem] h-[25rem]'
        },
        {   
            'label': '4 x 7 Widgets',
            'items': filteredList?.extraLarge5,
            'extraStyle2': 'w-[23rem] h-[25rem]'
        },
        {
            'label': '4 x 8 Widgets',
            'items': filteredList?.extraLarge6,
            'extraStyle2': 'w-[23rem] h-[15rem]'
        },
        {
            'label': '6 x 6 Widgets',
            'items': filteredList?.six_x_six,
            'extraStyle2': 'w-[23rem] h-[15rem]'
        },
    ]
    
    return ( 
        <section ref={ref}  className={'w-full h-full p-4'}>
            
            {isLoading? <WidgetLoading />:
                <div className="relative flex flex-row w-full h-full overflow-auto ">
                        <div className="fixed flex flex-col w-40 h-[79%] gap-2 px-4 pt-4 overflow-hidden bg-vekin-green-50 z-60">
                          <span className="text-sm font-semibold text-primary">Widget Library</span>
                          <hr></hr>

                          <div className="flex flex-col items-start justify-start gap-4 w-28">
                            {categories?.map(category =>(
                                <button key={category} onClick={()=>setSelectedCategory(category)} className={selectedCategory==category?`${btn_primary}`:'text-left text-primary  py-1 text-sm '}>{category}</button>
                            ))}
                          </div>
                        </div>
                        
                    <div   id="libraryForm" className=' ml-40 h-auto  px-4    right-0 w-[25rem] border-t'>

                        {hasError && <p className="text-sm text-danger">An error occurred, please try again</p>}
                    <div className="flex flex-col gap-2 divide-y">
                    {widgetTypeList.map(({label, items, extraStyle1, extraStyle2, rows}, index) => (

                        items?.length>0 &&
                        <div key={index}>
                        <span className='text-[10px] opacity-50 text-primary mb-2'>{label}</span>
                            <div className={`grid gap-4 mt-6 gap-y-8 grid-cols-1 ${rows==2?'xl:grid-cols-2':''}`}>
                            {items?.filter(n=> n.name !='Static Map Device Widget')?.map((widget,index) => {
                                let isChecked = selectedWidget==widget?.name
                                return (
                                
                                <label htmlFor={widget?.name} key={widget?.name+index} className={` ${extraStyle2} relative bg-contain bg-no-repeat bg-center border-2 rounded-lg ${isChecked?"border-vekin shadow-xl":""} `} style={{backgroundImage: `url(${checkCustomImage(widget?.name)})`}}>
                                        <div className={`absolute -top-[1.6rem] left-1 ${isChecked? "bg-vekin":""}`}>
                                        <input onChange={onCheckWidget(widget)} name="widget_library_items" id={widget.name} type="radio"  
                                        className={`w-4 h-4 rounded outline-none hidden ${isChecked? "accent-vekin":""}`}/>

                                        {/* vekin-green-40  */}
                                            
                                        </div>
                                        <span className="text-[12px] absolute overflow-hidden -top-6"> {widget?.name?.replace("Widget","")}</span>
                                </label>
                            )})}
                            </div>
                        </div>
                        ))}
                        
                    </div>

                           
                    </div>
                </div>
                 }           
                </section> 
     );
}
 
export default WidgetLibrary;