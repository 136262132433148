const Icon_Chill = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#EFF4FA"/>
<path d="M34.1825 24.5714H34.0811C32.8542 24.5714 31.749 23.9831 30.8669 23.1355C30.5526 22.8264 30.1977 22.6868 29.8226 22.6868C29.417 22.7167 29.052 22.8364 28.7681 23.1256L28.6869 23.2053C27.8454 24.0629 26.6793 24.5515 25.4626 24.5515H25.3207C24.1141 24.5515 23.0494 23.9632 22.1774 23.1555C21.9138 22.9062 21.5589 22.7666 21.1939 22.7666C21.0925 22.7666 21.0013 22.7766 20.91 22.7965C20.2611 22.9261 19.5919 22.637 19.1255 22.1683L18 21.0814C19.0038 20.0942 20.3523 19.6156 21.8834 19.8349C22.8568 19.9745 23.7288 20.4831 24.4385 21.1612C24.7123 21.4204 25.0672 21.56 25.4525 21.56C25.8479 21.56 26.2231 21.4104 26.4968 21.1213L26.5779 21.0415C27.4398 20.154 28.6464 19.6654 29.8935 19.6953H29.9848C31.1914 19.7252 32.2662 20.3136 33.1381 21.1312C33.4525 21.4304 33.7972 21.56 34.1622 21.57H34.1825C34.5678 21.57 34.9328 21.4204 35.2066 21.1512L35.6426 20.7224C37.3967 18.9973 40.2459 18.9973 42 20.7224L40.8948 21.8093C40.4182 22.278 39.749 22.5672 39.1001 22.4475C38.6439 22.3577 38.147 22.4874 37.7921 22.8364L37.3561 23.2652C36.5146 24.0928 35.3688 24.5615 34.1724 24.5615L34.1825 24.5714Z" fill="url(#paint0_linear_4787_77159)"/>
<path d="M34.1825 33.4286H34.0811C32.8542 33.4286 31.749 32.6426 30.8669 31.5103C30.5526 31.0973 30.1977 30.9108 29.8226 30.9108C29.417 30.9108 29.052 31.1106 28.7681 31.497L28.6869 31.6035C27.8352 32.7492 26.6692 33.4019 25.4525 33.4019H25.3308C24.1242 33.4019 23.0596 32.616 22.1774 31.5369C21.9138 31.2039 21.5691 31.0307 21.1939 31.0174C21.1027 31.0174 21.0114 31.0307 20.9202 31.0574C20.2712 31.2305 19.6122 30.8709 19.1458 30.2581L18 28.7528C18.8517 27.6338 19.9772 27.021 21.1838 27.021H21.2852C22.5019 27.021 23.5767 27.807 24.4689 28.8993C24.7427 29.2323 25.0875 29.4055 25.4525 29.4055C25.8479 29.4055 26.2231 29.1924 26.4968 28.8194L26.5779 28.7128C27.4398 27.5405 28.5856 26.8345 29.8327 26.9144C31.0697 26.9144 32.2155 27.5805 33.0672 28.7528C33.4018 29.2057 33.7668 29.4188 34.1622 29.4322C34.5678 29.4322 34.9328 29.2457 35.2066 28.8727L35.6426 28.2999C37.3967 25.9953 40.2459 25.9953 42 28.2999L40.8948 29.7519C40.4182 30.378 39.749 30.7643 39.1001 30.6044C38.6439 30.4845 38.147 30.6577 37.7921 31.124L37.3561 31.6968C36.5146 32.8025 35.3688 33.4286 34.1724 33.4286H34.1825Z" fill="url(#paint1_linear_4787_77159)"/>
<path d="M34.1792 40.5714H34.0778C32.8504 40.5714 31.7447 39.982 30.8622 39.1327C30.5478 38.823 30.1927 38.6832 29.8174 38.6832C29.4218 38.6832 29.0465 38.843 28.7726 39.1228L28.6915 39.2127C27.8394 40.0719 26.6729 40.5615 25.4556 40.5615H25.3238C24.1167 40.5615 23.0516 39.972 22.1691 39.1627C21.9053 38.9129 21.5604 38.7831 21.1851 38.7731H21.175C21.0735 38.7731 20.9822 38.7731 20.891 38.803C20.2418 38.9329 19.5824 38.6332 19.1158 38.1736L18 37.0746C18.8622 36.2254 19.9577 35.7858 21.2156 35.7758C22.4328 35.7758 23.5689 36.2653 24.4108 37.1246C24.6847 37.4043 25.06 37.5642 25.4556 37.5642C25.8512 37.5642 26.2265 37.4043 26.5004 37.1246L26.5816 37.0346C27.4438 36.1654 28.6002 35.6359 29.8276 35.6859C31.0651 35.6859 32.2113 36.1854 33.0634 37.0646C33.3981 37.4043 33.7633 37.5642 34.1589 37.5742C34.5647 37.5841 34.9298 37.4343 35.2037 37.1545L35.6399 36.7249C37.3948 34.9965 40.2451 34.9965 42 36.7249L40.8943 37.8139C40.4176 38.2835 39.7582 38.5733 39.0989 38.4534C38.6424 38.3634 38.1454 38.4933 37.7904 38.843L37.3542 39.2726C36.5123 40.1019 35.366 40.5714 34.1691 40.5714H34.1792Z" fill="url(#paint2_linear_4787_77159)"/>
<rect x="9.5" y="9.5" width="41" height="41" rx="20.5" stroke="#6CCCDF" stroke-width="3"/>
<rect x="9.5" y="9.5" width="41" height="41" rx="20.5" stroke="url(#paint3_linear_4787_77159)" stroke-opacity="0.7" stroke-width="3"/>
<defs>
<linearGradient id="paint0_linear_4787_77159" x1="24.4385" y1="15.4375" x2="42.6342" y2="33.9393" gradientUnits="userSpaceOnUse">
<stop stop-color="#83D3F6"/>
<stop offset="1" stop-color="#00AEFB"/>
</linearGradient>
<linearGradient id="paint1_linear_4787_77159" x1="20.9404" y1="16.6304" x2="44.3736" y2="34.4664" gradientUnits="userSpaceOnUse">
<stop stop-color="#83D3F6"/>
<stop offset="1" stop-color="#00AEFB"/>
</linearGradient>
<linearGradient id="paint2_linear_4787_77159" x1="17.432" y1="24.5259" x2="35.6635" y2="43.0261" gradientUnits="userSpaceOnUse">
<stop stop-color="#83D3F6"/>
<stop offset="1" stop-color="#00AEFB"/>
</linearGradient>
<linearGradient id="paint3_linear_4787_77159" x1="11.1429" y1="4.33338" x2="52.1784" y2="5.58399" gradientUnits="userSpaceOnUse">
<stop stop-color="#0085FF" stop-opacity="0"/>
<stop offset="1" stop-color="#0085FF"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_Chill;