import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../components/layout/Header";
import SelectSiteHeaderCompany from "../components/layout/SelectSiteHeaderCompany";
import { useCompanyReport } from "../api/company";

function AnalyticsPage(props) {
    const {data, isLoadingData, isErrorData} = useCompanyReport(localStorage.getItem('companyId'))
    const details = data?.report?.[0]

    return (

        <>
        <Header pageTitle={"Analytics"} > <SelectSiteHeaderCompany /></Header>
        <div className="w-full h-[90vh]">
        {data?.report?.[0] ?
        <iframe title={details?.name} className="w-full h-full" src={details?.url} frameborder="0" allowFullScreen="true"></iframe>
        :
        <div className="flex items-center justify-center w-full h-[60vh]">
            <p>This company Does not Have any Analytics at the moment</p>
        </div>
        }
        </div>
        </>
    )
}

export default AnalyticsPage;
