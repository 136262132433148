const Icon_RecPotential = ({style}) => {
    return (
        <svg className={style} width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="60" height="60" rx="30" fill="#F2F8F6"/>
<path d="M27.6488 25.4625C28.4051 25.3388 29.0376 25.0363 29.5326 24.5C29.8763 24.1288 30.0963 23.6888 30.2338 23.1938C30.9213 20.6088 32.5713 18.9863 35.1838 18.4088C38.6763 17.6388 42.2238 19.9625 42.8013 23.62C43.0488 25.1738 42.7326 26.6175 41.9076 27.9513C41.7976 28.1438 41.7151 28.185 41.5088 28.0338C40.6976 27.4425 39.7901 27.0575 38.8138 26.8513C38.7313 26.8375 38.6626 26.81 38.5801 26.7825C38.5801 26.6725 38.6626 26.6313 38.7176 26.5763C39.5151 25.655 39.7351 24.61 39.2813 23.4688C38.8276 22.3688 37.9751 21.7363 36.7926 21.6125C35.2388 21.4338 33.8913 22.5338 33.5613 23.8813C33.0388 26.0125 31.7326 27.4975 29.7113 28.35C29.5051 28.4325 29.4226 28.4325 29.3401 28.185C28.9963 27.25 28.6801 26.7413 27.6488 25.4625Z" fill="#79C8B0"/>
<path d="M27.6488 25.4625C28.4051 25.3388 29.0376 25.0363 29.5326 24.5C29.8763 24.1288 30.0963 23.6888 30.2338 23.1938C30.9213 20.6088 32.5713 18.9863 35.1838 18.4088C38.6763 17.6388 42.2238 19.9625 42.8013 23.62C43.0488 25.1738 42.7326 26.6175 41.9076 27.9513C41.7976 28.1438 41.7151 28.185 41.5088 28.0338C40.6976 27.4425 39.7901 27.0575 38.8138 26.8513C38.7313 26.8375 38.6626 26.81 38.5801 26.7825C38.5801 26.6725 38.6626 26.6313 38.7176 26.5763C39.5151 25.655 39.7351 24.61 39.2813 23.4688C38.8276 22.3688 37.9751 21.7363 36.7926 21.6125C35.2388 21.4338 33.8913 22.5338 33.5613 23.8813C33.0388 26.0125 31.7326 27.4975 29.7113 28.35C29.5051 28.4325 29.4226 28.4325 29.3401 28.185C28.9963 27.25 28.6801 26.7413 27.6488 25.4625Z" fill="url(#paint0_linear_3293_57615)"/>
<path d="M35.2525 31.6638C34.7438 32.1863 34.4138 32.7638 34.3175 33.465C34.235 34.0013 34.3038 34.5238 34.5238 35.0188C36.1738 38.8138 33.7538 43.1175 29.615 43.7638C26.0813 44.3138 22.74 41.8388 22.2863 38.305C22.2038 37.6588 22.2038 37.6588 22.85 37.6313C23.7575 37.59 24.6238 37.37 25.4488 37.0125C25.5038 36.985 25.5588 36.9575 25.6688 36.9163C25.655 37.26 25.6138 37.5763 25.655 37.8925C25.8475 39.3775 27.1263 40.4913 28.6525 40.4913C30.1925 40.4913 31.4713 39.35 31.6363 37.8513C31.705 37.2875 31.5813 36.765 31.375 36.2563C30.5775 34.2625 30.7838 32.3513 31.8838 30.5088C31.9938 30.3163 32.0763 30.275 32.2825 30.4263C33.135 31.045 34.0838 31.4438 35.115 31.6363C35.1288 31.6225 35.17 31.6363 35.2525 31.6638Z" fill="#79C8B0"/>
<path d="M35.2525 31.6638C34.7438 32.1863 34.4138 32.7638 34.3175 33.465C34.235 34.0013 34.3038 34.5238 34.5238 35.0188C36.1738 38.8138 33.7538 43.1175 29.615 43.7638C26.0813 44.3138 22.74 41.8388 22.2863 38.305C22.2038 37.6588 22.2038 37.6588 22.85 37.6313C23.7575 37.59 24.6238 37.37 25.4488 37.0125C25.5038 36.985 25.5588 36.9575 25.6688 36.9163C25.655 37.26 25.6138 37.5763 25.655 37.8925C25.8475 39.3775 27.1263 40.4913 28.6525 40.4913C30.1925 40.4913 31.4713 39.35 31.6363 37.8513C31.705 37.2875 31.5813 36.765 31.375 36.2563C30.5775 34.2625 30.7838 32.3513 31.8838 30.5088C31.9938 30.3163 32.0763 30.275 32.2825 30.4263C33.135 31.045 34.0838 31.4438 35.115 31.6363C35.1288 31.6225 35.17 31.6363 35.2525 31.6638Z" fill="url(#paint1_linear_3293_57615)"/>
<path d="M30.0412 21.255C29.7662 20.7325 29.4087 20.3062 28.9274 20.0037C27.5112 19.11 25.6274 19.5225 24.7337 20.9387C23.8537 22.3412 24.2937 24.1562 25.7512 25.05C27.4837 26.1225 28.5012 27.6625 28.8174 29.6837C28.9137 30.2887 28.8999 30.2887 28.3362 30.33C27.4012 30.3987 26.5074 30.5775 25.6549 30.9762C25.4624 31.0725 25.4212 31.0175 25.4487 30.825C25.5449 30.0687 25.3524 29.3812 24.9124 28.7487C24.6649 28.3912 24.3349 28.1162 23.9499 27.8825C22.2174 26.7962 21.1862 25.2425 20.9524 23.2212C20.5949 20.2237 22.4374 17.4187 25.3112 16.4975C27.8274 15.6862 30.5637 16.47 32.2687 18.505C32.3512 18.6012 32.4474 18.67 32.2824 18.78C31.3749 19.4125 30.6599 20.21 30.1237 21.1587C30.1099 21.1725 30.0962 21.2 30.0412 21.255Z" fill="#79C8B0"/>
<path d="M30.0412 21.255C29.7662 20.7325 29.4087 20.3062 28.9274 20.0037C27.5112 19.11 25.6274 19.5225 24.7337 20.9387C23.8537 22.3412 24.2937 24.1562 25.7512 25.05C27.4837 26.1225 28.5012 27.6625 28.8174 29.6837C28.9137 30.2887 28.8999 30.2887 28.3362 30.33C27.4012 30.3987 26.5074 30.5775 25.6549 30.9762C25.4624 31.0725 25.4212 31.0175 25.4487 30.825C25.5449 30.0687 25.3524 29.3812 24.9124 28.7487C24.6649 28.3912 24.3349 28.1162 23.9499 27.8825C22.2174 26.7962 21.1862 25.2425 20.9524 23.2212C20.5949 20.2237 22.4374 17.4187 25.3112 16.4975C27.8274 15.6862 30.5637 16.47 32.2687 18.505C32.3512 18.6012 32.4474 18.67 32.2824 18.78C31.3749 19.4125 30.6599 20.21 30.1237 21.1587C30.1099 21.1725 30.0962 21.2 30.0412 21.255Z" fill="url(#paint2_linear_3293_57615)"/>
<path d="M33.7125 25.8336C33.8775 26.0949 33.9875 26.3286 34.1662 26.5211C34.84 27.2774 35.6787 27.6349 36.6825 27.5661C38.8137 27.4286 40.6562 28.0749 42.0862 29.6836C45.2212 33.2036 43.585 38.8136 38.8 40.0099C37.7825 40.2574 36.765 40.2436 35.7475 40.0099C35.5275 39.9549 35.4312 39.9136 35.5275 39.6386C35.8025 38.7999 35.9262 37.9336 35.8437 37.0399C35.83 36.8611 35.8162 36.6961 35.8162 36.4899C36.16 36.5999 36.4487 36.7374 36.765 36.7924C38.4287 37.0674 40.0237 35.9536 40.2712 34.3174C40.5187 32.6399 39.3225 31.0999 37.6175 30.9074C37.3975 30.8799 37.1775 30.8936 36.9575 30.9074C34.7437 31.0449 32.9287 30.2474 31.485 28.5699C31.4025 28.4736 31.375 28.4186 31.4987 28.3361C32.42 27.6761 33.1487 26.8649 33.7125 25.8336Z" fill="#79C8B0"/>
<path d="M33.7125 25.8336C33.8775 26.0949 33.9875 26.3286 34.1662 26.5211C34.84 27.2774 35.6787 27.6349 36.6825 27.5661C38.8137 27.4286 40.6562 28.0749 42.0862 29.6836C45.2212 33.2036 43.585 38.8136 38.8 40.0099C37.7825 40.2574 36.765 40.2436 35.7475 40.0099C35.5275 39.9549 35.4312 39.9136 35.5275 39.6386C35.8025 38.7999 35.9262 37.9336 35.8437 37.0399C35.83 36.8611 35.8162 36.6961 35.8162 36.4899C36.16 36.5999 36.4487 36.7374 36.765 36.7924C38.4287 37.0674 40.0237 35.9536 40.2712 34.3174C40.5187 32.6399 39.3225 31.0999 37.6175 30.9074C37.3975 30.8799 37.1775 30.8936 36.9575 30.9074C34.7437 31.0449 32.9287 30.2474 31.485 28.5699C31.4025 28.4736 31.375 28.4186 31.4987 28.3361C32.42 27.6761 33.1487 26.8649 33.7125 25.8336Z" fill="url(#paint3_linear_3293_57615)"/>
<path d="M30.11 34.8812C29.725 34.7162 29.3675 34.5787 28.9825 34.5375C28.1163 34.4412 27.3463 34.675 26.6863 35.225C22.9325 38.415 17.24 36.5175 16.1813 31.705C15.535 28.7762 17.3225 25.5725 20.1275 24.5825C20.265 24.5275 20.3613 24.5137 20.4163 24.6925C20.7463 25.7237 21.2963 26.6175 22.0388 27.415C22.0525 27.4287 22.0663 27.4562 22.0938 27.4975C21.86 27.5662 21.6263 27.6075 21.4063 27.7037C20.1963 28.185 19.5088 29.0925 19.4538 30.385C19.3988 31.595 19.9625 32.5437 21.0488 33.1075C22.1763 33.6987 23.3038 33.6025 24.3075 32.8187C25.1325 32.1725 26.0125 31.6362 27.03 31.375C28.0888 31.1137 29.1338 31.1137 30.1925 31.3612C30.3988 31.4162 30.4813 31.4712 30.3988 31.7187C30.11 32.6262 29.9725 33.5612 30.0825 34.51C30.0963 34.62 30.11 34.73 30.11 34.8812Z" fill="#79C8B0"/>
<path d="M30.11 34.8812C29.725 34.7162 29.3675 34.5787 28.9825 34.5375C28.1163 34.4412 27.3463 34.675 26.6863 35.225C22.9325 38.415 17.24 36.5175 16.1813 31.705C15.535 28.7762 17.3225 25.5725 20.1275 24.5825C20.265 24.5275 20.3613 24.5137 20.4163 24.6925C20.7463 25.7237 21.2963 26.6175 22.0388 27.415C22.0525 27.4287 22.0663 27.4562 22.0938 27.4975C21.86 27.5662 21.6263 27.6075 21.4063 27.7037C20.1963 28.185 19.5088 29.0925 19.4538 30.385C19.3988 31.595 19.9625 32.5437 21.0488 33.1075C22.1763 33.6987 23.3038 33.6025 24.3075 32.8187C25.1325 32.1725 26.0125 31.6362 27.03 31.375C28.0888 31.1137 29.1338 31.1137 30.1925 31.3612C30.3988 31.4162 30.4813 31.4712 30.3988 31.7187C30.11 32.6262 29.9725 33.5612 30.0825 34.51C30.0963 34.62 30.11 34.73 30.11 34.8812Z" fill="url(#paint4_linear_3293_57615)"/>
<path d="M28.6525 35.3487C29.835 35.3487 30.8112 36.3249 30.8112 37.4937C30.8112 38.6899 29.835 39.6524 28.625 39.6524C27.4287 39.6524 26.425 38.6624 26.4387 37.4937C26.4662 36.2837 27.4425 35.3349 28.6525 35.3487Z" fill="#79C8B0"/>
<path d="M28.6525 35.3487C29.835 35.3487 30.8112 36.3249 30.8112 37.4937C30.8112 38.6899 29.835 39.6524 28.625 39.6524C27.4287 39.6524 26.425 38.6624 26.4387 37.4937C26.4662 36.2837 27.4425 35.3349 28.6525 35.3487Z" fill="url(#paint5_linear_3293_57615)"/>
<path d="M34.3037 24.7062C34.3037 23.5099 35.28 22.5612 36.49 22.5612C37.6862 22.5612 38.6762 23.5649 38.6625 24.7337C38.6487 25.9299 37.6725 26.8649 36.4487 26.8649C35.2525 26.8512 34.29 25.8887 34.3037 24.7062Z" fill="#79C8B0"/>
<path d="M34.3037 24.7062C34.3037 23.5099 35.28 22.5612 36.49 22.5612C37.6862 22.5612 38.6762 23.5649 38.6625 24.7337C38.6487 25.9299 37.6725 26.8649 36.4487 26.8649C35.2525 26.8512 34.29 25.8887 34.3037 24.7062Z" fill="url(#paint6_linear_3293_57615)"/>
<path d="M22.4375 32.6125C21.2413 32.6125 20.265 31.6363 20.265 30.4675C20.265 29.2713 21.2413 28.3225 22.465 28.3225C23.675 28.3225 24.6375 29.2988 24.6238 30.495C24.6238 31.6913 23.6475 32.6263 22.4375 32.6125Z" fill="#79C8B0"/>
<path d="M22.4375 32.6125C21.2413 32.6125 20.265 31.6363 20.265 30.4675C20.265 29.2713 21.2413 28.3225 22.465 28.3225C23.675 28.3225 24.6375 29.2988 24.6238 30.495C24.6238 31.6913 23.6475 32.6263 22.4375 32.6125Z" fill="url(#paint7_linear_3293_57615)"/>
<path d="M27.3187 20.4712C28.5287 20.4849 29.4912 21.4474 29.4775 22.6162C29.4637 23.8124 28.4737 24.7749 27.2775 24.7612C26.0675 24.7474 25.0912 23.7574 25.1187 22.5749C25.1462 21.3924 26.1225 20.4437 27.3187 20.4712Z" fill="#79C8B0"/>
<path d="M27.3187 20.4712C28.5287 20.4849 29.4912 21.4474 29.4775 22.6162C29.4637 23.8124 28.4737 24.7749 27.2775 24.7612C26.0675 24.7474 25.0912 23.7574 25.1187 22.5749C25.1462 21.3924 26.1225 20.4437 27.3187 20.4712Z" fill="url(#paint8_linear_3293_57615)"/>
<path d="M37.2875 36.0087C36.0775 36.0087 35.115 35.0462 35.115 33.85C35.115 32.6537 36.105 31.705 37.3012 31.705C38.4975 31.7187 39.46 32.6675 39.46 33.85C39.4737 35.0462 38.4975 36.0087 37.2875 36.0087Z" fill="#79C8B0"/>
<path d="M37.2875 36.0087C36.0775 36.0087 35.115 35.0462 35.115 33.85C35.115 32.6537 36.105 31.705 37.3012 31.705C38.4975 31.7187 39.46 32.6675 39.46 33.85C39.4737 35.0462 38.4975 36.0087 37.2875 36.0087Z" fill="url(#paint9_linear_3293_57615)"/>
<path d="M29.8762 50.8175C18.395 50.8175 9.05872 41.4812 9.05872 30C9.05872 18.5187 18.395 9.1825 29.8762 9.1825C41.3575 9.1825 50.68 18.5187 50.68 30C50.68 41.4812 41.3437 50.8175 29.8762 50.8175ZM29.8762 11.6575C19.7562 11.6575 11.5337 19.8937 11.5337 30C11.5337 40.1062 19.7562 48.3425 29.8762 48.3425C39.9825 48.3425 48.2187 40.12 48.2187 30C48.2187 19.88 39.9825 11.6575 29.8762 11.6575Z" fill="#79C8B0"/>
<path d="M29.8762 50.8175C18.395 50.8175 9.05872 41.4812 9.05872 30C9.05872 18.5187 18.395 9.1825 29.8762 9.1825C41.3575 9.1825 50.68 18.5187 50.68 30C50.68 41.4812 41.3437 50.8175 29.8762 50.8175ZM29.8762 11.6575C19.7562 11.6575 11.5337 19.8937 11.5337 30C11.5337 40.1062 19.7562 48.3425 29.8762 48.3425C39.9825 48.3425 48.2187 40.12 48.2187 30C48.2187 19.88 39.9825 11.6575 29.8762 11.6575Z" fill="url(#paint10_linear_3293_57615)"/>
<defs>
<linearGradient id="paint0_linear_3293_57615" x1="27.8302" y1="22.4856" x2="43.1389" y2="22.7203" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_3293_57615" x1="22.4033" y1="35.9619" x2="35.4724" y2="36.0903" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_3293_57615" x1="21.0447" y1="22.3657" x2="32.5669" y2="22.4565" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint3_linear_3293_57615" x1="31.5606" y1="31.8156" x2="43.902" y2="31.9233" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint4_linear_3293_57615" x1="16.2203" y1="29.6517" x2="30.6729" y2="29.8248" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint5_linear_3293_57615" x1="26.4907" y1="37.1418" x2="30.8851" y2="37.1874" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint6_linear_3293_57615" x1="34.3555" y1="24.3544" x2="38.7363" y2="24.3997" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint7_linear_3293_57615" x1="20.3169" y1="30.1101" x2="24.6976" y2="30.1555" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint8_linear_3293_57615" x1="25.1701" y1="22.2584" x2="29.5513" y2="22.3038" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint9_linear_3293_57615" x1="35.1667" y1="33.4982" x2="39.5336" y2="33.5432" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint10_linear_3293_57615" x1="9.55421" y1="26.5304" x2="51.3835" y2="26.9571" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

     );
}
 
export default Icon_RecPotential;