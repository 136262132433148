const Turbine_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.7499 10.55C12.5299 10.87 12.2799 11.25 11.9999 11.66C12.3099 11.86 12.5299 12.19 12.5299 12.58C12.5299 13.19 12.0399 13.68 11.4299 13.68C11.1899 13.68 10.9699 13.59 10.7899 13.45C10.5699 13.78 10.3499 14.1 10.1499 14.4C14.8699 17.58 20.1899 20.05 20.8899 18.83C21.5999 17.59 17.7799 13.93 12.7499 10.55Z" fill={stroke}/>
<path d="M12.7499 10.55C12.5299 10.87 12.2799 11.25 11.9999 11.66C12.3099 11.86 12.5299 12.19 12.5299 12.58C12.5299 13.19 12.0399 13.68 11.4299 13.68C11.1899 13.68 10.9699 13.59 10.7899 13.45C10.5699 13.78 10.3499 14.1 10.1499 14.4C14.8699 17.58 20.1899 20.05 20.8899 18.83C21.5999 17.59 17.7799 13.93 12.7499 10.55Z" fill={stroke} fill-opacity="0.7"/>
<path d="M11.5599 1.10006C10.1299 1.18006 9.14993 6.38006 9.04993 12.4401C9.42993 12.4501 9.86993 12.4501 10.3499 12.4601C10.4099 11.9001 10.8599 11.4701 11.4299 11.4701C12.0099 11.4701 12.4799 11.9301 12.5199 12.5001C12.9299 12.5101 13.3299 12.5101 13.6899 12.5201C13.7899 6.83006 12.9599 1.02006 11.5599 1.10006Z" fill={stroke}/>
<path d="M11.5599 1.10006C10.1299 1.18006 9.14993 6.38006 9.04993 12.4401C9.42993 12.4501 9.86993 12.4501 10.3499 12.4601C10.4099 11.9001 10.8599 11.4701 11.4299 11.4701C12.0099 11.4701 12.4799 11.9301 12.5199 12.5001C12.9299 12.5101 13.3299 12.5101 13.6899 12.5201C13.7899 6.83006 12.9599 1.02006 11.5599 1.10006Z" fill={stroke} fill-opacity="0.7"/>
<path d="M12.07 13.4501C11.89 13.5801 11.67 13.6801 11.43 13.6801C10.82 13.6801 10.33 13.1901 10.33 12.5801C10.33 12.2101 10.53 11.8901 10.81 11.6901C10.53 11.2901 10.25 10.9101 9.99995 10.5601C5.37995 13.8801 1.19995 18.0001 2.09995 19.0801C3.01995 20.1801 7.77995 17.8701 12.71 14.3401C12.52 14.0801 12.3 13.7801 12.07 13.4501Z" fill={stroke}/>
<path d="M12.07 13.4501C11.89 13.5801 11.67 13.6801 11.43 13.6801C10.82 13.6801 10.33 13.1901 10.33 12.5801C10.33 12.2101 10.53 11.8901 10.81 11.6901C10.53 11.2901 10.25 10.9101 9.99995 10.5601C5.37995 13.8801 1.19995 18.0001 2.09995 19.0801C3.01995 20.1801 7.77995 17.8701 12.71 14.3401C12.52 14.0801 12.3 13.7801 12.07 13.4501Z" fill={stroke} fill-opacity="0.7"/>
<path d="M11.4299 10.2001C10.1099 10.2001 9.04993 11.2701 9.04993 12.5801C9.04993 13.8901 10.1199 14.9601 11.4299 14.9601C12.7399 14.9601 13.8099 13.8901 13.8099 12.5801C13.8099 11.2701 12.7499 10.2001 11.4299 10.2001ZM11.4299 13.6801C10.8199 13.6801 10.3299 13.1901 10.3299 12.5801C10.3299 11.9701 10.8199 11.4801 11.4299 11.4801C12.0399 11.4801 12.5299 11.9701 12.5299 12.5801C12.5299 13.1901 12.0399 13.6801 11.4299 13.6801Z" fill={stroke}/>
<path d="M11.4299 10.2001C10.1099 10.2001 9.04993 11.2701 9.04993 12.5801C9.04993 13.8901 10.1199 14.9601 11.4299 14.9601C12.7399 14.9601 13.8099 13.8901 13.8099 12.5801C13.8099 11.2701 12.7499 10.2001 11.4299 10.2001ZM11.4299 13.6801C10.8199 13.6801 10.3299 13.1901 10.3299 12.5801C10.3299 11.9701 10.8199 11.4801 11.4299 11.4801C12.0399 11.4801 12.5299 11.9701 12.5299 12.5801C12.5299 13.1901 12.0399 13.6801 11.4299 13.6801Z" fill={stroke} fill-opacity="0.7"/>
<path d="M12.51 16.84L11.43 16L10.35 16.84C10.35 16.84 9.33997 28.79 9.33997 31.79C10.26 31.79 12.25 31.79 13.51 31.79C13.52 28.3 12.51 16.84 12.51 16.84Z" fill={stroke}/>
<path d="M12.51 16.84L11.43 16L10.35 16.84C10.35 16.84 9.33997 28.79 9.33997 31.79C10.26 31.79 12.25 31.79 13.51 31.79C13.52 28.3 12.51 16.84 12.51 16.84Z" fill={stroke} fill-opacity="0.7"/>
<path d="M23.94 23.02L23.14 22.53L22.34 23.02C22.34 23.02 21.59 30.03 21.59 31.79C22.27 31.79 23.75 31.79 24.68 31.79C24.69 29.74 23.94 23.02 23.94 23.02Z" fill={stroke}/>
<path d="M23.94 23.02L23.14 22.53L22.34 23.02C22.34 23.02 21.59 30.03 21.59 31.79C22.27 31.79 23.75 31.79 24.68 31.79C24.69 29.74 23.94 23.02 23.94 23.02Z" fill={stroke} fill-opacity="0.7"/>
<path d="M23.9201 19.0699C23.7901 19.2699 23.6301 19.4999 23.4601 19.7499C23.6501 19.8699 23.7801 20.0699 23.7801 20.3099C23.7801 20.6799 23.4801 20.9799 23.1101 20.9799C22.9601 20.9799 22.8301 20.9199 22.7201 20.8399C22.5801 21.0399 22.4501 21.2399 22.3301 21.4199C25.2101 23.3599 28.4601 24.8699 28.8901 24.1299C29.3301 23.3699 26.9901 21.1299 23.9201 19.0699Z" fill={stroke}/>
<path d="M23.9201 19.0699C23.7901 19.2699 23.6301 19.4999 23.4601 19.7499C23.6501 19.8699 23.7801 20.0699 23.7801 20.3099C23.7801 20.6799 23.4801 20.9799 23.1101 20.9799C22.9601 20.9799 22.8301 20.9199 22.7201 20.8399C22.5801 21.0399 22.4501 21.2399 22.3301 21.4199C25.2101 23.3599 28.4601 24.8699 28.8901 24.1299C29.3301 23.3699 26.9901 21.1299 23.9201 19.0699Z" fill={stroke} fill-opacity="0.7"/>
<path d="M23.2 13.3C22.33 13.35 21.73 16.53 21.67 20.22C21.9 20.22 22.17 20.23 22.46 20.23C22.5 19.89 22.77 19.62 23.12 19.62C23.48 19.62 23.76 19.9 23.78 20.25C24.03 20.25 24.28 20.26 24.5 20.26C24.56 16.8 24.05 13.25 23.2 13.3Z" fill={stroke}/>
<path d="M23.2 13.3C22.33 13.35 21.73 16.53 21.67 20.22C21.9 20.22 22.17 20.23 22.46 20.23C22.5 19.89 22.77 19.62 23.12 19.62C23.48 19.62 23.76 19.9 23.78 20.25C24.03 20.25 24.28 20.26 24.5 20.26C24.56 16.8 24.05 13.25 23.2 13.3Z" fill={stroke} fill-opacity="0.7"/>
<path d="M23.51 20.84C23.4 20.92 23.27 20.98 23.12 20.98C22.75 20.98 22.45 20.68 22.45 20.31C22.45 20.08 22.57 19.89 22.74 19.77C22.57 19.53 22.4 19.29 22.25 19.08C19.42 21.1 16.88 23.62 17.43 24.28C17.99 24.95 20.9 23.54 23.91 21.39C23.78 21.22 23.65 21.04 23.51 20.84Z" fill={stroke}/>
<path d="M23.51 20.84C23.4 20.92 23.27 20.98 23.12 20.98C22.75 20.98 22.45 20.68 22.45 20.31C22.45 20.08 22.57 19.89 22.74 19.77C22.57 19.53 22.4 19.29 22.25 19.08C19.42 21.1 16.88 23.62 17.43 24.28C17.99 24.95 20.9 23.54 23.91 21.39C23.78 21.22 23.65 21.04 23.51 20.84Z" fill={stroke} fill-opacity="0.7"/>
<path d="M23.12 18.85C22.32 18.85 21.67 19.5 21.67 20.3C21.67 21.1 22.32 21.75 23.12 21.75C23.92 21.75 24.57 21.1 24.57 20.3C24.57 19.5 23.92 18.85 23.12 18.85ZM23.12 20.98C22.75 20.98 22.45 20.68 22.45 20.31C22.45 19.94 22.75 19.64 23.12 19.64C23.49 19.64 23.79 19.94 23.79 20.31C23.79 20.68 23.49 20.98 23.12 20.98Z" fill={stroke}/>
<path d="M23.12 18.85C22.32 18.85 21.67 19.5 21.67 20.3C21.67 21.1 22.32 21.75 23.12 21.75C23.92 21.75 24.57 21.1 24.57 20.3C24.57 19.5 23.92 18.85 23.12 18.85ZM23.12 20.98C22.75 20.98 22.45 20.68 22.45 20.31C22.45 19.94 22.75 19.64 23.12 19.64C23.49 19.64 23.79 19.94 23.79 20.31C23.79 20.68 23.49 20.98 23.12 20.98Z" fill={stroke} fill-opacity="0.7"/>

</svg>

    )
}

export default Turbine_Icon;