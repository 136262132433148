const Icon_NoName = ({style}) => {
    return (
        <svg className={style} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="48" height="48" rx="24" fill="#F2F8F6"/>
<path d="M27.9152 13.0368C27.8379 12.5729 27.4513 12.2637 27.0647 12.1863C26.91 12.1863 23.1215 11.3359 20.2608 13.2688C19.1783 13.9646 18.5598 15.0471 18.2505 16.1295C18.0959 15.8976 17.8639 15.7429 17.632 15.5883C15.3898 14.042 12.1425 14.7378 12.1425 14.7378C12.1425 14.7378 11.3693 18.2944 13.6115 20.4593C15.2352 22.0056 17.4774 21.9283 18.6371 21.6963C18.7144 21.6963 19.7969 22.0829 21.2659 22.0829C22.7349 22.0829 24.5905 21.6963 25.9822 20.2273C28.843 17.4439 27.9152 13.1915 27.9152 13.0368ZM24.4359 18.6037C23.3535 19.6861 21.8071 19.8407 20.7247 19.6861L23.2761 17.3666C23.4308 17.212 23.4308 16.98 23.2761 16.8254C23.1215 16.6707 22.8896 16.6707 22.7349 16.8254L20.1061 19.2222C20.1061 17.8305 20.3381 15.9749 21.5752 15.1244C22.9669 14.1966 24.7452 14.1966 25.7503 14.2739C25.8276 15.3563 25.7503 17.2893 24.4359 18.6037Z" fill="#528ACC"/>
<path d="M35.9561 34.3763L33.6366 24.789C33.5593 24.4024 33.25 24.0932 32.7861 23.9385C32.3995 23.8612 31.9356 23.9385 31.6264 24.2478L29.7708 26.1807L29.3842 24.8663C29.2295 24.4798 28.9203 24.2478 28.5337 24.0932C28.1471 24.0158 27.7605 24.0932 27.4512 24.4024L25.0544 26.7219L24.2812 22.3149C24.0493 21.0778 22.8895 20.15 21.5751 20.15H17.9412C16.5495 20.15 15.3898 21.0778 15.2351 22.3149L13.2249 33.6032C13.1476 34.2217 13.3022 34.7629 13.6888 35.2268C14.1527 35.6907 14.7712 36 15.4671 36H24.0493C24.7451 36 25.3637 35.6907 25.8276 35.2268C26.2142 34.7629 26.3688 34.1444 26.2915 33.6032L25.5956 29.3507L27.8378 27.2632L28.3017 28.6549C28.4564 29.0415 28.7656 29.3507 29.1522 29.428C29.5388 29.5054 30.0027 29.428 30.2347 29.1188L31.9356 27.3405L33.4047 33.5259H28.3017C27.6832 33.5259 27.142 34.0671 27.142 34.6856C27.142 35.3041 27.6832 35.8454 28.3017 35.8454H34.8737C35.2603 35.8454 35.5695 35.6907 35.8015 35.3815C35.9561 35.0722 36.0334 34.6856 35.9561 34.3763ZM15.5444 33.8351L16.7042 27.1859H19.9515C20.4927 27.1859 20.8793 26.7993 20.8793 26.258C20.8793 25.7941 20.4927 25.3302 19.9515 25.3302H17.0134L17.4773 22.5468C17.4773 22.3922 17.7093 22.3149 17.9412 22.3149H21.6525C21.8844 22.3149 22.1164 22.4695 22.1164 22.5468L24.0493 33.6805L15.5444 33.8351Z" fill="#528ACC"/>
<path d="M30.7759 31.7476C31.0079 31.7476 31.1625 31.5929 31.1625 31.361C31.1625 31.129 31.0079 30.9744 30.7759 30.9744H27.6832C27.4513 30.9744 27.2966 31.129 27.2966 31.361C27.2966 31.5929 27.4513 31.7476 27.6832 31.7476H30.7759Z" fill="#528ACC"/>
</svg>

    )
}

export default Icon_NoName;