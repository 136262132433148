const Bulb_3d_Icon = ({style}) => {
    return (
        <svg className={style} width="500" height="500" viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.08" d="M250 494.2C282.033 494.2 308 479.067 308 460.4C308 441.733 282.033 426.6 250 426.6C217.967 426.6 192 441.733 192 460.4C192 479.067 217.967 494.2 250 494.2Z" fill="black"/>
<path d="M290.3 473.3C267.7 486.2 232.3 486.2 211.4 473.3C200.1 466.9 195.3 458.8 195.3 449.1C195.3 441 195.3 434.6 195.3 434.6H308C308 434.6 308 442.7 308 450.7C306.4 458.8 301.5 468.4 290.3 473.3Z" fill="url(#paint0_linear_5914_87595)"/>
<path d="M306.4 460.4C275.8 478.1 224.2 478.1 193.6 460.4C177.5 450.7 169.4 439.5 171 426.6C171 415.3 171 410.5 171 410.5L330.5 408.9C330.5 408.9 330.5 415.3 330.5 426.6C328.9 439.4 322.5 450.7 306.4 460.4Z" fill="url(#paint1_linear_5914_87595)"/>
<path d="M250 457.1C293.575 457.1 328.9 436.192 328.9 410.4C328.9 384.608 293.575 363.7 250 363.7C206.425 363.7 171.1 384.608 171.1 410.4C171.1 436.192 206.425 457.1 250 457.1Z" fill="url(#paint2_linear_5914_87595)"/>
<path d="M175.9 383.1V408.9C177.5 418.6 184 428.2 196.8 436.3C225.8 452.4 272.5 452.4 301.5 436.3C314.4 428.2 322.4 418.6 322.4 408.9V383.1H175.9Z" fill="url(#paint3_linear_5914_87595)"/>
<path d="M306.4 423.3C275.8 441 224.2 441 193.6 423.3C177.5 413.6 169.4 402.4 171 389.5C171 378.2 171 376.6 171 376.6L330.5 375C330.5 375 330.5 378.2 330.5 389.5C328.9 404 322.5 415.3 306.4 423.3Z" fill="url(#paint4_linear_5914_87595)"/>
<path d="M250 421.7C293.575 421.7 328.9 400.792 328.9 375C328.9 349.208 293.575 328.3 250 328.3C206.425 328.3 171.1 349.208 171.1 375C171.1 400.792 206.425 421.7 250 421.7Z" fill="url(#paint5_linear_5914_87595)"/>
<g opacity="0.3">
<path d="M246.8 6.10005C166.3 7.70005 100.2 73.8 100.2 154.3C100.2 189.7 111.5 222 130.8 247.7C159.8 284.7 177.5 323.4 184 368.5L185.6 374.9C188.8 381.3 193.7 387.8 203.3 392.6C229.1 407.1 271 407.1 296.7 392.6C304.8 387.8 311.2 381.3 314.4 374.9L316 370.1C324.1 323.4 341.8 284.7 370.8 247.7C390.1 221.9 401.4 191.3 401.4 157.5C399.8 72.2 330.5 4.50005 246.8 6.10005Z" fill="url(#paint6_radial_5914_87595)"/>
</g>
<path opacity="0.3" d="M250 308.9C333.616 308.9 401.4 241.116 401.4 157.5C401.4 73.8842 333.616 6.1001 250 6.1001C166.384 6.1001 98.6001 73.8842 98.6001 157.5C98.6001 241.116 166.384 308.9 250 308.9Z" fill="url(#paint7_radial_5914_87595)"/>
<path d="M188.1 129.6C205 129.6 218.7 115.9 218.7 99.0001C218.7 82.1002 205 68.4001 188.1 68.4001C171.2 68.4001 157.5 82.1002 157.5 99.0001C157.5 115.9 171.2 129.6 188.1 129.6Z" fill="white"/>
<path d="M287 381.4C266.1 392.7 233.8 392.7 214.5 381.4C193.6 370.1 193.6 352.4 214.5 341.1C235.4 329.8 267.7 329.8 287 341.1C306.4 350.8 306.4 370.2 287 381.4Z" fill="url(#paint8_radial_5914_87595)"/>
<path d="M264.5 365.3C256.4 370.1 243.6 370.1 235.5 365.3C230.7 363.7 229.1 360.5 229.1 357.2C229.1 354 229.1 333 229.1 333H271C271 333 271 355.6 271 357.2C270.9 358.9 269.3 362.1 264.5 365.3Z" fill="#CCCCCC"/>
<path d="M264.5 339.6C256.4 344.4 243.6 344.4 235.5 339.6C227.4 334.8 227.4 328.3 235.5 323.5C243.6 318.7 256.4 318.7 264.5 323.5C272.6 328.3 272.6 334.7 264.5 339.6Z" fill="white"/>
<path d="M243.6 336.3C242 336.3 242 334.7 242 334.7V304.1C242 302.5 243.6 302.5 243.6 302.5C245.2 302.5 245.2 304.1 245.2 304.1V333.1C246.8 334.7 245.2 336.3 243.6 336.3Z" fill="url(#paint9_radial_5914_87595)"/>
<path d="M256.4 329.9C254.8 329.9 254.8 328.3 254.8 328.3V299.3C254.8 297.7 256.4 297.7 256.4 297.7C258 297.7 258 299.3 258 299.3V328.3C259.7 328.3 258.1 329.9 256.4 329.9Z" fill="url(#paint10_radial_5914_87595)"/>
<path d="M264.5 305.7C256.4 310.5 243.6 310.5 235.5 305.7C230.7 304.1 229.1 300.9 229.1 297.6C229.1 294.4 229.1 273.4 229.1 273.4H271C271 273.4 271 296 271 297.6C271 299.2 269.3 302.5 264.5 305.7Z" fill="#CCCCCC"/>
<path d="M264.5 280C256.4 284.8 243.6 284.8 235.5 280C227.4 275.2 227.4 268.7 235.5 263.9C243.6 259.1 256.4 259.1 264.5 263.9C272.6 268.7 272.6 275.1 264.5 280Z" fill="white"/>
<path d="M250 222C238.7 222 229.1 231.7 229.1 242.9V271.9H271V242.9C270.9 231.6 261.3 222 250 222Z" fill="white"/>
<path d="M250 217.1C246.8 217.1 243.6 220.3 243.6 223.5V233.2H256.5V223.5C256.4 220.4 253.2 217.1 250 217.1Z" fill="white"/>
<path d="M243.6 276.7C242 276.7 242 275.1 242 275.1V257.4C242 252.6 245.1 246.3 251.6 244.1C258 241.9 259.7 247.7 259.7 250.9V268.6C259.7 270.2 258.1 270.2 258.1 270.2C256.5 270.2 256.5 268.6 256.5 268.6V252.3C256.5 250.7 256.1 247.4 252 249C248 250.6 245.8 255.8 245.8 259V276.7C241 276.7 243.6 276.7 243.6 276.7Z" fill="url(#paint11_radial_5914_87595)"/>
<defs>
<linearGradient id="paint0_linear_5914_87595" x1="308.144" y1="503.523" x2="224.476" y2="419.856" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint1_linear_5914_87595" x1="170.867" y1="441.249" x2="330.542" y2="441.249" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</linearGradient>
<linearGradient id="paint2_linear_5914_87595" x1="170.283" y1="410.442" x2="329.662" y2="410.442" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint3_linear_5914_87595" x1="330.28" y1="481.388" x2="246.613" y2="397.719" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<linearGradient id="paint4_linear_5914_87595" x1="170.867" y1="405.81" x2="330.542" y2="405.81" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</linearGradient>
<linearGradient id="paint5_linear_5914_87595" x1="170.283" y1="375.003" x2="329.662" y2="375.003" gradientUnits="userSpaceOnUse">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</linearGradient>
<radialGradient id="paint6_radial_5914_87595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(186.675 106.517) scale(260.898 260.898)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
<radialGradient id="paint7_radial_5914_87595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(190.61 106.517) scale(466.349)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
<radialGradient id="paint8_radial_5914_87595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(250.33 360.742) scale(41.8689 41.8689)">
<stop stop-color="#009377"/>
<stop offset="1" stop-color="#3AD296"/>
</radialGradient>
<radialGradient id="paint9_radial_5914_87595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(243.89 319.429) scale(12.0386)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
<radialGradient id="paint10_radial_5914_87595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(256.777 313.791) scale(11.473 11.473)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
<radialGradient id="paint11_radial_5914_87595" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(250.806 260.197) scale(13.271 13.271)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
</defs>
</svg>
)}
export default Bulb_3d_Icon;