import { SelectNormalIcons } from "../SetupWidget/SelectWidgetIcon";
import MultiSelectBtn from "./multiSelectBtn";
import { useGetDevice } from "../../../api/userInfo";
import { useEffect, useState } from "react";
/*
setup fields for single field widget (don't have multiple fields)
- Total Value Widget
- Current Status Widget
- Performance Device Listed
*/

export const SingleFieldSetup = ({clearForm, submitForm, updateWidgetIcon, widgetIcon, updateSetupDetails, setupDetails, selectedWidget}) => {
    const {deviceList, isLoadingDeviceList, isErrorDeviceList} = useGetDevice(localStorage.getItem('plantId'));
    const [multiSelect,setMultiSelect ]= useState({})
    
  

    useEffect(() => {
        if(multiSelect?.data){
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                    ...setupDetails.measurement?.[0],
                    [multiSelect?.field]: multiSelect?.data.length > 1 ? multiSelect?.data : multiSelect?.data[0],
                  }]
            });
        }
    }, [multiSelect]);

    
    const onInput = (field) => (e) => {
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                  ...setupDetails.measurement?.[0],
                  [field]: e.target.value
                }]
              });
    };
    
    // external_id - brand - model - name
    return ( 
        <>
            <div className="flex flex-col gap-4 mt-4">
                    <div className="flex flex-col gap-2">
                        <label htmlFor="device" className="col-span-1 text-sm text-gray-500">Device ID (select one or more devices)</label>
                        {/* <input onChange={onInput('device')} autoComplete="off" required type="text" name="device"  className="setupModal_input" placeholder={'1122'}/> */}
                        <MultiSelectBtn setMultiSelect={setMultiSelect} data={deviceList?.devices} name="device" placeholder="select device" />

                    </div> 
                
                    <div className={`grid ${(selectedWidget == 'Performance Widget' ||selectedWidget==='Gas Widget')  ? "xl:grid-cols-8 ":"xl:grid-cols-10"} gap-2 grid-cols-1`}>
                    {selectedWidget !== 'Performance Widget' && 
                        <div  className="flex flex-col col-span-4 gap-2">
                            <label htmlFor="title" className="col-span-1 text-sm text-gray-500">Title</label>
                            <input onChange={onInput('title')} autoComplete="off" required type="text" name="title"  className="setupModal_input" placeholder="Title of widget"/>
                        </div> }

                        

                        {selectedWidget !== 'Gas Widget' &&
                        <div  className={`flex flex-col gap-2 ${selectedWidget == 'Performance Widget' ? "col-span-4 ":"col-span-2 "}`}>
                            <label htmlFor="unit" className="col-span-1 text-sm text-gray-500">Unit</label>
                            <input onChange={onInput('unit')} autoComplete="off" required type="text" name="unit"  className="setupModal_input" placeholder="kWh"/>
                        </div>
                        }
                        
                        <div  className="flex flex-col col-span-4 gap-2">
                            <label htmlFor="field" className="col-span-1 text-sm text-gray-500">Field</label>
                            <input onChange={onInput('field')} autoComplete="off" required type="text" name="field"  className="setupModal_input" placeholder="_ac_energy"/>
                        </div>

                    </div>
            </div>
                
                {selectedWidget !== 'Performance Widget' &&  selectedWidget !== 'Gas Widget' &&
                    <SelectNormalIcons updateSetupDetails={updateSetupDetails} setupDetails={setupDetails}/> 
                }

                
                {/* ------------ submit button */}
                <div className='flex flex-row justify-between gap-4 mt-4'>
                <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
                    Cancel
                </button>

                
                    <button type="button" onClick={()=> submitForm()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                        Submit
                    </button>  

                
                </div>
        </>
     );
}


 

