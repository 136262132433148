import React from "react";

function ButtonFuncBottom(props) {
    function onClick() {
        try {
            props.onClick();
        } catch { }
    }
    return (
        <div className="h-full px-9 flex items-center rounded-lg cursor-pointer hover:bg-quartenary duration-300 ease-in-out"
            onClick={(e) => { onClick() }}>
            <img className="w-4 h-4 mr-2" src={props.image} />
            <p className={`text-darkGreen text-xs font-semibold`}>
                {props.text}
            </p>
        </div>
    )
}

export default ButtonFuncBottom;