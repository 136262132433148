import React, { useEffect, useRef, useState } from "react";
import BtnEdit from "./btn-edit";
import CoreAPI from '../../../shared/services/core';
import BtnCancel from "./btn-cancel";
import BtnSave from "./btn-save";
import imgWarning from "../../../assets/siteManagement/images/warning-2.svg"
import imgArrow from "../../../assets/siteManagement/images/arrow-3.svg"
import imgEdit from "../../../assets/siteManagement/images/edit-2.svg"
import imgClose from "../../../assets/siteManagement/images/close-2.svg"
import imgCheck from "../../../assets/siteManagement/images/check.svg"
import imgDelete from "../../../assets/siteManagement/images/delete.svg"
import imgRefresh from "../../../assets/siteManagement/images/refresh.svg"

import ItemCard from "./building-info-item-card";
import ItemCardNew from "./building-info-item-card-new";
import PopupModalConfirmDelete from "../popup-modal/confirm-delete";
import PopupModalConfirmSave from "../popup-modal/confirm-save";
import PopupModalError from "../popup-modal/error";
import InputImage from "../input-image";
import { addFloor,editFloor, deleteBuilding, deleteFloor, deleteImageByBuildingId, editBuilding, getFloorByBuildingId, getImageByBuildingId, uploadImageFileByBuildingId } from "../../../api/site";

function FloorContent(props) {
    const [siteId, setSiteId] = useState(null);
    const [parentId, setParentId] = useState(null);
    const [parentDetail, setParentDetail] = useState(null);
    const [imageDetail, setImageDetail] = useState(null);
    const [disable, setDisable] = useState(true);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditParent, setIsEditParent] = useState(false);
    const [focusInputParent, setFocusInputParent] = useState(false);
    const [inputValueParent, setInputValueParent] = useState('');
    //==============================
    const [isOpenPopupConFirmSave, setIsOpenPopupConFirmSave] = useState(false);
    const [isOpenPopupConFirmDelete, setIsOpenPopupConFirmDelete] = useState(false);
    const [isOpenPopupError, setIsOpenPopupError] = useState(false);
    //==============================
    const [labelError, setLabelError] = useState('');
    const [isSaveItem, setIsSaveItem] = useState(true);
    const [isDeleteParent, setIsDeleteParent] = useState(true);
    //==============================
    const [isClearValueItemCardNew, setIsClearValueItemCardNew] = useState(false);
    const [listItemInfo, setListItemInfo] = useState([]);
    const listItemInfoDefaultRef = useRef([]);
    const dragItem = useRef(null);
    const dragOverItem = useRef(null);
    //==============================
    const inputImageRef = useRef(null);
    //==============================
    useEffect(() => {
        setDisable(props.disable ? props.disable : false)
    }, [props.disable])
    useEffect(() => {
        if (props.siteId) {
            setSiteId(props.siteId);
        }
    }, [props.siteId])
    useEffect(() => {
        if (props.buildingId) {
            setParentId(props.buildingId);
        }
    }, [props.buildingId])
    useEffect(() => {
        if (parentId) {
            onGetItemInfo(parentId);
        }
    }, [parentId])
    //==============================
    function randomString(length) {
        var text = "";
        var possible =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
    async function onGetItemInfo(param) {
        listItemInfoDefaultRef.current = [];
        //==============================
        let ImageId = null;
        let ImagePath = '';
        // const responseImage = await CoreAPI.apiCoreService.GetImageByBuildingId(param);
        const responseImage = await getImageByBuildingId(param);
        if (responseImage.status) {
            let dataResponse = responseImage.data;
            //==============================
            const detailImage = await onSetImageFromAlbum(dataResponse.album);
            ImageId = detailImage?.id;
            ImagePath = detailImage?.photo;
        }
        //==============================
        let listX = [];
        // const response = await CoreAPI.apiCoreService.GetFloorByBuildingId(param);
        const response = await getFloorByBuildingId(param);
        if (response.status) {
            let dataResponse = response.data;
            const parent = {
                Id: dataResponse.id,
                Name: dataResponse.name,
                Sequence: dataResponse.sequence,
                Image: ImagePath,
                ImageId: ImageId,
            }
            setParentDetail(parent)
            setInputValueParent(parent.Name);
            for (let item of dataResponse?.floor) {
                let listSubArea = [];
                if (Array.isArray(item.place)) {
                    listSubArea = item.place;
                }
                const detail = {
                    Id: item.floor_id,
                    Name: item.floor_name,
                    SubDetail: `${listSubArea.length} places`,
                    Sequence: item.floor_sequence,
                }
                listX.push(detail);
            }
            listX.sort((a, b) => a.Sequence > b.Sequence ? 1 : -1);
        }
        listItemInfoDefaultRef.current = listX.slice();
        setListItemInfo(listX);
    }
    function onSortFromDrag() {
        let listX = listItemInfo.slice();
        const dragItemX = listX.splice(dragItem.current, 1)[0];
        listX.splice(dragOverItem.current, 0, dragItemX);
        dragItem.current = null;
        dragOverItem.current = null;
        setListItemInfo(listX);
    }
    async function onConfirmSaveFloor() {
        setIsOpenPopupConFirmSave(false);
        //==============================
        for (let item of listItemInfoDefaultRef.current) {
            let model = listItemInfo.find(x => x.Id === item.Id);
            if (!model) {
                // const response = await CoreAPI.apiCoreService.DeleteFloor(item.Id);
                const response = await deleteFloor(item.Id);
            }
        }
        //==============================
        for (let [index, item] of listItemInfo.entries()) {
            if (item.NewItem) {
                const body = {
                    "name": item.Name,
                    "building": parentId,
                    "sequence": index,
                }
                // const response = await CoreAPI.apiCoreService.AddFloor(body);
                const response = await addFloor(body);
            } else {
                let model = listItemInfoDefaultRef.current.find(x =>
                    x.Id === item.Id
                    && x.Name === item.Name
                    && x.Sequence === index
                )
                if (!model) {
                    const body = {
                        "name": item.Name,
                        "building": parentId,
                        "sequence": index,
                    }
                    // const response = await CoreAPI.apiCoreService.EditFloor(item.Id, body);
                    const response = await editFloor(item.Id, body);
                }
            }
        }
        await onGetItemInfo(parentId);
        setIsEdit(false);
    }
    async function onConfirmSaveImage() {
        let parent = {
            ...parentDetail,
            Image: imageDetail.Path,
            ImageFile: imageDetail.File,
        }
        if (parent.ImageFile && parent.ImageId) {
            const bodyDeleteImage = {
                "photo_id": parent.ImageId,
            }
            // const responseDeleteImage = await CoreAPI.apiCoreService.DeleteImageBuildingById(parent.Id, bodyDeleteImage);
            const responseDeleteImage = await deleteImageByBuildingId(parent.Id, bodyDeleteImage);
        }
        if (parent.ImageFile) {
            // const responseImage = await CoreAPI.apiCoreService.UploadImageFileByBuildingId(parent.Id, parent.ImageFile);
            const responseImage = await uploadImageFileByBuildingId(parent.Id, parent.ImageFile, parentDetail.Name);
            if (responseImage.status) {
                const dataResponse = responseImage.data;
                const detailImage = await onSetImageFromAlbum(dataResponse.album);
                parent.ImageId = detailImage?.id;
                parent.ImageFile = null;
            }
        }
        setParentDetail(parent)
    }
    async function onSetImageFromAlbum(param) {
        let returnImage;
        let albumImage;
        try {
            albumImage = JSON.parse(param)
        } catch { }
        if (Array.isArray(albumImage) && albumImage.length > 0) {
            let listImage = albumImage.slice();
            returnImage = listImage[listImage.length - 1];
        }
        return returnImage;
    }
    //==============================
    function onDragStartItem(param) {
        dragItem.current = param
    }
    function onDragEnterItem(param) {
        dragOverItem.current = param
    }
    function onDragOverItem(param) {
        param.preventDefault();
    }
    //==============================
    function onClickEdit() {
        setIsEdit(true);
    }
    function onClickCancel() {
        setListItemInfo([])
        setTimeout(() => {
            let listX = listItemInfoDefaultRef.current.slice();
            setListItemInfo(listX)
        }, 1);
        setIsEdit(false);
    }
    async function onClickSave() {
        setIsSaveItem(true);
        setIsOpenPopupConFirmSave(true);
    }
    async function onConfirmSave() {
        if (isSaveItem) {
            await onConfirmSaveFloor();
        } else {
            await onConfirmSaveImage();
        }
        setIsOpenPopupConFirmSave(false);
    }
    async function onCancelSave() {
        setIsOpenPopupConFirmSave(false);
    }
    async function onConfirmDelete() {
        setIsOpenPopupConFirmDelete(false);
        if (isDeleteParent) {
            // const response = await CoreAPI.apiCoreService.DeleteBuilding(parentDetail.Id);
            const response = await deleteBuilding(parentDetail.Id);
            if (response.status) {
                onClickBack();
            } else {
                setLabelError(response.data);
                setIsOpenPopupError(true);
            }
        } else {
            if (parentDetail.ImageId) {
                const bodyDeleteImage = {
                    "photo_id": parentDetail.ImageId,
                }
                // const responseDeleteImage = await CoreAPI.apiCoreService.DeleteImageBuildingById(parentDetail.Id, bodyDeleteImage);
                const responseDeleteImage = await deleteImageByBuildingId(parentDetail.Id, bodyDeleteImage);

            }
            const parent = {
                ...parentDetail,
                Image: null,
                ImageId: null,
                ImageFile: null,
            }
            setParentDetail(parent)
        }
    }
    function onClickItemCard(param) {
        try {
            props.onSelectItem(param)
        } catch { }
    }
    function onDeleteItemCard(param) {
        let listX = listItemInfo.slice();
        listX = listX.filter(x => x.Id !== param.Id);
        setListItemInfo(listX);
    }
    function onInputTextItemCard(param, text) {
        let listX = listItemInfo.slice();
        let model = listX.find(x => x.Id === param.Id);
        if (model) {
            model.Name = text
        }
        setListItemInfo(listX);
    }
    function onClickAddNewItemCard(param) {
        let listX = listItemInfo.slice();
        const detail = {
            Id: randomString(10),
            Name: param,
            SubDetail: ``,
            Sequence: ``,
            NewItem: true,
        }
        listX.push(detail);
        setListItemInfo(listX);
        //==============================
        setIsClearValueItemCardNew(true)
        setTimeout(() => {
            setIsClearValueItemCardNew(false)
        }, 1);
    }
    function onClickBack() {
        try {
            props.onClickBack();
        } catch { }
    }
    function onClickEditParent() {
        setInputValueParent(parentDetail.Name);
        setIsEditParent(true)
    }
    async function onClickSaveChangeParent() {
        const body = {
            "name": inputValueParent,
            "plant": siteId,
            "sequence": parentDetail.Sequence,
        }
        // const response = await CoreAPI.apiCoreService.EditBuilding(parentDetail.Id, body);
        const response = await editBuilding(parentDetail.Id, body);
        if (response.status) {
            const parent = {
                ...parentDetail,
                Name: inputValueParent,
            }
            setParentDetail(parent)
        }
        setIsEditParent(false)
    }
    function onClickDeleteParent() {
        setIsDeleteParent(true);
        setIsOpenPopupConFirmDelete(true);
    }
    function onSetImageParent(param) {
        setIsSaveItem(false);
        setImageDetail(param);
        setIsOpenPopupConFirmSave(true);
    }
    function onChangeImageParent() {
        inputImageRef.current.click();
    }
    async function onHandleOpenFile(param) {
        let url = '';

        let reader = new FileReader();
        reader.onload = function (e) {
            url = e.target.result;
        };

        reader.readAsDataURL(param.target.files[0]);

        setTimeout(() => {
            // if (param.target.files[0].size < props.imageSize
            //     || !props.imageSize) 

            let detailImage = {
                Path: url,
                File: param.target.files[0],
            }

            onSetImageParent(detailImage)

            param.target.value = null;
        }, 500);
    }
    function onClickDeleteImage() {
        if (parentDetail.Image) {
            setIsDeleteParent(false);
            setIsOpenPopupConFirmDelete(true);
        }
    }
    //==============================
    return (
        <div className="w-full h-full">
            {isOpenPopupConFirmSave &&
                <PopupModalConfirmSave
                    isOpen={isOpenPopupConFirmSave}
                    labelMain={`${isSaveItem ? 'Confirm save floor?' : 'Confirm change?'}`}
                    labelSub={`${isSaveItem ? '' : ''}`}
                    onClickConfirm={(e) => { onConfirmSave() }}
                    onClickClose={(e) => { onCancelSave() }}
                />
            }
            {isOpenPopupConFirmDelete &&
                <PopupModalConfirmDelete
                    isOpen={isOpenPopupConFirmDelete}
                    labelMain={`${isDeleteParent ? 'Delete building?' : 'Delete image?'}`}
                    onClickConfirm={(e) => { onConfirmDelete() }}
                    onClickClose={(e) => { setIsOpenPopupConFirmDelete(false) }}
                />
            }
            {isOpenPopupError &&
                <PopupModalError
                    isOpen={isOpenPopupError}
                    text={labelError}
                    onClickClose={(e) => { setIsOpenPopupError(false) }}
                />
            }

            <div className="w-full h-[40px] mb-2 flex items-center">
                <img className="w-2.5 h-2.5 rotate-180 mr-4 hover:opacity-70 duration-300 ease-in-out cursor-pointer"
                    src={imgArrow}
                    onClick={(e) => { onClickBack() }} />
                <div className="flex items-center h-full">
                    {isEditParent ?
                        (<div className={`h-full mx-2 px-2 flex items-center rounded border border-solid
                        ${focusInputParent ? 'border-vekin-green100' : 'border-quartenary'}
                        `}
                            style={{ width: 'calc(100% - 46px)' }}>
                            <input
                                className="w-full h-full text-sm text-vekin-green100 focus:outline-0"
                                type={`text`}
                                placeholder={`Building name`}
                                onFocus={(e) => { setFocusInputParent(true) }}
                                onBlur={(e) => { setFocusInputParent(false) }}
                                value={inputValueParent}
                                onInput={(e) => { setInputValueParent(e.target.value) }}
                            />
                            <div className={`w-[30px] h-[30px] min-w-[30px] min-h-[30px] mr-1 ml-2
                                flex justify-center items-center rounded-lg 
                                cursor-pointer hover:opacity-60
                                duration-300 ease-in-out`}
                                style={{ background: 'linear-gradient(90.92deg, #DC5D5E 1.04%, #D8907D 95.96%), #D8907D' }}
                                onClick={(e) => { setIsEditParent(false) }}>
                                <img className="w-3.5 h3.5" src={imgClose} />
                            </div>
                            <div className={`w-[30px] h-[30px] min-w-[30px] min-h-[30px]
                                flex justify-center items-center rounded-lg 
                                cursor-pointer hover:opacity-60
                                duration-300 ease-in-out`}
                                style={{ background: 'linear-gradient(90.58deg, #1FA37C 1.6%, rgba(31, 163, 124, 0) 101.09%), #79C8B0' }}
                                onClick={(e) => { onClickSaveChangeParent() }}>
                                <img className="w-3.5 h3.5" src={imgCheck} />
                            </div>
                        </div>)
                        :
                        (<>
                            <p className={`text-secondary text-sm font-bold mr-4`}>
                                {parentDetail?.Name}
                            </p>
                            <img className="w-3.5 h-3.5 hover:opacity-70 duration-300 ease-in-out cursor-pointer"
                                src={imgEdit}
                                onClick={(e) => { onClickEditParent() }} />
                        </>)
                    }
                </div>

                <div className="flex items-center h-full ml-auto mr-0">
                    {isEdit &&
                        <div className="flex items-center h-full px-3 mr-2 duration-300 ease-in-out rounded-lg cursor-pointer bg-vekin-red40-opacity10 hover:bg-vekin-red40"
                            onClick={(e) => { onClickDeleteParent() }}>
                            <img className="w-3.5 h3.5 mr-2" src={imgDelete} />
                            <p className={`text-black text-xs font-medium`}>
                                Delete building
                            </p>
                        </div>
                    }
                    <div className="flex items-center mr-4">
                        <p className={`text-vekin-green100 text-sm font-semibold mr-2`}>
                            {listItemInfo.length}
                        </p>
                        <p className={`text-secondary text-xs font-normal`}>
                            Floors
                        </p>
                    </div>
                    {!isEdit ?
                        (<BtnEdit
                            text={`Edit floor`}
                            disable={disable}
                            onClick={(e) => { onClickEdit() }} />)
                        :
                        (<div className="flex h-full">
                            <div className="mr-2">
                                <BtnCancel onClick={(e) => { onClickCancel() }} />
                            </div>
                            <BtnSave onClick={(e) => { onClickSave() }} />
                        </div>)
                    }
                </div>
            </div>

            <div className="flex w-full" style={{ height: 'calc(100% - 48px)' }}>
                <div className="w-[200px] h-full mr-4">
                    <div className="w-[200px] h-[200px]">
                        <InputImage
                            image={parentDetail?.Image}
                            onInputImage={(e) => { onSetImageParent(e) }}
                        />
                    </div>

                    <div className="flex items-center h-10 px-3 mt-2 duration-300 ease-in-out rounded-lg cursor-pointer bg-vekin-greengray-opacity25 hover:bg-vekin-greengray"
                        onClick={(e) => { onChangeImageParent() }}>
                        <img className="w-3.5 h3.5 mr-2" src={imgRefresh} />
                        <p className={`text-vekin-green100 text-xs font-medium`}>
                            Change image
                        </p>
                    </div>

                    <div className="flex items-center h-10 px-3 mt-2 duration-300 ease-in-out rounded-lg cursor-pointer bg-vekin-red40-opacity10 hover:bg-vekin-red40"
                        onClick={(e) => { onClickDeleteImage() }}>
                        <img className="w-3.5 h3.5 mr-2" src={imgDelete} />
                        <p className={`text-black text-xs font-medium`}>
                            Remove image
                        </p>
                    </div>

                    <input ref={inputImageRef}
                        className="hidden"
                        accept="image/*" type={'file'}
                        onChange={(e) => { onHandleOpenFile(e) }} />

                </div>

                <div className="w-[200px] h-full relative" style={{ width: 'calc(100% - 216px)' }}>
                    {(!isEdit && listItemInfo.length === 0) &&
                        <div className="flex items-center justify-center w-full h-full">
                            <div className="w-full">
                                <div className="flex justify-center">
                                    <img className="w-11 h-11" src={imgWarning} />
                                </div>
                                <div className="flex justify-center mt-2">
                                    <p className={`text-tertiary text-xs font-medium`}>
                                        No  floors
                                    </p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="w-full h-full overflow-y-auto">
                        {listItemInfo.map((value, index) => {
                            return (
                                <div key={value.Id} className="w-full h-12 mb-2" draggable={isEdit}
                                    onDragStart={(e) => { onDragStartItem(index) }}
                                    onDragEnter={(e) => { onDragEnterItem(index) }}
                                    onDragEnd={(e) => { onSortFromDrag() }}
                                    onDragOver={(e) => { onDragOverItem(e) }}
                                >
                                    <ItemCard
                                        isEdit={isEdit}
                                        detail={value}
                                        mode={1}
                                        placeholder={'Floor name'}
                                        onClick={(e) => { onClickItemCard(value) }}
                                        onDelete={(e) => { onDeleteItemCard(value) }}
                                        onInputText={(e) => { onInputTextItemCard(value, e) }}
                                    />
                                </div>
                            )
                        })}
                        {isEdit &&
                            <div className="w-full h-12 mb-2">
                                <ItemCardNew
                                    placeholder={'+ Add new floor'}
                                    clear={isClearValueItemCardNew}
                                    onClickAdd={(e) => { onClickAddNewItemCard(e) }}
                                />
                            </div>
                        }
                    </div>

                </div>
            </div>
        </div>
    )
}

export default FloorContent;