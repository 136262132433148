import React, { createContext, useReducer, useState } from "react";

const AuthContext = createContext();

const initialState = {
  data: undefined,
  isAuthenticate: false,
  isLoading: false,
  error: undefined,
};

const AuthAction = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILED: "LOGIN_FAILED",
  GET_AUTHENTICATED_REQUEST: "GET_AUTHENTICATED_REQUEST",
  GET_AUTHENTICATED_SUCCESS: "GET_AUTHENTICATED_SUCCESS",
  GET_AUTHENTICATED_FAILED: "GET_AUTHENTICATED_FAILED",
  GET_LOG_OUT_REQUEST: "GET_LOG_OUT_REQUEST",
  GET_LOG_OUT_SUCCESS: " GET_LOG_OUT_SUCCESS",
  GET_LOG_OUT_FAILED: " GET_LOG_OUT_FAILED",
};

const reducerAuth = (state, action) => {
  switch (action.type) {
    case AuthAction.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticate: false,
      };
    case AuthAction.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isAuthenticate: true,
      };
    case AuthAction.LOGIN_FAILED:
      return {
        ...state,
        error: action.error,
        isLoading: false,
        isAuthenticate: false,
      };
    case AuthAction.GET_AUTHENTICATED_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthAction.GET_AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticate: true,
      };
    case AuthAction.GET_AUTHENTICATED_FAILED:
      return {
        ...state,
        isLoading: false,
        isAuthenticate: false,
      };
    case AuthAction.GET_LOG_OUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AuthAction.GET_LOG_OUT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isAuthenticate: false,
        error: undefined,
      };
    case AuthAction.GET_LOG_OUT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

const AuthContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducerAuth, initialState);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthContextProvider, AuthAction };
