const Invertor_Icon = () => {
  return (
    <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M44.3765 39.5928V36.6593C44.3765 36.0763 43.882 35.9259 43.2693 36.3208L38.12 39.7056C37.5072 40.1099 37.0127 40.9091 37.0127 41.4921V44.4538C37.0342 44.9521 37.3997 45.1496 37.8512 44.8769C38.3135 44.6042 38.6897 43.9461 38.6897 43.4101C38.6897 43.3913 38.6897 43.3725 38.6897 43.3537V41.6425C38.6897 41.4357 38.8617 41.1442 39.0875 41.0032L42.3125 38.8782C42.5275 38.7372 42.7103 38.7842 42.7103 39.0005V40.5894C42.7103 40.5989 42.7103 40.5989 42.7103 40.6083C42.7103 40.6177 42.7103 40.6177 42.7103 40.6271V40.7211V40.7117C42.7533 41.163 43.108 41.3322 43.538 41.069C44.0003 40.7963 44.3765 40.1381 44.3765 39.6022C44.3873 39.6022 44.3765 39.5928 44.3765 39.5928Z" fill="url(#paint0_linear_1132_7280)"/>
<path d="M7.81549 29.128V26.1945C7.81549 25.6116 7.32099 25.4611 6.70823 25.856L1.55892 29.2409C0.946166 29.6452 0.45166 30.4443 0.45166 31.0273V33.989C0.47316 34.4873 0.838664 34.6848 1.29017 34.4121C1.75242 34.1395 2.12868 33.4813 2.12868 32.9454C2.12868 32.9266 2.12868 32.9078 2.12868 32.8889V31.1777C2.12868 30.9709 2.30068 30.6794 2.52643 30.5384L5.75147 28.4134C5.96647 28.2724 6.14922 28.3194 6.14922 28.5357V30.1247C6.14922 30.1341 6.14922 30.1341 6.14922 30.1435C6.14922 30.1529 6.14922 30.1529 6.14922 30.1623V30.2563V30.2469C6.19222 30.6982 6.54698 30.8675 6.97698 30.6042C7.43924 30.3315 7.81549 29.6734 7.81549 29.1374C7.81549 29.1374 7.81549 29.1374 7.81549 29.128Z" fill="url(#paint1_linear_1132_7280)"/>
<path d="M45.0001 37.6935L44.5271 37.9662L5.18164 25.762V2.43484C5.18164 0.554373 6.92316 -0.451674 9.07318 0.197085L41.1085 9.79685C43.2586 10.4456 45.0001 12.4859 45.0001 14.3664V37.6935Z" fill="url(#paint2_radial_1132_7280)"/>
<path d="M44.5269 37.9662L4.7085 26.0347V2.70754C4.7085 0.827079 6.45002 -0.178969 8.60004 0.46979L40.6354 10.0696C42.7854 10.7183 44.5269 12.7586 44.5269 14.6391V37.9662Z" fill="url(#paint3_linear_1132_7280)"/>
<path d="M43.2583 37.5525L39.5925 39.9501L5.00937 26.0911L3.22485 3.864C3.22485 3.864 5.36413 2.6511 6.80464 1.79549C7.3314 1.47581 7.97641 1.34418 8.64291 1.54163L39.528 10.7653C41.592 11.3859 43.2691 13.3509 43.2691 15.1562L43.2583 37.5525Z" fill="url(#paint4_linear_1132_7280)"/>
<path d="M40.2916 40.6835L39.8186 40.9561L0.473145 28.7519V5.42477C0.473145 3.5443 2.21466 2.53825 4.36469 3.18701L36.4 12.7868C38.5501 13.4355 40.2916 15.4758 40.2916 17.3563V40.6835Z" fill="url(#paint5_radial_1132_7280)"/>
<path d="M39.8184 40.9562L0 29.0152V5.68807C0 3.80761 1.74152 2.80156 3.89154 3.45032L35.9269 13.0501C38.0769 13.6988 39.8184 15.7392 39.8184 17.6196V40.9562Z" fill="url(#paint6_linear_1132_7280)"/>
<path d="M23.274 15.2408L2.30054 8.85665V8.39594L23.274 14.7801V15.2408Z" fill="url(#paint7_radial_1132_7280)"/>
<path d="M23.274 13.539L2.30054 7.14541V6.68469L23.274 13.0689V13.539Z" fill="url(#paint8_radial_1132_7280)"/>
<path d="M23.274 14.3946L2.30054 8.001V7.54028L23.274 13.9245V14.3946Z" fill="url(#paint9_radial_1132_7280)"/>
<path d="M23.274 16.9521L2.30054 10.5679V10.1072L23.274 16.4914V16.9521Z" fill="url(#paint10_radial_1132_7280)"/>
<path d="M23.274 16.0964L2.30054 9.71224V9.25153L23.274 15.6357V16.0964Z" fill="url(#paint11_radial_1132_7280)"/>
<path d="M37.6469 19.7445L25.4993 16.0212V15.5605L37.6469 19.2744V19.7445Z" fill="url(#paint12_radial_1132_7280)"/>
<path d="M37.6469 18.0333L25.4993 14.31V13.8492L37.6469 17.5726V18.0333Z" fill="url(#paint13_radial_1132_7280)"/>
<path d="M37.6469 18.8889L25.4993 15.1656V14.7049L37.6469 18.4188V18.8889Z" fill="url(#paint14_radial_1132_7280)"/>
<path d="M37.6469 21.4463L25.4993 17.7324V17.2717L37.6469 20.9856V21.4463Z" fill="url(#paint15_radial_1132_7280)"/>
<path d="M37.6469 20.6001L25.4993 16.8768V16.4161L37.6469 20.13V20.6001Z" fill="url(#paint16_radial_1132_7280)"/>
<path d="M40.2915 35.0609L42.549 33.6788V32.7573L40.2915 34.1395V35.0609Z" fill="url(#paint17_linear_1132_7280)"/>
<path d="M40.2915 31.6102L42.549 30.2281V29.2972L40.2915 30.6794V31.6102Z" fill="url(#paint18_linear_1132_7280)"/>
<path d="M40.2915 33.3403L42.549 31.9581V31.0273L40.2915 32.4094V33.3403Z" fill="url(#paint19_linear_1132_7280)"/>
<path d="M40.2915 38.5209L42.549 37.1388V36.2079L40.2915 37.5901V38.5209Z" fill="url(#paint20_linear_1132_7280)"/>
<path d="M40.2915 36.7909L42.549 35.4087V34.4779L40.2915 35.86V36.7909Z" fill="url(#paint21_linear_1132_7280)"/>
<path d="M40.2915 26.4295L42.549 25.0474V24.1166L40.2915 25.4987V26.4295Z" fill="url(#paint22_linear_1132_7280)"/>
<path d="M40.2915 22.9695L42.549 21.5874V20.666L40.2915 22.0481V22.9695Z" fill="url(#paint23_linear_1132_7280)"/>
<path d="M40.2915 24.6996L42.549 23.3174V22.3866L40.2915 23.7687V24.6996Z" fill="url(#paint24_linear_1132_7280)"/>
<path d="M40.2915 29.8802L42.549 28.498V27.5672L40.2915 28.9587V29.8802Z" fill="url(#paint25_linear_1132_7280)"/>
<path d="M40.2915 28.1502L42.549 26.768V25.8466L40.2915 27.2288V28.1502Z" fill="url(#paint26_linear_1132_7280)"/>
<path d="M19.9738 7.85056L22.5431 6.43081L21.6293 6.15814L19.0601 7.57789L19.9738 7.85056Z" fill="url(#paint27_linear_1132_7280)"/>
<path d="M16.5768 6.83518L19.1569 5.42483L18.2431 5.15216L15.6738 6.56251L16.5768 6.83518Z" fill="url(#paint28_linear_1132_7280)"/>
<path d="M18.2753 7.34293L20.8446 5.93258L19.9416 5.65991L17.3616 7.07026L18.2753 7.34293Z" fill="url(#paint29_linear_1132_7280)"/>
<path d="M23.36 8.85666L25.9293 7.44631L25.0263 7.17365L22.4463 8.58399L23.36 8.85666Z" fill="url(#paint30_linear_1132_7280)"/>
<path d="M21.6616 8.34891L24.2416 6.93856L23.3278 6.66589L20.7585 8.07624L21.6616 8.34891Z" fill="url(#paint31_linear_1132_7280)"/>
<path d="M11.4919 5.32138L14.0719 3.91103L13.1581 3.63837L10.5889 5.04871L11.4919 5.32138Z" fill="url(#paint32_linear_1132_7280)"/>
<path d="M8.10565 4.30588L10.6857 2.89553L9.77192 2.62286L7.19189 4.03321L8.10565 4.30588Z" fill="url(#paint33_linear_1132_7280)"/>
<path d="M9.80414 4.81363L12.3734 3.40328L11.4704 3.13062L8.89038 4.54096L9.80414 4.81363Z" fill="url(#paint34_linear_1132_7280)"/>
<path d="M14.8889 6.32742L17.4581 4.91708L16.5444 4.64441L13.9751 6.05476L14.8889 6.32742Z" fill="url(#paint35_linear_1132_7280)"/>
<path d="M13.1904 5.82907L15.7597 4.40932L14.8566 4.13666L12.2766 5.55641L13.1904 5.82907Z" fill="url(#paint36_linear_1132_7280)"/>
<path d="M32.0783 11.4798L34.6583 10.0695L33.7446 9.79681L31.1753 11.2072L32.0783 11.4798Z" fill="url(#paint37_linear_1132_7280)"/>
<path d="M28.6921 10.4738L31.2721 9.0541L30.3583 8.79083L27.7891 10.2012L28.6921 10.4738Z" fill="url(#paint38_linear_1132_7280)"/>
<path d="M30.3906 10.9722L32.9598 9.56185L32.0568 9.28918L29.4768 10.7089L30.3906 10.9722Z" fill="url(#paint39_linear_1132_7280)"/>
<path d="M35.4755 12.4953L38.0448 11.085L37.1418 10.8123L34.5618 12.2227L35.4755 12.4953Z" fill="url(#paint40_linear_1132_7280)"/>
<path d="M33.777 11.9876L36.3571 10.5772L35.4433 10.3046L32.8633 11.7149L33.777 11.9876Z" fill="url(#paint41_linear_1132_7280)"/>
<path d="M24.4781 10.5679H24.3599V35.6345H24.4781V10.5679Z" fill="url(#paint42_linear_1132_7280)"/>
<path d="M25.2843 9.45839L25.1445 9.41138L28.3588 7.43689L28.4986 7.4839L25.2843 9.45839Z" fill="url(#paint43_linear_1132_7280)"/>
<defs>
<linearGradient id="paint0_linear_1132_7280" x1="37.6824" y1="40.8463" x2="44.6792" y2="39.9277" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint1_linear_1132_7280" x1="1.11904" y1="30.3868" x2="8.11582" y2="29.4681" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<radialGradient id="paint2_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.0907 18.5694) rotate(16.6806) scale(18.635 14.9558)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
<linearGradient id="paint3_linear_1132_7280" x1="24.6193" y1="6.0212" x2="17.2895" y2="30.4826" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint4_linear_1132_7280" x1="27.891" y1="8.50582" x2="19.6249" y2="36.859" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<radialGradient id="paint5_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(20.3808 21.5552) rotate(16.6806) scale(18.635 14.9558)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="1" stop-color="#00A99D"/>
</radialGradient>
<linearGradient id="paint6_linear_1132_7280" x1="19.9094" y1="9.00701" x2="12.5796" y2="33.4684" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<radialGradient id="paint7_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.9691 3.25117) scale(27.5212 24.0707)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint8_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.9691 3.25115) scale(27.5212 24.0707)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint9_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.9691 3.25113) scale(27.5212 24.0707)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint10_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.9691 3.25119) scale(27.5212 24.0707)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint11_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(25.9691 3.25115) scale(27.5212 24.0707)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint12_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7067 6.97716) scale(25.3475 22.1695)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint13_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7067 6.97713) scale(25.3475 22.1695)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint14_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7067 6.97718) scale(25.3475 22.1695)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint15_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7067 6.97711) scale(25.3475 22.1695)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<radialGradient id="paint16_radial_1132_7280" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(40.7067 6.97714) scale(25.3475 22.1695)">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</radialGradient>
<linearGradient id="paint17_linear_1132_7280" x1="43.2926" y1="33.9089" x2="40.342" y2="33.9089" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint18_linear_1132_7280" x1="43.2926" y1="30.4542" x2="40.342" y2="30.4542" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint19_linear_1132_7280" x1="43.2926" y1="32.1815" x2="40.342" y2="32.1815" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint20_linear_1132_7280" x1="43.2926" y1="37.3636" x2="40.342" y2="37.3636" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint21_linear_1132_7280" x1="43.2926" y1="35.6362" x2="40.342" y2="35.6362" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint22_linear_1132_7280" x1="43.2926" y1="25.2721" x2="40.342" y2="25.2721" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint23_linear_1132_7280" x1="43.2926" y1="21.8175" x2="40.342" y2="21.8175" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint24_linear_1132_7280" x1="43.2926" y1="23.5448" x2="40.342" y2="23.5448" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint25_linear_1132_7280" x1="43.2926" y1="28.7268" x2="40.342" y2="28.7268" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint26_linear_1132_7280" x1="43.2926" y1="26.9995" x2="40.342" y2="26.9995" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint27_linear_1132_7280" x1="21.555" y1="4.86779" x2="20.2331" y2="9.64281" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint28_linear_1132_7280" x1="18.1423" y1="3.92307" x2="16.8204" y2="8.69809" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint29_linear_1132_7280" x1="19.8486" y1="4.3955" x2="18.5267" y2="9.17051" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint30_linear_1132_7280" x1="24.9677" y1="5.81264" x2="23.6458" y2="10.5877" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint31_linear_1132_7280" x1="23.2613" y1="5.34022" x2="21.9394" y2="10.1152" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint32_linear_1132_7280" x1="13.023" y1="2.50588" x2="11.7011" y2="7.28091" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint33_linear_1132_7280" x1="9.61032" y1="1.56104" x2="8.28841" y2="6.33605" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint34_linear_1132_7280" x1="11.3167" y1="2.03346" x2="9.99477" y2="6.80848" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint35_linear_1132_7280" x1="16.4358" y1="3.45067" x2="15.1138" y2="8.22569" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint36_linear_1132_7280" x1="14.7294" y1="2.97825" x2="13.4075" y2="7.75326" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint37_linear_1132_7280" x1="33.6105" y1="8.66873" x2="32.2886" y2="13.4437" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint38_linear_1132_7280" x1="30.1978" y1="7.724" x2="28.8759" y2="12.499" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint39_linear_1132_7280" x1="31.9042" y1="8.19642" x2="30.5823" y2="12.9714" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint40_linear_1132_7280" x1="37.0235" y1="9.61357" x2="35.7016" y2="14.3886" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint41_linear_1132_7280" x1="35.3171" y1="9.14115" x2="33.9952" y2="13.9162" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
<linearGradient id="paint42_linear_1132_7280" x1="15.5786" y1="16.9279" x2="25.553" y2="26.0395" gradientUnits="userSpaceOnUse">
<stop stop-color="#00C7B9"/>
<stop offset="0.223" stop-color="#00C4B6"/>
<stop offset="0.4033" stop-color="#00BAAD"/>
<stop offset="0.5687" stop-color="#00A99D"/>
<stop offset="0.7251" stop-color="#009187"/>
<stop offset="0.8738" stop-color="#00726A"/>
<stop offset="1" stop-color="#00524C"/>
</linearGradient>
<linearGradient id="paint43_linear_1132_7280" x1="26.6002" y1="8.02469" x2="27.1868" y2="9.51654" gradientUnits="userSpaceOnUse">
<stop stop-color="#999999"/>
<stop offset="1"/>
</linearGradient>
</defs>
</svg>

  )
}

export default Invertor_Icon;
