const Icon_Lighting = ({style}) => {
    return (
        
        <svg className={style} width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.0857544" width="32" height="32" rx="16" fill="#F2F8F6"/>
<path d="M16.0858 23.5C16.9108 23.5 17.5858 22.825 17.5858 22H14.5858C14.5858 22.825 15.2608 23.5 16.0858 23.5Z" fill="#79C8B0"/>
<path d="M16.0858 23.5C16.9108 23.5 17.5858 22.825 17.5858 22H14.5858C14.5858 22.825 15.2608 23.5 16.0858 23.5Z" fill="url(#paint0_linear_2819_56330)"/>
<path d="M13.8358 21.25H18.3358C18.7483 21.25 19.0858 20.9125 19.0858 20.5C19.0858 20.0875 18.7483 19.75 18.3358 19.75H13.8358C13.4233 19.75 13.0858 20.0875 13.0858 20.5C13.0858 20.9125 13.4233 21.25 13.8358 21.25Z" fill="#79C8B0"/>
<path d="M13.8358 21.25H18.3358C18.7483 21.25 19.0858 20.9125 19.0858 20.5C19.0858 20.0875 18.7483 19.75 18.3358 19.75H13.8358C13.4233 19.75 13.0858 20.0875 13.0858 20.5C13.0858 20.9125 13.4233 21.25 13.8358 21.25Z" fill="url(#paint1_linear_2819_56330)"/>
<path d="M16.0858 8.5C12.9808 8.5 10.4608 11.02 10.4608 14.125C10.4608 16.99 12.4558 18.52 13.2883 19H18.8833C19.7158 18.52 21.7108 16.99 21.7108 14.125C21.7108 11.02 19.1908 8.5 16.0858 8.5Z" fill="#79C8B0"/>
<path d="M16.0858 8.5C12.9808 8.5 10.4608 11.02 10.4608 14.125C10.4608 16.99 12.4558 18.52 13.2883 19H18.8833C19.7158 18.52 21.7108 16.99 21.7108 14.125C21.7108 11.02 19.1908 8.5 16.0858 8.5Z" fill="url(#paint2_linear_2819_56330)"/>
<defs>
<linearGradient id="paint0_linear_2819_56330" x1="10.5947" y1="14.75" x2="21.9014" y2="14.8365" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint1_linear_2819_56330" x1="10.5947" y1="14.75" x2="21.9014" y2="14.8365" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
<linearGradient id="paint2_linear_2819_56330" x1="10.5947" y1="14.75" x2="21.9014" y2="14.8365" gradientUnits="userSpaceOnUse">
<stop stop-color="#1FA37C"/>
<stop offset="1" stop-color="#1FA37C" stop-opacity="0"/>
</linearGradient>
</defs>
</svg>

    )
}

export default Icon_Lighting;