import React, { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useGetInvitationDetails, userInvitationConfirmation } from "../../api/userInfo";
import bg_image from "../../assets/backgroundImages/company_bg.jpg";
import  {WidgetLoading} from "../../components/ui";



const InvitationPage = () => {
  const navigate = useNavigate();
  const {pathname, search} = useLocation();
  const [details, setDetails]=useState('')
  const {invitation, isLoadingInvitation, isErrorInvitation}= useGetInvitationDetails(pathname,search)

  //console.log({invitation, isLoadingInvitation, isErrorInvitation})
  function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  useEffect(() => {
      if(invitation && invitation?.answer != "pending"){
        navigate('/login')
      }
  }, [invitation]);

  useEffect(() => {
      const invitation_details ={
        'email': new URLSearchParams(search).get("email"),
        'invite_id': pathname?.split('/')?.slice(-1)?.[0]
      } 
     // console.log(invitation_details)
      
      if(validateEmail(invitation_details?.email)){
        setDetails(invitation_details)
      }else{
        alert("Invalid invitation link")
        navigate('/login')
      }
      
    return () => {
      // cleanup
      setDetails('')
    }
  }, []);

  
  const acceptInvitation = async (accepted) => {
    try {
      let request = {
          "is_accept": accepted, 
          'email':invitation.email,
          'plant': invitation.plant
      }
       
      const response = await userInvitationConfirmation(invitation?.id, request)  

      if(accepted && response?.data){
        switch (response.data.detail) {
          case "Registration is required":
            navigate('/signup')
            break;
          case "error while verifying invitation.":
            alert("error while verifying invitation, please try again")
            break;
        }
      }else if(!accepted && response?.data){
        navigate('/login')
      }else{
        alert(response?.error)
        navigate('/login')
      }
    }catch(error){
      alert("Something went wrong, please try again")
    }
  }
  

  return (
    <div
      className="relative flex items-center justify-center w-full h-screen bg-no-repeat bg-cover bg-image"
      style={{ backgroundImage: `url(${bg_image})` }}
    >

      {(!invitation && !isErrorInvitation) ?
      <div>
        <WidgetLoading />
        <p className="mt-8 text-white/75">Loading Invitation</p>
        </div>
       :(isErrorInvitation && !invitation) ?
       <div>
        <WidgetLoading />
        <p className="mt-8 text-white/75">An error occurred while loading invitation</p>
       </div>
       :
      <div className="absolute inset-0 z-20 flex items-center justify-center w-full h-screen overflow-hidden">
        <div className="max-w-lg p-4 mx-auto text-center bg-white rounded h-50 md:max-w-lg">
          <h1 className="text-3xl font-bold text-center text-vekin">
            Invitation
          </h1>
          <div className="p-4 m-4">
            
            <p>You have been invited to join <span className="font-bold">{invitation?.plant_name}</span> <br></br>from <span className="font-bold">{invitation?.company_name} company</span></p>
            
          </div>
          <div className="flex justify-center gap-8">
            <button
              onClick={()=> acceptInvitation(false)}
              className="items-center w-40 gap-2 p-2 px-4 text-center text-red-400 border border-red-300 rounded-md hover:shadow-md"
            >
              Reject
            </button>
           
            <button
              onClick={()=> acceptInvitation(true)}
              className="items-center gap-2 p-2 px-4 text-center text-white rounded-md bg-vekin hover:shadow-md"
            >
              Accept Invitation
            </button>
          </div>
        </div>
      </div> }
    </div>
  );
};

export default InvitationPage;
