import { SelectNormalIcons } from "../SetupWidget/SelectWidgetIcon";
import MultiSelectBtn from "./multiSelectBtn";
import { useGetDevice } from "../../../api/userInfo";
import { useEffect, useState } from "react";


export const SingleDeviceSetup = ({clearForm, submitForm, updateWidgetIcon, widgetIcon, updateSetupDetails, setupDetails, selectedWidget}) => {
    const {deviceList, isLoadingDeviceList, isErrorDeviceList} = useGetDevice(localStorage.getItem('plantId'));
    const [multiSelect,setMultiSelect ]= useState({})
    
    useEffect(() => {
      //  console.log(multiSelect?.deviceFields )
        if(multiSelect?.deviceFields !== undefined){
            document.getElementById('selectField').removeAttribute('disabled')
        }else{
            document.getElementById('selectField').setAttribute('disabled', true)
        }
        
        if(multiSelect?.data){
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                    ...setupDetails.measurement?.[0],
                    [multiSelect?.field]: multiSelect?.data ,

                  }]
            });
        }
    }, [multiSelect]);

    
    const onInput = (field) => (e) => {
            updateSetupDetails({
                ...setupDetails,
                'measurement': [{
                  ...setupDetails.measurement?.[0],
                  [field]: e.target.value
                }]
              });
    };

    const handleSubmit = () => {
        const {title, field, device, icon} = setupDetails?.measurement?.[0] ?? {title: null, field: null, device: null, icon: null}
        if(title && field && device && icon){
            submitForm()
        }else{
            alert('Please fill all the fields')
        }
    }
    
    return ( 
        <>
            <div className="flex flex-col gap-4 mt-4">
                    <div className="flex flex-col gap-2">
                        <label htmlFor="device" className="col-span-1 text-sm text-gray-500">Device ID (select one or more devices)</label>
                        <MultiSelectBtn isMulti={false} setMultiSelect={setMultiSelect} data={deviceList?.devices} name="device" placeholder="select device" />
                    </div> 
                    
                    <div className={`grid grid-cols-2 gap-2`}>
                        <div  className="flex flex-col gap-2">
                            <label htmlFor="title" className="col-span-1 text-sm text-gray-500">Title</label>
                            <input onChange={onInput('title')} autoComplete="off" required type="text" name="title"  className="setupModal_input" placeholder="Title of widget"/>
                        </div> 

                    
                        
                        <div  className="flex flex-col gap-2">
                            <label htmlFor="field" className="col-span-1 text-sm text-gray-500">Select Field</label>
                            <select id="selectField" className="setupModal_input" onChange={onInput('field')} disabled>
                                {multiSelect?.deviceFields?.length ===undefined && <option>You need to first select the device</option>}
                                <option value={''}>select field</option>
                                {multiSelect?.deviceFields?.map(({name, field}, index) => (
                                    <option key={index+field} value={field} >{name}</option>
                                ))}
                            </select>
                        </div>

                    </div>

                    
            </div>
                
                {selectedWidget !== 'Performance Widget' &&
                <SelectNormalIcons updateSetupDetails={updateSetupDetails} setupDetails={setupDetails} insideMeasurement={true} /> 
                 }

                {/* ------------ submit button */}
                <div className='flex flex-row justify-between gap-4 mt-4'>
                <button  type="button" onClick={()=> clearForm()} className='w-1/2 py-2 text-center border border-gray-300 rounded-md text-vekin hover:shadow-md'>
                    Cancel
                </button>

                
                    <button type="button" onClick={()=> handleSubmit()}  className='w-1/2 py-2 text-center text-white rounded-md bg-vekin hover:shadow-md'>
                        Submit
                    </button>  

                
                </div>
        </>
     );
}


 

