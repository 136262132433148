const Air_Icon = ({style, stroke}) => {
    return (
        <svg className={style} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.46875 10.9687H2.625C1.875 10.9687 1.21875 10.3125 1.21875 9.56245C1.21875 8.81245 1.875 8.1562 2.625 8.1562H9.5625C9.9375 8.1562 11.7188 8.06246 11.7188 5.81245C11.7188 5.06245 11.5312 4.49996 11.1562 4.21871C10.6875 3.84371 10.125 3.74995 9.65625 3.8437C8.71875 4.0312 8.25 4.68746 8.25 5.90621C8.25 6.65621 7.59375 7.31246 6.84375 7.31246C6.09375 7.31246 5.4375 6.65621 5.4375 5.90621C5.4375 3.28121 6.9375 1.40621 9.28125 1.12496C10.5937 0.937455 12 1.31245 13.0312 2.1562C13.6875 2.7187 14.5312 3.93746 14.5312 5.90621C14.5312 9.28121 12 10.9687 9.46875 10.9687Z" fill={stroke}/>
<path d="M21.6563 15.3751H2.625C1.875 15.3751 1.21875 14.7188 1.21875 13.9688C1.21875 13.2188 1.875 12.5626 2.625 12.5626H21.6563C23.3438 12.5626 25.0313 11.3438 25.0313 9.00005C25.0313 7.9688 24.6563 7.12505 24.0938 6.56255C23.3438 5.9063 22.5 5.81255 21.9375 5.9063C21 6.00005 19.5 6.6563 19.5 9.0938C19.5 9.8438 18.8438 10.5001 18.0938 10.5001C17.3438 10.5001 16.6875 9.8438 16.6875 9.0938C16.6875 5.9063 18.5625 3.56255 21.5625 3.18755C23.0625 3.00005 24.75 3.4688 25.9688 4.50005C26.9063 5.25005 27.9375 6.6563 27.9375 9.0938C27.8438 13.1251 24.6563 15.3751 21.6563 15.3751Z" fill={stroke}/>
<path d="M22.2188 28.6875C19.5 28.6875 16.7812 26.625 16.7812 23.0625C16.7812 22.3125 17.4375 21.6563 18.1875 21.6563C18.9375 21.6563 19.5938 22.3125 19.5938 23.0625C19.5938 24.9375 21.1875 25.875 22.2188 25.875C23.625 25.875 25.2188 25.0313 25.2188 23.1563C25.2188 20.8125 22.9688 19.9688 21.6563 19.9688H2.625C1.875 19.9688 1.21875 19.3125 1.21875 18.5625C1.21875 17.8125 1.875 17.1562 2.625 17.1562H21.6563C24.8438 17.1562 28.0312 19.2188 28.0312 23.1563C28.0312 26.5313 25.7813 28.6875 22.2188 28.6875Z" fill={stroke}/>

</svg>
    )
}

export default Air_Icon;